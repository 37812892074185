import React from "react";
import { connect } from "react-redux";

const DatosProveedor = ({ Proveedor,habilitado }) => {
  return (
    <>
      <div className={"flex-auto p-1 border-t border-gray-300 rounded-b-lg bg-gray-100 "+(habilitado?" opacity-50":"")}>
        <div className="flex flex-wrap">
          <div className="relative w-full pr-2 max-w-full flex-grow flex-1">
            <span className="font-semibold text-xs lg:text-base text-gray-800">
              {Proveedor.nombre}
            </span>
          </div>
          <div className="relative w-auto pl-2 flex-initial">
            <div className={(Proveedor.nombre ? "bg-blue-700" : "bg-gray-300") +
               " text-white p-2 text-center inline-flex items-center justify-center w-6 h-6 lg:w-8 lg:h-8 text-xs lg:text-base rounded-full "
            }>
              <i className="fas fa-user-tie"></i>
            </div>
          </div>
        </div>
        {Proveedor.rfc ? (
          <p className="text-xs lg:text-sm text-gray-500 mt-4">
            <span className="text-orange-500 mr-2">RFC:</span>
            <span className="whitespace-no-wrap">{Proveedor.rfc}</span>
          </p>
        ) : null}
        {Proveedor.importe ? (
          <p className="text-xs lg:text-sm text-gray-500 mt-4">
            <span className="text-orange-500 mr-2">Importe:</span>
            <span className="whitespace-no-wrap">{Proveedor.importe}</span>
          </p>
        ) : null}
        {Proveedor.pagado ? (
          <p className="text-xs lg:text-sm text-gray-500 mt-4">
            <span className="text-orange-500 mr-2">Pagado:</span>
            <span className="whitespace-no-wrap">{Proveedor.pagado}</span>
          </p>
        ) : null}
        {Proveedor.restante ? (
          <p className="text-xs lg:text-sm text-gray-500 mt-4">
            <span className="text-orange-500 mr-2">Restante:</span>
            <span className="whitespace-no-wrap">{Proveedor.restante}</span>
          </p>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Proveedor: state.SearchProveedor,
});

export default connect(mapStateToProps)(DatosProveedor);
