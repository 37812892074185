import React,{ useRef} from 'react'
import { useState } from 'react';
import { useMemo } from 'react';

const InputSelectormap =({catalogo, name,getvalor,disabled,hidden,defaultValue,reset,className,table,onBlur,newValor,placeh,onBlurI,inputRef,onKeyPress}) =>{
  const select = inputRef || useRef("");
  const [existValue,setExistValue] = useState(true);
  const placeholder = placeh||"";
  const getval = getvalor || ((valor)=>{});

  function cambiaValor(valor) {
    const val = Object.entries(catalogo).find(([key,value])=>(catalogo[key]===valor));
    if(val){
      setExistValue(true);
      getval({clave:val[0],valor:val[1]});
   }else{
    (valor==""?setExistValue(true):setExistValue(false));
    getval({valor:valor});
  }
  }

  function getValorBlur(valor) {
    const val = Object.entries(catalogo).find(([key,value])=>(catalogo[key]===valor));
    if(val){
      setExistValue(true);
      return{clave:val[0],valor:val[1]};
   }else{
    (valor==""?setExistValue(true):setExistValue(false));
    return{valor:valor};
  }
  }

  useMemo(()=>{
    select.current?.value? select.current.value = "" :""
  },[reset])

  useMemo(() => {
    if(newValor){
      cambiaValor(newValor);
    }
  }, [newValor])
  

    return <div className={(className?className:" ")} hidden={hidden||false}>
            <input
            name={name}
            ref={select}
            defaultValue={defaultValue}
            disabled={disabled||false}
            placeholder={placeholder}
            autoComplete="on"
           
            type='text'
            onBlur={e=>{
              onBlur?onBlur(e):(onBlurI?onBlurI(getValorBlur(e.target.value.toUpperCase().trim())):"");
            }}
            onKeyDown={e=>{
              onKeyPress?onKeyPress(e):""
            }}
            list={name+'is'}
            className={" w-full uppercase "+(table?"border-table ":"border-form ")+" "+(existValue==false?"bg-red-100":"bg-white")}
            onChange={e => {
              cambiaValor(e.target.value.toUpperCase().trim());
            }}
            />
         <datalist id={name+'is'}>
                {(catalogo)?Object.entries(catalogo).sort().map(([key,value])=>(
                    <option key={key} value={value}>{value}</option>
                )):[]}
            </datalist>
    </div>
}

InputSelectormap.defaultProps ={
    agregable : false,
    tip: ''
}

export default InputSelectormap