import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useCatalogo, useCustomQuery } from '../../Hooks/useApis';
import { agregarProducto } from '../../redux/Actions';
import { getDescriptionProduct, getSucursal } from '../../services/functions';
import SearchSvg from '../../static/img/SearchSvg';
import Modalgeneric from '../modal/generic';
import Modalinfo from '../modal/info';
import LoadingModal from '../modal/LoadingModal';
import TableModal from '../modal/TableModal';
import {motion} from 'framer-motion'
import InputSelectormap from './InputSelectormap';
import { useEffect } from 'react';
import Labelmoney from '../labelmoney';
import QRCode from "react-qr-code";


const SearchLine = ({Cliente,getDatos,getLinea,Ruta,Proveedor}) => {
  const [modalModelo,setModalModelo]=useState({}),
  [modalLinea,setModalLinea]=useState([]),
  [datos,setDatos]=useState([]),
  [lineas,setLineas]=useState([]),
  [linea,setLinea]=useState(""),
  [modalLoading, setmodalLoading] = useState({}),
  [buttonErrorLinea,setButtonErrorLinea]=useState(false),
  [modalBarcode,setModalBarcode]=useState({}),
  [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [valueCode,setValueCode]=useState("indigo");

  const bad = useRef(), itemNoExist = useRef();

  const rutaActual =Ruta?.[Ruta.length-1];

  function camposExtras() {
    const campos =[
      { header: "Codigo", width:'w-2/12', accessor: "id" },
      { header: "Mod/Grad",width:"w-1/12", 
        // eslint-disable-next-line react/display-name
        code: (item) => (<label className=''>{item?.medida||""}</label>)
      },
      {
        header: "Exist", width:'w-1/12 text-center',
        // eslint-disable-next-line react/display-name
        code: (item) => (<label>{item?.sucursales[getSucursal()]?.existencia||0}</label>),
      },
      { header: "Descripción", width:"w-4/12 text-xs", 
        // eslint-disable-next-line react/display-name
        code: (item) => (<input readOnly={true} className="w-full bg-transparent outline-none font-size" value ={item?.descripcion}/>),},
      { header: "marca", width:"w-1/12 text-center", accessor:"marca"},
    ];

    if(["panelAjuste","compra"].includes(rutaActual)){
      campos.push({
        header:"PrecioC",
        width:"w-1/12 text-center",
        // eslint-disable-next-line react/display-name
        code: (item) => (<div className='w-full grid grid-flow-col gap-1 '>
            <Labelmoney  twoDecimal={true}>{item?.sucursales?.[getSucursal()]?.precioCompra||0}</Labelmoney>
            <button className={(item?.sucursales?.[getSucursal()]?.comprable==false?'w-auto text-xs ':'hidden')} title='bloqueado'><i className='fas fa-ban text-red-500'></i></button>
          </div>)
      });
      campos.push({
        header:"PrecioV",
        width:"w-1/12 text-center",
        // eslint-disable-next-line react/display-name
        code: (item) => (<Labelmoney twoDecimal={true} >{item?.sucursales?.[getSucursal()]?.precioVenta||0}</Labelmoney>)
      });
    }

    campos.push(              {
      header:"BarCode",
      width:"w-1/12 text-center",
      // eslint-disable-next-line react/display-name
      code: (item) => (
        <button className={'w-auto text-xs'} title='bloqueado' 
        onClick={e=>{
          //closeModalModelo();
          setValueCode(item.id);
          setModalBarcode({isOpen:true});
        }}><i className='fas fa-qrcode text-blue-900'></i></button>
      )
    },)

    return campos;
  }


  function closeModalModelo(e) { setModalModelo({ isOpen: false });}
  function closeModalLinea(e) { setModalLinea({ isOpen: false });}
  function closeModalinfo(e) { bad.current=null; itemNoExist.current=null; setmodalinfoConfig({ isOpen: false }); }
  function openmodalLoading(title)  { setmodalLoading({ isOpen: true, title:(title||"nothing") }); }
  function closemodalLoading(e) {setmodalLoading({ isOpen: false });}

  function ValidarCampos(){
    if(rutaActual==""){return false;}
    if(rutaActual=="compra"&&!Proveedor?.id){return true;}
    if(rutaActual=="venta"&&!Cliente?.id){return true;}
    if(getDatos){return false;}
    if(getLinea){return false;}

    return false;
  }

  function getModelo(item){
    if(item?.type=="LINEA"){
      let keys = "";
      const mods = Object.keys(item.schema[item.id]).sort();
      if(mods.length>1) {
        keys = mods[0]+"-"+mods[mods.length-1];
      }
      return keys;
    }else{
      return "";
    }
  }

  function PeticionLinea(isProduct) {
    const peticion ={ 
      indexName: "lineaSearchIndex",
      hashKey: {valor:linea.valor,nombre:"linea"},
      sortKey: {valor:"true",nombre:"search",operador:"eq"}
    };
    if(isProduct){
      peticion.filters=[{valor:"PRODUCT",nombre:"type",operador:"eq"}]
    }
    useCustomQuery(peticion,handleSuccessfulLinea,handleErrorLinea);
  }

  const handleSuccessfulLinea=(resp)=>{
    console.log('first:',resp)
    const productos = resp?.datos|| [];
    closemodalLoading();
    if (productos.length === 0) {
      setmodalinfoConfig({
        isOpen: true,
        title: "No se ha encontrado la clave en la base de datos.",
        message: "No existe el producto",
      });
      return;
    }
    let new_items =[];
    resp.datos.forEach(item=>{
      new_items.push(getDescriptionProduct(item));
    });

    if(getDatos){getDatos(new_items); return}
    /*if(new_items.length==1){
      const it = {...new_items[0]}
      geLinea(it);*/
    if (new_items.length>=1) {
      setDatos(new_items)
      setModalModelo({ isOpen: true });
    }
  }

  const handleErrorLinea=(error)=>{
    closemodalLoading();
    closeModalLinea();
    setmodalinfoConfig({
      isOpen: true,
      title: "No se ha encontrado la clave en la base de datos.",
      message:"Linea: "+error?.response?.data?.message || "",
    });
  }
  const PeticionLineas=()=>{
    openmodalLoading("Cargando Catalogos");
    useCatalogo("LINEAS",(resp)=>{
      setLineas(resp.opciones);
      closemodalLoading();
    },()=>{
      setButtonErrorLinea(true);
    });
  }

  useEffect(()=>{
    PeticionLineas();
  },[])

  return (
    <>
     <Modalgeneric w="size-w-40" h="size-h-50" front="z-40" config={modalBarcode} >
      <Modalgeneric.Body>
        <Modalgeneric.Title>Codigo Qr</Modalgeneric.Title>
         <div className='w-full flex flex-col gap-2 items-center justify-center'>
          <QRCode
          id="QRCode"
          size={256}
          style={{ height: "150px", maxWidth: "100%", width: "150px" }}
          value={valueCode}
          title={valueCode}
          level="M"
          viewBox={`0 0 256 256`}
          />
          <input className='border-form ' value={valueCode} onChange={e=>{
            e.target.value = e.target.value.toUpperCase();
            setValueCode(e.target.value);
          }}/>
          <button 
          className='btn-green ' 
          onClick={()=>{
            const svg = document.getElementById("QRCode");
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const img = new Image();
            img.onload = () => {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
              const pngFile = canvas.toDataURL("image/png");
              const downloadLink = document.createElement("a");
              downloadLink.download = valueCode;
              downloadLink.href = `${pngFile}`;
              downloadLink.click();
            };
            img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
          }}>Descargar QR</button>
        </div>
      </Modalgeneric.Body>
      <Modalgeneric.Buttons>
        <button
          type="button"
          onClick={e=>{setModalBarcode({isOpen:false})}}
          tabIndex="200"
          className="btn-gray2"
        >
          Cancelar
        </button>
      </Modalgeneric.Buttons>
      </Modalgeneric>         
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>{modalLoading?.title}</LoadingModal.Title>
        </LoadingModal.Body>
        <LoadingModal.Buttons>
          <button className='btn-blue '
          hidden={!buttonErrorLinea}
          onClick={e=>{
            setButtonErrorLinea(false);
            PeticionLineas();
          }}>
            Reintentar
          </button>
        </LoadingModal.Buttons>
      </LoadingModal>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={modalinfoConfig.title}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modalgeneric w="size-w-90" h="size-h-90" config={modalModelo} closeModal={closeModalModelo}>
        <Modalgeneric.Title>Elija una opción</Modalgeneric.Title>
        <Modalgeneric.Body>
          <TableModal
          config={modalModelo}
            columns={
              camposExtras()
            }
            onclickRow={(item,e) => {
              if(e.detail==2||e.key=="Enter"){
                closeModalModelo();
                if(getDatos){
                  getDatos(item)
                  return
                }
                if(["compra","panelAjuste"].includes(rutaActual)){
                  getLinea({...item});
                  return;
                }
              }
            }}
            data={datos}
          />
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModalModelo}
            tabIndex="200"
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <div className='size-form gap-1 items-center w-full'>
        <label className={"text-size font-bold "+(["compra","panelAjuste"].includes(rutaActual)?"text-white":"")}>Linea:</label>
        <form 
        className={"flex w-48 justify-between"}
        onSubmit={e=>{
          e.preventDefault();
          if(linea?.clave){
            openmodalLoading("Cargando Productos");
            if(getLinea){
              (rutaActual=="panelAjuste"?PeticionLinea(true):PeticionLinea())
            }
          }
          else{
            getLinea({});
          }
        }}>
          <InputSelectormap
          catalogo={lineas}
          disabled={ValidarCampos()}
          name="linea"
          className={"w-full"}
          getvalor={(valor) => {
            setLinea(valor);
          }}/>
          <motion.button
          className={"w-4 h-4 self-center mx-1 md:mr-0 "+(["compra","panelAjuste"].includes(rutaActual)?"hover:text-white":"hover:text-blue-400")}
          type="submit"
          disabled={ValidarCampos()}
          style={{ transition: "all .15s ease" }}
          whileHover={{ scale: 1.2 }}
          whileTap={{
            scale: 0.8,
            rotate: 5,
            borderRadius: "100%"
          }}>
            <SearchSvg width="w-full" height="h-full" text={(["compra","panelAjuste"].includes(rutaActual)?"text-white":"text-blue-600")}/>
          </motion.button>
        </form>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(agregarProducto(item)),
});

const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
  Proveedor: state.SearchProveedor,
  Ruta: state.Ruta,
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchLine)