import React, { useState, useMemo } from "react";
import { connect } from 'react-redux';
import TablaArmazonInv from "./TablaArmazonInv";
import Tablefromjson from "./Tablefromjson";
import TablaArmazonInvAux from "./TablaArmazonInvAux";

const WrapperTable = ({PropsInv,inventario}) => {
    const [inventariado,setInventariado] = useState(0);

    useMemo(() => {
      calcularrestante();
    }, [inventario,PropsInv?.tab]);

    
    function calcularrestante() {
      let gTotal = 0;
      if(PropsInv?.tab=="IN"||PropsInv?.tab=="B") gTotal =inventario.reduce((a, b) => parseInt(a) + parseInt(b.cantidad), 0);
      else if(PropsInv?.tab=="G") gTotal =inventario.reduce((a, b) => parseInt(a) + parseInt(b.guideline), 0);
      else if(PropsInv?.tab=="O") gTotal =inventario.reduce((a, b) => a + (b.N>0?b.N:0), 0);
      else gTotal =inventario.reduce((a, b) => parseInt(a) + parseInt(b.[PropsInv?.tab]||0), 0);
      setInventariado(gTotal);
    }

    function extractCol(nombre_Tabla) {
      let col = nombre_Tabla.replace(PropsInv.data.id,"");
      col = col.replace("-","");
      col = col.replace("+","");
      return PropsInv.data.colores[col]
    }
    

    return (
      <div className="w-full justify-center flex flex-wrap">
        <p className={(PropsInv?.data.id?" font-bold text-xl text-center":"hidden")} >{PropsInv.data.id+" "+PropsInv.data.descripcion}</p>
        <p className={(PropsInv?.idcompra?" font-bold text-lg text-center text-green-600":"hidden")}>&nbsp;&nbsp;&nbsp;&nbsp;{" TOTAL COMPRADO: "+(PropsInv?.cantidad||0)+ " / CAPTURADO: "+inventariado}</p>
        <p className={(PropsInv?.data.id?"w-full font-bold text-lg text-center text-blue-700":"hidden")}>{"GRAN TOTAL: "+inventariado}</p>
        {PropsInv.data?.categoria=="MICA"||PropsInv.data?.categoria=="LENTE DE CONTACTO"?
        <div className={"size-form justify-start lg:justify-center items-end w-full "}>
          {PropsInv.data.tablas.map((nombre_Tabla, index) => (
            <div className="flex flex-col justify-center items-center mx-2" key={index} >
              <p hidden={PropsInv.data.tablas.length<=1 } className="w-full font-bold text-xl text-center" >
                {PropsInv.data?.colores?extractCol(nombre_Tabla):""}
              </p>
              <div className="relative w-full " onContextMenu={e=>{e.preventDefault()}}>
                <Tablefromjson
                  key={index}
                  linea={nombre_Tabla}
                  data={{ nombre_Tabla: PropsInv.data.schema[nombre_Tabla] }}
                /> 
              </div>
            </div> 
          ))}
        </div>
        :
        <div className={"size-form justify-start lg:justify-center items-end w-full "}>
          {PropsInv.data.tablas.map((nombre_Tabla, index) => (
            <div className="flex flex-col justify-center items-center" key={index} >
              <div className="relative h-full md:h-95/100" onContextMenu={e=>{e.preventDefault()}}>
                <TablaArmazonInvAux
                  linea={nombre_Tabla}
                  data={PropsInv.data.schema[nombre_Tabla] }
                /> 
              </div>
            </div> 
          ))}
        </div>}
      </div> 
    );
  };

  const mapDispatchToProps = dispatch => ({
    setPropsInventario:(data)=> dispatch({type:"SET_PROPSINVENTARIO", estado:data}),
  })

  
  const mapStateToProps = (state) => ({
      inventario: state.Inventario,
      PropsInv: state.PropsInventario,
  })
  

  export default connect(mapStateToProps,mapDispatchToProps)(WrapperTable)