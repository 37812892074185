import React, { useState, useEffect} from "react";
import {  FechaLocalToUTC, FechaUTCToLocal, getArrayAsync, getSucursal, getTotal, tipoCelda } from "../../../services/functions";
import Modalinfo from "../../modal/info";
import { connect } from "react-redux";
import LoadingModal from "../../modal/LoadingModal";
import { setRuta, setRutaRep } from "../../../redux/Actions";
import TableReactWindows from "../Cash/TableReactWindows";

const ReporteMarcas = ({PropsReportes,setHeader,Datos,setCleanInventario,propsInventario,setPropsInventario,addRutaGlobal}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [inventario,setInventario]=useState([]);
  const nameComponent = "inventarioMarca",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const filtroFecha =  [FechaLocalToUTC("01-01-2023"),FechaLocalToUTC(PropsReportes.fecha_Final,"23","59","59")]

  const handleError=(error)=>{closemodalLoading();}
  const closeModalinfo=(e)=> { setmodalinfoConfig({ isOpen: false });}
  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };

  
  useEffect(() => {
    if(rutaActualRep!==nameComponent){return;}
    const getInventario= async()=>{
      const peticiones =[{
        indexName:"sparseIndex",
        hashKey:{valor:"COMPRA#"+getSucursal()+"#LINEA#"+Datos[nameComponent][1].linea,nombre:"statusTemp"},
        sortKey:{valor:filtroFecha,operador:"between",nombre:"id"},
      },{
        indexName:"sparseIndex",
        hashKey:{valor:"VENTA#"+getSucursal()+"#LINEA#"+Datos[nameComponent][1].linea,operador:"eq",nombre:"statusTemp"},
        sortKey:{valor:filtroFecha,operador:"between",nombre:"id"},
      }];
      try{
        openmodalLoading();
        const compras = await getArrayAsync(peticiones[0],[]);
        const ventas = await getArrayAsync(peticiones[1],[]);
        const newRegistro = {merma:0,devolucion:0,descripcion:"",marca:"",categoria:"",vendido:0,comprado:0,precioCompra:0,monto:0,cantTotal:0,before:0,total:0,fecha:""};
        const balance ={};
  
        compras.forEach(compra=>{
          const id =compra.type.replace('COMPRA#PRODUCT#','');
          const fechaCompra = FechaUTCToLocal(compra.id).fecha;
          if(!balance?.[id]){ balance[id]={...newRegistro,id:id,descripcion:compra.descripcion,tipo:compra.tipo,marca:compra.marca.replace('#'+getSucursal(),'')}}
          if(fechaCompra<PropsReportes.fecha_Inicial){
            balance[id].before += compra.cantidadComprada;
            balance[id].monto += compra.precioCompra*compra.cantidadComprada;
          }else{
            balance[id].comprado += compra.cantidadComprada;
            balance[id].monto += compra.precioCompra*compra.cantidadComprada;
          }
        })

  
        ventas.forEach(venta=>{
          const id =venta.type.replace('VENTA#PRODUCT#','');
          const fechaCompra = FechaUTCToLocal(venta.id).fecha;
          if(!balance?.[id]){ balance[id]={...newRegistro,id:id,descripcion:venta.descripcion,marca:venta.marca.replace('#'+getSucursal(),'')}}
          if(fechaCompra<PropsReportes.fecha_Inicial){
            balance[id].before -= venta.cantidadVendida;
            balance[id].monto -= venta.precioCompra*venta.cantidadVendida;
          }else{
            balance[id].vendido += venta.cantidadVendida;
            balance[id].monto -= venta.precioCompra*venta.cantidadVendida;
          }
        })

        Object.keys(balance).forEach(key=>{
          balance[key].cantTotal = balance[key].before + balance[key].comprado + balance[key].merma - balance[key].vendido - balance[key].devolucion
          balance[key].precioCompra = balance[key].monto / balance[key].cantTotal 
        })

        //console.log(balance)
        let newArreglo = Object.values(balance);

        setInventario(newArreglo);
        closemodalLoading();

      } catch (error){
        closemodalLoading();
        handleError(error)
      }
    }
    const datos = Datos[nameComponent]||{};
    if(JSON.stringify(datos)=='{}'){
      getInventario();
    }else{
      if(PropsReportes.fecha_Inicial==datos[1].fechai&&PropsReportes.fecha_Final==datos[1].fechaf){
        setInventario(datos[0]);
        return;
      }
      getInventario();
    }
  }, [PropsReportes.fecha_Inicial,PropsReportes.fecha_Final]);


  useEffect(()=>{
    if(rutaActualRep!==nameComponent){return;}
    console.log('kung fu')
     setHeader({text:"Inventory by Line"})
  },[])


  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
          <TableReactWindows
            columns={[
              {
                key:"id",
                header:"Style",
                width:150,
                type:tipoCelda.text,
                filter:true,
                // eslint-disable-next-line react/display-name
                content:({row,index})=>(
                  <div className="w-full flex flex-wrap justify-between ">
                    <label>{row.id}</label>
                    {row.tipo=="LINEA"?//['MI','LC','AR'].includes(row?.id.substring(0,2))
                    <button
                    className="text-blue-600 text-xs"
                    onClick={e=>{
                      setCleanInventario();
                      const data =  {...propsInventario.data,id:row.id,schema:{},tablas:[]};
                      setPropsInventario({...propsInventario,sucursales:[getSucursal()], data:data,items:[]});
                      addRutaGlobal("micas");
                    }}
                    >
                      <i className={(row?.id.substring(0,2)=="MI"||row?.id.substring(0,2)=="LC"?"fas fa-border-all":"fas fa-glasses")}/>
                    </button>
                    :null}
                  </div>
                )
              },
              {
                key:"descripcion",
                header:"Desc",
                width:370,
                type:tipoCelda.text,
                filter:true,
              },
              {
                key:"before",
                header:"QohBgn",
                width:90,
                type:tipoCelda.number,
                filter:true,
                footer:true,
                function: getTotal
              },
              {
                key:"comprado",
                header:"Rcvd",
                width:90,
                className:"text-blue-800",
                type:tipoCelda.number,
                filter:true,
                footer:true,
                function: getTotal
              },
              {
                key:"devolucion",
                header:"Return",
                width:90,
                className:"text-pink-800",
                type:tipoCelda.number,
                filter:true,
                footer:true,
                function: getTotal
              },
              {
                key:"merma",
                header:"Decrease",
                width:90,
                className:"text-pink-800",
                type:tipoCelda.number,
                filter:true,
                footer:true,
                function: getTotal
              },
              {
                key:"vendido",
                header:"Sold",
                width:90,
                className:"text-pink-800",
                type:tipoCelda.number,
                filter:true,
                footer:true,
                function: getTotal
              },
              {
                key:"cantTotal",
                header:"QohEnd",
                width:90,
                type:tipoCelda.number,
                filter:true,
                footer:true,
                function: getTotal
              },
              {
                key:"monto",
                header:"Amount",
                width:120,
                type:tipoCelda.money,
                filter:true,
                footer:true,
                function: getTotal
              },
              {
                key:"precioCompra",
                header:"UnitCost",
                width:100,
                type:tipoCelda.money,
                filter:true,
              },
              {
                key:"marca",
                header:"Brand",
                width:140,
                type:tipoCelda.text,
                filter:true,
              },
            ]}
            data={inventario}
          />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),

  setCleanInventario: () => dispatch({type: 'RESET_INVENTARIO'}),
  setPropsInventario:(data)=> dispatch({type:"SET_PROPSINVENTARIO", estado:data}),
  addRutaGlobal:(ruta)=> dispatch(setRuta(ruta))
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Categorias: state.Catalogos.CATEGORIAS.opciones,
  Datos: state.Datos,
  propsInventario: state.PropsInventario,
});

export default connect (mapStateToProps,mapDispatchToProps)(ReporteMarcas);
