import {  GetFechaActualSeparado, getSucursal } from '../../services/functions';
import Xlsx from 'xlsx-populate';

export const columnasXlsx = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',  'U', 'V', 'W', 'X', 'Y', 'Z'],
formatoCelda={contabilidad: {numberFormat:'[$$-409]_(* #,##0.00_);_([$$-409]_(* (#,##0.00);_([$$-409]_(* "-"??_);_(@_)'},number:{numberFormat:'_-* #,##0_-;-* #,##0_-;_-* "-"_-;_-@_-'}};

const ano =GetFechaActualSeparado().ano,mes=GetFechaActualSeparado().mes,dia=GetFechaActualSeparado().dia;

export const GenerateXlsx=async()=> {
  try {
    return await Xlsx.fromBlankAsync();
  } catch (error) {
    return {error: error.message || error};
  }
}

export const SaveXlsx=async(workbook,btnFileName,noDefaultFileName)=>{
  try {
    workbook.sheet(0)._sheetPrNode.children.push({
      name: "pageSetUpPr",
      children: [ ],
      attributes: {
          fitToPage: '1',
      }
  });
  
  workbook.sheet(0)._node.children.push({
      name: "pageSetup",
      children: [ ],
      attributes: {
          paperSize: '9',
          orientation: 'landscape',
          fitToWidth: '1',
          fitToHeight: '0',
      }
  });

    const out = await workbook.outputAsync({ type: "blob" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(out, btnFileName+".xlsx");
    } else{
      var url = window.URL.createObjectURL(out);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = btnFileName+(noDefaultFileName?"":"-"+getSucursal()+"-"+(ano+mes+dia))+".xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } 
     return true
  } catch (error) {
    return {error : error.message || error || "changos"}
  }
}
