import React from "react";

const Modalbase = (props) => {
  return (
    <div className="inline-block align-middle items-end justify-center min-h-screen px-4 mt-20 sm:mt-0 pb-20 text-center sm:block sm:p-0">
      {/*
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    */}
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span
        className="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>
      {/*
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    */}
      <div
        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg  size-w-40"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        {props.children}
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
Modalbase.Body = (props) => (
  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
    <div className="sm:flex sm:items-start">{props.children}</div>
  </div>
);

// eslint-disable-next-line react/display-name
Modalbase.Buttons = (props) => (
  <div className="px-4 pb-1 sm:px-6 sm:flex sm:flex-row-reverse gap-1">
    {props.children}
  </div>
);

// eslint-disable-next-line react/display-name
Modalbase.Bodytext = (props) => (
  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
    {props.children}
  </div>
);

// eslint-disable-next-line react/display-name
Modalbase.Message = (props) => (
  <div className="mt-2">
    <p className={" text-base text-center "}>{props.children}</p>
  </div>
);

// eslint-disable-next-line react/display-name
Modalbase.Title = (props) => (
  <h4
    className="text-lg leading-6 font-medium text-gray-900 items-center text-center w-full"
    id="modal-headline"
  >
    {props.children}
  </h4>
);

// eslint-disable-next-line react/display-name
Modalbase.Iconsuccess = (props) => (
  <div className="mx-auto my-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
    <svg
      width="1.8em"
      height="1.8em"
      viewBox="0 0 16 16"
      className="bi bi-x  text-green-600"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
      />
    </svg>
  </div>
);

// eslint-disable-next-line react/display-name
Modalbase.Iconerror = (props) => (
  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
    <svg
      width="1.8em"
      height="1.8em"
      viewBox="0 0 16 16"
      className="bi bi-x  text-red-600"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
      />
      <path
        fillRule="evenodd"
        d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
      />
    </svg>
  </div>
);

// eslint-disable-next-line react/display-name
Modalbase.Iconinfo = (props) => (
  <div className="mx-auto my-auto flex-shrink-0 flex items-center justify-items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
    <svg
      width="1.8em"
      height="1.8em"
      viewBox="0 0 16 16"
      className="bi bi-x  text-blue-600"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
      <circle cx="8" cy="4.5" r="1" />
    </svg>
  </div>
);

// eslint-disable-next-line react/display-name
Modalbase.Iconwarning = (props) => (
  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-200 sm:mx-0 sm:h-10 sm:w-10">
    <svg
      width="1.8em"
      height="1.8em"
      viewBox="0 0 16 16"
      className="bi bi-x  text-yellow-600"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
    </svg>
  </div>
);

export default Modalbase;
