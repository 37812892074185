import React, { useState, useRef, useEffect} from "react";
import { FechaLocalToUTC, FechaUTCToLocal, IdToShortId, OrderArrayAZ, apiUsePromise, getSucursal, getTotal, tipoCelda, valueToMoney2 } from "../../../services/functions";
import { connect } from "react-redux";
import LoadingModal from "../../modal/LoadingModal";
import Modaldanger from "../../modal/danger";
import { useCatalogo } from "../../../Hooks/useApis";
import TableReactWindows from "../Cash/TableReactWindows";
import Modalsuccess from "../../modal/success";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";
import Modalgeneric from "../../modal/generic";

const ReporteComprasLinea = ({config,setModal,setOpenModalCalendar,stateFechas,Categorias}) => {
  const [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [select,setSelect]=useState({}),
  [arrayItems,setArrayItems]=useState([]),
  [fechas,setFechas]=useState(),
  [reporte,setReporte]=useState("lineas");
  const ventasBrand=useRef([]),arrayMarcas=useRef(""),btnGeneric = "btn-light-free border border-gray-500 bg-gray-200 hover:bg-gray-400 h-6 md:h-8";
  const fechaI = FechaLocalToUTC(stateFechas.fecha_Inicial),fechaF=FechaLocalToUTC(stateFechas.fecha_Final,23,59,59);

  const handleError = (error) => {
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  }


  const closeModal=()=> {
    setFechas({...fechas,fecha_Inicial:stateFechas.fecha_Inicial,fecha_Final:stateFechas.fecha_Final});
    setModal({ isOpen: false });
  }
  const openModal=()=>{
    setModal({ isOpen: true });
  }
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }


  function closeModaldangererror(e) {
      const newdataconfig = { ...modaldangererrorConfig };
      newdataconfig.isOpen = false;
      setmodaldangererrorConfig(newdataconfig);
  }
  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };

  const getCompras=async(peticion,datos,petLast)=>{
    if(petLast){ peticion.LastEvaluatedKey = petLast;}

    const responseReporte = await apiUsePromise({ url: "/custom-query", method: "POST",data: peticion})
    datos = datos.concat(responseReporte.data.datos);

    if(responseReporte.data?.LastEvaluatedKey){
      datos = await getCompras(peticion,datos,responseReporte.data.LastEvaluatedKey);
    }
    return datos
  }

  const getComprasAsync =async() => {
    if(arrayMarcas.current.length==0){return;}
    const peticiones =[],repLineas={},lineas=[],lotes=5;
    let totalResp=[];
    arrayMarcas.current.forEach(marca=>{
      peticiones.push({
      indexName:"asociadoIndex",
      hashKey:{valor:"COMPRA#"+getSucursal()+"#MARCA#"+marca,nombre:"sujetoAsociado"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"id"}
      });
    })

    ventasBrand.current = [];
    try{
      let array=[];

      openmodalLoading();
      for(let i=0; i<peticiones.length; i+=lotes){
        const lote = peticiones.slice(i,i+lotes);
        const requests =  lote.map(async (peticion, index)=>{
          let datos=[];
          await new Promise(resolve=> setTimeout(resolve,index));
          datos = await getCompras(peticion,datos);
          return datos
        });
        const respuestas = await Promise.all(requests);
        totalResp = totalResp.concat(respuestas);
        await new Promise(resolve=>setTimeout(resolve,200));
      }

      totalResp.forEach(resp=>{
        const datos = resp||[];
        datos.forEach(dato=>{
          const marca = dato.marca.split("#")[0] || "",
          linea = dato.linea.split('#')[0] || "",
          clave = dato.type.split("#")[2],
          ticket= IdToShortId(dato.id);

          if(!repLineas[linea]){
            repLineas[linea] = {
              linea:linea,
              cantidad:dato.cantidadComprada,
              importe:dato.importe
            };
          }else{
            repLineas[linea].cantidad += dato.cantidadComprada;
            repLineas[linea].importe += dato.importe;
          }

          array.push({
            clave:clave,
            cantidad:dato.cantidadComprada,
            precioCompra:dato.precioCompra,
            importe:dato.importe,
            marca:marca,
            linea:linea,
            descripcion:dato.descripcion,
            categoria:Categorias[clave.substring(0,2)],
            ticket:ticket,
            fecha: dato.id
          })
        })
      });

      Object.values(repLineas).sort((fa,fb)=>fa.linea.toString().localeCompare(fb.linea.toString())).forEach(rep=>{
        if(rep.cantidad>0){lineas.push(rep)}
      })

      setArrayItems(array);
      ventasBrand.current =lineas;
      closemodalLoading();
    }catch(error){
      closemodalLoading();
      handleError(error)
    }
  }


  const getTable=()=>{
    if(reporte=="lineas"){
      return <TableReactWindows
      btnDownload={async(e,data)=>{
        if(!data?.length){handleError("No hay datos que exportar"); return;}
        openmodalLoading();
        const workb = await GenerateXlsx();
        if(workb?.error){
          closemodalLoading();
          handleError(workb.error);
          return
        }

        const nameFile="ComprasLineas",
        headerFile="Reporte compras por Lineas del "+stateFechas.fecha_Inicial+" al "+stateFechas.fecha_Final,
        sheet1 = workb.sheet(0),sheet2=workb.addSheet("All"),colPlus = 4;

        sheet1.cell("A1").value(headerFile);
        sheet1.cell("A1").style("bold",true);
        sheet1.cell("A1").style("fontSize",18);
    
        const sizeData = data.length + colPlus,
        header={'Linea':13,'Cant':10,'Total':17};
    
        Object.keys(header).map((key,index)=>{
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet1.column(columnasXlsx[index]).width(header[key]); 
        })

        data.forEach((venta,index) => {
          sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.linea||"");
          sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.cantidad||0);
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(venta?.importe||0));
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
        });
    
        sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.contabilidad);


        sheet2.cell("A1").value("Reporte de compras del "+stateFechas.fecha_Inicial+" al "+stateFechas.fecha_Final);
        sheet2.cell("A1").style("bold",true);
        sheet2.cell("A1").style("fontSize",18);

        const header2={'Item':25,'Descripcion':70,'Costo':17,'Cantidad':10,'Total':17,'Compra':17,'Fecha':12,'Linea':20,'Marca':20},
        items = OrderArrayAZ([...arrayItems],'fecha','string');
        let cont=0;

        Object.keys(header2).map((key,index)=>{
          sheet2.cell(columnasXlsx[index]+(colPlus-1)).value(key);
          sheet2.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet2.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet2.column(columnasXlsx[index]).width(header2[key]); 
        })

        items.forEach((venta,index) => {
          sheet2.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.clave||"");
          sheet2.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.descripcion||"");
          sheet2.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(venta?.precioCompra||0));
          sheet2.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);

          sheet2.cell(columnasXlsx[3]+(colPlus+index)).value(venta?.cantidad||0);
          sheet2.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.number);
          sheet2.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(venta?.importe||0));
          sheet2.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);

          sheet2.cell(columnasXlsx[5]+(colPlus+index)).value(venta?.ticket||"");
          sheet2.cell(columnasXlsx[6]+(colPlus+index)).value(FechaUTCToLocal(venta?.fecha).fecha||"");
          sheet2.cell(columnasXlsx[7]+(colPlus+index)).value(venta?.linea||"");
          sheet2.cell(columnasXlsx[8]+(colPlus+index)).value(venta?.marca||"");
          cont++;
        });
    
        sheet2.cell(columnasXlsx[3]+""+(cont+colPlus)).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(cont+colPlus-1)+")");
        sheet2.cell(columnasXlsx[3]+""+(cont+colPlus)).style(formatoCelda.number);
        sheet2.cell(columnasXlsx[4]+""+(cont+colPlus)).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(cont+colPlus-1)+")");
        sheet2.cell(columnasXlsx[4]+""+(cont+colPlus)).style(formatoCelda.contabilidad);

        const response =await SaveXlsx(workb,nameFile);
        if(response?.error){
          closemodalLoading();
          handleError(response.error);
          return
        }
        closemodalLoading();
        openModalSuccess("Archivo generado correctamente","Descarga Completa");
      }}
      columns={[
        {
          key:"linea",
          header:"Line",
          width:140, 
          type: tipoCelda.text,
          className:"text-left text-blue-800",
          filter:true,
          onClick:({e,row})=>{
            if(e.detail==2){
              let filter=[];
              filter = OrderArrayAZ(arrayItems.filter(item=>(item.linea==row.linea)),'fecha','string');
              setSelect({...select,items:filter,linea:row.linea});
              setReporte("items")
            }
          }
        },
        {
          key:"cantidad",
          header:"Qty",
          width:50, 
          type: tipoCelda.number,
          filter:true,
          footer:true,
          function: getTotal,
        },
        {
          key:"importe",
          header:"Total",
          width:120, 
          type: tipoCelda.money,
          filter:true,
          footer:true,
          function: getTotal,
        },
      ]}
      data={ventasBrand.current}
      />
    }
    if(reporte=="items"){
      return <TableReactWindows
      btnDownload={async(e,data)=>{
        if(!data?.length){handleError("No hay datos que exportar"); return;}
        openmodalLoading();
        const workb = await GenerateXlsx();
        if(workb?.error){
          closemodalLoading();
          handleError(workb.error);
          return
        }

        const nameFile="Compras "+select.linea,
        headerFile="Reporte compras "+select.linea+" del "+stateFechas.fecha_Inicial+" al "+stateFechas.fecha_Final,
        sheet1 = workb.sheet(0),colPlus = 4;

        sheet1.cell("A1").value(headerFile);
        sheet1.cell("A1").style("bold",true);
        sheet1.cell("A1").style("fontSize",18);
    
        const sizeData = data.length + colPlus,
        header={'Item':25,'Descripcion':70,'Costo':17,'Cantidad':10,'Total':17,'Compra':17,'Fecha':12};
    
        Object.keys(header).map((key,index)=>{
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet1.column(columnasXlsx[index]).width(header[key]); 
        })

        data.forEach((venta,index) => {
          sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.clave||"");
          sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.descripcion||"");
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(venta?.precioCompra||0));
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);

          sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(venta?.cantidad||0);
          sheet1.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.number);
          sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(venta?.importe||0));
          sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);

          sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(venta?.ticket||"");
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(FechaUTCToLocal(venta?.fecha).fecha||"");
        });
    
        sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[3]+sizeData).style(formatoCelda.number);
        sheet1.cell(columnasXlsx[4]+sizeData).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[4]+sizeData).style(formatoCelda.contabilidad);

        const response =await SaveXlsx(workb,nameFile);
        if(response?.error){
          closemodalLoading();
          handleError(response.error);
          return
        }
        closemodalLoading();
        openModalSuccess("Archivo generado correctamente","Descarga Completa");
      }}
      columns={[
        {
          key:"clave",
          header:"Item",
          width:150, 
          type: tipoCelda.text,
          filter:true
        },
        {
          key:"descripcion",
          header:"Description",
          width:350,
          type:tipoCelda.text,
          filter:true
        },
        {
          key:"precioCompra",
          header:"UnitCost",
          width:90,
          type:tipoCelda.money,
          filter:true
        },
        {
          key:"cantidad",
          header:"Qty",
          width:70, 
          type: tipoCelda.number,
          filter:true,
          footer:true,
          function: getTotal,
        },
        {
          key:"importe",
          header:"Total",
          width:120, 
          type: tipoCelda.money,
          filter:true,
          footer:true,
          function: getTotal,
        },
        {
          key:"ticket",
          header:"Rcvng",
          width:100,
          type:tipoCelda.text,
          filter:true
        },
        {
          key:"fecha",
          header:"Date",
          width:90,
          type:tipoCelda.date,
          filter:true
        }
      ]}
      data={select.items}
      />
    }
  }

  useEffect(()=>{
    if(config.isOpen==false){return}
    if(stateFechas?.fecha_Inicial==fechas?.fecha_Inicial&&stateFechas?.fecha_Final==fechas?.fecha_Final){return;}
    if(arrayMarcas.current?.length==0){
      openmodalLoading();
      useCatalogo("MARCAS",resp=>{
        closemodalLoading();
        if(!resp?.opciones){
          handleError();
            return;
        }
        arrayMarcas.current = Object.values(resp.opciones)
        getComprasAsync(); 
      },handleError)
      return
    }
    getComprasAsync(); 
  },[config?.isOpen,stateFechas.fecha_Inicial,stateFechas.fecha_Final]);


  return (
    <>
     <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

     <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <Modalgeneric w="w-full" h="size-h-90" front="z-10" config={config}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>{'Compras por Lineas del '+stateFechas.fecha_Inicial +' al '+stateFechas.fecha_Final }</Modalgeneric.Title>
          <div className="relative w-full h-95/100">
            {getTable()}
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button className="btn-gray2" onClick={e=>{
            closeModal();
          }}>
            Cerrar
          </button>
          <button 
            className={btnGeneric} 
            type="button"
            onClick={e=>{
              setOpenModalCalendar(true);
            }}>
              <i className='fas fa-calendar-day text-black-700'></i>
          </button>
          {reporte=="items"?
            <button 
            className={btnGeneric} 
            type="button"
            onClick={e=>{
              setReporte('lineas');
            }}>
              <i className='fas fa-arrow-circle-left text-black-700'></i>
          </button>:null}
        </Modalgeneric.Buttons>
      </Modalgeneric>
    </>
  );
};


const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
  Categorias: state.Catalogos.CATEGORIAS.opciones
});

export default connect (mapStateToProps,null)(ReporteComprasLinea);
9