import React, { useState, useEffect} from "react";
import { connect } from "react-redux";
import { redondeo, getNombre,Sucursal, getIdUser, getSucursal, havePermission } from "../../services/functions";
import { setRuta, resetProducto } from "../../redux/Actions";
import Labelmoney from "../../components/labelmoney";
import Modaldanger from "../modal/danger";
import Modalsuccess from "../modal/success";
import { useInsertCotizacion, useInsertVenta } from "../../Hooks/useApis";
import Modalinfo from "../modal/info";
import { permisos } from "../../static/dat/datos";

const Totales = (props) => {
  const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalinfoCotizacion, setmodalinfoCotizacion] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({});
  const ivaSucursal = Sucursal("iva")?Sucursal("iva")/100:0.0;
  const sumador = (acumulador, currentValue) => acumulador + currentValue.importe;
  const sumadorutilidad = (acumulador, currentValue) => acumulador + parseInt(currentValue.utilidad);
  const sumadorSubtotal = (total, producto)=> total + parseFloat(producto.precio*producto.cantidad);
  const contador = (acumulador, currentValue) => acumulador + parseInt(currentValue.cantidad);
  var total = redondeo(props.itemsVenta.reduce(sumador, 0));
  var utilidadtotal = props.itemsVenta.reduce(sumadorutilidad, 0);
  var subTotal = redondeo(props.itemsVenta.reduce(sumadorSubtotal,0));
  const totaliva = redondeo(props.itemsVenta.reduce((a,item)=>a+((item.precio-(item.precio/(1+ivaSucursal)))*item.cantidad),0)),
  numArticulos = props.itemsVenta.reduce(contador, 0),
  permiso_caja = havePermission(permisos.cobrar);

  let venta = {
    sucursal: getSucursal(),
    cliente: props.cliente.id,
    vendedor: getIdUser(),
    nombreVendedor: getNombre(),
    nombreCliente: props.cliente.nombre,
    items: props.itemsVenta,
    iva: totaliva,
    numeroArticulos: numArticulos,
    total: total,
    descuentoTotal: subTotal - total,
    subtotal: subTotal,
    utilidad: utilidadtotal
  };
  if (props.cliente?.cotizado){ venta['cotizacion'] = props.cliente.cotizado; }

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }
  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }
  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false }); 
    props?.setInputFocus?props.setInputFocus(!props.inputFocus):null; 
  }
  function closeModalinfoCotizacion(e) {
    setmodalinfoCotizacion({ isOpen: false });
     props?.setInputFocus?props.setInputFocus(!props.inputFocus):null; 
  }


  const handleSuccessful = (data) => {
    //
    venta.id = data.short_id;
    //
    props.SetTicket(venta);
    props.resetItem();
    props.addClient({});
    navegar("ticketCotizacion");
  };
  const handleVentaSuccessful = (data) => {
    //
    venta.id = data.shortId;
    setmodalSuccessConfig({
      isOpen: true,
      title: "Ticket "+ data.shortId,
      message: data?.mensaje?.message,
      data: venta,
    });
    const cliente = {...props.Clientes,venta:{shortId:data.shortId}}
    props.addClienteVenta(cliente);//

    props.SetTicket(venta);
    props.resetItem();
    props.addClient({});
    
  };
  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  const handleKeyPress=(e)=>{
    if(e.key=="Escape"){
      e.preventDefault();
      closeModalsuccess(e);
      return;
    }
  }

  function PagarVenta(e) {
    if(e.detail>1){return}
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
    if(permiso_caja){
      props.addRuta("caja");
    }
  }

  const cerrarVenta = () => {
    let newItems = [];

    venta.items.map(articulo=>{
      const itemPush = {
        id:articulo.id,
        cantidad: articulo.cantidad,
        utilidad: articulo.utilidad,
        precio: articulo.precio,
        precioCompra: articulo.sucursales[getSucursal()].precioCompra,
        descuento: articulo.descuento,
        descripcion: articulo.descripcion,
        importe: articulo.importe,
        categoria: articulo.categoria,
        marca: articulo.marca,
        linea: articulo.linea
      }
      if(articulo.cantidad>articulo.sucursales[getSucursal()].existencia){
        itemPush.existenciaCero= articulo.sucursales[getSucursal()].existencia;
      }
      if(articulo.categoria=="SERVICIO"){
        itemPush.agregados = articulo.agregados||[]; 
        delete itemPush.existenciaCero;
      }
      newItems.push(itemPush);
    })
    venta.items = newItems;
    //console.log(venta)
    useInsertVenta(venta,handleVentaSuccessful,handleError);
  };

  const cotizar = () => {
    let venta_aux = {...venta};
    let items = [];
    venta.items.map(item=>{
      const item_aux = {...item};
      delete item_aux.descuentoMax;
      delete item_aux.margen;
      delete item_aux.minPrecioVenta;
      items.push(item_aux)
    })
    venta_aux.items = items;
    
    if(!props.HeaderVenta?.itemsold&&!props.HeaderVenta?.cliente&&venta.cliente&&venta_aux.items.length){
      useInsertCotizacion(venta_aux,handleSuccessful,handleError); 
      return; 
    }

    let olditems=[],itms=[];

     props.HeaderVenta.itemsold.forEach(element => {
      const new_item= {...element};
      delete new_item.sucursales;
      olditems.push(new_item);
    });
    venta.items.forEach(element => {
      const new_item= {...element};
      delete new_item.minPrecioVenta;
      delete new_item.precio;
      delete new_item.descuentoMax;
      delete new_item.margen;
      delete new_item.importe;
      delete new_item.importe;
      delete new_item.utilidad;
      delete new_item.sucursales;

      itms.push(new_item);
    });


    if(!props.HeaderVenta?.itemsold||props.HeaderVenta?.type!==("COTIZACION#"+getSucursal())||!props.HeaderVenta?.cliente){  return; }//si el cliente de la cotizacion es difente al cliente actual o no hay items viejos
    if(props.HeaderVenta.cliente!==venta?.cliente){ return}

    if(JSON.stringify(olditems) === JSON.stringify(itms)){
      //console.log('imp copia');
      venta.id = props.HeaderVenta.id;
      props.SetTicket(venta);
      props.resetItem();
      props.addClient({});
      props.resetHeaderVenta();
      navegar("ticketCotizacion");
      return;
    }else{
      venta_aux.update = true;
      venta_aux.idCotizacion = props.HeaderVenta.fecha;
      //console.log('venta>>',venta_aux)
    }
    useInsertCotizacion(venta_aux,handleSuccessful,handleError);
  };

  const navegar = (ruta) => {
    props.addRuta(ruta);
  };

  useEffect(() => {
    if([true,false].includes(props?.closeSale)&&props.cliente.nombre&&venta.items.length>0){
      setmodalinfoConfig({
        isOpen: true,
        message: "",
      });
    }
  }, [props?.closeSale])

  useEffect(() => {
    if([true,false].includes(props?.cerrarCotizacion)&&props.cliente.nombre&&venta.items.length>0){
      setmodalinfoCotizacion({
        isOpen: true,
        message: "",
      });
    }
  }, [props?.cerrarCotizacion])

  useEffect(() => {
    if([true,false].includes(props?.cleanVenta)){
      props.resetItem();
      props.addClient({});
      props.resetHeaderVenta();
    }
  }, [props?.cleanVenta])
  
  

  return (
    <div tabIndex={"0"} onKeyDown={handleKeyPress}>
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Desea Finalizar la Venta?"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-gray1"
            name="modalinfo"
            tabIndex={"101"}
            onClick={closeModalinfo}
          >
            No
          </button>
          <button 
            type="button"
            className="btn-green"
            name="modalinfo"
            tabIndex={"100"}
            onClick={e=>{
              if(e.detail>1){return}
              closeModalinfo(e);
              cerrarVenta();
            }}
            >
              Si
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modalinfo bg="bg-bluei" config={modalinfoCotizacion}>
        <Modalinfo.Message title={"Desea Crear una Cotizacion?"}>
          {modalinfoCotizacion.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-gray1"
            name="modalinfo"
            tabIndex={"101"}
            onClick={closeModalinfoCotizacion}
          >
            No
          </button>
          <button 
            type="button"
            className="btn-green"
            name="modalinfo"
            tabIndex={"100"}
            onClick={e=>{
              if(e.detail>1){return}
              closeModalinfoCotizacion(e);
              cotizar();
            }}
            >
              Si
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modalsuccess closModal={closeModalsuccess} tab="200" config={modalSuccessConfig}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={(permiso_caja?"btn-green ":"hidden")}
            onClick={PagarVenta}
          >
            Pagar
          </button>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"201"}
            className={"btn-blue"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
      <div className=" size-form w-full justify-between items-center">
        <div className="size-3 items-center gap-1 mb-1 md:mb-0">
            <button
              className="btn-red1 w-full "
              disabled={!props.cliente.nombre}
              onClick={(e) => {
                props.resetItem();
                props.addClient({});
                props.resetHeaderVenta();
                props?.setInputFocus?props.setInputFocus(!props.inputFocus):null;
              }}
              style={{ transition: "all .15s ease" }}
            >
              Limpiar
            </button>
            <button
              className="btn-gray2 whitespace-nowrap text-size border w-full"
              disabled={!props.cliente.nombre}
              title="F7"
              onClick={(e) => {
                if(e.detail>1){return}
                cotizar();
              }}
              style={{ transition: "all .15s ease" }}
            >
              Imprimir Cotización
            </button>

            <button
              className="btn-blue w-full"
              title="F5"
              disabled={!props.cliente.nombre}
              onClick={(e) => {
                if(e.detail>1){ return; }
                cerrarVenta();
              }}
              style={{ transition: "all .15s ease" }}
            >
              Guardar
            </button>
        </div>
        <div className="grid grid-cols-2 grid-rows-1 pb-1 px-1 font-bold md:w-4/12 bg-blue-800 text-3xl rounded-xl shadow-md leading-none">
          <div className="grid-flow-row px-1 text-white text-right sm:text-left">
            <p >SubTotal</p>
            <p >Descuento</p>
            <p >Total</p>
          </div> 
          <div className="grid-flow-row px-1 text-white text-right sm:text-left ">
            <Labelmoney twoDecimal={true} className="font-bold w-full">{subTotal}</Labelmoney>
            <Labelmoney twoDecimal={true} className="font-bold w-full">{subTotal - total}</Labelmoney>
            <Labelmoney twoDecimal={true} className="font-bold w-full">{total}</Labelmoney>
            {/* <h5 className="text-white rounded-lg text-right sm:text-left"><Labelmoney twoDecimal={true} className="font-bold w-full">{totaliva}</Labelmoney></h5> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  itemsVenta: state.itemsVenta,
  cliente: state.Clientes,
  HeaderVenta: state.HeaderVenta,
});

const mapDispatchToProps = (dispatch) => ({
  addRuta: (ruta) => dispatch(setRuta(ruta)),
  SetTicket: (datos) =>
    dispatch({
      type: "SET_TICKET",
      ticket: datos,
    }),
  resetItem: (item) => dispatch(resetProducto(item)),
  addClient: (cliente) =>
    dispatch({
      type: "ADD_CLIENT",
      item: cliente,
    }),
  addClienteVenta: (cliente) =>
    dispatch({
      type: "ADD_CLIENTVENTA",
      item: cliente,
    }),
  resetHeaderVenta: ()=> dispatch({type:"CLEAN_HEADER_VENTA"}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Totales);
