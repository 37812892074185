import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { redondeo, getSucursal, getIdUser, getNombre, } from "../../services/functions";
import { useGetBalanceProveedor, useInsertCompra, useInsertTraspaso, useUpdateCompra} from '../../Hooks/useApis';
import Modalsuccess from "../../components/modal/success";
import Labelmoney from "../labelmoney";
import Modaldanger from "../modal/danger";
import { useRef } from "react";
import LoadingModal from "../modal/LoadingModal";
import { returnRuta, setRuta } from "../../redux/Actions";

const TotalesCompra = (props) => {
  const [totalPagado,setTotalPagado] = useState(0), 
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const title = useRef("Cargando Informacion");

  const sumador = (acumulador, currentValue) =>acumulador + (currentValue.id!==""?currentValue.importe:0);
  const contador = (acumulador, currentValue) =>acumulador + (currentValue.id!==""?parseInt(currentValue.cantidad):0);
  var total =  parseInt(redondeo(props.itemsCompra.reduce(sumador, 0)));
  const numArticulos = props.itemsCompra.reduce(contador, 0);


  useEffect(() => {
    if(props.HeaderCompra?.importe && props.HeaderCompra?.pagos){
      setTotalPagado(props.HeaderCompra.pagos.reduce((a, b) => parseInt(a) + parseInt(b.monto), 0));
    }
  }, [props.HeaderCompra])

  const handleKeyPress=(e)=> {
      if(e.key=="Escape"&&modalSuccessConfig?.isOpen){
        props.setOldReferencia();
        setmodalSuccessConfig({ isOpen: false, botoncredito: false });
      }
  }

  const resetDatos=()=>{
    props.setCleanItem();
    props.setCleanHeaderCompra();
    props.setCleanProveedor();
    props.setInventario0();
  }

  const handleSuccessfulPagar=(data)=>{
    closemodalLoading();
    resetDatos();
    setmodalSuccessConfig({
      isOpen: true,
      title: (data?.short_id?data.short_id:"Exito"),
      message: "Compra Guardada con Exito",
    });
  }

  const handleSuccessfulTraspaso=(resp)=>{
    closemodalLoading();
    resetDatos();
    setmodalSuccessConfig({
      isOpen: true,
      title: (resp?.short_id?resp.short_id:"Exito"),
      message: "Devolucion Guardada con Exito",
    });
  }

  const handleErrorDatos=(data)=>{
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: "Debe seleccionar la sucursal destino" ,
    });
  }
  const handleErrorPagar=(error)=>{
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message || error?.message || "aqui va otro mensaje de error" ,
    });
  }
  const handleSuccessfulProv=(data)=>{
    const balance = {...data[0]};
    balance.totalCompras = balance.comprasCredito.reduce((a, b) => parseInt(a) + parseInt(b.importe||0), 0);
    balance.credito = balance.proveedor.creditoSucursales[getSucursal()] || 0;
    balance.pagado = balance.pagosAp.reduce((a, b) => parseInt(a) + (b.importe<0?b.importe*-1:b.importe), 0);

    const creditoDisponible = (balance.credito - balance.totalCompras + balance.pagado);

    if(total<=creditoDisponible){
      let hayLineas =false;
      const oldItems= [...props.itemsCompra]
      let itemsCompra = [];
      oldItems.forEach(product=>{
        if(product.index!==0){itemsCompra.push(product)  }
        else if(product.id!==""&&product.precioCompra>0&&product.cantidad>0&&product.importe>0&&product.descripcion!==""){
          itemsCompra.push(product)
        }
      });
  
      itemsCompra.forEach(item =>{ if(item.type=="LINEA"){hayLineas = true;return; }})
      //itemsCompra.forEach(item =>{ item.oldPreciocompra = item.precioCompra })
  
      let compra = {
        sucursal: getSucursal(),
        proveedor: props.Proveedor.id,
        nombreProveedor: props.Proveedor.nombre,
        referencia: props.HeaderCompra?.referencia || "",
        fechaReferencia: props.HeaderCompra.fechaReferencia,
        personal: getIdUser(),
        nombrePersonal: getNombre(),
        cantidadTotal: numArticulos,
        importe: (total-props.HeaderCompra.descuento+props.HeaderCompra.envio),
        subtotal: total,
        //utilidad: utilidadtotal,
        items: itemsCompra,
        inventarioPendiente:hayLineas,
        pagos: [],
        descuento : props.HeaderCompra.descuento,
        envio: props.HeaderCompra.envio
      };
      //console.log(itemsCompra);
      //return
      useInsertCompra(compra,handleSuccessfulPagar,handleErrorPagar);
    }else{
      closemodalLoading();
      setmodaldangererrorConfig({
        isOpen: true,
        title: "Venta a credito",
        message: (
          <p className="text-center items-center">
            No es posible convertir esta venta a credito. El monto se excede
            por{" "}
            <Labelmoney>
              {total - creditoDisponible}
            </Labelmoney>{" "}
          </p>
        ),
      });
    }
  }

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }
  const funcionError=(error)=>{
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  }
  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true, title });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };

  return (
  <div className="size-form w-full h-full my-1 justify-center" onKeyDown={handleKeyPress}>
        <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>{title.current}</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
        <Modalsuccess config={modalSuccessConfig}>
        <Modalsuccess.Message title={modalSuccessConfig.title} message={modalSuccessConfig.message}>
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-green"
            name="modalsuccess"
            tabIndex={"101"}
            onClick={e=>{
              props.setOldReferencia();
              props.setPropsRep({...props.PropsReportes,update:true})
              setmodalSuccessConfig({ isOpen: false, botoncredito: false });
            }
            }
          >
            Cerrar
          </button>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"100"}
            className="btn-gray1"
            onClick={e=>{
              props.delRuta();
              props.setPropsRep({...props.PropsReportes,update:true})
            }}
          >
            Salir
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
      
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>
    <button
      className="btn-gray2 mr-1 "
      disabled={total === 0}
      onClick={(e) => {
       resetDatos();
      }}
      style={{ transition: "all .15s ease" }}
    >
      Restablecer Datos
    </button>
    <button
      className="btn-blue1 mr-1"
      disabled={total === 0}
      hidden={totalPagado === props.HeaderCompra?.importe}
      onClick={(e) => {
        if(e.detail>1){return}
        if(!props.HeaderCompra?.id){
          
          props.setCantidadTotalCompra(numArticulos);
          props.setImporteCompra(total)
        }
        props.addRuta("PagosCompra"); 
      }}
      style={{ transition: "all .15s ease" }}
    >
      {"Realizar Pago"}
    </button>
    <button
      className={props.HeaderCompra?.id||total === 0?"hidden":"btn-gray2 "}
      onClick={(e) => {
        if(e.detail>1){return}
        openmodalLoading();
        if(props.HeaderCompra.traspaso){
          if(!props.HeaderCompra?.sucursalDestino){
            handleErrorDatos();
            return;
          }
          let hayLineas =false;
          const oldItems= [...props.itemsCompra]
          const itemsCompra =oldItems.filter(product=> product.id!=="");
      
          itemsCompra.forEach(item =>{ if(item.type=="LINEA"){hayLineas = true;return; }})
  
          const traspaso = {
            sucursal: getSucursal(),
            proveedor: props.Proveedor.id,
            nombreProveedor: props.Proveedor.nombre,
            referencia: props.HeaderCompra?.referencia || "",
            fechaReferencia: props.HeaderCompra.fechaReferencia,
            personal: getIdUser(),
            nombrePersonal: getNombre(),
            cantidadTotal: numArticulos,
            importe: (total-props.HeaderCompra.descuento+props.HeaderCompra.envio),
            subtotal: total,
            //utilidad: utilidadtotal,
            items: itemsCompra,
            inventarioPendiente:hayLineas,
            descuento : props.HeaderCompra.descuento,
            envio: props.HeaderCompra.envio,
            sucursalDestino: props.HeaderCompra.sucursalDestino
          };

          useInsertTraspaso(traspaso,resp=>{
            //console.log(resp)
            handleSuccessfulTraspaso(resp);
          },error=>{
            handleErrorPagar(error);
          });
          return;
        }
        useGetBalanceProveedor(props.Proveedor.id,handleSuccessfulProv,funcionError);
      }}
      style={{ transition: "all .15s ease" }}
    >
      {"Finalizar Compra"}
    </button>
    <button
      className="btn-dark hover:bg-cyan-600 bg-cyan-500"
      disabled={total === 0}
      hidden={(props.HeaderCompra?.id?false:true)}
      onClick={(e) => {
        if(e.detail>1){return}
        openmodalLoading();
        const peticion = {
          id:props.HeaderCompra.id,
          sucursal:getSucursal(),
          pagos:[],
          lineas: props.HeaderCompra?.lineas || [],
          inventarioPendiente: props.HeaderCompra.inventarioPendiente,
          referencia: props.HeaderCompra.referencia,
          fechaReferencia: props.HeaderCompra.fechaReferencia,
      };
        useUpdateCompra(peticion,handleSuccessfulPagar,handleErrorPagar)
      }}
      style={{ transition: "all .15s ease" }}
    >
      Actualizar Compra
    </button>
  </div>
  );
};

const mapStateToProps = (state) => ({
  itemsCompra: state.itemsCompra,
  Proveedor: state.SearchProveedor,
  HeaderCompra: state.HeaderCompra,
  PropsReportes: state.PropsReportes,
});

const mapDispatchToProps = (dispatch) => ({
  delRuta:()=>dispatch(returnRuta()),
  addRuta: (ruta) => dispatch(setRuta(ruta)),
  setCleanItem:() => dispatch({type:"CLEAN_ITEMCOMPRA"}),
  setCleanHeaderCompra:() => dispatch({type:"CLEAN_HEADERCOMPRA"}),
  setCleanProveedor:() => dispatch({type:"CLEAN_PROVEEDOR"}),
  setInventario0:() => dispatch({type: "RESET_PROPSINVENTARIO"}),
  setCantidadTotalCompra:(cantidadTotal)=> dispatch({type:"SET_CANTIDADTOTALCOMPRA",cantidadTotal:cantidadTotal}),
  setImporteCompra:(importe)=>dispatch({type:"SET_IMPORTECOMPRA",importe:importe}),
  setOldReferencia: () => dispatch({type:"SET_OLDREFERENCIA_COMPRA"}),

  setPropsRep:(props)=> dispatch({type:"SET_PROPS_REP",props:props}),
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalesCompra);
