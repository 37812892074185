import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";

const TableModal = (props) => {
  const onclickRow = props.onclickRow || ((item,e) => {});
  const columns = props.columns || [{}];
  const footer = props.footer || [{}];
  const data = props.data || [];
  const [field, setfield] = useState(""),
  [desc, setdesc] = useState(false),
  [countTouches,setCountTouches]=useState({}),
  [input, setinput] = useState("");
  const searchinput = props.searchinput === undefined ? true : props.searchinput;
  const input_search = useRef(),rowIndex=useRef(10);

  const name = props?.name||"tablemodal";

  function byfield(a, b) {
    if (desc) {
      if (a[field] > b[field]) return 1;
      if (a[field] < b[field]) return -1;
    } else {
      if (a[field] < b[field]) return 1;
      if (a[field] > b[field]) return -1;
    }

    return 0;
  }

  function searchByAnyField(item) {
    let newsearch = input.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&').toUpperCase();
    //(input[0]?(input[0]=="+"?"\\"+input:input):input);
    for (const value of Object.values(item)) {
      if (typeof value === "string" || value instanceof String) {
        if (value.toUpperCase().search(newsearch) !== -1) {
          return true;
        }
      }
    }
  }

  const handleKeyPress=(e)=>{
    if(e.key=="ArrowDown"){
      const elements = document.getElementsByName(name); 
      if(rowIndex.current >= elements.length+9){
        rowIndex.current = 10;
      }else{
        rowIndex.current ++;
      }
    
      elements.forEach(element => {
        if(element.tabIndex==rowIndex.current){
          element.focus();
        }
      });     
      return;
    }
    if(e.key=="ArrowUp"){
      const elements = document.getElementsByName(name); 
      if(rowIndex.current <= 10){
        rowIndex.current = elements.length+10;
      }else{
        rowIndex.current --;
      }
      elements.forEach(element => {
        if(element.tabIndex==rowIndex.current){
          element.focus();
        }
      });
      return;
    }
  }
  
  useEffect(() => {
    if(props?.config?.isOpen){
      input_search?.current?.focus();
      setinput("");
    }
  }, [props?.config?.isOpen])

  useEffect(() => {
    if(countTouches?.count%2==0&&JSON.stringify(countTouches?.item)==JSON.stringify(countTouches?.olditem)){
      const e = {detail:2}
      onclickRow(countTouches.item,e);
    }
  }, [countTouches])
  
  

  return (
    <div tabIndex={"1"} onKeyDown={handleKeyPress} className={"flex flex-col break-words overflow-x-auto overflow-y-auto w-full mx-auto landscape:mt-0 "+(props?.className?props.className:"h-90/100")}>
      {searchinput ? (
        <div className="mt-1 flex sm:flex-row-reverse flex-col ">
          <div className="block right-0 mr-1">
            <input
              type="text"
              autoFocus={true}
              className="border-form focus:shadow-outline uppercase landscape:h-7"
              name="buscar"
              autoComplete="off"
              ref={node=>{input_search.current=node}}
              value={input}
              onKeyPress={e=>{
                if(e.key=='Enter'&&props?.onEnterProduct&&data){
                  const filtro = data.filter((item) => searchByAnyField(item)).sort(byfield);
                  if(filtro.length==1){
                    props.onEnterProduct(filtro[0],e);
                  }
                }
              }}
              onChange={(e) => {
                setinput(e.target.value);
              }}
              placeholder="Buscar"
              style={{ transition: "all .15s ease" }}
            />
          </div>
        </div>
      ) : null}
      <div className="overflow-x-auto overflow-y-auto mt-1 relative flex flex-col min-h-0 max-h-screen min-w-0  max-w-screen-2xl mb-1 break-words rounded bg-white border-0">
        <table>
          <thead className="sticky top-0 rounded bg-transparent text-white text-sm font-bold align-middle cursor-pointer uppercase">
            <tr>
              {columns
                ? columns.map((item, index) => (
                    <th
                      key={index}
                      onClick={(e) => {
                        setdesc(!desc);
                        setfield(item.accessor);
                      }}
                      className={"py-1 px-2 whitespace-nowrap bg-blue-800 "+(index==0?" rounded-l-lg":(index==columns.length-1?" rounded-r-lg":" border-l-2 border-r-2"))}
                      data-priority={index}
                    >
                      {item.header}
                    </th>
                  ))
                : null}
            </tr>
          </thead>
          <tbody >
            {data
              ? data
                  .filter((item) => searchByAnyField(item))
                  .sort(byfield)
                  .map((item, index1) => (
                    <tr
                      key={index1}
                      tabIndex={index1+10}
                      name={name}
                      className={(index1 % 2 === 0 ? "bg-gray-100" : "bg-white") +" cursor-pointer hover:bg-indigo-100 focus:bg-indigo-200 focus:outline-none focus:ring-2 h-7 lg:h-8 text-size"}
                      onKeyPress={e=>{
                        onclickRow(item,e);
                      }}
                      onClick={(e) => {
                        onclickRow(item,e);
                      }}
                      onTouchStart={e=>{
                        const touch = countTouches?.count?countTouches?.count:0;
                        setCountTouches({count:touch+1,item:item,olditem:countTouches?.item});
                      }}
                    >
                      {props.columns.map((itemcolumn, index2) => (
                        <td  key={index2} className={ itemcolumn.width +" border-t-0 px-2 align-middle border-l-0 border-r-0 font-size "}>
                          {itemcolumn.code
                            ? itemcolumn.code(item)
                            : (!itemcolumn.space?
                              <input className="w-full whitespace-nowrap font-size outline-none bg-transparent cursor-pointer" readOnly={true} value={item[itemcolumn.accessor]}></input>
                              :<input className="bg-transparent w-full outline-none cursor-pointer font-size text-size" value={item[itemcolumn.accessor]} readOnly={true}/>
                            )}
                        </td>
                      ))}
                    </tr>
                  ))
              : null}
          </tbody>
          <tfoot className=" bottom-1 rounded-lg ">
          <tr className="">
              {footer
                ? footer.map((item, index) => (
                    <th
                      key={index}
                      className={item.width+  " px-2 align-middle border-l-2 border-r-2" }
                      data-priority={index}
                    >
                      {item.code}
                    </th>
                  ))
                : null}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default TableModal;