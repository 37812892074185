import React, { useEffect, useRef, useState } from 'react'
import TableReactWindows from '../Reportes/Cash/TableReactWindows'
import { FechaUTCToLocal, getTotal, tipoCelda } from '../../services/functions'
import InputPrecioPanel from '../InputPrecioPanel'
import InputSelectormap from '../Productos/InputSelectormap'
import { ListaLaboratorio } from '../../static/dat/datos'
import Modalinfo from '../modal/info'
import Toggle from '../Toggle.jsx'
import {motion} from "framer-motion"
import { btnScale, btnTap } from '../../static/motionStyle.js'
import Modalgeneric from '../modal/generic.js'
import Selectormap from '../Productos/Selectormap.jsx'
import { connect } from 'react-redux'
import LabelErrors from '../LabelErrors.jsx'

const Bitacora=({Motivos})=> {

  const [modalInfo, setmodalInfo] = useState({}),
  [modalGarantia,setModalGarantia]=useState({isOpen:false}),
  [datos,setDatos]=useState([
    {
      "fecha": "2023-06-01T12:34:56.789",
      "codigo":"GDMOL",
      "nombre": "Juan Perez",
      "entrada": "entrada 1",
      "material": "POLICARBONATO",
      "cantidad": 15,
      "laboratorio": "BISEL",
      "folio": "1234",
      "observaciones": "Observación sobre el proceso.",
      "personal": "Ana Martínez",
      "tipo":"NORMAL",
    },
    {
      "fecha": "2023-06-02T09:21:36.789",
      "codigo":"GDOPT",
      "nombre": "Luis Gómez",
      "entrada": "entrada 2",
      "material": "CR-39",
      "cantidad": 22,
      "laboratorio": "TALLADO",
      "folio": "2345",
      "observaciones": "Observación sobre el material.",
      "personal": "Carlos Sánchez",
      "tipo":"URGENTE",
    },
    {
      "fecha": "2023-06-03T15:45:12.789",
      "codigo":"GDGUDG",
      "nombre": "Marta López",
      "entrada": "entrada 3",
      "material": "CRISTAL",
      "cantidad": 10,
      "laboratorio": "BISEL",
      "folio": "3456",
      "observaciones": "Observación sobre la cantidad.",
      "personal": "Elena Ruiz",
      "tipo":"NORMAL",
    },
    {
      "fecha": "2023-06-04T11:11:11.789",
      "codigo":"GDMOL",
      "nombre": "Juan Perez",
      "entrada": "entrada 4",
      "material": "HI-INDEX",
      "cantidad": 5,
      "laboratorio": "TALLADO",
      "folio": "4567",
      "observaciones": "Observación sobre la calidad.",
      "personal": "Fernando Morales",
      "tipo":"NORMAL",
    },
    {
      "fecha": "2023-06-05T14:22:33.789",
      "codigo":"GDSAR",
      "nombre": "Sara Díaz",
      "entrada": "entrada 5",
      "material": "CR-39",
      "cantidad": 18,
      "laboratorio": "BISEL",
      "folio": "5678",
      "observaciones": "Observación sobre el tiempo.",
      "personal": "Laura Castro",
      "tipo":"NORMAL",
      "garantia":"INVERTIDO"
    },
    {
      "fecha": "2023-06-06T16:44:55.789",
      "codigo":"GDDT",
      "nombre": "David Torres",
      "entrada": "entrada 6",
      "material": "HI-INDEX",
      "cantidad": 9,
      "laboratorio": "TALLADO",
      "folio": "6789",
      "observaciones": "Observación sobre el recibo.",
      "personal": "Ricardo Herrera",
      "tipo":"NORMAL",
    },
    {
      "fecha": "2023-06-07T13:33:22.789",
      "codigo":"GDIHM",
      "nombre": "Isabel Hernández",
      "entrada": "entrada 7",
      "material": "POLICARBONATO",
      "cantidad": 25,
      "laboratorio": "BISEL",
      "folio": "7890",
      "observaciones": "Observación sobre el producto.",
      "personal": "María Blanco",
      "tipo":"URGENTE",
    },
    {
      "fecha": "2023-06-08T10:00:00.789",
      "codigo":"GDCGR",
      "nombre": "Carlos García",
      "entrada": "entrada 8",
      "material": "CR-39",
      "cantidad": 12,
      "laboratorio": "TALLADO",
      "folio": "8901",
      "observaciones": "Observación sobre el pedido.",
      "personal": "Lucía Martínez",
      "tipo":"NORMAL",
    },
    {
      "fecha": "2023-06-09T17:18:27.789",
      "codigo":"GDPM",
      "nombre": "Patricia Méndez",
      "entrada": "entrada 9",
      "material": "CRISTAL",
      "cantidad": 30,
      "laboratorio": "BISEL",
      "folio": "9012",
      "observaciones": "Observación sobre el cliente.",
      "personal": "Antonio Rodríguez",
      "tipo":"NORMAL",
    },
    {
      "fecha": "2023-06-10T08:09:08.789",
      "codigo":"GDAO",
      "nombre": "Alberto Ortiz",
      "entrada": "entrada 10",
      "material": "HI-INDEX",
      "cantidad": 7,
      "laboratorio": "TALLADO",
      "folio": "0123",
      "observaciones": "Observación sobre el envío.",
      "personal": "Rosa Alonso",
      "tipo":"NORMAL",
      "garantia":"MAL CALCULADO"
    }
  ]),
  [errors,setErrors]=useState({'categoria':{}});
  const inputCliente = useRef(""), inputMaterial=useRef(""),inputCantidad=useRef(""),inputLaboratorio=useRef(""),inputFolio=useRef(""), inputObservacion=useRef(""),inputPersonal=useRef(""),inputTipo=useRef("");
  const selectMotivo = useRef("");


  const openModalInfo=(titulo,message)=>{setmodalInfo({isOpen:true,title:titulo||"Datos Incorrecto",message:message||""})}
  const closeModalInfo=()=>{setmodalInfo({isOpen:false})}

  const openModalGarantia=(itemSelect)=>{setModalGarantia({isOpen:true,itemSelect:itemSelect})}
  const closeModalGarantia=()=>{setModalGarantia({isOpen:false})}

  const validaDatos=()=>{
    if(!inputCliente.current.value||!inputMaterial.current.value){
      openModalInfo("Falta algun campo por rellenar","Cliente/Material");
      return;
    }
  }

  const cleanErrors=()=>{
    setErrors({'categoria':{}})
  }
  
  return (
    <>
    <Modalgeneric w="size-w-30" h="size-h-30" config={modalGarantia} closeModal={closeModalGarantia} front="z-40" tab="400">
      <Modalgeneric.Body>
        <div className='flex flex-col gap-2 items-center'>
          <div className='flex flex-wrap w-full gap-2 justify-center items-center'>
            <label htmlFor='categoria'>Motivo</label>
            <Selectormap 
            width="w-40"
            catalogo={Motivos}
            //clean={!itemSelect}
            name="categoria"
            inputRef={selectMotivo}/>
            <LabelErrors name="categoria" errors={errors}/>
          </div>
        </div>
      </Modalgeneric.Body>
      <Modalgeneric.Buttons>
        <button className='btn-gray2' 
        name="modalgeneric"
        tabIndex="400"
        onClick={e=>{
          closeModalGarantia();
        }}>
          Cerrar
        </button>
        <button className='btn-green'
        name="modalgeneric"
        tabIndex="401"
        onClick={e=>{
          if(!selectMotivo?.current?.value){
            const newErrors = {...errors}
            newErrors['categoria'].message="Seleccione algun motivo valido";
            setErrors(newErrors)
            return
          }
          const newItem = {...modalGarantia.itemSelect};
          const newDatos = [...datos];
                
          setDatos(newDatos.map(dato=>(dato.fecha==newItem.fecha?{...newItem,garantia:selectMotivo.current.value}:dato)))
          cleanErrors();
          closeModalGarantia();
        }}>
          Aplicar
        </button>
      </Modalgeneric.Buttons>
    </Modalgeneric>
    <Modalinfo config={modalInfo} front="z-30" tab="300">
        <Modalinfo.Message title={modalInfo.title}>
          {modalInfo.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalInfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
    <div className='w-full h-full bg-white  '>
      <div className='w-full flex flex-col py-1 '>
        <div className='flex flex-wrap gap-2 items-center'>
          <label className='font-semibold'>Cliente</label>
          <input className='border-form w-28 uppercase' ref={inputCliente} />

          <label className='font-semibold'>Material</label>
          <input className='border-form w-48 uppercase' ref={inputMaterial} />

          <label className='font-semibold'>Cantidad</label>
          <InputPrecioPanel noShowSignal={true} className={"w-16"} min={10000} form={true} inputRef={inputCantidad} 
          onBlur={e=>{}} 
          />
          
          <label className='font-semibold'>Laboratorio</label>
          <InputSelectormap
           catalogo={ListaLaboratorio} 
           inputRef={inputLaboratorio}
           className="w-28"/>

          <label className='font-semibold'>Folio</label>
          <input className='border-form w-20 uppercase' ref={inputFolio} />

          <label className='font-semibold'>Observ</label>
          <input className='border-form w-44 uppercase' ref={inputObservacion} />

          <label className='font-semibold'>Folio</label>
          <input className='border-form w-20 uppercase' ref={inputFolio} />
          
          <button className='btn-green'
          onClick={e=>{
            validaDatos();
          }}>Agregar</button>

        </div>
       
      </div>
      <div className='relative flex flex-wrap w-full overflow-x-auto overflow-y-auto h-90/100'>
      <TableReactWindows
        rowHeight={25}
        bgHeader="bg-blue-800 text-white"
        columns={[
          {
            key:"fecha",
            header:"Fecha Entrada",
            width:120,
            type: tipoCelda.date,
            filter:true,
            // eslint-disable-next-line react/display-name
            content:({row,index})=>(
              <div className='relative flex items-center justify-around'>
                <p>{FechaUTCToLocal(row.fecha).fecha=="Invalid Date"?"-":FechaUTCToLocal(row.fecha).fecha}</p>
                <motion.button className='text-red-500'
                whileHover={btnScale}
                whileTap={btnTap}
                onClick={e=>{
                  if(e.detail==2){
                    alert('Seguro que desea eliminar el registro:'+row.codigo)
                  }
                }}>
                  <i className='fas fa-minus-circle'></i>
                </motion.button>
              </div>
            )
          },

          {
            key:"nombre",
            header:"Nombre",
            width: 250,
            type: tipoCelda.text,
            filter:true
          },
          {
            key:"codigo",
            header:"Cliente",
            width:80,
            type:tipoCelda.text,
            filter:true
          },
          {
            key:"hora",
            header:"Entrada",
            width:80,
            type:tipoCelda.text,
            filter:true
          },
          {
            key:"material",
            header:"Material",
            width:120,
            type:tipoCelda.text,
            filter:true
          },
          {
            key:"cantidad",
            header:"Cant",
            width:50,
            type:tipoCelda.number,
            filter:true,
            footer: true,
            function: getTotal
          },
          {
            key:"laboratorio",
            header:"Laboratorio",
            width:120,
            type:tipoCelda.text,
            filter:true
          },
          {
            key:"folio",
            header:"Folio",
            width:80,
            type:tipoCelda.text,
            filter:true
          },
          {
            key:"observaciones",
            header:"Observ",
            width:250,
            type:tipoCelda.text,
            filter:true
          },
          {
            key:"personal",
            header:"Recibe",
            width:100,
            type:tipoCelda.text,
            filter:true
          },
          {
            key:"tipo",
            header:"Tipo",
            width:100,
            type:tipoCelda.text,
            className:"font-bold",
            bgCol:{campo:"tipo",valor:"URGENTE",verdadero:"text-red-600"},
            filter:true,
          },
          {
            key:"garantia",
            header:"Garantia",
            width:200,
            className:"font-bold justify-center flex",
            filter:true,
            // eslint-disable-next-line react/display-name
            content:({row,index})=>(
              //<Toggle size="xs" />
              row.garantia?
               <p>{row.garantia}</p>:
                <motion.button className="rounded-full bg-white text-blue-800 relative " 
                whileHover={btnScale}
                whileTap={btnTap}
                onClick={e=>{openModalGarantia(row);}}
                >
                  <i className='fas fa-plus-circle fa-lg'></i>
                </motion.button>
              
            )

          }
          
        ]}
        data={datos}
      />
      </div>
    </div>
    </>)

}

const mapStateToProps = (state) => ({
  Motivos: state.Catalogos.GARANTIAS,
});

export default connect (mapStateToProps,null)(Bitacora)