import React, { useMemo, useState } from "react";
import Modalgeneric from "../modal/generic";

const Categorias = (props) => {
  const saveChange = props.savechange || [],
  [data, setdata] = useState([]),
  [modalgenericonfig, setmodalgenericonfig] = useState({}),
  [registro, setregistro] = useState({}),
  [invalid,setInvalid] = useState(false),
  [utilidad,setUtilidad] = useState(props.utilMin),
  [editRegistro,setEditRegistro] = useState({});

  const openmodalgeneric = (e) => {
    setmodalgenericonfig({ isOpen: true });
  };
  const closemodalgeneric = (e) => {
    setmodalgenericonfig({ isOpen: false });
    setregistro({});
  };

  const closesavemodalgeneric = () => {
    let newArray = [...data], newRegistro ={};
    (Object.keys(editRegistro).length!==0)?newRegistro={...editRegistro}:newRegistro = {...registro};

    const index = newArray.findIndex((cuenta) => JSON.stringify(cuenta)===JSON.stringify(newRegistro));
    index > -1? (newArray[index] = registro): newArray.push(newRegistro);

    saveChange(newArray);
    setdata(newArray);
    setmodalgenericonfig({ isOpen: false });
    setregistro({});
    setEditRegistro({});
  };

  const editItem = (cuenta) => {
    setEditRegistro(cuenta);
    setregistro(cuenta);
    setUtilidad(Object.values(cuenta)[0]);
    openmodalgeneric(0);
  };

  useMemo(() => {
    saveChange(props.data || [{"MICA": props.utilMin},{ "ARMAZON": props.utilMin},{"LENTE DE CONTACTO": props.utilMin},{"EQUIPO LABORATORIO": props.utilMin},{"EQUIPO OPTICO": props.utilMin},{"ACCESORIO": props.utilMin}]);
    setdata(props.data || [{"MICA": props.utilMin},{ "ARMAZON": props.utilMin},{"LENTE DE CONTACTO": props.utilMin},{"EQUIPO LABORATORIO": props.utilMin},{"EQUIPO OPTICO": props.utilMin},{"ACCESORIO": props.utilMin}]);
  }, [props.data]);

  return (
    <>
      <Modalgeneric w="size-w-40" h="size-h-40" config={modalgenericonfig}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Entrada del historial</Modalgeneric.Title>
          <div className="flex flex-col items-center mt-2 w-full" >
            <label className="text-lg font-semibold">Categoria</label>
            <label className="border-form w-full pt-1 text-center">{Object.keys(registro)[0]}</label>

            <label className="text-lg font-semibold">Margen Minimo de Utilidad</label>
            <input
            type="number"
            min={props.utilMin}
            className={"border-form uppercase w-full lg:w-1/2 " + (invalid?"bg-red-200":"bg-white")}
            onChange={(e) => {
                setUtilidad(parseInt(e.target.value));
                if(parseInt(e.target.value)<props.utilMin){setInvalid(true);return;}
                setInvalid(false);
            }}
            onBlur={e=>{
                if(parseInt(e.target.value)<props.utilMin){return;}
                let newReg = {};
                newReg[Object.keys(registro)[0]]=  parseInt(e.target.value);
                setregistro(newReg);
            }}
            value={utilidad}
            ></input>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            className="btn-blue"
            onClick={(e) => {
                if(utilidad<props.utilMin){return;}
                closesavemodalgeneric();
            }}
          >
            Guardar
          </button>
          <button
            type="button"
            className="btn-gray2"
            onClick={closemodalgeneric}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <div className="w-full flex flex-col items-center  mx-auto " >
        <div className="w-full px-0 ">
          <div className={"flex flex-col items-center relative border "+(props?.bg?"border-teal-700":"border-blue-900")}>
            <div className="w-full ">
              <div
                className={"my-2 p-1  bg-white rounded focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3  transition-colors duration-200 ease-in-out mb-2 max-h-select overflow-y-auto focus:"+(props?.bg?"border-teal-500":"border-indigo-500")}
                style={{ height: "20vh" }}
              >
                {data.map((item, index) => (
                  <div key={index} className="flex flex-auto ">
                    <div
                      className={"cursor-pointer flex justify-center items-center my-1 rounded font-medium text-white border border-gray-300 hover:"+(props?.bg?"bg-teal-600 bg-teal-800":"bg-blue-600 bg-blue-900")}
                      onClick={(e) => {
                        editItem(item);
                      }}
                    >
                      <div className=" leading-none max-w-full flex-initia py-1 px-2 ">
                        {Object.keys(item)[0]}
                      </div>
                      <label className="font-medium text-gray-700 bg-white borde py-1 px-2" >
                      {Object.values(item)[0]}
                    </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default Categorias;
