import React, { useEffect } from "react";
import Modalbase from "./base";

const Modalsuccess = (props) => {

  const handleKeyPress=(e)=>{
    if(e.key=="Escape"&&props?.config?.isOpen&&props?.closeModal){
      props.closeModal();
    }
  }

  useEffect(() => {
    if(props.config?.isOpen==true){ 
      const elements = document.getElementsByName("modalsuccess"); 
      const tab = props?.tab||"100";
      elements.forEach(element => {
        //console.log(element.tabIndex,'<>',tab)
        if(element.tabIndex==tab){
          //console.log('first')
          element.focus();
        }
      });
    }
  }, [props.config])

  return (
    <div tabIndex={"0"} onKeyDown={handleKeyPress} className={"inset-0 "+(props.config.isOpen ? "fixed " : "hidden ") + (props?.front?props.front:" z-50 ")} >
      <Modalbase>{props.children}</Modalbase>
    </div>
  );
};

// eslint-disable-next-line react/display-name
Modalsuccess.Message = (props) => {
  const message = props.message||"";
  const title = props.title || "";

  return (
    <Modalbase.Body>
      <Modalbase.Iconsuccess />
      <Modalbase.Bodytext>
        <Modalbase.Title>{title}</Modalbase.Title>
        <Modalbase.Message>{props.children ?? message}</Modalbase.Message>
      </Modalbase.Bodytext>
    </Modalbase.Body>
  );
};

// eslint-disable-next-line react/display-name
Modalsuccess.Body = (props) => {
  const title = props.title || "";

  return (
    <Modalbase.Body>
      <Modalbase.Iconsuccess />
      <Modalbase.Bodytext>
        <Modalbase.Title>{title}</Modalbase.Title>
        {props.children}
      </Modalbase.Bodytext>
    </Modalbase.Body>
  );
};

// eslint-disable-next-line react/display-name
Modalsuccess.Buttons = (props) => (
  <Modalbase.Buttons>{props.children}</Modalbase.Buttons>
);

export default Modalsuccess;
