import React from "react";

const LoadingModal = (props) => {
  return (
    props.config?.isOpen?
    <div className={ "fixed" + " inset-0 mt-8 lg:mt-10 " +(props?.z?props.z:"z-40")}>
      <div className="align-middle items-center justify-center p-0 md:px-4 px-2 my-4  sm:mt-0  text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity mt-8 lg:mt-10" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-600 opacity-75 "></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        {/*
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    */}
        <div
          className="inline-block items-center align-bottom rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-4 sm:align-middle sm:max-w-lg  md:max-w-4xl w-50/100  "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {props.children}
        </div>
      </div>
    </div>
    :null
  );
};
// eslint-disable-next-line react/display-name
LoadingModal.Buttons = (props) => (
  <div className={"px-2 py-1 sm:px-6 sm:flex sm:flex-row-reverse gap-2 "+(props?.bg?props.bg:"bg-gray-50")}>
    {props.children}
  </div>
);
// eslint-disable-next-line react/display-name
LoadingModal.Body = (props) => (
  <div className={"bg-white px-1 pt-2 pb-1 md:pb-4 md:pt-5 md:px-4 "+(props?.bg?props.bg:"bg-white")}>
    <div className="mt-1 text-center sm:mt-0 sm:ml-4 sm:text-left md:mt-3">
    {props.children}
        <div className="flex flex-col justify-between items-center mt-2 w-full h-52">
          <div className="absolute  flex flex-col min-w-0 break-words w-full mb-8 rounded-lg group bg-transparent">
            <div className="flex flex-wrap mx-auto items-center justify-between">
              <div className=" text-white p-3 text-center items-center justify-center w-32 h-32 mb-5 shadow-none rounded-full bg-transparent">
                <svg
                  className=" animate-spin  text-blue-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-50"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-100"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        
    </div>
  </div>
);
// eslint-disable-next-line react/display-name
LoadingModal.Title = (props) => (
  <h3
    className=" w-full h-8 text-lg leading-6 font-medium text-center text-gray-900"
    id="modal-headline"
  >
    {props.children}
  </h3>
);

export default LoadingModal;
