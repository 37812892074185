import React from 'react';
import Container from '../components/Container';

const Tallado = () => {
  return (
    <Container>
    </Container>
  )
}

export default Tallado