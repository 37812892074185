import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { FechaLocalToUTC, FechaUTCToLocal, IdToShortId, apiUsePromise, filtroValoresUnicos, getArrayAsync, getSucursal, tipoCelda, valueToMoney2} from '../../../services/functions';
import Modaldanger from '../../modal/danger';
import LoadingModal from '../../modal/LoadingModal';
import TableReactWindows from '../Cash/TableReactWindows';
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from '../../Excel/DataToXlsx';
import Modalsuccess from '../../modal/success';

const ReporteAPDetail = ({PropsReportes,addDatos,Datos,setDatos,MajorAccounts,setHeader}) => {
  const [balanceFilter,setBalanceFilter]=useState([]),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const new_supplier = { balanceBefore:0,balance:0,id:"-", comprado:0,pagado:0,devolucion:0,envio:0,descuento:0};
  const fechaI = FechaLocalToUTC(PropsReportes.fecha_Inicial),fechaF=FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59);

  const nameComponent = "proveedorBalanceDia",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const datosComponent = Datos[nameComponent]||{};
  const proveedor = datosComponent[1]?.proveedor||"";


  let peticiones =[
    {
      indexName:"asociadoIndex",
      hashKey:{valor:("COMPRA#"+getSucursal())+"#PROVEEDOR#"+proveedor,nombre:"sujetoAsociado"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"id"},
    },
    {
      indexName:"contraCuentaIndex",
      hashKey:{valor:(proveedor+"#"+getSucursal()),nombre:"contracuenta"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    {
      indexName:"reverseIndex",
      hashKey:{valor:("COMPRA_PE#"+proveedor),nombre:"type"},
      sortKey:{valor:[getSucursal()+'#'+fechaI, getSucursal()+'#'+fechaF],operador:"between",nombre:"id"},
    },
    {
      indexName:"asociadoIndex",
      hashKey:{valor:("TRASPASO#"+getSucursal()+'#PROVEEDOR#'+proveedor),nombre:"sujetoAsociado"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"id"},
    },
    {
      indexName:"reverseIndex",
      hashKey:{valor:("PE#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[getSucursal()+'#'+fechaI, getSucursal()+'#'+fechaF],operador:"between",nombre:"id"},
    }
  ];

function getTotal(array,campo) {
  let new_array = [...array];
  new_array = new_array.reduce((a, b) => parseInt(a) + parseInt(b?.[campo]||0), 0)
  return new_array;
}

function SortCompras(compras) {
  const cp = compras.sort((a,b)=>{
    if (a.fecha > b.fecha) return 1;
    if (a.fecha < b.fecha) return -1;
    return 0;
  });
  
  return cp;
}

const getBalanceDay=(reporte,oldB)=>{
  const oldBalance = oldB?oldB:(datosComponent[1]?.balance  || {});
  const registro_Before={...new_supplier,referencia:"Fowared Bal"};
  registro_Before.balance = oldBalance.balanceBefore;
  registro_Before.fecha = fechaI;
  registro_Before.tipo = "";

  SortCompras(reporte);
  reporte.unshift(registro_Before);
  reporte.map((reg,index)=>{
    if(index>0){
      reg.balance=(reporte[index-1]?.balance||0)+((reg?.comprado||0)+(reg?.envio||0)-(reg?.pagado||0)-(reg?.devolucion||0)-(reg?.descuento||0))
    }
  })
}
    
  const handleError = (error) => {
        setmodaldangererrorConfig({
          isOpen: true,
          message: error?.response?.data?.message,
        });
  };

  function closeModaldangererror(e) {
        const newdataconfig = { ...modaldangererrorConfig };
        newdataconfig.isOpen = false;
        setmodaldangererrorConfig(newdataconfig);
  }
  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false });};

  
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }


  
  const getCompras=async(reporte,petLast)=>{
    if(petLast){ peticiones[0].LastEvaluatedKey = petLast;}
    const responseVentas= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[0]});
    const compras = responseVentas.data.datos;

    compras.forEach(compra=>{
      const new_compra={...new_supplier};
      new_compra.fecha = compra.id;
      new_compra.comprado = compra.subtotal;
      new_compra.descuento = compra.descuento;
      new_compra.envio = compra.envio;
      new_compra.referencia = compra.referencia;
      new_compra.id = compra.shortId;
      new_compra.type = 'COMPRA';
      reporte.push(new_compra);
    })

    if(responseVentas.data?.LastEvaluatedKey){ await getCompras(reporte,responseVentas.data.LastEvaluatedKey); }
  }


  const getDevolucion=async(reporte,petLast)=>{
    if(petLast){ peticiones[3].LastEvaluatedKey = petLast;}
    const responseVentas= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[3]});
    const compras = responseVentas.data.datos;

    compras.forEach(compra=>{
      const new_compra={...new_supplier};
      new_compra.fecha = compra.id;
      new_compra.devolucion = compra.subtotal;
      new_compra.envio = compra.descuento;
      new_compra.descuento = compra.envio;
      new_compra.referencia = compra.referencia;
      new_compra.id = compra.shortId;
      new_compra.type = 'DEVOLUCION';
      reporte.push(new_compra);
    })

    if(responseVentas.data?.LastEvaluatedKey){ await getCompras(reporte,responseVentas.data.LastEvaluatedKey); }
  }

  const getPes=async(reporte,petLast)=>{
    if(petLast){ peticiones[2].LastEvaluatedKey = petLast;}
    const responseVentas= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[2]});
    const compras = responseVentas.data.datos;

    compras.forEach(compra=>{
      const new_compra={...new_supplier};
      new_compra.fecha = compra.id.split('#')[1];
      new_compra.comprado = compra.importe;
      new_compra.referencia = compra.folio;
      new_compra.id = IdToShortId(compra.id.split('#')[1]);
      new_compra.type = 'COMPRA PES';
      reporte.push(new_compra);
    })

    if(responseVentas.data?.LastEvaluatedKey){ await getCompras(reporte,responseVentas.data.LastEvaluatedKey); }
  }

  /*const getPesCompare=async(reporte,petLast)=>{
    if(petLast){ peticiones[2].LastEvaluatedKey = petLast;}
    const responseVentas= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[2]});
    const compras = responseVentas.data.datos;

    compras.forEach(compra=>{
      reporte.push({proveedor:compra.proveedor,importe:compra.importe,pes:IdToShortId(compra.id.split('#')[1]),fecha:FechaUTCToLocal(compra.id.split('#')[1]).fecha,type:"COMPRE_PE"})
    })

    if(responseVentas.data?.LastEvaluatedKey){ await getCompras(reporte,responseVentas.data.LastEvaluatedKey); }
  }


  const getPesAux=async(news,petLast)=>{
    if(petLast){ peticiones[4].LastEvaluatedKey = petLast;}
    const responseVentas= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[4]});
    const pes = responseVentas.data.datos;
    //console.log(pes)
    pes.map(p=>{
      p.proveedores.forEach(prov=>{
        if(prov.proveedor==proveedor){
          news.push({proveedor:prov.proveedor,importe:prov.importe,pes:p.shortId,fecha: FechaUTCToLocal(p.id.split('#')[1]).fecha,type:"PE#"})
        }
      })
    })
    if(responseVentas.data?.LastEvaluatedKey){ await getPesAux(news,responseVentas.data.LastEvaluatedKey); }
  }*/


  useEffect(()=>{
    let reporte=[];
    if(rutaActualRep!==nameComponent){return;}

    const getBalanceProveedor=async(oldBalance)=>{
      openmodalLoading();
     
      try{ 
        /*let peses= [],compraspes=[],diff1=[],diff2=[];
        await getPesAux(peses)
        await getPesCompare(compraspes);
        
        console.log('peses: ',peses.length)
        console.log('compraspes: ',compraspes.length)
        peses.forEach(p=>{
          const found = compraspes.filter(cp=>(p.pes==cp.pes))
          if(found.length==0){
            diff1.push(p)
          }
        })

        compraspes.forEach(p=>{
          const found = peses.filter(cp=>(p.pes==cp.pes))
          if(found.length==0){
            diff2.push(p)
          }
        })
        console.log('diff1',diff1)
        console.log('diff2',diff2)

        */
          await getCompras(reporte);
          const cash = await getArrayAsync(peticiones[1],[]);
          
          cash.filter(cash=>['AP','APIN'].includes(cash.clave)).forEach(pago=>{
            const new_compra={...new_supplier};
            new_compra.fecha = pago.type;
            new_compra.referencia = pago.descripcion;
            
            if(MajorAccounts[pago.clave]?.IO=="I"){
              new_compra.comprado = pago.importe;
              new_compra.type = 'CARGO';
            }else{
              new_compra.pagado = pago.importe *-1;
              new_compra.type = 'PAGO';
            }
      
            reporte.push(new_compra);
          })

          await getDevolucion(reporte);
          await getPes(reporte);
        
          if(oldBalance){
            getBalanceDay(reporte,oldBalance);
          }else{
            getBalanceDay(reporte);
          }
          
          setBalanceFilter(reporte);
          let new_Datos = [...datosComponent];
          new_Datos[0] = reporte;
          addDatos(nameComponent,new_Datos)
          closemodalLoading();
      }catch(Error){
          closemodalLoading();
          handleError(Error);
      }
    }

    if(!datosComponent[0]){
      getBalanceProveedor();
    }else{
      if(PropsReportes.fecha_Inicial==datosComponent[1].fechai&&PropsReportes.fecha_Final==datosComponent[1].fechaf&&PropsReportes.header?.proveedor==datosComponent[1]?.proveedor){
        setBalanceFilter(datosComponent[0])
        return;
      }
      const reporte ={balanceBefore:0,comprado:0,pagado:0,devolucion:0,envio:0,descuento:0,balance:0};
      const reportes =datosComponent[1].allRep;
      reportes.forEach(rep=>{
        if(rep.fecha<PropsReportes.fecha_Inicial){
            reporte.balanceBefore += ((rep?.comprado||0)-(rep?.devolucion||0)+(rep?.envio||0)-(rep?.descuento||0)-(rep?.pagado||0));
        }
      })
      getBalanceProveedor(reporte);
      let newDatos = [...datosComponent];
      newDatos[1].fechai = PropsReportes.fecha_Inicial;
      newDatos[1].fechaf =PropsReportes.fecha_Final;
      setDatos(nameComponent,newDatos)
    }
  },[PropsReportes.fecha_Inicial,PropsReportes.fecha_Final,PropsReportes.rutas]);

  useEffect(()=>{
    if(rutaActualRep!==nameComponent){return;}
    if(!Datos[nameComponent][0]){return}
     setHeader({text:"AP Detail "+Datos[nameComponent][1].proveedor})
  },[PropsReportes.rutas])

    

  return (
  <>
    <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Productos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>
      <TableReactWindows
      btnDownload={async(e,datos)=>{
        if(!datos?.length){handleError("No hay datos que exportar"); return;}
        openmodalLoading();
        const workb = await GenerateXlsx();
        if(workb?.error){
          closemodalLoading();
          handleError(workb.error);
          return
        }

        const data = datos||[],
        nameFile = "BalanceAP-"+datosComponent[1].proveedor,
        headerFile = "Reporte balance proveedor "+datosComponent[1].proveedor +" del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final,
        sheet1 = workb.sheet(0),colPlus = 4;

        sheet1.cell("A1").value(headerFile);
        sheet1.cell("A1").style("bold",true);
        sheet1.cell("A1").style("fontSize",18);
    
        const sizeData = data.length + colPlus,
        header={
          'Fecha':12,
          'Descripcion':25,
          'Compra':17,
          'Envio':17,
          'Devolucion':17,
          'Pagado':17,
          'Descuento':17,
          'End Balance':17,
          'Folio Compra':17,
          'Tipo':15
        };
    
        Object.keys(header).map((key,index)=>{
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet1.column(columnasXlsx[index]).width(header[key]); 
        })

        data.forEach((ap,index) => {
          sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(FechaUTCToLocal(ap.fecha).fecha);
          sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(ap?.referencia||"");
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(ap?.comprado||0));
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(valueToMoney2(ap?.envio||0));
          sheet1.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(ap?.devolucion||0));
          sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(ap?.pagado||0));
          sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(ap?.descuento||0));
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(valueToMoney2(ap?.balance||0));
          sheet1.cell(columnasXlsx[7]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(ap.id);
          sheet1.cell(columnasXlsx[9]+(colPlus+index)).value(ap.type);
        });
    
        sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[3]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[4]+sizeData).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[4]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[6]+sizeData).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[7]+sizeData).formula(""+columnasXlsx[2]+sizeData+"+"+columnasXlsx[3]+sizeData+"-"+columnasXlsx[4]+sizeData+"-"+columnasXlsx[5]+sizeData+"-"+columnasXlsx[6]+sizeData);
        sheet1.cell(columnasXlsx[7]+sizeData).style(formatoCelda.contabilidad);

        const response =await SaveXlsx(workb,nameFile);
        if(response?.error){
          closemodalLoading();
          handleError(response.error);
          return
        }
        closemodalLoading();
        openModalSuccess("Archivo generado correctamente","Descarga Completa");
      }}
        columns={[
          {
            key:"fecha",
            header:"Date",
            width:100, 
            type: tipoCelda.date,
            filter:true,
          },
          {
            key:"referencia",
            header: "Description",
            width:300,
            type: tipoCelda.text,
            filter:true,
          },
          {
            key:"comprado",
            header: "Purchase",
            width:120,
            type: tipoCelda.money,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"envio",
            header: "Freigth",
            width:120,
            type: tipoCelda.money,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"devolucion",
            header: "Return",
            width:120,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"pagado",
            header: "Paid",
            width:120,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"descuento",
            header: "Discount",
            width:120,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"balance",
            header: "Balance",
            width:130,
            type: tipoCelda.money,
            footer: balanceFilter?.[balanceFilter.length-1]?.balance
          },
          {
            key:"id",
            header: "#Rcvng",
            width:100,
            type: tipoCelda.text,
            filter:true,
          },
          {
            key:"type",
            header: "Tipo",
            width:100,
            type: tipoCelda.text,
            filter:true,
          }
        ]}
      
        data={balanceFilter}
      />
      </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  setDatos:(nombre,datos)=>dispatch({type:"SET_DATOS",nombre:nombre,datos:datos})
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
  MajorAccounts: state.Catalogos.MAJORACCOUNTS,
});

export default connect (mapStateToProps,mapDispatchToProps)(ReporteAPDetail)