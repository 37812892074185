import React, { useState, useEffect} from "react";
import { FechaUTCToLocal, FechaLocalToUTC, getSucursal, apiUsePromise, tipoCelda, valueToMoney2 } from "../../../services/functions";
import Modalinfo from "../../modal/info";
import { connect } from 'react-redux';
import LoadingModal from "../../modal/LoadingModal";
import TableReactWindows from "../Cash/TableReactWindows";
import { setRutaRep } from "../../../redux/Actions";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";
import Modalsuccess from '../../modal/success';

const ReporteComprasDay = ({setRutaRep,PropsReportes,setHeader,addDatos,Datos,setPropsRep}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [ventas,setVentas]=useState([]);
  const nameComponent = "comprasDay";
  const rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];

  const handleSuccesfulComprasDay=(data)=>{
    return data.datos.reverse();
  }
  const handelSuccesfulTraspasos=(resp)=>{
    let arreglo =[];
    
    if(resp.datos){
      resp.datos.forEach(traspaso=>{
        arreglo.push({...traspaso,importe:traspaso.importe*-1,cantidadTotal:traspaso.cantidadTotal*-1})
      })
    }
    return arreglo;
  }
  const handleError = (error) => {
    closemodalLoading();
  }

  const closeModalinfo=(e)=> {
    setmodalinfoConfig({ isOpen: false });
  }
  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  function getTotal(array,campo) {
    //console.log(array,' campo:',campo,' val:',array[0]?.[campo],'==>',array.reduce((a, b) => parseInt(a) + parseInt(b?.[campo]), 0))
    let new_array = [...array];
    new_array = new_array.reduce((a, b) => parseInt(a) + parseInt(b?.[campo]||0), 0)
    return new_array;
  }

  useEffect(() => {
    if(rutaActualRep!==nameComponent){return;}
    const newPropsRep = {...PropsReportes,header:{btns:{edit:true,detail:true,by:true}}};
    if(newPropsRep?.update){ delete newPropsRep.update;}
    if(newPropsRep.header?.text!=="Receiving by Number"){ newPropsRep.header.text = "Receiving by Number"}

    const datos = Datos[nameComponent]||{};

    const getVentas=async()=>{
      openmodalLoading();
      const fechaI = FechaLocalToUTC(PropsReportes.fecha_Inicial),fechaF = FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59);
      let peticiones =[{
          indexName:"reverseIndex",
          hashKey:{valor:("COMPRA#"+getSucursal()),nombre:"type"},
          sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"id"}
      },
      {
        indexName:"reverseIndex",
        hashKey:{valor:("TRASPASO#"+getSucursal()),nombre:"type"},
        sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"id"}
      }];
      let arreglo =[],traspasos=[];
      
      try{
        const respCompras = await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[0]});
        arreglo = handleSuccesfulComprasDay(respCompras.data);

        const respTraspasos = await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[1]});
        traspasos = handelSuccesfulTraspasos(respTraspasos.data);

        traspasos.forEach(tras=>{ arreglo.push(tras); });

        //console.log(arreglo)
        arreglo = [...arreglo].sort((fa,fb)=> new Date(fa.id).getTime()-new Date(fb.id).getTime());


        newPropsRep.select = arreglo[0].id;
        
        setPropsRep(newPropsRep)
        setVentas(arreglo);
        addDatos(nameComponent,[arreglo,{fechai:PropsReportes.fecha_Inicial,fechaf:PropsReportes.fecha_Final}]);
        
        closemodalLoading();
      }catch(Error){
        closemodalLoading();
        handleError(Error);
      }
    };

    if(JSON.stringify(datos)=='{}'||PropsReportes?.update==true){
      getVentas();
    }else{
      if(PropsReportes.fecha_Inicial==datos[1].fechai&&PropsReportes.fecha_Final==datos[1].fechaf&&!PropsReportes?.update){
        const arreglo = datos[0];
        setVentas(arreglo);
        newPropsRep.select = arreglo[0].shortId;
        setPropsRep(newPropsRep)
        return;
      }
      getVentas();
    }
  }, [PropsReportes.fecha_Inicial,PropsReportes.fecha_Final,PropsReportes.rutas,PropsReportes?.update]);


  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>


      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
      <TableReactWindows
        btnDownload={async(e,datos)=>{
          const data = datos || ventas;
          if(!data?.length){handleError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            handleError(workb.error);
            return
          }

          const nameFile="Compras",
          headerFile="Reporte de Compras del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={'Fecha':13,'Compra':10,'Proveedor':17,'Cant':7,'Subtotal':17,'Descuento':15,'Envio':15,'Total':17,'Recibido':15,'Referencia':40,'Fecha Ref': 13};
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((compra,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(FechaUTCToLocal(compra?.id).fecha||"");
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(compra?.shortId||"");
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(compra?.proveedor||"");
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(compra?.cantidadTotal||0);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(compra?.subtotal||0));
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(compra?.descuento||0));
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(compra?.envio||0));
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(valueToMoney2(compra?.importe||0));
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(compra?.personal||"");
            sheet1.cell(columnasXlsx[9]+(colPlus+index)).value(compra?.referencia||"");
            sheet1.cell(columnasXlsx[10]+(colPlus+index)).value(compra?.fechaReferencia||"");
          });
      
          sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[4]+sizeData).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[4]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[6]+sizeData).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[7]+sizeData).formula("SUM("+columnasXlsx[7]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[7]+sizeData).style(formatoCelda.contabilidad);
          
          const response =await SaveXlsx(workb,nameFile);
          if(response?.error){
            closemodalLoading();
            handleError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
        }}
        onRowClick={({e,row})=>{ if(e.detail==1){ setPropsRep({...PropsReportes,select:row.shortId}) } }}
        columns={[
          {
            key:"shortId",
            title:"Folio de la compra",
            header:"#Rcv",
            width:100, 
            type: tipoCelda.text,
            className: "text-blue-800 text-left",
            filter:true,
            onClick: ({e,row})=>{
              if(e.detail==2){
                setHeader({...PropsReportes.header,
                  day:FechaUTCToLocal(row.id).fecha,
                  folio:row.shortId,
                  proveedor:row.proveedor,
                  nombreCliente:row.nombreCliente,
                  user:row.personal,
                  referencia:row?.referencia,
                  fechaRef:row.fechaReferencia,
                  text:"",
                  descuento:row.descuento,
                  envio:row.envio,
                  importe:row.importe,
                  type:row.type,
                });
                setRutaRep("comprasFolio");
              } 
            }
          },
          {
            key:"id",
            header:"Date",
            title:"Fecha de la compra",
            width:80, 
            type: tipoCelda.date,
            filter:true,
          },
          {
            key:"proveedor",
            tiutle:"Codigo del proveedor",
            header:"Supplier",
            width:100, 
            type: tipoCelda.text,
            filter:true,
          },
          {
            key:"cantidadTotal",
            header:"QtyRcvd",
            width:80, 
            className:"text-blue-800",
            type: tipoCelda.number,
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"subtotal",
            header:"Subtotal",
            width:120, 
            type: tipoCelda.money,
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"descuento",
            header:"Discount",
            title:"Descuento de la compra",
            width:120, 
            type: tipoCelda.money,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"envio",
            header:"Freight",
            title:"Monto por concepto de envio",
            width:120, 
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"importe",
            header:"Amount",
            width:120, 
            type: tipoCelda.money,
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"personal",
            header:"RcvBy",
            width:100, 
            type: tipoCelda.text,
            filter:true,
          },
          {
            key:"referencia",
            header:"Invoice #",
            width:200, 
            type: tipoCelda.text,
            filter:true,
          },
          {
            key:"fechaReferencia",
            header:"InvoiceDate",
            width:100, 
            type: tipoCelda.date,
            filter:true,
          },
          /*{
            key:"inventarioPendiente",
            header:"Inv",
            width:50,
            filter:true,
            type:tipoCelda.text,
             // eslint-disable-next-line react/display-name
             content:({row,index})=>(
              <p className="text-center cursor-pointer" title={row?.inventarioPendiente?"Compra con inventario":""}>
                <i className={row?.inventarioPendiente?"fas fa-circle text-blue-400 ":""} />
              </p>
             )
          }*/
        ]}
        data={ventas}
      />
      
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  setPropsRep: (props)=>dispatch({type:"SET_PROPS_REP",props:props})
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteComprasDay);
