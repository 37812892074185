import React from 'react'

export default function Target(props) {
    return (
        <div
        key={props?.index?props.key:0}
        className={props.className+" justify-center items-center font-medium py-1 px-1 rounded-full text-white cursor-pointer filter drop-shadow-md "+(props?.bg?"bg-red-700 border border-red-300 hover:bg-red-500":"bg-blue-800 border border-indigo-300 hover:bg-blue-600")}
        disabled={props?.disabled?props.disabled:false}
        >
            <div 
            className="text-sm font-semibold leading-none max-w-full flex-initial px-1 disabled:opacity-50"
            disabled={props?.disabled?props.disabled:false}
            onClick={(e) => {
                props?.disabled?"":props.getValor(props.valor);
            }}>
                {props.valor}
            </div>
        </div>
    )
}
