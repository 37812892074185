import React, { useEffect, useRef, useState } from 'react'
import {motion} from "framer-motion"

const Toggle=(props)=> {
  const [isOn,setIsOn] = useState(false);
  const size =  (props?.size?"-"+props.size:""); 
  const toggleSwitch=()=>{setIsOn(!isOn)};
  const btnToggle = props?.inputRef || useRef("");
  const spring ={
    type: "spring",
    stiffness: 800,
    damping:50
  }

  useEffect(()=>{
    setIsOn(props.checked)
  },[props.checked])

  return (
    <div className={"switch"+size} name={props?.name} ref={btnToggle} data-isOn={isOn} onClick={e=>{
      if(props?.onClick){props.onClick(e,!isOn);}
      toggleSwitch();
    }} >
      <motion.div className={"handle"+size} layout transition={spring}/>
    </div>
  )
}

export default Toggle