import React from "react";


const LabelProcent = (props) => {
  const valor = parseFloat(props?.valor||0).toFixed(2);

  return  <input
  type="text"
  className={"border-table w-full "+props?.className}
  readOnly={true}
  value={valor+"%"}
  />;
};

export default LabelProcent;