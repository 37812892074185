import React, { useState,useEffect, useMemo, useRef } from 'react'
import { ConvertMoneytoFormat, valueToMoney,maxlimite } from '../services/functions'

 const InputPrecioPanel=({className,setValor,min,max,disabled,defaultValue,zero,inputRef,name,width,newValor,reset,onBlur,onKeyPress,readOnly,placeholder,form,noShowSignal,onFocus,bgtext,hidden,bgMin})=> {
  const [aux,setaux]=useState({text:"",number:0}),
  [maximo,setMaximo] = useState(!isNaN(Number(max))?valueToMoney(max):maxlimite);
  const minimo = !isNaN(Number(min))?valueToMoney(min):(maxlimite*-1);
  const inputRf = inputRef || useRef(""), setVal = setValor || (()=>{});

  useEffect(() => {
    const val = (defaultValue?valueToMoney(defaultValue).toString():"0")
    ConvertMoneytoFormat(val,aux,setaux);
  }, [])

  useEffect(()=>{
    setVal(aux.number);
  },[aux])

  useEffect(() => {
    if(!isNaN(Number(max))&&max!==""){ setMaximo(valueToMoney(max));}
    else {setMaximo(maxlimite);}
  }, [max])

  useEffect(()=>{
    const val = (newValor?valueToMoney(newValor).toString():"0")
    ConvertMoneytoFormat(val,aux,setaux);
  },[newValor])

  useMemo(()=>{
    setaux({text:"",number:0});
  },[reset])

  const input=(bgtext)=>{
    return (
      <input
      type="text"
      className={"w-full text-right font-semibold pl-1 outline-none focus:outline-none "+(aux.number<0||aux.number<minimo||aux.number>maximo?"text-red-500 ":(bgtext?bgtext:""))+(bgMin?" bg-red-200":" bg-transparent") }
      style={{ transition: "all .15s ease" }}
      placeholder={placeholder?placeholder:""}
      ref={inputRf}
      name={name}
      value={aux.text}
      disabled={disabled?disabled:false}
      readOnly={readOnly?readOnly:false}
      onFocus={e=>{
        if(!inputRf.current?.value){return}
        if(inputRf.current.value==0){inputRf.current.value=""}
      }}
      onBlur={e=>{
        aux.number<minimo?setaux({...aux,text: (zero?"0":minimo.toString()),number:(zero?0:minimo)}):"";
        aux.number>maximo?setaux({...aux,text:maximo.toString(),number:maximo}):"";
        //console.log(aux.number)
        onBlur?onBlur(aux.number,e):"";
      }}
      onChange={e=>{
        ConvertMoneytoFormat(e.target.value,aux,setaux);
      }}
      onKeyDown={e=>{
        if(e.key=="Tab"||e.key=="Enter"){
          aux.number<minimo?setaux({...aux,text: (zero?"":minimo.toString()),number:(zero?0:minimo)}):"";
          aux.number>maximo?setaux({...aux,text:maximo.toString(),number:maximo}):"";
        }
        onKeyPress?onKeyPress(e,aux.number):""
      }}
      />
    )
  }
    return (
    <>
    {
      <div className={(hidden?"hidden ":"flex "+(disabled?"opacity-30 ":" ")+(width?width:"w-full "))+" items-center "+(className?className:"")+(form?" border-form w-full text-right ":(" border-table text-right "+ (bgtext?bgtext:"")))}>
        <p hidden={noShowSignal?true:false} className={(form?" lg:px-2 font-bold "+(disabled?"text-gray-400 ":""):"font-semibold cursor-text")}>$</p>
        {input()}
      </div>
    }
    </>
    )
}

export default InputPrecioPanel;