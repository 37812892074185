import React, { useEffect,useRef, useState } from 'react';
import { connect } from 'react-redux';
import { FechaUTCToLocal, getArrayAsync, havePermission, Sucursal } from "../../services/functions";
import Selectormap from '../Productos/Selectormap';
import CalendarModal from '../modal/CalendarModal';
import Labelmoney from '../labelmoney';
import { returnRuta, setRuta, setRutaRep } from '../../redux/Actions';
import Modalgeneric from '../modal/generic';
import ReporteComprasProveedor from './Compras/ReporteComprasProveedor';
import ReporteComprasMarca from './Compras/ReporteComprasMarca';
import ReporteComprasLinea from './Compras/ReporteComprasLinea';
import ModalMerma from '../Inventario/ModalMerma';
import ReporteVentaProveedor from './Ventas/ReporteVentaProveedor';
import ReporteVentaLinea from './Ventas/ReporteVentaLinea';
import { permisos } from '../../static/dat/datos';
import InOut from './Modales/InOut';
import LoadingModal from '../modal/LoadingModal';
import ReporteFinancial from './Finanzas/ReporteFinancial';

const HeaderReportes=({PropsReportes,RutaModal,addRutaModal,delRuta,addRutaGlobal,delRutaGlobal,setRutaRep,Ruta,setHeader,setCleanInventario,resetPropsInventario,setHeaderCompra,Datos,setPropsRep})=> {
    const  [openModalCalendar,setOpenModalCalendar]=useState(false), 
    [fechaCalendar,setFechaCalendar] = useState({fecha_Inicial: PropsReportes.fecha_Inicial,fecha_Final:PropsReportes.fecha_Final}),
    [modalToolsReceiving,setModalToolsReceiving]=useState({isOpen:false}),
    [modalToolsSales,setModalToolsSales]=useState({isOpen:false}),
    [modalComprasMarca,setModalComprasMarca]=useState({isOpen:false}),
    [modalComprasLinea,setModalComprasLinea]=useState({isOpen:false}),
    [modalComprasProveedor,setModalComprasProveedor]=useState({isOpen:false}),
    [modalSalesLinea,setModalSalesLinea]=useState({isOpen:false}),
    [modalFinancial,setModalFinancial]=useState({isOpen:false}),
    [modalMerma,setModalMerma]=useState({isOpen:false}),
    [modalLoading, setmodalLoading] = useState({}),
    [modalSalesProveedor,setModalSalesProveedor]=useState({isOpen:false});
    const permisoMermas = havePermission(permisos.regMerma);
    const cuentaPago=useRef([]);
    const btnGeneric = "btn-light-free bg-gray-200 hover:bg-gray-50 h-10",
    btnGeneric1 = "btn-free bg-gray-200 hover:bg-gray-50 h-10";
    const rutaActual =Ruta?.[Ruta.length-1],rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
    const rutasRep ={
        "comprasDay":"shortId",
    }


    const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, title: titulo||"" });};
    const closemodalLoading = (e) => {setmodalLoading({ isOpen: false });};

    const openModalReceving=()=>{  setModalToolsReceiving({isOpen:true}); }
    const closeModalReceving=()=>{setModalToolsReceiving({isOpen:false}); }

    const openModalSales=()=>{  setModalToolsSales({isOpen:true}); }
    const closeModalSales=()=>{setModalToolsSales({isOpen:false}); }

    const openModalMerma=()=>{  setModalMerma({isOpen:true}); }
    const closeModalMerma=()=>{setModalMerma({isOpen:false}); }

    const openmodalComprasMarca=()=>{  setModalComprasMarca({isOpen:true}); }
    const openmodalComprasLinea=()=>{  setModalComprasLinea({isOpen:true}); }
    const openmodalComprasProveedor=()=>{  setModalComprasProveedor({isOpen:true}); }

    const openmodalSalesProveedor=()=>{  setModalSalesProveedor({isOpen:true}); }
    const openmodalSalesLinea=()=>{  setModalSalesLinea({isOpen:true}); }
    const openmodalFinancial=()=>{  setModalFinancial({isOpen:true}); }

    const directionRepSe=(option)=>{
        if(!PropsReportes?.selectSe){return}
        
        let newRuta = "",rutaGlobal="";
        const newPropsRep = {...PropsReportes};

        if(rutaActualRep=="ventasDay"){
            let indice = Datos["ventasRango"][0].findIndex(item=>(item.type == PropsReportes.selectSe));
            indice = option=="select"?indice:(option=="+"?indice+1:indice-1);

            if(indice<0||indice>Datos["ventasRango"][0].length-1){return}
            const row = Datos["ventasRango"][0][indice];
            if(!row){return}

            newPropsRep.header.day = row.type;
            newPropsRep.selectSe = row.type;
            newPropsRep.select = row.shortId;

            newRuta = (option=="select"?"ventasDay":null);
        }  
        /*else if(["ventasFolio"].includes(rutaActualRep)){
            if(!row){return}
            newPropsRep.header.folio:row.shortId;
            newPropsRep.header.cliente=row.cliente;
            newPropsRep.header.nombreCliente=row.nombreCliente;
            newPropsRep.header.user=row.vendedor;
            newPropsRep.header.type=row.type;
            newPropsRep.header.items=row?.items||[];
           
            setRutaRep("ventasFolio");
        }*/
       
        setPropsRep(newPropsRep);
        option=="select"&&newRuta?setRutaRep(newRuta):"";
        option=='edit'&&rutaGlobal?addRutaGlobal(rutaGlobal):null;
    }

    const directionRep=(option)=>{
        if(!PropsReportes?.select){return}
        let newRuta = "",rutaGlobal="";
        const newPropsRep = {...PropsReportes};

        if(["comprasFolio","comprasDay"].includes(rutaActualRep)){
            let indice = Datos["comprasDay"][0].findIndex(item=>(item.shortId == PropsReportes.select));
            indice = option=="select"?indice:(option=="+"?indice+1:indice-1);

            if(indice<0||indice>Datos["comprasDay"][0].length-1){return}
            const row = Datos["comprasDay"][0][indice];
            if(!row){return}
            
            newPropsRep.header.day=FechaUTCToLocal(row.id).fecha;
            newPropsRep.header.folio=row.shortId;
            newPropsRep.header.proveedor=row.proveedor;
            newPropsRep.header.nombreCliente=row.nombreCliente;
            newPropsRep.header.user=row.personal;
            newPropsRep.header.referencia=row?.referencia;
            newPropsRep.header.fechaRef=row.fechaReferencia;
            newPropsRep.header.text="";
            newPropsRep.header.descuento=row.descuento;
            newPropsRep.header.envio=row.envio;
            newPropsRep.header.importe = row.importe||0;
            newPropsRep.header.type=row.type;

            newPropsRep.select = row.shortId;
            //newPropsRep.descuento = row?.descuento||0;
            //newPropsRep.envio = row?.envio||0;
           

            newRuta = (option=="select"&&rutaActualRep=="comprasDay"?"comprasFolio":null);
           /* if(!PropsReportes?.select){return}
            setHeaderCompra({...HeaderCompra,edit:true});*/
            rutaGlobal = (option=='edit'?"compra":null);

        }if(rutaActualRep=="ventasRango"){
            let indice = Datos["ventasRango"][0].findIndex(item=>(item.type == PropsReportes.select));
            indice = option=="select"?indice:(option=="+"?indice+1:indice-1);

            if(indice<0||indice>Datos["ventasRango"][0].length-1){return}
            const row = Datos["ventasRango"][0][indice];
            if(!row){return}

            newPropsRep.header.day = row.type;
            newPropsRep.select = row.type;

            newRuta = (option=="select"?"ventasDay":null);
        }else if('ventasDay'==rutaActualRep&&option=="select"){
            let indice = Datos["ventasDay"][0].findIndex(item=>(item.shortId == PropsReportes.select));
            indice = option=="select"?indice:(option=="+"?indice+1:indice-1);
            if(indice<0||indice>Datos["ventasDay"][0].length-1){return}
            const row = Datos["ventasDay"][0][indice];
            if(!row){return}
            newPropsRep.header.folio=row.shortId;
            newPropsRep.header.cliente=row.cliente;
            newPropsRep.header.nombreCliente=row.nombreCliente;
            newPropsRep.header.user=row.vendedor;
            newPropsRep.header.type=row.type;
            newPropsRep.header.items=row?.items||[];
            newPropsRep.select = row.shortId;
           
            newRuta = (option=="select"?"ventasFolio":null);
        }
        else if(["ventasFolio"].includes(rutaActualRep)){
            let indice = Datos["ventasDay"][0].findIndex(item=>(item.shortId == PropsReportes.select));
            indice = option=="select"?indice:(option=="+"?indice+1:indice-1);

            if(indice<0||indice>Datos["ventasDay"][0].length-1){return}
            const row = Datos["ventasDay"][0][indice];
            if(!row){return}

            newPropsRep.header.folio=row.shortId;
            newPropsRep.header.cliente=row.cliente;
            newPropsRep.header.nombreCliente=row.nombreCliente;
            newPropsRep.header.user=row.vendedor;
            newPropsRep.header.type=row.type;
            newPropsRep.header.items=row?.items||[];
            newPropsRep.select = row.shortId;
           
            newRuta = (option=="select"?"ventasFolio":null);
        }
       
        setPropsRep(newPropsRep);
        option=="select"&&newRuta?setRutaRep(newRuta):"";
        option=='edit'&&rutaGlobal?addRutaGlobal(rutaGlobal):null;
    }

    const rutasModales ={
        inout: <InOut openmodalLoading={openmodalLoading} closemodalLoading={closemodalLoading}/>,
    }

    const headerInventory=()=>{
        return <div className='flex gap-2 size-form '>
             <CalendarModal state={fechaCalendar} setState={setFechaCalendar} openmodal={openModalCalendar} closemodal={setOpenModalCalendar}/> 
                    <button 
                    className={rutaActualRep=="inventarioCategoria"?'btn-blue ':"hidden" }
                    onClick={e=>{
                        setRutaRep("altaLineas");
                    }}>
                        By Line
                    </button>
                    <button 
                    className={rutaActualRep=="inventarioCategoria"?'btn-blue ' :"hidden"}
                    onClick={e=>{
                        setRutaRep("inventarioMarcas");
                    }}>
                        By Brand
                    </button>
                    <button 
                    className={rutaActualRep=="inventarioCategoria"?'btn-gray2 ':"hidden"}
                    onClick={e=>{
                        setRutaRep("inventarioServices");
                    }}>
                        Services
                    </button>
                    <button 
                    className={rutaActualRep=="inventarioCategoria"?'btn-gray2 ' :"hidden"}
                    onClick={e=>{
                        setRutaRep("inventarioAjuste");
                    }}>
                        Adjust
                    </button>
                    <button 
                    className={rutaActualRep=="inventarioAjuste"&&permisoMermas?'btn-gray2' :"hidden"}
                    onClick={e=>{
                        addRutaGlobal("panelAjuste");
                    }}>
                        New Adjust
                    </button>
                    <button 
                    className={rutaActualRep=="inventarioCategoria"?'btn-gray2 ':"hidden"}
                    onClick={e=>{
                        setRutaRep("inventarioMerma");
                    }}>
                        Warranty
                    </button>
            </div>
    }

    const headerWarranty=()=>{
        return <button 
            className={rutaActualRep=="inventarioMerma"&&permisoMermas?'btn-gray2':"hidden"}
             onClick={e=>{
                openModalMerma();
            }}>
                New
            </button>
    }

    const headerCash=()=>{
        return <>
        <div className={'md:w-36 items-center justify-center '+(rutaActualRep=="cashRango"?"flex flex-col":"hidden")}>
            <Selectormap
            name={"cuentaReport"}
            defaultValue={"CAJA"}
            noNulo={true}
            catalogo={cuentaPago.current}
            getvalor={(valor) => {
                if(!valor?.clave){return;}
                setHeader({...PropsReportes.header,filtro:valor.valor})
            }}
            />
        </div>
        </>
    }

    useEffect(()=>{
        if(havePermission(permisos.reportes)){return;}
        addRutaGlobal(PropsReportes.global);
    },[PropsReportes.global])

    useEffect(()=>{
        const accounts =  Sucursal('cuentas');
        if(cuentaPago.current?.length){cuentaPago.current=[];}
        cuentaPago.current.push("CAJA");
        accounts.map(met=>{
          Object.keys(met).map(cuenta=>{
            cuentaPago.current.push(cuenta+"/"+met[cuenta])
            met.id= cuenta+"/"+met[cuenta];
          })
        })
        //cuentaPago.current.push("ALL");
      },[])

    return (
    <div className='w-full flex flex-col gap-1 my-1'>
        <LoadingModal config={modalLoading} >
            <LoadingModal.Body>
            <LoadingModal.Title>{modalLoading.title||"Cargando Datos"}</LoadingModal.Title>
            </LoadingModal.Body>
        </LoadingModal>

        <Modalgeneric w="size-w-30" h="size-h-50" config={modalToolsReceiving}>
            <Modalgeneric.Body>
                <div className='w-full flex flex-col gap-2 pt-1'>
                    <button className='btn-gray2' onClick={e=>{
                        closeModalReceving();
                        openmodalComprasMarca();
                    }}>Brand</button>
                    <button className='btn-gray2' onClick={e=>{
                        closeModalReceving();
                        openmodalComprasProveedor();
                    }}>Supplier</button>
                     <button className='btn-gray2' onClick={e=>{
                        closeModalReceving();
                        openmodalComprasLinea();
                    }}>Line</button>
                </div>
            </Modalgeneric.Body>
            <Modalgeneric.Buttons>
                <button className='btn-gray2 ' onClick={e=>{closeModalReceving();}}>Cerrar</button>
            </Modalgeneric.Buttons>
        </Modalgeneric>

        <Modalgeneric w="size-w-30" h="size-h-50" config={modalToolsSales}>
            <Modalgeneric.Body>
                <div className='w-full flex flex-col gap-2 pt-1'>
                    <button className='btn-gray2' onClick={e=>{
                        closeModalSales();
                        openmodalSalesProveedor();
                    }}>Supplier</button>
                     <button className='btn-gray2' onClick={e=>{
                        closeModalSales();
                        openmodalSalesLinea();
                    }}>Line</button>
                    <button className='btn-gray2' onClick={e=>{
                        closeModalSales();
                        openmodalFinancial();
                    }}>Financial</button>
                </div>
            </Modalgeneric.Body>
            <Modalgeneric.Buttons>
                <button className='btn-gray2 ' onClick={e=>{closeModalSales();}}>Cerrar</button>
            </Modalgeneric.Buttons>
        </Modalgeneric>
        <ReporteComprasMarca config={modalComprasMarca} setModal={setModalComprasMarca} setOpenModalCalendar={setOpenModalCalendar} stateFechas={fechaCalendar}/>
        <ReporteComprasProveedor config={modalComprasProveedor} setModal={setModalComprasProveedor}  setOpenModalCalendar={setOpenModalCalendar} stateFechas={fechaCalendar}/>
        <ReporteComprasLinea config={modalComprasLinea} setModal={setModalComprasLinea} setOpenModalCalendar={setOpenModalCalendar} stateFechas={fechaCalendar}/>
        {modalMerma?.isOpen? <ModalMerma config={modalMerma} setModal={setModalMerma} />:null}
       

        <ReporteVentaProveedor config={modalSalesProveedor} setModal={setModalSalesProveedor} setOpenModalCalendar={setOpenModalCalendar} stateFechas={fechaCalendar}/>
        <ReporteVentaLinea config={modalSalesLinea} setModal={setModalSalesLinea} setOpenModalCalendar={setOpenModalCalendar} stateFechas={fechaCalendar}/>
        {
            modalFinancial?.isOpen?
            <ReporteFinancial config={modalFinancial} setModal={setModalFinancial} setOpenModalCalendar={setOpenModalCalendar} stateFechas={fechaCalendar}/>
            :null
        }
        
        {rutasModales[RutaModal]??""}

        <div className='flex flex-wrap w-full items-center gap-1 justify-center font-semibold text-center'>
            {PropsReportes.header?.btns?.direction?
            <button
            className={btnGeneric1+" text-pink-800"}
            onClick={e=>{ directionRep("-"); }}
            ><i className='fas fa-caret-left fa-2x pr-1'/></button>
            :null}
            {PropsReportes.header?.btns?.direction?
            <button
            className={btnGeneric1+" text-pink-800"}
            onClick={e=>{ directionRep("+"); }}
            ><i className='fas fa-caret-right fa-2x pl-1'/></button>
            :null}

            {PropsReportes.header?.btns?.directionSe?
            <button
            className={btnGeneric1+" text-blue-800"}
            onClick={e=>{ directionRepSe("-"); }}
            ><i className='fas fa-caret-left fa-2x pr-1'/></button>
            :null}
            {PropsReportes.header?.btns?.directionSe?
            <button
            className={btnGeneric1+" text-blue-800"}
            onClick={e=>{ directionRepSe("+"); }}
            ><i className='fas fa-caret-right fa-2x pl-1'/></button>
            :null}
            
            {PropsReportes.header?.btns?.detail?
            <button
            className={btnGeneric1+" text-blue-800"}
            onClick={e=>{ directionRep("select"); }}
            >DETAIL</button>
            :null}

            {PropsReportes.header?.btns?.by?
             <button 
             className={btnGeneric1+" text-blue-800"}
             onClick={e=>{
                if(PropsReportes.global=="compra"){
                    openModalReceving();
                }else if(PropsReportes.global=="venta"){
                    openModalSales();
                }
             }}>
                 BY
             </button>
             :null}

            {PropsReportes.header?.btns?.edit?
            <button
            className={btnGeneric}
            onClick={e=>{
                directionRep("edit"); 
            }}
            >EDIT</button>
            :null}

            <button 
            hidden={!PropsReportes?.global}
            className={btnGeneric} 
            onClick={e=>{
                if(rutaActual=="inventory"){
                    setCleanInventario();
                    resetPropsInventario();
                }
                addRutaGlobal(PropsReportes.global);
            }}>
                {(rutaActual=="inventory"?"INVENTORY":"NEW")}
            </button>

            <div className='flex flex-col h-10 items-center border border-black bg-gray-200 text-size'>
                <label className='bg-gray-500 text-white w-full px-2'>Beginning Date</label>
                <label>{PropsReportes.fecha_Inicial}</label>
            </div>
 
            <div className=' flex flex-col h-10 items-center border border-black bg-gray-200 text-size'>
                <label className='bg-gray-500 text-white w-full px-2'>Ending Date</label>
                <label >{PropsReportes.fecha_Final}</label>
            </div>

                <button 
                className={btnGeneric} 
                onClick={e=>{
                    if(rutaActual=="reportes"){
                        if(Object.keys(PropsReportes?.rutas).length==0){
                            //addRutaGlobal(Ruta.oldruta)
                            delRutaGlobal();
                        }
                    }
                    else if ([1,0].includes(Object.keys(PropsReportes?.rutas).length)){
                        //addRutaGlobal(oldruta)
                        delRutaGlobal();
                    }
                    delRuta();
                }}>
                    <i className='fas fa-hand-point-left text-3xl text-black'></i>
                </button>
                <div className={(PropsReportes.header?.inout?"flex flex-col":"hidden")}>
                <button className={btnGeneric} onClick={(e)=>{
                    addRutaModal("inout");
                }}>IN/OUT</button>
                    
                </div>
        </div>
        <div className={' w-full justify-center gap-1 font-semibold size-text text-center ' +(Object.keys(PropsReportes.header).length==0?"hidden":"flex flex-wrap")} >
            {PropsReportes.header?.text?
            <p className='md:w-60 font-bold border border-black bg-gray-500 text-white text-center py-2 '>{PropsReportes.header?.text}</p>
            :null}
            {PropsReportes.header?.folio?
            <div className='md:w-28 border border-black bg-gray-200 text-size flex flex-col'>
                <p className='bg-gray-500 text-center text-white  '>{PropsReportes.global=="compra"?"Rcving No":"Folio"}</p>
                <p className='font-bold text-red-600 px-1'>{PropsReportes.header?.folio}</p>
            </div>
            :null}
            {PropsReportes.header?.day?
            <div className='md:w-32 border border-black bg-gray-200 text-size flex flex-col'>
                <p className='bg-gray-500 text-center text-white '>{PropsReportes.global=="compra"?"Date Rcvd":"Date"}</p>
                <p>{PropsReportes.header?.day}</p>
            </div>
            :null}
            {PropsReportes.header?.cliente?
            <div className={'md:w-36 border border-black bg-gray-200 text-size flex flex-col'}>
                <p className='bg-gray-500 text-center text-white '>Customer</p>
                <p >{PropsReportes.header?.cliente}</p>
            </div>
            :null}
            {PropsReportes.header?.nombreCliente?
            <div className={'md:w-72 border border-black bg-gray-200 text-size flex flex-col'}>
                <p className='bg-gray-500 text-center text-white '>Customer Name</p>
                <p>{PropsReportes.header?.nombreCliente}</p>
            </div>
            :null}
            {PropsReportes.header?.user?
            <div className={'md:w-36 border border-black bg-gray-200 text-size flex flex-col'}>
                <p className='bg-gray-500 text-center text-white '>{PropsReportes.global=="compra"?"Rceived By":"User"}</p>
                <p className=' whitespace-nowrap '>{PropsReportes.header?.user}</p>
            </div>
            :null}
            {PropsReportes.header?.referencia?
            <div className={'border border-black bg-gray-200 text-size flex flex-col'}>
                <p className='bg-gray-500 text-center text-white '>{PropsReportes.global=="compra"?"His Invoice No":"User"}</p>
                <p className=' whitespace-nowrap '>{PropsReportes.header?.referencia}</p>
            </div>
            :null}
            {PropsReportes.header?.proveedor?
            <div className={'md:w-36 border border-black bg-gray-200 text-size flex flex-col'}>
                <p className='bg-gray-500 text-center text-white '>Supplier</p>
                <p className=' whitespace-nowrap '>{PropsReportes.header?.proveedor}</p>
            </div>
            :null}
            {/*PropsReportes.header?.referencia?
            <div className={'md:w-36 border border-black bg-gray-200 text-size flex flex-col'}>
                <p className='bg-gray-500 text-center text-white '>His Invoice No</p>
                <p className='px-1 overflow-x-auto break-all'>{PropsReportes.header?.referencia}</p>
            </div>
            :null
            */}   
            {PropsReportes.header?.fechaRef?
            <div className={'md:w-36 border border-black bg-gray-200 text-size flex flex-col'}>
                <p className='bg-gray-500 text-center text-white '>Invoice Date</p>
                <p>{PropsReportes.header?.fechaRef}</p>
            </div>
            :null}
            {PropsReportes.header?.descuento?
            <div className={'md:w-36 border border-black bg-ambar-100 text-size flex flex-col'}>
                <p className='bg-black text-center text-ambar-200 '>Discount</p>
                <Labelmoney twoDecimal={true}>{PropsReportes.header?.descuento||0}</Labelmoney>
            </div>
            :null}  
            {PropsReportes.header?.envio?
            <div className={'md:w-36 border border-black bg-ambar-100 text-size flex flex-col'}>
                <p className='bg-black text-center text-ambar-200 '>Freight</p>
                <Labelmoney twoDecimal={true}>{PropsReportes.header?.envio||0}</Labelmoney>
            </div>
            :null}
            {PropsReportes.header?.importe?
            <div className={'md:w-36 border border-black bg-ambar-100 text-size flex flex-col'}>
                <p className='bg-black text-center text-ambar-200 '>Total</p>
                <Labelmoney twoDecimal={true}>{PropsReportes.header?.importe||0}</Labelmoney>
            </div>
            :null}

            {headerInventory()}
            {headerWarranty()}    
            {headerCash()}  
        </div>
    </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    delRuta: () => dispatch({type:"DEL_RUTA_REP"}),
    setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
    delRutaModal: () => dispatch({type:"DEL_RUTAMODAL_REP"}),
    addRutaModal:(ruta)=>dispatch({type:"ADD_RUTAMODAL_REP",ruta:ruta}),
    delRutaGlobal: () => dispatch(returnRuta()),
    addRutaGlobal:(ruta)=> dispatch(setRuta(ruta)),

    setHeaderCompra:(header)=> dispatch({type:"SET_HEADER_COMPRA",compra:header}),

    setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
    setPropsRep:(props)=> dispatch({type:"SET_PROPS_REP",props:props}),

    setCleanInventario: () => dispatch({type: 'RESET_INVENTARIO'}),
    resetPropsInventario: ()=> dispatch({type: "RESET_PROPSINVENTARIO"}),
  });
  const mapStateToProps = (state) => ({
    PropsReportes: state.PropsReportes,
    Ruta: state.Ruta,
    RutaModal: state.PropsReportes.rutasModal[state.PropsReportes.rutasModal.length-1],
    HeaderCompra: state.HeaderCompra,
    Datos: state.Datos,
  });

export default connect(mapStateToProps, mapDispatchToProps)(HeaderReportes);