import React, { useState, useMemo, useEffect, useRef } from "react";
import InputClientVenta from "../components/InputClientVenta";
import DatosClienteVenta from "../components/DatosClienteVenta";
import InputVenta from "../components/inputVenta";
import { connect } from "react-redux";
import Labelmoney from "../components/labelmoney";
import { apiUse, apiUsePromise, CalculaPagos, getIdUser, getNombre, getSucursal, moneyToValue, Sucursal, valueToMoney } from "../services/functions.js";
import Modalinfo from "../components/modal/info";
import Modaldanger from "../components/modal/danger";
import Modalsuccess from "../components/modal/success";
import { returnRuta, setRuta } from "../redux/Actions";
import Container from "../components/Container";
import Modalgeneric from "../components/modal/generic";
import InputPrecioPanel from "../components/InputPrecioPanel";
import TableModal from "../components/modal/TableModal";
import Pendientes from "../components/Ventas/Pendientes";
import { cloneDeep } from "lodash";
import LoadingModal from "../components/modal/LoadingModal.jsx";

const Pago = ({ config }) => {
  const formasPagoLibres = config.formasPago;
   
  return (
    <tr className="h-12 lg:h-16">
    <td className="w-2/12">
      <div className="flex w-full px-2 items-center ">
        <button
        className="text-ambar-300 hover:text-red-400 text-xl mr-2"
        onClick={(e) => {
          config.deletepago(config.index);
        }}
        >
          <i className="fas fa-times-circle"></i>
        </button>
        <select
          className="border-form w-full"
          onChange={(e) => {
            let newdata = [...config.data];
            if(e.target.value==="EFECTIVO"){
               newdata[config.index].cuenta="CAJA";
            }else{
              newdata[config.index].cuenta="";
            }
              newdata[config.index].metodo = e.target.value;
            
            config.setdata(newdata);
          }}
          value={config.data[config.index]?.metodo}
        >
          <option key={0} value={""}></option>
          {formasPagoLibres().map((item, index) => (
            <option key={index + 1} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </td>
    <td className="px-2 w-6/12">
      <select
          className="border-form w-full"
          disabled={config.data[config.index]?.metodo=="EFECTIVO"}
          onChange={(e) => {
            let newdata = [...config.data];
            newdata[config.index].cuenta = e.target.value;
            config.setdata(newdata);
          }}
        >
          <option key={0} value={""}></option>
          {config.cuentas.map((item, index) => (
            <option key={index + 1} value={Object.keys(item)[0]+'/'+Object.values(item)[0]}>
              {Object.keys(item)[0]+' '+Object.values(item)[0]}
            </option>
          ))}
        </select>
    </td>
    <td className="w-2/12 px-1">
      <InputPrecioPanel
      className="bg-white "
      form={true}
      min={0}
      defaultValue={config.data[config.index]?.monto}
      setValor={(valor)=>{
        let newdata = [...config.data];
        //
        if (!isNaN(valor) ){
          newdata[config.index].monto = valor;
        }
        config.setdata(newdata);
       }}
      />
    </td>
  </tr>
  );
};

const Caja = ({ VentaAPagar,Clientes, addClient,SetVentaAPagar,VentasCredito,addRuta,delRuta,SetTicket,FormasP,Ruta}) => {
  const  [pagos, setpagos] = useState(VentaAPagar?.pagos||[{ monto: 0,metodo: "", cuenta: "",},]),
  [pin,setPin] =useState(''),
  [modalTablaConfig, setmodalTablaConfig] = useState(false),
  [enableButton,setEnableButton] = useState(false),
  [modalLoading, setmodalLoading] = useState({}),
  [pagado,setPagado]=useState(false),
  [ventaEx,setVentaEx]=useState();
  const oldRuta =Ruta?.[Ruta.length-2];

  const pagoExtra = useRef({recibido:0,cambio:false,restante:0});

  const [restante, setrestante] = useState(0);
  const [modalinfoConfig, setmodalinfoConfig] = useState({});
  const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const [modalSuccessConfig, setmodalSuccessConfig] = useState({});

  const navegar = (ruta) => {
    addRuta(ruta);
  };

  const calcularrestante=()=> {
    let totalpagado = CalculaPagos(pagos,true);
    
    const restante = VentaAPagar.total - parseInt(totalpagado);
    setrestante(restante);
  }

  function closeModaltabla(e) { setmodalTablaConfig({ isOpen: false });}
  function closeModalinfo(e) { 
    setEnableButton(false); 
    setmodalinfoConfig({ isOpen: false, botoncredito: false, inputPin:false});
  }
  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }
  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  const openmodalLoading = (titulo) => {
    titulo= titulo||"Procesando Datos";
    setmodalLoading({ isOpen: true, titulo });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };

  const borrarDatos = () => {
    addClient({});
    SetVentaAPagar({ total: 0 });
    VentasCredito([{}]);
    setpagos([
      {
        monto: 0.0,
        metodo: "",
        cuenta: ""
      },
    ]);
  };

  const hacerPago = async (newpagos) => {
    openmodalLoading();
    let mispagos = [];
    if (CalculaPagos(newpagos,true) != 0){
      for (let pago of newpagos) {
        const pay = {...pago,monto:moneyToValue(pago.monto || 0)};
        mispagos.push(pay);
      }
    }
    try {
      let datos ={
        pagos: mispagos,
        id: VentaAPagar.id,
        sucursal:getSucursal(),
        personal:getIdUser(),
        nombrePersonal:getNombre(),
        status:"PAGO"
      }

      const resp = await apiUsePromise({ url: "/update-pedido-especial", method: "POST",data: datos});

      const ticket = {
        item:VentaAPagar.headerpes.itemSelected,
        pagos:pagos,
        pagado:moneyToValue(pagos.reduce((total,pago)=> total + parseFloat(pago.monto),0)),
        id:VentaAPagar.headerpes.itemSelected.id,
        cliente:Clientes.id,
        subtotal:VentaAPagar.total,
        iva:0,
        total: VentaAPagar.total
      };
   
      if(pagoExtra.current.cambio==true){
        ticket.cambio=true;
        ticket.recibido = pagoExtra.current.recibido;
        ticket.restante= pagoExtra.current.restante;
      }
      closemodalLoading();
      setEnableButton(false);
      SetTicket(ticket);
      if(CalculaPagos(pagos,true)==0){
        setmodalinfoConfig({
          isOpen: true,
          title: "El monto de pago debe ser mayor a cero",
          botoncredito: false,
          botonconfirmar:false,
        });
        return
      }
      borrarDatos();
      if(CalculaPagos(pagos,true)==VentaAPagar.total){
        navegar("ticketPagadoPes");
      }else{
        navegar("ticketPagadoAbonosPes")
      }
    } catch (error) {
      setEnableButton(false);
      closemodalLoading();
      console.log('Error=>',error)
    }
  };

  const sendDatapagar = (e) => {
    //e.preventDefault();
    setEnableButton(true);
    openmodalLoading();

    let totalpagado = CalculaPagos(pagos,true)

    if (totalpagado > VentaAPagar.total) {
      const newpagos= cloneDeep(pagos);

      if(newpagos.length===1){
        const pagado = newpagos[0].monto;
        //if(newpagos[0].metodo=="EFECTIVO"){
        pagoExtra.current={recibido:totalpagado,restante:totalpagado -VentaAPagar.total,cambio:true}
        totalpagado = VentaAPagar.total;
        newpagos[0].excedente = moneyToValue(pagado);
        newpagos[0].monto = valueToMoney(VentaAPagar.total);
        hacerPago(newpagos);
        return;
      }
    } else if (totalpagado < VentaAPagar.total) {
      closemodalLoading();
      //let aunConCredito = VentaAPagar.total - totalpagado <= Clientes.creditoRestante;
      if (totalpagado>0) {
        setmodalinfoConfig({
          isOpen: true,
          title: "Venta a credito",
          message: "Está seguro que desea abonar a esta venta a crédito?.",
          botoncredito: true,
          botonconfirmar:false,
          textoBotonCredito: "abonar a la cuenta",
        });
      } else if (totalpagado==0) {
        setmodalinfoConfig({
          isOpen: true,
          title: "Venta a credito",
          message: (
            <p>
              No es posible abonar un monto en 0
            </p>
          ),
        });
      } else {
        setmodalinfoConfig({
          isOpen: true,
          title: "Venta a credito",
          message: (
            <p>
              No es posible convertir esta venta a crédito. El monto se excede
              por{" "}
              <Labelmoney twoDecimal={true}>
                {VentaAPagar.total - totalpagado - Clientes.creditoRestante}
              </Labelmoney>{" "}
            </p>
          ),
        });
      }
    } else {
      hacerPago(pagos);
    }
  };

  const addpay = (e) => {
    e.preventDefault();
    setpagos(pagos.concat({ monto: 0.0, metodo: "", cuenta : {} }));
  };

  const deletePago = (index) => {
    let newPagos = [...pagos];
    let misPagos = [];
    for (let i = 0; i < newPagos.length; i++) {
      if (i !== index) {
        misPagos.push(newPagos[i]);
      }
    }
    setpagos(misPagos);
  };

  Set.prototype.difference = function (otherSet) {
    var differenceSet = [];
    for (var elem of this) {
      if (!otherSet.has(elem)) differenceSet.push(elem);
    }
    return differenceSet;
  };

  const validaPagos=()=>{
    let valido = true;
    //console.log(pagos,'=>',pagado,"=>",VentaAPagar.total)
    if(!pagos.length||pagado ||VentaAPagar.total === 0||!pagos.reduce( (accumulator, currentValue) => accumulator && currentValue.metodo !== "",true )||!pagos.reduce( (accumulator, currentValue) => accumulator && currentValue.cuenta !== "", true  )){
      valido=false
    }
    //console.log('=>',valido)
    return valido;
  }


  useMemo(() => {
    calcularrestante();
  }, [pagos]);

  useEffect(()=>{
    if(VentaAPagar?.cob){
      setpagos([
        {
          monto: 0.0,
          metodo: "",
          cuenta: ""
        },
      ]);
    }
    if(!VentaAPagar?.total||!VentaAPagar?.pagos){return;}
    if(VentaAPagar.total!==VentaAPagar.pagos.reduce((total,pago)=> total + parseFloat(pago.monto),0)){
      
      setPagado(false);
      return;
    }
    
    setPagado(true);
    VentaAPagar.pagos.map(pago=>{
      pago.monto = valueToMoney(pago.monto)
    })
    setpagos(VentaAPagar.pagos);
  },[VentaAPagar])

  useEffect(() => {
    if(!Clientes.venta){return;}
    setVentaEx(Clientes.venta)
  }, [])
  

  return (
    <>
      <LoadingModal config={modalLoading} >
          <LoadingModal.Body>
            <LoadingModal.Title>{"Procesando Pago"}</LoadingModal.Title>
          </LoadingModal.Body>
      </LoadingModal>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={modalinfoConfig.title}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        {modalinfoConfig.inputPin?(<div className="flex justify-center w-full mb-2">
          <input 
          className="border-form w-full md:w-1/2" 
          type="password" 
          defaultValue=""
          onChange={(e) => {setPin(e.target.value)}}
          />
        </div>):null}
        <Modalinfo.Buttons>
          <>
            <button
              type="button"
              className="btn-gray2"
              onClick={closeModalinfo}
            >
              Cerrar
            </button>
            {modalinfoConfig.botoncredito ? (
              <button
                type="button"
                className="btn-green"
                onClick={(e) => {
                  if(e.detail>1){return}
                  closeModalinfo();
                  hacerPago(pagos);
                }}
              >
                {modalinfoConfig.textoBotonCredito}
              </button>
            ) : null}
            {modalinfoConfig.botonconfirmar ? (
              <button
                type="button"
                className="btn-blue1"
                disabled={modalinfoConfig.pin != pin }
                onClick={(e) => {
                  if(e.detail>1){return;}
                  closeModalinfo();
                  hacerPago(pagos);
                }}
              >
                {modalinfoConfig.textoBotonCredito}
              </button>
            ) : null}
          </>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>
      
      <Modalgeneric config={modalTablaConfig}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Historial de pagos</Modalgeneric.Title>
          <TableModal searchinput={false}
            columns={[
              { header: "Fecha", accessor: "fecha" },
              {
                header: "Método",
                // eslint-disable-next-line react/display-name
                accessor:"metodo",
              },
              {
              header: "monto",
              // eslint-disable-next-line react/display-name
              code: (pagos) => <Labelmoney twoDecimal={true}>{pagos.monto}</Labelmoney>,
              }
            ]}
            
            data={VentaAPagar?.pagos||null}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            className="btn-gray2"
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalsuccess config={modalSuccessConfig}>
        <Modalinfo.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalinfo.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
      <Container lg="px-80">
          <div className="size-2 mt-2 mb-1 mx-2 ">
            <div className="flex flex-col w-full">
              {oldRuta=="venta"||oldRuta=="pedidoEspecial"?null:<InputClientVenta />}
              <DatosClienteVenta venta={true} />
            </div>
            {oldRuta=="venta"||oldRuta=="pedidoEspecial"?null:
              <div className="size-2 w-full">
                <InputVenta ventaex={ventaEx} />
                <Pendientes  caja={true} getPendiente={venta=>{
                  setVentaEx(venta);
                }}/>
              </div>
            }
          </div>
          <table className="bg-gradient-to-b from-bluei via-bluei to-blue-900 h-full border-2 shadow-lg rounded-2xl mx-2">
            <thead className="top-0 rounded text-white text-sm font-semibold align-middle cursor-pointer uppercase">
              <tr className="text-xl h-16">
                <th key={0} className={"py-1 break-words"}>Forma Pago</th>
                <th key={1} className={"py-1 break-words"} >Cuenta</th>
                <th key={2} className={"py-1 break-words"}>Monto</th>
              </tr>
            </thead>
            <tbody>
             {pagos.map((item, index) => (
              <Pago
              key={index}
              config={{
                setdata: setpagos,
                deletepago: deletePago,
                data: pagos,
                restante:restante,
                index: index,
                formasPago: () => {
                  let todasFormasPago = new Set([
                    ...FormasP?.opciones.SS,
                  ]);
                  let formaspagousadas = new Set();
                  for (let i = 0; i < pagos.length; i++) {
                    if (i !== index) {
                      formaspagousadas.add(pagos[i].metodo);
                    }
                  }

                  return todasFormasPago.difference(formaspagousadas);
                },
                cuentas: Sucursal('cuentas'),
                importeTotal:VentaAPagar.total,
              }}
           /> 
              ))}
              <tr>
                <td></td>
                <td className="flex flex-wrap justify-center">
                  <button
                    className="btn-dark w-1/4 bg-teal-500 break-words"
                    type="button"
                    onClick={addpay}
                    disabled={
                      !pagos.reduce(
                        (accumulator, currentValue) =>
                          accumulator && currentValue.monto > 0,
                        true
                      ) ||
                      !pagos.reduce(
                        (accumulator, currentValue) =>
                          accumulator && currentValue.metodo !== "",
                        true
                      )||
                      !pagos.reduce(
                        (accumulator, currentValue) =>
                          accumulator && currentValue.cuenta !== "",
                        true
                      )
                    }
                    style={{ transition: "all .15s ease" }}
                  >
                    +
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot className=" text-white font-semibold">
            <tr>
                <td></td>
                <td className="text-right font-semibold pr-4 lg:py-1 lg:text-2xl md:text-lg">IMPORTE TOTAL:</td>
                <td className="font-semibold lg:py-1 lg:text-2xl md:text-lg"><Labelmoney twoDecimal={true}>{VentaAPagar.total}</Labelmoney></td>
              </tr>
              <tr className="">
                <td></td>
                <td className="text-right font-semibold pr-4 lg:py-1 lg:text-2xl md:text-lg">PAGADO:</td>
                <td className="font-semibold lg:py-1 lg:text-2xl md:text-lg"><Labelmoney twoDecimal={true}>{moneyToValue(pagos.reduce((total,pago)=> total + parseFloat(pago.monto),0))}</Labelmoney></td>
              </tr>
              <tr className="">
                <td></td>
                <td className="text-right font-semibold pr-4 lg:py-1 lg:text-2xl md:text-lg">RESTANTE:</td>
                <td className="font-semibold lg:py-1 lg:text-2xl md:text-lg"><Labelmoney twoDecimal={true}>{(restante>=0)?restante:0}</Labelmoney></td>
              </tr>
              <tr className="">
                <td></td>
                <td className="text-right font-semibold pr-4 lg:py-1 lg:text-2xl md:text-lg">CAMBIO:</td>
                <td className="font-semibold lg:py-1 lg:text-2xl md:text-lg"><Labelmoney twoDecimal={true}>{(restante<=0)?(restante*-1):0}</Labelmoney></td>
              </tr>
            </tfoot>
          </table>

          <div className="container lg:px-5 lg:py-2 mx-auto w-full ">
            <div className=" flex flex-auto md:flex-row flex-col w-3/4 mx-auto">
              <button className="btn-green1 w-full"
                    onClick={(e) => {
                      setmodalTablaConfig({ isOpen: true, pagos:VentaAPagar.pagos});
                    }}
                  >
                  Ver pagos
                  </button>
              <button
                className="btn-gray2 w-full md:mx-2"
                type="button"
                onClick={(e) => {
                  borrarDatos();
                  delRuta();
                }}
                style={{ transition: "all .15s ease" }}
              >
                Cancelar
              </button>
              <button
                className={(validaPagos()?(restante<=0?"btn-green w-full":"btn-dark bg-yellow-500 w-full"):"hidden")}
                type="button"
                disabled={enableButton}
                onClick={(e) => {
                  if(e.detail>1){return}
                  sendDatapagar(e);
                }}
                style={{ transition: "all .15s ease" }}
              >
                {restante>0?"Abono":"Pagar"}
              </button>
            </div>
          </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  VentaAPagar: state.VentaAPagar,
  Clientes: state.ClientesVenta,
  FormasP: state.Catalogos.FORMASPAGO,
  Ruta: state.Ruta,
});

const mapDispatchToProps = (dispatch) => ({
  delRuta: () => dispatch(returnRuta()),
  addRuta:(ruta)=> dispatch(setRuta(ruta)),
  addClient: (cliente) =>
    dispatch({
      type: "ADD_CLIENTVENTA",
      item: cliente,
    }),
  SetVentaAPagar: (datos) =>
    dispatch({
      type: "SET_VENTAAPAGAR",
      ventaapagar: datos,
    }),
  VentasCredito: (datos) =>
    dispatch({
      type: "SET_VENTACREDITO",
      ventascredito: datos,
    }),
  SetTicket: (datos) =>
    dispatch({
      type: "SET_TICKET",
      ticket: datos,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Caja);
