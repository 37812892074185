import React from "react";
import Navbar from "../components/Navbar.js";

const Layout = (props) => {

  return (
    <div className="flex flex-col h-screen bg-bluedemond " onKeyDown={e=>{
      if(e.key=="F5"){ e.preventDefault();}
    }} onContextMenu={e=>{e.preventDefault()}}>
      <Navbar />
      {props.children}
    </div>
  );
};

export default Layout;
