import React, { useState, useEffect } from "react";
import { setsearchPersonal, setRuta } from "../../redux/Actions";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apiUse, getOffices, updating, validatePermission, disabledFieldIsNot, moneyToValue, getAlcance, getPermisos, getIdUser, havePermission, encryptData, decryptData,} from "../../services/functions.js";
import Modaldanger from "../../components/modal/info";
import Modalsuccess from "../../components/modal/success";
import Multiselector from "../../components/Multiselector";
import Historial from "../../components/Historial";
import InputPrecioPanel from "../../components/InputPrecioPanel";
import ListaMap from "../../components/ListaMap";
import { useCatalogo } from "../../Hooks/useApis";
import LoadingModal from "../../components/modal/LoadingModal";
import LabelErrors from "../../components/LabelErrors";
import { permisos } from "../../static/dat/datos.js";
import Toggle from "../../components/Toggle.jsx";

const schema = yup.object().shape({
  nombre: yup.string().required("El nombre es obligatorio"),
  rfc: yup.string(),
  sucursal: yup.string().required("La sucursal es obligatoria"),
  id: yup.string().required("Por favor, el ID es obligatorio"),
  password: yup.string().required("La contraseña es la obligatoria"),
  direccion: yup.string(),
  email: yup.string().email("email invalido"),
  telefono: yup .string(),
  puesto: yup.string().required("El departamento es obligatorio"),
    sueldo: yup .number().positive("El valor debe ser mayor a cero"),
  nss: yup.string(),
  fechaIngreso: yup.string().required("La fecha de ingreso es obligatoria"),
    activo: yup.bool().required("El campo es obligatorio"),
    alcance: yup.array().of(yup.string()),
    permisos: yup.array().of(yup.string()),
    socio: yup.bool().required("El campo es obligatorio"),  
});

const Formulariopersonal = (props) => {
  const [idvalue, setidvalue] = useState(props.datospersonal?.id || "");
  const [suc,setSuc]=useState((props.datospersonal?.sucursal?props.datospersonal.sucursal.substring(0,2):""));
  const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [Departamentos,setDepartamentos]=useState([]),
  [socios,setSocios]=useState(),
  [showPass,setShowPass] = useState(false),
  [editable,setEditable]=useState(false),
  [modalLoading, setmodalLoading] = useState({});
  const setsearchPersonal = props.setsearchPersonal;
  const permisoEsp = havePermission(permisos.socios);
  const validatepermission = validatePermission(props.datospersonal);

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });


  register({ name: "sueldo" });
  register({ name: "activo"});
  register({ name: "alcance"});
  register({ name: "permisos" });
  register({ name: "historial"});
  register({ name: "socio"});
  register({ name: "limite"});


  const nameEndpoind = (data) => {
    if (updating(data)) {
      return "/update-personal";
    }

    return "/insert-personal";
  };

  const handleSuccessful = (data) => {
    const endpoind = nameEndpoind(props.datospersonal);
    setmodalSuccessConfig({
      isOpen: true,
      message:
        endpoind === "/insert-personal"
          ? "El registro se realizó con éxito"
          : "La actualización se realizó con éxito",
    });
    setsearchPersonal(data);
  };

  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message || "EL id ya existe",
    });
  };


  const sendData = (dataform) => {
    if(!props.datospersonal?.id){dataform.id = suc+idvalue;}
    console.log('>>>',dataform)
    
    dataform.password = encryptData(dataform.password);
    const endpoind = nameEndpoind(props.datospersonal);
    apiUse({
      url: endpoind,
      method: "POST",
      data: dataform,
      handleSuccessful: handleSuccessful,
      handleError: handleError,
    });
  };

  const validatepermissioncreate = validatepermission.create(  permisos.regUsuario  );

  function verifyPermission() {
    if(editable){return true;}
    return disabledFieldIsNot([
      validatepermissioncreate,
      validatepermission.update(permisos.modUsuario),
    ]);
  }

  const funcionError=(error)=>{
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: (error||"")+" Error al cargar el catalogos",
    });
  }

  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };

  useEffect(()=>{
    openmodalLoading();
    useCatalogo("DEPARTAMENTOS",(resp)=>{
        if(!resp?.opciones){
          funcionError();
          return;
        }
        setDepartamentos(resp.opciones);
        closemodalLoading();
      },funcionError);

  },[])


  useEffect(() => {
    if([true,false].includes(props.datospersonal?.socio)){ setValue('socio',props.datospersonal.socio); setSocios(props.datospersonal.socio); }
    else{setValue('socio',false); setValue("limite",{}) }
    if(props.datospersonal?.sueldo){ setValue('sueldo',moneyToValue(props.datospersonal.sueldo)); }
    if([true,false].includes(props.datospersonal?.activo)){ setValue('activo',props.datospersonal.activo); }
    else{setValue('activo',false); }
  }, [props.datospersonal]);

  useEffect(()=>{
    if(socios==false){ setValue("limite",{}); }
  },[socios])

  useEffect(()=>{
    if(props.datospersonal?.id){setidvalue(props.datospersonal.id);}
    if(props.datospersonal?.id!==getIdUser()){setEditable(false); return;}
    setEditable(true);
  },[props.datospersonal])


  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Movimientos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal> 
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalsuccess config={modalSuccessConfig}>
        <Modalsuccess.Message title={"Éxito"}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2  "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
      <form id="formpersonal" autoComplete="new-password">
        <div className="flex flex-wrap items-top mb-6">
          <div className="w-full lg:w-6/12 px-4 ml-auto">
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Nombre
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="nombre"
                  readOnly={(props.datospersonal?.nombre?true:false)}
                  placeholder="Nombre"
                  ref={register}
                  defaultValue={props.datospersonal?.nombre}
                  style={{ transition: "all .15s ease" }}
                  onBlur={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                    if(e.target.value==""){setidvalue("")}
                    let palabras = e.target.value.toUpperCase().split(" ");
                    let code = "";
                    palabras.map(word=>{
                      code+=word.charAt(0);
                    })
                    setidvalue(code);
                  }}
                />
              <LabelErrors name="nombre" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                RFC
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="rfc"
                  readOnly={verifyPermission()}
                  placeholder="Rfc"
                  ref={register}
                  onBlur={e=>{e.target.value=e.target.value.toUpperCase();}}
                  defaultValue={props.datospersonal?.rfc}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="rfc" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Sucursal
              </label>
                <select
                  name="sucursal"
                  ref={register}
                  disabled={verifyPermission()}
                  defaultValue={props.datospersonal?.sucursal || ""}
                  className="border-form w-full"
                  onChange={e=>{
                    const sucursal = e.target.value.toUpperCase();
                    if(sucursal==""){setSuc(""); return;}
                    setSuc(sucursal.substring(0,2));
                  }}
                >
                  <option key={-1} value="" selected={""}>{""}</option>
                  {getOffices().map((item, index) => (
                    <option key={index} defaultValue={props.datospersonal?.sucursal===item} selected={props.datospersonal?.sucursal===item}>
                      {item}
                    </option>
                  ))}
                </select>
                <LabelErrors name="sucursal" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                ID
              </label>
              <div className="border-form w-full">
                {props.datospersonal?.id?"":<label>{(props.datospersonal?.sucursal?props.datospersonal?.sucursal.substring(0,2):suc)}</label>}
                  <input
                    type="text"
                    className="bg-transparent outline-none font-semibold uppercase"
                    maxLength={"10"}
                    autoComplete="none"
                    name="id"
                    ref={register}
                    readOnly={(props.datospersonal?.id?true:false)}
                    defaultValue={props.datospersonal?.id || idvalue}
                    onBlur={(e) => {
                      setidvalue(e.target.value.toUpperCase().substring(0, 10));
                    }}
                    style={{ transition: "all .15s ease" }}
                  />
              </div>
              <LabelErrors name="id" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Contraseña
              </label>
              <div className="w-full flex flex-wrap justify-center md:justify-start">
                <button type="button" className="text-gray-800 hover:text-gray-500 text-lg w-4 h-4 mr-2" onClick={e=>{
                  setShowPass(!showPass)
                }}>
                  <i className={(showPass?"fas fa-eye-slash":"fas fa-eye")}/>
                </button>
                  <input
                    type={(!showPass?"password":"text")}
                    className="border-form w-10/12 uppercase"
                    autoComplete={(!showPass?"new-password":"off")}
                    name="password"
                    readOnly={verifyPermission()}
                    placeholder="Contraseña"
                    ref={register}
                    defaultValue={decryptData(props.datospersonal?.password||"")}
                    style={{ transition: "all .15s ease" }}
                    onBlur={e=>{e.target.value=e.target.value.toUpperCase().trim()}}
                  />
                  <LabelErrors name="password" errors={errors}/>
              </div>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Dirección
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="direccion"
                  readOnly={verifyPermission()}
                  placeholder="Dirección"
                  ref={register}
                  defaultValue={props.datospersonal?.direccion}
                  style={{ transition: "all .15s ease" }}
                  onBlur={(e) => {
                    e.target.value = e.target.value.toUpperCase().trim();
                  }}
                />
               <LabelErrors name="direccion" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Email
              </label>
                <input
                  type="text"
                  className="border-form w-full"
                  name="email"
                  readOnly={verifyPermission()}
                  placeholder="Email"
                  ref={register}
                  defaultValue={props.datospersonal?.email}
                  style={{ transition: "all .15s ease" }}
                />
                <LabelErrors name="email" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Teléfono
              </label>
                <input
                  type="text"
                  className="border-form w-full"
                  maxLength={"10"}
                  name="telefono"
                  readOnly={verifyPermission()}
                  placeholder="Teléfono"
                  defaultValue={props.datospersonal?.telefono}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                />
                <LabelErrors name="telefono" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Departamento
              </label>
                <select
                  name="puesto"
                  ref={register}
                  disabled={verifyPermission()}
                  defaultValue={props.datospersonal?.puesto || ""}
                  className="border-form w-full"
                >
                  {Departamentos.map((item, index) => (
                    <option key={index} defaultValue={props.datospersonal?.puesto===item} selected={props.datospersonal?.puesto===item}>
                      {item}
                    </option>
                  ))}
                </select>
                <LabelErrors name="puesto" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Sueldo semanal o quincenal
              </label>
              <InputPrecioPanel
              className="bg-white"
              form={true}
              min={0}
              newValor={props.datospersonal.sueldo||0}
              placeholder="Sueldo mensual"
              disabled={verifyPermission()}
              onBlur={valor=>{
                setValue('sueldo',moneyToValue(valor));
              }}
              />
              <LabelErrors name="sueldo" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                NSS
              </label>
              <input
                  type="text"
                  className="border-form w-full"
                  name="nss"
                  readOnly={verifyPermission()}
                  placeholder="NSS"
                  ref={register}
                  defaultValue={props.datospersonal?.nss}
                  style={{ transition: "all .15s ease" }}
              />
              <LabelErrors name="nss" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Fecha de ingreso
              </label>
                <input
                  type="date"
                  className="border-form w-full"
                  name="fechaIngreso"
                  readOnly={verifyPermission()}
                  placeholder="Fecha de ingreso"
                  defaultValue={props.datospersonal?.fechaIngreso}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="fechaIngreso" errors={errors}/>
            </div>
            <div className="flex w-full py-2">
              <label htmlFor="toggle" className=" font-semibold pr-3  text-blue-700">
                Activo
              </label>
                <Toggle checked={props.datospersonal?.activo} disabled={verifyPermission()}  onClick={(e,isOn)=>{
                  setValue("activo", isOn);
                }}/>
                <LabelErrors name="activo" errors={errors}/>
            </div>
          </div>

          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label className="block textf  text-blue-700" htmlFor="grid-password">
                Alcance de Trabajo
              </label>
              <Multiselector
                selected={props.datospersonal?.alcance}
                disabled={verifyPermission()}
                filters={true}
                savechange={(data) => {
                  setValue("alcance", data);
                }}
                data={getAlcance()}
              />
              <LabelErrors name="alcance" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf  text-blue-700" htmlFor="grid-password">
                Permisos
              </label>
              <Multiselector
                selected={props.datospersonal?.permisos}
                disabled={verifyPermission()}
                filters={true}
                savechange={(data) => {
                  setValue("permisos", data);
                }}
                data={getPermisos()}
              />
              <LabelErrors name="permisos" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Historial
              </label>
              <Historial
                savechange={(data) => {
                  setValue("historial", data);
                }}
                disabled={verifyPermission()}
                data={props.datospersonal?.historial}
              />
            </div>
            {permisoEsp?
              <div className="flex items-center w-full py-2 gap-2">
                <label htmlFor="toggle" className="textf ">
                  Socio/Comisionista
                </label>
                  <Toggle name="socio" disabled={verifyPermission()} checked={props.datospersonal?.socio} onClick={(e,isOn)=>{
                    setValue("socio",isOn)
                    setSocios(isOn)
                  }}/>
                  <LabelErrors name="socio" errors={errors}/>
              </div>
            :null}
            {permisoEsp&&socios==true?
              <div className="relative w-full mb-3">
                <label className="block textf" htmlFor="grid-password">
                  Limite Mensual
                </label>
                  <ListaMap
                    savechange={(data) => {
                      setValue("limite", data);
                    }}
                    readOnly={verifyPermission()}
                    data={props.datospersonal?.limite}
                    lista={getAlcance()}
                    nameCampo="Sucursal"
                  />
                <LabelErrors name="limite" errors={errors}/>
              </div>
            :null}
           
            <div className="md:flex md:items-center mb-6">
              <div className=" lg:w-6/12 px-6">
                <button
                  className="btn-gray2 w-full"
                  type="button"
                  onClick={(e) => {
                    props.setRuta("menu");
                  }}
                  style={{ transition: "all .15s ease" }}
                >
                  Cancelar
                </button>
              </div>
              <div className=" lg:w-6/12 px-6">
                <button
                type="button"
                className="btn-blue w-full"
                disabled={verifyPermission()}
                style={{ transition: "all .15s ease" }}
                onClick={handleSubmit(sendData)}>
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
const mapStateToProps = (state) => ({
  datospersonal: state.SearchPersonal,
});

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
  setsearchPersonal: (personal) => dispatch(setsearchPersonal(personal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Formulariopersonal);
