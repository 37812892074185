import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import {  useGetClient, useFactura } from '../../../Hooks/useApis'
import { CalcularDescuentoPorPorcentaje,  FechaLocalToUTC,  FechaUTCToLocal,  GetFechaActualSeparado,   GetFechaActualUTC,   SucursalAll, apiUsePromise,  decimales,  ConvertDecimal,  getSucursal, getTotal, moneyToValue,  tipoCelda, valueToMoney, valueToMoney2, formatoYMDtoDMY, GetFechaSeparado, havePermission, fechaMax, AddDiasFecha, GetFechaActual, Sucursal } from '../../../services/functions'
import { Formas_Pago_Sat, Metodo_Pago_Sat, Uso_Cfdi, Clave_Indigo_Sat_Short, Motivos_Cancelacion, permisos } from '../../../static/dat/datos'
import Container from '../../Container'
import DatosClienteVenta from '../../DatosClienteVenta'
import InputClient from '../../inputClient'
import Labelmoney from '../../labelmoney'
import Modaldanger from '../../modal/danger'
import LoadingModal from '../../modal/LoadingModal'
import Modalsuccess from '../../modal/success'
import Selectormap from '../../Productos/Selectormap'
import TablaTicketFact from '../TablaTicketFact'
import LabelFecha from '../../LabelFecha'
import {motion} from "framer-motion"
import BuscaTicket from "../../Ventas/BuscaTicket.jsx";
import Modalgeneric from '../../modal/generic';
import cloneDeep from 'lodash/cloneDeep';
import Modalinfo from '../../modal/info'
import TableReactWindows from '../../Reportes/Cash/TableReactWindows'
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from '../../Excel/DataToXlsx.jsx'
import Toggle from '../../Toggle.jsx'
import InputPrecioPanel from '../../InputPrecioPanel.jsx'
import InputSelectormap from '../../Productos/InputSelectormap.jsx'
import { btnScale, btnTap } from '../../../static/motionStyle.js'

const TicketFact = ({Cliente,windowSize,addClient,PropsReportes}) => {
  const [itemsFact,setItemsFact]=useState([]),
  [Ventas,setVentas]=useState([]),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalTicketFact, setModalTicketFact] = useState({}),
  [modalPreFact, setModalPrefact] = useState({}),
  [modalCancel, setModalCancel] = useState({}),
  [modalComplement, setModalComplement] = useState({}),
  [formaPago,setFormaPago]=useState(),
  [metodoPago,setMetodoPago]=useState(),
  [checkPeriodo,setCheckPeriodo]=useState({"Diario":false,"Semanal":false,"Mensual":false}),
  [itemSelect,setItemSelect]=useState(),
  [checkActivo,setCheckActivo]=useState(false),
  [modalFactGlobal,setModalFactGlobal]=useState({}),
  [fecha,setFecha]=useState({fechai:PropsReportes.fecha_Inicial,fechaf:PropsReportes.fecha_Final});

  const selectCfdi = useRef(""),
  inputRelacion=useRef(""),
  inputObservacion=useRef(""),
  inputTk=useRef(""),
  inputRefBank=useRef(""),
  inputRelacionCancel=useRef(""),
  arrayPrefactura=useRef([]),
  inputMontoFactGlobal=useRef(0),
  btnToggle=useRef(""),
  
  selectCfdiEdit = useRef(""),
  selectMotivo = useRef(""),
  inputRefBankEdit=useRef(""),
  selectFormaPagoEdit=useRef(""),
  inputRelacionEdit=useRef(""),
  inputObservacionEdit=useRef(""),
  selectMetodoPagoEdit=useRef(""),

  inputFechaIComplement=useRef(""),
  inputMeses=useRef(""),
  inputFechaFComplement=useRef(""),
  inputFechaEmision=useRef(""),
  
  inputCfdiRfc=useRef("");

  const DatosEmisor = SucursalAll();
  const fechaI = FechaLocalToUTC(PropsReportes.fecha_Inicial),fechaF=FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59);
  const Meses={'01':'ENERO', '02':'FEBRERO', '03':'MARZO', '04':'ABRIL', '05':'MAYO', '06':'JUNIO', '07':'JULIO', '08':'AGOSTO', '09':'SEPTIEMBRE', '10':'OCTUBRE','11':'NOVIEMBRE', '12':'DICIEMBRE'};
  const permisoCfdis = havePermission(permisos.configurar)


  const excelCfdis=async(datos,cfdis,rfc)=>{
    const data = datos;
    if(!data?.length){handleError("No hay datos que exportar"); return;}
    openmodalLoading();
    const workb = await GenerateXlsx();
    if(workb?.error){
      closemodalLoading();
      handleError(workb.error);
      return
    }

    const ano = PropsReportes.fecha_Inicial.split('-')[0],mes= PropsReportes.fecha_Inicial.split('-')[1];
    const nameFile="Facturas "+rfc+"-"+ano+mes,
    headerFile="Reporte de Facturas RFC: "+rfc+" de "+Meses[GetFechaSeparado(PropsReportes.fecha_Inicial).mes]+" del "+ano,
    sheet1 = workb.sheet(0),sheet2=workb.addSheet("All"),colPlus = 4;

    sheet1.cell("A1").value(headerFile);
    sheet1.cell("A1").style("bold",true);
    sheet1.cell("A1").style("fontSize",18);

    const sizeData = data.length + colPlus,
    header={'Serie':13,'Timbrados':12};

    Object.keys(header).map((key,index)=>{
      sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
      sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
      sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
      sheet1.column(columnasXlsx[index]).width(header[key]); 
    })

    data.forEach((factura,index) => {
      sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(factura.serie);
      sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(factura.timbrados);

      sheet1.cell(columnasXlsx[1]+(colPlus+index)).style(formatoCelda.number);
    });

    sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");
    sheet1.cell(columnasXlsx[1]+sizeData).style(formatoCelda.number);


    sheet2.cell("A1").value("Reporte Facturas Masivo ");
    sheet2.cell("A1").style("bold",true);
    sheet2.cell("A1").style("fontSize",18);

    const header2=['Folio','Fecha','Rfc Cliente','Cliente','Status','Importe','UUID','Id Facturama'],
    sizeColumn2=[12,13,17,60,13,13,42,35];
    let cont=0;

    header2.forEach((value,index)=>{
      sheet2.cell(columnasXlsx[index]+(colPlus-1)).value(value);
      sheet2.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
      sheet2.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
      sheet2.column(columnasXlsx[index]).width(sizeColumn2[index]);
    })


    cfdis.forEach((factura,index) => {
      sheet2.cell(columnasXlsx[0]+(colPlus+cont)).value(factura.Serie+"-"+factura.Folio);
      sheet2.cell(columnasXlsx[1]+(colPlus+cont)).value(FechaUTCToLocal(factura.Date).fecha||"");
      sheet2.cell(columnasXlsx[2]+(colPlus+cont)).value(factura.Rfc);
      sheet2.cell(columnasXlsx[3]+(colPlus+cont)).value(factura.TaxName);
      sheet2.cell(columnasXlsx[4]+(colPlus+cont)).value(factura.Status);
      sheet2.cell(columnasXlsx[5]+(colPlus+cont)).value(factura.Total);
      sheet2.cell(columnasXlsx[6]+(colPlus+cont)).value(factura.Uuid);
      sheet2.cell(columnasXlsx[7]+(colPlus+cont)).value(factura.Id);

      sheet2.cell(columnasXlsx[5]+(colPlus+cont)).style(formatoCelda.contabilidad);
      cont++;
    });
    
    const response =await SaveXlsx(workb,nameFile,true);
    if(response?.error){
      closemodalLoading();
      handleError(response.error);
      return
    }
    closemodalLoading();
    openModalSuccess("Archivo generado correctamente","Descarga Completa");
  }

  function validateclient(client) {
    if (client.activo === true) { addClient(client);} 
    else {openmodalError("Cliente inhabilitado","Error en Cliente");}
  }

  const getFacturas=async()=>{
    setItemSelect(null);
    const peticion = {
      hashKey:{valor:("FACTURA#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
      decimalEncoding:'float',
    };

    arrayPrefactura.current = [];
    openmodalLoading();
    try{
      await apiGetFacturas(peticion);
      //console.log(arrayPrefactura.current);
      if(!arrayPrefactura.current.length) {arrayPrefactura.current=[{}];}
      closemodalLoading();      
    }catch(Error){
      closemodalLoading();
      setmodaldangererrorConfig({
        isOpen: true,
        message:  Error,
      });
    }
  }

  const getPrefacturas=async()=>{
    setItemSelect(null);
    const peticion = {
      hashKey:{valor:("FACTURA#PENDIENTE#"+getSucursal()),nombre:"id"},
      decimalEncoding:'float',
      //sortKey:{valor:[fecha_Inicial,fechaFinal],operador:"between",nombre:"type"},
    };

    arrayPrefactura.current = [];
    openmodalLoading();
    try{
      await apiGetPrefacturas(peticion);
      //console.log(arrayPrefactura.current);
      if(!arrayPrefactura.current.length){ arrayPrefactura.current=[{}]; }
      closemodalLoading();      
    }catch(Error){
      closemodalLoading();
      setmodaldangererrorConfig({
        isOpen: true,
        message:  Error,
      });
    }    
  }

  const apiGetPrefacturas=async(peticion,petLast)=>{
      if(petLast){
        peticion.LastEvaluatedKey = petLast;
      }
      const responsePrefacturas= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticion});
      const prefacturas = responsePrefacturas.data.datos;
      //console.log('==>',prefacturas);
      let datos =[...arrayPrefactura.current];
      prefacturas.forEach(fact=>{
        const prefact = fact.prefactura;
        fact.monto = moneyToValue(fact.monto);
        fact.Name = prefact.Receiver.Name;
        fact.Rfc = prefact.Receiver.Rfc;
        fact.Observations = prefact?.Observations || "";
        fact.PaymentMethod = prefact.PaymentMethod;
        fact.PaymentForm = Metodo_Pago_Sat[prefact.PaymentForm];
        fact.idSat = prefact?.idSat || "";
        fact.idFacturama = prefact?.idFacturama || "";
        fact.folioFact = (prefact?.serie || "") + (prefact?.folio || "");
        datos.push(fact)
      })
      //console.log(datos)
      arrayPrefactura.current = datos;
      if(responsePrefacturas.data?.LastEvaluatedKey){
        await apiGetPrefacturas(peticion,responsePrefacturas.data.LastEvaluatedKey);
      }
  }

  const apiGetFacturas=async(peticion,petLast)=>{
    if(petLast){
      peticion.LastEvaluatedKey = petLast;
    }
    const responseFacturas= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticion});
    const facturas = responseFacturas.data.datos;
    //console.log('==>',facturas);
    let datos =[...arrayPrefactura.current];
    facturas.forEach(fact=>{
      fact.monto = moneyToValue(fact.monto);
      fact.Name = fact.razon;
      fact.Rfc = fact.rfc;
      fact.Observations = fact?.observaciones || "";
      fact.PaymentMethod = fact.formaPago;
      fact.PaymentForm = Metodo_Pago_Sat[fact.metodoPago];
      fact.folioFact = (fact?.serie?fact.serie+"-":"") + (fact?.folio || "");
      datos.push(fact)
    })
    arrayPrefactura.current = datos;
    if(responseFacturas.data?.LastEvaluatedKey){
      await apiGetFacturas(peticion,responseFacturas.data.LastEvaluatedKey);
    }
}
  
  const eliminarVenta=(ticket)=>{
    setVentas(Ventas.filter(venta=>venta.shortId!==ticket));
    setItemsFact(itemsFact.filter(item=>item.shortId!==ticket));
  }

  const limpiarFormulario=()=>{
    inputObservacion.current.value ="";
    inputRefBank.current.value ="";
    inputRelacion?.current?inputRelacion.current.value ="":"";
    inputTk.current.value = "";

    inputFechaEmision.current.selectedIndex = -1;
    selectFormaPagoEdit.current.selectedIndex = -1;
    selectMetodoPagoEdit.current.selectedIndex = -1;
    inputObservacionEdit.current.value = "";
    inputRefBankEdit.current.value = "";


    if(Cliente?.rfc=="XAXX010101000"){
      btnToggle?.current?.dataset?.ison==true?btnToggle.current.click():"";
      inputMeses?.current?inputMeses.current.value=Meses?.[GetFechaActualSeparado().mes]:"";
      setCheckActivo(false);
    }
   
    setVentas([]);
    setItemsFact([]);
    addClient({});
  }

  const timbrarCfdi=async(preFactura)=>{
    const cfdi = preFactura.prefactura;
    if(!cfdi){return;}
    cfdi.Opcion="timbrar";
    cfdi.IdPrefactura=preFactura.type;
    //console.log(itemSelect)
    if(DatosEmisor.rfc!==cfdi.Issuer.Rfc){cfdi.Issuer.Rfc= DatosEmisor.rfc}
    if(DatosEmisor.razonSocial!==cfdi.Issuer.Name){cfdi.Issuer.Name= DatosEmisor.razonSocial}
    if(DatosEmisor.regimen!==cfdi.Issuer.FiscalRegime){cfdi.Issuer.FiscalRegime= DatosEmisor.regimen}
    if(DatosEmisor.codigo_postal!==cfdi.ExpeditionPlace){cfdi.ExpeditionPlace= DatosEmisor.codigo_postal}

    openmodalLoading();
    //console.log('timbrar',cfdi);
    
    try{
      let resp = await useFactura(cfdi);
      resp = resp.data
      closemodalLoading();
        if(resp?.item?.opcion=="guardar"){
          setmodalSuccessConfig({
            isOpen: true,
            title: "La prefactura de agrego con exito ",
            message: resp?.mensaje,
          });
          limpiarFormulario();
          return;
        }
        const linkSource = `data:application/pdf;base64,${resp.pdf}`;
        const downloadLink = document.createElement("a");
        downloadLink.download = resp.factura.serie+"-"+resp.factura.folio+".pdf";
        downloadLink.href = linkSource;
        downloadLink.click();
        closeModalPrefact();
        getPrefacturas();
      
    }catch(error){
      console.log('Error>',error)
      openmodalError(error);
      closemodalLoading();
    }
  }

  const crearCfdi=async()=>{
    openmodalLoading();
    let tks =[];
    Ventas.forEach(vt=>{
      //console.log(vt)
      tks.push({id:vt.id,type:vt.type});
    })
    //"Email": Cliente.email,
    const cfdi ={
      "Opcion":"guardar",
      "Relations":"",
      "PaymentBank":"",
      "IdPrefactura":"",
      "PaymentAccountNumber":inputRefBank?.current?.value||"",
      "GlobalInformation":"",
      "Observations":"",
      "Tickets": tks,
      "Email": Cliente.email,

      "Sucursal":DatosEmisor.id,
      "Serie":DatosEmisor.id,
      "CfdiType": "I",
      "URLLogo":"",
      "PaymentForm": metodoPago,
      "PaymentMethod": formaPago,
      "ExpeditionPlace": DatosEmisor.codigo_postal,
      "Issuer":{
        "Name": DatosEmisor.razonSocial,
        "Rfc": DatosEmisor.rfc,
        "FiscalRegime": DatosEmisor.regimen,
        "idClient": Cliente.id
      },
      "Receiver": {
        "Rfc": Cliente.rfc,
        "CfdiUse": Cliente.uso_cfdi,
        "Name": Cliente.razonSocial,
        "FiscalRegime":  Cliente.regimen,
        "TaxZipCode": Cliente.codigopostal,
        //"idClient": Cliente.id
      },
    };

    if(Cliente.rfc=="XAXX010101000"){
      const fechaS = GetFechaActualSeparado(),mes=Object.keys(Meses).find(key=> Meses[key] == inputMeses.current.value);
      if(!checkPeriodo.Diario&&!checkPeriodo.Mensual&&!checkPeriodo.Semanal){
        openmodalError("Cuando se factura a Publico en general es obligatorio seleccionar el periodo de facturacion");
        return;
      }
      if(!mes){
        openmodalError("Cuando se factura a Publico en general es obligatorio seleccionar el mes");
        return;
      }
      const periodo =(cfdi.Issuer.FiscalRegime == "621" ? "05" : (checkPeriodo.Diario ? "01" : (checkPeriodo.Semanal ? "02" : "04")));
      cfdi.GlobalInformation= {
        "Periodicity" : periodo,// (cfdi.Receiver.FiscalRegime == "621" ? "05" : (rbDiario.Checked ? "01" : (rbSemanal.Checked ? "02" : "04"))),
        "Months" : mes  ,
        "Year" : fechaS.ano,
      }
    }

    if(checkActivo){
      if(inputRelacion?.current?.value!==""){
        cfdi.Relations ={
          "Type":"04",
          "Cfdis": [{"Uuid":inputRelacion.current.value}]
        }
      }else{
        openmodalError("El UUID de la factura es obligatorio cuando se activa la opcion Factura Relacionada");
        return;
      }
    }
    if(inputObservacion.current.value!==""){
      cfdi.Observations=inputObservacion.current.value
    }

    let items=[];

    itemsFact.forEach(prod=>{
        items.push({
              "ProductCode": prod.ProductCode,
              "idProduct": prod.idProduct,
              "Description": prod.Description,
              "UnitCode": prod.UnitCode,
              "Quantity": prod.Quantity,
              "UnitPrice": prod.UnitPrice,
              "Subtotal": prod.Subtotal,
              "TaxObject": prod.TaxObject,
              "Discount": prod.Discount,
              "Taxes": prod.Taxes,
              "Total": prod.Total
        })
    })

    const peticion={
        "Issuer": {
            "Name": "INGRID XODAR JIMENEZ",
            "Rfc": "XOJI740919U48",
            "FiscalRegime": "612",
            "idClient": "SYS"
        },
        "Receiver": {
            "Rfc": "MISC491214B86",
            "CfdiUse": "G03",
            "Name": "CECILIA MIRANDA SANCHEZ",
            "FiscalRegime": "612",
            "TaxZipCode": "01010"
        },
    };
    /*cfdi.Issuer = peticion.Issuer;
    cfdi.Receiver = peticion.Receiver;
    cfdi.GlobalInformation = "";*/

    cfdi.Items = items;
    /*console.log('ventas ',Ventas);
    console.log('Items ',itemsFact)
    console.log(cfdi);
    closemodalLoading();*/
    
    try{
      let resp = await useFactura(cfdi);
      resp = resp.data
      closemodalLoading();
      if(resp?.item?.opcion=="guardar"){
        closeModalTicketFact();
        limpiarFormulario();
        setmodalSuccessConfig({
          isOpen: true,
          title: "La prefactura de agrego con exito ",
          message: resp?.mensaje,        
        });
        getPrefacturas();
        return;
      }
    }catch(error){
      //console.log(JSON.stringify(error))
      openmodalError(error,"Error ");
      closemodalLoading();
    }
  }

  const getTicket=async(folio)=>{
    let datos={found:false,items:[],ventas:[]};
    openmodalLoading();
    const peticiones = [{
        url: "/get-venta",
        method: "POST",
        data: { id: folio, status: "", sucursal: getSucursal(), },
      },{
        url: "/get-pedido-especial",
        method: "POST",
        data: { id: folio, sucursal: getSucursal(), },
      }]
    
    try{
      const respVenta = await apiUsePromise(peticiones[0]);
      datos = funcionExitoTicket(respVenta.data);
      if (datos.found){
        inputTk.current.value = "";
        setItemsFact(datos.items);
        setVentas(datos.ventas);
        return;
      }
     const respPes = await apiUsePromise(peticiones[1]);
     datos = funcionExitoPes(respPes.data);
     if (datos.found){
      inputTk.current.value = "";
      setItemsFact(datos.items);
      setVentas(datos.ventas);
      return;
    }
     openmodalError("No existe la venta")
    }catch(Error){
      funcionErrorTicket(Error)
    }
  }



  const funcionExitoPes=(resp)=>{
    const pes = resp.items[0];
    let new_items = [...itemsFact];
    let new_ventas = [...Ventas];
    let totalIva= 0,totalDescuento=0,totalImporte=0;
    const IVA = DatosEmisor.iva/100;

    if(pes.items==0){
      return {found:false,items:[],ventas:[]};
    }

    const existVenta = new_ventas.filter(tk=>tk.shortId==pes.shortId);

    if(existVenta.length>0){
      openmodalError("El pes ya esta agregado");
      return {found:false,items:[],ventas:[]};
    }
    /*if(pes?.facturado){
      openmodalInfo("El pes selecionado ya ha sido anteriormente en otra factura");
      return {found:false,items:[],ventas:[]};
    }*/

    pes.items.forEach(item=>{
      const pv  =(item.precioVenta/item.cantidad)/(IVA+1);
      const subtotal = valueToMoney2(pv*item.cantidad);
      const base = Number(subtotal.toFixed(2));
        //let val = base*IVA;
        //let [entero,decimal] = val.toFixed(4).split(".");
        //const taxTotal = Number(entero+"."+Math.ceil(Number(decimal.substring(2,0)+"."+decimal.substring(2,4))));
      const taxTotal = Number((base*IVA).toFixed(2));
      const total = Number((base+(base*IVA)).toFixed(2));
      totalIva += taxTotal;
      totalImporte += subtotal;
      totalDescuento += 0.00;

      new_items.push({
          idProduct : "ZXPES",
          ProductCode : Clave_Indigo_Sat_Short["AC"].clave,
          Description : item.descripcion,
          UnitCode : Clave_Indigo_Sat_Short["AC"].unidad,
          Quantity : item.cantidad,
          UnitPrice: valueToMoney2(pv),
          Subtotal: subtotal,
          TaxObject: "02",
          Discount: 0.00,
          Total: total,//valueToMoney2(item.importe),
          Taxes: [{
              Total: taxTotal,
              Name: "IVA",
              Base: base,
              Rate: IVA,
              IsRetention: false
          }],
          shortId : pes.shortId
      });
    });
    new_ventas.push({
      id : pes.id,
      iva : totalIva,
      nombreCliente : pes.cliente,
      shortId : pes.shortId,
      subtotal : totalImporte,
      descuento: totalDescuento,
      total : valueToMoney2(pes.importe),
      type : "PE#"+getSucursal(),
    });

    closemodalLoading();
    return {found:true,items:new_items,ventas:new_ventas};
  }


  const funcionExitoTicket=(resp)=>{
    const tkventa = resp.venta;
    const items = resp.items||[];
    let new_items = [...itemsFact];
    let new_ventas = [...Ventas];
    let totalIva= 0,totalDescuento=0,totalImporte=0;
    const IVA = DatosEmisor.iva/100;

    if(items.length==0){ return {found:false,items:[],ventas:[]}; }

    const existVenta = new_ventas.filter(tk=>tk.shortId==tkventa.shortId);

    if(existVenta.length>0){
      openmodalError("El ticket ya esta agregado");
      return {found:false,items:[],ventas:[]};
    }
    /*if(tkventa?.facturado){
      openmodalInfo("El ticket selecionado ya ha sido anteriormente en otra factura");
      return {found:false,items:[],ventas:[]};
    }*/
  

    const services = items.filter(serv=>(serv.servicios))[0];
    if(services?.servicios?.length>0){
      services.servicios.forEach(serv=>{
        const claveSat = "SE";
        const pv  =valueToMoney((serv.precioVenta/(IVA+1)));
        //console.log('Product>',serv.id)
        const descuento = Number(ConvertDecimal((CalcularDescuentoPorPorcentaje(pv,serv.descuento)*serv.cantidadVendida),decimales));
        //console.log('importe')
        const importe = Number(ConvertDecimal((pv*serv.cantidadVendida),decimales));
        //console.log('base')
        const base =  ConvertDecimal((importe-descuento),decimales);
        //let val = base*IVA;
        //let [entero,decimal] = val.toFixed(4).split(".");
        //const taxTotal = Number(entero+"."+Math.ceil(Number(decimal.substring(2,0)+"."+decimal.substring(2,4))));
        const taxTotal = ConvertDecimal((base*IVA),decimales);
        const total = ConvertDecimal((base+(base*IVA)),decimales);
        totalIva += taxTotal;
        totalImporte += importe;
        totalDescuento += descuento;

        //console.log('=>',serv.importe,"=>",serv.descuento,"=>",descuento)

        new_items.push({
          idProduct : serv.id,
          ProductCode : Clave_Indigo_Sat_Short[claveSat].clave,
          Description : serv.descripcion,
          UnitCode : Clave_Indigo_Sat_Short[claveSat].unidad,
          Quantity : serv.cantidadVendida,
          UnitPrice: pv,
          Subtotal: importe,
          TaxObject: "02",
          Discount: descuento,
          Total: total,
          Taxes: [{
              Total: taxTotal,
              Name: "IVA",
              Base: base,
              Rate: IVA,
              IsRetention: false
          }],
          shortId : tkventa.shortId
        });
      })
    }
    
    items.forEach(item=>{
      if(item.id!=="VENTA#SERVICE"){
        const claveSat = item.id.substring(0,2);
        const pv  =valueToMoney(item.precioVenta/(IVA+1)); //855000/(1.16)>>>73.706896>>73.7069
        const descuento = ConvertDecimal((CalcularDescuentoPorPorcentaje(pv,item.descuento)*item.cantidadVendida),decimales);//(73.7069,5)>>>3.685345*3>>>11.056035>>>11.0560
        const importe =ConvertDecimal(pv*item.cantidadVendida,decimales);//73.7069*3>>>>221.1207
        const base = ConvertDecimal((importe-descuento),decimales);//221.1207-11.0560>>>210.0647
        //let val = base*IVA;
        //let [entero,decimal] = val.toFixed(4).split(".");
        //const taxTotal = Number(entero+"."+Math.ceil(Number(decimal.substring(2,0)+"."+decimal.substring(2,4))));
        const taxTotal = ConvertDecimal((base*IVA),decimales);//210.0647*0.16>>>33.610352
        const total = ConvertDecimal((base+(base*IVA)),decimales);//210.0647+(210.0647*0.16)>>>243.675052>>>243.68
        totalIva += taxTotal;
        totalImporte += importe;
        totalDescuento += descuento;

        new_items.push({
          idProduct : item.id,
          ProductCode : Clave_Indigo_Sat_Short[claveSat].clave,
          Description : item.descripcion,
          UnitCode : Clave_Indigo_Sat_Short[claveSat].unidad,
          Quantity : item.cantidadVendida,
          UnitPrice: pv,
          Subtotal: importe,//221.12
          TaxObject: "02",
          Discount: descuento,
          Total: total,//valueToMoney2(item.importe),
          Taxes: [{
              Total: taxTotal,
              Name: "IVA",
              Base: base,
              Rate: IVA,
              IsRetention: false
          }],
          shortId : tkventa.shortId
        });
      }
    })

    new_ventas.push({
      id : tkventa.id,
      iva : ConvertDecimal(totalIva,2),
      nombreCliente : tkventa.nombreCliente,
      shortId : tkventa.shortId,
      subtotal : ConvertDecimal(totalImporte,2),
      descuento: ConvertDecimal(totalDescuento,2),
      total : valueToMoney2(tkventa.total),
      type : "VENTA#"+getSucursal(),
    });

    //console.log('==>',new_items)
    //console.log('==>',new_ventas)
    closemodalLoading();
    return {found:true,items:new_items,ventas:new_ventas};
  }
  const funcionErrorTicket=(error)=>{
    closemodalLoading();
    openmodalError(error ,"Error de Venta");
  }

  const openmodalInfo=(message,titulo)=>{
    closemodalLoading();
    setmodalinfoConfig({
      isOpen: true,
      message : message || "",
      title: titulo || "Informacion Importante"
    })
  }
  const closeModalInfo=()=>{
    const newdataconfig = { ...modalinfoConfig };
    newdataconfig.isOpen = false;
    setmodalinfoConfig(newdataconfig);
  }

  const openmodalError=(error,titulo)=>{
    closemodalLoading();
    /*if(JSON.stringify(error?.response?.data?.item)!=""){
      error = ;
    }*/
    setmodaldangererrorConfig({
      isOpen: true,
      message :  error?.response?.data?.message || error?.message || error || "Datos Incorrectos",
      title: titulo  ||  "Error"
    })
  }
  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }
  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  }

  const openModalFactGlobal=()=>{setModalFactGlobal({isOpen:true})}
  const closeModalFactGlobal=()=>{setModalFactGlobal({isOpen:false})}

  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }
  
  const openmodalLoading = (titulo) => {
    setmodalLoading({ isOpen: true, title:titulo });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };

  const openModalTicketFact = (e) => {
    setModalTicketFact({ isOpen: true });
  };
  const closeModalTicketFact = (e) => {
    setModalTicketFact({ isOpen: false });
  };

  const openModalComplement = (e) => {
    setModalComplement({ isOpen: true });
  };
  const closeModalComplement = (e) => {
    setModalComplement({ isOpen: false });
  };

  const openModalPrefact = (e) => {
    setModalPrefact({ isOpen: true });
  };
  const closeModalPrefact = (e) => {
    setModalPrefact({ isOpen: false });
  };

  const openModalCancel = (e) => {
    setModalCancel({ isOpen: true });
  };
  const closeModalCancel = (e) => {
    setModalCancel({ isOpen: false });
  };

  function validaDatosEmisor(){
    let valido = true;
    if(!DatosEmisor?.razonSocial || !DatosEmisor?.rfc || !DatosEmisor?.regimen || !DatosEmisor?.codigo_postal ){
      valido =false;
    }
    return valido;
  }
  
  function validaDatosCliente(){
    let valido = true;
    if(!Cliente?.razonSocial || !Cliente?.rfc || !Cliente?.uso_cfdi || !Cliente?.regimen || !Cliente?.codigopostal || !Cliente?.email){
      valido =false;
    }
    return valido;
  }

  useEffect(()=>{
    if(!Cliente?.id){
      setFormaPago();
      setMetodoPago();
      return;
    }
    if(!validaDatosCliente){
      setmodaldangererrorConfig({
        isOpen: true,
        message: "Paara poder facturar el cliente debe tener obligatoriamente los siguientes campos: Razon social, RFC, Uso de CFDI, Regimen Fiscal, Codigo Postal y Correo" ,
      });
      return;
    }
    if(itemSelect?.tipo=="prefactura"){
      const prefact = {...itemSelect.prefactura};
      let usoCfdi = Uso_Cfdi[prefact?.Receiver?.CfdiUse] || "",
      formaPago = Formas_Pago_Sat[prefact?.PaymentMethod] || "",
      metodoPago = Metodo_Pago_Sat[prefact?.PaymentForm] || "",
      relacion = prefact?.Relations?.Cfdis?(prefact.Relations.Cfdis?.[0]?.Uuid||"" ): "" ;

      if(Cliente.uso_cfdi!==prefact?.Receiver?.CfdiUse){usoCfdi = Uso_Cfdi[Cliente.uso_cfdi] || "";}
      if(Cliente.regimen!==prefact?.Receiver?.FiscalRegime){prefact.Receiver.FiscalRegime = Cliente.regimen;}
      if(Cliente.razonSocial!==prefact?.Receiver?.Name){prefact.Receiver.Name = Cliente.razonSocial;}
      if(Cliente.rfc!==prefact?.Receiver?.Rfc){prefact.Receiver.Rfc = Cliente.rfc;}
      if(Cliente.codigopostal!==prefact?.Receiver?.TaxZipCode){prefact.Receiver.TaxZipCode = Cliente.codigopostal;}
      
      selectCfdiEdit.current.value = usoCfdi;
      selectFormaPagoEdit.current.value = formaPago;
      selectMetodoPagoEdit.current.value = metodoPago;
      inputObservacionEdit.current.value = prefact.Observations;
      inputRefBankEdit.current.value = prefact.PaymentAccountNumber;
      inputRelacionEdit?.current?inputRelacionEdit.current.value = relacion:"";

        if(prefact.Relations?.Cfdis){
          setCheckActivo(true);
        }else{
          setCheckActivo(false);
        }
      setItemSelect({...itemSelect,prefactura:prefact})
        //addClient({});
      openModalPrefact();
    }
  },[Cliente])

 /* useEffect(()=>{
    if(!itemSelect){ return;}

  },[itemSelect])*/

  return (
    <>
    <LoadingModal config={modalLoading} >
    <LoadingModal.Body>
      <LoadingModal.Title>{modalLoading?.title||"Cargado datos"}</LoadingModal.Title>
    </LoadingModal.Body>
    </LoadingModal>
    
    <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <Modaldanger front="z-50" config={modaldangererrorConfig}>
      <Modaldanger.Message title={modaldangererrorConfig?.title || "Error"}>
        {modaldangererrorConfig.message}
      </Modaldanger.Message>
      <Modaldanger.Buttons>
        <button
        type="button"
        className="btn-gray2 "
        onClick={closeModaldangererror}
        >
          Cerrar
        </button>
      </Modaldanger.Buttons>
    </Modaldanger>

    <Modalinfo config={modalinfoConfig} tab="100" closeModal={closeModalInfo}>
      <Modalinfo.Message title={modalinfoConfig?.title || "Info"}>
        {modalinfoConfig.message}
      </Modalinfo.Message>
      <Modalinfo.Buttons>
        <button
        type="button"
        className="btn-gray1"
        tabIndex={"100"}
        name="modalinfo"
        onClick={closeModalInfo}
        >
          Cerrar
        </button>
      </Modalinfo.Buttons>
    </Modalinfo>


    <Modalgeneric  h="size-h-80" w="size-w-30" config={modalComplement}>
      <Modalgeneric.Body >
      <div className='flex flex-col items-center w-full gap-2'>
        <div className='size-form gap-2 items-center justify-start w-full '>
          <label className='font-size text-size w-52'>{"RFC: "+DatosEmisor?.rfc}</label>
          <label className='font-size text-size '>Fecha Inicial</label>
          <input 
          type="date"  
          min={'2023-01-01'} 
          defaultValue={fecha?.fechai}
          ref={inputFechaIComplement}
          className='border-form'></input>
          <label className='font-size text-size '>Fecha Final</label>
          <input 
          type="date"  
          min={'2023-01-01'} 
          defaultValue={fecha?.fechaf}
          ref={inputFechaFComplement}
          className='border-form'></input>
        </div>
        <div className='size-form gap-2 items-center justify-start w-full '>
          <div className='size-form gap-2 mt-1 items-center '>
                <label className='textf text-size'>Metodo de Pago</label>
                <Selectormap
                catalogo={Metodo_Pago_Sat}
                clean={!itemSelect}
                name="categoria"
                inputRef={selectMetodoPagoEdit}
                />
          </div>
        </div>
        <div className='size-form gap-2 items-center justify-start w-full '>
          <label className='font-size text-size '>Factura:</label>
          <label className='font-size text-size font-bold w-32'>{itemSelect?.serie+"-"+itemSelect?.folio}</label>
          <label className='font-size text-size w-48'>Uuid Relacionado</label>
          <input className='bg-red-200 w-24' 
          type='text'
          ref={inputRelacionCancel}
          defaultValue={""}
          />
        </div>
      </div>
      </Modalgeneric.Body>
      <Modalgeneric.Buttons >
        <button
          type="button"
          className="btn-blue mx-1"
          onClick={e=>{
            setItemSelect(null);
            closeModalComplement();
          }}
        >
          Cerrar
        </button>
        <button
        className="btn-green "
        type="button"
        onClick={async(e) => {
          if(!itemSelect){return;}
          //console.log(DatosEmisor)
        }}
        style={{ transition: "all .15s ease" }}
        >
          Crear Complement
        </button>
      </Modalgeneric.Buttons>
    </Modalgeneric>


    <Modalgeneric h="size-h-30" w="size-w-50" config={modalCancel}>
      <Modalgeneric.Body >
      <div className='flex flex-col items-center w-full gap-2'>
        <div className='size-form gap-2 items-center justify-start w-full '>
          <label className='font-size text-size '>Cliente:</label>
          <label className='font-size text-size font-bold w-32'>{itemSelect?.cliente}</label>
          <label className='font-size text-size '>Motivo</label>
          <Selectormap
          catalogo={Motivos_Cancelacion}
          clean={!itemSelect}
          name="categoria"
          inputRef={selectMotivo}
          />
        </div>
        <div className='size-form gap-2 items-centerf justify-start w-full '>
          <label className='font-size text-size '>Factura:</label>
          <label className='font-size text-size font-bold w-32'>{itemSelect?.serie+"-"+itemSelect?.folio}</label>
          <label className='font-size text-size w-48'>Uuid Relacionado</label>
          <input className='border-form w-full' 
          type='text'
          ref={inputRelacionCancel}
          defaultValue={""}
          />
        </div>
      </div>
      </Modalgeneric.Body>
      <Modalgeneric.Buttons >
        <button
          type="button"
          className="btn-blue mx-1"
          onClick={e=>{
            setItemSelect(null);
            closeModalCancel();
          }}
        >
          Cerrar
        </button>
        <button
        className="btn-green "
        type="button"
        onClick={async(e) => {
          if(!itemSelect){return;}
          const motivo = Object.entries(Motivos_Cancelacion).find(([key,value])=>(Motivos_Cancelacion[key]===selectMotivo.current.value))?.[0]||"";
          if(!motivo){openmodalInfo("Debe seleccionar algun motivo de cancelacion","Datos Incompletos"); return;}
          if(motivo=="01"&&!inputRelacionCancel.current.value){
            openmodalInfo("Cuando se seleccion el motivo 01 es obligatorio poner el UUID de la factura relacionada(factura que se emitio en reposicion a la que se esta cancelando)","Datos Incompletos"); 
            return;
          }

          const folio = itemSelect.serie+"-"+itemSelect.folio;
          const peticion = {'Opcion':'cancelar','Folio':itemSelect.idFacturama,"Motivo":motivo,"IdRel":inputRelacionCancel.current?.value||"","Id":itemSelect.type,"Sucursal":getSucursal()};
          //console.log(peticion);
          openmodalLoading("Cancelando Factura "+folio);

          try{
            let resp = await useFactura(peticion);
            resp = resp.data
            closemodalLoading();
            const mensaje = resp.item;
            if(['pending ','active'].includes(mensaje.Status)){
              openmodalError(mensaje.Message,"Error de cancelacion");
            }else if('canceled'==mensaje.Status){
              openModalSuccess(mensaje.Message,"Cancelada")
            }else if(['rejected ','expired','acepted'].includes(mensaje.Status)){
              openmodalInfo(mensaje.Message,"Pendiente");
            }
            //console.log(resp);
          }catch(error){
            openmodalError(error,"Error al descargar PDF");
            closemodalLoading();
          }
        }}
        style={{ transition: "all .15s ease" }}
        >
          Cancelar
        </button>
      </Modalgeneric.Buttons>
    </Modalgeneric>


    <Modalgeneric  h="size-h-50"  w="size-w-50" config={modalPreFact}>
      <Modalgeneric.Body >
      <>
          <div className='flex flex-col items-center w-full gap-2'>
            <div className='size-form gap-2 items-center w-full'>
              <label className='textf text-size'>Cliente</label>
              <DatosClienteVenta/>
            </div>
            <div className='size-form gap-2 mt-1 items-center w-full'>
              <label className='textf text-size'>Uso CFDI</label>
              <Selectormap
                catalogo={Uso_Cfdi}
                clean={!itemSelect}
                name="categoria"
                inputRef={selectCfdiEdit}
                getvalor={(valor) => {
                  //console.log('Valor:',valor)
                }}
                />
            </div>
            <div className='size-form w-full gap-2 mt-1'>            
              <div className='size-form gap-2 items-center '>
                <label className='textf text-size '>Forma de Pago</label>
                <Selectormap
                catalogo={Formas_Pago_Sat}
                clean={!itemSelect}
                name="categoria"
                inputRef={selectFormaPagoEdit}
                />
              </div>
              <div className='size-form gap-2 mt-1 items-center '>
                <label className='textf text-size'>Metodo de Pago</label>
                <Selectormap
                catalogo={Metodo_Pago_Sat}
                clean={!itemSelect}
                name="categoria"
                inputRef={selectMetodoPagoEdit}
                />
              </div>
            </div>

            <div className='size-form w-full gap-2 mt-1'> 
              <div className='size-form gap-2 items-center'>
                <label className='textf text-size' >Ref.Bancaria</label>
                <input className='border-form uppercase w-full md:w-24'
                onBlur={e=>{e.target.value=e.target.value.toUpperCase()}}
                ref={inputRefBankEdit}
                />
              </div>
              <div className={'w-full flex flex-wrap gap-2 items-center justify-center'}>
                <div className="relative items-center flex flex-wrap gap-2">
                  <label htmlFor="toggle" className=" font-semibold">
                    Relacionado
                  </label>
                  <Toggle onClick={(e,isOn)=>{
                    setCheckActivo(isOn)
                  }}/>
                 
                </div>
                {checkActivo?
                <div className='size-form gap-2 items-cente'>
                  <label className='textf text-size '>Id Factura</label>
                  <input className='border-form uppercase '
                  ref={inputRelacionEdit}
                  onBlur={e=>{e.target.value=e.target.value.toUpperCase()}}
                  />
                </div>
                :null}
               
              </div>
            </div>
            <div className='size-form gap-2 mt-2 items-center w-full'>
              <label className='textf text-size' >Observaciones</label>
              <input className='border-form uppercase w-full '
              ref={inputObservacionEdit}
              onBlur={e=>{e.target.value=e.target.value.toUpperCase()}}
              />
            </div>

            <div className='size-form gap-2 mt-2 items-center w-full'>
              <label className='textf text-size'>Fecha Emision</label>
              <Selectormap
              width="w-auto"
              catalogo={['1 Dia antes','2 Dias antes']}
              name="fechasBefore"
              inputRef={inputFechaEmision}
              />
            </div>
          </div>
        </>
      </Modalgeneric.Body>
      <Modalgeneric.Buttons >
        <button
          type="button"
          className="btn-blue mx-1"
          onClick={e=>{
            setItemSelect(null);
            closeModalPrefact();
          }}
        >
          Cerrar
        </button>
        <button
        className="btn-green "
        type="button"
        onClick={(e) => {
          if(e.detail>1){return;}
          const item = cloneDeep(itemSelect);
          const prefact = item.prefactura;
          const receiver = prefact.Receiver;
          const cfdi = Object.entries(Uso_Cfdi).find(([key,value])=>(Uso_Cfdi[key].toUpperCase()===selectCfdiEdit.current.value.toUpperCase()))?.[0]||"",
          formaPago = Object.entries(Formas_Pago_Sat).find(([key,value])=>(Formas_Pago_Sat[key].toUpperCase()===selectFormaPagoEdit.current.value.toUpperCase()))?.[0]||"",
          metodoPago = Object.entries(Metodo_Pago_Sat).find(([key,value])=>(Metodo_Pago_Sat[key].toUpperCase()===selectMetodoPagoEdit.current.value.toUpperCase()))?.[0]||"",
          refBancaria = inputRefBankEdit.current.value || "",
          relacion = inputRelacionEdit.current.value || "",
          observacion = inputObservacionEdit.current.value || "";

          if(cfdi==""){
            setmodaldangererrorConfig({
              isOpen: true,
              message: "El uso de Cfdi es obligatorio" ,
            });
            return;
          }
          if(!formaPago||!metodoPago){
            setmodaldangererrorConfig({
              isOpen: true,
              message: "Los campos Forma de Pago y Metodo de Pago son obligatorios!!" ,
            });
            return;
          }
          if(refBancaria!==prefact.PaymentAccountNumber){
            prefact.PaymentAccountNumber = refBancaria;
          }
          if(checkActivo){
            if(!relacion){
              setmodaldangererrorConfig({
                isOpen: true,
                message: "Cuando la casilla Relacionado esta activado es necesario poner el uuid de la factura ralcionada" ,
              });
              return;
            }
            if(!prefact.Relations?.Cfdis){
              prefact.Relations = {
                "Type":"04",
                "Cfdis": []
              }
            }
            if(relacion!==prefact.Relations?.Cfdis?.[0]?.Uuid){
              prefact.Relations.Cfdis.push( {"Uuid":relacion} );
            }
          }else{
            prefact.Relations = "";
          }
          if(observacion!==prefact.Observations){
            prefact.Observations = observacion;
          }

          if(['1 Dia antes','2 Dias antes'].includes(inputFechaEmision.current.value)){
            let fecha = new Date();
            const horaActual = fecha.toLocaleTimeString('es-MX',{timeStyle:'medium',hour12:false,timeZone: Sucursal('timezone')});
            fecha = AddDiasFecha(fecha,(inputFechaEmision.current.value=="1 Dia antes"?-1:-2));
            let newFecha = fecha+'T'+horaActual;
            prefact.Date = newFecha;
          }

          receiver.CfdiUse = cfdi;
          prefact.PaymentForm = metodoPago;
          prefact.PaymentMethod = formaPago;
          
          setItemSelect(item);
          timbrarCfdi(item);
          closeModalPrefact();
        }}
        style={{ transition: "all .15s ease" }}
        >
          Timbrar
        </button>
      </Modalgeneric.Buttons>
    </Modalgeneric>

    <Modalgeneric h="size-h-20" w="size-w-20" front="z-40" tab="400" closeModal={closeModalFactGlobal} config={modalFactGlobal}>
      <Modalgeneric.Body>
        <div className='w-full flex flex-col gap-2 pt-1'>
          <div className='size-3 items-center'>
            <label className='font-size text-size'>Monto</label>
            <InputPrecioPanel
            min={moneyToValue(0.01)}
            className="col-span-2"
            inputRef={inputMontoFactGlobal}
            form={true}
            width="w-32"
            reset={!modalTicketFact?.isOpen}
            />
          </div>
        </div>
      </Modalgeneric.Body>
      <Modalgeneric.Buttons>
        <button 
        className='btn-gray2'
        name="modalgeneric"
        tabIndex="401"
        onClick={e=>{
          inputMontoFactGlobal.current.value ="";
          eliminarVenta("GLOBAL");
          btnToggle.current?.click()?btnToggle.current.click():"";
        }}>
          Cerrar
        </button>
        <button 
        className='btn-blue'
        name="modalgeneric"
        tabIndex="402"
        onClick={e=>{
          const cantidad = 1,// inputCantFactGlobal.current.value.replace(',',''),
          precioVenta = inputMontoFactGlobal.current.value.replace(',',''),
          IVA = DatosEmisor.iva/100,
          pv = Number(((precioVenta)/(IVA+1)).toFixed(2)),
          descuento = 0,
          subtotal = Number((pv*cantidad).toFixed(2)),
          base= Number((subtotal-descuento).toFixed(2)),
          taxTotal= Number((base*IVA).toFixed(2)),
          total = Number((base+(base*IVA)).toFixed(2));

          
          setItemsFact([{
            idProduct:"VENTA",
            ProductCode:Clave_Indigo_Sat_Short["FA"].clave,
            Description:"VENTA GLOBAL",
            UnitCode:  Clave_Indigo_Sat_Short["FA"].unidad,
            Quantity: cantidad,
            UnitPrice: pv,
            Subtotal: subtotal,
            TaxObject: "02",
            Discount: descuento,
            Total: total,
            Taxes: [{
              Total: taxTotal,
              Name: "IVA",
              Base: base,
              Rate: IVA,
              IsRetention: false
            }],
            shortId : "GLOBAL"
          }]);
          setVentas([{
            id: GetFechaActualUTC(),
            iva: taxTotal,
            nombreCliente : Cliente.nombre,
            type:"GLOBAL#"+getSucursal(),
            shortId : "GLOBAL",
            subtotal : subtotal,
            descuento: descuento,
            total : precioVenta,
          }]);

          closeModalFactGlobal();
        }}>
          Agregar
        </button>
      </Modalgeneric.Buttons>
    </Modalgeneric>
      

    <Modalgeneric h="size-h-90" w="size-w-70" config={modalTicketFact}>
      <Modalgeneric.Body >
        <div className='h-full w-full flex flex-col overflow-y-auto' >
          <div className='flex flex-col items-center w-full'>
            <div className='size-form gap-2 items-center w-full'>
              <label className='textf text-size'>Cliente</label>
              <InputClient/>
              <DatosClienteVenta/>
            </div>
            <div className='size-form gap-2 mt-1 items-center w-full'>
              <label className='textf text-size'>Uso CFDI</label>
              <select
                name="uso_cfdi"
                disabled={!Cliente?.id}
                ref={selectCfdi}
                className="border-form"
                defaultValue={Cliente?.uso_cfdi}
                >
                      <option key={-1} value={""} defaultValue=""></option>
                      {Object.keys(Uso_Cfdi).map((item,index) => (
                        <option key={index} value={item} defaultValue={Cliente?.uso_cfdi===item} selected={Cliente?.uso_cfdi===item} >
                          {Uso_Cfdi[item]}
                        </option>
                      ))}
              </select>
            </div>
            <div className='size-form w-full gap-2 mt-1'>            
              <div className='size-form gap-2 items-center '>
                <label className='textf text-size '>Forma de Pago</label>
                <Selectormap
                catalogo={Formas_Pago_Sat}
                disabled={!Cliente?.id}
                clean={!Cliente?.id}
                name="categoria"
                getvalor={(valor) => {
                  setFormaPago(valor?.clave);
                }}
                />
              </div>
              <div className='size-form gap-2 mt-1 items-center '>
                <label className='textf text-size'>Metodo de Pago</label>
                <Selectormap
                catalogo={Metodo_Pago_Sat}
                disabled={!Cliente?.id}
                clean={!Cliente?.id}
                name="categoria"
                defaultValue={Cliente?.cfdi}
                getvalor={(valor) => {
                  setMetodoPago(valor?.clave);
                }}
                />
              </div>
            </div>

            <div className='size-form w-full gap-2 mt-1'> 
              <div className='size-form gap-2 items-center'>
                <label className='textf text-size' >Ref.Bancaria</label>
                <input className='border-form uppercase w-full md:w-24'
                disabled={!Cliente?.id}
                onBlur={e=>{e.target.value=e.target.value.toUpperCase()}}
                ref={inputRefBank}
                />
              </div>
              <div className={'w-full flex flex-wrap gap-2 items-center justify-center'}>
                <div className="relative flex flex-wrap items-center">
                  <label htmlFor="toggle" className=" font-semibold pr-3">
                    Relacionado
                  </label>
                  <Toggle onClick={(e,isOn)=>{
                    setCheckActivo(isOn)
                  }}/>
                </div>
                {checkActivo?
                <div className={'size-form gap-2 items-center '}>
                  <label className={'textf text-size '} >Id Factura</label>
                  <input className={'border-form uppercase '}
                  ref={inputRelacion}
                  disabled={!Cliente?.id}
                  onBlur={e=>{e.target.value=e.target.value.toUpperCase()}}
                  />
                </div>
                :null}
                
              </div>
            </div>
            <div className='size-form gap-2 mt-2 items-center w-full'>
              <label className='textf text-size' >Observaciones</label>
              <input className='border-form uppercase w-96 '
              ref={inputObservacion}
              disabled={!Cliente?.id}
              onBlur={e=>{e.target.value=e.target.value.toUpperCase()}}
              />
            </div>
            <div className='size-form gap-2 mt-2 items-center w-full'>
              <div className='size-form gap-2 items-center'>
                <label className='textf text-size'>Ticket</label>
                <input className='border-form uppercase w-full md:w-28'
                ref={inputTk}
                disabled={!Cliente?.id}
                onKeyPress={e=>{
                  if(e.key=="Enter"&&e.target.value!==""){
                    getTicket( e.target.value.trim().toUpperCase());
                  }
                }}
                />
                <motion.button 
                whileHover={btnScale}
                whileTap={btnTap}
                className='rounded-full items-center'
                onClick={e=>{
                  if(inputTk.current.value!==""){
                    getTicket(inputTk.current.value.trim().toUpperCase());
                  }
                }}>
                  <i className='fas fa-plus-circle text-yellow-400 text-xl rounded-full'/>
                </motion.button>
              </div>
              <div className='w-32'>
                <BuscaTicket windowSize={windowSize}/>
              </div>     
              {Cliente?.rfc=="XAXX010101000"?
                          <div className={' border-2 border-ambar-500 rounded-xl p-1 flex flex-wrap gap-2 items-center justify-center'}>
                          <div className=" flex gap-2 items-center">
                            <label htmlFor="toggle" className=" font-semibold">
                              Periodo
                            </label>
                            <div className="flex flex-row gap-1 align-middle select-none transition duration-200 ease-in focus:shadow-outline">
                              {checkPeriodo?
                              Object.keys(checkPeriodo).sort().map((key,index)=>(
                                <div key={index} className="flex flex-row gap-1 items-center">
                                  <input className='radio-blue radio' type="radio" checked={checkPeriodo[key]} name="periodo" value={key} 
                                    onChange={e=>{
                                    const newchecks={...checkPeriodo};
                                      Object.keys(checkPeriodo).forEach(key=>{
                                        newchecks[key] = false; 
                                      })
                                      newchecks[key] = !checkPeriodo[key];
                                      setCheckPeriodo(newchecks);
                                  }}/>
                                  <label className="text-size font-semibold ">{key}</label>
                                </div>
                              )):[]}
                            </div>
                          </div>
                          <div className=" flex gap-2 items-center border-l-2 pl-1 border-ambar-500">
                            <label className=" font-semibold" >
                              Mes
                            </label>
                            <div className="flex flex-row gap-2 align-middle select-none transition duration-200 ease-in focus:shadow-outline">
                              {checkPeriodo?
                              <InputSelectormap
                              catalogo={Meses} 
                              inputRef={inputMeses}
                              defaultValue={Meses?.[GetFechaActualSeparado().mes]}
                              className="w-28"/> :[]}
                            </div>
                          </div>
                          <div className='flex gap-2 items-center border-l-2 pl-1 border-ambar-500'>
                            <label className='font-size'>Factura Global</label>
                            <Toggle inputRef={btnToggle} onClick={(e,isOn)=>{
                              if(!isOn){eliminarVenta("GLOBAL");}
                              setModalFactGlobal({isOpen:isOn});
                            }}/>
                          </div>
                        </div>  
              :null}
  
            </div>   
          </div>
          <div className='w-full h-65/100 mt-1 '>
            <TablaTicketFact
            searchinput={false}
            columns={[
              {
                header: "Ticket",
                width: "w-24 text-center text-size",
                // eslint-disable-next-line react/display-name
                code: (item) => <div className='flex flex-row w-full h-full items-center justify-between'>
                  <div className="text-red-600 ">
                      <motion.button
                      whileHover={{scale:1.2}}
                      whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}}
                      onClick={(e) => {
                        if(item.shortId=="GLOBAL"){btnToggle.current.click()}
                        eliminarVenta(item.shortId);
                      }}
                      >
                        <i className="fas fa-minus-circle"></i>
                      </motion.button>
                  </div>
                  {item?.shortId}
                  </div>
              },
              {
                header: "total",
                width: "w-28",
                // eslint-disable-next-line react/display-name
                code: (item) => <Labelmoney twoDecimal={true} className="w-full text-size">{moneyToValue(item.total)}</Labelmoney>,
              },
              {
                header: "Subtotal",
                width: "w-28",
                // eslint-disable-next-line react/display-name
                code: (item) => <Labelmoney twoDecimal={true} className="w-full text-size">{moneyToValue(item.subtotal)}</Labelmoney>,
              },
              {
                header: "impuesto",
                width: "w-28 ",
                // eslint-disable-next-line react/display-name
                code: (item) => <Labelmoney twoDecimal={true} className="w-full text-size">{moneyToValue(item.iva)}</Labelmoney>,
              },
              {
                header: "descuento",
                width: "w-28",
                // eslint-disable-next-line react/display-name
                code: (item) => <Labelmoney twoDecimal={true} className="w-full text-size">{moneyToValue(item.descuento)}</Labelmoney>,
              },
              {
                header: "Fecha",
                width:"w-28 ",
                // eslint-disable-next-line react/display-name
                code: (item) => <LabelFecha className=" text-center w-full" hour={true}>{item.id||""}</LabelFecha>
              },
              
            ]}
            footer={[
              {},
              {

                code: <Labelmoney twoDecimal={true} className="font-semibold w-full" noShowSignal={true}>{moneyToValue(Ventas.reduce((a, b) => a + (b?.id!==0?b.total:0), 0))}</Labelmoney>
              },
              {
                code: <Labelmoney twoDecimal={true} className="font-semibold w-full" noShowSignal={true}>{moneyToValue(Ventas.reduce((a, b) => a + (b?.id!==0?b.subtotal:0), 0))}</Labelmoney>
              },
              {
                code: <Labelmoney twoDecimal={true} className="font-semibold w-full" noShowSignal={true}>{moneyToValue(Ventas.reduce((a, b) => a + (b?.id!==0?b.iva:0), 0))}</Labelmoney>
              },
              {
                code: <Labelmoney twoDecimal={true} className="font-semibold w-full" noShowSignal={true}>{moneyToValue(Ventas.reduce((a, b) => a + (b?.id!==0?b.descuento:0), 0))}</Labelmoney>
              },{} 
            ]}
            data={Ventas}
          />
          </div>
        </div>
      </Modalgeneric.Body>
      <Modalgeneric.Buttons >
        <button
          type="button"
          className="btn-blue mx-1"
          onClick={closeModalTicketFact}
        >
          Cerrar
        </button>
        <button
          type="button"
          className="btn-ambar1 mx-1"
          onClick={e=>{
            limpiarFormulario();
          }}
        >
          Limpiar
        </button>
        <button
        className="btn-green "
        type="button"
        onClick={(e) => {
          if(e.detail>1){
            return;
          }

          if(!validaDatosEmisor()){
            setmodaldangererrorConfig({
              isOpen: true,
              message: "Para poder facturar el emisor debe tener obligatoriamente los siguientes campos: Razon social, RFC, Regimen Fiscal y Codigo Postal" ,
            });
            return;
          }
          if(!validaDatosCliente()){
            setmodaldangererrorConfig({
              isOpen: true,
              message: "Para poder facturar el cliente debe tener obligatoriamente los siguientes campos: Razon social, RFC, Uso de CFDI, Regimen Fiscal, Codigo Postal y Correo" ,
            });
            return;
          }
          if(!selectCfdi.current.value){
            setmodaldangererrorConfig({
              isOpen: true,
              message: "El Uso de CFDI es obligatorio" ,
            });
            return;
          }
          if(!formaPago||!metodoPago){
            setmodaldangererrorConfig({
              isOpen: true,
              message: "El Metodo y Forma de pago son Campos Obligatorios" ,
            });
            return;
          }
          if(Ventas.length==0){
            setmodaldangererrorConfig({
              isOpen: true,
              message: "Debe haber por lo menos un ticket para crear un prefactura" ,
            });
            return;
          }
          crearCfdi();
        }}
        style={{ transition: "all .15s ease" }}
        >
          Crear Prefactura
        </button>
      </Modalgeneric.Buttons>
    </Modalgeneric>

    <Container lg="w-4/6 mx-auto">
      <label className='text-xl font-bold w-full text-center'>FACTURACION</label>
      <div className="w-full mt-2 gap-1 size-form items-center justify-center">
        <motion.button
         whileHover={{ scale: 1.1 }}
         whileTap={{
           scale: 0.8,
           rotate: 5,
           borderRadius: "100%"
         }}
        className="btn-green1 "
        type="button"
        onClick={(e) => {
          setItemSelect(null);
          openModalTicketFact();
        }}
        style={{ transition: "all .15s ease" }}
        >
          <i className='fas fa-plus '/>
        </motion.button>
        <button
        className=" btn-green "
        type="button"
        onClick={async(e) => {
          if(e.detail>1){
            return;
          }
          getFacturas();
        }}>
          Timbrados
        </button>
        <button
        className="btn-blue "
        type="button"
        onClick={async(e) => {
          if(e.detail>1){
            return;
          }
          getPrefacturas();
        }}
        style={{ transition: "all .15s ease" }}
        >
          Prefacturas
        </button>
        <button
        className={" btn-blue1 "}
        type="button"
        onClick={async(e) => {
          openModalComplement();
        }}
        style={{ transition: "all .15s ease" }}
        >
          Complemento
        </button>
       

        <p className="w-24"/>
        {itemSelect?.tipo=="prefactura"?
        <button
        className={"btn-green"}
        type="button"
        onClick={(e) => {
          if(e.detail>1){
            return;
          }
          timbrarCfdi(itemSelect);
        }}
        style={{ transition: "all .15s ease" }}
        >
          Timbrar
        </button>
        :null}

        {itemSelect?.tipo=="prefactura"?
        <button
        className={"btn-red1"}
        type="button"
        onClick={async(e) => {
          try{
            openmodalLoading();
            let resp = await useFactura({'Opcion':'eliminar','IdPrefactura':itemSelect.type,'Sucursal':getSucursal()});
            resp = resp.data
            closemodalLoading();
            getPrefacturas();
            openModalSuccess("La prefactura se ha borrado de la base de datos","Prefactura Eliminada")
          }catch(error){
            openmodalError(error,"Error al eliminar Prefactura");
            closemodalLoading();
          }
        }}
        style={{ transition: "all .15s ease" }}
        >
          Eliminar
        </button>
        :null}
        
        {itemSelect?.tipo=="prefactura"?
        <button
        className={"btn-blue1"}
        type="button"
        onClick={(e) => {
          const prefact = itemSelect.prefactura;
          openmodalLoading("Buscando Cliente");
          useGetClient(prefact.Issuer.idClient,((respuesta)=>{
            closemodalLoading();
            let clientes = respuesta.filter(cliente=>(cliente.sucursal===getSucursal()))

            if (clientes.length === 0) {
              openmodalError("No existe el cliente '" + prefact.Issuer.idClient + "'");
              addClient({});
            }
        
            if (clientes.length === 1) {
              validateclient(clientes[0]);
            }
          }),error=>{
            closemodalLoading();
            openmodalError(error);
          });
        }}
        style={{ transition: "all .15s ease" }}
        >
          Editar
        </button>
        :null}
        
        {itemSelect?.tipo=="factura"?
        <button
        className={" btn-red1 "}
        type="button"
        onClick={async(e) => {
          const folio = itemSelect.serie+"-"+itemSelect.folio;
          openmodalLoading("Descargando Factura "+folio);

          try{
            let resp = await useFactura({'Opcion':'pdf','Folio':itemSelect.idFacturama});
            resp = resp.data
            closemodalLoading();
            if(resp?.item?.opcion=="pdf"){
              const linkSource = `data:application/pdf;base64,${resp.item.pdf}`;
              const downloadLink = document.createElement("a");
              downloadLink.download = folio+".pdf";
              downloadLink.href = linkSource;
              downloadLink.click();
            }
          }catch(error){
            openmodalError(error,"Error al descargar PDF");
            closemodalLoading();
          }
        }}
        style={{ transition: "all .15s ease" }}
        >
          PDF
        </button>
        :null}
        
        {itemSelect?.tipo=="factura"?
        <button
        className={" btn-green"}
        type="button"
        onClick={async(e) => {
          try{
            const folio = itemSelect.serie+"-"+itemSelect.folio;
            openmodalLoading("Enviando Factura "+folio);
            useGetClient(itemSelect.cliente,(async(respuesta)=>{
              let clientes = respuesta.filter(cliente=>(cliente.sucursal===getSucursal()))

              if (clientes.length === 0) {openmodalError("No existe el cliente '" + itemSelect.Name + "'");return;}
          
              if (clientes.length === 1) {
                if (clientes[0].activo === true||clientes[0]?.email) {
                  const cliente =  clientes[0];
                             //if(itemSelect.status=="canceled"){openmodalInfo("Esta factura ya esta cancelada","Cancelada"); return;}
                  const peticion = {'Opcion':'enviar','Folio':itemSelect.idFacturama,"Email":cliente.email};
                  let resp = await useFactura(peticion);
                  resp = resp.data
                  closemodalLoading();
                  const mensaje = resp.item?.msj||"";
                  openModalSuccess(mensaje,"Factura Enviada con exito");
                } else {
                  openmodalError("Cliente inhabilitado o no cuenta con correo ","Error en Cliente");
                  return
                }
              }
            }),error=>{
              closemodalLoading();
              openmodalError(error);
            });
          }catch(error){
            openmodalError(error);
            closemodalLoading();
          }
        }}
        style={{ transition: "all .15s ease" }}
        >
          Enviar Cliente
        </button>
        :null}

        {itemSelect?.tipo=="factura"?
        <button
        className={" btn-gray2 "}
        type="button"
        onClick={async(e) => {
          if(!DatosEmisor?.email){
            setmodaldangererrorConfig({
              isOpen: true,
              message: "Falta agregar el correo a los datos de la Sucursal" ,
            });
            return;
          }
          //if(itemSelect.status=="canceled"){openmodalInfo("Esta factura ya esta cancelada","Cancelada"); return;}
          const folio = itemSelect.serie+"-"+itemSelect.folio;
          const peticion = {'Opcion':'enviar','Folio':itemSelect.idFacturama,"Email":DatosEmisor.email};
          openmodalLoading("Enviando Factura "+folio);

          try{
            let resp = await useFactura(peticion);
            resp = resp.data
            closemodalLoading();
            //console.log(resp);
            const mensaje = resp.item?.msj||"";
            openModalSuccess(mensaje,"Factura Enviada con exito");
          }catch(error){
            openmodalError(error);
            closemodalLoading();
          }
        }}
        style={{ transition: "all .15s ease" }}
        >
          Enviar Sucursal
        </button>
        :null}


        {itemSelect?.tipo=="factura"?
          <button
          className={"btn-ambar1"}
          type="button"
          onClick={async(e) => {
            if(itemSelect.status=="canceled"){openmodalInfo("Esta factura ya esta cancelada","Cancelada"); return;}
            //console.log(itemSelect)
            openModalCancel();
          }}
          style={{ transition: "all .15s ease" }}
          >
            Cancelar
          </button>
        :null}

        {permisoCfdis?
          <input className='border-form w-32' ref={inputCfdiRfc}></input>
        :null}
        {permisoCfdis?
        <motion.button
        className='btn-gray'
        type="button"
        onClick={e=>{
          const rfc = inputCfdiRfc.current?.value||DatosEmisor.rfc;
          const peticion = {'Opcion':'getCfdis','page':"0",'rfc':rfc,'fechaI':formatoYMDtoDMY(PropsReportes?.fecha_Inicial,"/"),"fechaF":formatoYMDtoDMY(PropsReportes?.fecha_Final,"/")};
          const newReg = {timbrados:0,cancelados:0,serie:""}

          const getCfdis=async()=>{
            openmodalLoading("Obteniendo CFDIs del "+PropsReportes?.fecha_Inicial+" al "+PropsReportes?.fecha_Final);
            let cfdis = [];
            let page=0,flag =true;
            const json ={};

            while(flag){
              try{
                peticion.page = page+"";
                let resp = await useFactura(peticion);
                resp = resp.data
                if(resp?.item?.cfdis?.length>0){
                  cfdis = cfdis.concat(resp.item.cfdis);
                  page++;
                }else{flag = false; }
              }catch(error){
                openmodalError(error);
                closemodalLoading();
                flag =false;
                break;
              }
            }

            cfdis.forEach(fact=>{
              if(!json[fact.Serie]){ json[fact.Serie] = {...newReg,serie:fact.Serie } }
              json[fact.Serie].timbrados += fact.Status.toUpperCase() == "ACTIVE"?2:3;
            })


            let balance =Object.keys(json).map(key=>(json[key] ))

            excelCfdis(balance,cfdis,rfc)
            closemodalLoading();
          }
          
          getCfdis();
        }}>
          Get CFDIs
        </motion.button>
        :null}
      
      </div>
      <div className=' relative  w-full h-full flex justify-start mt-2' >
        <TableReactWindows
          bgRow="bg-white"
          bgHeader="bg-blue-800 text-white"
          onRowClick={({e,row})=>{
            if(itemSelect&&e.detail==1){
              setItemSelect(null);
            }
          }}
          btnDownload={async(e,datos)=>{
            const data = datos||arrayPrefactura.current;
            if(!data?.length){handleError("No hay datos que exportar"); return;}
            openmodalLoading();
            const workb = await GenerateXlsx();
            if(workb?.error){
              closemodalLoading();
              handleError(workb.error);
              return
            }
  
            const nameFile="Facturas",
            headerFile="Reporte de Facturas del "+PropsReportes?.fecha_Inicial+" al "+PropsReportes?.fecha_Final,
            sheet1 = workb.sheet(0),colPlus = 4;
  
            sheet1.cell("A1").value(headerFile);
            sheet1.cell("A1").style("bold",true);
            sheet1.cell("A1").style("fontSize",18);
        
            const sizeData = data.length + colPlus,
            header={'Fecha':13,'Folio':12,'Status':10,'Cliente':10,'Razon Social':40,'RFC':20,'Importe':17,'Impuesto':17,'Forma Pago':8,'MetodoPago':20,'UUID':40};
        
            Object.keys(header).map((key,index)=>{
              sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
              sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
              sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
              sheet1.column(columnasXlsx[index]).width(header[key]); 
            })
  
            data.forEach((factura,index) => {
              sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(FechaUTCToLocal(factura.type).fecha);
              sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(factura?.serie+"-"+factura?.folio);
              sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(factura?.status||"");
              sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(factura?.cliente||"");
              sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(factura?.Name||"");
              sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(factura?.Rfc||"");
              sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(factura?.monto));
              sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
              sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(factura?.total_iva||0);
              sheet1.cell(columnasXlsx[7]+(colPlus+index)).style(formatoCelda.contabilidad);
              sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(factura?.PaymentMethod||"");
              sheet1.cell(columnasXlsx[9]+(colPlus+index)).value(factura?.PaymentForm||"");
              sheet1.cell(columnasXlsx[10]+(colPlus+index)).value(factura?.idSat||"");
            });
        
            sheet1.cell(columnasXlsx[6]+sizeData).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
            sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[7]+sizeData).formula("SUM("+columnasXlsx[7]+""+colPlus+":"+columnasXlsx[7]+""+(sizeData-1)+")");
            sheet1.cell(columnasXlsx[7]+sizeData).style(formatoCelda.contabilidad);
            
            const response =await SaveXlsx(workb,nameFile);
            if(response?.error){
              closemodalLoading();
              handleError(response.error);
              return
            }
            closemodalLoading();
            openModalSuccess("Archivo generado correctamente","Descarga Completa");
          }}
          columns={[
            {
              key:"type",
              header:"Fecha",
              width:80, 
              type: tipoCelda.date,
              className: "text-blue-800 ",
              filter:true,
              onClick:({e,row,index})=>{
                if(e.detail==2){
                  const copyItem = cloneDeep(row);
                  copyItem.tipo = (row?.idSat?"factura":"prefactura");
                  setItemSelect(copyItem);
                }else if(e.detail==1){
                  setItemSelect(null);
                }
              }
            },
            {
              key:"folioFact",
              header:"Folio",
              width:95,
              type:tipoCelda.text,
              filter:true,
            },
            {
              key:"status",
              header:"status",
              width:80, 
               // eslint-disable-next-line react/display-name
              content:({row,index})=>{
                return <p className={(row.status=="active"?"text-green-500":(row.status=="canceled"?"text-red-600":"text-ambar-600"))+' classCellBase text-center uppercase'}>{row.status}</p>
              },
              filter:true,
            },
            {
              key:"cliente",
              header:"Cliente",
              width:70, 
              type: tipoCelda.text,
              filter:true,
            },
            {
              key:"Name",
              header:"Razon Social",
              width:250, 
              type: tipoCelda.text,
              filter:true,
            },
            {
              key:"Rfc",
              header:"Rfc",
              width:120, 
              type: tipoCelda.text,
              filter:true,
            },
            {
              key:"monto",
              header:"Total",
              width:100, 
              type: tipoCelda.money,
              filter:true,
              footer:true,
              function:getTotal,
              bgFooter:"bg-blue-800 text-white"
            },
            {
              key:"PaymentMethod",
              header:"F Pago",
              width:60, 
              type: tipoCelda.text,
              filter:true,
            },
            {
              key:"PaymentForm",
              header:"Metodo Pago",
              width:140, 
              type: tipoCelda.text,
              filter:true,
            },
            {
              key:"Observations",
              header:"Observaciones",
              className:" lowercase",
              width:300, 
              type: tipoCelda.text,
              filter:true,
            },
            {
              key:"idFacturama",
              header:"Folio Fact",
              width:200, 
              type: tipoCelda.text,
              className: "text-blue-800",
              filter:true,
            },
            {
              key:"idSat",
              header:"UUID",
              width:280, 
              type: tipoCelda.text,
              className: "text-blue-800",
              filter:true,
            },
          ]}
          data={arrayPrefactura.current}
        />
      </div>
    </Container>
  </>
  )
}
const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
  Iva: state.Datasucursal.IVA,
  windowSize: state.Logueo.windowSize,
  PropsReportes: state.PropsReportes,
});

const mapDispatchToProps = (dispatch) => ({
  addClient: (cliente) => dispatch({type: "ADD_CLIENT",item: cliente,}),
})

export default connect(mapStateToProps, mapDispatchToProps)(TicketFact)