import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setRuta } from "../../redux/Actions";
import { GetFechaActual, getNombre, moneyToValue, Sucursal, TransformSys } from "../../services/functions.js";
import PagadoSvg from "../../static/img/Pagado";
import {motion} from 'framer-motion'
import Newlogo from "../../static/img/logonew.jsx";
import Labelmoney from "../labelmoney";

const TicketPagadoPes = ({ Ticket, setRuta }) => {
  useEffect(() => {
   window.print();
  }, []);

  return (
    <>
    <div className="mx-2 75mm">
        <div className="w-full text-2xs">
          <div className="grid grid-flow-col justify-items-stretch w-full">
            {(Ticket?.pagado==Ticket.total)?
              <div className="w-[250px] justify-self-end">
                <PagadoSvg bg="bg-transparent" text="text-red-800"/>
              </div>
              :<p className="text-sm mt-2 justify-self-end font-black uppercase">Venta a credito</p>
            }
            <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{
              scale: 0.8,
              rotate: 5,
              borderRadius: "100%"
            }}
            onClick={(e) => {setRuta("pedidoEspecial") }}
            className={ "w-[80px] justify-self-end h-14 border-none leading-relaxed inline-block" }
            >
              <Newlogo corona="#00ace5" corona1="#00C9E5"  text="#000000"/>
            </motion.button>
          </div>
          <div className="w-full">
            <p className="uppercase w-full text-lg font-bold text-center">{Sucursal('nombre')}</p>
            <p className="uppercase">{Sucursal('direccion')+" C.P. "+Sucursal('codigo_postal')+" Tel. "+Sucursal('telefono')}</p>
            <p className="uppercase"> e-mail: {Sucursal('email')}</p>
            <p className="uppercase ">Ticket: <label className="font-bold">{Ticket?.item?.shortId}</label> Fecha: <label className="font-bold">{GetFechaActual().fecha+" "+GetFechaActual().hora}</label> </p>
            <p className="uppercase">Cliente: <label className="font-bold">{TransformSys(Ticket?.cliente)}</label>   VENDEDOR: <label className="font-bold">{getNombre()}</label></p>
          </div>
            <table className="w-full text-2xs font-semibold mt-1">
              <thead>
                <tr>
                  <th className="text-center">Cant</th>
                  <th className="text-center mx-0.5">Descripción</th>
                  <th className="text-right w-12">Precio U</th>
                  <th className="text-right w-16">Subtotal</th>
                </tr>
              </thead>
              <tbody>
              {Ticket?.item?.items
              ? (Ticket.item.items.map((product,index)=>{
                return <tr key={index}>
                <td className=" text-center">{product.cantidad}</td>
                  <td className=" text-start text-3xs ">{product.descripcion}</td>
                  <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{product.precioVenta/product.cantidad}</Labelmoney></td>
                  <td className="w-16 text-right "><Labelmoney twoDecimal={true}>{product.precioVenta}</Labelmoney></td>
              </tr>
              }))
              : null}
              </tbody>
            </table>
            <div className="flex flex-col w-full my-2 ">
              <div className="grid grid-cols-2 w-1/2 ">
                <label>Importe:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.total}</Labelmoney>
                <div className="font-bold text-start">Metodo</div>
                <div className="font-bold text-start">Pago</div>
              </div>
              {Ticket?.pagos.length?
              <div className="w-full">
                {Ticket.pagos.map((pago,index)=>(
                  <div key={index} className="grid grid-cols-2 w-1/2">
                     <label>{pago.metodo}:</label><Labelmoney className="w-16" twoDecimal={true}>{(Ticket?.cambio==true&&pago.metodo=="EFECTIVO"?Ticket.recibido || 0:moneyToValue(pago.monto) || 0)}</Labelmoney>
                  </div>
                ))}
                <div className="grid grid-cols-2 w-1/2">
                <label className="">Total:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.total}</Labelmoney>
                <label className={""+(Ticket?.total-Ticket?.pagado>0?"block":"hidden")}>Restante:</label><p className={""+(Ticket?.total-Ticket?.pagado>0?"block":"hidden")}><Labelmoney className="w-16" twoDecimal={true}>{(Ticket?.total-Ticket?.pagado || 0)}</Labelmoney></p>
                  <label className={""+(Ticket?.cambio==true?"block":"hidden")}>Cambio:</label><p className={""+(Ticket?.cambio==true?"block":"hidden")}><Labelmoney className="w-16" twoDecimal={true}>{(Ticket.restante || 0)}</Labelmoney></p>
                </div>
              </div>
              :""
            }

            </div>
            {Sucursal('ticket')?.header?
              <p className="uppercase w-full text-center">{Sucursal('ticket').header}</p>
            :""}
            {Sucursal('ticket')?.productos.length?
              <div className="w-full text-center mt-3">
                <p className="uppercase w-full text-center font-black mb-1">Nuevos Productos</p>
                {Sucursal('ticket').productos.map((prod,index)=>(<p key={index} className="uppercase w-full text-center">{prod}</p>))}
              </div>
            :""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Ticket: state.Ticket,
  Ruta: state.Ruta,
});

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketPagadoPes);
