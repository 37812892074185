import React from "react";

const ContextMenu =(props)=>{
    const items = props.config?.items || [<div key={0}>Nothing</div>];
    const ejey = (props.config.y||0)+"px", ejex= (props.config.x||0)+"px";

    return (
        props.config.isOpen ?
        <div onMouseLeave={e=>{props.closemodalMenu();}}
        className=' fixed z-50 border border-gray-500 bg-white cursor-pointer text-size md:-translate-x-80 lg:translate-x-0 ' 
        style={{'top': ejey, 'left': ejex}}//, 'transform': 'translate(-100%, -100%)'
        id='text' >
            {items.map((item,index)=>( item))}
        </div>
        :null
        
    )
  }
export default ContextMenu;
