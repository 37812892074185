import React, {useState,useEffect, useRef} from "react";
import PanelCard from "../components/panelcard";
import { connect } from "react-redux";
import LoadingModal from "../components/modal/LoadingModal";
import { useCatalogos } from "../Hooks/useApis";
import { setRuta, setLogueo } from "../redux/Actions";

const MenuInicio = (props) => {
  const titleModal = useRef("ACTUALIZANDO DATOS");
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [btnRetry,setBtnRetry]= useState(true),
  [tryCatalog,setTryCatalog] = useState(false);

  function ResetRedux() {
    props.resetitemsVenta();
    props.resetClientes();
    props.resetClientesVenta();
    props.resetSearchPersonal();
    props.resetSearchProveedor();
    props.resetHeaderCompra();
    props.resetitemsCompra();
    props.resetHeaderVenta();
    props.resetVentaAPagar();
    props.resetTicket();
    props.resetventasCredito();
    props.resetInventario();
    props.resetPropsInventario();
    props.resetitemsPedidoEspecial();
    props.resetitemsCash();
    props.resetPropsReportes();
    props.resetDatos();
    props.resetHeaderPes();
  }

  const handleSuccessfulCatalog = (respuesta) => {
    props.addCatalogo(respuesta);
    localStorage.setItem("utilidadMinima", respuesta.GLOBALCONFIGS.utilidadMinima);
    closemodalgeneric()
  };

  const handleError = (error) => {
    //console.log('cat', error)
    titleModal.current = (typeof error == "string"? error: (error?.message || error ||"El Servidor tarda en responder"))
    setBtnRetry(false);
  };

  const openmodalgeneric = (e) => {
    setmodalinfoConfig({ isOpen: true });
  };
  const closemodalgeneric = (e) => {
   setmodalinfoConfig({ isOpen: false });
  };

  const logOut = () => {
    document.cookie = "sessionIndigo=; max-age=0; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    localStorage.clear();
    props.setRuta("login");
    props.setLogueo({});
    localStorage.setItem("sucursal", JSON.stringify({}));
    ResetRedux();
  };

  useEffect(() => {
    openmodalgeneric();
    useCatalogos(handleSuccessfulCatalog,handleError);
  }, [tryCatalog]);

  useEffect(()=>{
    props.resetDatos();
    props.resetPropsReportes();
  },[])

  return (
    <div className="h-full overflow-y-auto bg-mesh"  >
        <LoadingModal config={modalinfoConfig} >
        <LoadingModal.Body>
          <LoadingModal.Title>{titleModal.current}</LoadingModal.Title>
        </LoadingModal.Body>
        <LoadingModal.Buttons>
        <button
            type="button"
            className="btn-dark bg-gray-700"
            hidden={btnRetry}
            onClick={(e) => {
              logOut();
            }}
          >
            Cerrar Sesion
          </button>
          <button
            type="button"
            className="btn-blue"
            hidden={btnRetry}
            onClick={(e) => {
              setBtnRetry(true);
              setTryCatalog(!tryCatalog);
              openmodalgeneric();
            }}
          >
            Reintentar
          </button>
        </LoadingModal.Buttons>
      </LoadingModal>
    <section className=" pt-4">
      <div className="px-2 flex flex-wrap justify-center gap-1">
        <PanelCard
            text="Receiving"
            icon="fas fa-hand-holding-usd"
            url="receiving"
            permiso="REGISTRAR COMPRAS"
          />

          <PanelCard
            text="Inventory"
            icon="fas fa-sitemap"
            url="inventory"
            permiso="VER BALANCE DE INVENTARIOS"
          />
          <PanelCard
              text="Sales"
              icon="fas fa-shopping-basket"
              url="sales"
              permiso="VENDER PRODUCTOS"
          />
          <PanelCard
            text="Cash"
            icon="fas fa-exchange-alt"
            url="cash"
            permiso="REGISTRAR EX Y CASH"
          />

          <PanelCard
            text="Cash Register"
            icon="fas fa-cash-register"
            url="caja"
            permiso="COBRAR VENTAS (CAJA)"
          />

          <PanelCard
            text="AR"
            icon="fas fa-users"
            url="balanceCliente"
            permiso="VER CLIENTES"
          />

          <PanelCard
            text="Personal"
            icon="fas fa-portrait"
            url="personal"
            permiso="VER USUARIOS"
          />

          <PanelCard
            text="Alta de productos"
            icon="fas fa-glasses"
            url="altaProducto"
            permiso="DAR DE ALTA PRODUCTOS"
          >
            
          </PanelCard>


          <PanelCard
            text="AP"
            icon="fas fa-user-tie"
            url="balanceProveedor"
            permiso="VER PROVEEDORES"
          />

          <PanelCard
            text="Configuraciones"
            icon="fas fa-cog"
            url="configuraciones"
            permiso="VER CONFIGURACIONES"
          />
          <PanelCard
            text="AR Other"
            icon="fas fa-user-friends"
            url="arother"
            permiso="VER REPORTES"
          />

          <PanelCard
            text="Tallado"
            icon="fas	fa-inbox"
            url="tallado"
            permiso="VER TALLADOS"
          />

          <PanelCard
            text="Bisel"
            icon="fas fa-adjust"
            url="bisel"
            permiso="VER BISELES"
          />

          <PanelCard
            text="Pedido especial"
            icon="fas fa-box"
            url="pedidoEspecial"
            permiso="VER PEDIDO ESPECIAL"
          />

          <PanelCard
            text="Micas y Armazones"
            icon="fas fa-border-all"
            url="micas"
            permiso="VER BALANCE DE INVENTARIOS"
          />

          <PanelCard
            text="Sucursales"
            icon="fas fa-warehouse"
            url="sucursales"
            permiso="VER SUCURSALES"
          />

          <PanelCard
            text="Facturacion"
            icon="fas fa-file-invoice-dollar"
            url="facturas"
            permiso="VER FACTURAS"
          />

        <PanelCard
            text="Tester"
            icon="fas fa-chart-bar"
            url="test"
            permiso="VER CONFIGURACIONES"
          />
        <PanelCard
            text="Bitacora"
            icon="fas fa-newspaper"
            url="laboratorio"
            permiso="VER BITACORA"
          />
      </div>
    </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addCatalogo: (element) =>dispatch({ type: "SET_CATALOGOS", catalogos: element }),
  setRuta: (ruta) => dispatch(setRuta(ruta)),
  setLogueo: (datos) => dispatch(setLogueo(datos)),


  resetitemsVenta:()=>dispatch({type:"RESET_ITEM_VENTA"}),
  resetClientes:()=>dispatch({type:"CLEAN_CLIENT"}),
  resetClientesVenta:()=>dispatch({type:"CLEAN_CLIENT_VENTA"}),
  resetSearchPersonal:()=>dispatch({type:"RESET_SEARCHPERSONAL"}),
  resetSearchProveedor:()=>dispatch({type:"RESET_SEARCHPROVEEDOR"}),
  resetHeaderCompra:()=>dispatch({type:"CLEAN_HEADERCOMPRA"}),
  resetitemsCompra:()=>dispatch({type:"CLEAN_ITEMCOMPRA"}),
  resetHeaderVenta:()=>dispatch({type:"CLEAN_HEADER_VENTA"}),
  resetVentaAPagar:()=>dispatch({type:"RESET_VENTAPAGAR"}),
  resetTicket:()=>dispatch({type:"RESET_TICKET"}),
  resetventasCredito:()=>dispatch({type:"RESET_VENTACREDITO"}),
  resetInventario:()=>dispatch({type:"RESET_INVENTARIO"}),
  resetPropsInventario:()=>dispatch({type:"RESET_PROPSINVENTARIO"}),
  resetHeaderPes:()=>dispatch({type:"RESET_HEADER_PES"}),
  resetitemsPedidoEspecial:()=>dispatch({type:"RESET_ITEMS_ESP"}),
  resetitemsCash:()=>dispatch({type:"RESET_ITEMS_CASH"}),
  resetPropsReportes:()=>dispatch({type:"RESET_REP"}),
  resetDatos:()=>dispatch({type:"RESET_DATOS"}),
});

export default connect(null, mapDispatchToProps)(MenuInicio);
