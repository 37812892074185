import React, { useState, useEffect } from "react";
import { setsearchPersonal, setRuta } from "../../redux/Actions";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  apiUse,
  getOffices,
  updating,
  validatePermission,
  disabledFieldIsNot,
  moneyToValue,
  getSucursal,
} from "../../services/functions.js";
import Modaldanger from "../../components/modal/danger";
import Modalsuccess from "../../components/modal/success";
import InputPrecioPanel from "../../components/InputPrecioPanel";
import LabelErrors from "../../components/LabelErrors";
import {Regimen_Sat,Uso_Cfdi, permisos}  from '../../static/dat/datos';
import { useCustomQuery } from "../../Hooks/useApis";
import Toggle from "../../components/Toggle.jsx";

const schema = yup.object().shape({
  id: yup.string().required("El código es obligatorio"),
  nombre: yup.string().required("El nombre es obligatorio"),
  activo: yup.bool().required("El campo es obligatorio"),
  sucursal: yup.string().required("La sucursal es obligatoria"),
  pin: yup.string().required("El pin es obligatorio"),
  email: yup.string().email("email invalido"),
  direccion: yup.string(),
  descuento: yup.number().max(100).min(0).required('"El descuento es requerido"'),
  limiteCredito: yup.number().required("El límite de crédito es requerido"),
  rfc: yup.string(),
  telefono: yup.string().max(10,"el telefono debe ser a 10 digitos"),
  
  regimen:yup.string(),
  uso_cfdi:yup.string(),
});

const Formulariocliente = (props) => {
  const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({});
  const setsearchPersonal = props.setsearchPersonal;
  const validatepermission = validatePermission(props.datospersonal);

  useEffect(() => {
    if(props.datospersonal?.limiteCredito){
      setValue('limiteCredito',moneyToValue(props.datospersonal.limiteCredito));
    }
    if(typeof props.datospersonal?.activo == 'undefined'){
      //setsearchPersonal({...props.datospersonal,activo:true})
      setValue("activo", true)
    }else{
      setValue("activo", props.datospersonal.activo)
    }
  }, [props.datospersonal]);


  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
    setsearchPersonal({});
    if(props?.setshowForm){
      props.setshowForm(!props?.showForm);
    }
  }

  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  register({name:"limiteCredito"});
  register({name:"regimen"});
  register({name:"uso_cfdi"});
  register({name:"activo"});

  const nameEndpoind = (data) => {
    if (updating(data)) {
      return "/update-client";
    }

    return "/insert-client";
  };

  const handleSuccessful = (data) => {
    const endpoind = nameEndpoind(props.datospersonal);
    setmodalSuccessConfig({
      isOpen: true,
      message:
        endpoind === "/insert-client"
          ? "El registro se realizó con éxito"
          : "La actualización se realizó con éxito",
    });
    setsearchPersonal(data);
  };
  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };
  const handleSuccessfulClient = (data) => {
    let datos = data?.datos||[];
    if(datos.length>0){     
      setmodaldangererrorConfig({
        isOpen: true,
        message: "El codigo ya existe!!",
      });
     
    }
  }

  const sendData = (dataform) => {
    const endpoind = nameEndpoind(props.datospersonal);
    dataform.id = (dataform.id=="SYS"?dataform.id+"#"+getSucursal():dataform.id);
    
    //console.log('==>',dataform)
    apiUse({
      url: endpoind,
      method: "POST",
      data: dataform,
      handleSuccessful: handleSuccessful,
      handleError: handleError,
    });
  };

  function verifyPermission() {
    return disabledFieldIsNot([
      validatepermission.create(permisos.altaCliente),
      validatepermission.update(permisos.modCliente),
    ]);
  }

  return (
    <>
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2  "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalsuccess config={modalSuccessConfig}>
        <Modalsuccess.Message title={"Éxito"}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
        <div className="flex flex-wrap items-top justify-between ">
          <div className="w-full lg:w-5/12 flex flex-col gap-2 ">
            <div className="relative w-full">
              <label className=" textf text-blue-700" htmlFor="grid-password">
                Código
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="id"
                  disabled={verifyPermission()}
                  placeholder="Código"
                  ref={register}
                  onChange={e=>{e.target.value=e.target.value.toUpperCase()}}
                  onBlur={e=>{
                    if(props?.newPerson&&e.target.value.toUpperCase()){
                      const peticion ={
                        indexName:"reverseIndex",
                        hashKey:{valor:e.target.value.toUpperCase(),nombre:"id"},
                        sortKey:{valor:"CLIENT",operador:"eq",nombre:"type"}
                      }
                      useCustomQuery(peticion,handleSuccessfulClient,handleError);
                    }
                  }}
                  defaultValue={props.datospersonal?.id}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="id" errors={errors}/>
            </div>
            <div className="relative w-full ">
              <label className=" textf text-blue-700" htmlFor="grid-password">
                Nombre optica
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="nombre"
                  readOnly={verifyPermission()}
                  placeholder="Nombre optica"
                  ref={register}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase()
                  }}
                  defaultValue={props.datospersonal?.nombre}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="nombre" errors={errors}/>
            </div>

            <div className="relative w-full">
              <label className=" textf" htmlFor="grid-password">
                Dirección
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="direccion"
                  readOnly={verifyPermission()}
                  placeholder="Dirección"
                  ref={register}
                  onChange={(e) => { e.target.value=e.target.value.toUpperCase(); }}
                  defaultValue={props.datospersonal?.direccion}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="direccion" errors={errors}/>
            </div>
            <div className="relative w-full ">
              <label className=" textf" htmlFor="grid-password">
                Código postal
              </label>

                <input
                  type="text"
                  className="border-form w-full"
                  name="codigopostal"
                  readOnly={verifyPermission()}
                  placeholder="Código postal"
                  ref={register}
                  defaultValue={props.datospersonal?.codigopostal}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="codigopostal" errors={errors}/>
            </div>
            <div className="relative w-full">
              <label className=" textf" htmlFor="grid-password">
                Teléfono
              </label>
                <input
                  type="text"
                  className="border-form w-full"
                  name="telefono"
                  readOnly={verifyPermission()}
                  placeholder="Teléfono"
                  defaultValue={props.datospersonal?.telefono}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                />
                <LabelErrors name="telefono" errors={errors}/>
            </div>
            <div className="relative w-full">
              <label className=" textf" htmlFor="grid-password">
                Email
              </label>
                <input
                  type="text"
                  className="border-form w-full"
                  name="email"
                  readOnly={verifyPermission()}
                  placeholder="Email"
                  ref={register}
                  defaultValue={props.datospersonal?.email}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="email" errors={errors}/>
            </div>
            <div className="relative w-full">
              <label className=" textf text-blue-700" htmlFor="grid-password">
                Pin
              </label>
                <input
                  type="text"
                  className="border-form w-full"
                  name="pin"
                  readOnly={verifyPermission()}
                  placeholder="pin"
                  defaultValue={props.datospersonal?.pin}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="pin" errors={errors}/>
            </div>
          </div>

          <div className="w-full lg:w-6/12 flex flex-col gap-2">
            <div className="relative w-full">
              <label htmlFor="toggle" className="textf ">
                Activo
              </label>
              <Toggle name="activo" checked={props.datospersonal?.activo} onClick={(e,isOn)=>{
                setValue("activo",isOn)
              }}/>
              <LabelErrors name="activo" errors={errors}/>
            </div>

            <div className="relative w-full">
              <label className=" textf text-blue-700" htmlFor="grid-password">
                Porcentaje de descuento
              </label>
              <input
              type="number"
              className="border-form w-full"
              name="descuento"
                  min={0}
                  max={100}
                  readOnly={verifyPermission()}
                  placeholder="Descuento"
                  defaultValue={props.datospersonal?.descuento}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
              />
              <LabelErrors name="descuento" errors={errors}/>
            </div>

            <div className="relative w-full">
              <label className=" textf" htmlFor="grid-password">
                Limite de credito
              </label>
              <InputPrecioPanel
                  className="bg-white"
                  min={0}
                  form={true}
                  name={"limiteCredito"}
                  newValor={props.datospersonal.limiteCredito}
                  placeholder="Limite de credito"
                  setValor={(valor) => { 
                    setValue('limiteCredito',moneyToValue(valor));
                  }}
              />
              <LabelErrors name="limiteCredito" errors={errors}/>
            </div>

            <div className="relative w-full">
              <label className=" textf" htmlFor="grid-password">
                RFC
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="rfc"
                  readOnly={verifyPermission()}
                  placeholder="RFC"
                  ref={register}
                  onChange={(e) => { e.target.value=e.target.value.toUpperCase(); }}
                  onBlur={e=>{ e.target.value=e.target.value.trim().toUpperCase();}}
                  defaultValue={props.datospersonal?.rfc}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="rfc" errors={errors}/>
            </div>
            <div className="relative w-full">
              <label className=" textf" htmlFor="grid-password">
                Regimen Fiscal
              </label>
                <select
                  name="regimen"
                  ref={register}
                  disabled={verifyPermission()}
                  className="border-form w-full"
                  defaultValue={props.datospersonal?.regimen}
                >
                   <option key={-1} value={""} defaultValue=""></option>
                  {Object.keys(Regimen_Sat).map((item,index) => (
                  <option key={index} value={item} defaultValue={props.datospersonal?.regimen===item} selected={props.datospersonal?.regimen===item}>
                      {Regimen_Sat[item]}
                    </option>
                  ))}
                </select>
                <LabelErrors name="regimen" errors={errors}/>
            </div>
            <div className="relative w-full ">
              <label className=" textf" htmlFor="grid-password">
                Uso Cfdi
              </label>
                <select
                  name="uso_cfdi"
                  ref={register}
                  disabled={verifyPermission()}
                  className="border-form w-full"
                  defaultValue={props.datospersonal?.uso_cfdi}
                >
                   <option key={-1} value={""} defaultValue=""></option>
                  {Object.keys(Uso_Cfdi).map((item,index) => (
                    <option key={index} value={item} defaultValue={props.datospersonal?.regimen===item} selected={props.datospersonal?.uso_cfdi===item} >
                      {Uso_Cfdi[item]}
                    </option>
                  ))}
                </select>
              <LabelErrors name="uso_cfdi" errors={errors}/>
            </div>

            <div className="relative w-full">
              <label className=" textf text-blue-700" htmlFor="grid-password">
                Sucursal
              </label>
                <select
                  name="sucursal"
                  ref={register}
                  readOnly={verifyPermission()}
                  defaultValue={props.datospersonal?.sucursal || getSucursal()}
                  className="border-form w-full"
                >
                  {getOffices().map((item, index) => (
                    <option key={index} defaultValue={item} selected={props.datospersonal?.sucursal===item}>
                      {item}
                    </option>
                  ))}
                </select>
                <LabelErrors name="sucursal" errors={errors}/>
            </div>

            <div className="relative w-full">
              <label className=" textf" htmlFor="grid-password">
                Razon Social
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="razonSocial"
                  readOnly={verifyPermission()}
                  placeholder="Razon Social"
                  ref={register}
                  onChange={(e) => { e.target.value=e.target.value.toUpperCase(); }}
                  onBlur={e=>{ e.target.value=e.target.value.trim().toUpperCase();}}
                  defaultValue={props.datospersonal?.razonSocial}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="razonSocial" errors={errors}/>
            </div>

            <div className="md:flex md:items-center mb-6 mt-4">
                <button
                  className="btn-gray2 w-full lg:w-1/2"
                  type="button"
                  onClick={(e) => {
                    props.setRuta("menu");
                  }}
                  style={{ transition: "all .15s ease" }}
                >
                  Cancelar
                </button>

                <input
                  className="btn-blue w-full lg:w-1/2 cursor-pointer"
                  type="button"
                  disabled={verifyPermission()}
                  value="Guardar"
                  style={{ transition: "all .15s ease" }}
                  onClick={handleSubmit(sendData)}
                ></input>
            </div>
          </div>
        </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  datospersonal: state.SearchPersonal,
});

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
  setsearchPersonal: (personal) => dispatch(setsearchPersonal(personal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Formulariocliente);
