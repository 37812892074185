import React, { useState } from "react";
import { setRuta, setLogueo } from "../redux/Actions";
import { connect } from "react-redux";
import Modaldanger from "../components/modal/danger";
import { useSetLogin, useSetSucursal } from "../Hooks/useApis";
import { encryptData,  tiempoCookie } from "../services/functions";

const Login = ({ setRuta, setLogueo, setDataSucursal }) => {
  document.body.style = "background: rgba(17, 24, 39,1);";

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});

  const Crypto = require('crypto-js');

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  const setdatauser = (e) => {
    setUserName(e.target.value);
  };
  const setdatapassword = (e) => {
    setPassword(e.target.value.toUpperCase());
  };

  const handleSuccessfulAuth = (user) => {
      document.cookie = "sessionIndigo=" + user.token + "; ";//path=/; max-age=" + tiempoCookie; //valor en segundos, la cookie se almacenara por 18 horas;p
      useSetSucursal(user.sucursal,(respuesta)=>{ setDataSucursal(respuesta);},handleErrorAuth);
      setLogueo({...user,permisos:[...user.permisos].sort(),bgcolor:"bg-bluei",textcolor:"text-bluei"});
      setRuta("menu");
  };

  const handleErrorAuth = (error) => {
    
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message || "Error inesperado",
    });
  };

  const handleSubmit = (event) => {
    var encodedData = btoa(username.toUpperCase() + ":" + encryptData(password));
    useSetLogin(encodedData,handleSuccessfulAuth,handleErrorAuth)
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <>
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <section className="h-full items-center">
        <div className="sm:pt-24 lg:pt-0 sm:px-20 lg:px-8 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full md:w-5/12 lg:w-3/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-sm font-bold">Login</h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div
                  className="flex-auto px-4 lg:px-10 py-10 pt-0"
                  style={{ transition: "all .15s ease" }}
                >
                  <form>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Usuario
                      </label>
                      <input
                        type="text"
                        className="border-form uppercase w-full"
                        name="username"
                        placeholder="Usuario"
                        onChange={setdatauser}
                        required
                        style={{ transition: "all .15s ease" }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Contraseña
                      </label>
                      <input
                        type="password"
                        className="border-form uppercase w-full"
                        name="password"
                        placeholder="Contraseña"
                        onChange={setdatapassword}
                        onKeyDown={handleKeyDown}
                        required
                        style={{ transition: "all .15s ease" }}
                      />
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                        type="button"
                        onClick={handleSubmit}
                        style={{ transition: "all .15s ease" }}
                      >
                        Entrar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
  setLogueo: (datos) => dispatch(setLogueo(datos)),
  setDataSucursal: (datos) =>
    dispatch({
      type: "SET_DATASUCURSAL",
      datasucursal: datos,
    }),
});

export default connect(null, mapDispatchToProps)(Login);
