import React, { useEffect, useState} from "react";
import InputPrecioPanel from "../../InputPrecioPanel";
import TableReportes from "../TableReportes";
import { connect } from "react-redux";
import { moneyToValue, SearchValueInObject } from "../../../services/functions";

const ReporteMarcaLinea = ({PropsReportes,Datos,setHeader}) => {
    const [ventas,setVentas]=useState([]);
    const nameComponent = "inventarioMarcaLinea",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];

    useEffect(()=>{
        if(rutaActualRep!==nameComponent){return;}
        if(!Datos[nameComponent][0]){return}
        const marcas = Datos[nameComponent][0];
        let arreglo =[];
        for(let marca of Object.keys(marcas)){
          if(typeof marcas[marca] === "object" && !Array.isArray(marcas[marca])){
              arreglo.push(marcas[marca])
          }
        }
        setVentas(arreglo);
    },[PropsReportes.Datos])
  
    useEffect(()=>{
      if(rutaActualRep!==nameComponent){return;}
      setHeader({...PropsReportes.header,text:"Inventory "+Datos[nameComponent][1].marca});
    },[])
  
    return (
      <>
        <TableReportes
          searchinput={false}
          columns={[
            {
              header: "Brand",
              width:"w-24",
                          // eslint-disable-next-line react/display-name
              code: (item,index) => (
                <div 
                className="w-full flex justify-center text-center cursor-pointer"
                
                >
                  <input
                  type="text"
                  className="border-table w-full text-center cursor-pointer"
                  readOnly={true}
                  value={item?.nombre}
                  onClick={e=>{
                    if(item?.categorias.filter(cat=>(cat=="MICA"||cat=="LENTE DE CONTACTO"||cat=="ARMAZON")).length>0){return}
                    if(e.detail==2){
                      console.log('validar')
                    }
                  }}
                  />
                  <div className={"text-blue-600 w-1/6 "+(item?.categorias.filter(cat=>(cat=="MICA"||cat=="LENTE DE CONTACTO")).length>0?"flex ":"hidden ")}>
                      <button
                        onClick={(e) => {
                          let codigo = ""
                          if(item?.categorias.filter(cat=>(cat=="MICA")).length){
                            codigo = "MI"+SearchValueInObject(PropsReportes.header.Lineas,item?.nombre)[0];
                          }
                          else if(item?.categorias.filter(cat=>(cat=="LENTE DE CONTACTO")).length){
                            
                            codigo = "LC"+SearchValueInObject(PropsReportes.headerLineas,item?.nombre)[0];
                          }else{return}
                          
                        }}
                      >
                      <i className="fas fa-border-all"></i>
                    </button>
                  </div>
                  <div className={"text-blue-600 w-1/6 "+(item?.categorias.filter(cat=>(cat=="ARMAZON")).length==1?"flex ":"hidden ")}>
                      <button
                        onClick={(e) => { 
                          const codigo = "AR"+SearchValueInObject(PropsReportes.header.Lineas,item?.nombre)[0];
                          
                        }}
                      >
                      <i className="fas fa-glasses"></i>
                    </button>
                  </div>  
                </div>
              )
            }, 
            {
              header: "QohBgn",
              width:"w-32",
              // eslint-disable-next-line react/display-name
              code: (item,index) => (
                 <InputPrecioPanel
                  width="w-full"
                  noShowSignal={true}
                  hidden={(item?.qohb==0)}
                  newValor={moneyToValue(item?.qohb)}
                  readOnly={true}
                  setValor={(valor) => {}}
                 />
              ),
            },
            {
              header: "Rcvd",
              width:"w-28",
              // eslint-disable-next-line react/display-name
              code: (item,index) => (
                  <InputPrecioPanel
                  width="w-full"
                  bgtext="text-blue-500"
                  noShowSignal={true}
                  hidden={(item?.cantidadComprada==0)}
                  newValor={moneyToValue(item?.cantidadComprada)}
                  readOnly={true}
                  setValor={(valor) => {}}
                 />
              ),
            },
            {
              header: "Sold",
              width:"w-28",
              // eslint-disable-next-line react/display-name
              code: (item,index) => (
                  <InputPrecioPanel
                  width="w-full"
                  noShowSignal={true}
                  bgtext="text-pink-700"
                  hidden={(item?.cantidadVendida==0)}
                  newValor={moneyToValue(item?.cantidadVendida)}
                  readOnly={true}
                  setValor={(valor) => {}}
                 />
              ),
            },
            {
              header: "Qoh End",
              width:"w-32",
              // eslint-disable-next-line react/display-name
              code: (item,index) => (
                <InputPrecioPanel
                  width="w-full"
                  noShowSignal={true}
                  hidden={(item?.existencia==0)}
                  newValor={moneyToValue(item?.existencia)}
                  readOnly={true}
                  setValor={(valor) => {}}
                 />
              ),
            },
            {
              header: "Amount",
              width:"w-40",
              // eslint-disable-next-line react/display-name
              code: (item,index) => (
                  <InputPrecioPanel
                  min={0}
                  width="w-full"
                  hidden={(item?.total==0)}
                  newValor={item?.total}
                  readOnly={true}
                  disabled={true}
                  setValor={(valor) => {}}
                 />
              ),
            },
            {
              header:"Category",
              width:"w-24",
                          // eslint-disable-next-line react/display-name
              code: (item,index) => (
                <input
                type="text"
                className="border-table w-full text-center"
                readOnly={true}
                value={item?.categorias.join("-")}
              />
              )
            },
          ]}
          footer={[
            { 
              width:"bg-gray-100",
              code: <label className="font-semibold">Total:</label>
            },
            {
              width:"bg-gray-100",
              // eslint-disable-next-line react/display-name
              code:  <InputPrecioPanel 
              width="w-full bg-red-200" 
              noShowSignal={true}
              min={0} 
              newValor={moneyToValue(ventas.reduce((a, b) => parseInt(a) + parseInt(b.qohb), 0))} 
              readOnly={true} 
              setValor={e=>{}}
              />
            },
            {
              width:"bg-gray-100",
              // eslint-disable-next-line react/display-name
              code:  <InputPrecioPanel 
              width="w-full" 
              noShowSignal={true}
              min={0} 
              newValor={moneyToValue(ventas.reduce((a, b) => parseInt(a) + parseInt(b.cantidadComprada), 0))} 
              readOnly={true} 
              setValor={e=>{}}/>
            },
            {
              width:"bg-gray-100",
              // eslint-disable-next-line react/display-name
              code:  <InputPrecioPanel 
              width="w-full" 
              noShowSignal={true}
              min={0} 
              newValor={moneyToValue(ventas.reduce((a, b) => parseInt(a) + parseInt(b.cantidadVendida), 0))} 
              readOnly={true} 
              setValor={e=>{}}/>
            },
            {
              width:"bg-gray-100",
              // eslint-disable-next-line react/display-name
              code:  <InputPrecioPanel 
              width="w-full" 
              noShowSignal={true}
              min={0} 
              newValor={moneyToValue(ventas.reduce((a, b) => parseInt(a) + parseInt(b.existencia), 0))} 
              readOnly={true} 
              setValor={e=>{}}/>
            },
            {
              width:"bg-gray-100",
               // eslint-disable-next-line react/display-name
               code:  <InputPrecioPanel 
               width="w-full" 
               min={0} 
               newValor={ventas.reduce((a, b) => parseInt(a) + parseInt(b.total), 0)} 
               readOnly={true} 
               setValor={e=>{}}/>
            },
            {

            }
          ]}
          data={ventas}
        />
      </>
    );
  };
  
  const mapDispatchToProps = (dispatch) => ({
    setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
    addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  });
  const mapStateToProps = (state) => ({
    PropsReportes: state.PropsReportes,
    Datos: state.Datos,
  });

export default connect (mapStateToProps,mapDispatchToProps)(ReporteMarcaLinea);
