import React, { useMemo, useState } from "react";
import { ConvertMoneytoFormat, ceros_decimal, valueToMoney, valueToMoney2 } from "../services/functions.js";

const Labelmoney = (props) => {
  const [aux,setaux]=useState({text:"",number:0});

  useMemo(()=>{
    const val = (props.children?(props?.twoDecimal?valueToMoney2(props.children||0).toFixed(2):valueToMoney(props.children||0).toFixed((ceros_decimal+"").length-1)):"0")
    ConvertMoneytoFormat(val,aux,setaux);
  },[props.children]);

  return <p 
  className={(props?.className?props.className:"w-full ")+" font-semibold outline-none bg-transparent text-right "+(aux.number<0?"text-red-500":props?.font)} 
  >
    {(aux.number==0?"-":(props.noShowSignal?"":"$ ")+aux.text+(aux.text.split('.').length==2?"":".00"))}
  </p>
};

export default Labelmoney;
