import React from "react";

const LabelErrors = ({name,errors}) => {
  return (
    <p className={(errors[name]?.message?"text-sm italic font-semibold break-words text-center text-red-500 w-full":"hidden")}>
        {errors[name]?.message}
    </p>
  );
};

export default LabelErrors;
