import React,{useState} from "react";
import { connect } from "react-redux";

const DatosCliente = (props) => {
  const [showDetail,setShowDetail]= useState(false);
  const clie = (props.venta?props.ClienteVenta:props.Cliente);

  return (
    <>
      <div className={"flex-auto px-3  border shadow-lg border-gray-300 rounded-b-lg bg-gray-100 font-semibold text-gray-500 transform duration-200 "+ (showDetail?"translate-y-0.5":" -translate-y-0")}>
        <div className="flex flex-wrap">
          <div className="relative text-bluei w-full pr-4 max-w-full flex-grow flex-1 text-sm lg:text-lg">
            {(clie?.id?"Cliente: "+ clie.nombre + " Codigo: "+ clie.id:null)}
          </div>
          <div className="relative w-auto pl-4 flex-initial">
            <div className={(clie?.id ? "bg-blue-600" : "bg-sky-300") + " text-white text-center inline-flex items-center justify-center w-8 h-8 rounded-full "}>
              <i className="fas fa-users"></i>
            </div>
            <button 
              className="pl-1 text-bluei hover:text-blue-600 text-xl"
              onClick={e=>{setShowDetail(!showDetail)}}
            >
              <i className={"fas "+ (!showDetail?"fa-chevron-circle-down":"fa-chevron-circle-up")}/>
            </button>
          </div>
        </div>
        <div className={(showDetail?"block relative w-full pr-4 max-w-full flex-grow flex-1":"hidden shadow-none")}>
          <label className="block text-sm">
            {clie?.rfc ? "RFC: "+clie.rfc : ""} 
            {clie?.direccion?" Dir: "+clie.direccion:null} 
            {clie?.email?" Correo: "+clie.email:null} 
            {clie?.telefono?" Telefono: "+clie.telefono:null} 
            </label>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ClienteVenta: state.ClientesVenta,
  Cliente: state.Clientes
});

export default connect(mapStateToProps)(DatosCliente);