import React, { useState,useEffect, useMemo, useRef } from "react";
import { connect } from 'react-redux';
import { agregarItemInventario, eliminarItemInventario, returnRuta, setRuta } from "../../redux/Actions";
import { CalculaMinPrecioVenta, CalculaPrecioCompraPromediado, getSucursal, moneyToValue, redondeo, showMoney} from '../../services/functions'
import InputPrecioPanel from "../InputPrecioPanel";
import ContextMenu from "../modal/ContextMenu";
import Modaldanger from "../modal/danger";
import Modalgeneric from "../modal/generic";

const Tablefromjs = ({ addInventario,linea,inventario,data,PropsInv,setPropsInventario,setInventario,delRuta,resetColors}) => {
  const tablas = Object.keys(data), 
  line = linea,
  new_linea = linea.substring(0,linea.length-1),
  orden_esfera = (linea[linea.length-1]=="-"?[-1,1]:[1,-1]),
  orden_ciladd = (PropsInv.data?.descripcion.indexOf("MONOFOCAL")>0?[-1,1]:[1,-1]);

  const [metadata_tabla,setMetadata]=useState({}),
  [inventarioTotal,setInventarioTotal]=useState(0),
  [modalGeneric,setModalGeneric]=useState(false),
  [modalPrecioCompra,setModalPrecioCompra]=useState(false),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [preciog,setPreciog]= useState(0),
  [modalMenu, setmodalMenu] = useState({});
  
  const title = useRef("Sin Datos"),inputs =useRef({});

  const validaSucursallEdit=()=>{
    if(PropsInv.sucursales[0]!==getSucursal()){
      setmodaldangererrorConfig({
        isOpen: true,
        message:  "Desbes cambiar la sucursal actual de: "+PropsInv.sucursales[0]+" a la sucursal "+getSucursal() ,
      });
      return false;
    }else{ return true}
  }

  const getCantidad=(id)=>{
    let item = inventario.find(item=>item.id == id);
    return item?.cantidad || ""
  }
  const getitemOfInventario=(id)=>{
    let item = inventario.find(item=>item.id == id);
    return (item?{...item}:"")
  }

  const getitemOfProps=(id)=>{
    let item = PropsInv.items.find(item=>item.id == id);

    return item||{};
  }

  const getColorCell=(data,nombreTabla,ciladd,esfera)=>{
    const item = getitemOfInventario(data[nombreTabla][ciladd][esfera]);
    if(PropsInv.tab=="G2"){
      return item?.G2>item?.guideline?" bg-teal-200 ":(item?.G2<item?.guideline?" bg-red-200 ":"");
    }else if(PropsInv?.groupPrice){
      if(PropsInv.groupPrice.find(item=>item==data[nombreTabla][ciladd][esfera])){
        return "  bg-bluei text-white ";
      }else{
        return PropsInv.listprecios[item?.precioVenta]
      }
    }else if(PropsInv.oferta){
      return (!item.oferta?"bg-transparent":(PropsInv.oferta>item.oferta?"bg-red-200":"bg-teal-100"))
    }else{
      return PropsInv.listprecios[item?.precioVenta]
    }
  }
  
  const SubTotales=(nombreTabla,bycolumna)=>{
    let obj = [],total=0;
  
    if(JSON.stringify(metadata_tabla)!=="{}"){
      metadata_tabla[nombreTabla]["ciladd"].sort(bycolumna).map((itemColumna, index) => {
        total = 0;
          inventario.forEach(element => {
            if(element.ciladd.trim() == itemColumna.trim() && line == element.linea){
              total += 
              (PropsInv?.tab=="IN"||PropsInv?.tab=="B" ?parseInt(element.cantidad)
              :(PropsInv?.tab=="G" ?parseInt(element.guideline)
                :(PropsInv?.tab=="O"?(element.N>0?element.N:0)
                  :parseInt(element.[PropsInv?.tab]||0))
              ));
            }
          }); 
        obj.push(
          <td
            key={index}
            className={"cursor-pointer px-2 align-middle py-2 text-xs uppercase whitespace-nowrap font-bold text-center"}
            data-priority={index}
          >{total}
          </td>
        )
      });
    }
    return  obj;
  }

  function calculatotal() {
    let gTotal = 0;
    if(PropsInv?.tab=="IN"||PropsInv?.tab=="B") gTotal =inventario.filter(item=>(item.linea==line)).reduce((a, b) => parseInt(a) + parseInt(b.cantidad), 0);
    else if(PropsInv?.tab=="G") gTotal =inventario.filter(item=>(item.linea==line)).reduce((a, b) => parseInt(a) + parseInt(b.guideline), 0);
    else if(PropsInv?.tab=="O") gTotal =inventario.filter(item=>(item.linea==line)).reduce((a, b) => a + (b.N>0?b.N:0), 0);
    else gTotal =inventario.filter(item=>(item.linea==line)).reduce((a, b) => parseInt(a) + parseInt(b.[PropsInv?.tab]||0), 0);
    setInventarioTotal(gTotal);
  }

  function bycolumna(a, b) {
    const n1 = parseFloat(a);
    const n2 = parseFloat(b);
    
    if (n1 > n2) return orden_ciladd[0];
    if (n1 < n2) return orden_ciladd[1];

    return 0;
  }

  function byfila(a, b) {
    const n1 = parseFloat(a);
    const n2 = parseFloat(b);

    if (n1 > n2) return orden_esfera[0];
    if (n1 < n2) return orden_esfera[1];

    return 0;
  }

  function AddItems(item){
    return [
      <div key="0" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv.editItems&&!PropsInv.ajuste?(PropsInv?.groupPrice?"hidden":"block"):(PropsInv?.updatePrice&&!PropsInv?.groupPrice?"block":"hidden"))}
      onClick={e=>{
        const newProps = {...PropsInv,groupPrice:[],closeMenu:true};
        delete newProps?.globalPrice;
        setPropsInventario(newProps);
        closemodalMenu();
      }}>
          Seleccionar Graduaciones
      </div>,
      <div key="1" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv.editItems&&!PropsInv.ajuste?(!PropsInv?.groupPrice?"hidden":"block"):(PropsInv?.updatePrice?"block":"hidden"))}
      onClick={e=>{
        if(!PropsInv?.groupPrice){return;}
        if(PropsInv.groupPrice.length==0){return}
        let sumMinPv = 0;//promedia todos los minPv,asi no hay perdida en general
        PropsInv.groupPrice.forEach(id=>{
          const itemProps = inventario.find(item=>item.id==id);
          itemProps?sumMinPv+=itemProps.minPrecioVenta:0;
        });
        const newMinPv = redondeo(sumMinPv/PropsInv.groupPrice.length);
        setPropsInventario({...PropsInv,minPrecioVenta:newMinPv});
        openModalGeneric();
      }}>
        Cambiar Precio Venta
      </div>,
      <label key="2" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv.editItems?(!PropsInv?.groupPrice?"hidden":"block"):"hidden")}
      onClick={e=>{
        if(!PropsInv?.groupPrice){return;}
        const props = {...PropsInv,closeMenu:true};
        delete props.groupPrice;
        delete props.selectItems;
        setPropsInventario(props); 
        closemodalMenu();
      }}>
        Cancelar
      </label>,
      <div key="3" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv.editItems&&!PropsInv.ajuste?(PropsInv?.groupPrice?"hidden":"block"):(PropsInv?.updatePrice?"block":"hidden"))}
      onClick={e=>{
        const newMinPv = inventario.reduce((a,b)=>a+b.minPrecioVenta,0)/inventario.length;
        setPropsInventario({...PropsInv,minPrecioVenta:newMinPv, globalPrice:true,oldInv:[...inventario],closeMenu:true});
        openModalGeneric();
      }}>
        <i className="fas fa-dollar-sign ml-1"></i>
        {" Cambiar Precio General"}
      </div>,
      <div key="4" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.tab=="IN"&&PropsInv?.sucursales.length==1&&!PropsInv?.ajuste&&!PropsInv?.updatePrice&&!PropsInv?.globalPriceBuy?"block":"hidden")}
      onClick={e=>{
        validaSucursallEdit()?setPropsInventario({...PropsInv,editItems:true,ajuste:true,inventarioAjuste:[],oldInv:[...inventario],closeMenu:true}):"";
      }}>
          Ajuste Inventario
      </div>,
      <div key="5" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.tab=="B"?"block":"hidden")}
      onClick={e=>{
        delRuta();
      }}>
        <i className="far fa-arrow-alt-circle-left text-blue-500"></i>
          {" Regresar a Compras"}
      </div>,
      <div key="6" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.ajuste||PropsInv?.updatePrice||PropsInv?.globalPriceBuy?"block":"hidden")}
      onClick={e=>{
        if(PropsInv?.oldInv){
          //console.log(PropsInv.oldInv)
          setInventario(PropsInv.oldInv);
        }
        const props ={...PropsInv,closeMenu:true};
        delete props.ajuste;
        props.editItems = false;
        delete props.inventarioAjuste;
        delete props.updatePrice;
        delete props.updatePriceBuy;
        delete props.globalPriceBuy;
        delete props.globalPrice;
        delete props.selectItems;
        delete props.groupPrice;
        delete props.oldInv;
        
        setPropsInventario(props);
      }}>
          {"Cancelar Ajuste"}
      </div>,
      <div key="7" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv.editItems?(PropsInv?.tab=="B"?"block":(PropsInv?.tab=="G"&&PropsInv?.ajuste?"block":"hidden")):"hidden")}
      onClick={e=>{
        setPropsInventario({...PropsInv,enableImport:true,closeMenu:true});
      }}>
        <i className="fas fa-file-download "></i>
          {" Importar Formato Micas"}
      </div>,
      <div key="8" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.editItems?(PropsInv.editItems==false?"block":"hidden"):"block")}
      onClick={e=>{
        setPropsInventario({...PropsInv,enableExport:true,closeMenu:true});
      }}>
        <i className="fas fa-file-upload "></i>
          {" Exportar Formato Micas"}
      </div>,
      <div key="9" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.tab=="IN"&&PropsInv?.sucursales.length==1&&!PropsInv?.ajuste&&!PropsInv?.updatePrice?"block":"hidden")}
      onClick={e=>{
        validaSucursallEdit()?setPropsInventario({...PropsInv,updatePrice:true,inventarioAjuste:[],closeMenu:true}):"";
      }}>
          Actualizar Precio Venta
      </div>,
      <div key="10" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.tab=="G"?(PropsInv.editItems==false?"block":"hidden"):"hidden")}
      onClick={e=>{
        setPropsInventario({...PropsInv,editItems:true,ajuste:true,inventarioAjuste:[],oldInv:[...inventario],closeMenu:true});
      }}>
          Ajustar GuideLine
      </div>,
      <div key="11" 
      className={'font-semibold py-1 px-1 bg-red-200 hover:bg-gray-300 '+(PropsInv?.tab=="IN"&&PropsInv?.sucursales.length==1&&!PropsInv?.ajuste&&!PropsInv?.globalPriceBuy?"block":"hidden")}
      onClick={e=>{
        validaSucursallEdit()?setPropsInventario({...PropsInv,globalPriceBuy:true,updatePriceBuy:true,inventarioAjuste:[],oldInv:[...inventario],closeMenu:true}):"";
        openModalPrecioCompra();
      }}>
          Actualizar Precio Compra
      </div>,
      <div key="12" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.tab=="IN"&&PropsInv?.sucursales.length==1&&!PropsInv?.oferta?"block":"hidden")}
      onClick={e=>{
        validaSucursallEdit()?setPropsInventario({...PropsInv,ofertaEnable:true,inventarioAjuste:[],oldInv:[...inventario],closeMenu:true}):"";
      }}>
          Calcular Oferta
      </div>,
      <div key="13" 
      className={'font-semibold py-1 px-1 hover:bg-gray-300 '+(PropsInv?.oferta?"block":"hidden")}
      onClick={e=>{
        if(PropsInv?.oldInv){
          //console.log(PropsInv.oldInv)
          setInventario(PropsInv.oldInv);
        }
        const props ={...PropsInv,closeMenu:true};
        delete props.ofertaEnable;
        delete props.inventarioAjuste;
        delete props.oldInv;
        delete props.oferta;
        
        setPropsInventario(props);
      }}>
          Cancelar Oferta
      </div>,
    ]
  }

  function closeModalPrecioCompra(e) { setModalPrecioCompra({ isOpen: false });}
  function openModalPrecioCompra(e){ setModalPrecioCompra({ isOpen: true });}
  function closeModalGeneric(e) { setModalGeneric({ isOpen: false });}
  function openModalGeneric(e){ setModalGeneric({ isOpen: true });}
  const openmodalMenu = (e,item) => {
    const new_item = item || {};
    setPropsInventario({...PropsInv,closeMenu:false});
    setmodalMenu({ isOpen: true, title: title, x: e.clientX, y: e.clientY-20, items: AddItems(new_item)});
  };
  const closemodalMenu = (e) => { setmodalMenu({ isOpen: false, title: "", x: 0, y: 0}); };
  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }
  
  useMemo(()=>{
    if(!PropsInv?.idcompra){
      if(!inventario.length){setMetadata({});  return}
      if(JSON.stringify(metadata_tabla)!=="{}"){ return}
    }
    let meta = {};
    for (let nombre_tabla of tablas) {
      let temporal = {};
      meta[nombre_tabla] = {};
      meta[nombre_tabla]["ciladd"] = Object.keys(data[nombre_tabla]);
      for (let ejecolumna of meta[nombre_tabla]["ciladd"]) {
        let ejefila = Object.keys(data[nombre_tabla][ejecolumna]);
        for (let datoejefila of ejefila) {
          temporal[datoejefila] = datoejefila;
        }
      }
      meta[nombre_tabla]["esfera"] = Object.keys(temporal);
    }
    setMetadata(meta);
  },[inventario])

  useMemo(() => {
    calculatotal();
  }, [inventario,PropsInv?.tab]);


  useEffect(() => {
    if(PropsInv?.closeMenu==true){
      closemodalMenu();
    }
  }, [PropsInv?.closeMenu])

  useEffect(() => {
    const props = {...PropsInv};
    delete props.groupPrice;
    delete props.selectItems;
    setPropsInventario(props); 
    closemodalMenu();
  }, [PropsInv?.tab])
  
  useEffect(()=>{
    let list = {...PropsInv.listprecios};
    const colores = {...PropsInv.colores};
    if(!inventario.length){ resetColors();return;}
    inventario.map(item=>{
      if(!Object.keys(colores).find(color=>colores[color]==item.precioVenta)){
        const colors = Object.keys(colores);
        for(let i =0;i<colors.length;i++){
            if(colores[colors[i]]==""){
              colores[colors[i]]=item.precioVenta;
              list[item.precioVenta] = colors[i];
              break;
            }
        }
      }
    })
    setPropsInventario({...PropsInv, colores:colores,listprecios:list});
  },[inventario,PropsInv.data?.descripcion])

  return (
    <>
    <ContextMenu config={modalMenu} closemodalMenu={closemodalMenu}/>

    <Modaldanger config={modaldangererrorConfig}>
      <Modaldanger.Message title={"Error"}>
      {modaldangererrorConfig.message}
      </Modaldanger.Message>
      <Modaldanger.Buttons>
        <button
        type="button"
        className="btn-gray2 "
        onClick={closeModaldangererror} >
          Cerrar
        </button>
      </Modaldanger.Buttons>
    </Modaldanger>
    
    <Modalgeneric config={modalGeneric}>
    <Modalgeneric.Body>
      <Modalgeneric.Title>Precio venta </Modalgeneric.Title>
      <div className="flex flex-col w-full items-center">
        <div className="w-full md:w-24">
          <InputPrecioPanel
          form={true}
          min={PropsInv?.minPrecioVenta}
          setValor={(e)=>{ 
            setPreciog(moneyToValue(e));
          }}/>
        </div>
      </div>
    </Modalgeneric.Body>
    <Modalgeneric.Buttons>
      <button
        type="button"
        disabled={(preciog<PropsInv?.minPrecioVenta)}
        onClick={e=>{
          let new_inventario = [];
          if(PropsInv?.globalPrice){
            inventario.forEach(item=>{
              const newItem = {...item};
              if(newItem.precioCompra>=0){
                newItem.precioVenta = preciog;
              }
              new_inventario.push(newItem);
            })
          }else{
            inventario.forEach(item=>{
              const index = PropsInv.groupPrice.indexOf(item.id);
              if(index>-1){
                new_inventario.push({...item,precioVenta:preciog});
              }else{
                new_inventario.push({...item})
              }
            })
          }
          setInventario(new_inventario);
          const props = {...PropsInv};
          delete props.groupPrice;
          delete props.selectItems;
          setPropsInventario(props);
          closeModalGeneric();
        }}
        className="btn-green"
      >
        Aplicar Precio Venta
      </button>
      <button
        type="button"
        onClick={closeModalGeneric}
        className="btn-gray1"
      >
        Cancelar
      </button>
    </Modalgeneric.Buttons>
  </Modalgeneric>

  <Modalgeneric config={modalPrecioCompra}>
    <Modalgeneric.Body>
      <Modalgeneric.Title>Precio Compra </Modalgeneric.Title>
      <div className="flex flex-col w-full items-center">
        <div className="w-full md:w-24">
          <InputPrecioPanel
          form={true}
          min={0}
          setValor={(e)=>{ 
            setPreciog(moneyToValue(parseFloat(e)));
          }}/>
        </div>
      </div>
    </Modalgeneric.Body>
    <Modalgeneric.Buttons>
      <button
        type="button"
        disabled={(preciog<0)}
        onClick={e=>{
          let new_inventario = [];
          if(PropsInv?.globalPriceBuy){
            inventario.forEach(item=>{
              new_inventario.push({...item,precioCompra:preciog});
            })
          }
          setInventario(new_inventario);
          const props = {...PropsInv};
          delete props.selectItems;
          setPropsInventario(props);
          closeModalPrecioCompra();
        }}
        className="btn-green"
      >
        Aplicar Precio Compra
      </button>
      <button
        type="button"
        onClick={closeModalPrecioCompra}
        className="btn-gray1"
      >
        Cancelar
      </button>
    </Modalgeneric.Buttons>
  </Modalgeneric>
    <div className=" ">
      {tablas.map((nombreTabla, index) => (
        <div key={index} className=" relative flex flex-col mb-1 break-words rounded bg-white border-0">
          <table>
            <thead>
              <tr>
                <th className='w-16 bg-cyan-200 border border-gray-500 font-normal text-sm'>{"SPH("+line.substring(line.length-1)+")"}</th>
                {(JSON.stringify(metadata_tabla)!=="{}")?
                metadata_tabla[nombreTabla]["ciladd"].sort(bycolumna).map((itemColumna, index) => (
                <th
                key={index}
                onClick={(e) => {/*setdesc_fila(!desc_fila);*/}}
                className={"w-16 cursor-pointer align-middle pt-1 uppercase font-semibold text-center bg-cyan-200 border border-gray-500" }
                data-priority={index}
                >
                  {itemColumna}
                </th>
                ))
              :null}
              </tr>
            </thead>

            <tbody>
              {(JSON.stringify(metadata_tabla)!=="{}")?
              metadata_tabla[nombreTabla]["esfera"].sort(byfila).map((esfera, index1) => (
              <tr key={index1} >
                <td
                className={"w-16 bg-cyan-200 text-center font-semibold cursor-pointer pl-1 align-middle border border-gray-500 "}
                onClick={(e) => {/*setdesc_columna(!desc_columna);*/}}
                >
                  {esfera}
                </td>
                {metadata_tabla[nombreTabla]["ciladd"].sort(bycolumna).map((ciladd, index2) => (
                <td key={index2} className={"w-16 align-middle border border-black group "+getColorCell(data,nombreTabla,ciladd,esfera)}>
                  {PropsInv?.tab=="IN"||PropsInv?.tab=="B"?
                  <input
                  id={data[nombreTabla][ciladd][esfera]}
                  onWheel={e=>{
                    e.target.blur();
                  }}
                  type="number"
                  step='1'
                  min="0"
                  className={("w-16 text-base focus:outline-none focus:appearance-none text-center font-bold uppercase outline-none  " +(getCantidad(data?.[nombreTabla]?.[ciladd]?.[esfera])<0?"bg-red-100 text-red-600":"bg-transparent"))}
                  value={getCantidad(data[nombreTabla][ciladd][esfera]) }
                  readOnly={!PropsInv?.editItems}
                  onChange={e=>{
                    const getItemInv = getitemOfInventario(data[nombreTabla][ciladd][esfera]);
                    const itemProps = getitemOfProps(data[nombreTabla][ciladd][esfera]);
                    const itemPropsSucursal = itemProps?.sucursales[PropsInv.sucursales[0]]?{...itemProps.sucursales[PropsInv.sucursales[0]]}:{};
                    const cantidad = e.target.value==""?0:parseInt(e.target.value)
                    
                    const newItem = {
                      id:new_linea+esfera+ciladd.trim(),
                      esfera:esfera,
                      ciladd:ciladd.trim(),
                      descripcion: itemProps.descripcion,
                      linea:line,
                      type: PropsInv.data.linea,
                      marca: PropsInv.data.marca,
                      categoria: PropsInv.data.categoria,
                      cantidad: cantidad,
                      comprable: getItemInv?.comprable||itemPropsSucursal?.comprable || true,
                      vendible: getItemInv?.vendible||itemPropsSucursal?.vendible || true,
                    }

                    if(PropsInv?.ajuste){
                      if(e.target.value==""){return;}
                      let invAjuste=[...PropsInv.inventarioAjuste];
                      let item = invAjuste.find(item=>item.id == data[nombreTabla][ciladd][esfera]);

                      if(!item){
                        invAjuste.push(getItemInv); 
                        setPropsInventario({...PropsInv,inventarioAjuste:invAjuste})
                      }

                      newItem.precioCompra = getItemInv?.precioCompra||itemPropsSucursal?.precioCompra||0;
                      newItem.precioVenta = getItemInv?.precioVenta||(itemPropsSucursal?.precioVenta>PropsInv?.minPrecioVenta?itemPropsSucursal?.precioVenta:0);
                      
                    }else{
                        e.target.value == 0?e.target.value = "":"";
                        
                        const pc =PropsInv.precioCompra;
                        const oldPc = itemPropsSucursal?.precioCompra|| pc;
                        const pv = getItemInv?.precioVenta||itemPropsSucursal?.precioVenta||0;

                        newItem.guideline = itemPropsSucursal?.guideline||0;
                        newItem.oldExistencia = itemPropsSucursal?.existencia||0;
                        newItem.precioCompra = pc;
                        newItem.oldPrecioCompra = oldPc;
                        newItem.precioCompraPromediado = CalculaPrecioCompraPromediado(newItem.precioCompra,newItem.cantidad,newItem.oldPrecioCompra,newItem.oldExistencia);
                        newItem.minPrecioVenta = CalculaMinPrecioVenta(newItem.precioCompraPromediado,newItem.categoria);
                        newItem.precioVenta = pv<newItem.minPrecioVenta?newItem.minPrecioVenta:pv;
                        //console.log('===>',CalculaPrecioCompraPromediado(newItem.precioCompra,newItem.cantidad,newItem.oldPrecioCompra,newItem.oldExistencia),' pc=>',newItem.precioCompra,' cant=>',newItem.cantidad,' oldpc=>',newItem.oldPrecioCompra,' oldEx=>',newItem.oldExistencia)
                    }
                    addInventario(newItem);
                  }}
                  onMouseEnter={e=>{
                    const getItemInv =getitemOfInventario(data[nombreTabla][ciladd][esfera]),itemId = data[nombreTabla][ciladd][esfera];
                    if(PropsInv?.groupPrice&&getItemInv?.id&&!PropsInv?.selectItems){
                      const props ={...PropsInv};
                      let list = [...PropsInv.groupPrice];
                      if(list.indexOf(itemId)===-1){//recorre la lista de las gradauciones seleccionadoas y si no encuentra la graduacion lo agrega al grupo
                        list.push(itemId);
                        if(PropsInv?.updatePrice==true){
                          let invAjuste=[...PropsInv.inventarioAjuste];
                          let item = invAjuste.find(item=>item.id == itemId);
                          if(!item){
                            invAjuste.push(getItemInv); 
                            props.inventarioAjuste = invAjuste;
                          }
                        }
                      }else{//si la graduacion ya esta en el grupo lo elimina del grupo
                        list = list.filter(item=> item !==itemId)
                        if(PropsInv?.updatePrice==true){
                          let invAjuste=[...PropsInv?.inventarioAjuste];
                          invAjuste=invAjuste.filter(inv=>inv.id!==itemId)
                          props.inventarioAjuste = invAjuste;
                        }
                      }
                     
                      props.groupPrice = list;
                      setPropsInventario(props)
                    }
                  }}
                  onClick={e=>{
                    closemodalMenu();
                    if(PropsInv?.groupPrice){
                      setPropsInventario({...PropsInv,selectItems:!PropsInv?.selectItems})
                    }
                  }}
                  onContextMenu={e=>{
                    openmodalMenu(e,getitemOfInventario(data[nombreTabla][ciladd][esfera]));
                  }}
                  />
                  :                  
                  <input
                  ref={e=>{inputs.current[data[nombreTabla][ciladd][esfera]+'o']=e}}
                  onWheel={e=>{
                    e.target.blur();
                  }}
                  type="number"
                  className={"w-16 text-base focus:outline-none focus:appearance-none text-center font-bold uppercase outline-none bg-transparent "+(inputs.current[data[nombreTabla][ciladd][esfera]+'o']?.value<0?"text-red-400":"text-black")}
                  value={(PropsInv?.tab=="G"?getitemOfInventario(data[nombreTabla][ciladd][esfera])?.guideline||"":(PropsInv?.tab=="O"?(getitemOfInventario(data[nombreTabla][ciladd][esfera])?.N>0?getitemOfInventario(data[nombreTabla][ciladd][esfera])?.N||"":""):(getitemOfInventario(data[nombreTabla][ciladd][esfera])?.[PropsInv?.tab]||"")))}
                  readOnly={!PropsInv?.editItems}
                  onContextMenu={e=>{
                    openmodalMenu(e,getitemOfInventario(data[nombreTabla][ciladd][esfera]));
                  }}
                  onChange={e=>{
                    const itemID = data[nombreTabla][ciladd][esfera];
                    const getItemInv = getitemOfInventario(itemID);
                    const itemProps = getitemOfProps(itemID);
                    const itemPropsSucursal = itemProps?.sucursales[PropsInv.sucursales[0]]?{...itemProps.sucursales[PropsInv.sucursales[0]]}:{};

                    if(PropsInv?.ajuste&&!PropsInv?.importExcel){
                      if(e.target.value==""){return;}
                      let invAjuste=[...PropsInv.inventarioAjuste];
                      let item = invAjuste.find(item=>item.id == itemID);

                      if(!item){
                        invAjuste.push(getItemInv); 
                        setPropsInventario({...PropsInv,inventarioAjuste:invAjuste})
                      }
                      addInventario({
                          id:new_linea+esfera+ciladd.trim(),
                          esfera:esfera,
                          ciladd:ciladd.trim(),
                          descripcion: itemProps.descripcion,
                          linea:line,
                          type: PropsInv.data.linea,
                          marca: PropsInv.data.marca,
                          categoria: PropsInv.data.categoria,
                          comprable: getItemInv?.comprable||itemPropsSucursal?.comprable || true,
                          vendible: getItemInv?.vendible||itemPropsSucursal?.vendible || true,

                          guideline: parseInt(e.target.value),
                          cantidad: 0,
                          precioVenta:  getItemInv?.precioVenta||(itemPropsSucursal?.precioVenta>PropsInv?.minPrecioVenta?itemPropsSucursal?.precioVenta:0),
                          precioCompra: getItemInv?.precioCompra||itemPropsSucursal?.precioCompra||0,
                      });
                    }
                  }}
                  />
                }
                  <span className={(PropsInv?.tab=="IN"||PropsInv?.tab=="B"?(!PropsInv?.groupPrice?"tooltiptext":"hidden"):"hidden")}>
                     {showMoney(getitemOfInventario(data[nombreTabla][ciladd][esfera])?.precioVenta)}
                  </span>

                  {/* <span className={(PropsInv?.tab=="IN"||PropsInv?.tab=="B"?(!PropsInv?.groupPrice?"tooltiptext":"hidden"):"hidden")}>
                     {showMoney(getitemOfInventario(data[nombreTabla][ciladd][esfera])?.precioCompra)}
                  </span> */}
                
                </td>
                ))}
              </tr>
              ))
            :null}
            </tbody>

            <tfoot>
              <tr>
                <td>SubTotal</td>{SubTotales(nombreTabla,bycolumna)}
                <td></td>
              </tr>
              <tr>
                 <td className=" w-full" colSpan={"100"}>
                  <label className="w-full block font-bold text-xl text-center">{"Total: "+inventarioTotal}</label> 
                 </td> 
              </tr>
            </tfoot>
          </table>
        </div>
      ))}
    </div>
    </>
  );
};

const mapStateToProps = (state) => ({
    inventario: state.Inventario,
    PropsInv: state.PropsInventario,
})

const mapDispatchToProps = dispatch => ({
    addInventario: (item) => dispatch(agregarItemInventario(item)),
    delInventario: (item) => dispatch(eliminarItemInventario(item)),
    setPrecioInventario:(esfera,precioVenta) => dispatch({type:"SET_PRECIOINVENTARIO",item:{precioVenta:precioVenta,esfera:esfera}}),
    setPropsInventario:(data)=> dispatch({type:"SET_PROPSINVENTARIO", estado:data}),
    setInventario:(items)=>dispatch({type:"SET_INVENTARIO",inventario:items}),

    resetColors:()=>dispatch({type:"RESET_PROPSCOLORS"}),
    addRuta: (ruta) => dispatch(setRuta(ruta)),
    delRuta:()=>dispatch(returnRuta()),
})

export default connect(mapStateToProps,mapDispatchToProps)(Tablefromjs)
