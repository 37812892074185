import React from 'react'

export default function TarjetaStatus(props) {
    return (
        <div
        key={props?.index?props.key:0}
        className={props.className+" w-full justify-center items-center mr-1 font-medium py-1 px-1 rounded-full text-center text-white cursor-pointer filter drop-shadow-md "+(props?.bg?props.bg:"bg-blue-800 border border-indigo-300 hover:bg-blue-600")}
        disabled={props?.disabled?props.disabled:false}
        >
            <div 
            className="text-sm font-semibold leading-none max-w-full flex-initial mx-1 disabled:opacity-50"
            disabled={props?.disabled?props.disabled:false}
            defaultValue={props?.valor}
            onClick={e=>{props.onClick(e,props?.valor)}}>
                {props?.valor}
            </div>
        </div>
    )
}