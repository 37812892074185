import React, { useState,useEffect,useRef } from "react";
import { connect } from "react-redux";
import { FechaLocalToUTC, getSucursal, getTotal, tipoCelda } from "../../../services/functions.js";
import Modalinfo from "../../modal/info";
import LoadingModal from "../../modal/LoadingModal";
import { useCustomQuery } from "../../../Hooks/useApis";
import TableAjuste from "./TableAjuste.jsx";
import LabelFecha from "../../LabelFecha.jsx";
import Modalgeneric from "../../modal/generic.js";
import TableModal from "../../modal/TableModal.jsx";
import Labelmoney from "../../labelmoney.js";
import TableReactWindows from "../Cash/TableReactWindows.jsx";

const ReporteAjuste = ({PropsReportes,setHeader}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalItems, setModalItems] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [itemSelect,setItemSelect]=useState([]),
  [servicios,setServicios]=useState([]);
  const nameComponent = "inventarioAjuste",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const title = useRef("Sin Datos");

  const funcionExito = (datos) => {
    closemodalLoading();
    setServicios(datos.datos)
  };

  const funcionError = (error) => {
    setmodalinfoConfig({ isOpen: true, message: error?.response?.data?.message,});
  };

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }
  function closeModalItems(e) {
    setModalItems({ isOpen: false });
  }

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true, title });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };

  useEffect(()=>{
    openmodalLoading();
    let peticion ={
      hashKey:{valor:("AJUSTE#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[FechaLocalToUTC(PropsReportes.fecha_Inicial),FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59)],operador:"between",nombre:"type"}
    }
    useCustomQuery(peticion,funcionExito,funcionError);
  },[PropsReportes.fecha_Inicial,PropsReportes.fecha_Final])

  useEffect(()=>{
    if(rutaActualRep!==nameComponent){return;}
     setHeader({text:"Reporte de Ajustes de Inventario"})
  },[PropsReportes.rutas]);

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Servicios</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
    
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={title.current}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
      <Modalgeneric w="size-w-70" h="size-h-80" config={modalItems} closeModal={closeModalItems}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Articulos Ajustados</Modalgeneric.Title>
          <div className="h-90/100 relative">
            <TableReactWindows
              columns={[
                { header: "Clave", width:150, key: "id", type:tipoCelda.text,filter:true,title:"Id del producto" },
                { header: "Descripción", width:450,  key: "descripcion", type: tipoCelda.text },
                { header: "linea", width:150,  key: "linea", type: tipoCelda.text,filter:true  },
                { header: "Exist Ant", width:80, key:"existenciaOld", type: tipoCelda.number,filter:true, title:"Existencia anterior al ajuste" },
                { header: "Responsable", width:150,  key: "responsable", type: tipoCelda.text,filter:true },
                { header: "Cant", width:90, key: "cantidad", type: tipoCelda.number ,filter:true,footer:true,function: getTotal, title:"Cantidad Ajustada"},
                { header: "Costo U.", width:120, key:"unitCost", type:tipoCelda.money,filter:true, title:"Costo del producto" },
                { header: "Importe", width:120, key: "importe", type: tipoCelda.money,filter:true,footer:true,function: getTotal },
              ]}
              data={itemSelect}
            />
          </div>
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModalItems}
            name="modalgeneric"
            tabIndex="100"
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <div className="flex w-full justify-center items-center">
        <div className="w-full lg:w-3/4 h-95/100 overflow-y-auto">
          <TableAjuste
            searchinput={false}
            columns={[
              {
                header: "Fecha",
                width:"w-20",
                // eslint-disable-next-line react/display-name
                code: (item) =><div className="flex flex-row justify-between">
                  <LabelFecha className="font-bold " hour={false}>{item?.type||""}</LabelFecha>
                  <button 
                  className="ml-1"
                  onClick={e=>{
                    let newItems = [];
                    item.items.forEach(item=>{
                      newItems.push({...item,unitCost:item?.importe/item?.cantidad})
                    })
                    setItemSelect(newItems)
                    setModalItems({ isOpen: true, message: "",});
                  }}>
                    <i className=" text-sky-600 hover:text-sky-400 transform hover:scale-125 fas fa-list"></i>
                  </button>
                </div>
              },
              {
                header: "Description",
                width:"w-5/12 lg:w-6/12 text-start",
                accessor: "descripcion"
              },
              {
                header: "Personal",
                width:"w-24",
                accessor: "personal"
              },
              {
                header: "IN",
                width:"w-20",
                // eslint-disable-next-line react/display-name
                code: (item) =><div className={(item?.importeTotal>0?"w-full":"hidden")}>
                <Labelmoney twoDecimal={true} className={"text-right"}>{item?.importeTotal}</Labelmoney>
              </div>
              },
              {
                header: "OUT",
                width:"w-20",
                // eslint-disable-next-line react/display-name
                code: (item) =>
                <div className={(item?.importeTotal<0?"w-full":"hidden")}>
                  <Labelmoney twoDecimal={true} className={"text-right"}>{item?.importeTotal}</Labelmoney>
                </div>
              },
            ]}
            footer={[
              {},{},{},
              { width:"w-20 bg-ambar-100",
              // eslint-disable-next-line react/display-name
                code: 
                <Labelmoney twoDecimal={true} className={"text-right"}>{servicios.reduce((a, b) => parseInt(a) + (b.importeTotal>0?parseInt(b.importeTotal):0), 0)}</Labelmoney>
              },
              { width:"w-20 bg-ambar-100",
              // eslint-disable-next-line react/display-name
                code: 
                <Labelmoney twoDecimal={true} className={"text-right"}>{servicios.reduce((a, b) => parseInt(a) + (b.importeTotal<0?parseInt(b.importeTotal):0), 0)}</Labelmoney>
              }
            ]}
            data={servicios}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setPropsInventario: (estado) => dispatch({type:"SET_PROPSINVENTARIO", estado:estado}),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
});

const mapStateToProps = (state) => ({
  HeaderCompra: state.HeaderCompra,
  propsInventario: state.PropsInventario,
  PropsReportes: state.PropsReportes,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteAjuste);
