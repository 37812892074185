import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InputMedidas from '../Productos/InputMedidas'
import TableModal from "../modal/TableModal";
import Selectormap from './Selectormap';

const Tablaarmazon = ({producto,FormasArmazon,addModelo})=>{
  const [modelos,setModelos]=useState([]);
  
  useEffect(()=>{
    if(!producto.modelo){setModelos([]);return;}
    setModelos(producto.modelo);
  },[producto?.modelo])

  const addMedidas=(id,nombre,medida)=>{
    let newModelos = [];

    [...producto.modelo].map(modelo=>{newModelos.push({...modelo})});
    newModelos.map(modelo=>{if(modelo.id==id){modelo[nombre]=medida}})

    addModelo(newModelos)
  }

  return(<div className='w-full md:px-4'>
          <TableModal
          searchinput={false}
          columns={[
            {
              header: "Modelo",
              width:"w-24",
              // eslint-disable-next-line react/display-name
              code: (item) => <input readOnly className='bg-transparent w-full text-center font-semibold' value={(producto?.prefijo?producto.prefijo:"")+ (item?.id?item.id.toString():"")} />
            },
            {
              header: "Diagonal",
              width:"w-16",
              // eslint-disable-next-line react/display-name
              code: (item) => <InputMedidas defaultValue={item?.largo} getmedida={valor=>{addMedidas(item.id,"largo",parseInt(valor))}}/>
            },
            {
              header: "Puente",
              width: "w-16",
              // eslint-disable-next-line react/display-name
              code: (item) =><InputMedidas defaultValue={item?.puente} getmedida={valor=>{addMedidas(item.id,"puente",parseInt(valor))}}/>,
            },
            {
              header: "Varilla",
              width: "w-16",
              // eslint-disable-next-line react/display-name
              code: (item) => <InputMedidas defaultValue={item?.varilla} getmedida={valor=>{addMedidas(item.id,"varilla",parseInt(valor))}}/>,
            },
            {
              header: "Alto",
              width:"w-16",
              // eslint-disable-next-line react/display-name
              code: (item) => <InputMedidas defaultValue={item?.alto} getmedida={valor=>{addMedidas(item.id,"alto",parseInt(valor))}}/>
              //accessor: "vencimiento",
            },
            {
              header: "Forma",
              width: "w-48",
              // eslint-disable-next-line react/display-name
              code: (item) =>
              <Selectormap
              catalogo={FormasArmazon.SS}
              defaultValue={item?.forma}
              getvalor={(valor) => {
                addMedidas(item.id,"forma",(valor?.valor?valor.valor:""));
              }}
              />
            },
          ]}
          data={modelos}
          />
        </div>
      );
  };



  const mapDispatchToProps = dispatch => (
    {
      addModelo: element => dispatch({type: 'SET_ALTA_MODELO',modelo:element}),
    })
  
  export default connect(
    null,
    mapDispatchToProps
  )(Tablaarmazon)