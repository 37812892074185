import React, { useRef } from "react";

const Base = ({ getbase,bg,max,salto,bases}) => {
  const inputs = useRef({});
  let arregloBases = [];

  const CreaBases=()=>{
    for(let i=0;i<=max;i=i+salto){
      arregloBases.push(
      <span key={i}>
      <label className="font-semibold mr-1">{i}</label>
        <input
        className="mr-3"
        ref={node=>{inputs.current[i]=node}}
        type="checkbox"
        defaultChecked={bases.find(base=>parseInt(base)==i)?true:false}
        value={i}
        onChange={e=>{
          let newbases =[...bases];
          const newbase = parseFloat(e.target.value);
          e.target.checked
          ?newbases.find(base=>base==newbase)?"":newbases.push(newbase)
          :newbases = newbases.filter(base=>base!==newbase)
          getbase(newbases);
        }}
        />
      </span>);
    }
    return arregloBases;
  }

  return  <div className={"w-full mb-3 border-2 rounded-md px-1 "+(bg||"bg-gray-100")}>
    <div className="flex flex-wrap w-full justify-center">
      <label className="textf md:text-base mr-2">Todo</label>
      <input className="w-4 h-4 mt-1" type="checkbox" onChange={e=>{
        let newbases =[];
        
        for(let i=0;i<=max;i=i+salto){
          if(e.target.checked){
            newbases.push(parseInt(i));
            inputs.current[i].checked=true;
          }else{
            inputs.current[i].checked=false;
          }
        }
        getbase(newbases);
      }}/>
    </div>
       {CreaBases()}
    </div>
};

Base.defaultProps ={
  bases :  [],
  salto : 2,
  max: 20,
}

export default Base;
