import React,{useEffect, useRef} from "react";
import { useMemo } from 'react';

const Selectormap = ({catalogo, name,getvalor,hidden,defaultValue,disabled,clean,reset,width,table,bg,noNulo,onBlur,onBlurI,inputRef}) => {
  const select = inputRef || useRef("");
  const getval = getvalor || ((valor)=>{});

  
  function cambiaValor(valor) {
    const val = Object.entries(catalogo).find(([key,value])=>(catalogo[key]===valor));
    if(val){
      getval({clave:val[0],valor:val[1]});
   }else{
    getval({valor:valor});
  }
  }

  function getValorBlur(valor) {
    const val = Object.entries(catalogo).find(([key,value])=>(catalogo[key]===valor));
    if(val){
      return{clave:val[0],valor:val[1]};
   }else{
    return{valor:valor};
  }
  }

  useMemo(()=>{
    select.current?.value? select.current.value = "" :""
  },[reset])
  

useEffect(() => {
  if(!clean){return}
  select.current?.value? select.current.value = "" :""
}, [clean])


  return ( <select
        name={name}
        defaultValue={defaultValue}
        ref={select}
        hidden={hidden||false}
        disabled={disabled||false}
        className={(width?width+" ":"w-full ")+""+(table?((bg?bg:"")+"border-table "):"border-form ")}
        onBlur={e=>{
 /*          if(onBlur&&e.target.value){
            const val = Object.entries(catalogo).find(([key,value])=>(catalogo[key].toUpperCase()===e.target.value.toUpperCase()));
            onBlur({clave:val[0],valor:val[1]});
          } */
          onBlur?onBlur(e):(onBlurI?onBlurI(getValorBlur(e.target.value.trim())):"");
        }}
        onChange={e=>{
          cambiaValor(e.target.value.trim());
          /*if(e.target.value){
            const val = Object.entries(catalogo).find(([key,value])=>(catalogo[key].toUpperCase()===e.target.value.toUpperCase()));
            getval({clave:val[0],valor:val[1]});
          }else{getval()}*/
        }}>
            {noNulo?"":<option key={-1} defaultValue=""></option>}
            {(catalogo)?Object.entries(catalogo).sort().map(([key,value])=>(
                <option key={key} defaultValue={value} >{value}</option>
            )):[]}
        </select>
  )
};

export default Selectormap;
