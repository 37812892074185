import React, { useState } from "react";
import { createRef } from "react";
import { useGetPermisoEspecial } from "../Hooks/useApis.jsx";
import Modalinfo from "./modal/info";

const GetPermiso = ({permission = "",Successfunction = (i) => {}, children,ui = <></>,}) => {
  const [modalpermiso, setmodalpermiso] = useState({});
  const [errores, seterrores] = useState("");
  const input_usuario=createRef(null),input_contrasenia=createRef(null);

  function closeModalinfo(e) {
    setmodalpermiso({ isOpen: false });
    seterrores("");
  }

  const showModalPermiso = () => {
    setmodalpermiso({
      isOpen: true,
      title: "Obtener permiso",
      message: "Ingrese usuario y  contraseña",
    });
  };

  const handleSuccessful = (data) => {
    Successfunction(data);
    closeModalinfo("");
  };

  const handleError = (error) => {
    seterrores(error?.response?.data?.message);
  };

  return (
    <>
      <Modalinfo config={modalpermiso}>
        <Modalinfo.Body title={modalpermiso.title}>
          <div className="flex flex-col items-center my-4 break-words w-full h-full mt-1 sm:mt-4 landscape:mt-0 ">
            <h4 className="text-md mt-4 text-center font-medium text-gray-900 w-full">
              Ingrese usuario y contraseña
            </h4>
            <input
              type="text"
              className="border-form w-full my-2 uppercase"
              name="usuario"
              placeholder="Usuario"
              autoFocus={true}
              ref={input_usuario}
              style={{ transition: "all .15s ease" }}
              onChange={e=>{e.target.value = e.target.value.toUpperCase();}}
            />
            <input
              type="password"
              className="border-form w-full mb-1 uppercase"
              name="contrasenia"
              autoComplete={"new-password"}
              placeholder="Contraseña"
              autoFocus={true}
              ref={input_contrasenia}
              style={{ transition: "all .15s ease" }}
              onChange={e=>{e.target.value = e.target.value.toUpperCase();}}
            />
            {ui}
            <button
              className="btn-search text-md"
              type="submit"
              onClick={(e) => {
                if(!input_usuario.current?.value||!input_contrasenia.current?.value){return}
                useGetPermisoEspecial(input_usuario.current.value ,input_contrasenia.current.value,permission,handleSuccessful,handleError);
              }}
              style={{ transition: "all .15s ease" }}
            >
              Obtener
            </button>
            <h4 className="text-md mt-2 text-center font-medium text-red-500 w-full">{errores}</h4>
          </div>
        </Modalinfo.Body>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
      <a
        onClick={(e) => {
          showModalPermiso();
        }}
      >
        {children}
      </a>
    </>
  );
};

export default GetPermiso;
