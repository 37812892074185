import React, { useEffect, useRef, useState } from 'react'
import Modalgeneric from '../modal/generic';
import TableModal from '../modal/TableModal';
import { apiUsePromise } from '../../services/functions';
import LoadingModal from '../modal/LoadingModal';

const ModalProveedores = ({getProveedor,openModal}) => {
  const [modalTablaPersonal, setmodalTablaPersonal] = useState(false),
  [modalLoading, setmodalLoading] = useState({});
  const dataProveedor = useRef([]);

  const closeModaltablaPersonal=(e)=> {
    dataProveedor.current =[];
    setmodalTablaPersonal({ isOpen: false });
  }
  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false });};


  useEffect(()=>{
    if(openModal==undefined){return}
    const getProveedores = async()=>{
      openmodalLoading();
      const peticion={
        indexName:"reverseIndex",
        hashKey:{valor:"SUPPLIER",nombre:"type"},
      };

      try{
        const resp = await apiUsePromise({ url: "/custom-query", method: "POST",data: peticion});
        if(!resp.data.datos){
          closemodalLoading();
          return;
        }
        dataProveedor.current = resp.data.datos;
        closemodalLoading();
        setmodalTablaPersonal({ isOpen: true });

      }catch(error){
        closemodalLoading();
      }
    }
    getProveedores();
  },[openModal])
  

  return (
    <>
       <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Productos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
      <Modalgeneric config={modalTablaPersonal} closeModal={closeModaltablaPersonal}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>{"Proveedores "}</Modalgeneric.Title>
          <TableModal
            columns={[
              {
                header: "Codigo",
                accessor: "id",
                width: "w-20",
              },
              {
                header: "Nombre",
                accessor: "nombre",
                width: "w-52",
              },
              {
                header: "Direccion",
                accessor: "direccion",
                width: "w-20",
              },
              {
                header: "Email",
                accessor: "email",
                width: "w-32",
              },
              { header: "Activo", accessor: "activo", width: "w-12 text-center" },
            ]}
            onclickRow={(item,e) => {
              if(!getProveedor||e.detail!=2){return}
              getProveedor(item);
              setmodalTablaPersonal({ isOpen: false });
            }}
            data={dataProveedor.current}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltablaPersonal}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
        </Modalgeneric>
    </>
  )
}

export default ModalProveedores