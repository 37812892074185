import React, { useState,useEffect,useRef } from "react";
import { connect } from "react-redux";
import { getSucursal, moneyToValue, redondeo } from "../../../services/functions.js";
import Modalinfo from "../../modal/info";
import InputPrecioPanel from "../../InputPrecioPanel";
import TableServices from "./TableServices";
import LoadingModal from "../../modal/LoadingModal";
import { useGetProduct, useUpdateProducto } from "../../../Hooks/useApis";
import Modalsuccess from "../../modal/success.js";

const ReporteServicios = ({PropsReportes,setHeader}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [peticion,setPeticion]= useState([]),
  [respuesta, setrespuesta] = useState(null),
  [servicios,setServicios]=useState([]);
  const title = useRef("Sin Datos"),oldServ =useRef([]);
  const nameComponent = "inventarioServices",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];

  function changeprecioventa(item,e){
    const precioVenta = moneyToValue(parseFloat(e));
    const servs = [...servicios];
    servs.map(serv=>{
      if(serv.id==item.id){serv.precioVenta=precioVenta}
    })
    setServicios(servs);
  }

  function changepreciocompra(item,e){
    const precioCompra = moneyToValue(parseFloat(e));
    const servs = [...servicios];
    servs.map(serv=>{
      if(serv.id==item.id){serv.precioCompra=precioCompra}
    })
    setServicios(servs);
  }

  const funcionExitoServ = (dataproductos) => {
    const data = [...dataproductos.productos];
    closemodalLoading();
    if (data.length === 0) {
        title.current ="Producto No Encontrado";
        setmodalinfoConfig({ isOpen: true,message: ("No existe el producto "),});
    }
    if (data.length === 1){ 
        oldServ.current =[data[0]];
        setServicios([data[0]]);
    } 
    if (data.length > 1) {
      let servs = [],oldS=[];
        data.map(item=>{
          const new_item = {
            cantidad:item?.cantidad||1,
            descripcion: item.descripcion,
            precioCompra:  redondeo(item?.sucursales?.[getSucursal()]?.precioCompra)||1,
            precioCompraIni: redondeo(item?.sucursales?.[getSucursal()]?.precioCompra)||1,
            precioVenta:redondeo(item?.sucursales?.[getSucursal()]?.precioVenta)||0,
            precioVentaIni : redondeo(item?.sucursales?.[getSucursal()]?.precioVenta || 0),
            comprable : (item.sucursales?.[getSucursal()]?.comprable==false)?item?.sucursales?.[getSucursal()].comprable :false,
            vendible: (item.sucursales?.[getSucursal()]?.vendible?item.sucursales.[getSucursal()].vendible :false),
            guideline : 1,
            type : item.type,
            id: item.id,
            categoria: item.categoria,
            marca: item.marca,
            linea: item.linea,
          };
          servs.push(new_item);
          oldS.push({...new_item});
        });
        oldServ.current= oldS;
        setServicios(servs);
    }
  };

  const funcionError = (error) => {
    setmodalinfoConfig({ isOpen: true, message: error?.response?.data?.message,});
  };

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true, title });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  
  const handleSuccessfulProducto = (text) => {
    setrespuesta({ status: 200, tamaño: respuesta.tamaño+1 });
  };
  const handleErrorProducto = (text) => {
    closemodalLoading();
    setrespuesta({ status: 400, tamaño: respuesta.tamaño+1 });
    setmodaldangererrorConfig({
      isOpen: true,
      message: text?.response?.data?.message || "Error al Insertar Producto" ,
    });
  };

  
  const FinishInsert=()=>{
    setPeticion([]);
    setrespuesta(null);
  }

  useEffect(()=>{
    openmodalLoading();
    useGetProduct({producto:"SE",sinLimite:true,funcionExito:funcionExitoServ,funcionError:funcionError});
  },[])

  useEffect(() => {
    if (peticion.length === 0) return; 
    if (respuesta.tamaño < peticion.length && respuesta.status === 200) {
      useUpdateProducto(peticion[respuesta.tamaño],handleSuccessfulProducto,handleErrorProducto);
    } else {
        closemodalLoading();
        FinishInsert();
        setmodalSuccessConfig({
          isOpen: true,
          message: "Productos Agregados",
        });
      
    }
  }, [respuesta]);

  useEffect(()=>{
    if(rutaActualRep!==nameComponent){return;}
     setHeader({text:"Cambio de Precios Servicios"})
  },[PropsReportes.rutas]);

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Servicios</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalsuccess config={modalSuccessConfig}>
        <Modalsuccess.Body title={modalSuccessConfig.title}>
          Producto Guardado Con Exito
        </Modalsuccess.Body>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
    
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={title.current}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
      <div className="flex flex-row w-full">
      <TableServices
        searchinput={true}
        columns={[
          {
            header: "Linea",
            width:"w-16 text-center",
            accessor: "linea"
          },
          {
            header: "Unit Cost",
            width:"w-16",
            // eslint-disable-next-line react/display-name
            code: (item) => (item?.type=="PRODUCT"?<div className="flex flex-row">
              <InputPrecioPanel
                width="w-full"
                min={0}
                newValor={item?.precioCompraIni}
                setValor={(e)=>{  
                  changepreciocompra(item,e)
                }}
                />
              </div>
              :""
            ),
          },
          {
            header: "maker/style",
            width:"w-20 text-center",
            accessor: "id"
          },
          {
            header: "Description",
            width:"w-5/12 lg:w-6/12 text-start",
            accessor: "descripcion"
          },
          {
            header: "Unit Price",
            width:"w-24",
            // eslint-disable-next-line react/display-name
            code: (item) => (item?.type=="PRODUCT"?<div className="flex flex-row">
              <InputPrecioPanel
                width="w-full"
                min={0}
                newValor={item?.precioVentaIni}
                setValor={(e)=>{  
                  changeprecioventa(item,e)
                }}
                />
              </div>
              :""
            ),
          },
          {
            header: "MarkUp",
            width:"w-20",
            // eslint-disable-next-line react/display-name
            code: (item) => <label hidden={item?.type=="LINEA"} >{(((item?.precioVenta-item?.precioCompra)/item?.precioVenta)>0?(((item?.precioVenta-item?.precioCompra)/item?.precioVenta)*100).toFixed(2):0)+"%"}</label>,
          },
          {
            header: "Vendible",
            width:"w-20 ",
            // eslint-disable-next-line react/display-name
            code: (item) => <input type="checkbox" checked={item?.vendible} onChange={e=>{
              const servs = [...servicios];
              servs.map(serv=>{
                if(serv.id==item.id){serv.vendible=!item?.vendible}
              })
              setServicios(servs);
            }}/>,
          }
        ]}
        data={servicios}
      />
      </div>
      <button 
        className="btn-blue w-24 absolute top-0"
        onClick={e=>{
          
          let inventario= [];
          oldServ.current.forEach(item=>{
            servicios.forEach(serv=>{
              if(item.id==serv.id){
                if(item.precioVenta!==serv.precioVenta||item.vendible!==serv.vendible||item.precioCompra!==serv.precioCompra){
                  const newitem= {...serv};
                  delete newitem.precioCompraIni;
                  delete newitem.precioVentaIni;
                  delete newitem.type;
                  inventario.push(newitem)
                }
              }
            })
          })
          /*servicios.forEach(serv=>{
                const newitem= {...serv};
                delete newitem.precioCompraIni;
                delete newitem.precioVentaIni;
                delete newitem.type;
                inventario.push(newitem)
          })*/
          if(inventario.length==0){return;}
          openmodalLoading();
          let new_peticion = [],x = 0;
          while (x < inventario.length) {
            new_peticion.push(inventario.slice(x, x + 10));
            x = x + 10;
          }
          inventario = new_peticion;
          //console.log(inventario)
          setPeticion(inventario);setrespuesta({ status: 200, tamaño: 0 });

        }}
        >Guardar
        </button>

    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setPropsInventario: (estado) => dispatch({type:"SET_PROPSINVENTARIO", estado:estado}),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
});

const mapStateToProps = (state) => ({
  HeaderCompra: state.HeaderCompra,
  propsInventario: state.PropsInventario,
  PropsReportes: state.PropsReportes,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteServicios);
