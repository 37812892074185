import React from "react";
import PropTypes from "prop-types";

const InputMedidas = ({ getmedida,defaultValue }) => {
  const [color, setcolor] = React.useState("white");
  return <input
          className={"border-form w-full text-center "+(color)}
          type="number"
          step="1"
          min="0"
          max="999"
          defaultValue={defaultValue}
          onChange={(e) => {
            if (e.target.value == "" || !e.target.validity.valid) {setcolor("bg-red-200");return; } 
            setcolor("bg-white");
          }}
          onBlur={e=>{
            if (e.target.value !== "" && e.target.validity.valid) {getmedida(e.target.value); return;}
            e.target.value = "";
            setcolor("bg-red-200");
            getmedida();
          }}
        />
};

InputMedidas.propTypes = {
  getmedida: PropTypes.func.isRequired,
};
export default InputMedidas;
