import React, { useMemo, useState } from "react";
import Modalgeneric from "./modal/generic";

const Bancos = (props) => {
  const saveChange = props.savechange || [],
  [data, setdata] = useState([]),
  [modalgenericonfig, setmodalgenericonfig] = useState({}),
  [registro, setregistro] = useState({}),
  [editRegistro,setEditRegistro] = useState({});

  const openmodalgeneric = (e) => {
    setmodalgenericonfig({ isOpen: true });
  };
  const closemodalgeneric = (e) => {
    setmodalgenericonfig({ isOpen: false });
    setregistro({});
  };

  const closesavemodalgeneric = () => {
    let newArray = [...data], newRegistro ={};
    (Object.keys(editRegistro).length!==0)?newRegistro={...editRegistro}:newRegistro = {...registro};

    const index = newArray.findIndex((cuenta) => JSON.stringify(cuenta)===JSON.stringify(newRegistro));
    index > -1? (newArray[index] = registro): newArray.push(newRegistro);

    saveChange(newArray);
    setdata(newArray);
    setmodalgenericonfig({ isOpen: false });
    setregistro({});
    setEditRegistro({});
  };

  const editItem = (cuenta) => {
    setEditRegistro(cuenta);
    setregistro(cuenta);
    openmodalgeneric(0);
  };

  const createItem = (e) => {
    openmodalgeneric(e);
  };

  useMemo(() => {
    saveChange(props.data || []);
    setdata(props.data || []);
  }, [props.data]);

  return (
    <>
      <Modalgeneric w="size-w-40" h="size-h-40" config={modalgenericonfig}>
        <Modalgeneric.Body>
          <div className="flex flex-col items-center mt-2 w-full">
            <label className="text-lg font-semibold">Banco</label>
            <select
            className="border-form w-full lg:w-1/2"
            value={Object.keys(registro)[0]||""}
            onChange={e=>{
              let newReg = {};

              newReg[e.target.value]= Object.values(registro)[0];
              setregistro(newReg);
            }}>
            {<option key={-1} defaultValue=""></option>}
            {Object.values(props.banks).sort().map((item,index)=>(
                <option key={index} defaultValue={item}>{item}</option>
            ))}
            </select>
            <label className="text-lg font-semibold">Nombre de Cuenta</label>
            <input
            type="text"
            className="border-form uppercase w-full lg:w-1/2"
            onChange={(e) => {
              let newReg = {};

              newReg[Object.keys(registro)[0]]=  e.target.value.toUpperCase();
              setregistro(newReg);
            }}
            value={Object.values(registro)[0]||""}
            ></input>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            className="btn-blue"
            onClick={(e) => {
              closesavemodalgeneric();
            }}
          >
            Guardar
          </button>
          <button
            type="button"
            className="btn-gray2"
            onClick={closemodalgeneric}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <div className="w-full flex flex-col items-center  mx-auto ">
        <div className="w-full px-0 ">
          <div className="flex flex-col items-center relative border border-blue-900 ">
            <div className="w-full ">
              <div
                className="my-2 p-1  bg-white rounded focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3  transition-colors duration-200 ease-in-out mb-2 max-h-select overflow-y-auto"
                style={{ height: "20vh" }}
              >
                {data.map((item, index) => (
                  <div key={index} className="flex flex-auto ">
                    <div
                      className="cursor-pointer flex justify-center items-center my-1 rounded font-medium text-white bg-blue-900 border border-gray-300 hover:bg-blue-600 "
                      onClick={(e) => {
                        editItem(item);
                      }}
                    >
                      <div className=" leading-none max-w-full flex-initia py-1 px-2 ">
                        {Object.keys(item)[0]}
                      </div>
                      <label className="font-medium text-gray-700 bg-white borde py-1 px-2">
                      {Object.values(item)[0]}
                    </label>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col relative justify-center items-center">
                <div className="cursor-pointer flex justify-center items-center m-1 w-1/3 py-2 px-2  rounded font-bold text-white bg-green-600 border border-gray-700 ">
                  <button
                    disabled={props.disabled}
                    type="button"
                    onClick={createItem}
                    className="text-xs font-bold uppercase leading-none max-w-full flex-initial disabled:opacity-50"
                  >
                    Agregar Banco
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bancos;
