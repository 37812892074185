import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { FechaLocalToUTC, FechaUTCToLocal, IdToShortId, OrderArrayAZ, TransformIndex, getArrayAsync, getSucursal, getTotal, tipoCelda } from '../../../services/functions';
import TableReactWindows from '../Cash/TableReactWindows';
import Modalgeneric from '../../modal/generic';
import Modaldanger from '../../modal/danger';
import { useGetCompraAsync, useGetVentaAsync } from '../../../Hooks/useApis';

const InOut=({PropsReportes,openmodalLoading,closemodalLoading,delRutaModal})=> {
  const [balanceInOut,setBalanceInOut] = useState([]),
  [ticket,setTicket]=useState([]),
  [modalTicket,setModalTicket]=useState({}),
  [modalCompra,setModalCompra]=useState({}),
  [modalError,setModalError]=useState({}),
  [modal,setModal]=useState({isOpen:true});
  const filtroFecha = [FechaLocalToUTC("01-01-2023"),FechaLocalToUTC(PropsReportes.fecha_Final,"23","59","59")];
  const linea = PropsReportes?.header?.inout ||"";

  const closeModalTicket= ()=>{setModalTicket({isOpen:false})};
  const openModalTicket= ()=>{setModalTicket({isOpen:true})};

  const openModalCompra=()=>{setModalCompra({isOpen:true});}
  const closeModalCompra=()=>{setModalCompra({isOpen:false});}

  const handleError = (error) => { 
    closemodalLoading();
    setModalError({isOpen:true,title:"Error al obtener Datos",message:error.message||error})
  }
  const closeModalError=()=>{setModalError({isOpen:false}); }

  useEffect(async()=>{
    if(!PropsReportes.header?.inout){console.log('la linea esta vacia'); return}
  
      openmodalLoading("Cargando Historial de compras y ventas");
      const newCampo = {in:0,out:0,fecha:"",folio:"",personal:"",pc:0,pv:0,importeOut:0,importeIn:0,descripcion:"",tipo:""};
      let balance =[];
      const peticiones =[{
        indexName:"sparseIndex",
        hashKey:{valor:"COMPRA#"+getSucursal()+"#LINEA#"+linea,nombre:"statusTemp"},
        sortKey:{valor:filtroFecha,operador:"between",nombre:"id"},
      },{
        indexName:"sparseIndex",
        hashKey:{valor:"VENTA#"+getSucursal()+"#LINEA#"+linea,operador:"eq",nombre:"statusTemp"},
        sortKey:{valor:filtroFecha,operador:"between",nombre:"id"},
      }];
      const compras = await getArrayAsync(peticiones[0],[]);
      const ventas = await getArrayAsync(peticiones[1],[]);

      compras.forEach(compra=>{
        balance.push({...newCampo,in:compra.cantidadComprada,fecha:compra.id,folio: IdToShortId(compra.id),pc:compra.precioCompra,importeIn:(compra.cantidadComprada*compra.precioCompra),tipo:"COMPRA"})
      })

      ventas.forEach(venta=>{
        const codigo = venta.type.split('#')[2];
        balance.push({...newCampo,out:venta.cantidadVendida,fecha:venta.id,folio:IdToShortId(venta.id),descripcion:codigo,pc:venta.precioCompra,pv:venta.precioVenta,importeOut:(venta.cantidadVendida*venta.precioCompra),tipo:"VENTA"})
      })

      balance =OrderArrayAZ(balance,"fecha","string");

      setBalanceInOut(balance);
      
      closemodalLoading();
      
  },[])

  return (
    <>
      <Modaldanger config={modalError}>
        <Modaldanger.Message title={modalError.title||"Error al obtener datos"}>
          {modalError.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalError}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      {!modalCompra?.isOpen?null:
      <Modalgeneric w="size-w-50" h="size-h-80" bg="bg-bluei " tab="400" front="z-30" config={modalCompra}>
        <Modalgeneric.Body>
        <Modalgeneric.Title>
            {ticket?.compra?
              <div className="w-full flex flex-row justify-center gap-2 text-white">
                <p>{"Folio: "+ticket?.compra?.shortId}</p>
                <p>{"Proveedor: "+TransformIndex(ticket?.compra?.proveedor)||""}</p>
                <p>{"Fecha: "+FechaUTCToLocal(ticket?.compra?.id).fecha}</p>
                <p>{"Personal: "+ticket?.compra?.nombrePersonal}</p>
              </div>
            :""}
          
        </Modalgeneric.Title>
        <div className="h-95/100 flex relative">
        <TableReactWindows
          btnDownload={async(e,datos)=>{          }}
            columns={[
              {
                key:"cantidadComprada",
                header:"Cant",
                width:90, 
                type: tipoCelda.number,
                filter:true,
                footer:true,
                function: getTotal,
              },
              {
                key:"descripcion",
                header: "Desc",
                width:550,
                type: tipoCelda.text,
                filter:true,
              },
              {
                key:"precioCompra",
                header: "UnitCosts",
                width:100,
                type: tipoCelda.money,
                filter:true
              },
              {
                key:"importe",
                header: "Total",
                width:120,
                type: tipoCelda.money,
                filter:true,
                footer:true,
                function: getTotal,
              },
            ]}
          
            data={ticket.items||[]}
        />
        </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModalCompra}
            tabIndex="400"
            name="modalgeneric"
            className="btn-gray1"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      }

      {!modalTicket?.isOpen?null:
      <Modalgeneric w="size-w-50" h="size-h-80" front="z-30" tab="400" config={modalTicket} closeModal={closeModalTicket}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>
              {ticket?.venta?
                <div className="w-full flex flex-row justify-center gap-2">
                  <p>{"Ticket: "+ticket?.venta?.shortId}</p>
                  <p>{"Cliente: "+TransformIndex(ticket?.venta?.cliente)||""}</p>
                  <p>{"Fecha: "+FechaUTCToLocal(ticket?.venta?.id).fecha}</p>
                  <p>{"Vendedor: "+ticket?.venta?.nombreVendedor}</p>
                </div>
              :""}
          </Modalgeneric.Title>
          <div className="h-95/100 flex relative">
            <TableReactWindows
              btnDownload={async(e,datos)=>{          }}
              columns={[
                {
                  key:"cantidadVendida",
                  header:"Cant",
                  width:90, 
                  type: tipoCelda.number,
                  filter:true,
                  footer:true,
                  function: getTotal,
                },
                {
                  key:"descripcion",
                  header: "Desc",
                  width:550,
                  type: tipoCelda.text,
                  filter:true,
                },
                {
                  key:"precioVenta",
                  header: "Price U",
                  width:100,
                  type: tipoCelda.money,
                  filter:true,
                  
                },
                {
                  key:"importe",
                  header: "Total",
                  width:120,
                  type: tipoCelda.money,
                  filter:true,
                  footer:true,
                  function: getTotal,
                },
              ]}
              data={ticket.items||[]}
            />
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button 
          tabIndex="400"
          name='modalgeneric'
          className='btn-gray2'
          onClick={closeModalTicket}>Cerrar</button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      }

      
      <Modalgeneric w="size-w-60" h="size-h-80" front="z-20" config={modal} >
        <Modalgeneric.Body>
          <Modalgeneric.Title>{PropsReportes.header?.inout}</Modalgeneric.Title>
          <div className="h-93/100 flex relative">
          <TableReactWindows
            btnDownload={async(e,datos)=>{          }}
              columns={[
                {
                  key:"fecha",
                  header:"Date",
                  width:90, 
                  type: tipoCelda.date,
                  filter:true,
                },
                {
                  key:"in",
                  header: "Rcved",
                  width:90,
                  type: tipoCelda.number,
                  className:"text-blue-800",
                  filter:true,
                  footer:true,
                  function: getTotal,
                },
                {
                  key:"out",
                  header: "Sold",
                  width:90,
                  type: tipoCelda.number,
                  className:"text-pink-800",
                  filter:true,
                  footer:true,
                  function: getTotal,
                },
                {
                  key:"pc",
                  header: "UnitCost",
                  width:100,
                  type: tipoCelda.money,
                  filter:true
                },
                {
                  key:"pv",
                  header: "Sale Price",
                  width:100,
                  type: tipoCelda.money,
                  filter:true,
                },
                {
                  key:"folio",
                  header: "Rcv/Inv",
                  width:110,
                  type: tipoCelda.text,
                  // eslint-disable-next-line react/display-name
                  content:({row,index})=>{
                    return  <div className={'flex flex-row w-full justify-between'} >
                      <label className={row.tipo=="VENTA"?"text-pink-800":(row.tipo=="COMPRA"?"text-blue-800":"")}>{row.folio}</label>
                      <button className='text-blue-800 hover:text-black' onClick={async(e)=>{
                        if(e.detail!==1){return;}
                        if(row.tipo=="VENTA"){
                          try{
                            openmodalLoading("Cargando Venta");
                            const resp = await useGetVentaAsync(row.folio);
                            const data = resp.data;
                            let items = []

                            data.items.forEach(item=>{
                              if(item?.servicios?.length){
                                item.servicios.forEach(serv=>{
                                  items.push(serv)
                                })
                              }else{
                                items.push(item)
                              }
                            })
                            data.items = items;
                            setTicket(data)
                            closemodalLoading();
                            openModalTicket();
                          }catch (error){
                            closemodalLoading();
                            handleError(error);
                          }
                        }else if(row.tipo=="COMPRA"){
                          try{
                            openmodalLoading("Obteniendo Compra");
                            const resp = await useGetCompraAsync({folio:row.folio});
                            const data = resp.data;
                            setTicket(data);
                            closemodalLoading();
                            openModalCompra();
                          }catch (error){
                            closemodalLoading();
                            handleError(error)
                          }
                        }
                      }}>
                        <i className='fas fa-eye'/>
                      </button>
                    </div> 
                  },
                  filter:true
                },
                {
                  key:"descripcion",
                  header: "Desc",
                  width:200,
                  type: tipoCelda.text,
                  filter:true
                },
                {
                  key:"importeIn",
                  header:"In",
                  width:100,
                  type: tipoCelda.money,
                  footer:true,
                  function: getTotal,
                },
                {
                  key:"importeOut",
                  header:"Out",
                  width:100,
                  type: tipoCelda.money,
                  footer:true,
                  function: getTotal,
                }
              ]}
            
              data={balanceInOut}
          />
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
            <button className='btn-gray2 ' onClick={e=>{
              delRutaModal();
            }}>Cerrar</button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setRutaPrev:(rutas)=>dispatch({type:"SET_PREV_RUTA",rutas:rutas}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  setPropsInventarioNew: (estado) => dispatch({type:"SET_NEW_PROPSINVENTARIO", estado:estado}),
  setCleanInventario: () => dispatch({type: 'RESET_INVENTARIO'}),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  delRutaModal: () => dispatch({type:"DEL_RUTAMODAL_REP"}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
  propsInventario: state.PropsInventario,
});

export default connect(mapStateToProps, mapDispatchToProps)(InOut)