import React, { useState, useRef, useEffect} from "react";
import { FechaLocalToUTC, FechaUTCToLocal, OrderArrayAZ, apiUsePromise, getSucursal, getTotal, tipoCelda, valueToMoney2 } from "../../../services/functions";
import { connect } from "react-redux";
import LoadingModal from "../../modal/LoadingModal";
import Modaldanger from "../../modal/danger";
import TableReactWindows from "../Cash/TableReactWindows";
import Modalsuccess from "../../modal/success";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";
import Modalgeneric from "../../modal/generic";

const ReporteComprasProveedor = ({config,setModal,setOpenModalCalendar,stateFechas}) => {
  const [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [select,setSelect]=useState({}),
  [arrayItems,setArrayItems]=useState([]),
  [fechas,setFechas]=useState(),
  [reporte,setReporte]=useState("marcas");
  const ventasBrand=useRef([]),btnGeneric = "btn-light-free border border-gray-500 bg-gray-200 hover:bg-gray-400 h-6 md:h-8";
  const fechaI = FechaLocalToUTC(stateFechas.fecha_Inicial),fechaF=FechaLocalToUTC(stateFechas.fecha_Final,23,59,59);

  const handleError = (error) => {
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  }


  const closeModal=()=> {
    setFechas({...fechas,fecha_Inicial:stateFechas.fecha_Inicial,fecha_Final:stateFechas.fecha_Final});
    setModal({ isOpen: false });
  }
  const openModal=()=>{
    setModal({ isOpen: true });
  }
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }


  function closeModaldangererror(e) {
      const newdataconfig = { ...modaldangererrorConfig };
      newdataconfig.isOpen = false;
      setmodaldangererrorConfig(newdataconfig);
  }
  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };


  const getCompras=async(peticion,datos,petLast)=>{
    if(petLast){ peticion.LastEvaluatedKey = petLast;}

    const responseReporte = await apiUsePromise({ url: "/custom-query", method: "POST",data: peticion})
    datos = datos.concat(responseReporte.data.datos);

    if(responseReporte.data?.LastEvaluatedKey){
      datos = await getCompras(peticion,datos,responseReporte.data.LastEvaluatedKey);
    }
    return datos
  }

  const getComprasAsync =async(e) => {
    let repProv =[];
    const repMarcas={},
    peticion = {
      indexName:"reverseIndex",
      hashKey:{valor:"COMPRA#"+getSucursal(),nombre:"type"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"id"}
    }

    ventasBrand.current = [];
    try{
      let array=[],datos=[];

      openmodalLoading();
      datos = await getCompras(peticion,datos);
      datos.forEach(compra=>{
        const proveedor = compra.proveedor,newCompra={...compra};
        if(!repMarcas?.[proveedor]){
          repMarcas[proveedor] ={
            proveedor:proveedor,
            nombre:compra.nombreProveedor,
            cantidad:compra.cantidadTotal,
            importe:compra.importe
          };
        }else{
          repMarcas[proveedor].cantidad += compra.cantidadTotal;
          repMarcas[proveedor].importe += compra.importe;
        }

        delete newCompra.pagado;
        delete newCompra.pagos;
        delete newCompra.statusTemp;
        delete newCompra.sujetoAsociado;
        delete newCompra.type;
        delete newCompra.timezone;

        array.push(newCompra);
      })

      Object.values(repMarcas).sort((fa,fb)=>fa.proveedor.toString().localeCompare(fb.proveedor.toString())).forEach(rep=>{
        repProv.push(rep)
      })

      setArrayItems(array);
      ventasBrand.current =repProv;
      closemodalLoading();

    }catch(error){
      closemodalLoading();
      handleError(error)
    }
  }

  const getTable=()=>{
    if(reporte=="marcas"){
      return <TableReactWindows
      btnDownload={async(e,data)=>{
        if(!data?.length){handleError("No hay datos que exportar"); return;}
        openmodalLoading();
        const workb = await GenerateXlsx();
        if(workb?.error){
          closemodalLoading();
          handleError(workb.error);
          return
        }

        const nameFile="ComprasProveedores",
        headerFile="Reporte compras por proveedor del "+stateFechas.fecha_Inicial+" al "+stateFechas.fecha_Final,
        sheet1 = workb.sheet(0),sheet2=workb.addSheet("All"),colPlus = 4;

        sheet1.cell("A1").value(headerFile);
        sheet1.cell("A1").style("bold",true);
        sheet1.cell("A1").style("fontSize",18);
    
        const sizeData = data.length + colPlus,
        header={'Proveedor':13,'Cant':10,'Total':17,'Nombre':20};
    
        Object.keys(header).map((key,index)=>{
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet1.column(columnasXlsx[index]).width(header[key]); 
        })

        data.forEach((venta,index) => {
          sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.proveedor||"");
          sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.cantidad||0);
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(venta?.importe||0));
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(venta?.nombre||"");
        });
    
        sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.contabilidad);


        sheet2.cell("A1").value("Reporte de compras del "+stateFechas.fecha_Inicial+" al "+stateFechas.fecha_Final);
        sheet2.cell("A1").style("bold",true);
        sheet2.cell("A1").style("fontSize",18);

        const header2={'Compra':12,'Fecha':12,'Cant':10,'SubTotal':17,'Descuento':17,'Envio':17,'Total':17,'Personal':15,'Referencia':30,'FechaRef':12};
        let cont=0;

        Object.keys(header2).map((key,index)=>{
          sheet2.cell(columnasXlsx[index]+(colPlus-1)).value(key);
          sheet2.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet2.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet2.column(columnasXlsx[index]).width(header2[key]); 
        })

        arrayItems.forEach((venta,index) => {
          sheet2.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.shortId||"");
          sheet2.cell(columnasXlsx[1]+(colPlus+index)).value(FechaUTCToLocal(venta?.id).fecha||"");

          sheet2.cell(columnasXlsx[2]+(colPlus+index)).value(venta?.cantidadTotal||0);
          sheet2.cell(columnasXlsx[3]+(colPlus+index)).value(valueToMoney2(venta?.subtotal||0));
          sheet2.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet2.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(venta?.descuento||0));
          sheet2.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet2.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(venta?.envio||0));
          sheet2.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet2.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(venta?.importe||0));
          sheet2.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);

          sheet2.cell(columnasXlsx[7]+(colPlus+index)).value(venta?.nombrePersonal||"");
          sheet2.cell(columnasXlsx[8]+(colPlus+index)).value(venta?.referencia||"");
          sheet2.cell(columnasXlsx[9]+(colPlus+index)).value(venta?.fechaReferencia||"");
          cont++;
        });

        sheet2.cell(columnasXlsx[2]+""+(cont+colPlus)).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(cont+colPlus-1)+")");
        sheet2.cell(columnasXlsx[3]+""+(cont+colPlus)).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(cont+colPlus-1)+")");
        sheet2.cell(columnasXlsx[3]+""+(cont+colPlus)).style(formatoCelda.contabilidad);
        sheet2.cell(columnasXlsx[4]+""+(cont+colPlus)).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(cont+colPlus-1)+")");
        sheet2.cell(columnasXlsx[4]+""+(cont+colPlus)).style(formatoCelda.contabilidad);
        sheet2.cell(columnasXlsx[5]+""+(cont+colPlus)).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(cont+colPlus-1)+")");
        sheet2.cell(columnasXlsx[5]+""+(cont+colPlus)).style(formatoCelda.contabilidad);
        sheet2.cell(columnasXlsx[6]+""+(cont+colPlus)).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(cont+colPlus-1)+")");
        sheet2.cell(columnasXlsx[6]+""+(cont+colPlus)).style(formatoCelda.contabilidad);
        
        
        const response =await SaveXlsx(workb,nameFile);
        if(response?.error){
          closemodalLoading();
          handleError(response.error);
          return
        }
        closemodalLoading();
        openModalSuccess("Archivo generado correctamente","Descarga Completa");
      }}
      columns={[
        {
          key:"proveedor",
          header:"Proveedor",
          width:150, 
          type: tipoCelda.text,
          className: "text-blue-800",
          filter:true,
          onClick:({e,row})=>{
            let items = [];
            if(e.detail==2){
              items = OrderArrayAZ(arrayItems.filter(compra=>(row.proveedor==compra.proveedor)),'id','string');
              setSelect({proveedor:row.proveedor,items:items});
              setReporte("items");
            }
          }
        },
        {
          key:"cantidad",
          header:"Cant",
          width:70, 
          type: tipoCelda.number,
          filter:true,
          footer:true,
          function: getTotal,
        },
        {
          key:"importe",
          header:"Total",
          width:120, 
          type: tipoCelda.money,
          filter:true,
          footer:true,
          function: getTotal,
        },
        {
          key:"nombre",
          header:"Nombre",
          width:220, 
          type: tipoCelda.text,
          filter:true,
        },
      ]}
      data={ventasBrand.current}
      />
    }
    if(reporte=="items"){
      return <TableReactWindows
      btnDownload={async(e,data)=>{
        if(!data?.length){handleError("No hay datos que exportar"); return;}
        openmodalLoading();
        const workb = await GenerateXlsx();
        if(workb?.error){
          closemodalLoading();
          handleError(workb.error);
          return
        }

        const nameFile="Compras-"+select.proveedor,
        headerFile="Reporte compras "+select.proveedor+" del "+stateFechas.fecha_Inicial+" al "+stateFechas.fecha_Final,
        sheet1 = workb.sheet(0),colPlus = 4;

        sheet1.cell("A1").value(headerFile);
        sheet1.cell("A1").style("bold",true);
        sheet1.cell("A1").style("fontSize",18);
    
        const sizeData = data.length + colPlus,
        header={'Compra':12,'Fecha':12,'Cant':10,'SubTotal':17,'Descuento':17,'Envio':17,'Total':17,'Personal':15,'Referencia':40,'FechaRef':12};
    
        Object.keys(header).map((key,index)=>{
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet1.column(columnasXlsx[index]).width(header[key]); 
        })

        data.forEach((venta,index) => {
          sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.shortId||"");
          sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(FechaUTCToLocal(venta?.id).fecha||"");

          sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(venta?.cantidadTotal||0);
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.number);
          sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(valueToMoney2(venta?.subtotal||0));
          sheet1.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(venta?.descuento||0));
          sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(venta?.envio||0));
          sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(venta?.importe||0));
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);

          sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(venta?.nombrePersonal||"");
          sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(venta?.referencia||"");
          sheet1.cell(columnasXlsx[9]+(colPlus+index)).value(venta?.fechaReferencia||"");
        });
    
        sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.number);
        sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[3]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[4]+sizeData).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[4]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[6]+sizeData).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);
        
        const response =await SaveXlsx(workb,nameFile);
        if(response?.error){
          closemodalLoading();
          handleError(response.error);
          return
        }
        closemodalLoading();
        openModalSuccess("Archivo generado correctamente","Descarga Completa");
        
      }}
      columns={[
        {
          key:"shortId",
          header:"Rcvng",
          width:80, 
          type: tipoCelda.text,
          filter:true,
          className:"text-blue-800"
        },
        {
          key:"id",
          header:"Date",
          width:85, 
          type: tipoCelda.date,
          filter:true,
        },
        {
          key:"cantidadTotal",
          header:"Qty",
          width:70, 
          type: tipoCelda.number,
          filter:true,
          footer:true,
          function: getTotal,
          className:"text-blue-800"
        },
        {
          key:"subtotal",
          header:"SubTotal",
          width:100, 
          type: tipoCelda.money,
          filter:true,
          footer:true,
          function: getTotal,
        },
        {
          key:"descuento",
          header:"Discount",
          width:100,
          type:tipoCelda.money,
          filter:true,
          footer:true,
          function: getTotal,
          className:"text-blue-800"
        },
        {
          key:"envio",
          header:"Freigth",
          width:100,
          type: tipoCelda.money,
          filter:true,
          footer:true,
          function: getTotal,
          className:"text-pink-800"
        },
        {
          key:"importe",
          header:"Total",
          width:120, 
          type: tipoCelda.money,
          filter:true,
          footer:true,
          function: getTotal,
        },
        {
          key:"nombrePersonal",
          header:"Rcv By",
          width:100, 
          type: tipoCelda.text,
          filter:true,
        },
        {
          key:"referencia",
          header:"Ref",
          width:450,
          type:tipoCelda.text,
          filter: true,
        },
        {
          key:"fechaReferencia",
          header:"Date Ref",
          width:85,
          type:tipoCelda.date,
          noUtc:true,
          filter:true,
        }
      ]}
      data={select.items}
      />
    }
  }

  useEffect(() => {
    if(config.isOpen==false){return}
    if(stateFechas?.fecha_Inicial==fechas?.fecha_Inicial&&stateFechas?.fecha_Final==fechas?.fecha_Final){return;}
    getComprasAsync(); 
  }, [config?.isOpen,stateFechas.fecha_Inicial,stateFechas.fecha_Final]);

  return (
    <div className="w-full h-full">
     <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

     <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalgeneric w="w-full" h="size-h-90" front="z-10" config={config}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>{'Compras por Proveedor del '+stateFechas.fecha_Inicial +' al '+stateFechas.fecha_Final }</Modalgeneric.Title>
          <div className="relative w-full h-95/100">
            {getTable()}
            </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button className="btn-gray2" onClick={e=>{
            closeModal();
          }}>
            Cerrar
          </button>
          <button 
            className={btnGeneric} 
            type="button"
            onClick={e=>{
              setOpenModalCalendar(true);
            }}>
              <i className='fas fa-calendar-day text-black-700'></i>
          </button>

          {reporte=="items"?
            <button 
            className={btnGeneric} 
            type="button"
            onClick={e=>{
              setReporte("marcas");
            }}>
              <i className='fas fa-arrow-circle-left text-black-700'></i>
          </button>:null}
        </Modalgeneric.Buttons>
      </Modalgeneric>
    </div>
  );
};


const mapStateToProps = (state) => ({
  Categorias: state.Catalogos.CATEGORIAS.opciones
});

export default connect (mapStateToProps,null)(ReporteComprasProveedor);
9