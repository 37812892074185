import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modalinfo from "./modal/info";
import {useGetVenta,useGetBalanceCliente} from "../Hooks/useApis";
import {getSucursal} from "../services/functions";

const InputVenta = ({ addClient, VentaAPagar, dark,ventaex }) => {
  let input;
  
  const [modalinfoConfig, setmodalinfoConfig] = useState({});

  useEffect(()=>{
    if(!ventaex){return;}
    input.value = ventaex.shortId;
    funcionBuscar();
  },[ventaex])

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }

  const funcionExito = (data) => {
    if (data.length === 0) {
      setmodalinfoConfig({
        isOpen: true,
        message: "No existe el ticket '" + input.value.toUpperCase() + "'",
      });
    }
    useGetBalanceCliente(data.venta.cliente,handleSuccessful,handleError);
    addClient({
      id: data.venta.cliente,
      nombre: data.venta.nombreCliente,
      sucursal: data.venta.sucursal,
      type: "CLIENT",
      cotizado: true,
    });

    VentaAPagar({
      id: data.venta.id,
      sucursal: data.venta.sucursal,
      total: data.venta.total,
      pagos: data.venta.pagos,
    });

    //addItem(data.items);
  };

  const funcionError = (error) => {
    
    setmodalinfoConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  const funcionBuscar = (e) => {
    if (!input.value.trim()) {return; }
    useGetVenta(input.value.toUpperCase(),"PENDIENTE",funcionExito,funcionError);

    //fin de llamada a la API
    input.value = "";
  };
  function calcularCreditoUsado(cuentasACredito) {
    let creditoUsado = 0;
    for (let cuenta of cuentasACredito) {
      creditoUsado = creditoUsado + (cuenta.total - cuenta.pagado);
    }
    return creditoUsado;
  }
  const handleSuccessful = (data) => {
    input.value = "";
  
    if(data.cliente.sucursal===getSucursal()){
    
    if (data.message) {
      setmodalinfoConfig({
        isOpen: true,
        message: data.message,
      });
      addClient({});
    }
  
    const creditoUsado = calcularCreditoUsado(data.ventasCredito);
    const creditoRestante = data.cliente.limiteCredito - creditoUsado;
  
    let nuevoCliente = { ...data.cliente };
    nuevoCliente.creditoUsado = creditoUsado;
    nuevoCliente.creditoRestante = creditoRestante;
  
    addClient(nuevoCliente);
  
  }
  };
  
  const handleError = (error) => {
    setmodalinfoConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };
  return (
    <>
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message
          title={"No se ha encontrado la clave en la base de datos."}
        >
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <form className="size-form items-center gap-1"
      onSubmit={(e) => {
        e.preventDefault();
        funcionBuscar();
      }}>
            <input
              type="text"
              className="border-form uppercase"
              name="articulo"
              placeholder="Ticket"
              style={{ transition: "all .15s ease" }}
              ref={(node) => (input = node)}
            />

            <button
              className={dark?"btn-blue1":"btn-gray2"}
              type="submit"
              style={{ transition: "all .15s ease" }}
            >
              Buscar
            </button>
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) =>
    dispatch({
      type: "SET_ITEMSDATA",
      items: item,
    }),
  addClient: (cliente) =>
    dispatch({
      type: "ADD_CLIENTVENTA",
      item: cliente,
    }),
  VentaAPagar: (datos) =>
    dispatch({
      type: "SET_VENTAAPAGAR",
      ventaapagar: datos,
    }),
});

const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
});


export default connect(mapStateToProps, mapDispatchToProps)(InputVenta);