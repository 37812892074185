import React, { useState,useEffect} from "react";
import { connect } from "react-redux";
import {  OrderArrayAZ, getArrayAsync, getSucursal, tipoCelda, valueToMoney2 } from "../../../services/functions";
import LoadingModal from "../../modal/LoadingModal";
import TableReactWindows from "../Cash/TableReactWindows";
import Modalsuccess from "../../modal/success";
import Modalinfo from "../../modal/info";
import Modaldanger from "../../modal/danger";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";



const ReporteProductosAltas = ({PropsReportes}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modalError,setModalError]=useState({}),
  [ventas,setVentas]=useState([]);

  const nameComponent = "itemsAll",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const PropsHeader = "Productos Registrados en el sistema";


  const handleError = (error) => {
    closemodalLoading();
    setModalError({isOpen:true,title:"Error de Red",message:error.message||error});
  }
  const closeModalError=()=>{ setModalError({isOpen:false}); }


  const closeModalinfo=(e)=> {setmodalinfoConfig({ isOpen: false });}
  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }



  const getVentasAsync=async()=>{
    const peticiones=[{
      indexName:"reverseIndex",
      hashKey:{valor:"LINEA",nombre:"type"},
      //sortKey:{valor:,operador:"begins_with",nombre:"id"},
    },
    {
      indexName:"reverseIndex",
      hashKey:{valor:"PRODUCT",nombre:"type"},
      filters:[{valor:"true",nombre:"search",operador:"eq"}]
    }
  ];


    try{
      openmodalLoading();
      let items = [];

      const lineas = await getArrayAsync(peticiones[0],[]);
      const productos = await getArrayAsync(peticiones[1],[]);

      items = items.concat(lineas);
      items = items.concat(productos);

      items = items.map(item=>{
        const newItem = {...item,disponible:false}
        if(item?.sucursales?.[getSucursal()]){
          const props = newItem.sucursales.[getSucursal()];
          if(item.type=="PRODUCT"){
            newItem.pc = props?.precioCompra ||0;
            newItem.pv = props?.precioVenta ||0;
          }
          newItem.disponible = true;
        }
        delete newItem.sucursales
        return newItem
      })

      items = OrderArrayAZ(items,'id',"string")
      //console.log(lineas);
      console.log(items);

      setVentas(items);
      closemodalLoading();
    }catch (error){
      handleError(error)
    }
  }

  /*useEffect(() => {
    //if(rutaActualRep!==nameComponent){return;}
    const datos = Datos[nameComponent]||{};

    if(JSON.stringify(datos)=='{}'){
      getVentasAsync();
      return;
    }
    if(PropsReportes.header.folio==datos[1].folio){
      setVentas(datos[0]);
      return;
    }
      getVentasAsync();
  }, []);*/

  /*useEffect(() => {
    if(rutaActualRep!==nameComponent){return;}
    setHeader({...PropsReportes.header,text:"Sales Detail"});
  }, []);*/


  useEffect(()=>{
    getVentasAsync();
  },[])
  

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      
      
      <Modaldanger config={modalError} closeModal={closeModalError}>
        <Modaldanger.Message title={modalError?.title}>
          {modalError.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
          className="btn-gray2"
          tabIndex="200"
          onClick={closeModalError}>Cerrar</button>
        </Modaldanger.Buttons>
      </Modaldanger>


      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>


      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <TableReactWindows
        btnDownload={async(e,datos)=>{
          const data = datos||ventas;
          if(!data?.length){handleError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            handleError(workb.error);
            return
          }

          const nameFile="Productos"+getSucursal(),
          headerFile=PropsHeader,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={'Style':17,'Descripcion':90,'Linea':17,'Marca':17,'Categoria':17,'Tipo':17,'PCompra':15,'Pventa':15,'Bloqueado':10};
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((venta,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.id||"");
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.descripcion||"");
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).style("fontSize",10);
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(venta.linea);
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(venta.marca);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(venta.categoria);
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(venta.type)
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(venta?.pc||0));
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(valueToMoney2(venta?.pv||0));
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(venta.bloqueado||"");
          });
      
          
          const response =await SaveXlsx(workb,nameFile,true);
          if(response?.error){
            closemodalLoading();
            handleError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
          
        }}
        columns={[
          {
            key:"id",
            header:"Style",
            width:150,
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"descripcion",
            header:"Descripcion",
            width:650,
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"linea",
            header: "Linea",
            width:200,
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"marca",
            header: "Marca",
            width:150,
            className:"text-blue-800",
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"categoria",
            header:"Categoria",
            width:100,
            type:tipoCelda.text,
            className:"items-center justify-center ",
            filter:true
          },
          {
            key:"type",
            header:"Tipo",
            width:120,
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"pc",
            header:"PCompra",
            width:90,
            type:tipoCelda.money,
            filter:true,
          },
          {
            key:"pv",
            header:"PVenta",
            width:90,
            type:tipoCelda.money,
            filter:true,
          },
          {
            key:"disponible",
            header:"Activo",
            width:110,
            type:tipoCelda.text,
            className:"items-center justify-center text-center uppercase",
            filter:true,
            bgCol:{campo:'disponible',valor:true,verdadero:"bg-teal-100"}
          }
        ]}

        data={ventas}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteProductosAltas);
