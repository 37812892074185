import React, { useState, useMemo,useEffect,useRef} from "react";
import { connect } from "react-redux";
import Labelmoney from "../components/labelmoney";
import { CalculaPagos, getIdUser, getNombre, getSucursal, moneyToValue, Sucursal} from "../services/functions.js";
import Modalinfo from "../components/modal/info";
import Modaldanger from "../components/modal/danger";
import Modalsuccess from "../components/modal/success";
import { returnRuta, setRuta } from "../redux/Actions";
import Container from "../components/Container";
import InputPrecioPanel from "../components/InputPrecioPanel";
import {useInsertCompra,useGetBalanceProveedor,useUpdateCompra,useInsertCompraEspecial,useUpdateCompraEspecial} from "../Hooks/useApis";

const PagoRows = ({ config }) => {
  const formasPagoLibres = config.formasPago;

  return (
    <tr className="h-8">
      <td className="px-2 ">
        <div className="w-full size-form items-center">
          <button
          className="text-ambar-300 hover:text-red-400 text-xl py-1 mr-2"
          onClick={(e) => {
            config.deletepago(config.index);
          }}
          >
            <i className="fas fa-times-circle"></i>
          </button>
          <select
            name="sucursal"
            className="border-form w-full"
            onChange={(e) => {
              let newdata = [...config.data];
              if(e.target.value==="EFECTIVO"||e.target.value==="DEPOSITO"){
                 newdata[config.index].cuenta="CAJA";
              }
              newdata[config.index].metodo = e.target.value;
              config.setdata(newdata);
            }}
            value={config.data[config.index]?.metodo}
          >
            <option key={0} value={""}></option>
            {formasPagoLibres().map((item, index) => (
              <option key={index + 1} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </td>
      <td className="px-2">
        <select
            className="border-form w-full"
            disabled={config.data[config.index]?.metodo=="EFECTIVO"||config.data[config.index]?.metodo==="DEPOSITO"}
            onChange={(e) => {
              let newdata = [...config.data];
              newdata[config.index].cuenta = e.target.value;
              config.setdata(newdata);
            }}
          >
            <option key={0} value={""}></option>
            {config.cuentas.map((item, index) => (
              <option key={index + 1} value={Object.keys(item)[0]+'/'+Object.values(item)[0]}>
                {Object.keys(item)[0]+' '+Object.values(item)[0]}
              </option>
            ))}
          </select>
      </td>
      <td className=" px-1 ">
        <InputPrecioPanel
        className="bg-white"
        form={true}
        min={0}
        max={config.importeTotal}
        defaultValue={config.data[config.index]?.monto}
        setValor={(valor)=>{
          let newdata = [...config.data];
          //
          if (!isNaN(valor) ){
            newdata[config.index].monto = valor;
          }
         // 
                       
          /*newdata[config.index].monto = !isNaN(e.target.value)
            ? e.target.value
            : newdata[config.index].monto;
          */
          config.setdata(newdata);
         }}
        />
      </td>
    </tr>
  );
};

const PagosCompra = (props) => {
  const [pagos, setpagos] = useState([]),
  [restante, setrestante] = useState(0),
  [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [importeTotal,setImporteTotal] = useState(0);
  const ticket = useRef(""),balanceProveedor=useRef({}),pagado=useRef("");
  const rutaActual =props.Ruta?.[props.Ruta.length-1], oldRuta =props.Ruta?.[props.Ruta.length-2];

  useMemo(() => {
    calcularrestante();
  }, [pagos]);

  useMemo(() => {
    setrestante(importeTotal);
  }, [importeTotal]);

  useEffect(() => {
    useGetBalanceProveedor(props.Proveedor.id,handleSuccessfulProv,funcionError);
  }, []);

  const funcionError=(error)=>{
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  }
  
  const handleSuccessfulProv = (data) => {
    const balance = {...data[0]};
    
    balance.totalCompras = balance.comprasCredito.reduce((a, b) => parseInt(a) + parseInt(b.importe||0), 0);
    balance.credito = balance.proveedor.creditoSucursales[getSucursal()];
    balance.pagado = balance.pagosAp.reduce((a, b) => parseInt(a) + (b.importe<0?b.importe*-1:b.importe), 0);
    balanceProveedor.current = balance;
    setImporteTotal(props.HeaderCompra.importe - (props.HeaderCompra?.descuento||0) + (props.HeaderCompra?.envio||0) - CalculaPagos(props.HeaderCompra.pagos,false))
  };

  function calcularrestante() {
    let totalpagado = CalculaPagos(pagos,true);

    const restante = importeTotal - parseInt(totalpagado);
    setrestante(restante);
  }

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false, botoncredito: false });
  }

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  const borrarDatos = () => {
    setpagos([
      {
        monto: 0.0,
        metodo: "",
        cuenta: "",
      },
    ]);
    if(oldRuta==="compra"){
      props.setCleanItem();
      props.setCleanHeaderCompra();
      props.setCleanProveedor();
      props.setInventario0();
    }
    if(oldRuta==="pedidoEspecial"){
      const headerCompra ={...props.HeaderCompra};
      //console.log('head',headerCompra)
      if(!headerCompra?.insertUpdate){
        headerCompra.compraOk = true;
        headerCompra.headerpes.pagado = pagado.current;
      }
      props.setHeaderCompra(headerCompra);
    }
    props.delRuta();
  };



  const hacerPago = (opcion) => {
      let mispagos = [];
      pagado.current = 0;
      for (let pago of pagos) {
        mispagos.push({
          metodo: pago.metodo,
          monto: parseInt(moneyToValue(pago.monto || 0)),
          cuenta: pago.cuenta
        });
        pagado.current+= moneyToValue(pago.monto || 0);
      }

      if(oldRuta=="compra"){
        if(props.HeaderCompra?.id){
          const peticion ={
            id: props.HeaderCompra.id,
            sucursal: getSucursal(),
            pagos: mispagos,
            lineas: props.HeaderCompra?.lineas || [],//{id:MINDCP , inventario: {MINDCP0,3.00,}}
            inventarioPendiente: props.HeaderCompra.inventarioPendiente,
          }
          //
          useUpdateCompra(peticion,handleSuccessfulPagar,handleErrorPagar);
        }else{
          let hayLineas =false;
          const oldItems= [...props.itemsCompra]
          const itemsCompra =oldItems.filter(product=> product.id!=="");

          itemsCompra.forEach(item =>{ if(item.type=="LINEA"){hayLineas = true;return; }})
          itemsCompra.forEach(item =>{ item.precioCompraPromediado = item.precioCompra})

          let compra = {
            sucursal: getSucursal(),
            proveedor: props.Proveedor.id,
            nombreProveedor: props.Proveedor.nombre,
            referencia: props.HeaderCompra?.referencia || "",
            fechaReferencia: props.HeaderCompra.fechaReferencia,
            personal: getIdUser(),
            nombrePersonal: getNombre(),
            cantidadTotal: props.HeaderCompra.cantidadTotal,
            importe: (props.HeaderCompra.importe-(props.HeaderCompra?.descuento||0)+(props.HeaderCompra?.envio||0)),
            subtotal: props.HeaderCompra.importe,
            //utilidad: utilidadtotal,
            items: itemsCompra,
            inventarioPendiente:hayLineas,
            pagos: mispagos,
            descuento : props.HeaderCompra.descuento,
            envio: props.HeaderCompra.envio
          };
          //
          useInsertCompra(compra,handleSuccessfulPagar,handleErrorPagar)
        }
      }else if(oldRuta==="pedidoEspecial"){
        //console.log('pagado=>',pagado.current)
        let peticion ={
          sucursal: getSucursal(),
          pagos: (opcion=='credito'?[]:mispagos),
          folio: props.HeaderCompra?.referencia||"",
          proveedor: props.Proveedor.id,
        }
        if(props.HeaderCompra?.insertUpdate){
          peticion.id= props.HeaderCompra.id;
          useUpdateCompraEspecial(peticion,handleSuccessfulPagar,handleErrorPagar);
          return;
        }
          peticion.idPedido= props.HeaderCompra.id,
          peticion.nombreProveedor= props.Proveedor.nombre;
          peticion.proveedor = props.Proveedor.id,
          peticion.importe= props.HeaderCompra.importe;
          peticion.personal= getIdUser();

          
          useInsertCompraEspecial(peticion,handleSuccessfulPagar,handleErrorPagar);
      }
  };

  const handleSuccessfulPagar=(data)=>{
    ticket.current = data.short_id
    setmodalSuccessConfig({
      isOpen: true,
      title: (data?.mensaje?.message)?data.mensaje.message:"Exito",
      message: "",
      data: props.HeaderCompra,
    });
  }
  const handleErrorPagar=(error)=>{
    //
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message || error?.message || "aqui va otro mensaje de error" ,
    });
  }

  const sendDatapagar = (e,opcion) => {
    e.preventDefault();

    let totalpagado = CalculaPagos(pagos,true);

    if (totalpagado > importeTotal ) {
      //
      setmodalinfoConfig({
        isOpen: true,
        title: "Monto incorrecto",
        message: (
          <span>
            La cantidad a pagar, excede el monto de la deuda. <br></br>
            Por favor, verifique la cantidad.
          </span>
        ),
      });
    } else if (totalpagado < importeTotal) {
      
      let aunConCredito = (importeTotal - totalpagado) <= (balanceProveedor.current.credito - balanceProveedor.current.totalCompras + balanceProveedor.current.pagado);
      let abono = aunConCredito && CalculaPagos(props.HeaderCompra.pagos,false) >= 0;
      //console.log(aunConCredito,CalculaPagos(props.HeaderCompra.pagos,false))
      
      if (abono) {
        //
        setmodalinfoConfig({
          isOpen: true,
          title: "Venta a credito",
          message: <span>Esta seguro que desea abonar a esta venta a credito?.</span>,
          botoncredito: true,
          textoBotonCredito: "abonar a la cuenta",
        });
      } else if (aunConCredito) {
        //
        setmodalinfoConfig({
          isOpen: true,
          title: "Venta a credito",
          message: <span>Esta seguro que desea poner esta venta a credito?.</span>,
          botoncredito: true,
          textoBotonCredito: "convertir a credito",
        });
      } else {
        //
        setmodalinfoConfig({
          isOpen: true,
          title: "Venta a credito",
          message: (
            <p>
              No es posible convertir esta venta a credito. El monto se excede
              por{" "}
              <Labelmoney>
                {importeTotal - totalpagado - props.Proveedor.creditoRestante}
              </Labelmoney>{" "}
            </p>
          ),
        });
      }
      
    } else {
      //
      hacerPago(opcion);
    }
  };

  const addpay = (e) => {
    e.preventDefault();
    setpagos(pagos.concat({ monto: 0.0, metodo: "", cuenta:"" }));
  };

  const deletePago = (index) => {
    let newPagos = [...pagos];
    let misPagos = [];
    for (let i = 0; i < newPagos.length; i++) {
      if (i !== index) {
        misPagos.push(newPagos[i]);
      }
    }
    setpagos(misPagos);
  };

  Set.prototype.difference = function (otherSet) {
    var differenceSet = [];
    for (var elem of this) {
      if (!otherSet.has(elem)) differenceSet.push(elem);
    }
    return differenceSet;
  };

  return (
    <>
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={modalinfoConfig.title}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <>
            <button
              type="button"
              className="btn-gray2 mx-1"
              onClick={closeModalinfo}
            >
              Cerrar
            </button>
            {modalinfoConfig.botoncredito ? (
              <button
                type="button"
                className="btn-blue1"
                onClick={(e) => {
                  
                  hacerPago();
                }}
              >
                {modalinfoConfig.textoBotonCredito}
              </button>
            ) : null}
          </>
        </Modalinfo.Buttons>
      </Modalinfo>
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalsuccess config={modalSuccessConfig}>
        <Modalinfo.Message title={modalSuccessConfig.title}>
         {/*  {modalSuccessConfig.message} */}
          Compra {ticket.current} Guardada Con Exito
        </Modalinfo.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={e=>{
              
              borrarDatos();
            }
            }
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
      <Container lg="px-80" bg="bg-blue-900">
        <div className=" mt-1 h-full flex flex-col min-h-0 min-w-0 mb-1 break-words rounded border-0">
          <table className="bg-gradient-to-b from-bluei via-bluei to-blue-900 h-full border-2 shadow-lg border-gray-300 mx-2">
            <thead className="top-0 rounded text-white text-sm font-semibold align-middle cursor-pointer uppercase">
              <tr className="text-xl h-16 text-center">
                <th
                key={0}
                className={"py-1 break-words"}
                >
                  Forma Pago
                </th>
                <th
                key={1}
                className={"py-1 break-words"}
                >
                  Cuenta
                </th>
                <th
                key={2}
                className={"py-1 break-words"}
                >
                  Monto
                </th>
              </tr>
            </thead>
            <tbody>
             {pagos.map((item, index) => (
              <PagoRows
              key={index}
              config={{
                setdata: setpagos,
                deletepago: deletePago,
                data: pagos,
                restante:restante,
                index: index,
                formasPago: () => {
                  let todasFormasPago = new Set([
                    ...props.FormasP.opciones.SS,
                  ]);
                  let formaspagousadas = new Set();
                  for (let i = 0; i < pagos.length; i++) {
                    if (i !== index) {
                      formaspagousadas.add(pagos[i].metodo);
                    }
                  }

                  return todasFormasPago.difference(formaspagousadas);
                },
                cuentas: Sucursal('cuentas'),
                importeTotal:importeTotal,
              }}/> 
              ))}
              <tr >
                <td></td>
                <td className="flex flex-wrap justify-center">
                  <button
                    className="btn-dark w-1/4 bg-teal-500 "
                    type="button"
                    onClick={addpay}
                    disabled={
                      !pagos.reduce(
                        (accumulator, currentValue) =>
                          accumulator && currentValue.monto > 0,
                        true
                      ) ||
                      !pagos.reduce(
                        (accumulator, currentValue) =>
                          accumulator && currentValue.metodo !== "",
                        true
                      )||
                      !pagos.reduce(
                        (accumulator, currentValue) =>
                          accumulator && currentValue.cuenta !== "",
                        true
                      )
                    }
                    style={{ transition: "all .15s ease" }}
                  >
                    +
                  </button>
                </td>
                <td></td>
              </tr>
            </tbody>
            <tfoot className=" text-white font-semibold">
            <tr>
                <td></td>
                <td className="text-right pt-4 pr-4 font-semibold text-2xl">IMPORTE TOTAL:</td>
                <td className="font-bold pt-4 text-2xl"><Labelmoney twoDecimal={true} className="font-semibold">{importeTotal}</Labelmoney></td>
              </tr>
              <tr className="">
                <td></td>
                <td className="text-right pt-4 pr-4 font-semibold text-2xl">PAGADO:</td>
                <td className="font-semibold pt-4 text-2xl"><Labelmoney twoDecimal={true} className="font-semibold">{moneyToValue(pagos.reduce((total,pago)=> total + parseFloat(pago.monto),0))}</Labelmoney></td>
              </tr>
              <tr className="">
                <td></td>
                <td className="text-right pt-4 pr-4 font-semibold text-2xl">CAMBIO:</td>
                <td className="font-semibold pt-4 text-2xl"><Labelmoney twoDecimal={true} className="font-semibold">{(restante<=0)?(restante*-1):0}</Labelmoney></td>
              </tr>
            </tfoot>
          </table>
          <div className="size-form w-3/4 mx-auto pt-2">
            <button
            type="button"
            className="btn-gray2 w-full"
            onClick={e=>{
              if(oldRuta=="pedidoEspecial"){ props.setCleanHeaderCompra(); }
              props.delRuta();
            }}
            >
              Cerrar
            </button>
            <button
            type="button"
            className={" w-full "+(restante==0?"btn-green1":"btn-dark bg-yellow-500")}
            disabled={
              importeTotal === 0 ||
              importeTotal === restante ||
              !pagos.length ||
              !pagos.reduce( (accumulator, currentValue) => accumulator && currentValue.metodo !== "",true ) ||
              !pagos.reduce( (accumulator, currentValue) => accumulator && currentValue.cuenta !== "", true  ) ||
              !pagos.reduce( (accumulator, currentValue) => accumulator && currentValue.monto > 0, true )
            }
            onClick={(e) => {sendDatapagar(e,(restante==0)?"Pagar":"Abonar");}}
            >
            {(restante==0)?"Pagar":"Abonar"}
            </button>
            <button
            type="button"
            className="btn-green1 w-full"
            disabled={pagos.length==0?false:true}
            onClick={(e) => {
              sendDatapagar(e,'credito');
            }}
            >
              Por Pagar
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  itemsCompra: state.itemsCompra,
  HeaderCompra: state.HeaderCompra,
  HeaderPes: state.HeaderPes,
  Proveedor: state.SearchProveedor,
  FormasP: state.Catalogos.FORMASPAGO,
  Ruta: state.Ruta,
});

const mapDispatchToProps = (dispatch) => ({
  addRuta: (ruta) => dispatch(setRuta(ruta)),
  delRuta:()=>dispatch(returnRuta()),
  setCleanItem:() => dispatch({type:"CLEAN_ITEMCOMPRA"}),
  setCleanHeaderCompra:() => dispatch({type:"CLEAN_HEADERCOMPRA"}),
  setCleanProveedor:() => dispatch({type:"CLEAN_PROVEEDOR"}),
  setInventario0:() => dispatch({type: "RESET_PROPSINVENTARIO"}),
  setHeaderCompra :(compra) => dispatch({type:"SET_HEADER_COMPRA",compra:compra}),
});

export default connect(mapStateToProps, mapDispatchToProps)(PagosCompra);

