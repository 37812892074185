import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import InputCompra from "./InputCompra";
import InputProveedor from '../../components/InputProveedor'
import DatosProveedor from "../../components/DatosProveedor";
import InputPrecioPanel from "../InputPrecioPanel";
import { getSucursal, moneyToValue } from "../../services/functions";

const HeaderCompra= ({Proveedor,headerCompra,setFechaCompra,setReferenciaCompra,setHeaderCompra,Logueo,Sucursales,itemsCompra,cleanItemsCompra,PropsReportes}) => {
  const alcance = [...Sucursales].filter(suc=>suc!=getSucursal()) ;
  const [idCompra,setIdCompra]=useState(null);

  useEffect(()=>{
    if(!headerCompra?.edit){return}
    setIdCompra(PropsReportes.select)
  },[PropsReportes?.select])

    return (
      <div className="size-form justify-between items-center pt-1 gap-1 ">
        <div className="flex flex-col gap-2">
            <div className="flex flex-wrap gap-1 justify-between items-center">
              <label className="text-white font-bold">Rcving N°</label>
              <InputCompra idCompra={idCompra} />
            </div>

            <div className="flex flex-wrap gap-1 justify-between items-center">
              <label title="Proveedor" className="text-white font-bold">Supplier</label>
              <InputProveedor horizontal={true} bg="bg-input-mesh"  habilitado={!headerCompra?.id ? false:true} valor={headerCompra.proveedor}/>
            </div>
            <div className="h-10 ">
              <DatosProveedor habilitado={!headerCompra?.id ? false:true}/>
            </div>
        </div>
        {Logueo?.windowSize>=1440?
        <div  className=" w-0 md:w-60 text-center gap-2 text-4xl text-white font-bold justify-center">
        <div>RECEIVING</div>
        <div hidden={headerCompra?.shortId?false:true} className="text-ambar-300">{"#"+headerCompra?.shortId}</div>
          {/*<div className="text-ambar-300" >
            {headerCompra?.shortId
            ?((headerCompra?.importe-(headerCompra?.pagos.reduce((totalpagos,pago)=> parseInt(totalpagos) + parseInt(pago.monto),0)))==0
              ?"PAGADO"
              :<div className="w-full flex flex-col items-center justify-center ">Saldo <Labelmoney className="font-semibold text-center">{headerCompra?.importe-(headerCompra?.pagos.reduce((totalpagos,pago)=> parseInt(totalpagos) + parseInt(pago.monto),0))}</Labelmoney></div>
            )
            :""
            }
          </div>*/}
        </div>
        :null
        }
        
        <div className="flex md:flex-row flex-col gap-2">
          <div className="flex flex-col gap-2 w-full md:w-auto ">
            <div className="flex flex-wrap gap-1 justify-between items-center">
                <label className="text-white font-bold">His Invoice No</label>
                <input 
                type="text" 
                className="border-form w-44 uppercase bg-input-mesh"
                maxLength="50"
                disabled={(Proveedor.id)?false:true}
                value={headerCompra.referencia||""}
                onChange={e=>{
                  (e.target.value&&e.target.validity)?
                  setReferenciaCompra(e.target.value.toUpperCase()):
                  setReferenciaCompra("")
                }}
                />
            </div>

            <div className="flex flex-wrap gap-1 justify-between items-center">
                <label className="text-white font-bold">Invoice Date</label>
                <input 
                    type='date' 
                    className="border-form bg-input-mesh w-44"
                    disabled={(Proveedor.id)?false:true}
                    value={headerCompra.fechaReferencia}
                    onChange={e=>{
                      setFechaCompra(e.target.value)
                    }}
                />
            </div>
            <div className="flex flex-wrap gap-1 justify-between ">
              <div className="flex flex-wrap gap-2 items-center" >
                  <label className="text-white font-bold">Discount</label>
                  <InputPrecioPanel
                  width="w-28"
                  form={true}
                  className="bg-input-mesh"
                  min={0}
                  defaultValue={headerCompra.descuento}
                  disabled={!headerCompra?.id?(Proveedor.id?false:true):true}
                  setValor={(e)=>{
                    setHeaderCompra({...headerCompra,descuento:moneyToValue(e) })
                  }}
                  />
              </div>
              <div className="flex flex-wrap gap-2 items-center">
                <label className="text-white font-bold">Freight</label>
                  <InputPrecioPanel
                  width="w-28"
                  form={true}
                  className="bg-input-mesh"
                  min={0}
                  newValor={headerCompra.envio||0}
                  disabled={!headerCompra?.id?(Proveedor.id?false:true):true}
                  setValor={(e)=>{
                    setHeaderCompra({...headerCompra,envio:moneyToValue(e) })
                  }}
                  />
              </div>                  
            </div> 
          </div>

          <div className="flex flex-col gap-1 ">
              {!headerCompra?.id?
                <div className="flex gap-2 items-center">
                  <input type="checkbox" checked={headerCompra.compraMenor} onChange={e=>{
                    if(headerCompra?.traspaso){return}
                    if(itemsCompra.length>1){
                      cleanItemsCompra();
                    }
                    setHeaderCompra({...headerCompra,compraMenor:!headerCompra.compraMenor})
                  }}></input>
                  <label className="text-white font-bold">Compra Menor</label>
                </div>
              :null}
              {!headerCompra?.id?
                <div className="flex gap-2 items-center">
                  <input type="checkbox" checked={headerCompra.traspaso} onChange={e=>{
                    if(!headerCompra?.compraMenor){ return}
                    setHeaderCompra({...headerCompra,traspaso:!headerCompra.traspaso})
                  }}></input>
                  <label className="text-white font-bold">Devolucion</label>
                </div>
              :null}
              {!Proveedor.id||!headerCompra.traspaso?null
              :<div className="flex flex-wrap gap-2 items-center ">
              <label className="text-white font-bold">Sucursal Destino</label>
            {Sucursales?(
              <select
              defaultValue={""}
              className="border-form w-40"
              onChange={e=>{
                if(e.target.value){ 
                  setHeaderCompra({...headerCompra,sucursalDestino:e.target.value})
                }else{
                  const header ={...headerCompra};
                  delete header.sucursalDestino;
                  setHeaderCompra(header);
                }

              }}>
                <option key={-1} defaultValue={""}>{""}</option>
                {Object.values(alcance).sort().map((item,index)=>(
                  <option key={index} defaultValue={item}>{item}</option>
                ))}
              </select>):null
              }
            </div>  
        }
          </div>
        </div>
      </div>
    );
  };

  const mapDispatchToProps = (dispatch) => ({
    setFechaCompra: (fecha) => dispatch({type:"SET_FECHAREFERENCIA",fechaReferencia:fecha}),
    setReferenciaCompra: (referencia) => dispatch({type:"SET_REFERENCIA_COMPRA",referencia:referencia}),
    setHeaderCompra :(compra) => dispatch({type:"SET_HEADER_COMPRA",compra:compra}),
    cleanItemsCompra: ()=> dispatch({type:"CLEAN_ITEMCOMPRA"}),
  });
  const mapStateToProps = (state) => ({
    headerCompra: state.HeaderCompra,
    Proveedor: state.SearchProveedor,
    Logueo: state.Logueo,
    Sucursales: state.Catalogos.sucursales,
    itemsCompra: state.itemsCompra,
    PropsReportes: state.PropsReportes,
  });

  export default connect(mapStateToProps, mapDispatchToProps)(HeaderCompra)