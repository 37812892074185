import React, { useState, useEffect } from "react";
import FormularioSucursal from "./formularioSucursal";
import { setsearchPersonal } from "../../redux/Actions";
import { connect } from "react-redux";
import Modaldanger from "../../components/modal/info";
import Container  from "../../components/Container";
import { useGetSucursal } from "../../Hooks/useApis";
import { havePermission } from "../../services/functions";
import { permisos } from "../../static/dat/datos";

const Wrapperformsucursal = (props) => {
  document.body.style = "background: rgba(17, 24, 39,1);";
  const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const [modaldangerConfig, setmodaldangerConfig] = useState({});
  const setsearchPersonal = props.setsearchPersonal, permiso = havePermission(permisos.regSucursal);

  useEffect(() => {
    return () => {
      setsearchPersonal({});
    };
  }, [setsearchPersonal]);

  function closeModaldanger(e) {
    const newdataconfig = { ...modaldangerConfig };
    newdataconfig.isOpen = false;
    setmodaldangerConfig(newdataconfig);
  }

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  const handleSuccessful = (data) => {
    const sucursal = {...data};
    if(!data?.descuentos){
      sucursal.descuentos=[
        {"MICA":0},
        {"ARMAZON":0},
        {"LENTE DE CONTACTO":0},
        {"EQUIPO LABORATORIO":0},
        {"EQUIPO OPTICO":0},
        {"ACCESORIO":0},
      ];
    }
      setsearchPersonal(sucursal);
  };

  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  return (
    <>
      <Modaldanger config={modaldangerConfig}>
        <Modaldanger.Message
          title={"No se ha encontrado la clave en la base de datos."}
        >
          {modaldangerConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModaldanger}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Container lg="px-60">
        <div className="px-6">
          <div className="mb-10 py-2 border-b ">
            <div className="flex flex-row mb-0">
              <div>
                <h6 className=" text-gray-600 text-sm font-bold">
                  Sucursal
                </h6>
              </div>

              <div className="ml-auto right-0">
                <div className="inline-block border-b-4 md:border-b-0 md:border-r-4 px-2">
                  <select
                    className="ml-1 border-form w-40"
                    onChange={e=>{
                      if (e.target.value){useGetSucursal(e.target.value,handleSuccessful,handleError);}
                      else{setsearchPersonal({});}
                    }}>
                       <option key={-1} defaultValue={""}></option>
                        {Object.values(props.Logueo.alcance).sort().map((item,index)=>(
                            <option key={index} defaultValue={item}>{item}</option>
                        ))}
                  </select>
                </div>
                <div className="inline-block px-2">
                  <button
                    className="btn-blue w-full"
                    type="button"
                    hidden={!permiso}
                    onClick={(e) => {
                      setsearchPersonal({});
                    }}
                    style={{ transition: "all .15s ease" }}
                  >
                    Nueva sucursal
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4">
              <FormularioSucursal key={0} />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setsearchPersonal: (personal) => dispatch(setsearchPersonal(personal)),
});
const mapStateToProps = (state) => ({
  Logueo: state.Logueo
});

export default connect(mapStateToProps, mapDispatchToProps)(Wrapperformsucursal);
