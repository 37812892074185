import React, { useEffect, useRef, useState } from "react";
import Modalbase from "./base";
import { connect } from "react-redux";
import { setLogueo, setRuta } from "../../redux/Actions";
import Modalgeneric from "./generic";
import { encryptData, getIdUser, tiempoCookie } from "../../services/functions";
import { useSetLogin } from "../../Hooks/useApis";
import { mensajesGenerales } from "../../static/dat/datos";



const Modaldanger = (props) => {
  const [modalSesion,setModalSesion] = useState({}),
  [error,setError]=useState("");
  const inputUser=useRef(""), inputPass=useRef("");

  function ResetRedux() {
    props.resetitemsVenta();
    props.resetClientes();
    props.resetClientesVenta();
    props.resetSearchPersonal();
    props.resetSearchProveedor();
    props.resetHeaderCompra();
    props.resetitemsCompra();
    props.resetHeaderVenta();
    props.resetVentaAPagar();
    props.resetTicket();
    props.resetventasCredito();
    props.resetInventario();
    props.resetPropsInventario();
    props.resetitemsPedidoEspecial();
    props.resetitemsCash();
    props.resetPropsReportes();
    props.resetDatos();
    props.resetHeaderPes();
  }

  const logOut = () => {
    document.cookie = "sessionIndigo=; max-age=0; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    localStorage.clear();
    props.setRuta("login");
    props.setLogueo({});
    localStorage.setItem("sucursal", JSON.stringify({}));
    ResetRedux();
  };

  const closeModalSesion=()=>{
    if(props?.closeModal){ props.closeModal()}
    setModalSesion({isOpen:false})
  }
  const handleSuccessfulAuth=(user)=>{
    inputUser.current.value ="";
    inputPass.current.value ="";
    setError("");
    document.cookie = "sessionIndigo=" + user.token + "; path=/; max-age=" + tiempoCookie;  //valor en segundos, la cookie se almacenara por 18 horas;p
    props.setLogueo({...user,permisos:[...user.permisos].sort(),bgcolor:"bg-bluei"});
    closeModalSesion();
    //console.log(props)
    if(props?.closeModal){props.closeModal()}
  }

  const handleErrorAuth=(error)=>{
    setError(error?.response?.data?.message||error)
  }

  useEffect(()=>{
    
    if(!props?.config?.isOpen){return}
    if(!props?.config?.message){return}
    if(props.Ruta[props.Ruta.length-1]=="login"){setModalSesion({isOpen:false}); props?.closeModal?props.closeModal():""; return;}
    if(props?.config.message==mensajesGenerales.login&&!modalSesion?.isOpen){
      
      setModalSesion({isOpen:true})
      
    }
  },[props?.config,props.Ruta])


  useEffect(()=>{
    if(modalSesion?.isOpen){if(props?.closeModal){props.closeModal()}}
  },[modalSesion?.isOpen])

  const handleKeyPress=(e)=>{
    if(e.key=="Escape"&&props?.config?.isOpen&&props?.closeModal){
      props.closeModal();
    }
  }

  useEffect(() => {
    if(props.config?.isOpen==true){ 
      const elements = document.getElementsByName("modaldanger"); 
      const tab = props?.tab||"100";
      elements.forEach(element => {
        if(element.tabIndex==tab){
          element.focus();
        }
      });
    }
  }, [props.config])

  
  return (
    <div tabIndex={"0"} onKeyDown={handleKeyPress} className={"inset-0 "+(props.config.isOpen ? "fixed "+(props?.front?props.front:" z-50 ") : "hidden")  }>
      <Modalgeneric w="size-w-30" h="size-h-30" bg="bg-bluei" front="z-50" config={modalSesion} closeModal={closeModalSesion}>
        <Modalgeneric.Body>
          <Modalgeneric.Title text="text-white uppercase ">Sesion Expirada</Modalgeneric.Title>
          <div className="flex flex-col gap-3 text-white justify-center items-center justify-items-center h-80/100">
            <div className="flex flex-row gap-2 font-semibold">
              <label className="w-24">Usuario</label>
              <input type="text" autoComplete="off" ref={inputUser} className="border-form uppercase"></input>
            </div>
            <div className="flex flex-row gap-2 font-semibold">
              <label className="w-24">Contraseña</label>
              <input type="password" autoComplete="off" ref={inputPass} className="border-form uppercase"></input>
            </div>
            <label className="text-red-300 font-bold pt-2 w-full text-center">{error}</label>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button className="btn-red1" onClick={e=>{
            inputUser.current.value ="";
            inputPass.current.value ="";
           logOut();
          }}>Cerrar Sesion</button>
          <button className="btn-gray1" onClick={e=>{
            const user = inputUser.current.value.toUpperCase(), pass=inputPass.current.value.toUpperCase();
            if(e.detail>1){return}
            if(!user||!pass){setError("Los campos no pueden quedar vacios"); return}
            if(user!=getIdUser()){setError("El usuario no es el mismo para logearte con otro usuario cierra sesion y vuelve a logearte"); return}
            var encodedData = btoa(user + ":" + encryptData(pass));
            useSetLogin(encodedData,handleSuccessfulAuth,handleErrorAuth)
            event.preventDefault();
          }}>Iniciar</button>
          <button className="btn-green1" onClick={e=>{closeModalSesion();}}>
            Cerrrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <Modalbase>{props.children}</Modalbase>
    </div>
  );
};

// eslint-disable-next-line react/display-name
Modaldanger.Message = (props) => {
  const message = "";
  const title = props.title || "";

  return (
    <Modalbase.Body>
      <Modalbase.Iconerror />
      <Modalbase.Bodytext>
        <Modalbase.Title>{title}</Modalbase.Title>
        <Modalbase.Message >{props.children ?? message}</Modalbase.Message>
      </Modalbase.Bodytext>
    </Modalbase.Body>
  );
};

// eslint-disable-next-line react/display-name
Modaldanger.Body = (props) => {
  const title = props.title || "";

  return (
    <Modalbase.Body>
      <Modalbase.Iconerror />
      <Modalbase.Bodytext>
        <Modalbase.Title>{title}</Modalbase.Title>
        {props.children}
      </Modalbase.Bodytext>
    </Modalbase.Body>
  );
};

// eslint-disable-next-line react/display-name
Modaldanger.Buttons = (props) => (
  <Modalbase.Buttons>{props.children}</Modalbase.Buttons>
);

const mapStateToProps = (state) => ({
  Ruta: state.Ruta,
})

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
  setLogueo: (datos) => dispatch(setLogueo(datos)),


  resetitemsVenta:()=>dispatch({type:"RESET_ITEM_VENTA"}),
  resetClientes:()=>dispatch({type:"CLEAN_CLIENT"}),
  resetClientesVenta:()=>dispatch({type:"CLEAN_CLIENT_VENTA"}),
  resetSearchPersonal:()=>dispatch({type:"RESET_SEARCHPERSONAL"}),
  resetSearchProveedor:()=>dispatch({type:"RESET_SEARCHPROVEEDOR"}),
  resetHeaderCompra:()=>dispatch({type:"CLEAN_HEADERCOMPRA"}),
  resetitemsCompra:()=>dispatch({type:"CLEAN_ITEMCOMPRA"}),
  resetHeaderVenta:()=>dispatch({type:"CLEAN_HEADER_VENTA"}),
  resetVentaAPagar:()=>dispatch({type:"RESET_VENTAPAGAR"}),
  resetTicket:()=>dispatch({type:"RESET_TICKET"}),
  resetventasCredito:()=>dispatch({type:"RESET_VENTACREDITO"}),
  resetInventario:()=>dispatch({type:"RESET_INVENTARIO"}),
  resetPropsInventario:()=>dispatch({type:"RESET_PROPSINVENTARIO"}),
  resetHeaderPes:()=>dispatch({type:"RESET_HEADER_PES"}),
  resetitemsPedidoEspecial:()=>dispatch({type:"RESET_ITEMS_ESP"}),
  resetitemsCash:()=>dispatch({type:"RESET_ITEMS_CASH"}),
  resetPropsReportes:()=>dispatch({type:"RESET_REP"}),
  resetDatos:()=>dispatch({type:"RESET_DATOS"}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modaldanger);
