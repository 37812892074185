import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modalinfo from "../modal/info";
import {useDeleteCompra, useDeleteTraspaso, useGetCompraAsync, useGetTraspasoAsync} from "../../Hooks/useApis";
import SearchSvg from "../../static/img/SearchSvg";
import { useRef } from "react";
import LoadingModal from "../modal/LoadingModal";

const InputCompra = (props) => {
  const title = useRef("Sin Datos"),input=useRef("");
  const [modalLoading, setmodalLoading] = useState({}),
  [modalinfoConfig, setmodalinfoConfig] = useState({});

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true, title });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };


  const funcionError = (error) => {
    closemodalLoading();
    setmodalinfoConfig({
      title: "Error al obtener los datos",
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  const funcionDeleteExito = (respuesta)=>{
    setmodalinfoConfig({
      title: "Compra Eliminada",
      isOpen: true,
      message: "",
    });
  }

  const getCompra=async(compra,petLast)=>{
    const peticion={folio:input.current.value.trim().toUpperCase()};
    if(petLast){ peticion.lastKeys = petLast;}
    const response = await useGetCompraAsync(peticion);
    if(response.data.message){return}
    const data =  response.data;

    for (let item of data.items) {
      const new_item = {
        cantidad: item.cantidadComprada,
        descripcion: item.descripcion,
        id: item.id,
        importe: item.importe,
        precioCompra: item.precioCompra,
        //precioCompraIni: item.precioCompra,
        //precioVentaIni: item.precioVenta,
        oldPrecioCompra: item.precioCompra,
        oldExistencia:item.cantidadComprada,
        inventario: item?.inventario,
        type : item.type,
      }
     
      if([true,false].includes(item?.inventarioPendiente)){
        new_item.inventarioPendiente=item.inventarioPendiente
      }
      compra.items.push(new_item);
    }

    if(data?.LastEvaluatedKey){
      await getCompra(compra,data.LastEvaluatedKey);
    }else if (compra.items.length){
      compra.header.referencia = data.compra.referencia;
      compra.header.fechaReferencia = data.compra.fechaReferencia;
      compra.header.inventarioPendiente = data.compra.inventarioPendiente;
      compra.header.proveedor = data.compra.proveedor;

      compra.header.oldRef = data.compra.referencia;
      compra.header.oldFecha = data.compra.fechaReferencia;
      compra.header.shortId = data.compra.shortId;
      compra.header.id = data.compra.id;
      compra.header.type = "COMPRA";
    }
  }

  const getDevolucion=async(compra,petLast)=>{
    const peticion={folio:input.current.value.trim().toUpperCase()};
    if(petLast){ peticion.lastKeys = petLast;}
    const response = await useGetTraspasoAsync(peticion);
    if(response.data.message){return}
    const data =  response.data;

    for (let item of data.items) {
      const new_item = {
        cantidad: item.cantidadComprada,
        descripcion: item.descripcion,
        id: item.id,
        importe: item.importe,
        precioCompra: item.precioCompra,
        //precioCompraIni: item.precioCompra,
        //precioVentaIni: item.precioVenta,
        oldPrecioCompra: item.precioCompra,
        oldExistencia:item.cantidadComprada,
        inventario: item?.inventario,
        type : item.type,
      }
     
      if([true,false].includes(item?.inventarioPendiente)){
        new_item.inventarioPendiente=item.inventarioPendiente
      }
      compra.items.push(new_item);
    }

    if(data?.LastEvaluatedKey){
      await getDevolucion(compra,data.LastEvaluatedKey);
    }else if (compra.items.length){
      compra.header.referencia = data.traspaso.referencia;
      compra.header.fechaReferencia = data.traspaso.fechaReferencia;
      compra.header.inventarioPendiente = false;
      compra.header.proveedor = data.traspaso.proveedor;

      compra.header.oldRef = data.traspaso.referencia;
      compra.header.oldFecha = data.traspaso.fechaReferencia;
      compra.header.shortId = data.traspaso.shortId;
      compra.header.id = data.traspaso.id;
      compra.header.type = "TRASPASO";
    }
  }

  const funcionBuscar = async(e) => {
    if (!input.current.value.trim()) { return; }
    let data ={header:{},items:[]};
    try{
      openmodalLoading();
      await getCompra(data);
      
      if (!data.header?.shortId&&!data.items.length) {
        await getDevolucion(data);
      }

      if(!data.items.length){
        setmodalinfoConfig({
          title: "No hay Compra",
          isOpen: true,
          message: "No existe la compra'" + input.current.value.toUpperCase() + "'",
        });
        closemodalLoading();
        return;
      }

      props.setCompra(data.header);
      props.addItem(data.items);
      closemodalLoading();

    }catch(error){
      funcionError(error);
    }
  };
  
  useEffect(()=>{
    if(!props?.idCompra){return}
    input.current.value = props.idCompra;
    funcionBuscar();
  },[props?.idCompra])

  return (
    <>
    <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>{title.current}</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message
          title={modalinfoConfig.title}
        >
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
          <div className="flex border-form bg-input-mesh">
            <input
              type="text"
              className="w-full border-0 focus:outline-none uppercase bg-transparent"
              name="articulo"
              placeholder="Ticket"
              style={{ transition: "all .15s ease" }}
              ref={input}
              onKeyDown={e=>{ if(e.key=="Enter"){ funcionBuscar(); }
              }}
            />

            <button
              className="w-12 ml-1"
              type="submit"
              onClick={funcionBuscar}
              style={{ transition: "all .15s ease" }}
            >
              <SearchSvg width="w-5" height="h-5" text="text-gray-600"/>
            </button>
            <div 
                className={"text-red-700 pt-1 mr-2 "+(props.Compra?.id?"block":"hidden") }
                title="Eliminar Compra" 
                hidden={!props.Compra?.id}>
                  <button
                    onClick={(e) => {
                      if(props.Compra.type=="COMPRA"){
                        useDeleteCompra(props.Compra.id,funcionDeleteExito,funcionError);
                      }else if (props.Compra.type=="TRASPASO"){
                        useDeleteTraspaso(props.Compra.id,funcionDeleteExito,funcionError)
                      }
                    }}
                  >
                    <i className="fas fa-trash text-lg hover:text-red-500"></i>
                  </button>
                </div> 
          </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch({ type: "SET_ITEMSCOMPRA", items: item, }),
  addProveedor: (proveedor) => dispatch({ type: "SET_SEARCHPROVEEDOR", proveedor: proveedor, }),
  setCompra: compra => dispatch({type:"SET_HEADER_COMPRA",compra:compra}),
  setProveedorCompra: proveedor => dispatch({type:"SET_PROVEEDORCOMPRA",proveedor:proveedor}),
});

const mapStateToProps = (state) => ({
  Proveedor: state.SearchProveedor,
  Compra: state.HeaderCompra
});

export default connect(mapStateToProps, mapDispatchToProps)(InputCompra);
