import React, { useState } from "react";
import {  setRuta } from "../redux/Actions";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modaldanger from "../components/modal/info";
import Modalsuccess from "../components/modal/success";
import Labelerror from "../components/LabelError";
import { useUpdateConfig } from "../Hooks/useApis";
import Container from "../components/Container";

const schema = yup.object().shape({
  utilidadInterna: yup
  .number()
  .positive("El valor debe ser mayor a cero")
  .integer()
  .required("La utilidad mínima para operaciones internas es obligatoria"),
  interes: yup
  .number()
  .positive("El valor debe ser mayor a cero")
  .integer()
  .required("El porcentaje de interés aplicado a ventas es obligatorio"),
  utilidad: yup
    .number()
    .positive("El valor debe ser mayor a cero")
    .integer()
    .required("El porcentaje de utilidad mínimo es obligatorio"),
  aguinaldo: yup
  .number()
  .positive("El valor debe ser mayor a cero")
  .integer()
  .required("El porcentaje de aguinaldo es obligatorio"),
});

const Formulariopersonal = (props) => {
  const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const [modalSuccessConfig, setmodalSuccessConfig] = useState({});

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSuccessful = (data) => {
    
    setmodalSuccessConfig({
      isOpen: true,
      message:"La actualización se realizó con éxito",
    });
  };

  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  const sendData = (dataform) => {
    useUpdateConfig(dataform,handleSuccessful,handleError);
  };
  
  return (
    <>
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalsuccess config={modalSuccessConfig}>
        <Modalsuccess.Message title={"Éxito"}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
      <Container bg="bg-bluei size-form justify-center ">
        <form id="formconfig" className="flex flex-col items-center text-center h-full w-full md:w-1/2 text-white px-2 md:px-0" onSubmit={handleSubmit(sendData)}>
          <div className="my-3 w-full md:w-auto">
            <label htmlFor="grid-password">
              Interés a ventas
            </label>
            <Labelerror text={true} error={errors}>
              <input
                type="number"
                className="border-form w-full"
                name="interes"
                placeholder="Interés para ventas a crédito"
                ref={register}
                defaultValue={props.GlobalConfigs.interesVentas}
                style={{ transition: "all .15s ease" }}
              />
            </Labelerror>
          </div>
          <div className="mb-3 w-full md:w-auto">
            <label htmlFor="grid-password">
              Días de aguinaldo
            </label>
            <Labelerror text={true} error={errors}>
              <input
                type="number"
                className="border-form w-full"
                name="aguinaldo"
                placeholder="Porcentaje de aguinaldo"
                ref={register}
                defaultValue={props.GlobalConfigs.aguinaldo}
                style={{ transition: "all .15s ease" }}
              />
            </Labelerror>
          </div>
          <div className="mb-3 w-full md:w-auto">
            <label htmlFor="grid-password">
              Utilidad mínima
            </label>
            <Labelerror text={true} error={errors}>
              <input
                type="number"
                className="border-form w-full"
                name="utilidad"
                placeholder="Utilidad mínima"
                ref={register}
                defaultValue={props.GlobalConfigs.utilidadMinima}
                style={{ transition: "all .15s ease" }}
              />
            </Labelerror>
          </div>
          <div className="mb-3 w-full md:w-auto">
            <label htmlFor="grid-password">
              Utilidad mínima para operaciones internas
            </label>
            <Labelerror text={true} error={errors}>
              <input
                type="number"
                className="border-form w-full"
                name="utilidadInterna"
                placeholder="Utilidad para operaciones internas"
                ref={register}
                defaultValue={props.GlobalConfigs.utilidadInterna}
                style={{ transition: "all .15s ease" }}
              />
            </Labelerror>
          </div>
          <button
          className="btn-gray2 w-full md:w-28 mb-1"
          type="button"
          onClick={(e) => {
            props.setRuta("menu");
          }}
          style={{ transition: "all .15s ease" }}
          >
              Cancelar
          </button>
          <button
          className="btn-blue1 w-full md:w-28"
          type="submit"
          style={{ transition: "all .15s ease" }}
          >
            Guardar
          </button>
        </form>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  datospersonal: state.Logueo,
  GlobalConfigs: state.Catalogos.GLOBALCONFIGS,
});

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Formulariopersonal);
