import React, { useState } from "react";

const TableMovimiento = (props) => {
  const onclickRow = props.onclickRow || ((item) => {});
  const columns = props.columns || [{}];
  const footer = props.footer || [{}];
  const data = props.data || [];
  const [field, setfield] = useState("");
  const [desc, setdesc] = useState(false);
  const [input, setinput] = useState("");
  const searchinput = props.searchinput === undefined ? true : props.searchinput;

  function byfield(a, b) {
    if (desc) {
      if (a[field] > b[field]) return 1;
      if (a[field] < b[field]) return -1;
    } else {
      if (a[field] < b[field]) return 1;
      if (a[field] > b[field]) return -1;
    }

    return 0;
  }

  function searchByAnyField(item) {
    let newsearch = input.replace('+','\\+');
    newsearch =  input.replace(/\\/g,'\\\\');
    
    for (const value of Object.values(item)) {
      if (typeof value === "string" || value instanceof String) {
        if (value.toUpperCase().search(input.toUpperCase()) !== -1) {
          return true;
        }
      }
    }
  }

  return (
    <div className={"flex flex-col break-words w-full landscape:mt-0 overflow-y-auto "}>
      {searchinput ? (
        <div className="mt-1 flex sm:flex-row-reverse flex-col w-full">
          <div className="block relative">
            <input
              type="text"
              className="inputf focus:shadow-outline uppercase landscape:h-8"
              name="buscar"
              value={input}
              onChange={(e) => {
                setinput(e.target.value);
              }}
              placeholder="Buscar"
              style={{ transition: "all .15s ease" }}
            />
          </div>
        </div>
      ) : null}
      <div className="absolute  flex flex-col break-words overflow-x-auto overflow-y-auto h-full max-w-full  mx-auto landscape:mt-0 rounded  border-0">
        <table className="overflow-x-auto overflow-y-auto mt-1  bg-ambar-100">
          <thead className="sticky top-0 rounded bg-black text-ambar-200 text-sm font-semibold align-middle cursor-pointer uppercase flex">
            <tr>
              {columns
                ? columns.map((item, index) => (
                    <th
                      key={index}
                      onClick={(e) => {
                        setdesc(!desc);
                        setfield(item.accessor);
                      }}
                      className={item.width + " py-1 px-1 border-l-2 border-r-2 break-words border-gray-300 " }
                      data-priority={index}
                      hidden={item?.visible||false}
                    >
                      {item.header}
                    </th>
                  ))
                : null}
            </tr>
          </thead>
          <tbody>
            {/*className={(item?.status)?((item.status=="SOLICITADO")?"bg-gray-100":(item.status=="ACEPTADO"?"bg-yellow-100":(item.status=="ENVIADO"?"bg-green-100":(item.status=="PROCESO"?"bg-blue-100":(item.status=="RECIBIDO"?"bg-blue-200":(item.status=="ENTREGADO"?"bg-teal-200":(item.status=="GARANTIA"?"bg-yellow-200":"bg-purple-300"))))))):(index1 % 2 === 0 ? "bg-gray-100" : "bg-white") +" cursor-pointer hover:bg-indigo-200 "} */}
            {data
              ? data
                  .filter((item) => searchByAnyField(item))
                  .sort(byfield)
                  .map((item, index1) => (
                    
                    <tr
                      key={index1}
                      className={"flex cursor-pointer w-full text-size hover:bg-ambar-300 "}
                      onClick={(e) => {
                        onclickRow(item);
                      }}
                    >
                      {props.columns.map((itemcolumn, index2) => (
                        <td
                          key={index2}
                          hidden={itemcolumn?.visible||false}
                          tabIndex={index1+index2}
                          className={itemcolumn.width + " px-1 text-size border border-gray-400 " }
                          onKeyDown={e=>{
                            const focusableElements = 'textarea:not([disabled]), input[type="number"]:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), select:not([disabled])';
                            const currentFocused = document.activeElement;
                            const allFocusable = Array.from(document.querySelectorAll(focusableElements));
                            const currentIndex = allFocusable.indexOf(currentFocused);
                            
                            if(["Enter","Tab"].includes(e.key)){
                              e.preventDefault();
                              if (currentIndex !== -1 && currentIndex < allFocusable.length - 1) {
                                allFocusable[currentIndex + 1].focus();
                                allFocusable[currentIndex+1]?.select?allFocusable[currentIndex+1].select():""
                              }
                            }
                            else if(e.key=="ArrowRight"&&allFocusable[currentIndex]?.selectionStart === 0 && allFocusable[currentIndex]?.selectionEnd === allFocusable[currentIndex].value.length){
                              e.preventDefault();
                              if (currentIndex !== -1 && currentIndex < allFocusable.length - 1) {
                                allFocusable[currentIndex+1].focus();
                                allFocusable[currentIndex+1]?.select?allFocusable[currentIndex+1].select():""
                              }
                            }
                            else if(e.key=="ArrowLeft"&&allFocusable[currentIndex]?.selectionStart === 0 && allFocusable[currentIndex]?.selectionEnd === allFocusable[currentIndex].value.length){
                              e.preventDefault();
                              if (currentIndex !== -1 && currentIndex > 0) {
                                allFocusable[currentIndex-1].focus();
                                allFocusable[currentIndex-1]?.select?allFocusable[currentIndex-1].select():""
                              }
                            }
                          }}
                        >
                          {itemcolumn.code
                            ? itemcolumn.code(item,index1)
                            : item[itemcolumn.accessor]}
                        </td>
                      ))}
                    </tr>
                  ))
              : null}
          </tbody>
          <tfoot className=" bottom-0 bg-mesh">
          <tr>
              {footer
                ? footer.map((item, index) => (
                    <td
                      key={index}
                      className={item.width+" px-2 text-size font-semibold whitespace-nowrap"}
                      data-priority={index}
                    >
                      {item.code}
                    </td>
                  ))
                : null}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default TableMovimiento;
