import React, { useState, useEffect } from "react";
import Formulariopersonal from "./formularioProveedor";
import { setsearchPersonal } from "../../redux/Actions";
import { connect } from "react-redux";
import Modaldanger from "../../components/modal/info";
import Modalgeneric from "../../components/modal/generic";
import Container from "../../components/Container";
import { useGetBalanceProveedor, useGetProveedor } from "../../Hooks/useApis";
import TableModal from "../../components/modal/TableModal";
import SearchSvg from "../../static/img/SearchSvg";
import ModalProveedores from "../../components/Proveedores/ModalProveedores";

const Wrapperformpersonal = (props) => {
  document.body.style = "background: rgba(17, 24, 39,1);";
  const [showForm, setshowForm] = useState(true);
  const [modalTablaConfig, setmodalTablaConfig] = useState(false);
  const [dataModalTabla, setdataModalTabla] = useState(false);
  const [search, changesearch] = useState(""),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [comprasCredito,setComprasCredito]=useState([]),
  [openModal,setOpenModal]=useState();
  const [modaldangerConfig, setmodaldangerConfig] = useState({title:"Dato no Encontrado"});
  const setsearchPersonal = props.setsearchPersonal;

  function closeModaldanger(e) {
    const newdataconfig = { ...modaldangerConfig };
    newdataconfig.isOpen = false;
    setmodaldangerConfig(newdataconfig);
  }

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  function closeModaltabla(e) {
    setmodalTablaConfig({ isOpen: false });
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const setdatasearch = (e) => {
    changesearch(e.target.value.toUpperCase());
  };

  const handleSuccessful = (data) => {
    if (Array.isArray(data)) {
      if (data.length === 0) {
        setmodaldangerConfig({
          title:"Proveedor no Encontrado",
          isOpen: true,
          message:
            "El proveedor '" + search + "' al parecer no existe",
        });
      }
      if (data.length === 1) {
        AgregarProveedor(data[0]);
        setshowForm(!showForm);
      }
      if (data.length > 1) {
        setdataModalTabla(data);
        setmodalTablaConfig({ isOpen: true });
      }
    }
  };

  const funcionBalanceExito=(respuesta)=>{
    if (Array.isArray(respuesta)) {
      if (respuesta.length === 0) { return; }
      if (respuesta.length === 1) {
        respuesta[0].comprasCredito.sort((a,b)=>{
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          return 0;}).map(compra=>{
          compra.pagado =  compra.pagos.reduce((totalpagos,pago)=> parseInt(totalpagos) + parseInt(pago.monto),0)
        })
        setComprasCredito(respuesta[0].comprasCredito);
      }
    }
  }

  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  const AgregarProveedor =(proveedor)=>{
    setsearchPersonal(proveedor);
    useGetBalanceProveedor(proveedor.id,funcionBalanceExito,handleError);
  }

  const handleSubmit = (event) => {
    setsearchPersonal({});
    setshowForm(!showForm);
    useGetProveedor(search,handleSuccessful,handleError);
  };

  useEffect(() => {
    return () => {
      setsearchPersonal({});
    };
  }, [setsearchPersonal]);

  return (
    <>
    <ModalProveedores openModal={openModal} getProveedor={setsearchPersonal}/>
      <Modaldanger config={modaldangerConfig}>
        <Modaldanger.Message
          title={modaldangerConfig.title}
        >
          {modaldangerConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModaldanger}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalgeneric config={modalTablaConfig}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Elija una opción</Modalgeneric.Title>
          <TableModal
            columns={[
              {
                header: "Clave",
                accessor: "id",
                width: "w-1/3",
              },
              { header: "Nombre", accessor: "nombre", width: "w-1/3" },
            ]}
            onclickRow={(item) => {
              AgregarProveedor(item);
              setmodalTablaConfig({ isOpen: false });
              setshowForm(!showForm);
            }}
            data={dataModalTabla}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <Container lg="px-60">
      <div className="px-6">
          <div className="mb-10 py-2 border-b ">
            <div className="flex flex-row mb-0 ">
                  <div>
                    <h6 className=" text-gray-600 text-sm font-bold">
                      Proveedor
                    </h6>
                  </div>

                  <div className="mx-auto">
                  <button className="btn-green1" onClick={e=>{
                    setOpenModal(!openModal);
                  }}>
                    Suppliers
                  </button>
              </div>

                  <div className="ml-auto right-0">
                    <div className="inline-block border-b-4 md:border-b-0 md:border-r-4 px-2">
                      <div className="pt-2 relative mx-auto text-gray-600">
                        <input
                          className=" w-full border-2 uppercase border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
                          type="search"
                          name="buscar"
                          placeholder="Buscar"
                          onChange={setdatasearch}
                          onKeyDown={handleKeyDown}
                        />
                        <button
                          type="button"
                          className="absolute right-0 top-0 mt-5 mr-4"
                          onClick={handleSubmit}
                        >
                          <SearchSvg width="w-4" height="h-4" text="text-gray-600"/>
                        </button>
                      </div>
                    </div>
                    <div className="inline-block px-2">
                      <button
                        className="btn-blue w-full"
                        type="button"
                        onClick={(e) => {
                          setsearchPersonal({});
                          setshowForm(!showForm);
                        }}
                        style={{ transition: "all .15s ease" }}
                      >
                        Nuevo Proveedor
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-center">
                <div className="w-full px-4">
                  {showForm ? (
                    <Formulariopersonal key={0} comprasCredito={comprasCredito}/>
                  ) : (
                    <Formulariopersonal key={1} comprasCredito={comprasCredito}/>
                  )}
                </div>
              </div>
            </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setsearchPersonal: (personal) => dispatch(setsearchPersonal(personal)),
});

export default connect(null, mapDispatchToProps)(Wrapperformpersonal);
