import React, { useEffect } from "react";
import Modalbase from "./base";

const Modalinfo = (props) => {

  const handleKeyPress=(e)=>{
    if(e.key=="Escape"&&props?.config?.isOpen&&props?.closeModal){
      props.closeModal();
    }
  }

  useEffect(() => {
    if(props.config?.isOpen==true){ 
      const elements = document.getElementsByName("modalinfo"); 
      const tab = props?.tab||"100";
      elements.forEach(element => {
        if(element.tabIndex==tab){
          element.focus();
        }
      });
    }
  }, [props.config])

  return (
    <div tabIndex={"0"} onKeyDown={handleKeyPress} className={"inset-0 "+(props.config.isOpen ? "fixed " : "hidden ") + (props?.front?props.front:" z-50 ")}>
      <Modalbase>{props.children}</Modalbase>
    </div>
  );
};

// eslint-disable-next-line react/display-name
Modalinfo.Message = (props) => {
  const message = "";
  const title = props.title || "";

  return (
    <Modalbase.Body>
      <Modalbase.Iconinfo />
      <Modalbase.Bodytext>
        <Modalbase.Title>{title}</Modalbase.Title>
        <Modalbase.Message>{props.children ?? message}</Modalbase.Message>
      </Modalbase.Bodytext>
    </Modalbase.Body>
  );
};

// eslint-disable-next-line react/display-name
Modalinfo.Body = (props) => {
  const title = props.title || "";

  return (
    <Modalbase.Body>
      <Modalbase.Iconinfo />
      <Modalbase.Bodytext>
        <Modalbase.Title>{title}</Modalbase.Title>
        {props.children}
      </Modalbase.Bodytext>
    </Modalbase.Body>
  );
};

// eslint-disable-next-line react/display-name
Modalinfo.Buttons = (props) => {
  return <Modalbase.Buttons>{props.children}</Modalbase.Buttons>
};

export default Modalinfo;
