/* eslint-disable */
import React, { useState, useEffect, useRef} from "react";
import { connect } from "react-redux";
import Selectormap from "../components/Productos/Selectormap";
import InputSelectormap from "../components/Productos/InputSelectormap";
import CheckDual from "../components/Productos/CheckDual";
import InputModelo from "../components/Productos/InputModelo";
import Base from "../components/Productos/Base";
import InputGraduacion from "../components/Productos/InputGraduacion";
import { apiUseProd, apiUse, SearchValueInObject, CreateCode, Sleeping } from "../services/functions";
import Tablaarmazon from "../components/Productos/Tablaarmazon";
import Modaldanger from "../components/modal/info";
import Modalsuccess from "../components/modal/success";
import Multiselector from "../components/Multiselector";
import Container from '../components/Container'
import LoadingModal from "../components/modal/LoadingModal";
import { useCatalogo, useCustomQuery, useUpdateCatalogos } from "../Hooks/useApis";
import ListaMapa from "../components/ListaMapa";
import {motion} from "framer-motion"

const AltaProducto=(props)=> {
  const producto = props.producto, 
  Categorias= props.Categorias;

  const dat= useRef({lineas:[],marcas:[],colores:{},coloresmi:{},coloreslc:{},lctipos:[],armazonformas:[],armazonmateriales:[],micamateriales:[],micatipos:[],micatratamientos:[],servicioarmazon:{},serviciomica:{},servicioagregados:{},serviciotinte:{},servicios:[]});
  const array = ["COLORES","COLORESMI","COLORESLC","LCTIPOS","ARMAZONFORMAS","ARMAZONMATERIALES","LINEAS","MARCAS","MICAMATERIALES","MICATIPOS","MICATRATAMIENTOS","SERVICIOARMAZON","SERVICIOMICA","SERVICIOAGREGADOS","SERVICIOTINTE","SERVICIOS"];
  const title = useRef("Sin Datos"), input_modelo = useRef(""), input_descA = useRef(""), input_prefij = useRef(""),checkSM=useRef(""),checkSA=useRef(""),check_generico=useRef(""),check_varilla=useRef("");
  const abcedario = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG'];

  const [peticion, setpeticion] = useState([]),
    [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
    [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
    [modalLoading, setmodalLoading] = useState({}),
    [respuesta, setrespuesta] = useState(null),
    [peticionLinea,setPeticionLinea] =  useState({}),
    [descAdicional,setDescAdicional] = useState(""),
    [updateCat,setUpdate]=useState(false),
    [cont,setCont]=useState(0),
    [checkAdds,setCheckadds]=useState({}),
    [codigo,setCodigo]=useState({codigo:"",descripcion:""});


  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  const handleSuccessfulCatalogo=(respuesta)=>{
    dat.current[respuesta.id.toLowerCase()] = respuesta.contenido;
    setUpdate(!updateCat);
    //props.updateCatalogos(respuesta.id,respuesta.contenido);
    props.setCategoria("");
    closemodalLoading();
  }
  const handleSuccessfulCatalogosServ=(respuesta)=>{
    dat.current[respuesta.id.toLowerCase()] = respuesta.contenido;
    setUpdate(!updateCat);
    //props.updateCatalogos(respuesta.id,respuesta.contenido);
    props.setCategoria("");
  }

  const handleSuccessfulProducto = (text) => {
      setrespuesta({ status: 200, tamaño: respuesta.tamaño+1 });
  };

  const handleErrorProducto = (text) => {
    closemodalLoading();
    setrespuesta({ status: 400, tamaño: respuesta.tamaño+1 });
    setmodaldangererrorConfig({
      isOpen: true,
      message: text?.response?.data?.message || "Error al Insertar Producto" ,
    });
  };

  const handleError = (text) => {
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: text?.response?.data?.message || text ,
    });
  };

  const handleSuccessfulLinea = (text) => {
    setPeticionLinea({})
    FinishInsert();
    setmodalSuccessConfig({
      isOpen: true,
      message: text,
    });
  };
  const handleErrorLinea = (text) => {
    closemodalLoading();
    setPeticionLinea({})
    //
    setmodaldangererrorConfig({
      isOpen: true,
      message: text?.response?.data?.message || "Error al insertar Linea",
    });
  };

  const funcionError=(error)=>{
    setCont(0);
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: (error||"")+" Error al cargar el catalogos",
    });
  }

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true, title });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };

  const FinishInsert=()=>{
    closemodalLoading();
    setpeticion([]);
    setrespuesta(null);
    setDescAdicional("");
    props.setCategoria("");
    setmodalSuccessConfig({
      isOpen: true,
      message: "Productos Agregados",
    });
  }

  const validar = (producto) => {
    let peticion = [], json = {};
    
    if (!producto.categoria?.clave || !producto.marca?.clave || !producto.linea?.clave) {
      handleError("Los Campos (Categoria Marca y Linea) son Obligatorios");return;
    }
    switch (producto.categoria.clave) {
      case "MI":
        if (!producto?.forma || !producto?.material || !producto?.tipo) {
          handleError("Falta los siguientes parametros: ("+(!producto?.forma?" FORMA ":"")+(!producto?.tipo?" TIPO ":"")+(!producto?.material?" MATERIAL ":"")+")");  return;
        }
        if(producto?.checkcolor==true&&!producto?.color?.length){ handleError("El Campo Color es Obligatorio"); return; }
        if (producto.forma === "TERMINADA") {
          if(isNaN(producto?.esferainicial) || isNaN(producto?.esferafinal) || isNaN(producto?.esferasalto) || isNaN(producto?.ciladdinicial) || isNaN(producto?.ciladdfinal) || isNaN(producto?.ciladdsalto) || !producto?.checksfi || !producto?.checksff || !producto?.checkciladdi || !producto?.checkciladdf){
            handleError("Falta los siguientes parametros: ("
            +(isNaN(producto?.esferainicial) ||!producto?.checksfi?" ESFERA_INICIAL ":"")
            +(isNaN(producto?.esferafinal)||!producto?.checksff?" ESFERA_FINAL ":"")
            +(isNaN(producto?.ciladdinicial)||!producto?.checkciladdi?" CILADD_INICIAL ":"")
            +(isNaN(producto?.ciladdfinal)||!producto?.checkciladdf?" CILADD_FINAL ":"")
            +(isNaN(producto?.salto_esfera)||isNaN(producto?.ciladdsalto)?" SALTO ":"")
            +")"); return;
          }
          const itemsGraduaciones = GeneraGraduaciones(producto);
          json["id"] =codigo.codigo;
          json["descripcion"] =  codigo.descripcion
          json["categoria"] = producto.categoria.valor;
          json["marca"] = producto.marca.valor;
          json["linea"] = producto.linea.valor ;
          json["search"] ="true";
          json["schema"] = {};
          

          if(producto?.checkcolor==true){
            json['colores']={};
            producto.color.forEach(color => {
              let clave_color = Object.keys(dat.current.coloresmi).find( (key) => dat.current.coloresmi[key] === color );
              json.colores[clave_color] = (producto.colorsdesc?.[color] || color);

              Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
                json.schema[codigo.codigo + clave_color + signo_esfera]={};
              })
              
              itemsGraduaciones.ciladds.map(ciladd=>{
                Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{ 
                  json.schema[codigo.codigo + clave_color + signo_esfera][ciladd]={};
                })
                Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
                  itemsGraduaciones.esferas[signo_esfera].map(esfera=>{
                    const col = producto.colorsdesc[color] || color;

                  json.schema[codigo.codigo + clave_color + signo_esfera][ciladd][esfera]=codigo.codigo + clave_color  + esfera + ciladd
                  peticion.push({
                    id: codigo.codigo + clave_color + esfera + ciladd,
                    categoria: producto.categoria.valor,
                    marca: producto.marca.valor,
                    linea: producto.linea.valor,
                    descripcion: codigo.descripcion + " " + col,
                    forma: producto.forma,
                    material: producto.material.valor,
                    tipo: producto.tipo,
                    tratamiento: producto.micatratamiento,
                    caracteristicas: col,
                    color: color,

                    esfera: esfera.toString(),
                    ciladd: ciladd.trim(),
                    medida: esfera+ciladd,
                  })
                })
                })
              })
            });
          }else{
            Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
              json.schema[codigo.codigo + signo_esfera]={};
            })
  
            itemsGraduaciones.ciladds.map(ciladd=>{
              Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
                json.schema[codigo.codigo + signo_esfera][ciladd]={};
              })
              Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
                itemsGraduaciones.esferas[signo_esfera].map(esfera=>{
                json.schema[codigo.codigo + signo_esfera][ciladd][esfera]=codigo.codigo + esfera + ciladd
                peticion.push({
                  id: codigo.codigo + esfera + ciladd,
                  categoria: producto.categoria.valor,
                  marca: producto.marca.valor,
                  linea: producto.linea.valor,
                  descripcion: codigo.descripcion,
                  forma: producto.forma,
                  material: producto.material.valor,
                  tipo: producto.tipo,
                  tratamiento: producto.micatratamiento,
                  //modelos:elementos,
                  esfera: esfera.toString(),
                  ciladd: ciladd.trim(),
                  medida: esfera+ciladd,
                })
               })
              })
            })
          }
        }else {
          json = {};
          if(!producto?.forma || !producto?.micabase?.length || isNaN(producto?.ciladdinicial) || isNaN(producto?.ciladdfinal) || isNaN(producto?.ciladdsalto) || !producto?.checksfi || !producto?.checkciladdi || !producto?.checkciladdf){
            handleError("Falta los siguientes parametros: ("
            +(!producto?.forma?" FORMA ":"")
            +(!producto?.micabase?.length?" BASE ":"")
            +(!producto.checksfi?" SIGNO_ESFERA ":"")
            +(isNaN(producto.ciladdinicial)||!producto.checkciladdi?" CILADD_INICIAL ":"")
            +(isNaN(producto.ciladdfinal)||!producto.checkciladdf?" CILADD_FINAL ":"")
            +(isNaN(producto.ciladdsalto)?" SALTO ":"")
            +")"); return;
          }

          let itemsGraduaciones = GeneraGraduaciones(producto);
          json["id"] = codigo.codigo;
          json["descripcion"] = codigo.descripcion;
          json["categoria"] = producto.categoria.valor;
          json["marca"] = producto.marca.valor;
          json["linea"] = producto.linea.valor ;
          json["search"] ="true";
          json["schema"] = {};

          if(producto?.checkcolor==true){
            json['colores']={};
            producto.color.forEach(color => {
              let clave_color = Object.keys(dat.current.coloresmi).find( (key) => dat.current.coloresmi[key] === color );
              json.colores[clave_color] = (producto.colorsdesc?.[color] || color);

              Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
                json.schema[codigo.codigo + clave_color + signo_esfera]={};
              })
              
              itemsGraduaciones.ciladds.map(ciladd=>{
                Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{ 
                  json.schema[codigo.codigo + clave_color + signo_esfera][ciladd]={};
                })
                Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
                  itemsGraduaciones.esferas[signo_esfera].map(esfera=>{
                  json.schema[codigo.codigo + clave_color + signo_esfera][ciladd][esfera]=codigo.codigo + clave_color  + esfera + ciladd.trim()
                  peticion.push({
                    id: codigo.codigo + clave_color + esfera + ciladd.trim(),
                    categoria: producto.categoria.valor,
                    marca: producto.marca.valor,
                    linea: producto.linea.valor,
                    descripcion: codigo.descripcion + " " + (producto.colorsdesc?.[color] || color) ,
                    forma: producto.forma,
                    material: producto.material.valor,
                    tipo: producto.tipo,
                    tratamiento: producto.micatratamiento,
                    caracteristicas: (producto.colorsdesc?.[color] || color),
                    color: color,
                    
                    esfera: esfera.toString(),
                    ciladd: ciladd.trim(),
                    medida: esfera+ciladd,
                  })
                })
                })
              })
            });
          }else{
            Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
              json.schema[codigo.codigo + signo_esfera]={};
            })

            itemsGraduaciones.ciladds.map(ciladd=>{
              Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
                json.schema[codigo.codigo + signo_esfera][ciladd]={};
              })
              Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
                itemsGraduaciones.esferas[signo_esfera].map(esfera=>{
                json.schema[codigo.codigo + signo_esfera][ciladd][esfera]=codigo.codigo + esfera + ciladd.trim()
                peticion.push({
                  id: codigo.codigo + esfera + ciladd.trim(),
                  categoria: producto.categoria.valor,
                  marca: producto.marca.valor,
                  linea: producto.linea.valor,
                  descripcion: codigo.descripcion,
                  forma: producto.forma,
                  material: producto.material.valor,
                  tipo: producto.tipo,
                  tratamiento: producto.micatratamiento,
                  //modelos:elementos,
                  esfera: esfera.toString(),
                  ciladd: ciladd.trim(),
                  medida: esfera+ciladd,
                })
              })
              })
            })
          }
        }
        break;
  
      case "AR":
        if (!producto?.material?.clave||!producto?.lote ){ handleError("Los Campos MATERIAL y LOTE Son Obligatorios)");return;}
        if(producto.generico){
          peticion.push({
          id: codigo.codigo,
          categoria: producto.categoria.valor,
          marca: producto.marca.valor,
          linea: producto.linea.valor,
          descripcion: codigo.descripcion,
          material: producto.material.valor,
          materialv: producto?.materialv||false,
          search :"true",
          lote : producto.lote,
          });
        }else{
          if (!producto?.material?.clave || !producto?.modelo){handleError("Los Campos ("+(!producto?.material?.clave?" MATERIAL":"")+(!producto?.modelo?" MODELO":"") + " Son Obligatorios)");return; }
          json = {};
          json["id"] =codigo.codigo;
          json["descripcion"] = codigo.descripcion;
          json["categoria"] = producto.categoria.valor;
          json["marca"] = producto.marca.valor;
          json["linea"] = producto.linea.valor ;
          json["schema"] = {};
          json["lote"]= producto.lote;
          json["search"] ="true";
          json["material"] = producto.material.valor;
          json["materialv"]= producto?.materialv||false,
          json.schema[codigo.codigo]={};
          const pref = (producto?.prefijo?producto.prefijo:"");

          producto.modelo.map(modelo=>{
            peticion.push({
              id: codigo.codigo + pref + modelo.id ,
              categoria: producto.categoria.valor,
              marca: producto.marca.valor,
              linea: producto.linea.valor,
              descripcion: codigo.descripcion,
              material: producto.material.valor,
              materialv: producto?.materialv||false,
              caracteristicas: modelo,
              medida: pref + modelo.id.toString(),
            });
            json.schema[codigo.codigo][ pref + modelo.id]=codigo.codigo + pref + modelo.id ;
          });
        }
      break;
  
      case "LC":
        if(producto?.checkcolor==true&&!producto?.color?.length){ handleError("El Campo Color es Obligatorio"); return; }
        if(!producto?.tipo){handleError("El Campo Tipo es Obligatorio"); return;}
        if(isNaN(producto?.esferainicial) || isNaN(producto?.esferafinal) || isNaN(producto?.esferasalto) || isNaN(producto?.ciladdinicial) || isNaN(producto?.ciladdfinal) || isNaN(producto?.ciladdsalto) || !producto?.checksfi || !producto?.checksff || !producto?.checkciladdi || !producto?.checkciladdf){
          handleError("Falta los siguientes parametros: ("
          +(isNaN(producto?.esferainicial) ||!producto?.checksfi?" ESFERA_INICIAL ":"")
          +(isNaN(producto?.esferafinal)||!producto?.checksff?" ESFERA_FINAL ":"")
          +(isNaN(producto?.ciladdinicial)||!producto?.checkciladdi?" CILADD_INICIAL ":"")
          +(isNaN(producto?.ciladdfinal)||!producto?.checkciladdf?" CILADD_FINAL ":"")
          +(isNaN(producto?.salto_esfera)||isNaN(producto?.ciladdsalto)?" SALTO ":"")
          +")"); return;
        }
        json = {};
        const itemsGraduaciones = GeneraGraduacionesLC(producto);
        json["id"] = codigo.codigo;
        json["descripcion"] = codigo.descripcion  ,
        json["categoria"] = producto.categoria.valor;
        json["marca"] = producto.marca.valor;
        json["linea"] = producto.linea.valor ;
        json["search"] ="true";
        json["schema"] = {};

        if(producto?.checkcolor==true){
          json['colores']={};
          producto.color.forEach(color => {
            let clave_color = Object.keys(dat.current.coloreslc).find( (key) => dat.current.coloreslc[key] === color );
            json.colores[clave_color] = (producto.colorsdesc?.[color] || color);
            Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
              json.schema[codigo.codigo + clave_color + signo_esfera]={};
            })
            
            itemsGraduaciones.ciladds.map(ciladd=>{
              Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{ json.schema[codigo.codigo + clave_color + signo_esfera][ciladd]={};})
              Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
                itemsGraduaciones.esferas[signo_esfera].map(esfera=>{
                json.schema[codigo.codigo + clave_color + signo_esfera][ciladd][esfera]=codigo.codigo + clave_color  + esfera + ciladd
                peticion.push({
                  id: codigo.codigo + clave_color + esfera + ciladd,
                  categoria: producto.categoria.valor,
                  marca: producto.marca.valor,
                  linea: producto.linea.valor,
                  descripcion: codigo.descripcion + " " + (producto.colorsdesc?.[color] || color) + " " + producto.tipo,
                  caracteristicas: (producto.colorsdesc?.[color] || color),
                  color: color,
                  esfera: esfera,
                  ciladd: ciladd,
                  medida: esfera+ciladd,
                })
              })
              })
            })
          });
        }else{
          const new_cod = codigo.codigo+ "NE";
          json["id"] = new_cod;
          Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{json.schema[new_cod + signo_esfera]={};})
          itemsGraduaciones.ciladds.map(ciladd=>{
            Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{ json.schema[new_cod + signo_esfera][ciladd]={}; })
            Object.keys(itemsGraduaciones.esferas).map(signo_esfera=>{
              itemsGraduaciones.esferas[signo_esfera].map(esfera=>{
              json.schema[new_cod + signo_esfera][ciladd][esfera]=new_cod + esfera + ciladd
              peticion.push({
                id: new_cod + esfera + ciladd,
                categoria: producto.categoria.valor,
                marca: producto.marca.valor,
                linea: producto.linea.valor,
                descripcion: codigo.descripcion + " " + producto.tipo,
                esfera: esfera,
                ciladd: ciladd,
                medida: esfera+ciladd,
              })
            })
            })
          })
        }

      
      break;

      case "SE":
        if (!dat.current.servicios?.[producto.linea.clave]) {
          let newcodigos = [{
            id:codigo.codigo,
            descripcion: codigo.descripcion, 
            categoria: producto.categoria.valor,
            marca: producto.marca.valor,
            linea: producto.linea.valor
          }];
          json = {};
          json["id"] = codigo.codigo;
          json["descripcion"] = codigo.descripcion  ,
          json["categoria"] = producto.categoria.valor;
          json["marca"] = producto.marca.valor;
          json["linea"] = producto.linea.valor ;
          json["schema"] = {};

          if(producto.content.mica==true){
            json.schema["mica"]={};
            Object.keys(dat.current.serviciomica).map(mica=>{
              json.schema.mica[dat.current.serviciomica[mica]]=codigo.codigo+mica;
              newcodigos.push({
                  id:codigo.codigo+mica,
                  descripcion:dat.current.serviciomica[mica],
                  categoria: producto.categoria.valor,
                  marca: producto.marca.valor,
                  linea: producto.linea.valor
              })
            })
          }
          if(producto.content.armazon==true){
            json.schema["armazon"]={};
            Object.keys(dat.current.servicioarmazon).map(armazon=>{
              json.schema.armazon[dat.current.servicioarmazon[armazon]]=codigo.codigo+armazon;
              newcodigos.push({
                id:codigo.codigo+armazon,
                descripcion:dat.current.servicioarmazon[armazon], 
                categoria: producto.categoria.valor,
                marca: producto.marca.valor,
                linea: producto.linea.valor
              })
            })
          }
          json.schema["agregados"]={};
          Object.keys(checkAdds).map(add=>{
            json.schema.agregados[checkAdds[add]]=codigo.codigo+add;
            newcodigos.push({
                id:codigo.codigo+add,
                descripcion:checkAdds[add], 
                categoria: producto.categoria.valor,
                marca: producto.marca.valor,
                linea: producto.linea.valor
            })
          })

          if(producto.content.tinte==true){
            json.schema["tinte"]={};
            Object.keys(dat.current.serviciotinte).map(tinte=>{
              json.schema.tinte[dat.current.serviciotinte[tinte]]=codigo.codigo+tinte;
              newcodigos.push({
                id:codigo.codigo+tinte,
                descripcion:dat.current.serviciotinte[tinte], 
                categoria: producto.categoria.valor,
                marca: producto.marca.valor,
                linea: producto.linea.valor
              })
            })
          }
          newcodigos.map(item=>{
            peticion.push(item)
          })
          
      } else { handleError("Ya existe"); return; }
    break;
    default:
      if (!producto?.modelo) { handleError("El Campo Modelo es Obligatorio");return; }
      peticion.push({
        id: codigo.codigo,
        categoria: producto.categoria.valor,
        marca: producto.marca.valor,
        linea: producto.linea.valor,
        descripcion: codigo.descripcion,
        medida: producto.modelo[0],
        search :"true",
      });
    break;
    }
  
    let new_peticion = [],x = 0;
    while (x < peticion.length) {
      new_peticion.push(peticion.slice(x, x + 10));
      x = x + 10;
    }
    peticion = new_peticion;
    console.log('Json Bef: ',json)

    if(producto?.updateItem==true){  json={} }

    /*console.log(peticion);
    console.log(json)
    return*/

    setpeticion(peticion);setrespuesta({ status: 200, tamaño: 0 });
    title.current = "Subiendo articulos porfavor espere "+codigo.codigo;
    if(producto.categoria.clave=="SE"){
      const new_catalogo = [...dat.current.servicios];
      new_catalogo.push(producto.linea.valor);
      useUpdateCatalogos("SERVICIOS",new_catalogo,
      handleSuccessfulCatalogosServ,
      handleError);
    }
    setPeticionLinea(json);
    openmodalLoading();
  };

  useEffect(()=>{
    if(cont==0){return}
    if(cont>=array.length){ setCont(0); closemodalLoading(); return}
    
    useCatalogo(array[cont],(resp)=>{
          if(!resp?.opciones){
            funcionError();
              return;
          }
          const new_dat={...dat.current};
          new_dat[array[cont].toLowerCase()]=resp.opciones;
          dat.current = new_dat;
          Sleeping(50).then(response=>{ setCont(cont+1)});
    },funcionError);
  },[cont])

  useEffect(()=>{
    title.current="Cargando Catalogos"
    openmodalLoading();
    useCatalogo(array[0],(resp)=>{
          if(!resp?.opciones){
            funcionError();
              return;
          }
          const new_dat={...dat.current};
          new_dat[array[0].toLowerCase()]=resp.opciones;
          dat.current = new_dat;
          setCont(1);
    },funcionError);
  },[])

  useEffect(()=>{
    check_generico.current.checked=false;
    check_varilla.current.checked=false;
    input_modelo.current.value ="";
    input_descA.current.value = "";
    input_prefij.current.value="";
    checkSA.current.checked = false;
    checkSM.current.checked = false;
  },[producto.categoria])

  useEffect(()=>{
    if(producto.categoria?.clave!=='MI'){return;}
    if(producto?.forma=="BASE"){
      props.setProducto({...producto,checksfi:"+"})
    }
  },[producto?.forma])

  useEffect(()=>{
    if(!producto.categoria?.clave||!producto.linea?.clave){setCodigo({codigo:"",descripcion:""});return;}
    let newcodigo = producto.categoria.clave+producto.linea.clave,extra="";
    let des = producto.categoria.valor;
    switch (producto.categoria.clave){
    case "MI":
        if(producto?.forma=="BASE"){des="";}
        des += (producto?.forma?" "+producto.forma:"")  + (descAdicional?" "+descAdicional:"") + (producto?.material?.valor?" "+producto?.material?.valor:"") 
        + (producto?.tipo?" "+producto?.tipo:"") + (producto?.micatratamiento.length?" "+producto?.micatratamiento.join(' '):"") +(producto.marca?.valor=="GENERICO"||producto.marca?.valor=="INDIGO"?"":(" "+producto.marca?.valor||""));
        extra += (producto?.color?.length?" + COLOR":"")+ "+ GRADUACION";
      break;
    case "AR":
        des += (producto?.linea.valor?" "+producto.linea.valor:"") + (producto?.material?.valor?(producto.material.valor=="OTRO"?"": (" "+producto.material.valor)):"")+ (descAdicional?" "+descAdicional:"")
        newcodigo += (producto?.material?.clave?producto.material.clave:"")+(producto?.lote?producto.lote:"") ;
        extra += (producto?.lote?" + LOTE":"")+ " + MODELO";
      break;
    case "LC":
        des += (producto?.linea.valor?" "+producto.linea.valor:"") + (descAdicional?" "+descAdicional:"")
        newcodigo +=  (producto?.tipo?producto.tipo.charAt(0):"") ;
        extra += " + MODELO";
      break;
    case "SE":
        des += (producto?.linea.valor?" "+producto.linea.valor:"");
      break;

    default:
        des = (producto?.linea.valor?" "+producto.linea.valor:"") + (descAdicional?" "+descAdicional:"") +" "+ producto.modelo; 
        newcodigo += (producto?.modelo?.[0] || "");
    break;
    }
    setCodigo({codigo:newcodigo,descripcion:des,extra:extra})
  },[producto,descAdicional])

  useEffect(() => {
    if (peticion.length === 0) return; 
    if (respuesta.tamaño < peticion.length && respuesta.status === 200) {
      apiUseProd({
        url: "/insert-product",
        method: "POST",
        data: peticion[respuesta.tamaño],
        handleSuccessful: handleSuccessfulProducto,
        handleError: handleErrorProducto,
      });
    } else {
      if(peticionLinea?.id){
      apiUse({
        url: "/insert-linea",
        method: "POST",
        data: peticionLinea,
        handleSuccessful: handleSuccessfulLinea,
        handleError: handleErrorLinea,
      });
      }else{
        FinishInsert();
      }
    }
  }, [respuesta]);

  return (
  <>
    <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>{title.current}</LoadingModal.Title>
        </LoadingModal.Body>
    </LoadingModal>
    
    <Modaldanger config={modaldangererrorConfig}>
      <Modaldanger.Message title={"Error"}>
        {modaldangererrorConfig.message}
      </Modaldanger.Message>
      <Modaldanger.Buttons>
        <button
        type="button"
        className="btn-gray2 "
        onClick={closeModaldangererror}
        >
          Cerrar
        </button>
      </Modaldanger.Buttons>
    </Modaldanger>

    <Modalsuccess config={modalSuccessConfig}>
      <Modalsuccess.Body title={modalSuccessConfig.title}>
        Producto Guardado Con Exito
      </Modalsuccess.Body>
      <Modalsuccess.Buttons>
        <button
        type="button"
        className="btn-gray2 "
        onClick={closeModalsuccess}
        >
          Cerrar
        </button>
      </Modalsuccess.Buttons>
    </Modalsuccess>
    <Container lg="w-4/6 mx-auto">
      <div
      className="h-full mx-1 md:mx-0 items-center flex flex-col gap-3"
      id="formaltaproducto"
      >
        <div className="flex flex-col mx-auto items-center">
          <label className="textf text-center md:text-base block">{"categoria (maker)"}</label>
          <Selectormap
          width="w-full md:w-80"
          catalogo={Categorias.opciones}
          clean={(JSON.stringify(producto.categoria)=="{}")}
          name="categoria"
          defaultValue={producto.categoria?.valor}
          getvalor={(valor) => {
            props.setCategoria(valor);
          }}
          />
        </div>
        <div className="flex flex-col mx-auto items-center" hidden={!producto.categoria?.clave}>
          <label className="textf text-center md:text-base block " onClick={e=>{
            //console.log(CreateCode(producto.marca.valor,4,dat.current.marcas));
            //console.log(dat.current)
          }}>marca o proveedor</label>
          <div className="w-full md:w-80 size-form gap-3">
            <InputSelectormap
            catalogo={dat.current.marcas}
            defaultValue={producto?.marca?.valor}
            reset={producto.categoria}
            name="marca"
            disabled={(producto.categoria?.clave=="SE"?true:false)}
            className={"w-full"}
            getvalor={(valor) => {
              props.setProducto({...producto,marca:valor});
            }}
            />
            <button
            type="button"
            hidden={(JSON.stringify(producto.marca)!=="{}"?(SearchValueInObject(dat.current.marcas,producto.marca.valor).length?true:false):true)}
              className="btn-blue " 
              onClick={(e) => { 
                if(!producto.marca?.valor){return;}
                title.current = "Subiendo Marca "+producto.marca.valor+" al catalogo";
                openmodalLoading();
                const new_catalogo = {...dat.current.marcas};
                new_catalogo[CreateCode(producto.marca.valor,4,dat.current.marcas)] = producto.marca.valor;
                useUpdateCatalogos("MARCAS",new_catalogo,
                handleSuccessfulCatalogo,
                handleError);
              }}
            >
              +
            </button>
          </div>
          <label className="textf text-center md:text-base block " onClick={e=>{
            //console.log(CreateCode(producto.linea.valor,6,dat.current.lineas))
          }}>{"linea (style)"}</label>
          <div className={"w-full md:w-80 size-form"}>
            <InputSelectormap
            defaultValue={producto.linea?.valor}
            catalogo={dat.current.lineas}
            name="linea"
            className={"w-full"}
            reset={producto.categoria}
            getvalor={(valor) => {
              const newprod = {...producto,linea:valor};
              if(producto.categoria?.clave=="SE"){
                if(valor?.clave){ 
                  if(dat.current.servicios?.[valor.clave]) {
                    newprod.content = {...dat.current.servicios[valor.clave]}; 
                  }else{
                   newprod.content = {};
                  }
                }//else{ delete newprod.content;}
              }
              props.setProducto(newprod);
            }}
            />
            <button
            type="button"
            hidden={(JSON.stringify(producto.linea)!=="{}"?(SearchValueInObject(dat.current.lineas,producto.linea.valor).length?true:false):true)}
              className="btn-blue " 
              onClick={() => { 
                if(!producto.linea?.valor){return;}
                title.current = "Subiendo Linea "+producto.linea.valor+" al catalogo";
                openmodalLoading();
                const new_catalogo = {...dat.current.lineas};
                new_catalogo[CreateCode(producto.linea.valor,6,dat.current.lineas)] = producto.linea.valor;
                useUpdateCatalogos("LINEAS",new_catalogo,
                handleSuccessfulCatalogo,
                handleError);
              }}
            >
              +
            </button>
          </div>

          <div hidden={producto.categoria?.clave === "AC" || producto.categoria?.clave === "EL" || producto.categoria?.clave === "EO"?false:true}>
            <label className="textf text-center md:text-base block ">modelo</label>
            <input 
            name="modelo"
            defaultValue={producto?.modelo}
            className="border-form w-full uppercase"
            ref={input_modelo}
            onChange={(e)=>{
              const newprod = {...producto};
              if(e.target.value==""){delete newprod.modelo; props.setProducto(newprod); return;}
              newprod.modelo= [e.target.value.toUpperCase()];
              props.setProducto(newprod);
            }}
            />
          </div>


          <div hidden={(producto.categoria?.clave === "MI" && producto?.marca?.clave && producto?.linea?.clave ? false : true)}>
            <label className="textf text-center md:text-base block ">material</label>
            <Selectormap
            catalogo={dat.current.micamateriales}
            name={"material"}
            defaultValue={producto?.material?.valor}
            reset={producto.categoria}
            getvalor={(valor) => {
              props.setMica({...producto,material:valor});
            }}
            />
            <label className="textf text-center md:text-base block ">tipo</label>
            <Selectormap
            catalogo={dat.current.micatipos}
            name={"tipo"}
            defaultValue={producto?.tipo}
            reset={producto.categoria}
            getvalor={(valor) => {
              if(valor?.valor){
                props.setProducto({...producto,tipo:valor.valor,checkciladdi:(valor.valor=="BIFOCAL"||valor.valor=="PROGRESIVO"?"+":"-"),checkciladdf:(valor.valor=="BIFOCAL"||valor.valor=="PROGRESIVO"?"+":"-")});
              }else{
                const newprod ={...producto,tipo:""}
                delete newprod.checkciladdi;
                delete newprod.checkciladdf;
                delete newprod.esferafinal;
                delete newprod.esferainicial;
                delete newprod.ciladdinicial;
                delete newprod.ciladdfinal;
                delete newprod.checksfi;
                delete newprod.checksff;
                delete newprod.ciladdsalto;
                delete newprod.esferasalto;
                props.setProducto(newprod);
              }
            }}
            />
            <label className="textf text-center md:text-base block ">tratamiento</label>
            <Multiselector
            selected={[]}
            disabled={false}
            savechange={(data) => {
              data? props.addMicatratamiento(data) : props.addMicatratamiento([]);
            }}
            name="trat"
            data={dat.current.micatratamientos}
            />
            <div className="size-form w-full items-center justify-center ">
              <label className="textf text-center md:text-base " >color</label>
              <input
                type="checkbox"
                className="h-5 w-5 ml-2 rounded-lg"
                id="custom-switch-colorMI"
                defaultChecked={producto?.checkcolor}
                onChange={(e) => {
                  const newprod = {...producto,checkcolor:e.target.checked};
                  if(e.target.checked==false){
                    delete newprod.color;
                    delete newprod.colorsdesc;
                  }
                  props.setProducto(newprod);
              }}/>
            </div>
            <div className={(producto?.checkcolor==true?"w-full mt-2":"hidden")}>
              <div className="w-full">
                <Multiselector
                selected={[]}
                disabled={false}
                savechange={(data) => {
                  const newprod = {...producto};
                  if(data.length>0){ 
                    const oldcoldes = (newprod?.colorsdesc?{...newprod.colorsdesc}:{});
                    newprod.color = data;
                    newprod.colorsdesc ={};
                    console.log(oldcoldes)

                    data.forEach(color=>{
                      newprod.colorsdesc[color] = oldcoldes?.[color] || "";
                    })
                  }else{
                    delete newprod.color;
                    delete newprod.colorsdesc;
                  }
                  props.setProducto(newprod);
                }}
                name="coloresmi"
                data={Object.keys(dat.current.coloresmi).map((key) => {
                  return dat.current.coloresmi[key];
                })}
                />
              </div>
              <div className="w-full mt-2">
               <ListaMapa
                savechange={(data) => {
                  props.setProducto({...producto,colorsdesc:data})
                }}
                disabled={true}
                lista={dat.current.coloresmi}
                data={producto?.colorsdesc}
                nameCampo="coloresDesc"/> 
              </div>
            </div>
            <div hidden={!producto.tipo}>
              <label className="textf text-center md:text-base block ">Forma</label>
              <CheckDual
              nombre="Forma"
              valor={producto?.forma}
              opcion1="Base"
              opcion2="Terminada"
              getvalor={(valor) => {
                const newprod ={...producto,forma:valor,graduado:true};
                delete newprod.esferafinal;
                delete newprod.esferainicial;
                delete newprod.ciladdinicial;
                delete newprod.ciladdfinal;
                delete newprod.checksfi;
                delete newprod.checksff;
                delete newprod.micabase;
                delete newprod.esferasalto;
                props.setProducto(newprod);
              }}
              />
              <div hidden={producto?.forma !== "TERMINADA"}>
                <InputGraduacion
                nombre="Esfera"
                defaultCheckGI={producto?.checksfi}
                defaultCheckGF={producto?.checksff}
                defaultGI={producto?.esferainicial}
                defaultGF={producto?.esferafinal}
                getcheckgradi={(valor) => {props.addChecksfi(valor); }}
                getInputGraduacioni={(valor) => {props.setProducto({...producto,esferainicial:valor})}}
                getcheckgradf={(valor) => {props.addChecksff(valor);}}
                getInputGraduacionf={(valor) => {props.addEsferaf(valor);}}
                getsalto={(valor) => {props.addSaltosfr(valor);}}
                />
              </div>
              <div hidden={producto?.forma !== "BASE"}>
                <label className="textf text-center md:text-base block ">base</label>
                <Base
                bases={producto?.micabase}
                getbase={(valor) => {
                  props.addBase(valor);
                }}
                />
                <div className="flex flex-wrap justify-center w-full">
                  <label className="textf text-center md:text-base mr-2">graduado</label>
                  <input
                  type="checkbox"
                  className="h-5 w-5"
                  checked={(producto?.graduado?producto.graduado:false)}
                  onChange={(e) => {
                    props.setgraduado(e.target.checked);
                    if (e.target.checked) {return;}
                    const signo_cil = (producto?.tipo=="MONOFOCAL"?"-":"+");

                    props.addCheckciladdi(signo_cil);
                    props.addCheckciladdf(signo_cil);              
                    props.addCiladdi(0);
                    props.addCiladdf(0);
                    props.addsalto_ciladd(0.25);
                  }}
                  />
                </div>
              </div>
            </div>
            <div hidden={producto.forma !== "BASE" &&producto.forma !== "TERMINADA"}>
              <div hidden={producto?.graduado?!producto.graduado:true }>
                <InputGraduacion
                nombre="Cil/Add"
                signo_igual={true}
                disabled={(producto?.tipo?(producto.tipo === "BIFOCAL" || producto.tipo === "PROGRESIVO"?"+":"-"):"")}

                defaultCheckGI={producto?.checkciladdi}
                defaultCheckGF={producto?.checkciladdf}

                defaultGI={producto?.ciladdinicial}
                defaultGF={producto?.ciladdfinal}

                getcheckgradi={(valor) => {props.addCheckciladdi(valor);}}
                getcheckgradf={(valor) => {props.addCheckciladdf(valor);}}

                getInputGraduacioni={(valor) => {props.addCiladdi(valor);}}
                getInputGraduacionf={(valor) => { props.addCiladdf(valor);}}
                getsalto={(valor) => {props.addsalto_ciladd(valor);}}
                />
              </div>
            </div>
          </div>


          <div className={(producto.categoria?.clave == "LC" && producto?.marca.valor && producto.linea?.valor?"flex flex-col w-full md:px-8 items-center ":"hidden")} >
            <div className="size-form w-full items-center justify-center">
              <label className="textf text-center md:text-base " >color</label>
              <input
                type="checkbox"
                className="h-5 w-5 ml-2 rounded-lg"
                id="custom-switch-colorLC"
                defaultChecked={producto?.checkcolor}
                onChange={(e) => {
                  const newprod = {...producto,checkcolor:e.target.checked};
                  if(e.target.checked==false){
                    delete newprod.color;
                    delete newprod.colorsdesc;
                  }
                  props.setProducto(newprod);
              }}/>
            </div>
            <div className={(producto?.checkcolor==true?"w-full mt-2":"hidden")}>
              <div className="w-full">
                <Multiselector
                selected={[]}
                disabled={false}
                savechange={(data) => {
                  const newprod = {...producto};
                  if(data.length>0){ 
                    const oldcoldes = (newprod?.colorsdesc?{...newprod.colorsdesc}:{});
                    newprod.color = data;
                    newprod.colorsdesc ={};
                    console.log(oldcoldes)

                    data.forEach(color=>{
                      newprod.colorsdesc[color] = oldcoldes?.[color] || "";
                    })
                  }else{
                    delete newprod.color;
                    delete newprod.colorsdesc;
                  }
                  props.setProducto(newprod);
                }}
                name="coloreslc"
                data={Object.keys(dat.current.coloreslc).map((key) => {
                  return dat.current.coloreslc[key];
                })}
                />
              </div>
              <div className="w-full mt-2">
               <ListaMapa
                savechange={(data) => {
                  props.setProducto({...producto,colorsdesc:data})
                }}
                disabled={true}
                lista={dat.current.coloreslc}
                data={producto?.colorsdesc}
                nameCampo="coloresDesc"/> 
              </div>
            </div>
            <label className="textf text-center md:text-base block ">tipo</label>
            <Selectormap
            catalogo={dat.current.lctipos}
            name={"tipo"}
            defaultValue={producto?.tipo}
            reset={producto.categoria}
            getvalor={(valor) => {
              const newprod ={...producto}
              if(valor?.valor){
                newprod.tipo=valor.valor;

                if(valor.valor=="NEUTRO"){
                  delete newprod.graduado ;
                  newprod.checksfi = "-";newprod.checksff = "-";
                  newprod.esferainicial = 0;newprod.esferafinal = 0;
                  newprod.esferasalto = 0.25;

                  newprod.checkciladdi="-";newprod.checkciladdf="-";
                  newprod.ciladdinicial=0; newprod.ciladdfinal=0;
                  newprod.ciladdsalto=0.25;

                }else if(valor.valor=="ESFERICO"){
                  newprod.graduado = true;
                  delete newprod.checksff; delete newprod.checksfi;
                  delete newprod.esferainicial; delete newprod.esferafinal;
                  delete newprod.ciladdinicial;delete newprod.ciladdfinal;
                  newprod.esferasalto = 0.25;
                  newprod.checkciladdi="-";newprod.checkciladdf="-";
                  newprod.ciladdinicial=0; newprod.ciladdfinal=0;
                  newprod.ciladdsalto=0.25;
                }else{
                  newprod.graduado = true;
                  delete newprod.checksff; delete newprod.checksfi;
                  delete newprod.esferainicial; delete newprod.esferafinal;
                  delete newprod.ciladdinicial;delete newprod.ciladdfinal;

                  newprod.esferasalto = 0.25;
                  newprod.checkciladdi = (valor.valor=="MULTIFOCAL"?"+":"-"); newprod.checkciladdf = (valor.valor=="MULTIFOCAL"?"+":"-");
                  newprod.ciladdsalto=0.25;
                }
                props.setProducto(newprod);
              }else{
                newprod.tipo="";
                delete newprod.checkciladdi;
                delete newprod.checkciladdf;
                delete newprod.esferafinal;
                delete newprod.esferainicial;
                delete newprod.ciladdinicial;
                delete newprod.ciladdfinal;
                delete newprod.checksfi;
                delete newprod.checksff;
                delete newprod.ciladdsalto;
                delete newprod.esferasalto;
                props.setProducto(newprod);
              }
            }}
            />
            <span hidden={!producto?.graduado}>
              <InputGraduacion
              nombre="Esfera"
              defaultCheckGI={producto?.checksfi}
              defaultCheckGF={producto?.checksff}
              defaultGI={producto?.esferainicial}
              defaultGF={producto?.esferafinal}
              getcheckgradi={(valor) => {props.addChecksfi(valor); }}
              getInputGraduacioni={(valor) => {props.setProducto({...producto,esferainicial:valor})}}
              getcheckgradf={(valor) => {props.addChecksff(valor);}}
              getInputGraduacionf={(valor) => {props.addEsferaf(valor);}}
              getsalto={(valor) => {props.addSaltosfr(valor);}}
              />
              <InputGraduacion
                nombre="Cil/Add"
                signo_igual={true}
                disabled={(producto?.tipo?(producto.tipo === "MULTIFOCAL" ?"+":"-"):"")}
                disabledlInput={(producto?.tipo?(producto.tipo === "ESFERICO" ?true:false):false)}
                defaultCheckGI={producto?.checkciladdi}
                defaultCheckGF={producto?.checkciladdf}

                defaultGI={producto?.ciladdinicial}
                defaultGF={producto?.ciladdfinal}

                getcheckgradi={(valor) => {props.addCheckciladdi(valor);}}
                getcheckgradf={(valor) => {props.addCheckciladdf(valor);}}

                getInputGraduacioni={(valor) => {props.addCiladdi(valor);}}
                getInputGraduacionf={(valor) => { props.addCiladdf(valor);}}
                getsalto={(valor) => {props.addsalto_ciladd(valor);}}
              />
            </span>
          </div>


          <div className={(producto?.categoria?.clave == "AR" && producto?.marca?.clave && producto?.linea?.clave ?"flex flex-col items-center w-full md:px-8":"hidden")}>
            <label className="textf text-center md:text-base block  ">MATERIAL</label>
            <div className="size-form w-full md:w-80">
              <InputSelectormap
              catalogo={dat.current.armazonmateriales}
              defaultValue={producto?.material?.valor}
              reset={producto.categoria}
              name="material"
              className={"w-full"}
              onBlurI={val=>{
                if(val?.clave&&producto?.categoria?.clave&&producto?.linea?.clave){
                  const peticion ={ 
                    indexName: "lineaSearchIndex",
                    hashKey: {valor:producto.linea.valor,nombre:"linea"},
                    sortKey: {valor:"true",nombre:"search",operador:"eq"},
                    filters:[{valor:val.valor,nombre:"material",operador:"eq"}],
                  };
                  useCustomQuery(peticion,resp=>{
                    let new_lote = "A",new_lotes=[];
                    if(resp?.datos.length>0){
                       resp.datos.forEach(item=>{
                        if(item?.lote){new_lotes.push(item.lote);}
                      })
                      if(new_lotes.length>0){
                        new_lotes = new_lotes.sort();
                        const found = abcedario.indexOf(new_lotes[new_lotes.length-1]);
                        if(found+1<=abcedario.length){
                          new_lote = abcedario[found+1];
                        }else{
                          console.log('agregar mas letras')
                        }
                      }
                    }
                    const newprod ={...producto,lote:new_lote};
                    props.setProducto(newprod);
                  },error=>{
                    props.setCategoria("");
                  });
                }
              }}
              getvalor={(valor) => {
                props.setProducto({...producto,material:valor});
              }}
              />
              <button
              type="button"
              hidden={(producto?.material?(JSON.stringify(producto.material)!=="{}"?(SearchValueInObject(dat.current.armazonmateriales,producto.material.valor).length?true:false):true):true)}
              className="btn-blue " 
              onClick={() => { 
                if(!producto.material?.valor){return;}
                title.current = "Subiendo Material "+producto.material.valor+" al catalogo";
                openmodalLoading();
                const new_catalogo = {...dat.current.armazonmateriales};
                new_catalogo[CreateCode(producto.material.valor,2,dat.current.armazonmateriales)] = producto.material.valor;
                useUpdateCatalogos("ARMAZONMATERIALES",new_catalogo,
                handleSuccessfulCatalogo,
                handleError);
              }}>
                +
              </button>
            </div>
            <label className="textf text-center md:text-base block ">Lote</label>
            <input 
            type="text" 
            className="w-full md:w-48 border-form"
            value={producto?.lote}
            pattern={producto?.updateItem!==true?"[A-Z]{0,2}":"[A-Z0-9]{0,2}"}
            onChange={e=>{
              e.target.value = e.target.value.toUpperCase();
              if(!e.target.validity.valid){return;}
              const newprod ={...producto,lote:e.target.value};
              props.setProducto(newprod);
            }}
            />
            <div className="flex flex-wrap w-full font-semibold justify-center md:mt-2">
              <label>Diferente Varilla</label>
              <input
              type="checkbox"
              className="  h-5 w-5 mt-1 ml-2 rounded-lg"
              id={"radiomaterial"}
              ref={check_varilla}
              onChange={e=>{
                const newprod ={...producto,materialv:e.target.checked};
                props.setProducto(newprod);
              }}/>
            </div>
            <div className="flex flex-wrap w-full font-semibold justify-center md:mt-2">
              <label>ARMAZON GENERICO</label>
              <input
                type="checkbox"
                className="h-5 w-5 mt-1 ml-2 rounded-lg"
                id={"radiogenerico"}
                checked={producto?.generico}
                ref={check_generico}
                onChange={e=>{
                  const newprod ={...producto, generico: e.target.checked };
                  props.setProducto(newprod);
                }}
              />
            </div>
            <div className={(!producto?.generico?"w-full flex flex-col items-center md:px-8":"hidden")}>
              <label className="textf text-center md:text-base block ">MODELOS</label>
              <div className="w-full size-form items-center justify-center">
                <label className="textf">Prefijo:</label>
                <input 
                className="border-form uppercase w-full md:ml-1 md:w-14" 
                type="text"
                ref={input_prefij}
                onChange={e=>{
                  let prefijo = "";
                  if(e.target.value!=""){
                    if(producto?.updateItem==true&&producto.delPrefijo==true){
                      prefijo = e.target.value.toUpperCase().trim();
                    }else{
                      prefijo = e.target.value.toUpperCase().trim()+"-";
                    }
                  }
                  props.setProducto({...producto,prefijo:prefijo});
                }}
                />
                <InputModelo
                name="modelo"
                className={"md:w-44 md:ml-2"}
                reset={producto.categoria}
                producto={producto}
                getvalor={(valor) => {
                  props.addModelo(valor);
                }}
                />
              </div>

            </div>
          </div>
      

          <div hidden={(producto.categoria?.clave !== "SE"||!producto.linea?.clave?true:(dat.current.servicios.filter(serv=>serv==producto.linea.clave).length!==0?true:false))} >
            <div className={"w-full flex flex-col "+(dat.current.servicios?.[producto.linea?.valor] == undefined ?"flex flex-col":"hidden")}>
              <div className={"w-full font-semibold justify-center md:mt-2 flex flex-wrap"}>
                  <label>MATERIAL MICA</label>
                  <input
                    type="checkbox"
                    className="  h-5 w-5 mt-1 ml-2 rounded-lg"
                    id={"radio_serv_mi"}
                    ref={checkSM}
                    onChange={e=>{
                      const newprod ={...producto};
                      newprod.content["mica"] = e.target.checked;
                      props.setProducto(newprod);
                    }}
                  />
              </div>
              <div className={"w-full font-semibold justify-center md:mt-2 flex flex-wrap"}>
                  <label>MATERIAL ARMAZON</label>
                  <input
                    type="checkbox"
                    className="  h-5 w-5 mt-1 ml-2 rounded-lg"
                    id={"radio_serv_ar"}
                    ref={checkSA}
                    onChange={e=>{
                      const newprod ={...producto};
                      newprod.content["armazon"] = e.target.checked;
                      props.setProducto(newprod);
                    }}
                  />
              </div>
              <div className={"w-full flex flex-col items-center md:px-8"}>
                  <label className="font-semibold">EXTRAS</label>
                  <div className="size-form p-2 border-2 bg-sky-200 justify-between shadow-lg ">
                    {Object.keys(dat.current?.servicioagregados).length?
                      Object.keys(dat.current.servicioagregados).map((key,index)=>{
                        return (<div key={index} className="flex flex-col items-center justify-center md:px-2">
                          <motion.input
                          whileHover={{scale:1.2}}
                          type="checkbox"
                          id={"extra"+index}
                          className=" rounded-lg"
                          name="adds"
                          onChange={e=>{
                            const newCheck ={...checkAdds};
                            if(e.target.checked){
                              newCheck[key]=dat.current.servicioagregados[key];
                            }else{  delete newCheck[key];}
                            setCheckadds(newCheck);
                          }}
                          />
                          <label className="text-blue-900 text-size font-semibold">{dat.current.servicioagregados[key]}</label>
                          </div>)
                      })
                      :""
                    }         
                  </div>     
              </div>
              <div className={"w-full font-semibold justify-center md:mt-2 "+(producto.linea?.clave!=="TINTE"?"hidden":"flex flex-wrap")}>
                  <label>TINTE</label>
                  <input
                  type="checkbox"
                  className=" h-5 w-5 mt-1 ml-2 rounded-lg"
                  id={"radio_serv_ti"}
                  onChange={e=>{
                    const newprod ={...producto};
                    newprod.content.["tinte"] = e.target.checked;
                    props.setProducto(newprod);
                  }}
                  />
              </div>
            </div>
          </div>
          <div className={"w-full md:w-4/6 font-semibold justify-center md:mt-2 flex flex-wrap"}>
            <label className="textf text-center md:text-base block ">DESCRIPCION ADICIONAL</label>
            <input
            type="text"
            className="border-form w-full uppercase"
            ref={input_descA}
            onChange={e=>{
              setDescAdicional(e.target.value.toUpperCase())
            }}
            />
          </div>
          <div className={"w-full font-semibold justify-center md:mt-2 flex flex-wrap"}>
            <input readOnly={true} className=" font-bold text-lg text-center w-full outline-none md:mt-2 text-blue-700 " value={"CODIGO: " +codigo.codigo}/>
            <label className="w-full text-center text-lg font-bold md:mt-2 text-blue-700">DESCRIPCION</label>
            <label className="w-full text-center">{codigo.descripcion}</label>
          </div>
        </div>


        <div hidden={(producto?.categoria?.clave === "AR" && producto?.linea?.clave && !producto?.generico ? false: true)} className=" w-full">
          <Tablaarmazon
          FormasArmazon={dat.current.armazonformas}
          producto={producto}
          />
        </div>

        <div className="size-form w-full items-center justify-center">
              <label className="textf text-center md:text-base text-red-600  " >Actualizar Linea</label>
              <input
                type="checkbox"
                className="h-5 w-5 ml-2 rounded-lg"
                defaultChecked={false}
                onChange={(e) => {
                  const newprod = {...producto,updateItem:e.target.checked};
                  props.setProducto(newprod);
              }}/>
        </div>
        {producto?.updateItem?
          <div className="size-form w-full items-center justify-center">
          <label className="textf text-center md:text-base " >{"Quitar guion al modelo (-)"}</label>
          <input
            type="checkbox"
            className="h-5 w-5 ml-2 rounded-lg"
            defaultChecked={false}
            onChange={(e) => {
              const newprod = {...producto,delPrefijo:e.target.checked};
              props.setProducto(newprod);
          }}/>
        </div>
        :null
        }
      
        <div className="flex w-full justify-center md:mt-2 mb-2">
          <button
          type="button"
          className="btn-blue w-full md:w-1/4"
          style={{ transition: "all .15s ease" }}
          onClick={e=>{
            e.preventDefault();
            validar(producto);
          }}
          >
            Guardar
          </button>
        </div>
      </div>
    </Container>
  </>
  );
}

const GeneraGraduaciones = (producto) => {
  let esferaIgualFlag=false,array_ciladd = [],array_esferas=[];
  let graduacion_ciladd_inicial = producto.ciladdinicial,graduacion_ciladd_final = producto.ciladdfinal;
  let signo_esfera_inicial = producto.checksfi, signo_esfera_final = producto.checksff,signo_ciladd_inicial = producto.checkciladdi,signo_ciladd_final = producto.checkciladdf;
  
  array_esferas["+"]=[],  array_esferas["-"]=[];

  if(signo_esfera_inicial==signo_esfera_final){esferaIgualFlag=true;}
  if(graduacion_ciladd_inicial> graduacion_ciladd_final){
    const aux = graduacion_ciladd_inicial;
    graduacion_ciladd_inicial = graduacion_ciladd_final;
    graduacion_ciladd_final = aux;
  }
  for(let ciladd = graduacion_ciladd_inicial;ciladd<=graduacion_ciladd_final;ciladd+=producto.ciladdsalto){array_ciladd.push(signo_ciladd_inicial+parseFloat(ciladd).toFixed(2));}

  if(producto?.forma==='BASE'){
    producto.micabase.forEach((esfera) => { array_esferas["+"].push(esfera);});
    if(producto.graduado==false||(graduacion_ciladd_inicial==0&&graduacion_ciladd_final==0)){array_ciladd=[" "];}
  }else{
    let graduacion_esfera_inicial = producto.esferainicial,graduacion_esfera_final = producto.esferafinal;

    if(graduacion_esfera_inicial > graduacion_esfera_final){
      const aux = graduacion_esfera_inicial;
      graduacion_esfera_inicial = graduacion_esfera_final;
      graduacion_esfera_final = aux;
    }

    for(let esfera = graduacion_esfera_inicial;esfera<=graduacion_esfera_final;esfera+=producto.esferasalto){
      if(esferaIgualFlag){
        array_esferas[signo_esfera_final].push(signo_esfera_inicial+parseFloat(esfera).toFixed(2))
      }else{
        array_esferas["-"].push("-"+parseFloat(esfera).toFixed(2));
        array_esferas["+"].push("+"+parseFloat(esfera).toFixed(2));
      }
    }

    array_esferas["+"]=array_esferas["+"].filter(esfera=>((producto.tipo=="MONOFOCAL"?esfera!="+0.00":esfera!="-0.00")));
    array_esferas["-"]=array_esferas["-"].filter(esfera=>((producto.tipo=="MONOFOCAL"?esfera!="+0.00":esfera!="-0.00")));
  }
  if(!array_esferas["+"].length){delete array_esferas["+"]}
  if(!array_esferas["-"].length){delete array_esferas["-"]}

  return {esferas:array_esferas, ciladds:array_ciladd};
};

const GeneraGraduacionesLC = (producto) => {
  let esferaIgualFlag=false,array_ciladd = [],array_esferas=[];
  let graduacion_ciladd_inicial = producto.ciladdinicial,graduacion_ciladd_final = producto.ciladdfinal;
  let signo_esfera_inicial = producto.checksfi, signo_esfera_final = producto.checksff,signo_ciladd_inicial = producto.checkciladdi,signo_ciladd_final = producto.checkciladdf;
  
  array_esferas["+"]=[],  array_esferas["-"]=[];

  if(signo_esfera_inicial==signo_esfera_final){esferaIgualFlag=true;}
  if(graduacion_ciladd_inicial> graduacion_ciladd_final){
    const aux = graduacion_ciladd_inicial;
    graduacion_ciladd_inicial = graduacion_ciladd_final;
    graduacion_ciladd_final = aux;
  }
  for(let ciladd = graduacion_ciladd_inicial;ciladd<=graduacion_ciladd_final;ciladd+=producto.ciladdsalto){array_ciladd.push(signo_ciladd_inicial+parseFloat(ciladd).toFixed(2));}

    let graduacion_esfera_inicial = producto.esferainicial,graduacion_esfera_final = producto.esferafinal;

    if(graduacion_esfera_inicial > graduacion_esfera_final){
      const aux = graduacion_esfera_inicial;
      graduacion_esfera_inicial = graduacion_esfera_final;
      graduacion_esfera_final = aux;
    }

    for(let esfera = graduacion_esfera_inicial;esfera<=graduacion_esfera_final;esfera+=producto.esferasalto){
      if(esferaIgualFlag){
        array_esferas[signo_esfera_final].push(signo_esfera_inicial+parseFloat(esfera).toFixed(2))
      }else{
        array_esferas["-"].push("-"+parseFloat(esfera).toFixed(2));
        array_esferas["+"].push("+"+parseFloat(esfera).toFixed(2));
      }
    }

    array_esferas["+"]=array_esferas["+"].filter(esfera=>((producto.tipo=="MULTIFOCAL"?esfera!="-0.00":esfera!="+0.00")));
    array_esferas["-"]=array_esferas["-"].filter(esfera=>((producto.tipo=="MULTIFOCAL"?esfera!="-0.00":esfera!="+0.00")));

  if(!array_esferas["+"].length){delete array_esferas["+"]}
  if(!array_esferas["-"].length){delete array_esferas["-"]}

  return {esferas:array_esferas, ciladds:array_ciladd};
};

const mapStateToProps = (state) => ({
  producto: state.Productos,
  Categorias: state.Catalogos.CATEGORIAS,
  FormasArmazon: state.Catalogos.FORMASARMAZON,
});

const mapDispatchToProps = (dispatch) => ({
  setProducto: (producto) =>dispatch({ type: "SET_PRODUCTO", producto:producto}),
  setMica: (producto) => dispatch({type:"SET_MICA_PRODUCTO", producto:producto}),
  setCategoria:(categoria)=>dispatch({type:"SET_CATEGORIA_PRODUCTO",categoria:categoria}),
  setgraduado:(graduado)=>dispatch({type:"SET_GRADUADO_PRODUCTO",graduado:graduado}),
  updateCatalogos: (id,catalogo) =>dispatch({ type: "UPDATE_CATALOGO", id:id, catalogo: catalogo }),

  addSucursal: (element) =>dispatch({ type: "SET_ALTA_SUCURSAL", sucursal: element }),
  addPreciocompra: (element) =>dispatch({ type: "SET_ALTA_PRECIOCOMPRA", preciocompra: element }),
  addPrecioventa: (element) =>dispatch({ type: "SET_ALTA_PRECIOVENTA", precioventa: element }),
  addDescuentomax: (element) =>dispatch({ type: "SET_ALTA_DESCUENTOMAX", descuentomax: element }),
  addModelo: (element) => dispatch({ type: "SET_ALTA_MODELO", modelo: element }),

  addBase: (element) => dispatch({ type: "SET_ALTA_BASE", micabase: element }),
  addEsferai: (element) => dispatch({ type: "SET_ALTA_ESFERAI", esferainicial: element }),
  addEsferaf: (element) =>dispatch({ type: "SET_ALTA_ESFERAF", esferafinal: element }),
  addSaltosfr: (element) =>dispatch({ type: "SET_ALTA_ESFERASALTO", esferasalto: element }),
  addChecksfi: (element) => dispatch({ type: "SET_ALTA_CHECKSFI", checksfi: element }),
  addChecksff: (element) =>dispatch({ type: "SET_ALTA_CHECKSFF", checksff: element }),
  addCiladdi: (element) => dispatch({ type: "SET_ALTA_CILADDI", ciladdinicial: element }),
  addCiladdf: (element) =>dispatch({ type: "SET_ALTA_CILADDF", ciladdfinal: element }),
  addCheckciladdi: (element) =>dispatch({ type: "SET_ALTA_CHECKCILADDI", checkciladdi: element }),
  addCheckciladdf: (element) =>dispatch({ type: "SET_ALTA_CHECKCILADDF", checkciladdf: element }),
  addsalto_ciladd: (element) =>dispatch({ type: "SET_ALTA_CILADDSALTO", ciladdsalto: element }),

  addTipo: (element) => dispatch({ type: "SET_ALTA_TIPO", tipo: element }),
  addForma: (element) => dispatch({ type: "SET_ALTA_FORMA", forma: element }),
  addMicatratamiento: (element) =>dispatch({ type: "SET_ALTA_MICATRATAMIENTO", micatratamiento: element }),

  addMaterial: (element) =>dispatch({ type: "SET_ALTA_MATERIAL", material: element }),

  addCalidad: (element) => dispatch({ type: "SET_ALTA_CALIDAD", calidad: element }),

  addLcdurabilidad: (element) => dispatch({ type: "SET_ALTA_LCDURABILIDAD", lcdurabilidad: element }),
  addColor: (element) => dispatch({ type: "SET_ALTA_COLOR", color: element }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AltaProducto);
