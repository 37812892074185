import React from 'react'

const PagadoSvg = ({width,height,bg,text,contorno}) => {
  return (
    <svg 
    className={height+" "+width+" "+bg+" "+" fill-current stroke-current"}
    version="1.1" 
    fill="current" 
    stroke="current" 
    strokeWidth="w-2"
    xmlns="http://www.w3.org/2000/svg" 
    x="0px" 
    y="0px"
    viewBox="0 0 508.5 99"  >
<g className={"st0 "+text}>
<path d="M61.2,18.5c0-0.6,0.2-1.1,0.5-1.5c0.3-0.4,0.8-0.6,1.5-0.6h29.5c2.1,0,4.1,0.3,6,0.8c1.9,0.6,3.6,1.3,5.2,2.3
		c1.6,1,3,2.2,4.3,3.6c1.3,1.4,2.3,2.9,3.2,4.5c0.9,1.6,1.5,3.3,2,5c0.5,1.8,0.7,3.6,0.7,5.4c0,2.7-0.5,5.3-1.5,7.8
		c-1,2.5-2.4,4.8-4.3,6.8c-1.8,2-4.1,3.6-6.7,4.8c-2.6,1.2-5.5,1.9-8.6,1.9h-14v21.2c0,1.3-0.7,1.9-2.1,1.9H63.3
		c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.6-0.8-0.6-1.4V18.5z M96,38c0-0.9-0.2-1.8-0.5-2.7c-0.3-0.9-0.8-1.7-1.4-2.4
		c-0.6-0.7-1.4-1.3-2.2-1.7c-0.9-0.4-1.9-0.7-3-0.7h-9.8v14.9h9.8c1.1,0,2.1-0.2,3-0.6c0.9-0.4,1.6-1,2.2-1.7
		c0.6-0.7,1.1-1.5,1.4-2.4C95.9,39.9,96,39,96,38z"/>
	<path d="M136.9,18.2c0-0.2,0.1-0.4,0.3-0.6c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.5-0.2,0.8-0.2h11.8
		c0.5,0,1,0.2,1.3,0.5c0.4,0.3,0.6,0.6,0.8,0.8l1.2,2.5l24.3,60.2c0.2,0.6,0.2,1.1-0.1,1.4c-0.3,0.4-0.9,0.6-1.8,0.6h-13.3
		c-0.7,0-1.3-0.1-1.6-0.4c-0.4-0.3-0.7-0.7-0.9-1.3c-0.6-1.5-1.2-3-1.8-4.5c-0.6-1.5-1.2-3-1.8-4.5H133c-0.6,1.5-1.2,3-1.8,4.5
		c-0.6,1.5-1.2,3-1.8,4.5c-0.4,1.1-1.2,1.6-2.4,1.6h-13.6c-0.6,0-1.1-0.1-1.5-0.4c-0.4-0.3-0.5-0.7-0.2-1.3L136.9,18.2z M145.3,37.2
		c-0.6,1.5-1.2,3.1-1.8,4.9c-0.6,1.7-1.2,3.5-1.8,5.3c-0.6,1.8-1.2,3.5-1.8,5.3c-0.6,1.7-1.2,3.4-1.8,4.9h14.2L145.3,37.2z"/>
	<path d="M225.3,35.1c-1.6-1.1-3.3-1.9-5-2.5c-1.8-0.6-3.7-0.9-5.7-0.9c-2.6,0-4.9,0.5-7,1.4c-2.1,0.9-4,2.2-5.5,3.8
		c-1.5,1.6-2.7,3.5-3.6,5.6c-0.9,2.1-1.3,4.4-1.3,6.9s0.4,4.7,1.3,6.8c0.9,2.1,2.1,4,3.6,5.6c1.5,1.6,3.4,2.8,5.5,3.8
		c2.1,0.9,4.5,1.4,7,1.4c1.1,0,2.1-0.1,3.2-0.2c1.1-0.1,2.2-0.3,3.4-0.6V54.5c0-0.6,0.2-1,0.5-1.4c0.3-0.4,0.8-0.6,1.5-0.6h12.6
		c0.5,0,1,0.2,1.5,0.5c0.5,0.4,0.8,0.8,0.8,1.4v21.5c0,0.8-0.4,1.5-1.3,2c-1.4,0.9-3.1,1.8-4.8,2.4c-1.8,0.7-3.6,1.2-5.5,1.7
		c-1.9,0.4-3.8,0.8-5.8,1c-2,0.2-3.9,0.3-5.7,0.3c-3.3,0-6.4-0.4-9.4-1.2c-3-0.8-5.8-1.9-8.4-3.4c-2.6-1.5-5-3.3-7.1-5.3
		c-2.1-2.1-4-4.3-5.5-6.8c-1.5-2.5-2.7-5.2-3.6-8.1c-0.8-2.9-1.2-5.9-1.2-9.1c0-3.1,0.4-6.2,1.2-9.1c0.8-2.9,2-5.6,3.6-8.1
		c1.5-2.5,3.4-4.8,5.5-6.9c2.1-2.1,4.5-3.8,7.1-5.3c2.6-1.5,5.4-2.6,8.4-3.4c3-0.8,6.1-1.2,9.4-1.2c3.7,0,7.2,0.5,10.5,1.5
		c3.3,1,6.4,2.4,9.3,4.3c0.5,0.3,0.9,0.7,1.2,1.2c0.3,0.5,0.2,1-0.2,1.7l-7.5,10.4c-0.4,0.5-0.8,0.8-1.2,1
		C226.5,35.7,225.9,35.5,225.3,35.1z"/>
	<path d="M271,18.2c0-0.2,0.1-0.4,0.3-0.6c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.5-0.2,0.8-0.2h11.8
		c0.5,0,1,0.2,1.3,0.5c0.4,0.3,0.6,0.6,0.8,0.8l1.2,2.5L313,80.5c0.2,0.6,0.2,1.1-0.1,1.4c-0.3,0.4-0.9,0.6-1.8,0.6h-13.3
		c-0.7,0-1.3-0.1-1.6-0.4c-0.4-0.3-0.7-0.7-0.9-1.3c-0.6-1.5-1.2-3-1.8-4.5c-0.6-1.5-1.2-3-1.8-4.5h-24.5c-0.6,1.5-1.2,3-1.8,4.5
		c-0.6,1.5-1.2,3-1.8,4.5c-0.4,1.1-1.2,1.6-2.4,1.6h-13.6c-0.6,0-1.1-0.1-1.5-0.4c-0.4-0.3-0.5-0.7-0.2-1.3L271,18.2z M279.4,37.2
		c-0.6,1.5-1.2,3.1-1.8,4.9c-0.6,1.7-1.2,3.5-1.8,5.3c-0.6,1.8-1.2,3.5-1.8,5.3c-0.6,1.7-1.2,3.4-1.8,4.9h14.2L279.4,37.2z"/>
	<path d="M381.6,49.6c0,3.1-0.4,6.1-1.2,9c-0.8,2.8-2,5.5-3.6,7.9c-1.5,2.4-3.4,4.7-5.5,6.6c-2.1,2-4.5,3.7-7.1,5.1
		c-2.6,1.4-5.4,2.5-8.4,3.2c-3,0.8-6.1,1.1-9.4,1.1h-22.8c-0.6,0-1-0.1-1.5-0.4c-0.4-0.2-0.6-0.7-0.6-1.5V18.5
		c0-0.6,0.2-1.1,0.5-1.5c0.4-0.4,0.9-0.6,1.6-0.6h22.8c3.2,0,6.4,0.4,9.4,1.2c3,0.8,5.8,1.9,8.4,3.3c2.6,1.4,5,3.1,7.1,5.1
		c2.2,2,4,4.2,5.5,6.7c1.5,2.5,2.7,5.1,3.6,8C381.2,43.5,381.6,46.5,381.6,49.6z M339.3,32.4v34.2h3.9c0.7,0,1.4,0,2.1-0.1
		c0.7,0,1.4-0.1,2-0.2c2.3-0.2,4.5-0.7,6.5-1.6c2-0.9,3.8-2.1,5.2-3.6c1.5-1.5,2.7-3.2,3.5-5.2c0.8-2,1.3-4.2,1.3-6.5
		c0-2.5-0.5-4.8-1.5-6.9c-1-2.1-2.3-3.9-4-5.4c-1.7-1.5-3.6-2.7-5.8-3.6c-2.2-0.8-4.6-1.3-7.1-1.3H339.3z"/>
	<path d="M390.5,49.6c0-3.1,0.4-6.2,1.2-9.1c0.8-2.9,2-5.6,3.6-8.1c1.5-2.5,3.4-4.8,5.5-6.9c2.1-2.1,4.5-3.8,7.1-5.3
		c2.6-1.5,5.4-2.6,8.4-3.4c3-0.8,6.1-1.2,9.4-1.2c3.2,0,6.4,0.4,9.4,1.2c3,0.8,5.8,2,8.5,3.4c2.6,1.5,5,3.2,7.2,5.3
		c2.2,2.1,4,4.4,5.5,6.9c1.5,2.5,2.7,5.2,3.6,8.1c0.8,2.9,1.3,5.9,1.3,9.1c0,3.1-0.4,6.2-1.3,9.1c-0.8,2.9-2,5.6-3.6,8.1
		c-1.5,2.5-3.4,4.8-5.5,6.8c-2.2,2.1-4.5,3.8-7.2,5.3c-2.6,1.5-5.4,2.6-8.5,3.4c-3,0.8-6.2,1.2-9.4,1.2c-3.3,0-6.4-0.4-9.4-1.2
		c-3-0.8-5.8-1.9-8.4-3.4c-2.6-1.5-5-3.3-7.1-5.3s-4-4.3-5.5-6.8c-1.5-2.5-2.7-5.2-3.6-8.1S390.5,52.7,390.5,49.6z M408.4,49.5
		c0,2.5,0.4,4.8,1.3,6.9c0.9,2.1,2.1,4,3.6,5.6c1.6,1.6,3.4,2.8,5.5,3.7c2.1,0.9,4.5,1.4,7,1.4c2.6,0,4.9-0.5,7-1.4
		c2.1-0.9,4-2.1,5.5-3.7c1.6-1.6,2.8-3.4,3.6-5.6c0.9-2.1,1.3-4.4,1.3-6.9c0-2.4-0.4-4.7-1.3-6.9c-0.9-2.1-2.1-4-3.6-5.6
		c-1.6-1.6-3.4-2.9-5.5-3.8c-2.1-0.9-4.5-1.4-7-1.4c-2.6,0-4.9,0.5-7,1.4s-4,2.2-5.5,3.8c-1.6,1.6-2.8,3.5-3.6,5.6
		C408.8,44.8,408.4,47.1,408.4,49.5z"/>
</g>
  </svg>
  )
}

export default PagadoSvg