export const itemCompra = {index:0,id:"",descripcion : "",cantidad : 0, oldExistencia:0,guideline:0,importe : 0,precioCompra: 0,oldPrecioCompra: 0,precioCompraPromediado : 0,minPrecioVenta : 0,precioVenta : 0,comprable : true,vendible:true,type : ""};


export const ListaLaboratorio = ["BISEL","TALLADO"]

export const Regimen_Sat ={
  "601":"General de Ley Personas Morales",
  "612":"Personas Físicas con Actividades Empresariales y Profesionales",
  "603":"Personas Morales con Fines no Lucrativos",
  "605":"Sueldos y Salarios e Ingresos Asimilados a Salarios",
  "610":"Residentes en el Extranjero sin Establecimiento Permanente en México",
  "621":"Incorporación Fiscal",
  "626":"Régimen Simplificado de Confianza",
  "629":"De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
  "616":"Sin obligaciones fiscales",
  "625":"Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  "608":"Demás ingresos",
  "609":"Consolidación"
}

export const Persona_Sat={
  "PF":"PERSONA FISICA",
  "PM":"PERSONA MORAL"
}

export const Formas_Pago_Sat = {
  "PUE": "Pago en una sola exhibicion",
  "PPD": "Pago en parcialidades o diferido"
}

export const Metodo_Pago_Sat = {
"01":"Efectivo",
"02":"Cheque nominativo",
"03":"Transferencia electrónica de fondos",
"04":"Tarjeta de crédito",
"28":"Tarjeta de débito",
"30":"Aplicación de anticipos",
"99":"Por definir"
}

export const Clave_Unidad_Sat = {
  "H87":"Pieza",
  "E48":"Unidad de Servicio",
  "DPC":"Docenas de Piezas",
  "11":"Equipos",
  "ACT":"Actividad",
}

export const Motivos_Cancelacion = {
  "01":"01 Con Relacion",
  "02":"02 Sin Relacion",
  "03":"03 No existe la factura",
  "04":"04 Esta incluido en la factura global",
}

export const Clave_Indigo_Sat ={
  "ACCESORIO":{clave:"42142900",unidad:"H87"},
  "MICA":{clave:"42142902",unidad:"H87"},
  "ARMAZON":{clave:"42142903",unidad:"H87"},
  "LENTE DE CONTACTO":{clave:"42142913",unidad:"H87"},
  "EQUIPO OPTICO":{clave:"42183000",unidad:"H87"},
  "EQUIPO LABORATORIO":{clave:"23151700",unidad:"H87"},
  "SERVICIO":{clave:"73181000",unidad:"E48"},
  "FACTURA":{clave:"01010101",unidad:"ACT"},
}

export const Clave_Indigo_Sat_Short ={
  "AC":{clave:"42142900",unidad:"H87"},
  "MI":{clave:"42142902",unidad:"H87"},
  "AR":{clave:"42142903",unidad:"H87"},
  "LC":{clave:"42142913",unidad:"H87"},
  "EO":{clave:"42183000",unidad:"H87"},
  "EL":{clave:"23151700",unidad:"H87"},
  "SE":{clave:"73181000",unidad:"E48"},
  "FA":{clave:"01010101",unidad:"ACT"}
}

export const Uso_Cfdi={
  "G01":"Adquisición de mercancías",
  "G03":"Gastos en general",
  "I08":"Otra maquinaria y equipo",
  "G02":"Devoluciones, descuentos o bonificaciones",
  "I02":"Mobiliario y equipo de oficina por inversiones",
  "P01":"Por definir",
  "D01":"Honorarios médicos, dentales y gastos hospitalarios",
  "D02":"Gastos médicos por incapacidad o discapacidad",
  "D07":"Primas por seguros de gastos médicos",
  "S01":"Sin efectos fiscales",
  "I05":"Dados, troqueles, moldes, matrices y herramental",
}

export const Cfdi={
  Currency:"",
  Serie:"",
  CfdiType:"I",
  PaymentForm: "01",
  PaymentMethod:"",
  ExpeditionPlace:"",
  Sucursal:"",

  Issuer:{
    Name:"",
    Rfc:"",
    FiscalRegime:"",
  },

  Receiver:{
    Name:"",
    Rfc:"",
    FiscalRegime:"",
    CfdiUse:"",
    TaxZipCode:"",
    IdClient:"",
  },
  Items:[]
}
/*  Observations:"",
  Relations :"",
  PaymentBank:"",
  PaymentAccountNumber:"",
  URLogo:""
  */


  export const Cfdi_Global={
    Currency:"",
    Serie:"",
    CfdiType:"I",
    PaymentForm: "01",
    PaymentMethod:"PUE",
    ExpeditionPlace:"",
    Sucursal:"",

    GlobalInformation:{
      Periodicity :"",
      Months :"",
      Year :"",
    },

    Issuer:{
      Name:"",
      Rfc:"",
      FiscalRegime:"",
    },
  
    Receiver:{
      Name:"PUBLICO EN GENERAL",
      Rfc:"XAXX010101000",
      FiscalRegime:"616",
      CfdiUse:"S01",
      TaxZipCode:"",
      IdClient:"",
    },
    Items:[]
  }

export const Product_Cfdi={
  IdProduct:"",
  ProductCode:"",
  Description:"",
  UnitCode:"",
  UnitPrice:0,
  Quantity:0,
  Subtotal: 0,
  TaxObject: "02",
  Discount: 0,
  Total: 0,
  Taxes: []
}

export const Tax_Cfdi={
  Total:0,
  Name: "IVA",
  Base: 0,
  Rate: 0,
}

export const Relations =  {
  Type: "04",
  Cfdis: [],
}

export const userExtra=[
  "BISEL"
] 

export const mensajesGenerales={
  login:"LA SESION A CADUCADO, PORFAVOR VUELVA A INICIAR SESION",
  
}

export const permisos={
  altaCliente:"ALTA DE CLIENTES",
  altaProveedor:"ALTA DE PROVEEDORES",
  altaProds:"DAR DE ALTA PRODUCTOS",

  reportes:"VER REPORTES",
  configurar:"VER CONFIGURACIONES",

  desbPes:"DESBLOQUEAR PEDIDO ESPECIAL",
  vender:"VENDER PRODUCTOS",
  descuento:"APLICAR DESCUENTOS",
  cobrar:"COBRAR VENTAS (CAJA)",
  cancelVenta:"CANCELAR VENTAS",
  socios:"ADMINISTRAR SOCIOS",

  modCliente:"MODIFICAR CLIENTES",
  modUsuario:"MODIFICAR USUARIOS",
  modProveedor:"MODIFICAR PROVEEDORES",
  modSucursal:"MODIFICAR SUCURSALES",

  regMerma:"REGISTRAR MERMAS",
  regUsuario:"REGISTRAR USUARIOS NUEVOS",
  regSucursal:"REGISTRAR SUCURSALES",

  repPes:"REPORTE PEDIDO ESPECIAL",
}
