import React, { useState } from "react";
import Labelmoney from "../../labelmoney";

const TableServices = (props) => {
  const onclickRow = props.onclickRow || ((item) => {});
  const columns = props.columns || [{}];
  const data = props.data || [];
  const [field, setfield] = useState("");
  const [desc, setdesc] = useState(false);
  const [input, setinput] = useState("");
  const searchinput = props.searchinput === undefined ? true : props.searchinput;

  function byfield(a, b) {
    if (desc) {
      if (a[field] > b[field]) return 1;
      if (a[field] < b[field]) return -1;
    } else {
      if (a[field] < b[field]) return 1;
      if (a[field] > b[field]) return -1;
    }

    return 0;
  }

  function searchByAnyField(item) {
    let newsearch = input.replace('+','\\+');
    newsearch =  input.replace(/\\/g,'\\\\').toUpperCase();

    for (const value of Object.values(item)) {
      if (typeof value === "string" || value instanceof String) {
        if (value.toUpperCase().search(newsearch) !== -1) {
          return true;
        }
      }
    }
  }

  return (
    <div className="absolute flex flex-col break-words overflow-x-auto overflow-y-auto h-full w-full mx-auto landscape:mt-0">
      {searchinput ? (
        <div className="mt-1 flex sm:flex-row-reverse flex-col w-full">
          <div className="block relative">
            <input
              type="text"
              className="inputf focus:shadow-outline uppercase landscape:h-8"
              name="buscar"
              value={input}
              onChange={(e) => {
                setinput(e.target.value);
              }}
              placeholder="Buscar"
              style={{ transition: "all .15s ease" }}
            />
          </div>
        </div>
      ) : null}
      <div className="overflow-x-scroll overflow-y-auto mt-1 flex flex-col min-h-0 max-h-screen  min-w-0  max-w-screen mb-1 break-words rounded bg-ambar-100 border-0">
        <table>
          <thead className="sticky top-0 rounded bg-black text-ambar-200 text-sm font-semibold align-middle cursor-pointer uppercase">
            <tr>
              {columns
                ? columns.map((item, index) => (
                    <th
                      key={index}
                      onClick={(e) => {
                        setdesc(!desc);
                        setfield(item.accessor);
                      }}
                      className={
                        "py-1 border-l-2 border-r-2 break-words border-gray-300 "
                      }
                      data-priority={index}
                      hidden={item?.visible||false}
                    >
                      {item.header}
                    </th>
                  ))
                : null}
            </tr>
          </thead>
          <tbody>
            {/*className={(item?.status)?((item.status=="SOLICITADO")?"bg-gray-100":(item.status=="ACEPTADO"?"bg-yellow-100":(item.status=="ENVIADO"?"bg-green-100":(item.status=="PROCESO"?"bg-blue-100":(item.status=="RECIBIDO"?"bg-blue-200":(item.status=="ENTREGADO"?"bg-teal-200":(item.status=="GARANTIA"?"bg-yellow-200":"bg-purple-300"))))))):(index1 % 2 === 0 ? "bg-gray-100" : "bg-white") +" cursor-pointer hover:bg-indigo-200 "} */}
            {data
              ? data
                  .filter((item) => searchByAnyField(item))
                  .sort(byfield)
                  .map((item, index1) => (
                    
                    <tr
                      key={index1}
                      className={"cursor-pointer hover:bg-ambar-200 "+(item?.edit?"bg-sky-100":"")}
                      onClick={(e) => {
                        onclickRow(item);
                      }}
                    >
                      {props.columns.map((itemcolumn, index2) => (
                        <td
                          key={index2}
                          hidden={itemcolumn?.visible||false}
                          className={
                            itemcolumn.width +
                            " px-2 align-middle border border-gray-400 whitespace-nowrap text-size font-semibold"
                          }
                        >
                          {itemcolumn.code
                            ? itemcolumn.code(item,index1)
                            : item[itemcolumn.accessor]}
                        </td>
                      ))}
                    </tr>
                  ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableServices;
