import React, { useState } from 'react'
import { useCustomQuery, useDeleteCotizacion } from '../../Hooks/useApis';
import { FechaUTCToLocal, Sucursal, TransformSys, getIdUser, getSucursal, showMoney } from '../../services/functions';
import LabelFecha from '../LabelFecha';
import Labelmoney from '../labelmoney';
import Modalgeneric from '../modal/generic';
import Modalinfo from '../modal/info';
import TableModal from '../modal/TableModal';
import { useEffect } from 'react';
import { btnScale, btnTap } from '../../static/motionStyle';
import {motion} from "framer-motion"
import LoadingModal from '../modal/LoadingModal';

const Cotizaciones =({getCotizacion,stateCotizacion, setInputFocus,inputFocus})=> {
    const [dataCotizacion,setDataCotizacion]=useState([]),
    [modalTablaConfig, setmodalTablaConfig] = useState(false),
    [modalLoading, setmodalLoading] = useState({}),
    [modalinfoConfig, setmodalinfoConfig] = useState({}),
    [modalTicket,setmodalTicket]=useState({}),
    [Ticket,setTicketSelect]=useState({});

    const setInput = setInputFocus || null;

    const GetCotizaciones=(e)=>{
      let peticion ={
        indexName:"reverseIndex",
        hashKey:{valor:("COTIZACION#"+getSucursal()),nombre:"type"},
        filters:[{nombre:"vendedor",valor:getIdUser(),operador:"eq"}]
      }
      useCustomQuery(peticion,funcionExito,funcionError);
    }
  
    function closeModalinfo(e) { setmodalinfoConfig({ isOpen: false }); setInput?setInputFocus(!inputFocus):null;}
    function closeModaltabla(e) {setmodalTablaConfig({ isOpen: false }); setInput?setInputFocus(!inputFocus):null;}

    const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, title:titulo }); }
    const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); }
    
    const closeModalTicket=(e)=> {setmodalTicket({ isOpen: false }); setTicketSelect({});}

    const funcionExito=(respuesta)=>{
        if(respuesta?.datos?.length==0){
            setmodalinfoConfig({
                isOpen: true,
                title: "No se ha encontrado la clave en la base de datos.",
                message: "No Hay Cotizaciones'",
              });
            return;
        }
        if(respuesta?.datos?.length === 1){
            getCotizacion(respuesta.datos[0]);
            return;
        }
        setDataCotizacion(respuesta.datos);
        setmodalTablaConfig({ isOpen: true });
    }
    const funcionError=(error)=>{
        setmodalinfoConfig({
            isOpen: true,
            message: error?.response?.data?.message,
        });
    }

    useEffect(() => {
      if([true,false].includes(stateCotizacion)&&!modalTablaConfig?.isOpen){
        GetCotizaciones();
      }
    }, [stateCotizacion])

    useEffect(() => {
      if(JSON.stringify(Ticket)!=="{}"){
        setmodalTicket({isOpen:true});
      }
    }, [Ticket])

  return (
    <>

      <LoadingModal config={modalLoading} >
      <LoadingModal.Body>
        <LoadingModal.Title>{modalLoading?.title||"Cargado datos"}</LoadingModal.Title>
      </LoadingModal.Body>
      </LoadingModal>


      <Modalgeneric w="size-w-70" h="size-h-80" config={modalTicket} closeModal={closeModalTicket} tab="200" front="z-40">
        <Modalgeneric.Body>
          <Modalgeneric.Title>{"Folio: "+Ticket?.venta?.shortId}</Modalgeneric.Title>
          <div className={'size-form w-full items-center justify-center h-95/100'}>
            {(JSON.stringify(Ticket)!=="{}")?
            <div className='w-full border h-full max-h-screen overflow-scroll border-gray-500 text-sm lg:text-base'>
              <div className="w-full">
                <p className="uppercase w-full text-lg font-bold text-center">{Sucursal('nombre')}</p>
                <p className="uppercase">{Sucursal('direccion')+" C.P. "+Sucursal('codigo_postal')+" Tel. "+Sucursal('telefono')}</p>
                <p className="uppercase"> e-mail: {Sucursal('email')}</p>
                <p className="uppercase ">Ticket: <label className="font-bold">{Ticket?.venta?.shortId}</label> Fecha: <label className="font-bold">{FechaUTCToLocal(Ticket?.venta?.id).fecha + " "+ FechaUTCToLocal(Ticket?.venta?.id).hora}</label> </p>
                <p className="uppercase">Cliente: <label className="font-bold">{TransformSys(Ticket?.venta?.cliente)}</label>   VENDEDOR: <label className="font-bold">{Ticket?.venta?.nombreVendedor}</label></p>
              </div>
              <table className="w-full text-sm font-semibold mt-1">
                <thead>
                  <tr>
                    <th className="text-center">Cant</th>
                    <th className="text-center">Clave</th>
                    <th className="text-center">Desc</th>
                  </tr>
                </thead>
                <tbody>
                  {Ticket?.items
                    ? Ticket.items.map((item, index) => {
                        return (
                        <tr key={index}>
                          <td className=" text-center">{item.cantidad}</td>
                          <td className=" text-center text-xs ">{item?.id?item.id+" ":""}</td>
                          <td className=" text-center ">{item.descuento + "%"}</td>
                        </tr>)
                      })
                    : null}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-center"> {Ticket?.venta?.numeroArticulos || ""}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
              <div className="grid grid-cols-2 w-full my-2 ">
                <div className="grid grid-cols-2 w-full md:w-1/2">
                  <label>SubTotal:</label><Labelmoney font="font-size" twoDecimal={true}>{Ticket?.venta?.subtotal || 0}</Labelmoney>
                  <label className={(Ticket?.venta?.subtotal - Ticket?.venta?.total>0?"block":"hidden")}>Descuento: </label><Labelmoney font="font-size" twoDecimal={true} className={(Ticket?.venta?.subtotal - Ticket?.venta?.total>0?"block":"hidden")}>{(Ticket?.venta?.subtotal - Ticket?.venta?.total) || 0}</Labelmoney>
                  <label>Importe:</label><Labelmoney font="font-size" twoDecimal={true}>{Ticket?.venta?.total || 0}</Labelmoney>
                </div>
              </div>
            </div>
            :""}
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
        <button
            type="button"
            className="btn-gray2"
            name="modalgeneric"
            tabIndex={"200"}
            onClick={closeModalTicket}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalinfo config={modalinfoConfig} closeModal={closeModalinfo}>
        <Modalinfo.Message title={"No se ha encontrado la clave en la base de datos."} >
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>


      <Modalgeneric w="size-w-70" h="size-h-80" config={modalTablaConfig} closeModal={closeModaltabla}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Cotizaciones</Modalgeneric.Title>
          <TableModal
            config={modalTablaConfig}
            columns={[
                { 
                  header: "Clave",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (
                  <div className='flex flex-row w-full justify-between'>
                    <label className='font-size text-size'>{item?.vendedor||""}</label>
                    <motion.button className='text-teal-600 hover:text-black'
                    whileTap={btnTap}
                    whileHover={btnScale}
                    onClick={e=>{
                      if(e.detail==1){
                        openmodalLoading();
                        useDeleteCotizacion(item.shortId,(resp)=>{
                          closemodalLoading();
                          GetCotizaciones();
                        },(error)=>{
                          closemodalLoading();
                          setmodalinfoConfig({
                            isOpen: true,
                            title: "Error al eliminar Cotizacion",
                            message: error,
                          });
                        })
                        return
                      }
                    }}>
                      <i className='fas fa-trash text-red-500'/>
                    </motion.button> 
                    </div>
                    )
                  },
                { header: "Cliente", accessor: "cliente" },
                { header: "Nombre", accessor: "nombreCliente" },
                { header: "Fecha", 
                    // eslint-disable-next-line react/display-name
                    code: (item) => (<LabelFecha >{item?.id||""}</LabelFecha>),
                },
                { header: "Total",  
                    // eslint-disable-next-line react/display-name
                    code: (item) => (<Labelmoney className="w-24" twoDecimal={true}>{item?.total||0}</Labelmoney>),
                },
                { header: "Vendedor", 
                  // eslint-disable-next-line react/display-name
                  code: (item) => (
                  <div className='flex flex-row w-full justify-between'>
                    <label className='font-size text-size'>{item?.vendedor||""}</label>
                    <button className='text-teal-600 hover:text-black'
                    onClick={e=>{
                      const venta = {...item};
                      delete venta.items;
                      //console.log(venta)
                      setTicketSelect({items:item.items,venta:venta})
                    }}>
                      <i className='fas fa-eye'/>
                    </button>
                  </div>
                  )
                },
            ]}
            onclickRow={(item,e) => {
              if(e.detail!==2){return;}
                getCotizacion(item)
                closeModaltabla();
            }}
            data={dataCotizacion}
          />
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
    </>
  )
}

export default Cotizaciones;
