import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { agregarProducto, resetProducto } from "../redux/Actions.js";
import { agregarItemVenta, apiUsePromise, getSucursal, TransformSys, TransformSysAll } from "../services/functions.js";
import Modalinfo from "./modal/info";

const InputCotizacion = ({ addItem, addClient, Logueo,addHeaderVenta, HeaderVenta,Cliente,cotizaciones,setLoading,resetItem,resetHeaderVenta,itemsVenta}) => {
  let input;
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [cotizacion,setCotizacion]=useState();
  
  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }

  
  const handleError = (error) => {
    setmodalinfoConfig({
      isOpen: true,
      message: error?.response?.data?.message,
      title:"Error"
    });
  };

  const funcionBuscarCot = (idCotizacion) => {
    resetItem();
    addClient({});
    resetHeaderVenta();
    if (!idCotizacion) { return; }
    const getCotizacionAsync=async()=>{
      setLoading(true);
      //useGetCotizacion(input.value.toUpperCase().trim(),handleSuccessfulCotizacion,funcionError);
      const peticiones = [
        { id: idCotizacion, sucursal: getSucursal() },
        { sucursal:getSucursal() }
      ];
      
      try{
        const responseCotizacion = await apiUsePromise({ url: "/get-cotizacion", method: "POST",data: peticiones[0]});
        const coti = responseCotizacion.data.data
        //console.log('==>',coti);

        const responseCliente = await apiUsePromise({ url: "/get-client", method: "POST", data: {...peticiones[1],id: coti.cliente}});
        const cliente = responseCliente.data
        //console.log('==>',cliente);

        let clientes = cliente.filter(cli=>(cli.sucursal===Logueo.sucursal))
        if (clientes.length === 0) {
          setmodalinfoConfig({
            isOpen: true,
            message: "No existe el cliente '" + cliente+ "'",
            title:"CLiente no valido"
          });
          addClient({});
          setLoading(false);
          return;
        }
        if (clientes.length === 1 && clientes[0].activo==false) {
          setmodalinfoConfig({
            isOpen: true,
            message: "cliente inhabilitado",
            title:"CLiente no valido"
          });
          addClient({});
          setLoading(false);
          return;
        }

        setCotizacion(coti);
        addClient(clientes[0]);
        setLoading(false);
      }
      catch (error){
        setLoading(false);
        handleError(error)
      }
    }
   
    getCotizacionAsync();
  };

  useEffect(()=>{
    if(!cotizacion?.cliente){return;}
    if(!Cliente?.activo||TransformSys(cotizacion?.cliente)!==TransformSys(Cliente?.id)){return;}

    const getProductos=async()=>{
      setLoading(true);

        let listErrors=[];

        cotizacion.items.forEach(product=>{
          //console.log(product);
          const error = agregarItemVenta(product,addItem,Cliente?.descuento,itemsVenta,true);
          //onsole.log('error=>',error)
          error?listErrors.push(error):"";
        })
        if(listErrors.length>0){
          let message = [];
          listErrors.forEach(error=>{
            let r="";
            if(error.type=="existencia"){r+="Sin existencias: "}
            else if(error.type=="habilitar"){r+="Producto desactivado: "}
            else if(error.type=="vendible"){r+="Producto no vendible: "}
            else if(error.type=="insuficiente"){r+="Articulos insuficientes vuelva a agregar el producto: "}
            else{r+="Producto no encontrado en la Base de Datos: "}
            message.push(r+error.item.id);
          })
          setmodalinfoConfig({
            isOpen: true,
            message: message.join(" / "),
            title: "Articulo no valido"
          });
        }
        addHeaderVenta({...HeaderVenta, id:cotizacion.shortId, cliente:Cliente.id, fecha:cotizacion.id, type: cotizacion.type, itemsold: cotizacion.items});

        //console.log('end');
        setCotizacion();
        setLoading(false);
    }

    getProductos();

  },[Cliente])

  useEffect(()=>{
    if(!cotizaciones){ return;}
    funcionBuscarCot(cotizaciones.shortId);
    /*setLoading(true);
    useGetCotizacion(cotizaciones.shortId,handleSuccessfulCotizacion,funcionError);
    useGetClient(cotizaciones.cliente,handleSuccessfulCliente,handleError);*/
  },[cotizaciones])

  return (
    <>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={modalinfoConfig.title||"Titulo"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <div className="size-form w-full ">
            <input
              type="text"
              className="border-form focus:shadow-outline disabled:opacity-50 uppercase"
              name="cotizacion"
              placeholder="Cotizacion"
              style={{ transition: "all .15s ease" }}
              ref={(node) => (input = node)}
            />

            <button
              className="btn-search"
              type="submit"
              onClick={e=>{funcionBuscarCot(input.value.toUpperCase().trim())}}
              style={{ transition: "all .15s ease" }}
            >
              Buscar
            </button>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(agregarProducto(item)),
  addClient: (cliente) =>
    dispatch({
      type: "ADD_CLIENT",
      item: cliente,
    }),
  addHeaderVenta:(headerventa) => dispatch({type:"SET_HEADER_VENTA", headerVenta:headerventa}),
  resetItem: (item) => dispatch(resetProducto(item)),
  resetHeaderVenta: ()=> dispatch({type:"CLEAN_HEADER_VENTA"}),
});

const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
  Logueo: state.Logueo,
  HeaderVenta: state.HeaderVenta,
  utilMin: state.Catalogos.GLOBALCONFIGS.utilidadMinima,
  itemsVenta: state.itemsVenta,
});

export default connect(mapStateToProps, mapDispatchToProps)(InputCotizacion);
