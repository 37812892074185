import React from 'react'
import InputPedidoEspecial from './InputPedidoEspecial';

const HeaderPedidoEspecial=()=> {
    return (
        <div className="items-center " >
            <InputPedidoEspecial />
        </div>
    )
}
export default HeaderPedidoEspecial