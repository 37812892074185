import React, { useState,useRef, useEffect } from "react";
import { connect } from "react-redux";
import TableCompra from "../Compras/TableCompra";
import Container from "../Container";
import {  useGetProduct, useInsertMerma, useUpdateProducto } from "../../Hooks/useApis";
import { CalculaMinPrecioVenta, CalculaPrecioCompraPromediado, getSucursal, havePermission, moneyToValue, validProductId, valueToMoney } from "../../services/functions";
import InputPrecioPanel from "../InputPrecioPanel";
import LoadingModal from "../modal/LoadingModal";
import Modalgeneric from "../modal/generic";
import TableModal from "../modal/TableModal";
import Modalinfo from "../modal/info";
import Labelmoney from "../labelmoney";
import Modalsuccess from "../modal/success";
import SearchLine from "../Productos/SearchLine";
import { returnRuta } from "../../redux/Actions";
import LabelNumber from "../LabelNumber";
import { permisos } from "../../static/dat/datos";

const PanelAjuste = ({delRuta}) => {
  const item0 = {index:0,id:"",descripcion : "",cantidad : 1,importe : 0,precioCompra: 0,diff:0,type : ""}
  const [itemsAdjust, setItemsAdjust] = useState([item0]),
  [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modalTablaConfig, setmodalTablaConfig] = useState(false),
  [add,setAdd]=useState(undefined),
  [modalLoading, setmodalLoading] = useState({}),
  [dataproduct, setdataproduct] = useState([]);
  const title = useRef("Sin Datos"),inputs =useRef({});
  const permiso = havePermission(permisos.altaProds);
 
  const changeCantidad=(newItem)=>{
    let item = {...newItem};

    item.precioCompraPromediado = CalculaPrecioCompraPromediado(newItem.precioCompra,newItem.existencia,newItem.oldPrecioCompra,newItem.cantidad);
    item.minPrecioVenta = CalculaMinPrecioVenta(item.precioCompraPromediado,newItem.categoria); 
    item.diff = (newItem.cantidad-newItem.existencia);
    item.importe = (item.diff*newItem.precioCompra);
    setItem(item);
  }

  const setItem=(itemEdit)=>{
    setItemsAdjust([...itemsAdjust].map((item) => item.index === itemEdit.index?itemEdit:item ));
  }
  const eliminarItemCompra=(id)=>{
    setItemsAdjust(itemsAdjust.filter((item) => item.id !== id))
  }

  const addItem=(new_item)=>{
    let new_items = [{...item0}];
    new_items =  new_items.concat([...itemsAdjust,{...new_item,index:itemsAdjust.length}].filter(item=>item.index!==0))
    setItemsAdjust(new_items);
    setAdd(!add);
  }

  function focusProduct (id,campo){
    let index = 0, find = false;
    itemsAdjust.forEach(producto=>{
      if (producto.id == id&&producto.descripcion!==""){
        find = true;
        index = producto.index;
      }
    })
    if(find){
      setItem(item0);
      //inputs.current["0id"].value = "";
      inputs.current.[index+campo].focus();
      inputs.current[index+campo]?.select?inputs.current[index+campo].select():""
      
    }
    return find;
  }
  function agregarItem(item) {
    const newItem = itemsAdjust[0];

    if(item?.sucursales?.[getSucursal()]){
      Object.keys(item.sucursales.[getSucursal()]).map(key=>{
        newItem[key] = item.sucursales.[getSucursal()].[key];
      })
    }

    newItem.descripcion = item.descripcion;
    newItem.oldDescripcion = newItem.descripcion;
    newItem.precioCompra = item?.sucursales?.[getSucursal()]?.precioCompra ||0;
    newItem.oldPrecioCompra = item?.sucursales?.[getSucursal()]?.oldPrecioCompra || newItem.precioCompra;
    newItem.precioVenta = item?.sucursales?.[getSucursal()]?.precioVenta ||0;
    newItem.oldPrecioVenta =newItem.precioVenta;
    newItem.cantidad  = newItem?.existencia ||0;
    newItem.existencia = newItem.cantidad;
    newItem.diff = 0;
    newItem.type = item.type;
    newItem.id= item.id;
    newItem.categoria= item.categoria;
    newItem.marca= item.marca;
    newItem.linea= item.linea;
    newItem.comprable = item?.sucursales?.[getSucursal()]?.comprable ||true;
    newItem.vendible = item?.sucursales?.[getSucursal()]?.vendible ||true;
    newItem.oferta = item?.sucursales?.[getSucursal()]?.oferta || false;
    newItem.oldoferta = newItem.oferta;
    newItem.oldComprable = newItem.comprable;
    newItem.oldVendible = newItem.vendible;
    newItem.guideline = item?.sucursales?.[getSucursal()]?.guideline ||0;
    newItem.responsable="";
    newItem.ticket="";
    newItem.importe = newItem.diff * newItem.precioCompra;

    newItem.precioCompraPromediado = CalculaPrecioCompraPromediado(newItem.precioCompra,newItem.existencia,newItem.oldPrecioCompra,newItem.cantidad);
    newItem.minPrecioVenta = CalculaMinPrecioVenta(newItem.precioCompraPromediado,newItem.categoria);

    inputs.current["0id"].value = newItem.id;
    inputs.current["0cantidad"].focus();
    inputs.current["0cantidad"]?.select?inputs.current["0cantidad"].select():""

    setItem(newItem);
  }

  const funcionExitoMerma=(respuesta)=>{
    closemodalLoading();
    setmodalSuccessConfig({
      isOpen: true,
      message: respuesta,
      title: "AJUSTE REALIZADO CON EXITO"
    }); 
  };
  const funcionExitoUpdate=(respuesta)=>{
    closemodalLoading();
    setmodalSuccessConfig({
      isOpen: true,
      message: respuesta,
      title: "Cambio de precios Realizado"
    }); 
  }

  const funcionExito = (dataproductos) => {
    const data = dataproductos.productos;
    closemodalLoading();

    if(dataproductos.linea){
      setmodalinfoConfig({ isOpen: true,message: "Los ajustes de Micas/LC Y ARMAZONES debe hacerse en el Modulo de Inventario ",});
      return;
    }else{
      if (data.length === 0) {
        title.current ="Producto No Encontrado";
        setmodalinfoConfig({ isOpen: true,message: ("No existe el producto "+itemsAdjust[0]?.id),});
        return;
      }
      if (data.length === 1){ 
        if(data[0].id.substring(0,2)=="MI"||data[0].id.substring(0,2)=="LC"){
          setmodalinfoConfig({ isOpen: true,message: "Los ajustes de Micas/LC debe hacerse en el Modulo de Armazones y Micas ",});
          return;
        }
        const sucursal = data[0].sucursales?.[getSucursal()];
        if(!sucursal?.precioCompra||sucursal?.bloqueado){
          title.current ="Producto Nunca comprado o bloqueado";
          setmodalinfoConfig({ isOpen: true,message: ("El producto debe tener por lo menos un compra para poder ajustar "+itemsAdjust[0]?.id),});
          return;
        }
        agregarItem(data[0]);
        return;
      } 
      if (data.length > 1) {
        setdataproduct(data);
        setmodalTablaConfig({ isOpen: true });
      }
    }
  };


  const funcionError = (error) => {
    closemodalLoading();
    setmodalinfoConfig({ isOpen: true, message: error?.response?.data?.message||error?.message||error});
  };

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true, title });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  function closeModaltabla(e) {
    setmodalTablaConfig({ isOpen: false });
    inputs.current["0id"].focus();
    inputs.current["0id"].select();
  }
  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
    inputs.current["0id"].focus();
    inputs.current["0id"].select();
  }
  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
    delRuta();
  }


  useEffect(() => {
    if(add!==undefined){
      inputs.current["0id"].value = "";
      inputs.current["0id"].focus();
      inputs.current["0cantidad"].value = 0;
      //inputs.current["0pc"].value = "";
      //inputs.current["0id"].focus();
    }
  }, [add])

  return (
    <>
    <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Servicios</LoadingModal.Title>
        </LoadingModal.Body>
    </LoadingModal>

    <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Body title={modalSuccessConfig.title}>
          Producto Guardado Con Exito
        </Modalsuccess.Body>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex="200"
            className="btn-green"
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

    <Modalgeneric config={modalTablaConfig} tab="300" closeModal={closeModaltabla}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Elija una opción</Modalgeneric.Title>
          <TableModal
          searchinput ={true}
          config={modalTablaConfig}
            columns={[
              { header: "Clave", accessor: "id" },
              { header: "Descripción", accessor: "descripcion" },
              { header: "Linea", accessor: "linea" },
              { header: "Modelo", accessor: "medida" },
            ]}
            onclickRow={(item) => {
              const sucursal = item.sucursales?.[getSucursal()];
              if(!sucursal?.precioCompra||sucursal?.bloqueado){
                title.current ="Producto Nunca comprado o bloqueado";
                setmodalinfoConfig({ isOpen: true,message: ("El producto debe tener por lo menos un compra para poder ajustar "+item?.id),});
                return;
              }
              closeModaltabla();
              agregarItem(item);
              
            }}
            data={dataproduct}
          />
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            tabIndex="300"
            name="modalgeneric"
            className="btn-gray1"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalinfo config={modalinfoConfig} tab="200" closeModal={closeModalinfo}>
        <Modalinfo.Message title={title.current}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            tabIndex="200"
            name="modalinfo"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>


    <Container bg="bg-mesh">
      <SearchLine
      getLinea={linea=>{
        //console.log(linea)
        if(!linea?.id){
          setmodalinfoConfig({isOpen: true,message: "El id no es válido. " });
          setItem(item0);
          return;
        }
        if(validProductId(linea?.id)){
          const sucursal = linea.sucursales?.[getSucursal()];
          if(!sucursal?.precioCompra||sucursal?.bloqueado){
            title.current ="Producto Nunca comprado o bloqueado";
            setmodalinfoConfig({ isOpen: true,message: ("El producto debe tener por lo menos un compra para poder ajustar "+linea?.id),});
            return;
          }
          //console.log('linea=>',linea)
          if(focusProduct(linea.id,"cantidad")){return}
          agregarItem(linea);
        }
      }}/>
      <div className="relative h-80/100 md:h-94/100">
        <TableCompra
        searchinput={false}
        columns={[
        {
          header: "maker/style",
          width:"w-44",
          // eslint-disable-next-line react/display-name
          code: (item,index) => (
            <div className="flex flex-row w-full gap-1">
              <input
                type="text"
                className="border-table w-full text-rigth uppercase"
                placeholder=""
                ref={node=>{inputs.current[item?.index+"id"]=node}}
                defaultValue={item?.id}
                readOnly={item?.index==0?false:true}
                onKeyDown={e=>{
                  const valor = e.target.value.trim().toUpperCase();
                  if((e.key=="Enter"||e.key=="Tab")&&item.index==0&&item.descripcion==""){
                    if(focusProduct(valor,"cantidad")){return}
                      const peticion ={producto:valor,funcionExito:funcionExito,funcionError:funcionError};
                      if(valor.substring(0,2)=="MI"||valor.substring(0,2)=="LC"||valor.substring(0,2)=="AR"){
                        peticion.linea = true;
                      }
                      openmodalLoading(e);
                      useGetProduct(peticion);
                  }else if(e.key=="Escape"){
                    setItem(item0);
                    setAdd(!add);
                  }
                }}
              />
               <button
                className="text-red-600 transform hover:text-pink-700 hover:scale-110  " hidden={item.index==0}
                  onClick={(e) => {
                    eliminarItemCompra(item.id);
                  }}
                >
                  <i className="fas fa-times-circle"></i>
                </button>
          </div>
          ),
        },
        {
          header: "Description",
          width:"w-96",
           // eslint-disable-next-line react/display-name
          code:(item)=>(
            <input className="border-table w-full uppercase"
            type="text"  
            ref={node=>{inputs.current[item?.id+"descripcion"]=node}} 
            disabled={!permiso||item.id==""}
            defaultValue={item?.descripcion}
            onBlur={e=>{
              const valor =  e.target.value.trim().toUpperCase();
              if(valor==item.descripcion){return}
              setItem({...item,descripcion:valor});
            }}
            />
          )
        },
        {
          header: "Vendible",
          width:"w-24 ",
          // eslint-disable-next-line react/display-name
          code: (item) => 
          <input 
          className="w-full"
          type="checkbox" 
          checked={item?.vendible}
          onChange={e=>{
            setItem({...item,vendible:!item?.vendible});
          }}/>,
        },
        {
          header: "Comprable",
          width:"w-24  ",
          // eslint-disable-next-line react/display-name
          code: (item) => 
          <input 
          className="w-full"
          type="checkbox" 
          checked={item?.comprable}
          onChange={e=>{
            setItem({...item,comprable:!item?.comprable});
          }}/>,
        },
        {
          header: "Oferta",
          width:"w-24  ",
          // eslint-disable-next-line react/display-name
          code: (item) => 
          <input 
          className="w-full"
          type="checkbox" 
          checked={item?.oferta}
          onChange={e=>{
            const newoferta= !item?.oferta,
            newitem = {...item,oferta:newoferta};
            if(newoferta==false&&item.precioVenta<item.minPrecioVenta){
              newitem.precioVenta = 0;
              inputs.current[item.index+"pv"].focus();
              inputs.current[item.index+"pv"].select();
            }
            setItem(newitem);
          }}/>,
        },
        {
          header: "OldExists",
          width:"w-28",
           // eslint-disable-next-line react/display-name
          code:(item)=>(
            <LabelNumber twoDecimal={true}>{item.existencia}</LabelNumber>
          )
        },
        {
          header: "Qty",
          width:"w-28 ",
          // eslint-disable-next-line react/display-name
          code: (item) => (
            <InputPrecioPanel
            width="w-full"
            inputRef={node=>{inputs.current[item?.index+"cantidad"]=node}}
            newValor={moneyToValue(item?.cantidad)}
            noShowSignal={true}
            onBlur={(e,x)=>{
              const cant = e;
              if(cant<0&&item.id!==""){ inputs.current[item.index+"cantidad"].focus(); }
              if(cant==item.cantidad){ return;  }
              changeCantidad({...item,cantidad:cant});
            }}
            onKeyPress={e=>{
              if(e.key=="Escape"){ changeCantidad({...item,cantidad:item.existencia}) }
            }}
          />

          ),
        },
        {
          header: "Diff",
          width:"w-28",
           // eslint-disable-next-line react/display-name
          code:(item)=>(
            <LabelNumber>{item.diff}</LabelNumber>
          ),
        },
        {
          header: "Unit Price",
          width:"w-28",
          // eslint-disable-next-line react/display-name
          code: (item) => (
            <InputPrecioPanel
              width="w-full"
              //  min={item?.minPrecioVenta}
              bgMin={(item?.oferta?(item.precioVenta<item.precioCompraPromediado):(item.precioVenta<item.minPrecioVenta?true:false))}
              newValor={item?.precioVenta}
              inputRef={e=>{inputs.current[item?.index+"pv"]=e}}
              onBlur={(e,x)=>{
                const val =  moneyToValue(e);
                if(item.oferta){
                  if(val<item.precioCompraPromediado){
                    inputs.current[item?.index+"pv"].focus();
                    inputs.current[item?.index+"pv"].select();
                  }
                }
                else if((val<0.0001||val<item.minPrecioVenta)&&item.id!==""){
                  inputs.current[item?.index+"pv"].focus();
                  inputs.current[item?.index+"pv"].select();
                }
                if(val==item.precioVenta){ return;  }
                changeCantidad({...item,precioVenta:moneyToValue(e)})
              }}
              onKeyPress={e=>{
                if(e.key=="Escape"){ changeCantidad({...item,precioVenta:item.oldPrecioVenta}) }
              }}
            />
          ),
        },
        {
          header: "Unit Cost",
          width:"w-28",
          // eslint-disable-next-line react/display-name
          code: (item) => (
            <div className="w-full flex">
              <input className="w-0" type="text"
              onKeyDown={e=>{
                if((e.key=="Tab"||e.key=="Enter")&&item?.index==0&&item.id!==""&&item.descripcion!==""){
                  addItem({...item});
                }
              }}/>
              <Labelmoney twoDecimal={true}>{item?.precioCompra}</Labelmoney>
            </div>
            
            /*<InputPrecioPanel
              width="w-full"
              min="0"
              defaultValue={item?.precioCompra}
              newValor={item?.precioCompra}
              inputRef={e=>{inputs.current[item?.index+"pc"]=e}}
              readOnly={true}
              onBlur={e=>{
                if(e<0.0001&&item.id!==""){
                  inputs.current[item?.index+"pc"].focus();
                  return
                }
                changeCantidad({...item,precioCompra:moneyToValue(e)});
              }}
              onKeyPress={e=>{
                if((e.key=="Tab"||e.key=="Enter")&&item?.index==0&&item.id!==""&&item.descripcion!==""){
                  addItem({...item});
                }
              }}
            />*/
          ),
        },
        {
          header: "Amount",
          width:"w-36",
          // eslint-disable-next-line react/display-name
          code: (item) => (
            <Labelmoney twoDecimal={true} >{item?.importe}</Labelmoney>
          ),
        },
        ]}
        footer={[
          {width:"w-44"},{width:"w-96"},{width:"w-24"},{width:"w-24"},{width:"w-28"},{width:"w-28"},{width:"w-28"},{width:"w-28"},{width:"w-28"},
          { width:"w-36 bg-ambar-100  ",
              // eslint-disable-next-line react/display-name
            code: <Labelmoney twoDecimal={true} className="text-size">{itemsAdjust.reduce((a, b) => parseInt(a) + parseInt(b.importe), 0)}</Labelmoney>
          }
        ]}
        data={itemsAdjust}
        />
      </div>

      <div className="flex flex-wrap mb-1 w-full justify-center">
          <button className="btn-dark-free h-12 hover:text-yellow-200 bg-yellow-500 hover:bg-yellow-600 w-full md:w-32"
          onClick={e=>{
            let itemsDiff=[],itemsUpdate=[],copyItems=[],errors=false;

            //copyItems =copyItems.filter(item=>item.index!==0)
            [...itemsAdjust].forEach(product=>{
              if(product.index!==0){ copyItems.push(product)}
              else if(product.id!==""&&product.precioCompra>0&&product.descripcion!==""){ copyItems.push(product) }
            });

            copyItems.forEach(product=>{
              if(product.oferta==false&&product.precioVenta<product.minPrecioVenta){
                errors = true;
              }else if(product.precioVenta<0.0001||product.precioVenta<product.minPrecioVenta){
                errors = true;
              }
              if(product.cantidad<0){ errors = true }
            })

            if(errors){
              setmodalinfoConfig({ 
                isOpen: true,
                message: "Algun producto tiene cantidad o precio venta incorrecto ",
              });
              return
            }

            if(copyItems.length<1){return}
            let cantTotal=0,importeTotal=0;
            copyItems.forEach(item=>{
              if(item.diff !== 0){
                importeTotal += item.importe;
                cantTotal += item.cantidad;
                itemsDiff.push({
                  id:item.id,
                  cantidad: item.diff,
                  importe: item.importe,
                  descripcion:item.descripcion,
                  marca:item.marca,
                  linea:item.linea,
                  categoria:item.categoria,
                  responsable:"",
                  ticket:"",
                });
                if(item.precioVenta!==item.oldPrecioVenta||item.precioCompra!==item.oldPrecioCompra||item.oldDescripcion!==item.descripcion||item.oldComprable!==item.comprable||item.oldVendible!==item.vendible||item?.oferta!==item?.oldoferta){
                  const newItem = {
                    id: item.id,
                    cantidad: 0,
                    precioCompra: item.precioCompra,
                    precioVenta: item.precioVenta,
                    vendible: item.vendible,
                    comprable: item.comprable,
                    descripcion: item.descripcion,
                    categoria: item.categoria,
                    linea: item.linea,
                    marca:item.marca,
                    guideline: item.guideline,
                  };
                  if(item?.oferta!==item?.oldoferta){newItem.oferta = item.oferta}
                  itemsUpdate.push(newItem);
                }
              }else if(item.precioVenta!==item.oldPrecioVenta||item.precioCompra!==item.oldPrecioCompra||item.oldDescripcion!==item.descripcion||item.oldComprable!==item.comprable||item.oldVendible!==item.vendible||item?.oferta!==item?.oldoferta){
                /*console.log('pc=>',item.precioCompra,'-',item.oldPrecioCompra);
                console.log('pv=>',item.precioVenta,'-',item.oldPrecioVenta);
                console.log('desc=>',item.descripcion,'-',item.oldDescripcion);
                console.log('compra=>',item.comprable,'-',item.oldComprable);
                console.log('vende=>',item.vendible,'-',item.oldVendible);*/
                const newItem ={
                  id: item.id,
                  cantidad: 0,
                  precioCompra: item.precioCompra,
                  precioVenta: item.precioVenta,
                  vendible: item.vendible,
                  comprable: item.comprable,
                  descripcion: item.descripcion,
                  categoria: item.categoria,
                  linea: item.linea,
                  marca:item.marca,
                  guideline: item.guideline,
                };
                if(item?.oferta!==item?.oldoferta){newItem.oferta = item.oferta}
                itemsUpdate.push(newItem);
              }
            });

            if(itemsUpdate.length!==0&&itemsDiff.length==0){
              //console.log('updateProduct>>',itemsUpdate);
              openmodalLoading();
              useUpdateProducto(itemsUpdate,funcionExitoUpdate,funcionError);
              return;
            }
            if(itemsUpdate.length==0&&itemsDiff.length!==0){
              importeTotal = itemsDiff.reduce((a, b) => parseInt(a) + parseInt(b.importe), 0);
              cantTotal = itemsDiff.length;
              //console.log('Merma>>',itemsDiff);
              openmodalLoading();
              useInsertMerma({items:itemsDiff,descripcion:"AJUSTE DE INVENTARIO",cantidadTotal:cantTotal,importeTotal:importeTotal,tipo:"AJUSTE"},funcionExitoMerma,funcionError);
              return;
            }
            if(itemsUpdate.length!==0&&itemsDiff.length!==0){
              //console.log('all');
                const funcionExitoUpdatei=(respuesta)=>{
                  useInsertMerma({items:itemsDiff,descripcion:"AJUSTE DE INVENTARIO",cantidadTotal:cantTotal,importeTotal:importeTotal,tipo:"AJUSTE"},funcionExitoMerma,funcionError);
                }
  
                importeTotal = itemsDiff.reduce((a, b) => parseInt(a) + parseInt(b.importe), 0);
                cantTotal = itemsDiff.length;
                //console.log('Update>>',itemsUpdate);
                //console.log('Merm>>',itemsDiff)
                openmodalLoading();
                useUpdateProducto(itemsUpdate,funcionExitoUpdatei,funcionError);
                
            }
          }}>
            Hacer Ajuste
          </button>
      </div>
    </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  delRuta: (ruta) => dispatch(returnRuta(ruta)),
  setPropsInventario: (estado) => dispatch({type:"SET_PROPSINVENTARIO", estado:estado}),
});

const mapStateToProps = (state) => ({
  HeaderCompra: state.HeaderCompra,
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelAjuste);
