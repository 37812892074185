import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FechaUTCToLocal, Sucursal, TransformSys } from "../../services/functions.js";
import Newlogo from "../../static/img/logonew.jsx";
import {motion} from 'framer-motion'
import PagadoSvg from "../../static/img/Pagado";
import Labelmoney from "../labelmoney";
import { returnRuta } from "../../redux/Actions.js";

const TicketPagado = ({ Ticket, delRuta }) => {
  const [showIva,setShowIva] = useState(false),
  ivaSucursal = Sucursal("iva")?Sucursal("iva")/100:0.0;

  useEffect(() => { window.print();}, []);

  return (
    <>
    <div className="mx-2 75mm">
        <div className="w-full text-2xs">
          <div className="grid grid-flow-col justify-items-stretch w-full">
            {(Ticket?.pagado==Ticket.total)?
              <div className="w-40 justify-self-end" 
              onClick={e=>{
                setShowIva(!showIva);
              }}>
                <PagadoSvg bg="bg-transparent" text="text-red-800"/>
              </div>
              :<p className="text-sm mt-2 justify-self-end font-black uppercase"
              onClick={e=>{
                setShowIva(!showIva);
              }}>Venta a credito</p>
            }
            <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{
              scale: 0.8,
              rotate: 5,
              borderRadius: "100%"
            }}
            onClick={(e) => {delRuta();}}
            className={ "w-[80px] justify-self-end h-14 border-none leading-relaxed inline-block" }
            >
              <Newlogo corona="#00ace5" corona1="#00C9E5"  text="#000000"/>
            </motion.button>
          </div>
          <div className="w-full">
            <p className="uppercase w-full text-lg font-bold text-center">{Sucursal('nombre')}</p>
            <p className="uppercase">{Sucursal('direccion')+" C.P. "+Sucursal('codigo_postal')+" Tel. "+Sucursal('telefono')}</p>
            <p className="uppercase"> e-mail: {Sucursal('email')}</p>
            <p className="uppercase ">Ticket: <label className="font-bold">{Ticket?.shortId}</label> Fecha: <label className="font-bold">{FechaUTCToLocal(Ticket?.id).fecha + " "+ FechaUTCToLocal(Ticket?.id).hora}</label> </p>
            <p className="uppercase">Cliente: <label className="font-bold">{TransformSys(Ticket?.cliente)}</label>   VENDEDOR: <label className="font-bold">{Ticket?.nombreVendedor}</label></p>
          </div>
            <table className="w-full  font-semibold mt-1">
              <thead >
                <tr>
                  <th className="text-center">Cant</th>
                  <th className="text-center">Descripción</th>
                  <th className="text-right w-12">Precio U</th>
                  {showIva?<th className="text-right w-12">{"Iva"}</th>:null}
                  <th className="text-right w-10">Desc</th>
                  <th className="text-right w-16">Subtotal</th>
                </tr>
              </thead>
              <tbody >
                {Ticket?.items
                  ? Ticket.items.map((item, index) => {
                    if(item?.servicios){
                      return item.servicios.map((serv,i)=>{
                        if(showIva){
                          const iva = (serv.precioVenta-(serv.precioVenta/(1+ivaSucursal))),precioSinIva=(serv.precioVenta/(1+ivaSucursal));
                          return <tr key={serv.id+i} >
                            <td className=" text-center">{serv.cantidadVendida}</td>
                            <td className=" text-start text-3xs "><p  >{serv.descripcion.substring(0,35)}</p></td>
                            <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{precioSinIva}</Labelmoney></td>
                            <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{iva}</Labelmoney></td>
                            <td className="w-10 text-right ">{serv.descuento||0 + "%"}</td>
                            <td className="w-16 text-right "><Labelmoney twoDecimal={true}>{serv.importe-(iva*serv.cantidadVendida)}</Labelmoney></td>
                          </tr>
                        }
                        return <tr key={serv.id+i} >
                          <td className=" text-center ">{serv.cantidadVendida}</td>
                          <td className=" text-start text-3xs ">{serv.descripcion}</td>
                          <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{serv.precioVenta}</Labelmoney></td>
                          <td className="w-10 text-right ">{serv.descuento||0 + "%"}</td>
                          <td className="w-16 text-right "><Labelmoney twoDecimal={true}>{serv.importe}</Labelmoney></td>
                        </tr>
                    })
                    }else{
                      if(showIva){
                        const iva = (item.precioVenta-(item.precioVenta/(1+ivaSucursal))),precioSinIva=(item.precioVenta/(1+ivaSucursal));
                        return <tr key={index}>
                          <td className=" text-center">{item.cantidadVendida}</td>
                          <td className=" text-start text-3xs"><p >{(item?.id?item.id+" ":"")+item.descripcion.substring(0,25)}</p></td>
                          <td className="w-14 text-right "><Labelmoney twoDecimal={true}>{precioSinIva}</Labelmoney></td>
                          <td className="w-14 text-right "><Labelmoney twoDecimal={true}>{iva}</Labelmoney></td>
                          <td className="w-10 text-right ">{item.descuento + "%"}</td>
                          <td className="w-16 text-right "><Labelmoney twoDecimal={true}>{item.importe-(iva*item.cantidadVendida)}</Labelmoney></td>
                        </tr>
                      }
                      return (<tr key={index}>
                        <td className=" text-center">{item.cantidadVendida}</td>
                        <td className=" text-start text-3xs ">{(item?.id?item.id+" ":"")+item.descripcion.substring(0,25)}</td>
                        <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{item.precioVenta}</Labelmoney></td>
                        <td className="w-10 text-right ">{item.descuento + "%"}</td>
                        <td className="w-16 text-right "><Labelmoney twoDecimal={true}>{item.importe}</Labelmoney></td>
                      </tr>)
                    }
                  })
                  : null}
                  
              </tbody>
              <tfoot>
                <tr>
                  <td className="text-center"> {Ticket?.numeroArticulos || ""}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {showIva?<td></td>:null}
                </tr>
              </tfoot>
            </table>
            <div className="flex flex-col w-full my-2 ">
              {showIva?
                <div className="grid grid-cols-2 w-1/2">
                  <label>SubTotal:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.subtotal-Ticket?.iva}</Labelmoney>
                  <label>{"IVA:"}</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.iva}</Labelmoney> 
                  <label className={(Ticket?.subtotal - Ticket?.total>0?"block":"hidden")}>Descuento: </label><p className={(Ticket?.subtotal - Ticket?.total>0?"block":"hidden")}><Labelmoney className="w-16" twoDecimal={true}>{(Ticket?.subtotal - Ticket?.total)||0}</Labelmoney></p>
                  <label>Importe:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.total}</Labelmoney>
                </div>
              :
                <div className="grid grid-cols-2 w-1/2">
                <label>SubTotal:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.subtotal}</Labelmoney>
                  {/* <label>{"IVA (Inc) :"}</label><label>{showMoney(Ticket?.iva || 0)}</label> */}
                  <label className={(Ticket?.subtotal - Ticket?.total>0?"block":"hidden")}>Descuento: </label><p className={(Ticket?.subtotal - Ticket?.total>0?"block":"hidden")}><Labelmoney className="w-16" twoDecimal={true}>{(Ticket?.subtotal - Ticket?.total)||0}</Labelmoney></p>
                  <label>Importe:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.total}</Labelmoney>
                </div>
              }
              {Ticket?.pagos.length?
              <div className="w-full">
                <div className="font-bold text-center">PAGOS</div>
                {Ticket.pagos.map((pago,index)=>(
                  <div key={index} className="grid grid-cols-2 w-1/2">
                     <label>{pago.metodo}:</label><Labelmoney className="w-16" twoDecimal={true}>{(pago?.excedente?pago.excedente:(pago.monto || 0))}</Labelmoney>
                  </div>
                ))}
                <div className="grid grid-cols-2 w-1/2">
                <label className="">Total Tk:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.total}</Labelmoney>
                  <label className={""+(Ticket?.total-Ticket?.pagado>0?"block":"hidden")}>Restante:</label><p className={""+(Ticket?.total-Ticket?.pagado>0?"block":"hidden")}><Labelmoney className="w-16" twoDecimal={true}>{(Ticket?.total-Ticket?.pagado || 0)}</Labelmoney></p>
                  <label className={""+(Ticket?.cambio==true?"block":"hidden")}>Cambio:</label><p className={""+(Ticket?.cambio==true?"block":"hidden")}><Labelmoney className="w-16" twoDecimal={true}>{(Ticket.restante || 0)}</Labelmoney></p>
                </div>
              </div>
              :""
            }

            </div>
            {Sucursal('ticket')?.header?
              <p className="uppercase w-full text-center">{Sucursal('ticket').header}</p>
            :""}
            {Sucursal('ticket')?.productos.length?
              <div className="w-full text-center mt-3">
                <p className="uppercase w-full text-center font-black mb-1">Nuevos Productos</p>
                {Sucursal('ticket').productos.map((prod,index)=>(<p key={index} className="uppercase w-full text-center">{prod}</p>))}
              </div>
            :""}
            {Sucursal('ticket')?.footer?
              <p className="w-full text-center mt-3">{Sucursal('ticket').footer}</p>
            :""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Ticket: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  delRuta: () => dispatch(returnRuta()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketPagado);
