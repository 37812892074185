import React, { useState } from 'react'
import { useCustomQuery, useGetVenta } from '../../Hooks/useApis';
import { FechaUTCToLocal, Sucursal, TransformSys, getIdUser, getSucursal, showMoney } from '../../services/functions';
import LabelFecha from '../LabelFecha';
import Labelmoney from '../labelmoney';
import Modalgeneric from '../modal/generic';
import Modalinfo from '../modal/info';
import TableModal from '../modal/TableModal';
import { useEffect } from 'react';
import LoadingModal from '../modal/LoadingModal';

const Pendientes =({getPendiente,caja,statePendientes, setInputFocus,inputFocus})=> {
    const [dataPendiente,setDataPendiente]=useState([]),
    [modalTablaConfig, setmodalTablaConfig] = useState(false),
    [modalTicket,setmodalTicket]=useState({}),
    [Ticket,setTicketSelect]=useState({}),
    [modalLoading, setmodalLoading] = useState({}),
    [modalinfoConfig, setmodalinfoConfig] = useState({});

    const setInput = setInputFocus || null;
  
    function closeModalinfo(e) { setmodalinfoConfig({ isOpen: false }); setInput?setInputFocus(!inputFocus):null;}
    function closeModaltabla(e) {setmodalTablaConfig({ isOpen: false }); setInput?setInputFocus(!inputFocus):null;}
    function closeModalTicket(e) {setmodalTicket({ isOpen: false }); setTicketSelect({});}
    const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, titulo:titulo });};
    const closemodalLoading = (e) => {setmodalLoading({ isOpen: false }); };

    const funcionExito=(respuesta)=>{
      closemodalLoading();
        if(respuesta?.datos?.length==0){
            setmodalinfoConfig({
                isOpen: true,
                title: "No se ha encontrado la clave en la base de datos.",
                message: "No Hay Ventas Pendientes",
              });
            return;
        }
        /*if(respuesta?.datos?.length === 1){
            getPendiente(respuesta.datos[0]);
            return;
        }*/
        setDataPendiente(respuesta.datos);
        setmodalTablaConfig({ isOpen: true });
    }
    const funcionError=(error)=>{
      closemodalLoading();
        setmodalinfoConfig({
            isOpen: true,
            message: error?.response?.data?.message,
          });
    }

    const GetPendientes=()=>{
      openmodalLoading();
      let filter = [{nombre:"statusTemp",valor:"PENDIENTE#"+getSucursal(),operador:"eq"}];
      caja?"":filter.push({nombre:"vendedor",valor:getIdUser(),operador:"eq"});

      const peticion ={
        indexName:"reverseIndex",
        hashKey:{valor:("VENTA#"+getSucursal()),nombre:"type"},
        filters:filter
      }
      useCustomQuery(peticion,funcionExito,funcionError);
    }

    useEffect(() => {
      if([true,false].includes(statePendientes)&&!modalTablaConfig?.isOpen){
        GetPendientes();
      }
    }, [statePendientes])

    useEffect(() => {
      if(JSON.stringify(Ticket)!=="{}"){
        setmodalTicket({isOpen:true});
      }
    }, [Ticket])
    

  return (
    <>
        <LoadingModal config={modalLoading} z="z-50" >
        <LoadingModal.Body>
          <LoadingModal.Title>{modalLoading.titulo||"CARGANDO"}</LoadingModal.Title>
        </LoadingModal.Body>
        </LoadingModal>
        
        <Modalinfo config={modalinfoConfig} closeModal={closeModalinfo}>
        <Modalinfo.Message  title={"No se ha encontrado la clave en la base de datos."}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            name="modalinfo"
            tabIndex={"100"}
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modalgeneric  config={modalTicket} closeModal={closeModalTicket} tab="200" front="z-40">
        <Modalgeneric.Body>
          <Modalgeneric.Title>{"Folio: "+Ticket?.venta?.shortId}</Modalgeneric.Title>
          <div className={'size-form w-full items-center justify-center h-full'}>
            {(JSON.stringify(Ticket)!=="{}")?
            <div className='w-full border h-90/100 max-h-screen overflow-scroll border-gray-500 text-sm lg:text-base'>
              <div className="w-full">
                <p className="uppercase w-full text-lg font-bold text-center">{Sucursal('nombre')}</p>
                <p className="uppercase">{Sucursal('direccion')+" C.P. "+Sucursal('codigo_postal')+" Tel. "+Sucursal('telefono')}</p>
                <p className="uppercase"> e-mail: {Sucursal('email')}</p>
                <p className="uppercase ">Ticket: <label className="font-bold">{Ticket?.venta?.shortId}</label> Fecha: <label className="font-bold">{FechaUTCToLocal(Ticket?.venta?.id).fecha + " "+ FechaUTCToLocal(Ticket?.venta?.id).hora}</label> </p>
                <p className="uppercase">Cliente: <label className="font-bold">{TransformSys(Ticket?.venta?.cliente)}</label>   VENDEDOR: <label className="font-bold">{Ticket?.venta?.nombreVendedor}</label></p>
              </div>
              <table className="w-full text-sm font-semibold mt-1">
                <thead>
                  <tr>
                    <th className="text-center">Cant</th>
                    <th className="text-center">Descripción</th>
                    <th className="text-center w-16">Precio U</th>
                    <th className="text-center">Desc</th>
                    <th className="text-center">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {Ticket?.items
                    ? Ticket.items.map((item, index) => {
                        return (
                        <tr key={index}>
                          <td className=" text-center">{item.cantidadVendida}</td>
                          <td className=" text-start text-xs ">{(item?.id?item.id+" ":"")+item.descripcion.substring(0,25)}</td>
                          <td className=" text-center ">{showMoney(item.precioVenta||0)}</td>
                          <td className=" text-center ">{item.descuento + "%"}</td>
                          <td className=" text-center ">{showMoney(item.importe)}</td>
                        </tr>)
                      })
                    : null}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-center"> {Ticket?.venta?.numeroArticulos || ""}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
              <div className="grid grid-cols-2 w-full my-2 ">
                <div className="grid grid-cols-2 w-full md:w-1/2">
                  <label>SubTotal:</label><Labelmoney font="font-size" twoDecimal={true}>{Ticket?.venta?.subtotal || 0}</Labelmoney>
                  <label className={(Ticket?.venta?.subtotal - Ticket?.venta?.total>0?"block":"hidden")}>Descuento: </label><Labelmoney font="font-size" twoDecimal={true} className={(Ticket?.venta?.subtotal - Ticket?.venta?.total>0?"block":"hidden")}>{(Ticket?.venta?.subtotal - Ticket?.venta?.total) || 0}</Labelmoney>
                  <label>Importe:</label><Labelmoney font="font-size" twoDecimal={true}>{Ticket?.venta?.total || 0}</Labelmoney>
                </div>
              </div>
            </div>
            :""}
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
        <button
            type="button"
            className="btn-gray2"
            name="modalgeneric"
            tabIndex={"200"}
            onClick={closeModalTicket}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>


      <Modalgeneric w="size-w-70" h="size-h-80" config={modalTablaConfig} closeModal={closeModaltabla}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>VENTAS PENDIENTES</Modalgeneric.Title>
          <TableModal
          config={modalTablaConfig}
            columns={[
                { header: "Clave", width:"w-32", accessor: "shortId" },
                { header: "Cliente", width:"w-24", accessor: "cliente" },
                { header: "Nombre", width:"w-72", accessor: "nombreCliente" },
                { header: "Fecha", 
                  width:"w-40", 
                    // eslint-disable-next-line react/display-name
                    code: (item) => (<LabelFecha className="font-size w-full">{item?.id||""}</LabelFecha>),
                },
                { header: "Total", 
                  width:"w-32", 
                    // eslint-disable-next-line react/display-name
                    code: (item) => (<Labelmoney twoDecimal={true} >{item?.total||0}</Labelmoney>),
                },
                { header: "Vendedor",
                  width:"w-28",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (<div className='flex flex-row w-full justify-between'>
                    <label className='font-size text-size'>{item?.vendedor||""}</label>
                    <button className='text-teal-600 hover:text-black'
                    onClick={e=>{
                      openmodalLoading();
                        useGetVenta(item.id,"",respuesta=>{
                          closemodalLoading();
                          const prods = respuesta.items;
                          let items = [];
                          if(prods.length>0){
                            prods.map(item=>{
                              if(item.id=="VENTA#SERVICE"){
                                item.servicios.map(serv=>{
                                  items.push(serv)
                                })
                              }
                              else if(item.id=="DEVOLUCION#"+getSucursal()){
                                console.log('dev',item.items)
                                item.items.forEach(product => {
                                  items.push({...product,cantidadVendida:product.cantidad*-1,importe:product});
                                });
                              }
                              else{
                                items.push(item);
                              }
                            })
                            setTicketSelect({items:items,venta:respuesta.venta})
                          }
                        },funcionError);
                    }}>
                      <i className='fas fa-eye'/>
                    </button>
                  </div>),
                },
            ]}
            onclickRow={(item,e) => {
              if(e.detail==2||e.key=="Enter"){
                getPendiente(item)
                closeModaltabla();
              }
            }}
            data={dataPendiente}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
    </>
  )
}

export default Pendientes;
