import React from 'react'
import Container from '../components/Container';
import MenuReportes from '../components/Reportes/MenuReportes';

const Reportes = ({ruta,text}) => {
  const new_ruta = ruta ||"", new_text= text||"";
    return (
      <Container bg="bg-mesh">
          <MenuReportes ruta={new_ruta} text={new_text}/>
      </Container>
    );
  };
  
  export default Reportes;
