import React from "react";
import PanelTablaCompra from "../components/Compras/PanelTablaCompra";
import HeaderCompra from "../components/Compras/HeaderCompra";
import TotalesCompra from "../components/Compras/TotalesCompra";
import Container from "../components/Container";

const Compra = () => {
  document.body.style = "background: #F9FAFB;";
  return (
    <Container bg="bg-mesh">
      <div className="flex flex-col w-full h-full gap-1 ">
        <div className=" w-full overflow-y-auto px-2 ">
            <HeaderCompra/>
        </div>
        <div className="relative h-75/100 overflow-x-auto ">
            <PanelTablaCompra />
        </div>
        <div className=" bottom-0 w-full ">
            <TotalesCompra />
        </div>
      </div>
    </Container>
  );
};

export default Compra;
