import React, { useState,useEffect} from "react";
import { connect } from "react-redux";
import { useGetVenta, useGetVentaAsync } from "../../../Hooks/useApis";
import { CalcularDescuento, CalcularPorcentajeMargenUtilidad, getTotal, OrderArrayAZ, tipoCelda, valueToMoney2 } from "../../../services/functions";
import Modalinfo from "../../modal/info";
import LoadingModal from "../../modal/LoadingModal";
import TableReactWindows from "../Cash/TableReactWindows";
import Modalsuccess from "../../modal/success";
import Modaldanger from "../../modal/danger";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";

const ReporteVentasFolio = ({PropsReportes,setHeader,addDatos,Datos,setPropsRep}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modalError, setModalError] = useState({}),
  [ventas,setVentas]=useState([]);
  const nameComponent = "ventasFolio",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const header = PropsReportes.header||{};


  const openModalError = (error) => { setModalError({isOpen:true,title:"",message:error||"Error de validacion"}) }
  const closeModalError=()=>{ setModalError({isOpen:false})}

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }
  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  function getMargenTotal(array,type) {
    const utilidadTotal = array.reduce((a, b) => parseInt(a) + parseInt(b.utilidad), 0);
    const total = array.reduce((a, b) => parseInt(a) + parseInt(b.importe), 0);
    return CalcularPorcentajeMargenUtilidad(utilidadTotal,total);
  }

  useEffect(() => {

    if(rutaActualRep!==nameComponent){return;}
    const oldHeader = {...PropsReportes.header};
    delete oldHeader.btns;
    oldHeader.btns = {direction:true}
    const newPropsRep = {...PropsReportes};
    newPropsRep.header = oldHeader;

    if(newPropsRep.header?.text!=="Sales Detail"){ newPropsRep.header.text = "Sales Detail"}

    const datos = Datos[nameComponent]||{};

    const getVentasAsync=async()=>{
      try{
        if(header.type=="PE"){
          let new_items=[];
          header.items.map(item=>{
            const new_item = {...item,id:"PEESPECIAL"};
            new_item.maker = new_item?.id.substring(0,2)||"";
            new_item.style = new_item?.id.substring(2,new_item?.id.length);
            new_item.descuento = (CalcularDescuento(item.precioVenta,0)*item.cantidadVendida);
            new_item.utilidad = (item.importe-(item.precioCompra*item.cantidadVendida));
            new_item.margen = CalcularPorcentajeMargenUtilidad(new_item.utilidad,new_item.importe);
            new_items.push(new_item)
          })
          //console.log('==>',new_items)
          new_items = OrderArrayAZ(new_items,'descripcion','string');

          setVentas(new_items);
          setPropsRep(newPropsRep);
          addDatos(nameComponent,[new_items,{folio:header.folio}]);
          return;
        }

        openmodalLoading();
        const resp = await useGetVentaAsync(header.folio);
        let new_items=[];
        resp.data.items.map(item=>{
          if(item.id=="VENTA#SERVICE"){
            item.servicios.map(serv=>{
              const new_serv = {...serv};
              new_serv.maker = serv?.id.substring(0,2)||"";
              new_serv.style = serv?.id.substring(2,serv?.id.length);
              new_serv.descuento = (CalcularDescuento(serv.precioVenta,serv.descuento)*serv.cantidadVendida);
              new_serv.utilidad = (serv.importe-(serv.precioCompra*serv.cantidadVendida));
              new_serv.margen = CalcularPorcentajeMargenUtilidad(new_serv.utilidad,new_serv.importe);
              new_items.push(new_serv);
            })
          }else{
            const new_item = {...item};
            new_item.maker = item?.id.substring(0,2)||"";
            new_item.style = item?.id.substring(2,item?.id.length);
            new_item.descuento = (CalcularDescuento(item.precioVenta,item.descuento)*item.cantidadVendida);
            new_item.utilidad= (item.importe-(item.precioCompra*item.cantidadVendida));
            new_item.margen = CalcularPorcentajeMargenUtilidad(new_item.utilidad,new_item.importe);
            new_items.push(new_item);
          }
        })
        setPropsRep(newPropsRep)
        setVentas(new_items);
        addDatos(nameComponent,[new_items,{folio:header.folio}]);
        closemodalLoading();

      }catch (error){
        closemodalLoading();
        openModalError(error);
      }
    }

    if(JSON.stringify(datos)=='{}'){
      getVentasAsync();
      return;
    }
    if(PropsReportes.header.folio==datos[1].folio){
      const reporteVentas =datos[0];
      setVentas(reporteVentas);
      newPropsRep.select = reporteVentas[0].shortId;
      setPropsRep(newPropsRep);
      return;
    }
      getVentasAsync();
  }, [PropsReportes.header?.folio,PropsReportes.rutas]);

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>


      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modaldanger config={modalError}>
        <Modaldanger.Message title={"Error"}>
          {modalError.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalError}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <TableReactWindows
        btnDownload={async(e,datos)=>{
          const data = datos||ventas;
          if(!data?.length){openModalError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            openModalError(workb.error);
            return
          }

          const nameFile="Ticket-"+PropsReportes.header?.folio,
          headerFile="Ticket: "+PropsReportes.header?.folio +"     Cliente: "+PropsReportes?.header?.cliente +" ("+PropsReportes?.header?.nombreCliente+")     Fecha: "+PropsReportes?.header?.day,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={'Maker':7,'Style':17,'Descripcion':60,'Cant':7,'Precio':15,'Descuento':15,'Importe':15,'Costo':12,'Profit':12,'Margen':9};
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((venta,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.maker||"");
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.style||"");
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(venta?.descripcion||"");
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).style("fontSize",10);
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(venta?.cantidadVendida||0);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(venta?.precioVenta||0));
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(venta?.descuento||0));
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(venta?.importe||0));
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(valueToMoney2(venta?.precioCompra||0));
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(valueToMoney2(venta?.utilidad||0));
            sheet1.cell(columnasXlsx[8]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[9]+(colPlus+index)).value((venta?.margen/100)||0);
            sheet1.cell(columnasXlsx[9]+(colPlus+index)).style("numberFormat", "0.00%");
          });
      
          sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[6]+sizeData).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[8]+sizeData).formula("SUM("+columnasXlsx[8]+""+colPlus+":"+columnasXlsx[8]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[8]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[9]+sizeData).formula("AVERAGE("+columnasXlsx[9]+""+colPlus+":"+columnasXlsx[9]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[9]+sizeData).style("numberFormat", "0.00%");
          
          const response =await SaveXlsx(workb,nameFile,true);
          if(response?.error){
            closemodalLoading();
            openModalError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
        }}
        columns={[
          {
            key:"maker",
            header: "Maker",
            width:65,
            className:"text-blue-800",
            type:tipoCelda.text,
          },
          {
            key:"style",
            header: "Style",
            width:150,
            className:"text-blue-800",
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"descripcion",
            header: "Descripcion",
            width:350,
            type:tipoCelda.text,
            filter:true,
            footer:"Total:",
          },
          {
            key:"cantidadVendida",
            header: "Qty",
            width:85,
            type:tipoCelda.number,
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"precioVenta",
            header: "UnitPrice",
            width:90,
            type:tipoCelda.money,
            filter:true,
          },
          {
            key:"descuento",
            header: "Discount",
            width:90,
            type:tipoCelda.money,
            filter:true,
          },
          {
            key:"importe",
            header: "Amount",
            width:90,
            type:tipoCelda.money,
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"precioCompra",
            header: "UnitCost",
            width:90,
            type:tipoCelda.money,
            filter:true,
          },
          {
            key:"utilidad",
            header: "Profit",
            width:85,
            type:tipoCelda.money,
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"margen",
            header: "Margin",
            width:80,
            type:tipoCelda.porcent,
            filter:true,
            footer:true,
            function:getMargenTotal
          }
        ]}

        data={ventas}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  setPropsRep: (props)=>dispatch({type:"SET_PROPS_REP",props:props})
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteVentasFolio);
