import React, { useState } from "react";
import { connect } from "react-redux";
import { useGetPes } from "../../Hooks/useApis";
import SearchSvg from "../../static/img/SearchSvg";
import Modalinfo from "../modal/info";
import LoadingModal from "../modal/LoadingModal";
import { useRef } from "react";

const InputPedido = ({ setItemsPes, addClient, cleanPes,headerPes,setFilterPes}) => {
  let input;
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({});
  const statusArray =["SOLICITADO","ACEPTADO","ENVIADO","PROCESO","ENTREGADO","RECIBIDO","FINALIZADO","CANCELADO"];

  const inputFiltro = useRef("");


  const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, titulo:titulo });};
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false }); };
  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }

  const funcionExito = (data) => {
    //console.log('==>',data.items[0]);
    closemodalLoading();
    if (data.items.length === 0) {
      setmodalinfoConfig({
        isOpen: true,
        message: "No existe el pedido: '" + input.value.toUpperCase() + "'",
      });
    }

    setItemsPes(data.items); 
  };

  const funcionError = (error) => {
    closemodalLoading();
    setmodalinfoConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

 
  const funcionBuscar = (e) => {
    if (!input.value.trim()) { return; }
    openmodalLoading();
    useGetPes(input.value.trim().toUpperCase(),funcionExito,funcionError);
    input.value = "";
  };



  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>{modalLoading.titulo||"Cargando Pedidos"}</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message
          title={"No se ha encontrado la clave en la base de datos."}
        >
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <div className="size-form w-full items-center gap-1">
          <input
            type="text"
            className="border-form uppercase w-full md:w-40"
            name="articulo"
            placeholder="Folio"
            style={{ transition: "all .15s ease" }}
            onKeyDown={e=>{
              if(e.key=="Enter"){
                funcionBuscar();
              }
            }}
            ref={(node) => (input = node)}
          />
          <button
            className="btn-gray1"
            type="submit"
            onClick={funcionBuscar}
            style={{ transition: "all .15s ease" }}
          >
          Buscar
          </button>
          <label className="font-size text-size">Status:</label>
          <select
          defaultValue={headerPes.statusFilter}
          className=" border-form w-full md:w-40"
          ref={inputFiltro}
          onChange={e=>{
            setFilterPes(e.target.value.toUpperCase());
          }}>
                {<option key={-1} defaultValue=""></option>}
                {Object.values(statusArray).sort().map((item,index)=>(
                    <option key={index} defaultValue={item}>{item}</option>
                ))}
          </select>
          <button 
            className="w-12" 
            onClick={e=>{
              setFilterPes(inputFiltro.current.value);
          }}>
          <SearchSvg width="w-5" height="h-5" text="text-gray-600"/>
          </button>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setItemsPes:(items)=> dispatch({type:"SET_ITEMS_ESP",items:items}),
  addClient: (cliente) =>dispatch({type: "ADD_CLIENT",item: cliente}),
  setFilterPes:(filtro)=>dispatch({type:"SET_FILTER_PES",filter:filtro}),
  cleanPes:()=> dispatch({type:"RESET_ITEMS_ESP"}),
});

const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
  headerPes: state.HeaderPes,
});

export default connect(mapStateToProps, mapDispatchToProps)(InputPedido);
