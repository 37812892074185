import React, { useState, useEffect} from "react";
import {  FechaLocalToUTC, FechaUTCToLocal, getArrayAsync, getSucursal, getTotal, OrderArrayAZ, tipoCelda } from "../../../services/functions";
import Modalinfo from "../../modal/info";
import { connect } from "react-redux";
import LoadingModal from "../../modal/LoadingModal";
import { useRef } from "react";
import { setRutaRep } from "../../../redux/Actions";
import TableReactWindows from "../Cash/TableReactWindows";

const ReporteCategorias = ({setRutaRep,PropsReportes,setHeader,addDatos,Datos}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [inventario,setInventario]=useState([]);
  const reporteInventario=useRef([]);
  const nameComponent = "inventarioCategoria",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];

  const handleError=(error)=>{closemodalLoading();}
  const closeModalinfo=(e)=> { setmodalinfoConfig({ isOpen: false });}
  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };

  
  useEffect(() => {
    if(PropsReportes.rutas.length!==1){return;}
    if(rutaActualRep!==nameComponent){return;}
    const getInventario= async()=>{
      reporteInventario.current=[];
      openmodalLoading();
      let peticion = {
        hashKey:{valor:"REP#LINEA#"+getSucursal(),nombre:"id"},
        sortKey:{valor:["2023-01-01",PropsReportes.fecha_Final],operador:"between",nombre:"type"},
      }

      try{
        const responseReporte = await getArrayAsync(peticion,[]);
        const newRegistro = {merma:0,devolucion:0,vendido:0,comprado:0,precioCompra:0,monto:0,cantTotal:0,before:0,total:0,fecha:""};
        let allRegistros = [],balance=[];
        const jsonBalance = {};

        responseReporte.forEach(rep=>{
          if(rep?.inventario){
            Object.keys(rep.inventario).forEach(key=>{
              if(!jsonBalance[key]){ jsonBalance[key]={...newRegistro}}

              if(rep.type<PropsReportes.fecha_Inicial){
                jsonBalance[key].before += rep.inventario[key].comprado
                jsonBalance[key].before -= rep.inventario[key].vendido
                jsonBalance[key].before += rep.inventario[key].merma
                jsonBalance[key].before -= rep.inventario[key].devolucion
                jsonBalance[key].monto += rep.inventario[key].monto;
              }else{
                jsonBalance[key].comprado += rep.inventario[key].comprado
                jsonBalance[key].vendido += rep.inventario[key].vendido
                jsonBalance[key].merma += rep.inventario[key].merma
                jsonBalance[key].devolucion += rep.inventario[key].devolucion
                jsonBalance[key].monto += rep.inventario[key].monto;
              }

            });
            allRegistros.push(rep)
          }
        })
        //console.log('>>',jsonBalance)

        Object.keys(jsonBalance).forEach(key=>{
          jsonBalance[key].cantTotal = jsonBalance[key].before + jsonBalance[key].comprado + jsonBalance[key].merma - jsonBalance[key].vendido - jsonBalance[key].devolucion
          jsonBalance[key].precioCompra = jsonBalance[key].monto / jsonBalance[key].cantTotal 
          balance.push({...jsonBalance[key],linea:key})
        })
        
        //console.log(jsonBalance)
        balance = OrderArrayAZ(balance,'linea','string');
        setInventario(balance)
        addDatos(nameComponent,[balance,{fechai:PropsReportes.fecha_Inicial,fechaf:PropsReportes.fecha_Final,allItems:allRegistros}]);
        closemodalLoading();

      }catch (Error){
        handleError(Error)
      }
    }
    const datos = Datos[nameComponent]||{};
    if(JSON.stringify(datos)=='{}'){
      getInventario();
    }else{
      if(PropsReportes.fecha_Inicial==datos[1].fechai&&PropsReportes.fecha_Final==datos[1].fechaf){
        setInventario(datos[0]);
        return;
      }
      getInventario();
    }
  }, [PropsReportes.fecha_Inicial,PropsReportes.fecha_Final,PropsReportes.rutas]);


  useEffect(()=>{
    if(PropsReportes.rutas.length!==1){return;}
    if(rutaActualRep!==nameComponent){return;}
     setHeader({text:"Inventory by Category"})
  },[PropsReportes.rutas])


  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
      <TableReactWindows
      columns={[
        {
          key:"linea",
          header:"Style",
          width:120,
          type:tipoCelda.text,
          filter:true,
          onClick:async({e,row,index})=>{
            if(e.detail==2){
              addDatos("inventarioMarca",[row,{linea:row.linea}])
              setRutaRep("inventarioMarca");
            }
            if(e.detail>1){return;}
            const propsHeader = {...PropsReportes.header};
            propsHeader.inout = row.linea.split('#')[0];
            setHeader(propsHeader);
          }
        },
        {
          key:"before",
          header:"QohBgn",
          width:95,
          type:tipoCelda.number,
          filter:true,
          footer:true,
          function: getTotal
        },
        {
          key:"comprado",
          header:"Rcvd",
          width:95,
          className:"text-blue-800",
          type:tipoCelda.number,
          filter:true,
          footer:true,
          function: getTotal
        },
        {
          key:"devolucion",
          header:"Return",
          width:95,
          className:"text-pink-800",
          type:tipoCelda.number,
          filter:true,
          footer:true,
          function: getTotal
        },
        {
          key:"merma",
          header:"Decrease",
          width:95,
          className:"text-pink-800",
          type:tipoCelda.number,
          filter:true,
          footer:true,
          function: getTotal
        },
        {
          key:"vendido",
          header:"Sold",
          width:95,
          className:"text-pink-800",
          type:tipoCelda.number,
          filter:true,
          footer:true,
          function: getTotal
        },
        {
          key:"cantTotal",
          header:"QohEnd",
          width:95,
          type:tipoCelda.number,
          filter:true,
          footer:true,
          function: getTotal
        },
        {
          key:"monto",
          header:"Amount",
          width:120,
          type:tipoCelda.money,
          filter:true,
          footer:true,
          function: getTotal
        }
      ]}
      data={inventario}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Categorias: state.Catalogos.CATEGORIAS.opciones,
  Datos: state.Datos,
});

export default connect (mapStateToProps,mapDispatchToProps)(ReporteCategorias);
