import axios from "axios";
import { mensajesGenerales } from "../static/dat/datos";
export const ceros_decimal = 10000;
export const maxlimite = 100000000*ceros_decimal;
export const decimales = 4;
export const fechaMin = "2023-01-01";
export const tiempoCookie = 60*60*14;
export const horasValidacion = 14;

const Crypto = require('crypto-js');


export const tipoCelda = {
  text:"text",
  money:"money",
  date:"date",
  porcent:"porcent",
  number:"number"
}
export const colors = {
  "gray":"#F2F2F2",
  "ambar":"#F3F781",
  "black":"#000000",
}

export const urlpath = () => {
  return process.env.REACT_APP_BASE_URL;
};


export const CalculaPrecioCompraPromediado=(precioCompra,cantCompra,oldPC,oldExist)=>{
  let pcp = redondeo(((oldPC*oldExist)+(precioCompra*cantCompra))/(cantCompra+oldExist)) ;
    if(oldExist<0){ pcp= precioCompra; }//si la existencia es negativa entonces ponemos el pcp=pc, para no reducir el pcp
    if(cantCompra==0){ pcp= oldPC;}
  return pcp
}
export const getCookieValue = (nombreCookie) => {
  var object = {};
  const cookies = document.cookie ? document.cookie.split("; ") : [];
  for (var i = 0; i < cookies.length; i++) {
    const parts = cookies[i].split("=");
    
    const foundKey = parts[0].replace(/%3D/g, "=").replace(/%3B/g, ";");
    if (nombreCookie === foundKey) {
      const value = parts.slice(1).join("=");
      if (value !== "{}") object[foundKey] = value;
      return object;
    }
  }
  return "";
};

export const getToken = () => {
  return getCookieValue("sessionIndigo").sessionIndigo;
};

export const getOffices = () => {
  return JSON.parse(localStorage.getItem("usuario") || "{}").alcance;
};

export const getRole = () => {
  return JSON.parse(localStorage.getItem("usuario") || "{}").rol;
};

export const getSesion = () => {
  return JSON.parse(localStorage.getItem("usuario") || "{}").validacion;
};

export const getUtilMin = () => {
  return parseFloat(localStorage.getItem("utilidadMinima"));
};

export const User = (field) => {
  return JSON.parse(localStorage.getItem("usuario") || "{}")[field];
};

export const getSucursal = () => {
  return JSON.parse(localStorage.getItem("usuario") || "{}").sucursal;
};

export const getPermisos = () => {
  return JSON.parse(localStorage.getItem("usuario") || "{}").permisos;
};

export const getNombre = () => {
  return JSON.parse(localStorage.getItem("usuario") || "{}").nombre.split(
    " "
  )[0];
};

export const getIdUser = () => {
  return JSON.parse(localStorage.getItem("usuario") || "{}").id;
};

export const getAlcance =()=>{
  return JSON.parse(localStorage.getItem("usuario")||"{}").alcance
}

export const Sucursal = (field) => {
  return JSON.parse(localStorage.getItem("sucursal") || "{}")[field];
};

export const SucursalAll = () => {
  return JSON.parse(localStorage.getItem("sucursal") || "{}");
};

export const getFieldDatos = (field) => {
  return JSON.parse(localStorage.getItem("Datos") || "{}")[field];
};
export const getDatos = (field) => {
  return JSON.parse(localStorage.getItem("Datos") || "{}");
};


export const headerApi = {
  Authorization: "Bearer " + getToken(),
  role: getRole(),
  "Content-Type": "application/json;charset=UTF-8",
};






export const TransformSys=(cliente)=>{
  return (cliente=="SYS#"+getSucursal()?"SYS":cliente)
}
export const TransformSysAll=(cliente)=>{
  let new_client = cliente.split('#');
  if(new_client.length){new_client = new_client[0]}
  else{new_client=cliente}
  return new_client;
}

export const TransformSupplier=(supplier)=>{
  let new_supplier = supplier.split('#');
  if(new_supplier.length){new_supplier = new_supplier[0]}
  else{new_supplier=supplier}
  return new_supplier;
}

export  const TransformIndex = (campo)=>{
  const new_campo = campo.split('#')[0];
  return new_campo;
}

export const moneySucursal = () => {
  return Sucursal("moneda")["signo"] || "$";
};

export const redondeo = (numero) => {
  return Math.round(numero);
};

export const ConvertDecimal=(numero,dec)=>{
  //console.log('number>',numero,' Decimal>',dec,' Result>',Number(numero.toFixed(dec)))
  return Number(numero.toFixed(dec))
}

export const moneyToValue = (numero) => {
  return redondeo(Number(numero) * ceros_decimal);
};

export const valueToMoney = (numero) => {
  return (parseFloat((Number(numero) / ceros_decimal).toFixed(decimales)));
};

export const valueToMoney2= (numero) => {
  return parseFloat((Number(numero) / ceros_decimal).toFixed(2));
};

export const ConvertTwoDecimal= (numero) => {
  return parseFloat(numero.toFixed(2));
};


export const showMoney = (numero) => {
  let nuevoNumero = numero ?? 0;
  if (!Number.isInteger(nuevoNumero)) {"" }
    return moneySucursal() + "" + ConvertMoneyLabel2(nuevoNumero);
};

export const showMoneyAll = (numero) => {
  let nuevoNumero = numero ?? 0;
  if (!Number.isInteger(nuevoNumero)) {"" }
    return moneySucursal() + "" + ConvertMoneyLabel(nuevoNumero);
};

export const CalculaMinPrecioVenta=(precioCompra,categoria,minimo)=>{
  let minutil = getUtilMin();
  if(minimo){
    return redondeo(precioCompra/(1-(minutil/100))); //redondeo(precioCompra+((precioCompra/100)*minutil));
  }
  if(categoria){
    minutil = Sucursal('categorias').filter(min=>min[categoria])[0][categoria];
  }
  //console.log(minutil)
  return redondeo(precioCompra/(1-(minutil/100))); //redondeo(precioCompra+((precioCompra/100)*minutil));
}


export const getDescuentoMax = (pMin,pVenta) => {
 const minDesc = Math.trunc(parseFloat(((1-(pMin/pVenta))*100)));
  return (minDesc<0?0:minDesc*1)
}

export const getMargenUtilidad = (pv,pc) => {
  const margin = ((pv-pc)/pv*100).toFixed(decimales);
   return margin*1;
 }
export const CalcularPorcentajeMargenUtilidad = (utilidad,total) =>{
  const div =(utilidad/total)||0;
  return Number((div*100).toFixed(2));
}
export const getImporte =(precio,desc,cantidad)=>{
  return redondeo(precio * (1 - desc / 100) * cantidad)
}

export const CalcularDescuento=(precio,porcentaje)=>{
  return redondeo((precio/100)*porcentaje)
}
export const CalcularDescuentoPorPorcentaje=(precio,porcentaje)=>{
  return (Number(porcentaje)/100)*precio;
}

export const agregarItemVenta =(item,addItem,descuento,itemsVenta,recuperacion)=> {
  if(!ValidaSucursal(item)){ return {type:"habilitar",item:item}; }
  const itemAux = {...item};
  let Errors;
  const itemProps = itemAux.sucursales.[getSucursal()];
  if(!itemProps?.vendible||itemProps?.precioVenta<=0) { return {type:"vendible",item:itemAux}; }
  const precioCompra = itemProps.precioCompra;
  const precioVenta = itemProps?.precioVenta;
  const exist = itemsVenta.filter(it=>it.id==item.id&&it.descripcion==item.descripcion);
  let newCant = (itemAux.categoria=="MICA"?(itemProps.existencia>=2?2:1):1);

  if(exist.length>0){
    itemAux.cantidad=exist[0].cantidad+1;
    newCant=itemAux.cantidad;
  }else if(recuperacion&&itemAux?.cantidad){
    newCant = itemAux.cantidad;
    
    if(itemAux?.categoria!=="SERVICIO"&&itemProps.existencia<newCant&&itemProps.existencia>0){
        //console.log(itemProps.existencia,'    newCant:',newCant);
       return {type:"insuficiente",item:itemAux}; 
    }
  }
  
  try {
      if(itemAux?.categoria=="SERVICIO"){
        itemAux.minPrecioVenta = precioCompra;
      }else{
        if(itemProps.existencia<newCant&&!itemAux?.existenciaCero){ console.log('detail art'); throw "existencia"; }
        itemAux.minPrecioVenta = CalculaMinPrecioVenta(precioCompra,itemAux.categoria);
      }
      itemAux.precio = (itemAux.minPrecioVenta>precioVenta?itemAux.minPrecioVenta:precioVenta);
      itemAux.descuentoMax = getDescuentoMax(itemAux.minPrecioVenta,itemAux.precio);
      itemAux.margen = getMargenUtilidad(itemAux.precio,precioCompra);

      if(itemProps?.oferta){//las ofertas el minpv es el pc
        const descMax =  getDescuentoMax(precioCompra,itemAux.precio)
        itemAux.descuentoMax = descMax;
        itemAux.minPrecioVenta = precioCompra;
        itemAux.descuento = (itemProps.oferta>descMax?descMax:itemProps.oferta);
      }else if(descuento>0){//cuando el cliente tiene descuento especial hay que cambiarlo por caetegoria los descuentos de cada cliente
        itemAux.descuento= (descuento>itemAux.descuentoMax?itemAux.descuentoMax:descuento);
      }else if(itemAux?.descuento){//si el producto ya tiene descuento aplicarlo
        itemAux.descuento = (itemAux?.descuento?itemAux.descuento:0);
      }else{//si el descuento es general, el maximo de descuento sera lo que se indique en configuraciones
        const desc=Sucursal("descuentos");
        const cats ={};
        desc.forEach(desc=>{cats[Object.keys(desc)[0]] = desc[Object.keys(desc)[0]];});
        const desCat = cats[itemAux.categoria];
       
        const descMax =  getDescuentoMax(itemAux.minPrecioVenta,itemAux.precio);
        itemAux.descuentoMax = descMax;
        
        let descuento = (desCat>descMax?descMax:desCat);

        (itemAux.categoria=="SERVICIO"?itemAux.descuento=0:itemAux.descuento=descuento);
      }
      
      addItem(itemAux);
    } catch (e) { 
      if(e=="existencia"){return {type:"existencia",item:itemAux}}
      else if(e=="insuficiente"){return {type:"insuficiente",item:itemAux}}
      return {type:(e||"nofound"),item:itemAux}
    }

  return Errors;
}

export const ValidaSucursal=(item)=>{
  let Exist = true;
  if(!item.sucursales?.[getSucursal()]){Exist=false;}
  const propsItem = item.sucursales.[getSucursal()];
  if(!item.sucursales?.[getSucursal()]?.precioCompra){Exist=false;}
  if(item.sucursales?.[getSucursal()]?.precioCompra<=0){ Exist=false;}
  return Exist;
}


export const formatoYMDtoDMY=(fecha,separador)=>{
  const split =fecha.split('-'),sep=separador||"-";
  return split[2]+sep+split[1]+sep+split[0];
}

export const GetFecha=(fecha)=>{
  let new_fecha = "";
  if(fecha.indexOf("#")>0){
    new_fecha = fecha.substring(fecha.indexOf("#")+1,fecha.indexOf("#")+11);
  }else{
    new_fecha = fecha.substring(1,fecha.length);
  }
  return new_fecha;
}
export const GetFechaNoWeekEnds=(fecha)=>{
  var day = new Date(fecha).getUTCDay();
  if(day!==0&&day!==6) return true
  else return false
}
export const RestaFechas=(fechai,fechaf)=>{
  const fechaa = new Date(fechai).toISOString().substr(0,10);
  const fechab = new Date(fechaf).toISOString().substr(0,10);
  const fecha1 = new Date(fechaa);
  const fecha2 = new Date(fechab);
  const dif = fecha2.getTime() - fecha1.getTime();

  return (Math.round(dif/ (1000*60*60*24)));
}
export const RestaMinutos=(fechai,fechaf)=>{
  const date1 = new Date(fechai);
  const date2 = new Date(fechaf);

  const dif = date1 - date2;
  return  (Math.round(dif / (1000 * 60)));
}
export const AddDiasFecha=(fecha,dias,horas)=>{
  horas?fecha = fecha+" "+horas:"";
  fecha = new Date(fecha);
  fecha.setDate(fecha.getDate()+dias);
  //
  return fecha.toLocaleDateString('fr-ca',{timeZone: Sucursal('timezone')});
}
export const AddHoras=(fecha,horas,utc)=>{
  fecha = new Date(fecha+(utc?"Z":""));
  fecha.setHours(fecha.getHours()+horas);
  //
  return fecha.toISOString().replace('Z','');
}
export const AddMinutos=(fecha,minutos,utc)=>{
  fecha = new Date(fecha+(utc?"Z":""));
  fecha.setMinutes(fecha.getMinutes()+minutos);

  //
  return fecha.toISOString().replace('Z','');
}
export const GetFechaEstimada=(fechaActual)=>{
  let fechai = new Date(fechaActual),contadorDias=0;

  while(contadorDias<8){
    if(GetFechaNoWeekEnds(fechai)){contadorDias++;}
    fechai.setDate(fechai.getDate()+1);
  }
   return fechai.toISOString().substr(0,10);
}
export const FechaUTCToLocal=(fechautc)=>{
  const fechaLocal = new Date(fechautc+"Z");
  const fecha = fechaLocal.toLocaleDateString('fr-ca',{timeZone: Sucursal('timezone')});
  const hora = fechaLocal.toLocaleTimeString('es-MX',{timeStyle:'short',hour12:false,timeZone: Sucursal('timezone')});
  return {fecha:fecha,hora:hora};
}

export const FechaLocalToUTC=(fechalocal,hora,min,sec)=>{
  const h =hora||0,m=min||0,s=sec||0;
  const newfechaLocal = new Date(fechalocal);
  const fechautc = new Date( new Date(newfechaLocal.getFullYear(),newfechaLocal.getMonth(),(newfechaLocal.getDate()+1),h,m,s)).toISOString();
  return fechautc.replace('Z','');
}
export const FechaLocalToUTCDateHour=(fechalocal,hour,min,sec)=>{
  const h =hour||0,m=min||0,s=sec||0;
  const newfechaLocal = new Date(fechalocal);
  const fechautc = new Date( new Date(newfechaLocal.getFullYear(),newfechaLocal.getMonth(),(newfechaLocal.getDate()+1),h,m,s));

  const fecha = fechautc.toLocaleDateString('fr-ca',{timeZone: Sucursal('timezone')});
  const hora = fechautc.toLocaleTimeString('es-MX',{timeStyle:'short',hour12:false,timeZone: Sucursal('timezone')});
  return {fecha:fecha,hora:hora};
}
export const GetFechaSplit=()=>{
  const fechaLocal= new Date();
  const año =fechaLocal.getFullYear().toString();
  const mes = (fechaLocal.getMonth()<9?("0"+""+(fechaLocal.getMonth()+1)):fechaLocal.getMonth()+1).toString(); 
  const dia = (fechaLocal.getDate()<10?("0"+""+fechaLocal.getDate()):fechaLocal.getDate().toString()); 

  return {año:año,mes:mes,dia:dia};
}
export const GetFechaActual=()=>{
  const fechaLocal= new Date();
  const fecha =fechaLocal.toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
  const hora = fechaLocal.toLocaleTimeString('es-MX',{timeStyle:'short',hour12:false,timeZone: Sucursal('timezone')});
  return {fecha:fecha,hora:hora};
}


export const fechaMax = GetFechaActual().fecha;

export const validaFecha=(fechai,fechaf)=>{
  const new_fecha = {fechai:fechai,fechaf:fechaf};
  if(fechai<fechaMin){
      new_fecha.fechai = fechaMin;
  }
  if(fechaf<fechaMin){
      new_fecha.fechaf = fechaMin;
  }
  if(fechaf>fechaMax){
      new_fecha.fechaf = fechaMax;
  }
  if(fechai>new_fecha.fechaf){
      new_fecha.fechai = new_fecha.fechaf;
  }
  return new_fecha;
}

export const GetFechaActualSeparado=()=>{
  const fechaLocal= new Date();
  const fecha =fechaLocal.toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
  const hora = fechaLocal.toLocaleTimeString('es-MX',{timeStyle:'short',hour12:false,timeZone: Sucursal('timezone')});
  const separate = fecha.split('-');
  return {ano:separate[0],mes:separate[1],dia:separate[2],hora:hora};
}

export const GetFechaSeparado=(fecha)=>{
  const separate = fecha.split('-');
  return {ano:separate[0],mes:separate[1],dia:separate[2]};
}

export const GetFechaActualUTC=()=>{
  const fecha=new Date().toISOString().replace('Z','');
  //
  return fecha;
}
export const GetFechaHora=(date)=>{
  const fechaLocal = new Date(date);
  const fecha = fechaLocal.toLocaleDateString('fr-ca',{timeZone: Sucursal('timezone')});
  const hora = fechaLocal.toLocaleTimeString('es-MX',{timeStyle:'short',timeZone: Sucursal('timezone')});

  return {fecha:fecha,hora:hora};
}
export const GetFechaLocal=(fecha)=>{
  return new Date(fecha).toLocaleString();
}
export const GetFirtsLastMonth=(fecha)=>{
  const new_fecha = new Date(fecha),año = new_fecha.getFullYear(),mes = new_fecha.getMonth();
  return {
    primer: new Date(año,mes,1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')}),
    ultimo: new Date(año,mes+1,0).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')})
  }
}
export const GetFirstLastWeek=(date)=>{
  let fecha = new Date(date);

  return {
    primer:new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate() - fecha.getDay() + 1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')}),
    ultimo: new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate() + 7 - fecha.getDay()).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')})
  }
}

export const GetFirstLastWeekReports=(date)=>{
  let fecha = new Date(date);

  return {
    primer:new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate() - fecha.getDay() + 1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')}),
    ultimo: new Date().toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')})
  }
}


function funcionError(data) {
  
}

function funcionExito(error) {
  
}

export const apiUse = (myobject) => {
  const functionsuccess = myobject.handleSuccessful || funcionExito;
  const functionerror = myobject.handleError || funcionError;
  //const token = getToken();
  const diferenciaMinutos =RestaMinutos(getSesion(),GetFechaActualUTC());

  if(!myobject?.login&&diferenciaMinutos<=0){
      return functionerror({message:mensajesGenerales.login,response:{data:{message:mensajesGenerales.login}}})
  }

  const myheaders = {
    Authorization: "Bearer " + getToken(),
    role: getRole(),
    "Content-Type": "application/json;charset=UTF-8",
  };

  axios({
    url: urlpath() + myobject.url,
    method: myobject.method,
    data: myobject.data
      ? {
          data: JSON.stringify(myobject.data),
        }
      : JSON.stringify(myobject.customdata),
    headers: Object.assign(myheaders, myobject.headers),
  }).then((response) => {
      functionsuccess(response.data);
  }).catch((error) => {
      functionerror(error);
  });
};


export const apiUsePromise = async(myobject) => {
  const diferenciaMinutos =RestaMinutos(getSesion(),GetFechaActualUTC());

  if(!myobject?.login&&diferenciaMinutos<=0){
    throw new Error(mensajesGenerales.login);
  }

  const myheaders = {
    Authorization: "Bearer " + getToken(),
    role: getRole(),
    "Content-Type": "application/json;charset=UTF-8",
  };
  const resp = axios({
    url: urlpath() + myobject.url,
    method: myobject.method,
    data: myobject.data
      ? {
          data: JSON.stringify(myobject.data),
        }
      : JSON.stringify(myobject.customdata),
    headers: Object.assign(myheaders, myobject.headers),
  })

  return resp
};

export const apiUseProd = (myobject) => {
  const functionsuccess = myobject.handleSuccessful || funcionExito;
  const functionerror = myobject.handleError || funcionError;
  const diferenciaMinutos =RestaMinutos(getSesion(),GetFechaActualUTC());

  if(!myobject?.login&&diferenciaMinutos<=0){
    return functionerror({message:mensajesGenerales.login})
  }
  const myheaders = {
    Authorization: "Bearer " + getToken(),
    role: getRole(),
    "Content-Type": "application/json;charset=UTF-8",
  };

  axios({
    url: urlpath() + myobject.url,
    method: myobject.method,
    data: myobject.data
      ? {
          data: myobject.data,
        }
      : JSON.stringify(myobject.customdata),
    headers: Object.assign(myheaders, myobject.headers),
  })
    .then((response) => {
      functionsuccess(response.data);
    })
    .catch((error) => {
      functionerror(error);
    });
};

const logOut = () => {
  document.cookie =
    "sessionIndigo=; max-age=0; expires=Thu, 01 Jan 1970 00:00:00 GMT";
  localStorage.clear();
};

export const datauserexist = () => {
  if (getCookieValue("sessionIndigo") && localStorage.getItem("usuario") && localStorage.getItem("sucursal")){
    let bgcolor = "bg-bluei",textcolor="text-bluei";
    const user = JSON.parse(localStorage.getItem("usuario"));
    if( getCookieValue("sessionIndigo") ){
      bgcolor =(getSucursal()==User('sucursalOrigen')?"bg-bluei" : "bg-second")
      textcolor =(getSucursal()==User('sucursalOrigen')?"text-bluei" : "text-second")
    }
    user.bgcolor =bgcolor;
    user.textcolor = textcolor
    return user;
  }else{
    logOut();
    return {};
  }
};

export const datasucursalexist = () => {
  if (
    getCookieValue("sessionIndigo") &&
    localStorage.getItem("usuario") &&
    localStorage.getItem("sucursal")
  ) {
    return JSON.parse(localStorage.getItem("sucursal"));
  } else {
    logOut();
    return {};
  }
};

const isObject = (data) => {
  return data && data.constructor === Object;
};

export const updating = (data) => {
  return isObject(data) && Object.keys(data).length > 0;
};

export const creating = (data) => {
  return isObject(data) && Object.keys(data).length === 0;
};

export function havePermission(permission) {
  return getPermisos()?.includes(permission);
}

export function disabledFieldIsNot(validatePermissionArray) {
  for (const permission of validatePermissionArray) {
    if (permission === true) {
      return false;
    }
  }

  return true;
}

export function validatePermission(data) {
  function createPermission(permission) {
    return creating(data) && havePermission(permission);
  }
  function updatePermission(permission) {
    return updating(data) && havePermission(permission);
  }

  return { create: createPermission, update: updatePermission };
}


export function  OrderArrayAZ(array,campo,type) {
  let filtro =  [];
  if(type=="string"){
    filtro = [...array].sort((fa,fb)=>fa[campo].toString().localeCompare(fb[campo].toString()));
  }else if(type=="number"){
    filtro = [...array].sort((fa,fb)=>(fa?.[campo])-(fb?.[campo]));
  }else{
    console.log('type not defined');
  }
  return filtro;
}

export function  OrderArrayZA(array,campo,type) {
  let filtro =  [];
  if(type=="string"){
    filtro = [...array].sort((fa,fb)=>fb[campo].toString().localeCompare(fa[campo].toString()));
  }else if(type=="number"){
    filtro = [...array].sort((fa,fb)=>(fb?.[campo])-(fa?.[campo]));
  }else{
    console.log('type not defined');
  }
  return filtro;
}

export function filtroItems(arrayItems,itemFilter,campo,type){
  let filtro =  [...arrayItems].filter(row=>row[campo] ==itemFilter[campo]);
  if(type=="date"){
    filtro = [...arrayItems].filter(row=>FechaUTCToLocal(row[campo]).fecha == FechaUTCToLocal(itemFilter[campo]).fecha)
  }

  if(filtro.length==0){
    filtro = arrayItems;
  }
  return filtro;
}

export function filtroItemsSelect(arrayItems,texto,campo,type){

  let filtro =  [...arrayItems].filter(row=>(row[campo]+'').includes(texto));
  if(type=="money"){
    const valor = moneyToValue(texto);
    filtro = [...arrayItems].filter(row=>row[campo]==valor);
  }
  if(filtro.length==0){
    filtro = arrayItems;
  }
  return filtro;
}

export function filtroItemsMajor(arrayItems,itemFilter,campo,type){
  let filtro =  [...arrayItems].filter(row=>row[campo]>=itemFilter[campo]);
  if(filtro.length==0){
    filtro = arrayItems;
  }
  return filtro;
}

export function filtroItemsMinor(arrayItems,itemFilter,campo,type){
  let filtro =  [...arrayItems].filter(row=>row[campo]<=itemFilter[campo]);
  if(filtro.length==0){
    filtro = arrayItems;
  }
  return filtro;
}


export function filtroItemsExcept(arrayItems,itemFilter,campo){
  let filtro =  [...arrayItems].filter(row=>row[campo] !==itemFilter[campo]);
  if(filtro.length==0){
    filtro = arrayItems;
  }
  return filtro;
}

export const filtroValoresUnicos=(arrayItems,campo)=>{
  let filtro =[...arrayItems].reduce((acc, current) => {
  if([...arrayItems].filter(row => row[campo].trim() === current[campo].trim()).length > 1){
      return acc;
  }else { return [...acc, current];}
  },[])

  return filtro;
}

export const filtroValoresRepetidos=(arrayItems,campo)=>{
  const frecuencia = [...arrayItems].reduce((acc,item)=>{
    acc[item[campo].trim()] = (acc[item[campo].trim()]||0)+1;
    return acc;
  },{})

  const repetidos = [...arrayItems].filter(item=> frecuencia[item[campo].trim()]>1);

  return repetidos;
}

const clave2 = {
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  A: 10,
  B: 11,
  C: 12,
  D: 13,
  E: 14,
  F: 15,
  G: 16,
  H: 17,
  I: 18,
  J: 19,
  K: 20,
  L: 21,
  M: 22,
  N: 23,
  O: 24,
  P: 25,
  Q: 26,
  R: 27,
  S: 28,
  T: 29,
  U: 30,
  V: 31,
  W: 32,
  X: 33,
  Y: 34,
  Z: 35,
};




const  clave = {0:"0", 1:"1",2:"2",3:"3",4:"4",5:"5",6:"6",7:"7",8:"8",9:"9",10:"A",11:"B",12:"C",13:"D",14:"E",15:"F",16:"G",17:"H",18:"I",19:"J",20:"K",21:"L",22:"M",23:"N",24:"O",25:"P",26:"Q",27:"R",28:"S",29:"T",30:"U",31:"V",32:"W",33:"X",34:"Y",35:"Z"}

function decimalTo36(dec) {
  let id = "";

  while (dec > 0) {
      id = clave[dec % 36] + id;
      dec = Math.floor(dec / 36);
  }
  return id;
}

export function IdToShortId(db_id) {
  const date = new Date(db_id+"Z");
    const uidTimestamp = Math.floor(date.getTime() / 100);  // Convertir milisegundos a 10x segundos.
  return decimalTo36(uidTimestamp);
}

function getKey(val) {
  for (let key in clave) {
      if (clave[key] === val) {
          return parseInt(key);  // Convertir la clave string a entero
      }
  }
  return null;  // Retorna null si no encuentra el valor
}
export function shortIdToId(shortId) {
  let arr = 0;
  let potencia = shortId.length - 1;

  for (let x of shortId) {
      arr += getKey(x) * Math.pow(36, potencia);
      potencia -= 1;
  }
  
  const posix = arr / 10;

  // Convertir el timestamp a formato ISO
  let date = new Date(posix * 1000).toISOString().replace('Z','');
  const milesimas = date.split('.');
  if(milesimas.length==2){
    (milesimas[1].charAt(0)=='0')?date = milesimas[0]:date= date+"000";
  }
  
  return date;
}

export const ValidaDinero = (numero) => {
  //
  if (!Number.isInteger(numero)) {
    console.log("No es numero Entero: ", numero);
  }
  return (numero / 100).toFixed(decimales);
};

export const ParseFormatMoney=(money)=>{
  const match = ValidFormatMoney(money);
  const reales = getReales(match[1]);
  return reales+match[2];
}

export function formatMoney(valor){
  if(valor.length>3){
    let newval="",signo="",cont=0;
    if(valor[0]=="-"){
      signo="-";
      valor= valor.replace('-', '');
    }
    const aux=valor.split('').reverse().join('');
     for(let i=0;i<valor.length;i++){
       if(cont==3){
         newval+=(",");
         cont=0;
       }
       newval+=aux.charAt(i);
       cont++;
     }
     const kk = newval.split('').reverse().join('');
    return signo+kk
  }else{
    return valor;
  }
 }

 export function ConvertMoneyLabel(valor){
  const no_separator = valueToMoney(valor).toString().replace(/,/g,'');
  const match = /(-?\d*)(\.?)(\d?\d?\d?\d?)/g.exec(no_separator);
  if(!match[1]&&!match[2]&&!match[3]){
    return 0;
  }else{
    if(!isNaN(Number(match[1]+match[2]+match[3]))){
      return (formatMoney(match[1])+match[2]+match[3])
    }else{
      if(match[1]=="-"&&match[2]){
        if(match[3]){return formatMoney("-0"+match[2]+match[3])
        }else{return formatMoney("-0"+match[2]+match[3])}
      }
      else{return valor}
    }
  }
}

export function ConvertMoneyLabel2(valor){
  const no_separator = valueToMoney2(valor).toString().replace(/,/g,'');
  const match = /(-?\d*)(\.?)(\d?\d?\d?\d?)/g.exec(no_separator);
  if(!match[1]&&!match[2]&&!match[3]){
    return 0;
  }else{
    if(!isNaN(Number(match[1]+match[2]+match[3]))){
      return (formatMoney(match[1])+match[2]+match[3])
    }else{
      if(match[1]=="-"&&match[2]){
        if(match[3]){return formatMoney("-0"+match[2]+match[3])
        }else{return formatMoney("-0"+match[2]+match[3])}
      }
      else{return valor}
    }
  }
}

 export function ConvertMoneytoFormat(valor,aux,setaux){
  const no_separator = valor.replace(/,/g,'');
  const match = /(-?\d*)(\.?)(\d?\d?\d?\d?)/g.exec(no_separator);
  if(!match[1]&&!match[2]&&!match[3]){
    setaux({text:"",number:0})
  }else{
    if(!isNaN(Number(match[1]+match[2]+match[3]))){
      setaux({...aux,text:(formatMoney(match[1])+match[2]+match[3]),number:Number(match[1]+match[2]+match[3])})
    }else{
      if(match[1]=="-"&&match[2]){
        if(match[3]){
          setaux({...aux,text:formatMoney("-0"+match[2]+match[3]),number:("-0."+match[3])})
        }else{
          setaux({...aux,text:formatMoney("-0"+match[2]+match[3]),number:0})
        }
      }else{
        setaux({...aux,text:valor})
      }
    }
  }
}

export function ConvertMoneytoFormatReturn(valor){
  const no_separator = valor.replace(/,/g,'');
  const match = /(-?\d*)(\.?)(\d?\d?\d?\d?)/g.exec(no_separator);
  if(!match[1]&&!match[2]&&!match[3]){
    return "";
  }else{
    if(!isNaN(Number(match[1]+match[2]+match[3]))){
      return (formatMoney(match[1])+match[2]+match[3]);
    }else{
      if(match[1]=="-"&&match[2]){
        if(match[3]){
          return(formatMoney("-0"+match[2]+match[3]));
        }else{
          return formatMoney("-0"+match[2]+match[3]);
        }
      }else{
        return valor;
      }
    }
  }
}

export const ValidFormatMoney=(valor)=>{
  return /(\d{0,10})[^.]*((?:\.\d{0,2})?)/g.exec(valor);
}

export function getReales(valor){
  const arre= []
  const no_space = Number(valor.replace(',','')).toString();
  if(no_space.length>3){
      for(var i=no_space.length;i>=0;i=i-3){
          if(i-3>0){ arre.push(no_space.substring(i,i-3))
          }else{arre.push(no_space.substring(i,0));break; }
      }
      return arre.reverse().join(',');
  }else{ return no_space}
}


const clave1 = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "A",
  11: "B",
  12: "C",
  13: "D",
  14: "E",
  15: "F",
  16: "G",
  17: "H",
  18: "I",
  19: "J",
  20: "K",
  21: "L",
  22: "M",
  23: "N",
  24: "O",
  25: "P",
  26: "Q",
  27: "R",
  28: "S",
  29: "T",
  30: "U",
  31: "V",
  32: "W",
  33: "X",
  34: "Y",
  35: "Z",
};

export const decimal_to_36 = (dec) => {
  let id = "";
  while (dec > 0) {
    id = clave1[dec % 36] + id;
    dec = Math.floor(dec / 36);
  }
  return id;
};

export const generate_id = (midate) => {
  let posix = Math.floor(new Date(midate).getTime() / 100);
  return decimal_to_36(posix);
};

const WorkQueue = () => {
  let data_queue = [];
  let work_queue = [];
  let workOverData_queue = [];

  const addTask = (callback) => {
    work_queue.push({ callback: callback, work_type: "task" });
  };

  const addTaskOverData = (callback) => {
    workOverData_queue.push({ callback: callback, work_type: "taskOverData" });
  };

  const addData = (data) => {
    data_queue = data_queue.concat(data);
  };

  const tasksRunner = () => {
    let action;
    while (work_queue.length > 0) {
      if (work_queue[0].work_type === "task") {
        action = work_queue.shift();
        action.callback();
      }
    }
  };

  function chunkArray(myArray, chunk_size = 10) {
    var results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }

    return results;
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  function executeChunk(data, index, wait = 1000) {
    delay(wait * index)
      .then(() => {
        while (data.length > 0) {
          var current = new Date();
          
          workOverData_queue[0].callback(data.shift());
        }
      })
      .catch(() => {});
  }

  
  
  const taskRunnerOverData = (config) => {
    let chunkData = chunkArray(data_queue, config.chunk);
    let index = 0;
    while (chunkData.length > 0) {
      try {
        index = index + 1;
        executeChunk(chunkData.shift(), index, config.wait);
      } catch (e) {
        console.log('catch task')
      }
    }
  };

  return {
    tasksRunner: tasksRunner,
    addTask: addTask,
    taskRunnerOverData: taskRunnerOverData,
    addData: addData,
    addTaskOverData: addTaskOverData,
  };
};

export function CalculaPagos(pagos,money){
  let pagado = 0;
  if(money){
    pagos.forEach(pago => {pagado = pagado + parseInt(moneyToValue(pago.monto || 0))});
  }else{
    pagos.forEach(pago => {pagado = pagado + parseInt(pago.monto || 0) });
  }
  return pagado;
}

export function CalcularCreditoUsado(comprasCredito){
  let creditoUsado = 0;

  comprasCredito.forEach(credito =>{
    creditoUsado = creditoUsado + (credito.importe - CalculaPagos(credito.pagos,false))
  })
  return creditoUsado;
}



export function GetItemsPes (datos){

  const byfield=(a, b)=>{
      if (a["id"] > b["id"]) return 1;
      if (a["id"] < b["id"]) return -1;
    return 0;
  };

  let pedidos = []
  datos.forEach(element => {
    const esp = {...element};
    let its = [];
    esp.timeOut =false;// (RestaFechas(GetFechaActual().fecha,element.fechaEstimada)>=0)?false:(["RECIBIDO","ENTREGADO","ENVIADO","FINALIZADO"].includes(esp.status)?false:true);
    esp.items.forEach(it=>{
      its.push({...it,precioVentaIni:it.precioVenta})
    });
    esp.items = its;
    esp.date= esp.id.split('#')[1];
    delete esp.type;
    delete esp.statusTemp;
    pedidos.push(esp);
  });

  return pedidos.sort(byfield);
}

export function SearchValueInObject(object,valor){
  return Object.keys(object).filter(key=>{return object[key]==valor})
}

export function SearchKeyInObject(object,valor){
  return Object.keys(object).filter(key=>{return key==valor})
}

export function SearchValueArray(array,valor){
  return array.filter(val=>{return val==valor})
}

export function CreateCode(value,num,lista){
  const separateValue=(valor)=>{
    const words = valor.trim().split(' ');
    if(words.length==1){
      if(valor.length<=num){ return foundValue(valor);}
      return foundValue(valor.substring(0,num));
    }else if(words.length<num){
      let words_array = [],words_missing = "";
      words.map(word=>{ words_array.push(word[0]); })
      //
      words_missing = num-words_array.join('').length;
      for(let index=0;index<words.length;index++){
        let short_word = words[index].substring(1,words[index].length);
        //
        if(short_word.length>0){ words_array[index] =words_array[index]+short_word.substring(0,(short_word.length>=words_missing?words_missing:short_word.length)); }
        if(words_array.join('').length==num){
           index=words.length+1;
        }else{
          words_missing=num-words_array.join('').length;
        }
      } 
      return foundValue(words_array.join(''));
    }else if(words.length==num){
      let new_val = "";
      words.map(word=>{ new_val += word[0]; })
      return foundValue(new_val);
    }else{
      let new_val = "";
      for(let word=0;word<num;word++){
        new_val += words[word][0];
      }
      if(SearchKeyInObject(lista,new_val).length==0){ 
      return new_val; }
      //
      for(let word=num;word<words.length;word++){
        new_val= new_val.slice(0,-1) + words[word][0];
        // 
        if(SearchKeyInObject(lista,new_val).length==0){
          word=words.length+1;
          return new_val;
        }
      }//
      return foundValue(new_val);
    }
  }

  const foundValue=(valor)=>{
    if(SearchKeyInObject(lista,valor).length==0){
      return valor;
    }else{
      const last_char = valor.at(-1);
      let new_val= valor.substring(0,valor.length-1);
      //
      new_val += (!isNaN(Number(last_char))?Number(last_char)+1:0);
      //
      return separateValue(new_val);
    }
  }
 return separateValue(value);
}

export function Sleeping(time){
  return new Promise(resolve=> setTimeout(resolve,time));
}


export function AddRutasRep(hook,setHook,ruta,oldRuta,datos){
  let new_hook = [...hook];
  new_hook.push({ruta:ruta,oldRuta:oldRuta,datos:datos});
  setHook(new_hook);
}

export function getDescriptionProduct(item){
  const itemAux ={...item};
  if(item?.type=="LINEA"&&item?.categoria=="ARMAZON"){
    const mods = Object.keys(item.schema[item.id]).sort((a,b)=>{
      const numA = parseInt(a.match(/\d+/)[0], 10);
      const numB = parseInt(b.match(/\d+/)[0], 10);

      return numA - numB;
    });
    
    if(mods.length>1) {    itemAux.descripcion = item.descripcion +" " +mods[0]+"/"+mods[mods.length-1];    }
  }
    return itemAux;
}

export function validProductId(id){
  const invalidCharacters = [",",".","-","+"]
  let x = true
  if(id.length<4){x=false;}
  if(id.length>=2){(id.substring(0,2)=="SE"?x=false:"")}
  if(id.length>=2){
    if(["MI","LC"].includes(id.substring(0,2))){
      invalidCharacters.forEach(element=> {
        if (id.includes(element)){
          x = false
        }
      });
    }
  }
  
  return x
}


export function encryptData(data,encryptedString){
  const key = Crypto.SHA256("pistache");
  const iv = Crypto.enc.Base64.parse("");

  if(typeof data=="string"){
    data=data.slice();
    encryptedString = Crypto.AES.encrypt(data, key, {
      iv: iv,
      mode: Crypto.mode.CBC,
      padding: Crypto.pad.Pkcs7
    });
  } else{
    encryptedString = Crypto.AES.encrypt(JSON.stringify(data), key, {
      iv: iv,
      mode: Crypto.mode.CBC,
      padding: Crypto.pad.Pkcs7
    });  
  }
  return encryptedString.toString();
}

export function decryptData(encrypted){
  const key = Crypto.SHA256("pistache");
  const iv = Crypto.enc.Base64.parse("");

  var decrypted = Crypto.AES.decrypt(encrypted, key, {
          iv: iv,
          mode: Crypto.mode.CBC,
          padding: Crypto.pad.Pkcs7
      });
  return decrypted.toString(Crypto.enc.Utf8)
}

export function getTotal(array,campo) {
  return array.reduce((a, b) => parseInt(a) + parseInt(b?.[campo]||0), 0)
}


export const getArrayAsync=async(peticion,reporte,petLast)=>{
  if(petLast){ peticion.LastEvaluatedKey = petLast;}
  const response= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticion});
  reporte = reporte.concat(response.data.datos);

  if(response.data?.LastEvaluatedKey){
    reporte = await getArrayAsync(peticion,reporte,response.data.LastEvaluatedKey);
  }
  return reporte;
}

