import React, { useEffect, useState} from "react";
import TableReportes from "./TableReportes";
import { connect } from "react-redux";
import LoadingModal from "../modal/LoadingModal";
import Modaldanger from "../modal/info";
import { useCatalogo } from "../../Hooks/useApis";
import { apiUsePromise } from "../../services/functions";
import { setRutaRep } from "../../redux/Actions";

const AltaLineas = ({PropsReportes,setHeader,Datos}) => {
  const [modalLoading, setmodalLoading] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [Lineas,setLineas]=useState([]);
  const nameComponent = "altaLineas";

  const funcionError=(error)=>{
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: (error||"")+" Error al cargar el catalogos",
    });
  }

  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true });};
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false });};
  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  const getLineas = async(codigo)=>{
    const peticion ={ 
     indexName: "lineaSearchIndex",
     hashKey: {valor:codigo,nombre:"linea"},
     sortKey: {valor:"true",nombre:"search",operador:"eq"}
    };
    try{
      const responseLineas = await apiUsePromise({ url: "/custom-query", method: "POST",data: peticion});
      const lineas = responseLineas.data;
      if(lineas.conteo>1){
        console.log(lineas.datos)
      }else{
        console.log('1=>',lineas.datos[0]);
        //if(lineas.datos[0].type="Linea")
      }
      //balAsyc = handleSuccesfulProvAsync(compras);
    }catch(error){
      closemodalLoading();
      console.log('er')
      //handleError(error);
    }
  }

  useEffect(()=>{
    openmodalLoading();
    useCatalogo("LINEAS",(resp)=>{
        if(!resp?.opciones){
          funcionError();
          return;
        }
        console.log(resp.opciones,'\n')
        let lineas = [];
        Object.keys(resp.opciones).forEach(key=>{
          lineas.push({codigo:key,linea:resp.opciones[key]})
        })
        lineas.sort((a,b)=>{
          return a.linea.localeCompare(b.linea)
        })
        setLineas(lineas);
        closemodalLoading();
      },funcionError);

  },[])

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Catalogos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal> 
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>
      <div className="relative w-full md:w-1/2 h-full">
      <TableReportes
        searchinput={false}
        columns={[
          {
            header: "Linea",
            width:"w-24",
                        // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <div 
              className="w-full cursor-pointer"
              onClick={e=>{
                if(e.detail==2){
                  getLineas(item.linea);
                }
              }}
              >
                {item?.codigo}
              </div>
              )
          },
          {
            header: "Descripcion",
            width:"w-40",
            accessor:"linea"
          },

        ]}
        data={Lineas}
      />
      </div>

    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
});

export default connect (mapStateToProps,mapDispatchToProps)(AltaLineas);
