import React, { useState, useEffect } from "react";
import Formulariocliente from "./formularioCliente";
import { setsearchPersonal } from "../../redux/Actions";
import { connect } from "react-redux";
import Modaldanger from "../../components/modal/danger";
import Modalgeneric from "../../components/modal/generic";
import TablaCredito from "../../components/Tablacredito";
import Container from "../../components/Container";
import { useGetBalanceCliente, useGetClient } from "../../Hooks/useApis";
import TableModal from "../../components/modal/TableModal";
import SearchSvg from "../../static/img/SearchSvg";
import TableReactWindows from "../../components/Reportes/Cash/TableReactWindows";
import { filter } from "lodash";
import { getArrayAsync, getSucursal, OrderArrayAZ } from "../../services/functions";
import LoadingModal from "../../components/modal/LoadingModal";

const Wrapperformcliente = (props) => {
  document.body.style = "background: rgba(17, 24, 39,1);";
  const [showForm, setshowForm] = useState(true),
  [modalTablaConfig, setmodalTablaConfig] = useState(false),
  [dataModalTabla, setdataModalTabla] = useState(false),
  [search, changesearch] = useState(""),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [modaldangerConfig, setmodaldangerConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [estadocuenta, setestadocuenta] = useState(false),
  [newPerson,setNewPerson]=useState(true),
  [clientes,setClientes]= useState([]),
  [modalClientes,setModalClientes]=useState({});
  const setsearchPersonal = props.setsearchPersonal;
  
  function closeModaldanger(e) {
    const newdataconfig = { ...modaldangerConfig };
    newdataconfig.isOpen = false;
    setmodaldangerConfig(newdataconfig);
  }

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  function closeModaltabla(e) {
    setmodalTablaConfig({ isOpen: false });
  }

  const openModalClientes=()=>{ setModalClientes({isOpen:true})};
  const closeModalClientes=()=>{ setModalClientes({isOpen:false})};

  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false });};

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const setdatasearch = (e) => {
    changesearch(e.target.value.toUpperCase());
  };

  const handleBalanceSuccessful = (data) => {
    
    if (data.message) {
      setmodaldangerConfig({
        isOpen: true,
        message: data.message,
      });
    }
    props.VentasCredito(data.ventasCredito);
  };

  const handleSuccessful = (data) => {
    if (Array.isArray(data)) {
      if (data.length === 0) {
        setmodaldangerConfig({
          isOpen: true,
          message:
            "La búsqueda de '" + search + "' no devolvió ningún resultado",
        });
      }
      if (data.length === 1) {
        useGetBalanceCliente(data[0]['id'],handleBalanceSuccessful,handleError);
        setsearchPersonal(data[0]);
        setshowForm(!showForm);
      }
      if (data.length > 1) {
        setdataModalTabla(data);
        setmodalTablaConfig({ isOpen: true });
      }
    }
  };

  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  const handleSubmit = (event) => {
    setsearchPersonal({});
    setshowForm(!showForm);
    setNewPerson(false);
    useGetClient(search,handleSuccessful,handleError);
  };


  useEffect(() => {
    return () => {
      setsearchPersonal({});
      props.VentasCredito([]);
    };
  }, [setsearchPersonal]);

  useEffect(() => {
    setNewPerson(true);
    return () => {
      props.VentasCredito([]);
    };
  }, []);

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Productos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
      
      <Modaldanger config={modaldangerConfig} closeModal={closeModaldanger}>
        <Modaldanger.Message
          title={"No se ha encontrado la clave en la base de datos."}
        >
          {modaldangerConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            name="modaldanger"
            tabIndex="100"
            className="btn-gray2 color-danger"
            onClick={closeModaldanger}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalgeneric w="size-w-60" h="size-h-90" config={modalClientes}>
        <Modalgeneric.Body>
          <div className="relative h-full">
            <TableReactWindows
              onRowClick={({e,row})=>{
                if(e.detail!==2){return}
                setsearchPersonal(row)
                closeModalClientes();
              }}
              columns={[
              {
                key:"id",
                header:"Codigo",
                width:90,
                filter:true,
              },
              {
                key:"nombre",
                header:"Nombre",
                width:350,
                filter:true,
              },
              {
                key:"direccion",
                header:"Direccion",
                width:400,
                filter:true,
              },
              {
                key:"activo",
                header:"Activo",
                className:"uppercase text-center",
                width:90,
                filter:true,
              }
              ]}
              data={clientes}
            />
            </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModalClientes}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalgeneric config={modalTablaConfig}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Elija una opción</Modalgeneric.Title>
          <TableModal
            columns={[
              {
                header: "Nombre",
                accessor: "nombre",
                width: "w-1/2",
              },
              { header: "Telefono", accessor: "telefono", width: "w-1/2" },
              { header: "RCF", accessor: "rfc", width: "w-1/2" },

              {
                header: "Direccion",
                accessor: "direccion",
                width: "w-1/2",
              },
            ]}
            onclickRow={(item) => {
              
              useGetBalanceCliente(item['id'],handleBalanceSuccessful,handleError)
              setsearchPersonal(item);
              setmodalTablaConfig({ isOpen: false });
              setshowForm(!showForm);
            }}
            data={dataModalTabla}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <Container lg="px-60">
        <div className="px-6">
          <div className="mb-10 py-2 border-b ">
            <div className="flex flex-row mb-0">
              <div>
                <h6 className=" text-gray-600 text-sm font-bold">
                  Cliente
                </h6>
              </div>

              <div className="mx-auto">
                  <button className="btn-green1" onClick={e=>{
                    const peticion={
                      indexName:"reverseIndex",
                      hashKey:{valor:"CLIENT",nombre:"type"},
                      filters:[{nombre:"sucursal",valor:getSucursal(),operador:"eq"}]
                    }
                    const getClientes=async()=>{
                      openmodalLoading();
                      try{
                        const resp = await getArrayAsync(peticion,[]);
                        console.log(resp);
                        let arreglo = OrderArrayAZ(resp,'id','string');
                        setClientes(arreglo)
                        closemodalLoading();
                        openModalClientes();
                      }catch{
                        console.log('Error ');
                        closemodalLoading();
                      }
                    }
                    
                    getClientes();
                  }}>
                    Clientes
                  </button>
              </div>

              <div className="ml-auto right-0">
                <div className="inline-block border-b-4 md:border-b-0 md:border-r-4 px-2">
                  <div className="pt-2 relative mx-auto text-gray-600  ">
                    <input
                      className=" w-full uppercase border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
                      type="search"
                      name="buscar"
                      placeholder="Buscar"
                      onChange={setdatasearch}
                      onKeyDown={handleKeyDown}
                    />
                    <button
                      type="button"
                      className="absolute right-0 top-0 mt-5 mr-4"
                      onClick={handleSubmit}
                    >
                      <SearchSvg width="w-4" height="h-4" text="text-gray-600"/>
                    </button>
                  </div>
                </div>
                <div className="inline-block px-2">
                  <button
                    className="btn-blue w-full"
                    type="button"
                    onClick={(e) => {
                      setsearchPersonal({});
                      setNewPerson(true);
                      setshowForm(!showForm);
                    }}
                    style={{ transition: "all .15s ease" }}
                  >
                    Nuevo Cliente
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap ">
            <div className="w-full ">
              {showForm ? (
                <Formulariocliente key={0} newPerson={newPerson} setNewPerson={setNewPerson} setshowForm={setshowForm} showForm ={showForm}/>
              ) : (
                <Formulariocliente key={1} newPerson={newPerson} setNewPerson={setNewPerson} setshowForm={setshowForm} showForm ={showForm} />
              )}
            </div>
            <div className="w-full px-4 py-8 flex flex-col justify-center items-center">
              <div className="relative w-full md:w-1/2 mb-3">
                <button
                  className="btn-green w-full"
                  type="button"
                  onClick={(e) => {
                    setestadocuenta(!estadocuenta);
                  }}
                  style={{ transition: "all .15s ease" }}
                >
                  Estado de cuenta
                </button>
              </div>
              {estadocuenta ? (
                <>
                  <TablaCredito />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setsearchPersonal: (personal) => dispatch(setsearchPersonal(personal)),
  VentasCredito: (datos) =>
    dispatch({
      type: "SET_VENTACREDITO",
      ventascredito: datos,
    }),
});

export default connect(null, mapDispatchToProps)(Wrapperformcliente);
