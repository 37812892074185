import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useGetClient,useDeleteVenta } from "../../Hooks/useApis";
import { agregarProducto } from "../../redux/Actions";
import { agregarItemVenta, getSucursal, TransformSys } from "../../services/functions";
import Modalinfo from "../modal/info";

const InputDeleteVenta = ({ addClient, addItem, Cliente,addHeaderVenta,HeaderVenta,itemsVenta }) => {
  let input, listErrors =[];
  
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [venta,setVenta]=useState();

  useEffect(()=>{
    if(!Cliente?.activo||TransformSys(venta?.cliente)!==Cliente?.id){ return;}
    if(!Cliente?.disabled){ setVenta(); }

    addHeaderVenta({...HeaderVenta, id:venta.shortId, cliente:Cliente.id, fecha:venta.id, type: venta.type});


    venta.items.forEach(product=>{
        product.cantidad = product.cantidadVendida;
        product.precio = product.precioVenta;
        product.id = product?.categoria=="SERVICIO"?product.id:product.type.split("#")[2];
        product.type = "PRODUCT";
        delete product.precioVenta;
        delete product.cantidadVendida;

        const error = agregarItemVenta(product,addItem,Cliente?.descuento,itemsVenta,true);
        error?listErrors.push(error):"";
    })

    if(listErrors.length>0){
      let message = [];
      listErrors.forEach(error=>{
        let r="";
        if(error.type=="existencia"){r+="Sin existencias: "}
        else if(error.type=="habilitar"){r+="Producto desactivado: "}
        else if(error.type=="vendible"){r+="Producto no vendible: "}
        else{r+="Producto no encontrado en la Base de Datos: "}
        message.push(r+error.item.id);
      })
      setmodalinfoConfig({
        isOpen: true,
        message: message.join(" / "),
      });
    }
  },[Cliente])

  function closeModalinfo(e) {  setmodalinfoConfig({ isOpen: false }); }

  const funcionDeleteExito = (respuesta) => {
    if (!respuesta?.venta ) {
      setmodalinfoConfig({
        isOpen: true,
        message: "No existe el ticket '" + input.value.toUpperCase() + "'",
      });
      return ;
    }

    setVenta({...respuesta.venta,items: respuesta.items});
    useGetClient(TransformSys(respuesta.venta.cliente),handleSuccessfulCliente,handleClientError);
  };

  const handleSuccessfulCliente = (respuesta) => {
    let clientes = respuesta.filter(cliente=>(cliente.sucursal===getSucursal()))

    if (clientes.length === 0) {
      setmodalinfoConfig({
        isOpen: true,
        message: "No existe el cliente '" + input.value.toUpperCase() + "'",
      });
      addClient({});
    }
    if (clientes.length === 1) {validateclient(clientes[0]);}
  };

  function validateclient(client) {
    if (client.activo === true) {
      client.disabled = true;
      addClient(client);
    } else {
      setmodalinfoConfig({
        isOpen: true,
        message: "cliente inhabilitado",
      });
    }
  }

  const funcionDeleteError = (error) => {
    setmodalinfoConfig({
      isOpen: true,
      message: error?.response?.data?.message || "No se puede recuperar la venta",
    });
  };
  
  const handleClientError = (error) => {
    setmodalinfoConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  return (
    <>
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message
          title={"No se ha encontrado la clave en la base de datos."}
        >
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <div className="size-form w-full">
            <input
              type="text"
              className="border-form uppercase"
              name="articulo"
              placeholder="Venta a Eliminar"
              style={{ transition: "all .15s ease" }}
              ref={(node) => (input = node)}
            />

            <button
              className="btn-red"
              type="submit"
              onClick={e=>{
                if (!input.value.trim()) {return; }
                useDeleteVenta(input.value.trim(),funcionDeleteExito,funcionDeleteError);
                input.value = "";
              }}
              style={{ transition: "all .15s ease" }}
            >
             <i className="fas fa-trash text-red-100"></i>
            </button>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(agregarProducto(item)),
  addClient: (cliente) =>
  dispatch({
    type: "ADD_CLIENT",
    item: cliente,
  }),
  addHeaderVenta:(headerventa) => dispatch({type:"SET_HEADER_VENTA", headerVenta:headerventa})
});

const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
  HeaderVenta: state.HeaderVenta,
  itemsVenta: state.itemsVenta,
});


export default connect(mapStateToProps, mapDispatchToProps)(InputDeleteVenta);