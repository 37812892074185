import React, { useEffect, useState} from "react";
import { connect } from "react-redux";
import TableReportes from "../TableReportes";
import LoadingModal from "../../modal/LoadingModal";
import Modaldanger from "../../modal/danger";
import { useRef } from "react";
import { getSucursal } from "../../../services/functions";
import LabelNumber from "../../LabelNumber";
import Labelmoney from "../../labelmoney";
import { setRuta } from "../../../redux/Actions";

const ReporteLineaCodigo = ({PropsReportes,setHeader,Datos,setCleanInventario,setPropsInventario,propsInventario,setRutaGlobal}) => {
  const [inventario,setInventario]=useState([]),
  [modalLoading, setmodalLoading] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [Lineas,setLineas]=useState([]);
  const filterInventory =  useRef([]);
  const nameComponent = "inventarioLineaCodigo",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];

  const funcionError=(error)=>{
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: (error||"")+" Error al cargar el catalogos",
    });
  }

  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true });};
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false });};
  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  useEffect(()=>{
    if(rutaActualRep!==nameComponent){return;}
    if(!Datos[nameComponent][0]){return}
    let arreglo=[];
    const lineas = Datos[nameComponent][0];
    lineas.forEach(cod=>{
      if(cod.categoria==Datos[nameComponent][1].categoria&&cod.marca==Datos[nameComponent][1].marca){
        arreglo.push({codigo:cod.id,descripcion:cod.descripcion,type:cod.type,datos:cod.sucursales?.[getSucursal()]})
      }
    })
    console.log('ar=>',arreglo);
    filterInventory.current = arreglo;
    setInventario(arreglo);
},[PropsReportes.Datos])



useEffect(()=>{
  if(rutaActualRep!==nameComponent){return;}
  if(!Datos[nameComponent][0]){return}
   setHeader({text:"Inventory "+Datos[nameComponent][1].categoria+" - "+Datos[nameComponent][1].marca+" - "+Datos[nameComponent][1].linea})
},[PropsReportes.rutas])

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Catalogos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal> 
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>
      <div className="relative w-full md:w-1/2 h-full">
      <TableReportes
        searchinput={false}
        columns={[
          {
            header: "Linea",
            width:"w-24",
                        // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <div 
              className="w-full cursor-pointer flex flex-row gap-1 justify-between"
              >
                {item?.codigo}
                <button className={(item.type === 'PRODUCT'?"hidden w-0 ":"text-blue-600 ")}
                    onClick={(e) => {  
                      //console.log(item)
                      setCleanInventario();
                      const data =  {...propsInventario.data,id:item.codigo,schema:{},tablas:[]};
                      setPropsInventario({...propsInventario,sucursales:[getSucursal()], data:data,items:[]});
                      setRutaGlobal("micas");
                    }}
              >
                <i className="fas fa-border-all"></i>
                  
                </button>
              </div>
              )
          },
          {
            header: "Descripcion",
            width:"w-96",
            accessor:"descripcion"
          },
          {
            header: "Qty",
            width:"w-24",
                         // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <LabelNumber className={item?.datos?.existencia==0?"hidden":"text-size-table text-right w-full"}>{item?.datos?.existencia}</LabelNumber>  
            )
          },
          {
            header: "Sold",
            width:"w-20",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <LabelNumber className={item?.cantidadVendida==0?"hidden":"text-size-table text-pink-700 text-right w-full"}>{item?.cantidadVendida||""}</LabelNumber> 
            ),
          },
          {
            header: "Qoh End",
            width:"w-24",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <LabelNumber className={item?.existencia==0?"hidden":"text-size-table text-right w-full"}>{item?.existencia||""}</LabelNumber>   
            ),
          },
          {
            header: "Amount",
            width:"w-28",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <Labelmoney twoDecimal={true} className={(item?.datos?.existencia*item?.datos?.precioCompra)==0?"hidden":" text-size-table text-right w-full"}>{(item?.datos?.existencia*item?.datos?.precioCompra)||0}</Labelmoney>
            ),
          },
        ]}
        footer={[
          {},
          { 
            width:"bg-gray-100",
            code: <label className="font-semibold">Total:</label>
          },
          {
            width:"bg-gray-100 ",
            // eslint-disable-next-line react/display-name
            code:  
            <LabelNumber className={"text-size-table text-right w-full"}>{inventario.reduce((a, b) => parseInt(a) + parseInt(b?.datos?.existencia), 0)}</LabelNumber>  
          },
        ]}
        data={inventario}
      />
      </div>

    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),

  setPropsInventario:(data)=> dispatch({type:"SET_PROPSINVENTARIO", estado:data}),
  addRutaGlobal:(ruta)=> dispatch(setRuta(ruta)),
  resetPropsInventario: ()=> dispatch({type: "RESET_PROPSINVENTARIO"}),
  setCleanInventario: () => dispatch({type: 'RESET_INVENTARIO'}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
  propsInventario: state.PropsInventario,
});

export default connect (mapStateToProps,mapDispatchToProps)(ReporteLineaCodigo);
