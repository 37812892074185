import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { FechaLocalToUTC, tipoCelda, getSucursal, TransformSupplier,apiUsePromise, OrderArrayAZ,getTotal, GetFechaActual, GetFechaActualSeparado, valueToMoney2, getArrayAsync } from '../../../services/functions';
import Modaldanger from '../../modal/danger';
import LoadingModal from '../../modal/LoadingModal';
import TableReactWindows from '../Cash/TableReactWindows';
import {setRutaRep} from '../../../redux/Actions';
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from '../../Excel/DataToXlsx';
import Modalsuccess from '../../modal/success';

const ReporteAP = ({PropsReportes,addDatos,setHeader,setRutaRep,Datos}) => {
  const [balance,setBalance]=useState([]),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  
  const nameComponent = "proveedorBalance",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const new_supplier = { balanceBefore:0,balance:0, comprado:0,pagado:0,devolucion:0,envio:0,descuento:0};
  const datosComponent = Datos[nameComponent]||{};
  const todayStart=FechaLocalToUTC(GetFechaActual().fecha),todayEnd=FechaLocalToUTC(GetFechaActual().fecha,23,59,59);
  
  const peticiones =[
    {
      indexName:"reverseIndex",
      hashKey:{valor:"SUPPLIER",nombre:"type"},
    },
    {
      hashKey:{valor:("REPORTE#PROVEEDORES#"+getSucursal()),nombre:"id"},
      sortKey:{valor:['2023-01-01',PropsReportes.fecha_Final],operador:"between",nombre:"type"},
      //GetFechaActualSeparado().ano+
    },
    {
      indexName:"reverseIndex",
      hashKey:{valor:("COMPRA#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[todayStart,todayEnd],operador:"between",nombre:"id"},
    },
    {
      indexName:"reverseIndex",
      hashKey:{valor:("TRASPASO#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[todayStart,todayEnd],operador:"between",nombre:"id"},
    },
    {
      hashKey:{valor:("AP#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[todayStart,todayEnd],operador:"between",nombre:"type"},
    },
    {
      hashKey:{valor:("APIN#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[todayStart,todayEnd],operador:"between",nombre:"type"},
    },
    {
      indexName:"reverseIndex",
      hashKey:{valor:("PE#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[getSucursal()+"#"+todayStart,getSucursal()+"#"+todayEnd],operador:"between",nombre:"id"},
    }
  ];

  const handleError = (error) => {
        setmodaldangererrorConfig({
          isOpen: true,
          message: error?.response?.data?.message,
        });
  };

  function closeModaldangererror(e) {
        const newdataconfig = { ...modaldangererrorConfig };
        newdataconfig.isOpen = false;
        setmodaldangererrorConfig(newdataconfig);
  }
  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false });};

  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }


  const getRep=async(reporte,allRep,petLast)=>{
    if(petLast){ peticiones[1].LastEvaluatedKey = petLast;}

    const responseReporte= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[1]});
    const reportes = responseReporte.data.datos;

    reportes.forEach(rep=>{
      const keys = Object.keys(rep.proveedores);
      if(rep.type<PropsReportes.fecha_Inicial){
        keys.forEach(clave=>{
          if(reporte[clave]){
            reporte[clave].balanceBefore += ((rep.proveedores[clave]?.compras||0)+(rep.proveedores[clave]?.envio||0)-(rep.proveedores[clave]?.descuento||0)-(rep.proveedores[clave]?.pagos||0)-(rep.proveedores[clave]?.devolucion||0));
          }
        })
      }else if(rep.type>=PropsReportes.fecha_Inicial&&rep.type<=PropsReportes.fecha_Final){
        keys.forEach(clave=>{
          if(reporte[clave]){
            reporte[clave].pagado+= rep.proveedores[clave]?.pagos||0;
            reporte[clave].devolucion+= rep.proveedores[clave]?.devolucion||0;
            reporte[clave].descuento+= rep.proveedores[clave]?.descuento||0;
            reporte[clave].comprado+= rep.proveedores[clave]?.compras||0;
            reporte[clave].envio+= rep.proveedores[clave]?.envio||0;
          }
        })
      }
      keys.forEach(clave=>{
        if(allRep[clave]){
          allRep[clave].registros.push({pagado:rep.proveedores[clave]?.pagos||0,devolucion:rep.proveedores[clave]?.devolucion||0,envio:rep.proveedores[clave]?.envio||0,descuento:rep.proveedores[clave]?.descuento||0,comprado:rep.proveedores[clave]?.compras||0,fecha:rep.type});
        }
      })
    })

    if(responseReporte.data?.LastEvaluatedKey){
      await getRep(reporte,allRep,responseReporte.data.LastEvaluatedKey);
    }
  }

  useEffect(()=>{
    if(PropsReportes.rutas.length!==1){return;}
    if(rutaActualRep!==nameComponent){return;}

    const getBalanceSupplier = async()=>{
      openmodalLoading();

      try{
        const reporte={},allRep={};
        const responseSuppliers = await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[0]});
        let suppliers = responseSuppliers.data.datos,balanceProveedor=[];

        suppliers = OrderArrayAZ(suppliers,"id","string");
        suppliers.forEach(prov=>{
          const nombreProveedor =prov.id;
          if(!reporte[nombreProveedor]){ reporte[nombreProveedor] = {...new_supplier,proveedor:nombreProveedor, nombre:prov.nombre};}
          if(!allRep[nombreProveedor]){allRep[nombreProveedor] = {registros:[],proveedor:nombreProveedor, nombre:prov.nombre}}
        })

        await getRep(reporte,allRep);

        
        if(GetFechaActual().fecha==PropsReportes.fecha_Final){
          const compras = await getArrayAsync(peticiones[2],[]),
          traspasos = await getArrayAsync(peticiones[3],[]),
          pagos = await getArrayAsync(peticiones[4],[]),
          cargos = await getArrayAsync(peticiones[5],[]),
          pes = await getArrayAsync(peticiones[6],[]);

          compras.forEach(compra=>{
            reporte[compra.proveedor].comprado += compra.subtotal;
            reporte[compra.proveedor].descuento += compra.descuento;
            reporte[compra.proveedor].envio +=  compra.envio;
          })

          traspasos.forEach(traspaso=>{
            reporte[traspaso.proveedor].devolucion+= traspaso.subtotal;
            reporte[traspaso.proveedor].envio+= traspaso.descuento;
          })

          pagos.forEach(pago=>{
            const proveedor = TransformSupplier(pago.contracuenta);
            reporte[proveedor].pagado += pago.importe*-1;
          })

          cargos.forEach(cargo=>{
            const proveedor = TransformSupplier(cargo.contracuenta);
            reporte[proveedor].comprado += cargo.importe
          })

          pes.filter(pe=>!['CANCELADO'].includes(pe.status)&&pe.proveedores.length).forEach(pe=>{
            pe.proveedores.forEach(prov=>{
              reporte[prov.proveedor].comprado += prov.importe;
            })
          })
        }

        //console.log(reporte)
        for(let prov of Object.keys(reporte)){
          reporte[prov].balance =  reporte[prov].balanceBefore+ reporte[prov].comprado + reporte[prov].envio - reporte[prov].devolucion - reporte[prov].descuento - reporte[prov].pagado;
          balanceProveedor.push(reporte[prov]);
        }

        closemodalLoading();
        setBalance(balanceProveedor);
        addDatos(nameComponent,[balanceProveedor,{fechai:PropsReportes.fecha_Inicial,fechaf:PropsReportes.fecha_Final,allRep:allRep}]);     
      }catch(error){
          closemodalLoading();
          handleError(error);
      }
    }

    if(JSON.stringify(datosComponent)=='{}'){
      getBalanceSupplier();
    }else{
      if(PropsReportes.fecha_Inicial==datosComponent[1].fechai&&PropsReportes.fecha_Final==datosComponent[1].fechaf){
        setBalance(datosComponent[0]);
        return;
      }
      getBalanceSupplier();
    }
    
  },[PropsReportes.fecha_Inicial,PropsReportes.fecha_Final,PropsReportes.rutas]);

  useEffect(()=>{
    if(rutaActualRep!==nameComponent){return;}
    if(!datosComponent[0]){return}
     setHeader({text:"AP "})
  },[PropsReportes.rutas,Datos])

    

  return (
  <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Productos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>
      <TableReactWindows
        btnDownload={async(e,datos)=>{
          if(!balance?.length){handleError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            handleError(workb.error);
            return
          }

          const data = datos||balance,
          nameFile = "BalanceAP",
          headerFile = "Reporte balance de proveedores del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={
            'Proveedor':13,
            'Beg Balance':17,
            'Comprado':17,
            'Envio':17,
            'Devolucion':17,
            'Pagado':17,
            'Descuento':17,
            'End Balance':17,
            'Nombre':40
          };
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((ap,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(ap.proveedor);
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(valueToMoney2(ap?.balanceBefore||0));
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(ap?.comprado||0));
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(valueToMoney2(ap?.envio||0));
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(ap?.devolucion||0));
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(ap?.pagado||0));
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(ap?.descuento||0));
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(valueToMoney2(ap?.balance||0));
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(ap.nombre);
          });
      
          sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[1]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[3]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[4]+sizeData).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[4]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[6]+sizeData).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[7]+sizeData).formula(""+columnasXlsx[1]+sizeData+"+"+columnasXlsx[2]+sizeData+"+"+columnasXlsx[3]+sizeData+"-"+columnasXlsx[4]+sizeData+"-"+columnasXlsx[5]+sizeData+"-"+columnasXlsx[6]+sizeData);
          sheet1.cell(columnasXlsx[7]+sizeData).style(formatoCelda.contabilidad);
      

          const response =await SaveXlsx(workb,nameFile);
          if(response?.error){
            closemodalLoading();
            handleError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
        }}
        columns={[
          {
            key:"proveedor",
            header:"Supplier",
            width:100, 
            type: tipoCelda.text,
            className: "text-blue-800",
            filter:true,
            onClick: ({e,row})=>{
              if(e.detail==2){
                const oldDatos = Datos['proveedorBalanceDia'] || [];
                //console.log(balanceSupplier.current)
                addDatos(
                  "proveedorBalanceDia",
                  [
                    (oldDatos?.[1]?.proveedor==row.proveedor?oldDatos[0]:null),
                    {
                      fechai:(oldDatos?.[1]?.fechai || PropsReportes.fecha_Inicial),
                      fechaf:(oldDatos?.[1]?.fechaf || PropsReportes.fecha_Final),
                      balance:row,
                      proveedor:row.proveedor,
                      allRep:datosComponent[1]?.allRep?.[row.proveedor]?.registros
                    }
                  ]
                )
                setHeader({text:"AP Detail ",proveedor:row.proveedor})
                setRutaRep("proveedorBalanceDia");
              } 
            }
          },
          {
            key:"balanceBefore",
            header: "Beg Balance",
            width:120,
            type: tipoCelda.money,
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"comprado",
            header: "Purchase",
            width:100,
            type: tipoCelda.money,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"envio",
            header: "Freigth",
            width:100,
            type: tipoCelda.money,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"devolucion",
            header: "Return",
            width:100,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"pagado",
            header: "Paid",
            width:100,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"descuento",
            header: "Discount",
            width:100,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"balance",
            header: "Balance",
            width:130,
            type: tipoCelda.money,
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"nombre",
            header: "Name",
            width:250,
            type: tipoCelda.text,
            className: "text-blue-800",
            filter:true,
          }
        ]}
        data={balance}
      />
      </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect (mapStateToProps,mapDispatchToProps)(ReporteAP)