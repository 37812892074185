import React from 'react'
import Container from '../components/Container';
import HeaderMoviminetos from '../components/ExyCash/HeaderMoviminetos';
import PanelTablaMovimientos from '../components/ExyCash/PanelTablaMovimientos';

const ExyCash = () => {
    return (
      <Container bg="bg-mesh">
          <HeaderMoviminetos/>
          <PanelTablaMovimientos/>
      </Container>
    );
  };
  
  export default ExyCash;
