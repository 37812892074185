import React from "react";
import PropTypes from "prop-types";
import { useRef } from "react";
import { useMemo } from "react";

const InputModelo = ({ getvalor,name, className,reset }) => {
  const select = useRef("");

  useMemo(()=>{
    select.current?.value? select.current.value = "" :""
  },[reset])

  return <input
          type="text"
          name={name}
          className={"border-form w-full "+(className || "")}
          ref={select}
          pattern="([0-9]{1,4}[-,]{0,1}){1,}"
          placeholder="101-109 o 101,103,104"
          onBlur={(e) => {
            if (e.target.value !== "") {
              if (
                e.target.validity.valid &&
                e.target.value.charAt(e.target.value.length - 1) !== "," &&
                e.target.value.charAt(e.target.value.length - 1) !== "-" &&
                !(e.target.value.includes("-") && e.target.value.includes(","))
              ) {
                if (e.target.value.includes("-") && !e.target.value.includes(",")) {
                  const arre = e.target.value.split("-");
                  
                  const valor_inicial = arre[0];
                  let ini = parseInt(arre[0]), fin =parseInt(arre[1]);
                  let resultado = [];
                  if (ini > fin) {
                    ini = parseInt(arre[1]);
                    fin = parseInt(valor_inicial);
                  }
                  for (let i = ini; i <= fin; i++) {
                    resultado.push({
                      id: parseInt(i),
                      largo: 0,
                      alto: 0,
                      puente: 0,
                      varilla: 0,
                      forma: "",
                    });
                  }
                  getvalor(resultado);
                } else if (
                  !e.target.value.includes("-") &&
                  e.target.value.includes(",")
                ) {
                  let resultado = [];
                  e.target.value.split(",").map(item=>{
                    resultado.push({
                      id:parseInt(item),
                      largo: 0,
                      alto: 0,
                      puente: 0,
                      varilla: 0,
                      forma: "",
                    })
                  });
                  getvalor(resultado);
                } else {
                  getvalor([{
                    id:parseInt(e.target.value),
                    largo: 0,
                    alto: 0,
                    puente: 0,
                    varilla: 0,
                    forma: "",
                  }]);
                }
              } else {
                getvalor();
              }
            } else {
              getvalor();
            }
          }}
        />
};

InputModelo.propTypes = {
  getvalor: PropTypes.func.isRequired,
};

export default InputModelo;
