import React, {  useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useCatalogo, useCustomQuery } from '../../Hooks/useApis';
import { agregarItemInventario } from '../../redux/Actions';
import { CalculaMinPrecioVenta, CalculaPrecioCompraPromediado, GetFechaActual, getSucursal } from '../../services/functions';
import Modaldanger from '../modal/danger';
import Modalgeneric from '../modal/generic';
import LoadingModal from '../modal/LoadingModal';
import Multiselector from '../Multiselector';

const TestExcel=({PropsInv,setInventario,setPropsInventario,Inventario})=> {
    const [modalLoading, setmodalLoading] = useState({}),
    [FormatosMicas,setFormatos]=useState({}),
    [modalGeneric,setModalGeneric]=useState(false),
    [openFile,setOpenFile]=useState(false),
    [files,setFiles]=useState(false),
    [lineas,setLineas]=useState([]),
    [lineasSelected,setLineasSelected]=useState([]),
    [hacerPeticion,setHacerPetition]=useState({start:false,resp:[]}),
    [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});

    const XlsxPopulate = require("xlsx-populate");

    const title = useRef("Sin Datos");
    const inputfile = useRef();

    const openmodalLoading = (e) => {
        setmodalLoading({ isOpen: true, title });
      };
    const closemodalLoading = (e) => {
       setmodalLoading({ isOpen: false });
      };
    function closeModalGenericFinish() {
        setModalGeneric({ isOpen: false });
      }
    function closeModalGeneric(success) {
        const propsI = {...PropsInv};
        delete propsI.enableExport;
        delete propsI.enableImport;
        setPropsInventario(propsI);
        setModalGeneric({ isOpen: false });
      }
    function openModalGeneric(e){
        setModalGeneric({ isOpen: true });
      }
    function closeModaldangererror(e) {
        setHacerPetition({start:false,lineas:[],resp:[]});
        const newdataconfig = { ...modaldangererrorConfig };
        newdataconfig.isOpen = false;
        setmodaldangererrorConfig(newdataconfig);
    }
    
    const getitemOfProps=(id)=>{
        let item = PropsInv.items.find(item=>item.id == id.trim());  
        return item ||{}
    }

    const getitemOfInventario=(id,inv)=>{
        let item =inv.find(item=>(item.id == id.trim()))||{}; 
        //console.log('retunr=>',item,'==',id,'==>',Inventario.find(item=>(item.id == id.trim())))
        return item
    }

    const GenerateFileBlank=()=> {
        const generate=()=>{
            return XlsxPopulate.fromBlankAsync()
            .then(workbook=> {
                workbook.sheet(0).range("B6:J30").value(()=>1);
                workbook.sheet(0).range("M6:U30").value(()=>2);

               // 
        
                // Write to file.
                return workbook.outputAsync({ type: "base64" });
            })
            .catch(function (err) {
                alert(err.message || err);
                    throw err;
            })
        }

        return generate()
            .then(function (base64) {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    throw new Error("Navigating to data URI is not supported in IE.");
                } else {
                    location.href = "data:" + XlsxPopulate.MIME_TYPE + ";base64," + base64;
                }
            })
            .catch(function (err) {
                alert(err.message || err);
                throw err;
        });
    }

    const ExportFormats=()=> {

        const generate=()=>{
            if (!inputfile.current.files[0]){
                setmodaldangererrorConfig({
                    isOpen: true,
                    message: "El archivo seleccionado no es valido",
                });
                return;
            }

            const estableceInfo=(workbook)=>{
                workbook.sheets().forEach(sh=>{
                    const found = lineasSelected.includes(sh._idNode.attributes.name);
                    if(!found){
                        workbook.deleteSheet(sh._idNode.attributes.name);
                    }
                });
                hacerPeticion.resp.forEach(resp=>{
                   // let item = getitemExport(id,resp.datos);

                try{
                    const sheet = workbook.sheet(resp.linea);
                    const opcion =sheet.cell("A1").value();
                    const name=("MI"+sheet.cell("B1").value()).toUpperCase();

                    FormatosMicas[opcion].map(param=>{
                        const keysignal = Object.keys(param)[0];
                        const rango = param[keysignal];
                        const columvalue = sheet.cell(Object.keys(param)[0]).columnNumber();
                        const rowvalue = sheet.cell(Object.keys(param)[0]).rowNumber();
            
                        sheet.range(rango).map(cell=>{
                            //console.log('name:',name,'<>',sheet.row(cell.rowNumber()).cell(columvalue).value(),'<>',sheet.row(rowvalue).cell(cell.columnNumber()).value())
                                const propItem = getitemOfInventario(name + (sheet.row(cell.rowNumber()).cell(columvalue).value()==undefined?"":sheet.row(cell.rowNumber()).cell(columvalue).value()) + (sheet.row(rowvalue).cell(cell.columnNumber()).value()==undefined?"":sheet.row(rowvalue).cell(cell.columnNumber()).value()),resp.datos);
                                //console.log('=>',propItem)
                                if(JSON.stringify(propItem)!=="{}"){
                                    let valor = null;
                                    let par ;
                                    switch (PropsInv?.tab) {
                                        case "IN":
                                            par =(propItem?.cantidad>0?propItem?.cantidad/2:0);
                                            valor = (par?par:null);
                                            break;
                                        case "O":
                                            par =(propItem?.N>0?Math.ceil(propItem?.N/2):0);
                                            valor = (par?par:null);
                                           //console.log(valor)
                                            break;
                                        case "G":
                                            par =(propItem?.cantidad>0?propItem?.cantidad/2:0);
                                            valor = (par?par:null);
                                            break;
                                        default:
                                            propItem?.[PropsInv?.tab]||null;
                                            break;
                                    }
                                    if(PropsInv?.Pares==true){
                                        cell.style("numberFormat", "0.0");
                                    }
                                        cell.value(valor);
                                }
                        })
                    })
                    }catch (error){
                        console.log('Error=>',error);
                    }
                })

                setHacerPetition({start:false,resp:[],lineas:[]});
                closemodalLoading();
                closeModalGenericFinish();
                
                return workbook.outputAsync()
                .then(function (blob) {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        // If IE, you must uses a different method.
                        window.navigator.msSaveOrOpenBlob(blob, (getSucursal()+"-PEDIDO"+"-"+".xlsx"));
                    } else {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = (getSucursal()+"-"+GetFechaActual().fecha+".xlsx");
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                       //console.log('finish')
                    }
                })
                .catch(function (err) {
                    throw err;
                });
            }
            
            openmodalLoading();
            return XlsxPopulate.fromDataAsync(inputfile.current.files[0])
            .then(estableceInfo)
            .catch(function (err) {
                closeModalGeneric();
                closemodalLoading();
                setmodaldangererrorConfig({
                isOpen: true,
                message: "Error dentro del Excel: "+(err.message || err||""),
                });
            })
        }

        generate();
    }

    const LoadFormatExport=()=> {
    
        const generate=()=>{
            if (!inputfile.current.files[0]){
                setmodaldangererrorConfig({
                    isOpen: true,
                    message: "El archivo seleccionado no es valido",
                });
                return;
            }

            const estableceInfo=(workbook)=>{
                const sheet = workbook.sheet(PropsInv.data.linea);
                let sheet2 ;
                const opcion =sheet.cell("A1").value();
                const name=("MI"+sheet.cell("B1").value()).toUpperCase();

                if(PropsInv?.tab=="G2"){ sheet2 = workbook.addSheet('Venta Promedio');  }
                
                FormatosMicas[opcion].map(param=>{
                    const keysignal = Object.keys(param)[0];
                    const rango = param[keysignal];
                    const columvalue = sheet.cell(Object.keys(param)[0]).columnNumber();
                    const rowvalue = sheet.cell(Object.keys(param)[0]).rowNumber();
                    //console.log('key=>',keysignal);
                    //console.log('ran=>',rango);
                    
                    
                    sheet.range(rango).map(cell=>{
                        //console.log('name:',name,'<>',sheet.row(cell.rowNumber()).cell(columvalue).value(),'<>',sheet.row(rowvalue).cell(cell.columnNumber()).value())
                            const propItem = getitemOfInventario(name + (sheet.row(cell.rowNumber()).cell(columvalue).value()==undefined?"":sheet.row(cell.rowNumber()).cell(columvalue).value()) + (sheet.row(rowvalue).cell(cell.columnNumber()).value()==undefined?"":sheet.row(rowvalue).cell(cell.columnNumber()).value()),Inventario);
                            //console.log('name=>',name)
                            if(JSON.stringify(propItem)!=="{}"){
                                let valor = null;
                                let par ;
    
                                switch (PropsInv?.tab) {
                                    case "IN":
                                        par =(propItem?.cantidad>0?propItem?.cantidad/2:0);
                                        valor = (par?par:null);
                                        break;
                                    case "O":
                                        par =(propItem?.N>0?Math.ceil(propItem?.N/2):0);
                                        valor = (par?par:null);
                                       //console.log(valor)
                                        break;
                                    case "G":
                                        par =(propItem?.cantidad>0?propItem?.cantidad/2:0);
                                        valor = (par?par:null);
                                        break;
                                    case "ST":
                                        par =(propItem?.ST>0?propItem?.ST/2:0);
                                        valor = (par?par:null);
                                        break;
                                    default:
                                        //console.log(propItem?.[PropsInv?.tab],'>>>',PropsInv?.tab)
                                        valor = propItem?.[PropsInv?.tab]||null;
                                        break;
                                }
                                if(PropsInv?.Pares==true){
                                    cell.style("numberFormat", "0.0");
                                }
                                    cell.value(valor);
                            }
                    })
                    if(sheet2){
                        
                        sheet2.range(rango).map(cell=>{
                            //console.log('name:',name,'<>',sheet.row(cell.rowNumber()).cell(columvalue).value(),'<>',sheet.row(rowvalue).cell(cell.columnNumber()).value())
                                const propItem = getitemOfInventario(name + (sheet.row(cell.rowNumber()).cell(columvalue).value()==undefined?"":sheet.row(cell.rowNumber()).cell(columvalue).value()) + (sheet.row(rowvalue).cell(cell.columnNumber()).value()==undefined?"":sheet.row(rowvalue).cell(cell.columnNumber()).value()),Inventario);
                                //console.log('name=>',name)
                                if(JSON.stringify(propItem)!=="{}"){
                                    let valor = null;
                                    let par ;
        
                                    switch (PropsInv?.tab) {
                                        case "G2":
                                            valor = (propItem?.ST||0);
                                            break;
                                        default:
                                            //console.log(propItem?.[PropsInv?.tab],'>>>',PropsInv?.tab)
                                            valor = propItem?.[PropsInv?.tab]||null;
                                            break;
                                    }
                                    if(PropsInv?.Pares==true){
                                        cell.style("numberFormat", "0.0");
                                    }
                                        cell.value(valor);
                                        cell.style("numberFormat", "0.0")
                                }
                        })
                    }
                })

                closemodalLoading();
                closeModalGenericFinish();
                return workbook.outputAsync()
                .then(function (blob) {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        // If IE, you must uses a different method.
                        window.navigator.msSaveOrOpenBlob(blob, (PropsInv.data.linea+"-"+".xlsx"));
                    } else {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = (PropsInv.data.linea+"-"+GetFechaActual().fecha+".xlsx");
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    }
                })
                .catch(function (err) {
                    throw err;
                });
            }
            
            openmodalLoading();
            return XlsxPopulate.fromDataAsync(inputfile.current.files[0])
            .then(estableceInfo)
            .catch(function (err) {
                closeModalGeneric();
                closemodalLoading();
                setmodaldangererrorConfig({
                isOpen: true,
                message: "Error dentro del Excel: "+(err.message || err||""),
                });
            })
        }

        generate();
    }

    const LoadFormat=()=> {

        const extraeInfo=(workbook)=>{
            const sheet = workbook.sheet(PropsInv.data.linea);
            const opcion =sheet.cell("A1").value();
            const name=("MI"+sheet.cell("B1").value()).toUpperCase();
            let inventario = [];
            const oldinv = [...Inventario];
            
            inventario = importFormat(sheet,name,opcion)
            //console.log(PropsInv?.tab)
            if(PropsInv?.tab=="G"){
                let invG = [];
                Inventario.forEach(prod=>{
                    const newprod ={...prod};
                    const findInv = inventario.filter(item=>item.id==prod.id);
                    //console.log(findInv)
                    if(findInv.length>0){
                        newprod.guideline = findInv[0].cantidad;
                        newprod.cantidad= 0;
                    }else{
                        newprod.guideline = 0;
                    }
                    invG.push(newprod)
                })
                //console.log('is Guide=>',oldinv);
                setInventario(invG);
                setPropsInventario({...PropsInv,inventarioAjuste:oldinv,importExcel:true,enableImport:false,enableExport:false});
            }else if (PropsInv?.tab=="B"){
                setInventario(inventario);
            }
            closemodalLoading();
            closeModalGenericFinish();
        }
    
        const generate=()=>{
            if (!inputfile.current.files[0]){
                setmodaldangererrorConfig({
                    isOpen: true,
                    message: "El archivo seleccionado no es valido",
                });
                return;
            }
            
            openmodalLoading();
            return XlsxPopulate.fromDataAsync(inputfile.current.files[0])
            .then(extraeInfo)
            .catch(function (err) {
                closeModalGeneric();
                closemodalLoading();
                setmodaldangererrorConfig({
                isOpen: true,
                message: "Error dentro del Excel: "+(err.message || err||""),
                });
            })
        }

        generate();   
    }

    const importFormat=(sheet,name,opcion)=>{
        let new_inventario = [];

        FormatosMicas[opcion].map(param=>{
            const keysignal = Object.keys(param)[0];
            const signal = (sheet.cell(Object.keys(param)[0]).value()==undefined?"":sheet.cell(Object.keys(param)[0]).value());
            const rango = param[keysignal];
            const columvalue = sheet.cell(Object.keys(param)[0]).columnNumber();
            const rowvalue = sheet.cell(Object.keys(param)[0]).rowNumber();
            
            /*console.log('key=>',keysignal);
            console.log('signal=>',signal);
            console.log('rango=>',rango);
            console.log('column=>',columvalue);
            console.log('row=>',rowvalue);*/

            sheet.range(rango).map(cell=>{
                const ciladd_value = (sheet.row(rowvalue).cell(cell.columnNumber()).value()==undefined?"":sheet.row(rowvalue).cell(cell.columnNumber()).value().toString());
                const esfera_value = (sheet.row(cell.rowNumber()).cell(columvalue).value()==undefined?"":sheet.row(cell.rowNumber()).cell(columvalue).value().toString());
                const itemID= name + esfera_value + ciladd_value;

                const propItem = getitemOfProps(itemID);
                const itemPropsSucursal = propItem?.sucursales[PropsInv.sucursales[0]]?{...propItem.sucursales[PropsInv.sucursales[0]]}:{};
                const cantidad = parseInt(cell.value())?parseInt(cell.value()):0;
                const newItem = {
                    id:itemID,
                    esfera:esfera_value,
                    ciladd:ciladd_value,
                    descripcion:propItem.descripcion,
                    linea:name+signal,
                    type: PropsInv.data.linea,
                    marca: PropsInv.data.marca,
                    categoria: PropsInv.data.categoria,
                    cantidad : cantidad,
                    comprable: itemPropsSucursal?.comprable || true,
                    vendible: itemPropsSucursal?.vendible || true,
                }

               if(PropsInv?.tab=="B"){
                    const pc =PropsInv.precioCompra;
                    const oldPc = itemPropsSucursal?.precioCompra|| pc;
                    const pv = itemPropsSucursal?.precioVenta||0;

                    newItem.guideline = itemPropsSucursal?.guideline||0;
                    newItem.oldExistencia = itemPropsSucursal?.existencia||0;
                    newItem.precioCompra = pc;
                    newItem.oldPrecioCompra = oldPc;
                    newItem.precioCompraPromediado = CalculaPrecioCompraPromediado(newItem.precioCompra,newItem.cantidad,newItem.oldPrecioCompra,newItem.oldExistencia);
                    newItem.minPrecioVenta = CalculaMinPrecioVenta(newItem.precioCompraPromediado,newItem.categoria);
                    newItem.precioVenta = pv<newItem.minPrecioVenta?newItem.minPrecioVenta:pv;
                }

                new_inventario.push(newItem);
            })            
        })
        return new_inventario;
    }

    const funcionError=(error)=>{
        closemodalLoading();
        setmodaldangererrorConfig({
          isOpen: true,
          message: (error||"")+" Error al cargar el catalogos",
        });
      }

      const PeticionLineas=()=>{
        openmodalLoading("Cargando Lineas");
        useCatalogo("LINEAS",(resp)=>{
            let keys = Object.keys(resp.opciones);
            setLineas(keys);
            closemodalLoading();
        },()=>{
            funcionError();
        });
      }
    

    useEffect(()=>{
        openmodalLoading();
        useCatalogo("MICAFORMATOS",(resp)=>{
            if(!resp?.opciones){
              funcionError();
              return;
            }
            setFormatos(resp.opciones);
            closemodalLoading();
            PeticionLineas();
        },funcionError);
    },[])

    useEffect(()=>{
        if(PropsInv?.enableImport==true){
            openModalGeneric();
            return;
        }
        if(PropsInv?.enableExport==true){
            openModalGeneric();
        }
    },[PropsInv?.enableImport,PropsInv?.enableExport])

    useEffect(() => {
        if(!hacerPeticion.start){return;}
        if(hacerPeticion.resp.length==0&&hacerPeticion?.lineas.length>0){
            openmodalLoading();
            let newLineas = [...hacerPeticion.lineas];
            const linea = newLineas.shift();
            let new_inventario = [];

                const peticion ={
                    indexName:"lineaIndex",
                    hashKey:{valor:linea,nombre:"linea"},
                    sortKey:{valor:"PRODUCT",nombre:"type",operador:"eq"}
                  }
                  useCustomQuery(peticion,(resp=>{
                    const datos = resp?.datos||[];
                    datos.forEach(item=>{
                        new_inventario.push({
                            id:item.id,
                            guideline:  PropsInv.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.guideline||0), 0),
                            cantidad: PropsInv.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.existencia||0), 0), 
                            N: PropsInv.sucursales.reduce((a, b) => parseInt(a) + parseInt((item?.sucursales?.[b]?.guideline||0)-(item?.sucursales?.[b]?.existencia||0)), 0),
                            precioVenta: (PropsInv.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.precioVenta||0), 0)/PropsInv.sucursales.length), 
                            precioCompra: (PropsInv.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.precioCompra||0), 0)/PropsInv.sucursales.length), 
                            esfera:item.esfera, 
                            ciladd:item.ciladd,
                            descripcion:item.descripcion, 
                            linea: linea,
                            categoria: item.categoria,
                            marca: item.marca,
                            type: item.linea,
                          })
                    });

                    let oldResp = [...hacerPeticion.resp,{linea:linea,datos:new_inventario}];
                    const newPeticion = {...hacerPeticion};

                    newPeticion.resp =oldResp;
                    newPeticion.lineas = newLineas;
                    setHacerPetition(newPeticion)
                  }),funcionError);
                  return;
        }
        if(hacerPeticion.resp.length>0&&hacerPeticion?.lineas.length>0){
            let newLineas = [...hacerPeticion.lineas];
            const linea = newLineas.shift();
            let new_inventario = [];
                const peticion ={
                    indexName:"lineaIndex",
                    hashKey:{valor:linea,nombre:"linea"},
                    sortKey:{valor:"PRODUCT",nombre:"type",operador:"eq"}
                  }
                  useCustomQuery(peticion,(resp=>{
                    const datos = resp?.datos||[];
                    datos.forEach(item=>{
                        new_inventario.push({
                            id:item.id,
                            guideline:  PropsInv.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.guideline||0), 0),
                            cantidad: PropsInv.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.existencia||0), 0), 
                            N: PropsInv.sucursales.reduce((a, b) => parseInt(a) + parseInt((item?.sucursales?.[b]?.guideline||0)-(item?.sucursales?.[b]?.existencia||0)), 0),
                            precioVenta: (PropsInv.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.precioVenta||0), 0)/PropsInv.sucursales.length), 
                            precioCompra: (PropsInv.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.precioCompra||0), 0)/PropsInv.sucursales.length), 
                            esfera:item.esfera, 
                            ciladd:item.ciladd,
                            descripcion:item.descripcion, 
                            linea: linea,
                            categoria: item.categoria,
                            marca: item.marca,
                            type: item.linea,
                          })
                    });

                    let oldResp = [...hacerPeticion.resp,{linea:linea,datos:new_inventario}];
                    const newPeticion = {...hacerPeticion};

                    newPeticion.resp =oldResp;
                    newPeticion.lineas = newLineas;
                    setHacerPetition(newPeticion)
                  }),funcionError);
                  return;
        }
        if(hacerPeticion.resp.length==lineasSelected.length&&hacerPeticion?.lineas.length==0){
            closemodalLoading();
            //setHacerPetition({start:false,lineas:[]});
            console.log('pet=>',hacerPeticion)
            ExportFormats();
        }
    }, [hacerPeticion])
    

  return (
      <>
        <LoadingModal z="z-50" config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>{"Cargando Formato "}</LoadingModal.Title>
        </LoadingModal.Body>
        </LoadingModal>
        <Modaldanger config={modaldangererrorConfig} front="z-40">
        <Modaldanger.Message title={"Error"}>
        {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
        <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
        >
            Cerrar
        </button>
        </Modaldanger.Buttons>
        </Modaldanger>
        <Modalgeneric h="h-5/6" config={modalGeneric}>
        <Modalgeneric.Body>
        <Modalgeneric.Title>Importar Formato</Modalgeneric.Title>
            {files?
            <div className='w-full '>
                <Multiselector
                filters={true}
                savechange={(data) => {
                  if(JSON.stringify(data)!==JSON.stringify(lineas)){
                    setLineasSelected(data)
                  }else if(lineas.length==0){
                    setLineasSelected([])
                  }
                }}
                data={lineas}
              />
            </div>
            :null}
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
            <button className='btn-blue w-40' hidden={!PropsInv?.enableExport||!openFile} onClick={e=>{
                if(!inputfile.current.files[0]){
                    setOpenFile(false);
                    setmodaldangererrorConfig({
                    isOpen: true,
                    message: "Seleccion un archivo porfavor" ,
                    });
                    return
                }
                if(files&&lineasSelected.length>0){
                    setHacerPetition({...hacerPeticion,start:true,lineas:[...lineasSelected]});
                }else{
                    LoadFormatExport();
                }
            }}>
                Exportar
            </button>
            <button className='btn-blue w-40' hidden={!PropsInv?.enableImport} onClick={e=>{
                if(!inputfile.current.files[0]){
                    //inputfile.current.click();
                    setOpenFile(false);
                    setmodaldangererrorConfig({
                    isOpen: true,
                    message: "Seleccion un archivo porfavor" ,
                    });
                    return
                }
                LoadFormat();
            }}>
                Importar
            </button>
            <button className={('btn-green w-40')} onClick={e=>{
                setOpenFile(true);
                inputfile.current.click();
            }}>
                Abrir Excel
            </button>
           
            <button
                type="button"
                onClick={e=>{
                    setOpenFile(false);
                    closeModalGeneric();
                }}
                className="btn-gray1"
            >
                Cancelar
            </button>
            <button className='btn-blue w-40' hidden={!PropsInv?.enableExport} onClick={e=>{
                setFiles(!files);
            }}>
                Varios
            </button>
        </Modalgeneric.Buttons>
        </Modalgeneric>
        <div className={'flex flex-col w-full justify-center items-center'}>
            <input 
            className='hidden'
            ref={inputfile}
            type="file" 
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
        </div>
    </>
  )
}

const mapStateToProps = (state) => ({
    Inventario: state.Inventario,
    PropsInv: state.PropsInventario
})

const mapDispatchToProps = dispatch => ({
    addInventario: (item) => dispatch(agregarItemInventario(item)),
    setInventario:(items)=>dispatch({type:"SET_INVENTARIO",inventario:items}),
    setPropsInventario:(data)=> dispatch({type:"SET_PROPSINVENTARIO", estado:data}),
})

export default connect(mapStateToProps,mapDispatchToProps)(TestExcel);
