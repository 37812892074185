import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { returnRuta } from "../../redux/Actions";
import {  shortIdToId, Sucursal, TransformSys, FechaUTCToLocal } from "../../services/functions.js";
import Labelmoney from "../labelmoney";
import Newlogo from "../../static/img/logonew.jsx";
import {motion} from 'framer-motion'


const TicketCotizacion = ({ Ticket, delRuta }) => {
  const [showIva,setShowIva] = useState(false),
  ivaSucursal = Sucursal("iva")?Sucursal("iva")/100:0.0;
  useEffect(() => { window.print();}, []);

  return (
    <>
      <div className=" 80mm">
        <div className="w-full text-2xs">
          <div className="grid grid-flow-col justify-items-stretch w-full">
            <button className="text-base mt-3 justify-self-end font-black uppercase" 
            onClick={e=>{
              setShowIva(!showIva);
            }}>COTIZACION</button>
            <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{
              scale: 0.8,
              rotate: 5,
              borderRadius: "100%"
            }}
            onClick={(e) => {delRuta(); }}
            className={ "w-[80px] justify-self-end h-14 border-none leading-relaxed inline-block" }
            >
              <Newlogo corona="#00ace5" corona1="#00C9E5"  text="#000000"/>
            </motion.button>
          </div>
          <div className="w-full">
            <p className="uppercase w-full text-lg font-bold text-center">{Sucursal('nombre')}</p>
            <p className="uppercase">{Sucursal('direccion')+" C.P. "+Sucursal('codigo_postal')+" Tel. "+Sucursal('telefono')}</p>
            <p className="uppercase"> e-mail: {Sucursal('email')}</p>
            <p className="uppercase ">Cotizacion: <label className="font-bold">{Ticket?.id}</label> Fecha: <label className="font-bold">{FechaUTCToLocal(shortIdToId(Ticket?.id)).fecha+ " "+ FechaUTCToLocal(shortIdToId(Ticket?.id)).hora}</label> </p>
            <p className="uppercase">Cliente: <label className="font-bold">{TransformSys(Ticket?.cliente)}</label>   VENDEDOR: <label className="font-bold">{Ticket?.nombreVendedor}</label></p>
          </div>
            <table className="w-full text-2xs font-semibold mt-1">
              <thead>
                <tr>
                  <th className="text-center">Cant</th>
                  <th className="text-center mx-0.5">Descripción</th>
                  <th className="text-right w-12">Precio U</th>
                  {showIva?<th className="text-right w-12">{"Iva"}</th>:null}
                  <th className="text-right w-10">Desc</th>
                  <th className="text-right w-16">Subtotal</th>
                </tr>
              </thead>
              <tbody className="tracking-tight">
                {Ticket?.items
                  ? Ticket.items.map((item, index) => {
                    if(item?.agregados){
                      if(showIva){
                        const iva = (item.precio-(item.precio/(1+ivaSucursal))),precioSinIva=(item.precio/(1+ivaSucursal));
                        return (<tr key={index}>
                          <td className=" text-center">{item.cantidad}</td>
                          <td className=" text-start text-3xs "><p  >{item.descripcion.substring(0,35)}</p></td>
                          <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{precioSinIva}</Labelmoney></td>
                          <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{iva}</Labelmoney></td>
                          <td className="w-10 text-right ">{item.descuento + "%"}</td>
                          <td className="w-16 text-right "><Labelmoney twoDecimal={true}>{item.importe-(iva*item.cantidad)}</Labelmoney></td>
                        </tr>)
                      }
                      return (
                      <tr key={index} >
                        <td className=" text-center ">{item.cantidad}</td>
                        <td className=" text-start text-3xs "><p  >{item.descripcion.substring(0,35)}</p></td>
                        <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{item.precio}</Labelmoney></td>
                        <td className="w-10 text-right ">{item.descuento + "%"}</td>
                        <td className="w-16 text-right "><Labelmoney twoDecimal={true}>{item.importe}</Labelmoney></td>
                      </tr>
                      )
                    }else{
                      if(showIva){
                        const iva = (item.precio-(item.precio/(1+ivaSucursal))),precioSinIva=(item.precio/(1+ivaSucursal));
                        return (<tr key={index}>
                          <td className=" text-center">{item.cantidad}</td>
                          <td className=" text-start text-3xs"><p >{(item?.id?item.id+" ":"")+item.descripcion.substring(0,25)}</p></td>
                          <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{precioSinIva}</Labelmoney></td>
                          <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{iva}</Labelmoney></td>
                          <td className="w-10 text-right ">{item.descuento + "%"}</td>
                          <td className="w-16 text-right "><Labelmoney twoDecimal={true}>{item.importe-(iva*item.cantidad)}</Labelmoney></td>
                        </tr>)
                      }
                      return (<tr key={index}>
                        <td className=" text-center">{item.cantidad}</td>
                        <td className=" text-start text-3xs"><p >{(item?.id?item.id+" ":"")+item.descripcion.substring(0,25)}</p></td>
                        <td className="w-12 text-right "><Labelmoney twoDecimal={true}>{item.precio}</Labelmoney></td>
                        <td className="w-10 text-right ">{item.descuento + "%"}</td>
                        <td className="w-16 text-right "><Labelmoney twoDecimal={true}>{item.importe}</Labelmoney></td>
                      </tr>)
                    }
                  })
                  : null}
              </tbody>
              <tfoot>
                <tr>
                  <td className="text-center"> {Ticket?.numeroArticulos || ""}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {showIva?
                  <><td></td><td className="text-center"><Labelmoney twoDecimal={true}>{Ticket?.total - Ticket.iva}</Labelmoney></td></>:
                  <td className="text-center"><Labelmoney twoDecimal={true}>{Ticket?.total || 0}</Labelmoney></td>}
                  
                </tr>
              </tfoot>
            </table>
            {showIva?
              <div className="grid grid-cols-2 w-1/2 ">
                <label>SubTotal:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.subtotal-Ticket?.iva}</Labelmoney>
                <label>{"IVA:"}</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.iva||0}</Labelmoney>
                <label className={(Ticket?.subtotal - Ticket?.total>0?"block":"hidden")}>Descuento: </label><p className={(Ticket?.subtotal - Ticket?.total>0?"block":"hidden")}><Labelmoney className="w-16" twoDecimal={true}>{(Ticket?.subtotal - Ticket?.total)||0}</Labelmoney></p>
                <label>Importe:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.total}</Labelmoney>
              </div>
            : <div className="grid grid-cols-2 w-1/2 ">
                <label>SubTotal:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.subtotal}</Labelmoney>
                <label className={(Ticket?.subtotal - Ticket?.total>0?"block":"hidden")}>Descuento: </label><p className={(Ticket?.subtotal - Ticket?.total>0?"block":"hidden")}><Labelmoney className="w-16" twoDecimal={true}>{(Ticket?.subtotal - Ticket?.total)||0}</Labelmoney></p>
                <label>Importe:</label><Labelmoney className="w-16" twoDecimal={true}>{Ticket?.total}</Labelmoney>
              </div> }
            <div className="w-full text-center">*Precios sujetos a cambio sin previo aviso</div>
            <div className="w-full text-center">*Esta cotizacion tiene una vigencia de 5 dias</div>
            {Sucursal('ticket')?.productos.length?
              <div className="w-full text-center mt-3">
                <p className="uppercase w-full text-center font-black mb-1">Nuevos Productos</p>
                {Sucursal('ticket').productos.map((prod,index)=>(<p key={index} className="uppercase w-full text-center">{prod}</p>))}
              </div>
            :""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Ticket: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  delRuta:()=> dispatch(returnRuta()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketCotizacion);
