import React, { useEffect } from "react";
import { connect } from "react-redux";
import Layout from "./views/Layout";
import Caja from "./views/Caja.js";
import Venta from "./views/Venta";
import Login from "./views/Login";
import MenuInicio from "./views/MenuInicio";
import AltaProducto from "./views/AltaProducto.js";
import Personal from "./views/personal/wrapperformpersonal";
import Cliente from "./views/cliente/wrapperformcliente";
import Proveedor from "./views/proveedor/wrapperformproveedor";
import Building from "./views/Building";
import Compra from "./views/Compra";
import TicketCotizacion from "./components/Tickets/TicketCotizacion";
import TicketPagado from "./components/Tickets/TicketPagado";
import TicketPagadoAbonos from "./components/Tickets/TicketPagadoAbonos";
import Inventario from "./views/Inventario";
import Configuraciones from "./views/Configuraciones";
import Sucursal from "./views/sucursal/wrapperformsucursal";
import PagosCompra from "./views/PagosCompra";
import PedidoEspecial from "./views/PedidoEspecial";
import ExyCash from "./views/ExyCash";
import PagosPes from "./views/PagosPes";
import Reportes from "./views/Reportes";
import TicketCopia from "./components/Tickets/TicketCopia";
import PanelAjuste from "./components/Inventario/PanelAjuste";
import PanelMermas from "./components/Inventario/PanelMermas";
import Bisel from "./views/Bisel";
import Tallado from "./views/Tallado";
import TicketFact from "./components/Facturacion/TicketFactura/TicketFact";
import TicketPagadoPes from "./components/Tickets/TicketPagadoPes";
import TicketPagadoAbonosPes from "./components/Tickets/TicketPagadoAbonosPes";
import TicketCopiaPes from "./components/Tickets/TicketCopiaPes";
import Bitacora from "./components/Laboratorio/Bitacora.jsx";


const rutas = {
  login: <Login />,
  menu: <MenuInicio/>,
  venta:<Venta/>,
  compra: <Compra/>,
  inventario: <Inventario />,
  reportes:<Reportes/>,
  altaProducto: <AltaProducto />,
  caja: <Caja />,
  personal: <Personal />,
  clientes: <Cliente />,
  proveedor: <Proveedor />, 
  ticketCotizacion: <TicketCotizacion />,
  ticketPagado: <TicketPagado />,
  ticketPagadoAbonos: <TicketPagadoAbonos />,
  ticketCopia:<TicketCopia/>,
  configuraciones:<Configuraciones/>,
  sucursales:<Sucursal/>,
  PagosCompra: <PagosCompra/>,
  pedidoEspecial:<PedidoEspecial/>,
  cash:<Reportes ruta="cashRango" text="exycash" />,
  exycash:<ExyCash/>,
  PagosPes: <PagosPes/>,
  panelAjuste:<PanelAjuste/>,
  panelMerma:<PanelMermas/>,
  bisel:<Bisel/>,
  tallado:<Tallado/>,
  facturas:<TicketFact/>,
  micas:<Inventario />,
  test:<Reportes ruta="tester" text="exycash" />,
  laboratorio:<Bitacora/>,

  receiving: <Reportes ruta="comprasDay" text="compra" />,
  sales:<Reportes ruta="ventasRango" text="venta" />,
  inventory: <Reportes ruta="inventarioCategoria" text="inventario" />,
  balanceProveedor:<Reportes ruta="proveedorBalance" text="proveedor"/>,
  balanceCliente: <Reportes ruta="clienteBalance" text="clientes"/>,
  receivingFolio: <Reportes ruta="comprasFolio" text="compra" />,
  arother: <Reportes ruta="arotherBalance" text="arother" />,
};

const App = ({ ruta,setWindowsSize }) => {
  document.body.style = "background: #D1D5DB;";
  window.scrollTo(0, 0);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowsSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    setWindowsSize(window.innerWidth);
  },[]);

  if (ruta === "ticketCotizacion") {
    document.body.style = "background: #ffffff;";
    return <TicketCotizacion />;
  }
  if (ruta === "ticketPagado") {
    document.body.style = "background: #ffffff;";
    return <TicketPagado />;
  }
  if (ruta === "ticketPagadoAbonos") {
    document.body.style = "background: #ffffff;";
    return <TicketPagadoAbonos />;
  }
  if (ruta === "ticketCopia") {
    document.body.style = "background: #ffffff;";
    return <TicketCopia/>;
  }  
  if (ruta === "ticketPagadoPes") {
    document.body.style = "background: #ffffff;";
    return <TicketPagadoPes />;
  }
  if (ruta === "ticketPagadoAbonosPes") {
    document.body.style = "background: #ffffff;";
    return <TicketPagadoAbonosPes />;
  }
  if (ruta === "ticketCopiaPes") {
    document.body.style = "background: #ffffff;";
    return <TicketCopiaPes/>;
  }  

  return <Layout>{rutas[ruta] ?? <Building />}</Layout>;
};

const mapStateToProps = (state) => ({
  ruta: state.Ruta[state.Ruta.length-1],
});

const mapDispatchToProps = (dispatch) => ({
  setWindowsSize: (width)=>dispatch({type:"SET_WIDTH_WINDOWS",width:width}),
});

export default connect(mapStateToProps,mapDispatchToProps)(App);
