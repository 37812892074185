import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { apiUsePromise, FechaLocalToUTC, GetFechaActual, GetFechaActualSeparado, getSucursal, OrderArrayAZ, tipoCelda, TransformSysAll, valueToMoney2 } from '../../../services/functions';
import Modaldanger from '../../modal/danger';
import LoadingModal from '../../modal/LoadingModal';
import TableReactWindows from '../Cash/TableReactWindows';
import {setRutaRep} from '../../../redux/Actions';
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from '../../Excel/DataToXlsx';
import Modalsuccess from '../../modal/success';

const ReporteAR = ({PropsReportes,addDatos,setRutaRep,Datos,setHeader}) => {
  const [balance,setBalance]=useState([]),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const nameComponent = "clienteBalance",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const datosComponent = Datos[nameComponent]||{};
  const new_cliente= { balanceBefore:0, vendido:0,pagado:0,descuento:0,devolucion:0,balance:0};
  const fechaI = FechaLocalToUTC(PropsReportes.fecha_Inicial),fechaF=FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59);
  const todayStart=FechaLocalToUTC(GetFechaActual().fecha),todayEnd=FechaLocalToUTC(GetFechaActual().fecha,23,59,59);

  let peticiones =[
    {
      indexName:"reverseIndex",
      hashKey:{valor:("CLIENT"),nombre:"type"},
      filters:[{valor:getSucursal(),operador:"eq",nombre:"sucursal"}]
    },
    {
      hashKey:{valor:("REPORTE#CLIENTES#"+getSucursal()),nombre:"id"},
      sortKey:{valor:['2023-01-01',PropsReportes.fecha_Final],operador:"between",nombre:"type"},
      //GetFechaActualSeparado().ano+
    },

    {
      indexName:"reverseIndex",
      hashKey:{valor:("VENTA#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[todayStart,todayEnd],operador:"between",nombre:"id"},
    },
    {
      hashKey:{valor:("AR#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[todayStart,todayEnd],operador:"between",nombre:"type"},
    },
    {
      hashKey:{valor:("AROUT#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[todayStart,todayEnd],operador:"between",nombre:"type"},
    },
    {
      indexName:"reverseIndex",
      hashKey:{valor:("PE#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[getSucursal()+'#'+todayStart, getSucursal()+'#'+todayEnd],operador:"between",nombre:"id"},
    },
    {
      indexName:"reverseIndex",
      hashKey:{valor:("DEVOLUCION#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[todayStart,todayEnd],operador:"between",nombre:"id"},
    },
  ];

    
  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false });};


  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }


  const handleSuccesfulDevoluciones=(balance,balanceCliente,data)=>{
    let new_balan = [...balance];
      data.forEach(venta=>{
        const cliente =TransformSysAll(venta.cliente);
        new_balan.map(registro=>{
          if(cliente==registro.cliente){
            if(venta.id<fechaI){
              registro.balanceBefore += ((venta?.total||0)-(venta?.devolucion||0));
              balanceCliente[cliente].balanceBefore += ((venta?.total||0)-(venta?.devolucion||0));
            }else if(venta.id>=fechaI&&venta.id<=fechaF){
              registro.vendido+=venta.subtotal;
              registro.descuento+=venta?.descuentoTotal||0;
              registro.devolucion+=venta?.devolucion||0;
            }
            registro.registros.push({...new_cliente,fecha:venta.id,referencia:venta.shortId,vendido:venta.subtotal,descuento:venta?.descuentoTotal||0,devolucion:venta?.devolucion||0,type:'dev'});
            balanceCliente[cliente].registros.push({...new_cliente,fecha:venta.id,referencia:venta.shortId,vendido:venta.subtotal,descuento:venta?.descuentoTotal||0,devolucion:venta?.devolucion||0,type:'dev'});
          }
        })
      })
      //setComprasAntes(cb);
      //setComprasDespues(ca);
      return [new_balan,balanceCliente];
  }


  function getTotal(array,campo) {
    return array.reduce((a, b) => parseInt(a) + parseInt(b?.[campo]||0), 0)
  }

  const getBalanceAll=()=>{
    const balanceGeneral = datosComponent[0];
    //console.log('===>',balanceGeneral)
    const newBalance={},balanceArray=[];

    balanceGeneral.forEach(reg=>{
      if(!newBalance[reg.cliente]){
        newBalance[reg.cliente]={...new_cliente,nombre:reg.nombre};
      }
      reg.registros.forEach(item=>{
        if(item.fecha>=fechaI&&item.fecha<=fechaF){
          newBalance[reg.cliente].vendido += item.vendido;
          newBalance[reg.cliente].pagado += item.pagado;
          newBalance[reg.cliente].descuento += item.descuento;
          newBalance[reg.cliente].devolucion += item.devolucion;
        }else if(item.fecha<fechaI){
          newBalance[reg.cliente].balanceBefore += (item.vendido-item.pagado-item.descuento-item.devolucion);
        }
      })
    })

    Object.keys(newBalance).forEach(key=>{
      const r = {...newBalance[key]};
      r.cliente = key;
      r.balance = r.balanceBefore+r.vendido-r.pagado-r.descuento-r.devolucion;
      balanceArray.push(r);
    })
    //console.log(balanceArray);
    setBalance(balanceArray);
  }

  const getRep=async(reporte,allRep,petLast)=>{
    if(petLast){ peticiones[1].LastEvaluatedKey = petLast;}

    const responseReporte= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[1]});
    const reportes = responseReporte.data.datos;
    
    reportes.forEach(rep=>{
      const keys = Object.keys(rep.clientes);
      if(rep.type<PropsReportes.fecha_Inicial){
        keys.forEach(clave=>{
          if(reporte[clave]){
            reporte[clave].balanceBefore += ((rep.clientes[clave]?.ventas||0)-(rep.clientes[clave]?.descuento||0)-(rep.clientes[clave]?.pagos||0));
          }
        })
      }else if(rep.type>=PropsReportes.fecha_Inicial&&rep.type<=PropsReportes.fecha_Final){
        keys.forEach(clave=>{
          if(reporte[clave]){
            reporte[clave].pagado+= rep.clientes[clave]?.pagos||0;
            reporte[clave].descuento+= rep.clientes[clave]?.descuento||0;
            reporte[clave].vendido+= rep.clientes[clave]?.ventas||0;
          }
        })
      }
      keys.forEach(clave=>{
        if(allRep[clave]){
          allRep[clave].registros.push({pagado:rep.clientes[clave]?.pagos||0,descuento:rep.clientes[clave]?.descuento||0,vendido:rep.clientes[clave]?.ventas||0,fecha:rep.type});
        }
      })
    })

    if(responseReporte.data?.LastEvaluatedKey){
      await getRep(reporte,allRep,responseReporte.data.LastEvaluatedKey);
    }
  }

  const getVentas=async(reporte,petLast)=>{
    if(petLast){ peticiones[2].LastEvaluatedKey = petLast;}
    const responseVentas= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[2]});
    const repVentas = responseVentas.data.datos;
    const ventas =  repVentas.filter(p=>(!['PENDIENTE#'+getSucursal()].includes(p.statusTemp)));

    ventas.forEach(venta=>{
      const cliente =TransformSysAll(venta.cliente);
      if(reporte[cliente]){
        reporte[cliente].vendido += venta.subtotal ||0;
        reporte[cliente].descuento+=venta?.descuentoTotal||0;
      }
    })

    if(responseVentas.data?.LastEvaluatedKey){
      await getVentas(reporte,responseVentas.data.LastEvaluatedKey);
    }
  }

  const getAR=async(reporte,petLast)=>{
    if(petLast){peticiones[3].LastEvaluatedKey = petLast; }
    const responsePes= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[3]});
    const pagos = responsePes.data.datos;
    
    pagos.forEach(pago=>{
      const cliente =TransformSysAll(pago.contracuenta);
      if(reporte[cliente]){
        reporte[cliente].pagado += pago.importe ||0;
      }
    })

    if(responsePes.data?.LastEvaluatedKey){ await getAR(reporte,responsePes.data.LastEvaluatedKey);}
  }

  const getAROUT=async(reporte,petLast)=>{
    if(petLast){ peticiones[4].LastEvaluatedKey = petLast;}
    const responsePes= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[4]});
    const prestamos = responsePes.data.datos;
    
    prestamos.forEach(arout=>{
      const cliente =TransformSysAll(arout.contracuenta);
      if(reporte[cliente]){
        reporte[cliente].vendido += (arout.importe ||0)*-1;
      }
    })

    if(responsePes.data?.LastEvaluatedKey){ await getAROUT(reporte,responsePes.data.LastEvaluatedKey);}
  }

  const getPes=async(reporte,petLast)=>{
    if(petLast){ peticiones[5].LastEvaluatedKey = petLast;}
    const responsePes= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[5]});
    const pedidosPes = responsePes.data.datos;
    const pes =  pedidosPes.filter(p=>(!['CANCELADO','SOLICITADO'].includes(p.status)));

    pes.forEach(pedido=>{
      const cliente = TransformSysAll(pedido.cliente)

      if(reporte[cliente]){
        reporte[cliente].vendido += pedido.importe ||0;
      }
    })
    if(responsePes.data?.LastEvaluatedKey){ await getPes(reporte,responsePes.data.LastEvaluatedKey); }
  }
  

  useEffect(()=>{
    if(PropsReportes.rutas.length!==1){return;}
    if(rutaActualRep!==nameComponent){return;}

    const getBalanceCliente=async()=>{
      openmodalLoading();
     
      try{
        const reporte = {},allRep={},responseClientes = await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[0]});
        let clientes = responseClientes.data.datos,balanceCliente =[];

        clientes = OrderArrayAZ(clientes,"id","string");
        clientes.forEach(client=>{
          const cliente =TransformSysAll(client.id);
          if(!reporte[cliente]){ reporte[cliente] = {...new_cliente,cliente:cliente, nombre:client.nombre};}
          if(!allRep[cliente]){allRep[cliente] = {registros:[],cliente:cliente, nombre:client.nombre}}
        })

        await getRep(reporte,allRep);
        if(GetFechaActual().fecha==PropsReportes.fecha_Final){
          await getVentas(reporte);
          await getAR(reporte);
          await getAROUT(reporte);
          await getPes(reporte);
          //await devolucion(reporte);
        }

        for(let cliente of Object.keys(reporte)){
          reporte[cliente].balance =  reporte[cliente].balanceBefore +reporte[cliente].devolucion + reporte[cliente].vendido - reporte[cliente].descuento - reporte[cliente].pagado;
          balanceCliente.push(reporte[cliente]);
        }

        //console.log(balance);
        closemodalLoading();
        setBalance(balanceCliente);
        addDatos(nameComponent,[balanceCliente,{fechai:PropsReportes.fecha_Inicial,fechaf:PropsReportes.fecha_Final,allRep:allRep}]);
      }catch(Error){
        closemodalLoading();
        handleError(Error);
      }
    }

    if(JSON.stringify(datosComponent)=='{}'){
      getBalanceCliente();
    }else{
      if(PropsReportes.fecha_Inicial==datosComponent[1]?.fechai&&PropsReportes.fecha_Final==datosComponent[1]?.fechaf){
        setBalance(datosComponent[0]);
        return;
      }
      getBalanceCliente()
    }
  },[PropsReportes.fecha_Inicial,PropsReportes.fecha_Final,PropsReportes.rutas]);

  useEffect(()=>{
    if(rutaActualRep!==nameComponent){return;}
    if(!datosComponent[0]){return}
     setHeader({text:"AR "})
  },[PropsReportes.rutas,Datos])
    

  return (
  <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
            <LoadingModal.Title>Cargado Productos</LoadingModal.Title>
          </LoadingModal.Body>
      </LoadingModal>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>
      <TableReactWindows
        btnDownload={async(e,datos)=>{
          if(!balance?.length){handleError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            handleError(workb.error);
            return
          }

          const data = datos||balance,
          nameFile="BalanceAR",
          headerFile="Reporte balance de clientes del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={'Cliente':13,'Beg Balance':17,'Ventas':17,'Pagos':17,'Devolucion':17,'Descuento':17,'End Balance':17,'Nombre':40};
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((ar,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(ar.cliente);
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(valueToMoney2(ar?.balanceBefore||0));
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(ar?.vendido||0));
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(valueToMoney2(ar?.pagado||0));
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(ar?.devolucion||0));
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(ar?.descuento||0));
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(ar?.balance||0));
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(ar.nombre);
          });
      
          sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[1]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[3]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[4]+sizeData).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[4]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[6]+sizeData).formula(""+columnasXlsx[1]+sizeData+"+"+columnasXlsx[2]+sizeData+"-"+columnasXlsx[3]+sizeData+"-"+columnasXlsx[4]+sizeData+"-"+columnasXlsx[5]+sizeData);
          sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);
      
          
          const response =await SaveXlsx(workb,nameFile);
          if(response?.error){
            closemodalLoading();
            handleError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
        }}
        columns={[
          {
            key:"cliente",
            header:"Customer",
            width:100, 
            type: tipoCelda.text,
            className: "text-blue-800",
            filter:true,
            onClick: ({e,row})=>{
              if(e.detail==2){
                const oldDatos = Datos['clienteBalanceDia'] || [];
                addDatos(
                  "clienteBalanceDia",
                  [
                    (oldDatos?.[1]?.cliente==row.cliente?oldDatos[0]:null),
                    {
                      fechai:(oldDatos?.[1]?.fechai || PropsReportes.fecha_Inicial),
                      fechaf:(oldDatos?.[1]?.fechaf || PropsReportes.fecha_Final),
                      balance:row,
                      cliente:row.cliente,
                      allRep:datosComponent[1]?.allRep?.[row.cliente]?.registros
                    }
                  ]
                )
                setHeader({text:"AR Detail ",cliente:row.cliente})
                setRutaRep("clienteBalanceDia");
              } 
            }
          },
          {
            key:"balanceBefore",
            header: "Beg Balance",
            width:120,
            type: tipoCelda.money,
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"vendido",
            header: "Sales",
            width:100,
            type: tipoCelda.money,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"pagado",
            header: "Collection",
            width:100,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"descuento",
            header: "Discount",
            width:100,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"balance",
            header: "Balance",
            width:130,
            type: tipoCelda.money,
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"nombre",
            header:"Name",
            width:350, 
            type: tipoCelda.text,
            className: "text-blue-800",
            filter:true,
          }
        ]}
        data={balance}
      />
      </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect (mapStateToProps,mapDispatchToProps)(ReporteAR)