import React, { useEffect, useState} from "react";
import TableReportes from "../TableReportes";
import { connect } from "react-redux";
import { apiUsePromise, getSucursal } from "../../../services/functions";
import LoadingModal from "../../modal/LoadingModal";
import Modaldanger from "../../modal/info";
import { useCatalogo } from "../../../Hooks/useApis";
import Labelmoney from "../../labelmoney";
import LabelNumber from "../../LabelNumber";
import { useRef } from "react";
import { setRuta, setRutaRep } from "../../../redux/Actions";

const ReporteLinea = ({setRutaRep,PropsReportes,setHeader,Datos,setPropsInventario,propsInventario,addRutaGlobal,setCleanInventario,addDatos}) => {
  const [inventario,setInventario]=useState([]),
  [modalLoading, setmodalLoading] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [Lineas,setLineas]=useState([]);
  const filterInventory =  useRef([]);
  const nameComponent = "inventarioLinea",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];

  const funcionError=(error)=>{
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: (error||"")+" Error al cargar el catalogos",
    });
  }

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
    setmodalLoading({ isOpen: false });
  };

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  const getLineas = async(item)=>{
    const peticion ={ 
     indexName: "lineaSearchIndex",
     hashKey: {valor:item.nombre,nombre:"linea"},
     sortKey: {valor:"true",nombre:"search",operador:"eq"}
    };
    try{
      const responseLineas = await apiUsePromise({ url: "/custom-query", method: "POST",data: peticion});
      let lineas = responseLineas.data;
      let codigos = lineas.datos.filter(line=>(line.categoria==item.categoria));
      codigos = codigos.filter(line=>(line.marca==item.marca));
      codigos = codigos.filter(line=>(line.sucursales?.[getSucursal()]));

      if(codigos.length>1){
        addDatos("inventarioLineaCodigo",[codigos,{categoria: Datos[nameComponent][0].categoria,marca:item.marca,linea:item.nombre}])
        setRutaRep("inventarioLineaCodigo");
      }else if(codigos[0].type=="LINEA"){
          setCleanInventario();
          const data =  {...propsInventario.data,id:codigos[0].id,schema:{},tablas:[]};
          setPropsInventario({...propsInventario,sucursales:[getSucursal()], data:data,items:[]});
          addRutaGlobal("micas");
      }
    }catch(error){
      closemodalLoading();
      console.log('er');
      //handleError(error);
    }
  }

  useEffect(()=>{
    openmodalLoading();
    useCatalogo("LINEAS",(resp)=>{
        if(!resp?.opciones){
          funcionError();
          return;
        }
        setLineas(resp.opciones);
        closemodalLoading();
      },funcionError);

  },[])

  useEffect(()=>{
    if(rutaActualRep!==nameComponent){return;}
    if(!Datos[nameComponent][0]){return}
    const lineas = Datos[nameComponent][0];
    let arreglo =[],filter=[];
    for(let marca of Object.keys(lineas)){
      if(typeof lineas[marca] === "object" && !Array.isArray(lineas[marca])){
          arreglo.push(lineas[marca])
          if(lineas[marca]?.qohb>0||lineas[marca]?.cantidadComprada>0||lineas[marca]?.cantidadVendida>0){
            filter.push(lineas[marca]);
          }
      }
    }
    filterInventory.current = arreglo;
    setInventario(filter);
},[PropsReportes.Datos])

useEffect(()=>{
  if(rutaActualRep!==nameComponent){return;}
  if(!Datos[nameComponent][0]){return}
   setHeader({text:"Inventory "+Datos[nameComponent][1].categoria+" - "+Datos[nameComponent][1].marca})
},[PropsReportes.rutas])

  return (
    <>
          <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Catalogos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal> 
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2"
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>
      <TableReportes
        searchinput={false}
        columns={[
          {
            header: "Line",
            width:"w-48",
                        // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <div 
              className="w-full flex justify-center text-center cursor-pointer"
              onClick={e=>{
                if(e.detail==2){
                  //setHeader({...PropsReportes.header,day:item.type})
                  //setRuta("ventasDay");
                  //console.log(item)
                  getLineas(item);
                }
              }}
              >
                <input
                type="text"
                className="border-table w-full text-center"
                readOnly={true}
                value={item?.nombre}
                />
  
              </div>
            )
          },
          {
            header: "QohBgn",
            width:"w-24",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <LabelNumber className={item?.qohb==0?"hidden":"text-size-table text-right w-full"}>{item?.qohb}</LabelNumber>           
            ),
          },
          {
            header: "Rcvd",
            width:"w-20",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
                <LabelNumber className={item?.cantidadComprada==0?"hidden":"text-size-table text-blue-700 text-right w-full"}>{item?.cantidadComprada||""}</LabelNumber>   
            ),
          },
          {
            header: "Sold",
            width:"w-20",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
               <LabelNumber className={item?.cantidadVendida==0?"hidden":"text-size-table text-pink-700 text-right w-full"}>{item?.cantidadVendida||""}</LabelNumber>  
            ),
          },
          {
            header: "Qoh End",
            width:"w-24",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
               <LabelNumber className={item?.existencia==0?"hidden":"text-size-table text-right w-full"}>{item?.existencia||""}</LabelNumber>   
            ),
          },
          {
            header: "Amount",
            width:"w-28",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
               <Labelmoney twoDecimal={true} className={item?.total==0?"hidden":" text-right text-size-table w-full"}>{item?.total}</Labelmoney>
            ),
          },
        ]}
        footer={[
          { 
            width:"bg-gray-100",
            code: <label className="font-semibold">Total:</label>
          },
          {
            width:"bg-gray-100 ",
            // eslint-disable-next-line react/display-name
            code:  
            <LabelNumber className={"text-size-table text-right w-full"}>{inventario.reduce((a, b) => parseInt(a) + parseInt(b.qohb), 0)}</LabelNumber>  
          },
          {
            width:"bg-gray-100",
            // eslint-disable-next-line react/display-name
            code:
            <LabelNumber className={"text-size-table text-right w-full"}>{inventario.reduce((a, b) => parseInt(a) + parseInt(b.cantidadComprada), 0)}</LabelNumber>
          },
          {
            width:"bg-gray-100",
            // eslint-disable-next-line react/display-name
            code: 
            <LabelNumber className={"text-size-table text-right w-full"}>{inventario.reduce((a, b) => parseInt(a) + parseInt(b.cantidadVendida), 0)}</LabelNumber>
          },
          {
            width:"bg-gray-100",
            // eslint-disable-next-line react/display-name
            code:
            <LabelNumber className={"text-size-table text-right w-full"}>{inventario.reduce((a, b) => parseInt(a) + parseInt(b.existencia), 0)}</LabelNumber>
          },
          {
            width:"bg-gray-100",
            // eslint-disable-next-line react/display-name
            code:
            <Labelmoney twoDecimal={true} className={" text-size-table text-right w-full"}>{inventario.reduce((a, b) => parseInt(a) + parseInt(b.total), 0)}</Labelmoney>
         }
        ]}
        data={inventario}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  setPropsInventario:(data)=> dispatch({type:"SET_PROPSINVENTARIO", estado:data}),
  addRutaGlobal:(ruta)=> dispatch(setRuta(ruta)),
  resetPropsInventario: ()=> dispatch({type: "RESET_PROPSINVENTARIO"}),
  setCleanInventario: () => dispatch({type: 'RESET_INVENTARIO'}),

  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
  propsInventario: state.PropsInventario,
  categorias: state.Catalogos.CATEGORIAS.opciones,
});

export default connect (mapStateToProps,mapDispatchToProps)(ReporteLinea);
