import React, { useState, useEffect } from "react";
import { setsearchPersonal, setRuta } from "../../redux/Actions";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  apiUse,
  updating,
  validatePermission,
  disabledFieldIsNot,
  moneyToValue,
  havePermission,
  getAlcance,
} from "../../services/functions.js";
import Modaldanger from "../../components/modal/info";
import Modalsuccess from "../../components/modal/success";
import InputPrecioPanel from "../../components/InputPrecioPanel";
import TablaCreditoProveedor from "../../components/Proveedores/TablaCreditoProveedor";
import ListaMapa from "../../components/ListaMapa";
import { permisos } from "../../static/dat/datos.js";
import Toggle from "../../components/Toggle.jsx";
import LabelErrors from "../../components/LabelErrors.jsx";

const schema = yup.object().shape({
  nombre: yup.string().required("El nombre es obligatorio"),
  activo: yup.bool().required("El campo es obligatorio"),
  email: yup .string().email("email invalido"),
  direccion: yup.string(),
  telefono: yup.string().max(10,"el telefono debe ser a 10 digitos"),
  id: yup.string().required("El código es obligatorio"),
  fechaRegistro: yup.string(),
  limiteCredito:yup.number().required("Este campo es obligatorio"),
  creditoSucursales: yup.object()
});

const Formularioproveedor = (props) => {
  const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [showEstadoCuenta,setShowEstadoCuenta]= useState(false);
  const setsearchPersonal = props.setsearchPersonal;
  const validatepermission = validatePermission(props.datospersonal);
  const permisoEsp = havePermission(permisos.configurar);

useEffect(() => {
  if(props.datospersonal?.limiteCredito){ setValue('limiteCredito',moneyToValue(props.datospersonal.limiteCredito)); }
}, [props.datospersonal?.limiteCredito]);

useEffect(() => {
   if(props.datospersonal?.activo){ setValue('activo',props.datospersonal.activo)  }
 }, [props.datospersonal]);


  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  register({ name: "permisos" });
  register({ name: "historial" });
  register({name:"limiteCredito"});
  register({name:"creditoSucursales"});
  register({name:"activo"});

  const nameEndpoind = (data) => {
    if (updating(data)) {
      return "/update-proveedor";
    }

    return "/insert-proveedor";
  };

  const handleSuccessful = (data) => {
    const endpoind = nameEndpoind(props.datospersonal);
    setmodalSuccessConfig({
      isOpen: true,
      message:
        endpoind === "/insert-proveedor"
          ? "El registro se realizó con éxito"
          : "La actualización se realizó con éxito",
    });
    setsearchPersonal(data);
  };

  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  const sendData = (dataform) => {
    const endpoind = nameEndpoind(props.datospersonal);
    const newdata = { ...dataform };
    newdata.id = newdata.id.toUpperCase();
    

    apiUse({
      url: endpoind,
      method: "POST",
      data: newdata,
      handleSuccessful: handleSuccessful,
      handleError: handleError,
    });
  };

  function verifyPermission() {
    return disabledFieldIsNot([
      validatepermission.create(permisos.altaProveedor),
      validatepermission.update(permisos.modProveedor),
    ]);
  }

  return (
    <>
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2"
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalsuccess config={modalSuccessConfig}>
        <Modalsuccess.Message title={"Éxito"}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <form id="formproveedor" onSubmit={handleSubmit(sendData)}>
        <div className="flex flex-wrap items-top mb-6">
          <div className="w-full lg:w-6/12 px-4 ml-auto">
          <div className="relative w-full mb-3">
              <label htmlFor="toggle" className="textf pr-3 text-blue-700">
                Activo
              </label>
              <Toggle name="activo" checked={props.datospersonal?.activo} onClick={(e,isOn)=>{
                setValue("activo",isOn)
              }}/>
             <LabelErrors name="activo" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Código
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="id"
                  readOnly={verifyPermission()}
                  placeholder="Código"
                  ref={register}
                  onChange={e=>{e.target.value=e.target.value.toUpperCase();}}
                  defaultValue={props.datospersonal?.id}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="id" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Nombre
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="nombre"
                  readOnly={verifyPermission()}
                  placeholder="Nombre"
                  ref={register}
                  onChange={e=>{e.target.value=e.target.value.toUpperCase();}}
                  defaultValue={props.datospersonal?.nombre}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="nombre" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Dirección
              </label>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="direccion"
                  readOnly={verifyPermission()}
                  placeholder="Dirección"
                  ref={register}
                  onChange={e=>{e.target.value=e.target.value.toUpperCase();}}
                  defaultValue={props.datospersonal?.direccion}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="direccion" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Código Postal
              </label>
                <input
                  type="text"
                  className="border-form w-full"
                  name="cp"
                  readOnly={verifyPermission()}
                  placeholder="Código Postal"
                  ref={register}
                  defaultValue={props.datospersonal?.cp}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="cp" errors={errors}/>
            </div>

          </div>

          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Fecha de registro
              </label>
                <input
                  type="text"
                  className="border-form w-full"
                  name="fechaRegistro"
                  readOnly={true}
                  placeholder="Fecha de registro"
                  defaultValue={props.datospersonal?.fechaRegistro}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="fechaRegistro" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Teléfono
              </label>
                <input
                  type="text"
                  className="border-form w-full"
                  name="telefono"
                  readOnly={verifyPermission()}
                  placeholder="Teléfono"
                  defaultValue={props.datospersonal?.telefono}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="telefono" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Email
              </label>
                <input
                  type="text"
                  className="border-form w-full"
                  name="email"
                  readOnly={verifyPermission()}
                  placeholder="Email"
                  defaultValue={props.datospersonal?.email}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                />
              <LabelErrors name="email" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Limite Credito Global
              </label>
                <InputPrecioPanel
                  className="bg-white"
                  form={true}
                  name="limiteCredito"
                  min={0}
                  readOnly={(permisoEsp?false:true)}
                  newValor={props.datospersonal?.limiteCredito||0}
                  placeholder="limite de Credito"
                  disabled={verifyPermission()}
                  setValor={(valor) => { 
                    setValue('limiteCredito',moneyToValue(valor));
                  }}
                />
              <LabelErrors name="limiteCredito" errors={errors}/>
            </div>
            <div className={(permisoEsp?"relative w-full mb-3":"hidden")}>
              <label className="block textf" htmlFor="grid-password">
                Limite Credito Por Sucursal 
              </label>
                  <ListaMapa
                    savechange={(data) => {
                      setValue("creditoSucursales", data);
                    }}
                    readOnly={verifyPermission()}
                    data={props.datospersonal?.creditoSucursales}
                    lista={getAlcance()}
                    nameCampo="Sucursal"
                    money={true}
                    limite={props.datospersonal?.limiteCredito}
                  />
                <LabelErrors name="creditoSucursales" errors={errors}/>
            </div> 
          </div>
          <div className="flex flex-col  items-center w-full mb-3">
              <button
                className="btn-green w-full lg:w-1/2"
                type="button"
                style={{ transition: "all .15s ease" }}
                onClick={e=>{
                  setShowEstadoCuenta(!showEstadoCuenta)
                }}
              >
                Estado de cuenta
              </button>
              {showEstadoCuenta ? (
                <div className="w-full">
                  <TablaCreditoProveedor comprasCredito={props.comprasCredito} />
                </div>
              ) : null}
            </div>

            <div className="md:flex md:items-center mb-6">
                <button
                  className="btn-gray2 w-full lg:w-1/2"
                  type="button"
                  onClick={(e) => {
                    props.setRuta("menu");
                  }}
                  style={{ transition: "all .15s ease" }}
                >
                  Cancelar
                </button>

                <input
                  className="btn-blue w-full lg:w-1/2 cursor-pointer"
                  type="submit"
                  disabled={verifyPermission()}
                  value="Guardar"
                  style={{ transition: "all .15s ease" }}
                ></input>
            </div>
        </div>
      </form>
    </>
  );
};
const mapStateToProps = (state) => ({
  datospersonal: state.SearchPersonal,
});

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
  setsearchPersonal: (personal) => dispatch(setsearchPersonal(personal)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Formularioproveedor);
