import React, { useState} from "react";
import { connect } from "react-redux";
import Modalgeneric from "./modal/generic";
import Modalinfo from "./modal/info";
import { useGetClient } from "../Hooks/useApis.jsx";
import { getSucursal } from "../services/functions";
import TableModal from "./modal/TableModal";
import { useEffect , useRef } from "react";
import LoadingModal from "./modal/LoadingModal";

const InputClient = ({ addClient, Cliente,setInputFocus,inputFocus }) => {
  const [dataclient, setdataclient] = useState([]);
  const [modalTablaConfig, setmodalTablaConfig] = useState(false);
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({});
  const input = useRef();

  const setInput = setInputFocus || null;

  function validateclient(client) {
    if (client.activo === true) {
      addClient(client);
      setInput?setInputFocus(!inputFocus):null;
    } else {
      setmodalinfoConfig({
        isOpen: true,
        message: "cliente inhabilitado",
      });
    }
  }

  const openmodalLoading = (titulo) => {
    setmodalLoading({ isOpen: true, title:titulo });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  function closeModalinfo(e) { setmodalinfoConfig({ isOpen: false }); }
  function closeModaltabla(e) {setmodalTablaConfig({ isOpen: false }); }

  const handleSuccessful = (respuesta) => {
    input.current.value = "";
    let clientes = respuesta.filter(cliente=>(cliente.sucursal===getSucursal()))
    closemodalLoading();

    if (clientes.length === 0) {
      setmodalinfoConfig({
        isOpen: true,
        message: "No existe el cliente '" + input.current.value.toUpperCase() + "'",
      });
      addClient({});
    }

    if (clientes.length === 1) {
      validateclient(clientes[0]);
    }

    if (clientes.length > 1) {
      setdataclient(clientes);
      setmodalTablaConfig({ isOpen: true });
    }
  };
  const handleError = (error) => {
    closemodalLoading();
    setmodalinfoConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  
  useEffect(() => {
    if([true,false].includes(inputFocus)&&(Cliente.nombre === "Inexistente" || Cliente.nombre === undefined)){
      input.current.focus();
    }
  }, [inputFocus])

  return (
    <>
        <LoadingModal config={modalLoading} >
    <LoadingModal.Body>
      <LoadingModal.Title>{modalLoading?.title||"Cargado datos"}</LoadingModal.Title>
    </LoadingModal.Body>
    </LoadingModal>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message
          title={"No se ha encontrado la clave en la base de datos."}
        >
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modalgeneric config={modalTablaConfig}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Elija una opción</Modalgeneric.Title>
          <TableModal
            columns={[
              { header: "Clave", accessor: "id" },
              { header: "Nombre", accessor: "nombre" },
              { header: "RFC", accessor: "rfc" },
            ]}
            onclickRow={(item) => {
              validateclient(item);
              closeModaltabla();
            }}
            data={dataclient}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <div>
        <form
          className="form-horizontal form-label-left"
          onSubmit={(e) => {
            e.preventDefault();

            if (!input.current.value.trim()) { return; }
            openmodalLoading("Buscando Cliente");
            useGetClient(input.current.value.toUpperCase(),handleSuccessful,handleError)
          }}
        >
          <div className="size-form items-center">
            <input
              type="text"
              className=" border-form-free h-7 lg:h-9 lg:text-xl w-full md:w-72 focus:shadow-outline uppercase"
              name="cliente"
              autoComplete="off"
              placeholder="Cliente"
              disabled={Cliente?.cotizado === true || Cliente?.disabled === true}
              autoFocus={true}
              ref={input}
              onChange={(e) => {
                if(e.target.value==""){
                  addClient({});
                } 
              }}
              style={{ transition: "all .15s ease" }}
            />

            <button
              className="btn-gray2"
              type="submit"
              disabled={Cliente?.cotizado === true}
              style={{ transition: "all .15s ease" }}
            >
              Buscar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addClient: (cliente) =>
    dispatch({
      type: "ADD_CLIENT",
      item: cliente,
    }),
});

const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
});
export default connect(mapStateToProps, mapDispatchToProps)(InputClient);
