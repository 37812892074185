import React, { useEffect, useMemo, useRef, useState } from "react";
import Modalgeneric from "./modal/generic";
import Labelmoney from '../components/labelmoney'
import InputPrecioPanel from "./InputPrecioPanel";
import { moneyToValue } from "../services/functions";

const ListaMapa = (props) => {
    const saveChange = props.savechange || {},
    money= props.money || false,
    [data, setdata] = useState({}),
    [modalgenericonfig, setmodalgenericonfig] = useState({}),
    [registro, setregistro] = useState({}),
    [restante,setRestante] = useState(0),
    [editSucursal,setEditSucursal] = useState("");
    const disable=useRef(false);
  
    const openmodalgeneric = (e) => {
      setmodalgenericonfig({ isOpen: true });
    };
    const closemodalgeneric = (e) => {
      setmodalgenericonfig({ isOpen: false });
      setregistro({});
      setEditSucursal("");
      disable.current = false;
    };
  
    const closesavemodalgeneric = () => {
        if(data[editSucursal]!==registro[editSucursal]){
            const new_data ={...data};
            new_data[editSucursal] = registro[editSucursal];
            saveChange(new_data);
            setdata(new_data);
        }

      setregistro({});
      setEditSucursal("");
      disable.current = false;
      setmodalgenericonfig({ isOpen: false });
    };
  
    const createItem = (e) => {
      openmodalgeneric(e);
    };
  
    useEffect(() => {
      let res = 0;
      Object.keys(data).map(sucu=>{
        res += data[sucu];
      })
      res = props?.limite- res;
      setRestante(res)
    }, [props?.limite,data])
    
    useMemo(() => {
      saveChange(props.data || {});
      setdata(props.data || {});
    }, [props.data]);
  
    return (
      <>
        <Modalgeneric config={modalgenericonfig} h="h-30/100">
          <Modalgeneric.Body>
            <div className="flex flex-col items-center mt-2 w-full ">
              <label className="text-lg font-semibold">{props?.nameCampo}</label>
              <select
              className="border-form w-full md:w-36"
              value={editSucursal}
              disabled={disable.current}
              onChange={e=>{
                setEditSucursal(e.target.value)
              }}>
              {<option key={-1} defaultValue=""></option>}
              {Object.values(props.lista).sort().map((item,index)=>(
                  <option key={index} defaultValue={item}>{item}</option>
              ))}
              </select>
              <label className="text-lg font-semibold">Valor</label>
              {money?
                <InputPrecioPanel
                form={true}
                width="w-full md:w-36"
                max={restante}
                newValor={data[editSucursal]}
                setValor={(e)=>{ 
                    const new_val = {...registro};
                    new_val[editSucursal] = moneyToValue(e);
                    setregistro(new_val)
                }}
                />
                :<input
               type="text"
               className="border-form uppercase w-full md:w-36"
               onBlur={(e) => {
                if(e.target.value!=""){
                  const new_val = {...registro};
                  new_val[editSucursal] = e.target.value.toUpperCase();
                  setregistro(new_val)
                }
               }}
               defaultValue={data[editSucursal]||""}
               ></input>
              }
             
            </div>
          </Modalgeneric.Body>
          <Modalgeneric.Buttons>
            <button
              type="button"
              className="btn-blue"
              onClick={(e) => {
                closesavemodalgeneric();
              }}
            >
              Guardar
            </button>
            <button
              type="button"
              className="btn-gray2"
              onClick={closemodalgeneric}
            >
              Cerrar
            </button>
          </Modalgeneric.Buttons>
        </Modalgeneric>
  
        <div className="w-full flex flex-col items-center mx-auto ">
          <div className="w-full px-0 ">
            <div className="flex flex-col items-center relative border border-blue-900 ">
              <div className="w-full ">
                <div className="py-2 gap-2 flex flex-col bg-white rounded focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 transition-colors duration-200 ease-in-out mb-2 max-h-select overflow-y-auto" >
                  {Object.keys(data).map((item, index) => (
                    <div key={index} className="size-form w-full justify-center md:justify-start items-center font-medium">
                        <button type="button" className="btn-blue text-size w-28" 
                        onClick={(e) => {
                            disable.current = true;
                            setEditSucursal(item);
                            openmodalgeneric(0);
                        }}>
                          {item}
                        </button>
                        {money?<Labelmoney twoDecimal={true} className=" md:w-32 ml-1">{data[item]}</Labelmoney>
                        : <label className="font-medium w-32 text-gray-700 border-gray-300 bg-white borde px-2"> {data[item]} </label>
                        }
                    </div>
                  ))}
                </div>
                <div className="flex flex-col relative justify-center items-center pb-1">
                    <button
                      disabled={props.disabled}
                      type="button"
                      onClick={createItem}
                      className="btn-green"
                    >
                     {"Agregar " + props?.nameCampo}
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ListaMapa