
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { FechaLocalToUTC, FechaUTCToLocal, apiUsePromise, getSucursal, tipoCelda, valueToMoney2 } from '../../../services/functions';
import Modaldanger from '../../modal/danger';
import LoadingModal from '../../modal/LoadingModal';
import TableReactWindows from '../Cash/TableReactWindows';
import { setRutaRep } from '../../../redux/Actions';
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from '../../Excel/DataToXlsx';
import Modalsuccess from '../../modal/success';

const ReporteARDetail = ({PropsReportes,addDatos,Datos,setDatos,MajorAccounts,setHeader}) => {
  const [balanceFilter,setBalanceFilter]=useState([]),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const new_cliente= { balanceBefore:0, vendido:0,pagado:0,descuento:0,devolucion:0,balance:0,referencia:""};
  const fechaI = FechaLocalToUTC(PropsReportes.fecha_Inicial),fechaF=FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59);
  
  const nameComponent = "clienteBalanceDia",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const datosComponent = Datos[nameComponent]||{};
  const cliente = (datosComponent[1]?.cliente == "SYS"?"SYS#"+getSucursal():datosComponent[1].cliente);
  

  let peticiones =[
    {
      indexName:"asociadoIndex",
      hashKey:{valor:("VENTA#"+getSucursal())+"#"+cliente,nombre:"sujetoAsociado"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"id"},
    },
    {
      indexName:"contraCuentaIndex",
      hashKey:{valor:(cliente+"#"+getSucursal()),nombre:"contracuenta"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    {
      indexName:"asociadoIndex",
      hashKey:{valor:("PE#"+getSucursal()+"#"+cliente),nombre:"sujetoAsociado"},
      sortKey:{valor:[getSucursal()+'#'+fechaI, getSucursal()+'#'+fechaF],operador:"between",nombre:"id"},
    },
    {
      indexName:"asociadoIndex",
      hashKey:{valor:("DEVOLUCION#"+getSucursal()+cliente),nombre:"sujetoAsociado"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"id"},
    },
  ];


function getTotal(array,campo) {
  //console.log(array,' campo:',campo,' val:',array[0]?.[campo],'==>',array.reduce((a, b) => parseInt(a) + parseInt(b?.[campo]), 0))
  let new_array = [...array];
  new_array = new_array.reduce((a, b) => parseInt(a) + parseInt(b?.[campo]||0), 0)
  return new_array;
}

function SortVentas(vents) {
  const vt = vents.sort((a,b)=>{
    if (a.fecha > b.fecha) return 1;
    if (a.fecha < b.fecha) return -1;
    return 0;
  });
  
  return vt;
}

const getBalanceDay=(reporte,oldB)=>{
  const oldBalance = oldB?oldB:(datosComponent[1]?.balance  || {});
  const registro_Before = {...new_cliente,referencia:"Balance Before"};
  registro_Before.balance = oldBalance.balanceBefore;
  registro_Before.fecha = fechaI;
  registro_Before.tipo = "";

  SortVentas(reporte);
  reporte.unshift(registro_Before);
  reporte.map((reg,index)=>{
    if(index>0){
      reg.balance=(reporte[index-1]?.balance||0)+((reg?.vendido||0)+(reg?.devolucion||0)-(reg?.pagado||0)-(reg?.descuento||0))
    }
  })
 
}
    
  const handleError = (error) => {
        setmodaldangererrorConfig({
          isOpen: true,
          message: error?.response?.data?.message,
        });
  };
  
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }
  function closeModaldangererror(e) {
        const newdataconfig = { ...modaldangererrorConfig };
        newdataconfig.isOpen = false;
        setmodaldangererrorConfig(newdataconfig);
  }
  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false });};

  const getVentas=async(reporte,petLast)=>{
    if(petLast){ peticiones[0].LastEvaluatedKey = petLast;}
    const responseVentas= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[0]});
    const repVentas = responseVentas.data.datos;
    const ventas =  repVentas.filter(p=>(!['PENDIENTE#'+getSucursal()].includes(p.statusTemp)));

    ventas.forEach(venta=>{
      const new_venta={...new_cliente};
      new_venta.fecha = venta.id;
      new_venta.vendido = venta.subtotal;
      new_venta.descuento = venta.descuentoTotal;
      new_venta.referencia = venta.shortId;
      new_venta.type = 'VENTA';
      reporte.push(new_venta);
    })

    if(responseVentas.data?.LastEvaluatedKey){ await getVentas(reporte,responseVentas.data.LastEvaluatedKey); }
  }

  const getCash=async(reporte,petLast)=>{
    if(petLast){peticiones[1].LastEvaluatedKey = petLast; }
    const responseCash= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[1]});
    const cash = responseCash.data.datos;
    
    cash.forEach(pago=>{
      if(['AR','AROUT'].includes(pago.clave)){
        const new_venta={...new_cliente};
        new_venta.fecha = pago.type;
        new_venta.referencia = pago.descripcion;

        if(MajorAccounts[pago.clave]?.IO=="I"){
          new_venta.pagado = pago.importe;
          new_venta.type = 'PAGO';
        }else{
          new_venta.vendido = pago.importe *-1;
          new_venta.type = 'CARGO';
        }

        reporte.push(new_venta);
      }
    })

    if(responseCash.data?.LastEvaluatedKey){ await getCash(reporte,responseCash.data.LastEvaluatedKey);}
  }
  
  const getPes=async(reporte,petLast)=>{
    if(petLast){ peticiones[2].LastEvaluatedKey = petLast;}
    const responsePes= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[2]});
    const pes = responsePes.data.datos;
    const pedidos =  pes.filter(pes=>(!['CANCELADO','SOLICITADO'].includes(pes.status)));

    pedidos.forEach(pes=>{
      const new_pes={...new_cliente};
      new_pes.fecha = pes.id.split('#')[1];
      new_pes.vendido = pes.importe;
      new_pes.referencia = pes.shortId;
      new_pes.type = 'PES';
      reporte.push(new_pes);
    })

    if(responsePes.data?.LastEvaluatedKey){await getPes(reporte,responsePes.data.LastEvaluatedKey); }
  }

  useEffect(()=>{
    let reporte=[];
    if(rutaActualRep!==nameComponent){return;}

    const getBalanceCliente=async(oldBalance)=>{
      openmodalLoading();
     
      try{
          await getVentas(reporte);
          await getCash(reporte);
          await getPes(reporte);
          //await devolucion(reporte);
          if(oldBalance){
            getBalanceDay(reporte,oldBalance);
          }else{
            getBalanceDay(reporte);
          }

          setBalanceFilter(reporte);
          let new_Datos = [...datosComponent];
          new_Datos[0] = reporte;
          addDatos(nameComponent,new_Datos)
          closemodalLoading();
      }catch(Error){
          closemodalLoading();
          handleError(Error);
      }
    }
    if(!datosComponent[0]){
      getBalanceCliente();
    }else{
      if(PropsReportes.fecha_Inicial==datosComponent[1].fechai&&PropsReportes.fecha_Final==datosComponent[1].fechaf&&PropsReportes.header?.cliente==datosComponent[1]?.cliente){
        setBalanceFilter(datosComponent[0])
        return;
      }
      const reporte ={balanceBefore:0,pagado:0,descuento:0,vendido:0,balance:0};
      const reportes =datosComponent[1].allRep;
      reportes.forEach(rep=>{
        if(rep.fecha<PropsReportes.fecha_Inicial){
            reporte.balanceBefore += ((rep?.vendido||0)-(rep?.descuento||0)-(rep?.pagado||0));
        }
      })
      getBalanceCliente(reporte);
      let newDatos = [...datosComponent];
      newDatos[1].fechai = PropsReportes.fecha_Inicial;
      newDatos[1].fechaf =PropsReportes.fecha_Final;
      setDatos(nameComponent,newDatos)
    }
  },[PropsReportes.fecha_Inicial,PropsReportes.fecha_Final,PropsReportes.rutas]);

  useEffect(()=>{
    if(rutaActualRep!==nameComponent){return;}
    if(!Datos[nameComponent][0]){return}
     setHeader({text:"AR Detail "+Datos[nameComponent][1].cliente})
  },[PropsReportes.rutas])
    

  return (
  <>
    <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Productos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>


      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>


      <TableReactWindows
       btnDownload={async(e,datos)=>{
        if(!datos?.length){handleError("No hay datos que exportar"); return;}
        openmodalLoading();
        const workb = await GenerateXlsx();
        if(workb?.error){
          closemodalLoading();
          handleError(workb.error);
          return
        }
        console.log(datos)
        const data = datos||[],
        nameFile="BalanceAR-"+datosComponent[1].cliente,
        headerFile="Reporte balance cliente "+datosComponent[1].cliente+" del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final,
        sheet1 = workb.sheet(0),colPlus = 4;

        sheet1.cell("A1").value(headerFile);
        sheet1.cell("A1").style("bold",true);
        sheet1.cell("A1").style("fontSize",18);
    
        const sizeData = data.length + colPlus,
        header={
          'Fecha':12,
          'Descripcion':25,
          'Ventas':17,
          'Devolucion':17,
          'Pagos':17,
          'Descuento':17,
          'End Balance':17,
          'Tipo':15
        };

        Object.keys(header).map((key,index)=>{
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet1.column(columnasXlsx[index]).width(header[key]); 
        });


        data.forEach((ar,index) => {
          sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(FechaUTCToLocal(ar.fecha).fecha);
          sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(ar?.referencia||"");
          sheet1.cell(columnasXlsx[1]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(ar?.vendido||0));
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(valueToMoney2(ar?.devolucion||0));
          sheet1.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(ar?.pagado||0));
          sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(ar?.descuento||0));
          sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(ar?.balance||0));
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(ar.type);
        });

        sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[3]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[4]+sizeData).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[4]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[6]+sizeData).formula(""+columnasXlsx[2]+sizeData+"-"+columnasXlsx[3]+sizeData+"-"+columnasXlsx[4]+sizeData+"-"+columnasXlsx[5]+sizeData);
        sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);

        const response =await SaveXlsx(workb,nameFile);
        if(response?.error){
          closemodalLoading();
          handleError(response.error);
          return
        }
        closemodalLoading();
        openModalSuccess("Archivo generado correctamente","Descarga Completa");

        }}
        columns={[
          {
            key:"fecha",
            header:"Date",
            width:100, 
            type: tipoCelda.date,
            filter:true,
          },
          {
            key:"referencia",
            header: "Description",
            width:200,
            type: tipoCelda.text,
            filter:true,
          },
          {
            key:"vendido",
            header: "Sales",
            width:120,
            type: tipoCelda.money,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"devolucion",
            header: "Return",
            width:120,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"pagado",
            header: "Collection",
            width:120,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"descuento",
            header: "Discount",
            width:120,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"balance",
            header: "Balance",
            width:130,
            type: tipoCelda.money,
            footer: balanceFilter?.[balanceFilter.length-1]?.balance
          },
          {
            key:"type",
            header:"Tipo",
            width:100, 
            type: tipoCelda.text,
            filter:true,
          }
        ]}
        data={balanceFilter}
      />
      </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  setDatos:(nombre,datos)=>dispatch({type:"SET_DATOS",nombre:nombre,datos:datos})
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
  MajorAccounts: state.Catalogos.MAJORACCOUNTS,
});

export default connect (mapStateToProps,mapDispatchToProps)(ReporteARDetail)