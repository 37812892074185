import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { agregarProducto } from "../redux/Actions";
import { agregarItemVenta, getSucursal, ValidaSucursal } from "../services/functions.js";
import Modalgeneric from "./modal/generic";
import Modalinfo from "./modal/info";
import {useCatalogo, useCustomQuery, useGetProduct} from "../Hooks/useApis";
import TableModal from "../components/modal/TableModal";
import SearchSvg from "../static/img/SearchSvg";
import {motion} from 'framer-motion';
import LoadingModal from "./modal/LoadingModal";
import Modaldanger from "./modal/danger";
import Labelmoney from "./labelmoney";
import QrReader from "react-web-qr-reader";
import { btnScale, btnTap } from "../static/motionStyle.js";

const InputProduct = ({ addItem, Cliente,Ruta, inputFocus, setInputFocus, stateItem,setStateItem,itemsVenta,enableInput}) => {
  const bad = useRef(), itemNoExist = useRef(),contadoradd=useRef(0),itemServ=useRef({}),lastKeys=useRef({}),allItems=useRef([]),btnAllItems=useRef(true),input=useRef("");
  const [dataproduct, setdataproduct] = useState([]);
  const [modalTablaConfig, setmodalTablaConfig] = useState(false);
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalErrorProduct,setmodalErrorProduct] =useState({}),
  [modalModelo,setModalModelo]=useState({}),
  [modalservice, setmodalservice] = useState({}),
  [checkMicas,setCheckMicas]=useState({}),
  [checkArmazon,setCheckArmazon]=useState({}),
  [checkTipo,setCheckTipo]=useState({}),
  [checkZona,setCheckZona]=useState({}),
  [checkTinte,setCheckTinte]=useState({}),
  [checkAgregados,setCheckAgregados]=useState({}),
  [prodsRow,setProdsRow]=useState([]),
  [modalLoading, setmodalLoading] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [btnSelect,setBtnSelect]=useState(""),
  [catalogo,setCatalogo]=useState([]),
  [lineaServ,setLineaServ]=useState({}),
  [reset,setReset]=useState(false),
  [btnQr,setBtnQr]=useState(false),
  [modalQr, setmodalQr] = useState({}),
  [modelos,setModelos]=useState([]),
  [servicios,setServicios]=useState([]);

  const setInput = setInputFocus || null;
  const setStateI = setStateItem || null; 

  const openmodalservice = (e) => {setmodalservice({ isOpen: true });};
  const closemodalservice = (e) => {
    contadoradd.current =0;
    itemServ.current = {};
    setServicios([]);
    resetChecks(); 
    setBtnSelect(""); 
    setmodalservice({ isOpen: false });
    setInput?setInputFocus(!inputFocus):null;
  };
  const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, titulo:titulo });};
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false }); };
  const closeModalErrorProduct=()=>{
    setmodalErrorProduct({isOpen:false})
    setInput?setInputFocus(!inputFocus):null;
  };
  function closeModalinfo(e) { 
    bad.current=null; 
    itemNoExist.current=null; 
    setmodalinfoConfig({ isOpen: false }); 
    setInput?setInputFocus(!inputFocus):null;
  }
  function closeModaltabla(e) { 
    setmodalTablaConfig({ isOpen: false }); 
    setReset(!reset); 
    input.current.value=""; 
    setInput?setInputFocus(!inputFocus):null;
  }
  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }
  const openmodalQr = (e) => { setmodalQr({ isOpen: true });};
  const closemodalQr = (e) => { setmodalQr({ isOpen: false });};
  const closeModalModelo=(e)=> { 
    setModalModelo({ isOpen: false }); 
    setInput?setInputFocus(!inputFocus):null;
  }

  useEffect(() => {
    if(navigator.userAgent.match(/Android/i)|| navigator.userAgent.match(/webOS/i)|| navigator.userAgent.match(/iPhone/i)|| navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)|| navigator.userAgent.match(/Windows Phone/i)){
      setBtnQr(true);
    }
  }, [])

  const SearchProduct=(e)=>{
    let codigo = input.current.value.toUpperCase().trim();
    if (!codigo) {  return; }
    if(codigo.length<2){return}
    if(codigo.substring(0,2)=="SE"){openmodalservice();return}
    if(codigo.length<4){return;}
    codigo = codigo.replace("'","-");
    useGetProduct({producto:codigo,funcionExito:funcionExito,funcionError:funcionError});
  }
  const SearchModel=(e)=>{
    const modelo = input.current.value.toUpperCase().trim()
    if(modelo!=""){
      openmodalLoading();
      const peticion ={
        indexName:"medidaIndex",
        hashKey:{valor:modelo,nombre:"medida"},
        sortKey:{valor:"PRODUCT",nombre:"type",operador:"eq"}
      }
      useCustomQuery(peticion,handleSuccessfulModelo,handleErrorModelo);
    }
  }

  function validaProducto(error){
      setmodalinfoConfig({
        title: "Articulo no Valido",
        isOpen: true,
        message: (error.type=="existencia"
        ?"Sin Existencia. Desea venderlo de todas maneras? \n"
        :(error.type=="habilitar"
          ?"Producto desactivado/o sin entradas. Pedir al encargado revisar el producto: "
          :(error.type=="vendible"
            ?"Producto no disponible para venta: "
            :"El producto no existe en la Base de Datos :"
          )
        )
        )+error.item.id,
      });
  }

  const funcionErrorCatalog=(error)=>{
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: error,
    });
  }
  const funcionExitoLastEvaluate=(respuesta) => {
    const productos = respuesta.productos;
    if (productos.length === 0) {
      setmodalinfoConfig({
        isOpen: true,
        title: "No se ha encontrado la clave en la base de datos.",
        message: "No existe el producto '" + input.current.value.toUpperCase() + "'",
      });
    }
    else if(respuesta?.LastEvaluatedKey){
      let new_items = [...lastKeys.current.items];
      productos.forEach(item=>{
        new_items.push(item);
      })
      allItems.current=new_items;
      lastKeys.current={...lastKeys.current,items:new_items,idlast:respuesta.LastEvaluatedKey}
      setdataproduct(new_items.filter(prod => prod.sucursales?.[getSucursal()]?.existencia>0));
    }
    else{
      let new_items = [...lastKeys.current.items];
      productos.forEach(item=>{
        new_items.push(item);
      })
      allItems.current=new_items;
      lastKeys.current={};
      setdataproduct(new_items.filter(prod => prod.sucursales?.[getSucursal()]?.existencia>0));
    }
  };
  const funcionExito=(respuesta) => {
    const productos = respuesta.productos;
    allItems.current=[];
    lastKeys.current={};
    
    if (productos.length == 0) {
      setmodalErrorProduct({
        isOpen: true,
        title: "No se ha encontrado la clave en la base de datos.",
        message: "No existe el producto " + input.current?.value ,
      });
      if(input.current?.value){
        input.current.value="";
      }
      return ;
    }

    if (productos.length == 1) {
      const error = agregarItemVenta(productos[0],addItem,Cliente.descuento,itemsVenta);
      if(error){
        if(error.type=="existencia"){
          bad.current = error.type;
          itemNoExist.current = error.item;
          //useVentaPerdida(error.item.id,(error.item.cantidad-error.item.sucursales[getSucursal()].existencia),(resp)=>{console.log(resp,error.item.id)},(error)=>{});
          
        }
        validaProducto(error);
      }
      if(input.current?.value){
        input.current.value="";
      }
      return;
    }

    if (productos.length > 1) {
      if(respuesta?.LastEvaluatedKey){
        lastKeys.current = {items:productos,idlast:respuesta.LastEvaluatedKey};
      }
      allItems.current=productos;
      setdataproduct(productos.filter(prod => prod.sucursales?.[getSucursal()]?.existencia>0));
      setmodalTablaConfig({ isOpen: true });
    }
  };
  const handleSuccessfulModelo=(resp)=>{
    const productos = resp?.datos|| [];
    allItems.current=[];
    input.current.value = "";
    
    closemodalLoading();
    if (productos.length === 0) {
      setmodalErrorProduct({
        isOpen: true,
        title: "No se ha encontrado la clave en la base de datos.",
        message: "No existe el producto " + input.current?.value ,
      });
      if(input.current?.value){
        input.current.value="";
      }
      return ;
    }
    //if(Ruta=="venta"){
      if(productos.length==1){
        const error = agregarItemVenta(productos[0],addItem,Cliente.descuento,itemsVenta);
        if(error){
          if(error.type=="existencia"){
            bad.current = error.type;
            itemNoExist.current = error.item;
            //useVentaPerdida(error.item.id,(error.item.cantidad-error.item.sucursales[getSucursal()].existencia),(resp)=>{console.log(resp,error.item.id)},(error)=>{})
          }
          validaProducto(error);
        }
      }else if (productos.length>1) {
        allItems.current=productos;
        setModelos(productos.filter(prod => prod.sucursales?.[getSucursal()]?.existencia>0))
        setModalModelo({ isOpen: true });
      }
      //return;
    //}
  }
  const funcionExitoServAgregado=(respuesta)=>{
    const productos = respuesta.productos;
    if (productos.length === 0) {
      closemodalservice();
      setmodalinfoConfig({
        isOpen: true,
        title: "No se ha encontrado la clave en la base de datos.",
        message: "No existe el producto " + input.current.value.toUpperCase() + "",
      });
      return;
    }
    if(!ValidaSucursal(productos[0])){
      closemodalservice();
      setmodalinfoConfig({
        isOpen: true,
        title: "Servicio no Disponible",
        message: "Favor de pedirle al encargado habilitar este producto "+productos[0].id+"  "+productos[0].descripcion,
      });
      return;
    }
    const item = {...itemServ.current}
    let adds = (item?.descAdd?item.descAdd:[]);
    let descuento = Cliente.descuento;
    adds.push(productos[0].descripcion);
    item.sucursales[getSucursal()].precioVenta += productos[0].sucursales[getSucursal()].precioVenta;
    item.descAdd = adds;
    itemServ.current = item;
    contadoradd.current = contadoradd.current+1;

    if(contadoradd.current >=servicios.length){
      const item = {...itemServ.current};
      
      servicios.forEach(serv=>{
        let medio = serv.replace(item.id,"");
        //console.log(medio,"==",serv)
        if(medio=="EXME"){
          descuento = 50;
        }
      })
      item.descripcion += " "+ item.descAdd.sort().join(" ");
      delete item.descAdd;
      const error = agregarItemVenta(item,addItem,descuento,itemsVenta);
      if(error){
        validaProducto(error);
      }
      closemodalservice();
    }
  }
  const funcionExitoServ=(respuesta)=>{
    const productos = respuesta.productos;
    if (productos.length === 0) {
      setmodalinfoConfig({
        isOpen: true,
        title: "No se ha encontrado la clave en la base de datos.",
        message: "No existe el producto " + input.current.value.toUpperCase() ,
      });
      addItem({});
      return;
    }
    let agregados = [],require=false;

      Object.keys(checkAgregados).forEach(key=>{
        if(checkAgregados[key].select==true){
          agregados.push(checkAgregados[key].codigo);
        }
      })
      
      if(lineaServ?.schema?.mica){
        require=true;
        Object.keys(checkMicas).forEach(key=>{
          if(checkMicas[key].select==true){
            agregados.push(checkMicas[key].codigo);
          }
        })
      }
      if(lineaServ?.schema?.armazon){
        require=true;
        Object.keys(checkArmazon).forEach(key=>{
          if(checkArmazon[key].select==true){
            agregados.push(checkArmazon[key].codigo);
          }
        })
      }
      if(lineaServ?.schema?.tinte){
        require=true;
        Object.keys(checkTinte).forEach(key=>{
          if(checkTinte[key].select==true){
            agregados.push(checkTinte[key].codigo);
          }
        })
      }

      if(lineaServ?.schema?.tipo){
        require=true;
        Object.keys(checkTipo).forEach(key=>{
          if(checkTipo[key].select==true){
            agregados.push(checkTipo[key].codigo);
          }
        })
      }

      if(lineaServ?.schema?.zona){
        require=true;
        Object.keys(checkZona).forEach(key=>{
          if(checkZona[key].select==true){
            agregados.push(checkZona[key].codigo);
          }
        })
      }

      if(require){
        if(!agregados.length){
            setmodalinfoConfig({
              title: "Datos insuficientes",
              isOpen: true,
              message: "Al producto le falta alguna opcion",
            });
          closemodalservice();
          return;
        }
        const prod = productos[0];
        prod.agregados =[];
        itemServ.current = prod;
        setServicios(agregados);
      }else{
        const item = {...productos[0]}
        item.agregados =[];
        //item.sucursales[getSucursal()].precioVenta += productos[0].sucursales[getSucursal()].precioVenta;
        closemodalservice();
          const error = agregarItemVenta(item,addItem,Cliente.descuento,itemsVenta);
          if(error){
            validaProducto(error);
          }
      }
  }

  const funcionError = (error) => {
    input.current.value ="";
    closemodalservice();
    closemodalQr();
    setmodalErrorProduct({
      isOpen: true,
      title: "!No se ha encontrado la clave en la base de datos!",
      message: error || error?.response?.data?.message || "",
    });
  };
  const handleErrorModelo=(error)=>{
    closemodalLoading();
    closeModalModelo();
    setmodalErrorProduct({
      isOpen: true,
      title: "No se ha encontrado la clave en la base de datos.",
      message: error?.response?.data?.message || "",
    });
  }

  const resetChecks=()=>{
    setCheckAgregados({}); 
    setCheckArmazon({}); 
    setCheckMicas({}); 
    setCheckTinte({}); 
    setLineaServ({});
    setCheckTipo({});
  }

  const selectRowTable=(item,e)=>{
    if(e.detail==2||e.key=="Enter"){
      const error = agregarItemVenta(item,addItem,Cliente.descuento,itemsVenta);
      setmodalTablaConfig({ isOpen: false }); 
      setReset(!reset); 
      input.current.value=""; 
      if(error){
        if(error.type=="existencia"){
          bad.current = error.type;
          itemNoExist.current = error.item;
          //useVentaPerdida(error.item.id,(error.item.cantidad-error.item.sucursales[getSucursal()].existencia),(resp)=>{console.log(resp,error.item.id)},(error)=>{})
        }
        validaProducto(error);
      }else{
        closeModaltabla();
      }
      
    }
  }

  const selectRowTableModelo=(item,e)=>{
    if(e.detail==2||e.key=="Enter"){
      const error = agregarItemVenta(item,addItem,Cliente.descuento,itemsVenta);
      setModalModelo({ isOpen: false }); 
      setReset(!reset); 
      input.current.value=""; 
      if(error){
        if(error.type=="existencia"){
          bad.current = error.type;
          itemNoExist.current = error.item;
          //useVentaPerdida(error.item.id,(error.item.cantidad-error.item.sucursales[getSucursal()].existencia),(resp)=>{console.log(resp,error.item.id)},(error)=>{})
        }
        validaProducto(error);
      }else{
        closeModaltabla();
      }
      
    }
  }

  useEffect(()=>{
    if(Ruta!=="venta"){return;}
    openmodalLoading();
    useCatalogo("SERVICIOS",(resp)=>{
        if(!resp?.opciones){
          funcionErrorCatalog();
          return;
        }
        setCatalogo(resp.opciones);
        closemodalLoading();
      },funcionErrorCatalog);
  },[])


  useEffect(()=>{
    if(!servicios?.length&&contadoradd.current==0){return;}
    servicios.map(codigo=>{
      const item = {...itemServ.current};
      item.agregados = [...item.agregados,codigo];
      itemServ.current = item;
      useGetProduct({producto:codigo,funcionExito:funcionExitoServAgregado,funcionError:funcionError})
    })
  },[servicios])

  useEffect(() => {
    if([true,false].includes(inputFocus)){
      input.current.focus();
    }
  }, [inputFocus])
  

  return (
    <>
    <LoadingModal config={modalLoading}  >
        <LoadingModal.Body>
          <LoadingModal.Title>{modalLoading.titulo||"CARGANDO"}</LoadingModal.Title>
        </LoadingModal.Body>
    </LoadingModal>
    
    <Modaldanger config={modaldangererrorConfig} >
      <Modaldanger.Message title={"Error"}>
        {modaldangererrorConfig.message}
      </Modaldanger.Message>
      <Modaldanger.Buttons>
        <button
        type="button"
        className="btn-gray2"
        onClick={closeModaldangererror}
        >
          Cerrar 
        </button>
      </Modaldanger.Buttons>
    </Modaldanger>

    <Modaldanger config={modalErrorProduct} tab="600" closeModal={closeModalErrorProduct}>
        <Modaldanger.Message title={modalErrorProduct.title}>
          {modalErrorProduct.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            tabIndex={"600"}
            name="modaldanger"
            onClick={closeModalErrorProduct}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
    </Modaldanger>

    <Modalinfo config={modalinfoConfig} tab="200" closeModal={closeModalinfo}>
        <Modalinfo.Message title={modalinfoConfig.title}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button 
            type="button"
            className="btn-green"
            name="modalinfo"
            tabIndex={"200"}
            hidden={bad.current == "existencia"?false:true}
            onClick={e=>{
              const newitem = itemNoExist.current;
              newitem.existenciaCero = true;
             
              const error = agregarItemVenta(newitem,addItem,Cliente.descuento,itemsVenta);
              closeModalinfo();
              if(error){
                if(error.type=="existencia"){
                  bad.current = error.type;
                  itemNoExist.current = error.item;
                  //useVentaPerdida(error.item.id,(error.item.cantidad-error.item.sucursales[getSucursal()].existencia),(resp)=>{console.log(resp,error.item.id)},(error)=>{})
                }
                validaProducto(error);
              }
            }}
            >Vender
          </button>
          <button
            type="button"
            className="btn-blue"
            tabIndex={"200"}
            name="modalinfo"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>


      <Modalgeneric config={modalQr} >
      <Modalgeneric.Body >
       <div className={" w-full "}>
         {modalQr?.isOpen? <QrReader
          delay={500}
          style ={{display:"flex", margin:"20px 20px" }}
          onError={error=>{
            console.log('error:',error)
          }}
          onScan={resp=>{
            if(resp){
              closemodalQr();
              input.current.value = resp.data;
              useGetProduct({producto:resp.data,funcionExito:funcionExito,funcionError:funcionError});
            }
          }}
          />
          :null}
        </div>
      </Modalgeneric.Body>
      <Modalgeneric.Buttons>
          <button
            type="button"
            className="btn-gray2 mx-1"
            onClick={closemodalQr}
          >
            Cerrar
          </button>
      </Modalgeneric.Buttons>
    </Modalgeneric>

    <Modalgeneric w="size-w-90" config={modalModelo} closeModal={closeModalModelo}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Elija un producto</Modalgeneric.Title>
          <TableModal
          name="tableModelo"
          searchinput ={true}
          config={modalModelo}
          columns={[
              { header: "Clave", width:'w-2/12 text-left', accessor: "id" },
              { header: "Modelo", width:'w-1/12 text-center', accessor: "medida" },
              {
                header: "Existencia", width:'w-1/12',
                // eslint-disable-next-line react/display-name
                code: (item) => (<label className='font-size'>{item?.sucursales[getSucursal()]?.existencia||0}</label>),
              },
              { header: "Descripción", width:"w-6/12 text-xs text-left",  accessor: "descripcion" },
              { header: "Linea", width:'w-1/12',  accessor: "linea" },
              { header: "categoria", width:"w-1/12 text-center",  accessor: "categoria"}
            ]}
            onEnterProduct={(item,e)=>{
              selectRowTableModelo(item,e);
            }}
            onclickRow={(item,e) => {
              selectRowTableModelo(item,e);
            }}
            data={modelos}
          />
         {/*  <button className={(lastKeys?"btn-blue w-8":"hidden")} onClick={e=>{
            setProdsRow([prodsRow,dataproduct]);
            const codigo = input.value.toUpperCase().trim();
            useGetProduct({producto:codigo,funcionExito:funcionExito,funcionError:funcionError,lastKeys:lastKeys});
          }}>
            <i className="fas fa-angle-right pr-1"/>
          </button> */}
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModalModelo}
            tabIndex="200"
            className="btn-gray2"
          >
            Cancelar
          </button>
          <button className={"btn-red1"} title="productos sin existencia" tabIndex={"201"} onClick={e=>{
           (btnAllItems.current?setModelos(allItems.current):setModelos(allItems.current.filter(prod=>prod.sucursales?.[getSucursal()]?.existencia>0)))
            btnAllItems.current = !btnAllItems.current;
          }}>
            <i className="fas fa-ban text-bold"/>
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalgeneric w="size-w-90" config={modalTablaConfig}closeModal={closeModaltabla}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Elija un producto</Modalgeneric.Title>
          <TableModal
          name="tableClave"
          searchinput ={true}
          config={modalTablaConfig}
          onEnterProduct={(item,e)=>{
            selectRowTable(item,e);
          }}
            columns={[
              { header: "Clave", width:'w-2/12', accessor: "id" },
              { header: "Modelo", width:'w-1/12 text-center', accessor: "medida" },
              {
                header: "Existencia", width:'w-1/12',
                // eslint-disable-next-line react/display-name
                code: (item) => (<label className="font-size">{item?.sucursales[getSucursal()]?.existencia||0}</label>),
              },
              {
                header:"Precio",
                width:"w-1/12 text-center",
                // eslint-disable-next-line react/display-name
                code: (item) => (<div className='w-full grid grid-flow-col gap-1'>
                  <Labelmoney font="font-size" twoDecimal={true}>{item?.sucursales?.[getSucursal()]?.precioVenta||0}</Labelmoney>
                  <button className={(item?.sucursales?.[getSucursal()]?.vendible==false?'w-auto text-xs':'hidden')} title='bloqueado'><i className='fas fa-ban text-red-500'></i></button>
                </div>)
              },
              { header: "Descripción", width:'w-5/12',  
               // eslint-disable-next-line react/display-name
               code: (item) => (<label className="font-size">{item.descripcion+(item?.categoria=="LENTE DE CONTACTO"?" "+(item.caracteristicas||""):"")}</label>)
            },
              { header: "Marca", width:'w-1/12',  accessor: "marca" },
            ]}
            onclickRow={(item,e) => {
              selectRowTable(item,e);
            }}
            data={dataproduct}
          />
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            tabIndex="300"
            className="btn-gray2"
          >
            Cancelar
          </button>
          <button className={(lastKeys.current?.idlast?"btn-blue":"hidden")} tabIndex="302" title="cargar mas productos" onClick={e=>{
            setProdsRow([prodsRow,dataproduct]);
            const codigo = input.current.value.toUpperCase().trim();
            useGetProduct({producto:codigo,funcionExito:funcionExitoLastEvaluate,funcionError:funcionError,lastKeys:lastKeys.current.idlast});
          }}>
            <i className="fas fa-sort-down text-xl text-bold "/>
          </button>
          <button className={"btn-red1"} tabIndex="301" title="productos sin existencia" onClick={e=>{
           (btnAllItems.current?setdataproduct(allItems.current):setdataproduct(allItems.current.filter(prod=>prod.sucursales?.[getSucursal()]?.existencia>0)))
            btnAllItems.current = !btnAllItems.current;
          }}>
            <i className="fas fa-ban text-bold"/>
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>


      <Modalgeneric bg="bg-bluei" w="size-w-80" h="size-h-80" config={modalservice} tab="400" closeModal={closemodalservice} >
        <Modalgeneric.Body >
          <Modalgeneric.Title text="text-white">Servicios</Modalgeneric.Title>
          <div className="w-full flex flex-col items-center overflow-y-auto max-h-5/6 ">

            <div 
            className="grid gap-2 grid-flow-row grid-cols-1 md:grid-rows-1 md:grid-cols-5 w-full text-white">
                {catalogo.length?
                  catalogo.map((valor,index)=>(
                    <motion.button key={index}  
                    whileHover={btnScale}
                    whileTap={{scale: 0.8}}
                    onClick={e=>{
                      const linea= valor;
                      if(btnSelect==valor){return}
                      setBtnSelect(valor);
                      openmodalLoading();
                      useCustomQuery({
                        indexName:"lineaIndex",
                        hashKey:{valor:linea,nombre:"linea"},
                        sortKey:{valor:"LINEA",nombre:"type",operador:"eq"}
                      },(resp)=>{
                        if(!resp?.datos){
                          funcionErrorCatalog();
                          return;
                        }
                       
                        let adds={},micas={},armazones={},tintes={},tipos={},zonas={};
                        const schema = resp.datos[0].schema;
                        //console.log('schema=>',schema)
                        Object.keys(schema.agregados).map(add=>{
                          adds[add]={codigo:schema.agregados[add],select: false}
                        });
                        if(schema?.mica){
                          Object.keys(schema?.mica).map(mica=>{
                            micas[mica]={codigo:schema.mica[mica],select: false}
                          });
                        }
                        if(schema?.armazon){
                          Object.keys(schema?.armazon).map(armazon=>{
                            armazones[armazon]={codigo:schema.armazon[armazon], select: false}
                          });
                        }
                        if(schema?.tinte){
                          Object.keys(schema?.tinte).map(tinte=>{
                            tintes[tinte]={codigo:schema.tinte[tinte],select: false}
                          });
                        }
                        if(schema?.tipo){
                          Object.keys(schema?.tipo).map(tipo=>{
                            tipos[tipo]={codigo:schema.tipo[tipo],select: false}
                          });
                        }
                        if(schema?.zona){
                          Object.keys(schema?.zona).map(zona=>{
                            zonas[zona]={codigo:schema.zona[zona],select: false}
                          });
                        }

                        setCheckArmazon(armazones);
                        setCheckTinte(tintes);
                        setCheckMicas(micas);
                        setCheckTipo(tipos);
                        setCheckZona(zonas);
                        setCheckAgregados(adds);
                        setLineaServ(resp.datos[0]);
                        closemodalLoading();
                      },funcionErrorCatalog);
                    }} className={'rounded-full h-10 font-bold text-lg hover:text-black hover:bg-ambar-200 '+(btnSelect==valor?"text-white bg-ambar-400":"bg-white text-blue-800 ")}>{valor}</motion.button>
                  ))
                :[]}
            </div>

            <div className={"w-full justify-center bg-transparent pb-3 text-white mt-2 border border-rounded rounded-full border-gray-400 flex flex-col gap-1"}>
              <label className="w-full text-center font-bold" >AGREGADOS</label>
              <div className="size-form w-full justify-center gap-3">
                {lineaServ?.schema?.agregados?
                Object.keys(lineaServ.schema.agregados).map((key,index)=>(
                  <div key={index} className="flex flex-row items-center justify-center gap-1 text-white font-bold">
                  <input className=" " type="checkbox" checked={checkAgregados[key]?.select}
                  onChange={e=>{
                    const newchecks={...checkAgregados};
                    newchecks[key].select = !checkAgregados[key].select;
                    setCheckAgregados(newchecks);
                  }}/>
                  <label className="text-lg ">{key}</label>
                </div>
                ))
                :[]}
                
              </div>
            </div>

            <div className={" w-full justify-center bg-blue-800 py-3 text-white mt-2 border border-rounded rounded-md border-blue-700 "+(!lineaServ?.schema?.mica?"hidden":"size-3")}>
              {lineaServ?.schema?.mica?
                Object.keys(lineaServ.schema.mica).sort().map((key,index)=>(
                  <div key={index} className="flex flex-row items-center gap-2">
                    <input className="radio-yellow radio" type="radio" checked={checkMicas[key]?.select} name="material" value={key} 
                      onChange={e=>{
                        const newchecks={...checkMicas};
                        Object.keys(checkMicas).forEach(key=>{
                          newchecks[key].select = false; 
                        })
                        newchecks[key].select = !checkMicas[key].select;
                        setCheckMicas(newchecks);
                    }}/>
                    <label className="text-lg text-left font-semibold ">{key}</label>
                  </div>
                ))
              :[]}
            </div>

            <div className={" w-full justify-center bg-blue-900 py-3 text-white mt-2 border border-rounded rounded-md border-blue-800 "+(!lineaServ?.schema?.armazon?"hidden":"size-3")}>
              {lineaServ?.schema?.armazon?
                 Object.keys(lineaServ.schema.armazon).sort().map((key,index)=>(
                  <div key={index} className="flex flex-row items-center gap-2">
                    <input className="radio-yellow radio" type="radio" defaultChecked={false} name="armazon" value={key} 
                    onChange={e=>{
                      const newchecks={...checkArmazon};
                        Object.keys(checkArmazon).forEach(key=>{
                          newchecks[key].select = false; 
                        })
                        newchecks[key].select = !checkArmazon[key].select;
                        setCheckArmazon(newchecks);
                    }}/>
                    <label className="text-lg text-left font-semibold">{key}</label>
                  </div>
                ))
              :[]}
            </div>

            <div className={" w-full justify-center bg-blue-900 py-3 text-white mt-2 border border-rounded rounded-md border-blue-800 "+(!lineaServ?.schema?.tipo?"hidden":"size-3")}>
              {lineaServ?.schema?.tipo?
                 Object.keys(lineaServ.schema.tipo).sort().map((key,index)=>(
                  <div key={index} className="flex flex-row items-center gap-2">
                    <input className="radio-yellow radio" type="radio" defaultChecked={false} name="tipo" value={key} 
                    onChange={e=>{
                      const newchecks={...checkTipo};
                        Object.keys(checkTipo).forEach(key=>{
                          newchecks[key].select = false; 
                        })
                        newchecks[key].select = !checkTipo[key].select;
                        setCheckTipo(newchecks);
                    }}/>
                    <label className="text-lg text-left font-semibold">{key}</label>
                  </div>
                ))
              :[]}
            </div>

            <div className={" w-full justify-center bg-blue-900 py-3 text-white mt-2 border border-rounded rounded-md border-blue-800 "+(!lineaServ?.schema?.zona?"hidden":"size-3")}>
              {lineaServ?.schema?.zona?
                 Object.keys(lineaServ.schema.zona).sort().map((key,index)=>(
                  <div key={index} className="flex flex-row items-center gap-2">
                    <input className="radio-yellow radio" type="radio" defaultChecked={false} name="zona" value={key} 
                    onChange={e=>{
                      const newchecks={...checkZona};
                        Object.keys(checkZona).forEach(key=>{
                          newchecks[key].select = false; 
                        })
                        newchecks[key].select = !checkZona[key].select;
                        setCheckZona(newchecks);
                    }}/>
                    <label className="text-lg text-left font-semibold ">{key}</label>
                  </div>
                ))
              :[]}
            </div>

            <div className={" w-full justify-center bg-blue-900 py-3 text-white mt-2 border border-rounded rounded-md border-blue-800 "+(!lineaServ?.schema?.tinte?"hidden":"size-3")}>
              {lineaServ?.schema?.tinte?
                 Object.keys(lineaServ.schema.tinte).sort().map((key,index)=>(
                  <div key={index} className="flex flex-row items-center gap-2">
                    <input className="radio-yellow radio" type="radio" defaultChecked={false} name="tinte" value={key} 
                    onChange={e=>{
                      const newchecks={...checkTinte};
                        Object.keys(checkTinte).forEach(key=>{
                          newchecks[key].select = false; 
                        })
                        newchecks[key].select = !checkTinte[key].select;
                        setCheckTinte(newchecks);
                    }}/>
                    <label className="text-lg text-left font-semibold ">{key}</label>
                  </div>
                ))
              :[]}
            </div>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            className="btn-blue1"
            name="modalgeneric"
            tabIndex={"400"}
            onClick={(e) => {
              if(!lineaServ?.id){return}
              useGetProduct({producto:lineaServ.id,funcionExito:funcionExitoServ,funcionError:funcionError})
            }}
          >
            Guardar
          </button>
          <button
            type="button"
            className="btn-gray2"
            name="modalgeneric"
            tabIndex={"401"}
            onClick={closemodalservice}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <div className="size-form w-full items-center gap-3">
        <div className={"flex w-full md:w-80 justify-between items-center gap-1"}>
            <input
              type="text"
              className={"w-11/12 border-form-free h-7 lg:h-9 lg:text-xl first-line:uppercase focus:outline-none bg-white font-size"}
              name="articulo"
              placeholder="CODIGO O MODELO"
              autoComplete="off"
              style={{ transition: "all .15s ease" }}
              ref={input}
              disabled={enableInput?!enableInput:Cliente.nombre === "Inexistente" || Cliente.nombre === undefined}
              onKeyDown={e=>{
                if(e.key=="F5"){
                  e.preventDefault()
                  return
                }
                if(e.key=="Enter"){
                  SearchProduct(e);
                  return;
                }
                if(e.key=="ArrowDown"){
                  e.preventDefault();
                  SearchModel(e);
                  return;
                }
                if(e.key=="ArrowUp"&&setStateI){
                  setStateI(!stateItem);
                }
              }}
            />
            <motion.button
              className="w-4 h-4 hover:text-blue-400"
              type="submit"
              disabled={ Cliente.nombre === "Inexistente" || Cliente.nombre === undefined}
              style={{ transition: "all .15s ease" }}
              whileHover={{ scale: 1.2 }}
              whileTap={{
                scale: 0.8,
                rotate: 5,
                borderRadius: "100%"
              }}
              onClick={e=>{
                SearchProduct(e);
              }}>
              <SearchSvg width="w-full" height="h-full" text="text-blue-600"/>
            </motion.button>
        </div>
        <motion.button
        className={(enableInput?"hidden":(Cliente.nombre !== "Inexistente" && Cliente.nombre !== undefined?"btn-redondo bg-blue-600 text-size font-size":"hidden"))}
        type="submit"
        style={{ transition: "all .15s ease" }}
        whileHover={{ scale: 1.1 }}
        whileTap={{
          scale: 0.8,
          borderRadius: "100%"
        }}
        onClick={e=>{
          openmodalservice();
        }}>
            Servicios
        </motion.button>
        <button className={(btnQr&&(Cliente.nombre !== "Inexistente" && Cliente.nombre !== undefined)?"w-16 btn-green":"hidden")}
        onClick={e=>{ openmodalQr();}}>
          Qr
      </button>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(agregarProducto(item)),
});

const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
  utilMin: state.Catalogos.GLOBALCONFIGS.utilidadMinima,
  itemsVenta: state.itemsVenta
});

export default connect(mapStateToProps, mapDispatchToProps)(InputProduct);
