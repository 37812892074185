import React, { useEffect, useMemo, useRef, useState } from "react";
import Modalgeneric from "./modal/generic";
import Labelmoney from '../components/labelmoney'
import InputPrecioPanel from "./InputPrecioPanel";
import { moneyToValue, valueToMoney } from "../services/functions";

const ListaMap = (props) => {
    const saveChange = props.savechange || {},
    [data, setdata] = useState({}),
    [modalgenericonfig, setmodalgenericonfig] = useState({}),
    [registro, setregistro] = useState({}),
    [restante,setRestante] = useState(0),
    [editSucursal,setEditSucursal] = useState("");
    const disable=useRef(false);
  
    const openmodalgeneric = (e) => {
      setmodalgenericonfig({ isOpen: true });
    };
    const closemodalgeneric = (e) => {
      setmodalgenericonfig({ isOpen: false });
      setregistro({});
      setEditSucursal("");
      disable.current = false;
    };
  
    const closesavemodalgeneric = () => {
      
        if(data?.[editSucursal]?.limite!==registro?.[editSucursal]?.limite||data?.[editSucursal]?.porcentaje!==registro?.[editSucursal]?.porcentaje){
          const new_data ={...data};
          new_data[editSucursal] = registro[editSucursal];
            saveChange(new_data);
            setdata(new_data);
            //console.log('suc=>',new_data)
        }

      setregistro({});
      setEditSucursal("");
      disable.current = false;
      setmodalgenericonfig({ isOpen: false });
    };
  
    const createItem = (e) => {
      openmodalgeneric(e);
    };
  
    useEffect(() => {
      let res = 0;
      Object.keys(data).map(sucu=>{
        res += data[sucu];
      })
      res = props?.limite- res;
      setRestante(res)
    }, [props?.limite,data])
    
    useMemo(() => {
      saveChange(props.data || {});
      setdata(props.data || {});
    }, [props.data]);
  
    return (
      <>
        <Modalgeneric config={modalgenericonfig}>
          <Modalgeneric.Body>
            <div className="flex flex-col items-center justify-center mt-2 w-full">
              <label className="text-lg font-semibold">{props?.nameCampo}</label>
              <select
              className="border-form w-full lg:w-1/2"
              value={editSucursal}
              disabled={disable.current}
              onChange={e=>{
                setEditSucursal(e.target.value)
              }}>
              {<option key={-1} defaultValue=""></option>}
              {Object.values(props.lista).sort().map((item,index)=>(
                  <option key={index} defaultValue={item}>{item}</option>
              ))}
              </select>
              <label className="text-lg font-semibold">Limite</label>
                <InputPrecioPanel
                form={true}
                max={restante}
                newValor={data[editSucursal]?.limite}
                setValor={(e)=>{ 
                    const new_val = {...registro};
                    if(!new_val[editSucursal]){
                      new_val[editSucursal]={
                        limite : moneyToValue(e)
                      }
                    }else{
                      new_val[editSucursal].limite = moneyToValue(e);
                    }
                    setregistro(new_val)
                }}
                />
              <div className="w-full flex flex-col justify-center lg:w-1/2">
                  <label className="text-lg font-semibold  text-center">Porcentaje</label>
                  <InputPrecioPanel
                  form={true}
                  max={restante}
                  noShowSignal={true}
                  newValor={data[editSucursal]?.porcentaje}
                  setValor={(e)=>{ 
                    const new_val = {...registro};
                    if(!new_val[editSucursal]){
                      new_val[editSucursal]={
                        porcentaje : moneyToValue(e)
                      }
                    }else{
                      new_val[editSucursal].porcentaje = moneyToValue(e);
                    }
                    setregistro(new_val)
                  }}
                  />
              </div>
             
            </div>
          </Modalgeneric.Body>
          <Modalgeneric.Buttons>
            <button
              type="button"
              className="btn-blue"
              onClick={(e) => {
                closesavemodalgeneric();
              }}
            >
              Guardar
            </button>
            <button
              type="button"
              className="btn-gray2"
              onClick={closemodalgeneric}
            >
              Cerrar
            </button>
          </Modalgeneric.Buttons>
        </Modalgeneric>
  
        <div className="w-full flex flex-col items-center mx-auto ">
          <div className="w-full px-0 ">
            <div className="flex flex-col items-center relative border border-blue-900 ">
              <div className="w-full ">
                <div className="my-2 p-1 bg-white rounded focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1  transition-colors duration-200 ease-in-out mb-2 max-h-select overflow-y-auto" >
                  {Object.keys(data).map((item, index) => (
                    <div key={index} className="flex flex-auto ">
                      <div className="cursor-pointer flex justify-center items-center my-1 font-medium ">
                        <button type="button" className="btn-blue text-size max-w-40 lg:w-20" 
                        disabled={(props?.readOnly?props.readOnly:false)}
                        onClick={(e) => {
                            disable.current = true;
                            setEditSucursal(item);
                            openmodalgeneric(0);
                        }}>
                          {item}
                        </button>
                        <Labelmoney twoDecimal={true} className="border-form w-auto max-w-28 ml-1 ">{data[item].limite}</Labelmoney>
                        <label className="border-form max-w-28 ml-1 pt-1 ">{valueToMoney(data[item].porcentaje)+" %"}</label>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col relative justify-center items-center">
                  <div className="cursor-pointer flex justify-center items-center m-1 w-1/3 py-2 px-2  rounded font-bold text-white bg-green-600 border border-gray-700 ">
                    <button
                      disabled={props.disabled}
                      type="button"
                      onClick={createItem}
                      className="text-xs font-bold uppercase leading-none max-w-full flex-initial disabled:opacity-50"
                    >
                     {"Agregar " + props?.nameCampo}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ListaMap