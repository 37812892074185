import React, { useState, useEffect } from "react";
import Formulariopersonal from "./formularioPersonal";
import { setsearchPersonal } from "../../redux/Actions";
import { connect } from "react-redux";
import Modaldanger from "../../components/modal/info";
import Modalgeneric from "../../components/modal/generic";
import Container from "../../components/Container";
import { useCustomQuery, useGetPersonal } from "../../Hooks/useApis";
import { getSucursal, havePermission } from "../../services/functions";
import TableModal from "../../components/modal/TableModal";
import { permisos } from "../../static/dat/datos";

const Wrapperformpersonal = (props) => {
  document.body.style = "background: rgba(17, 24, 39,1);";
  const [showForm, setshowForm] = useState(true);
  const [modalTablaConfig, setmodalTablaConfig] = useState(false),
  [modalTablaPersonal, setmodalTablaPersonal] = useState(false);
  const [dataModalTabla, setdataModalTabla] = useState(false),
  [dataTablaPersonal,setDataPersonal]=useState([]);
  const [search, changesearch] = useState("");
  const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const [modaldangerConfig, setmodaldangerConfig] = useState({title:"Dato no Encontrado"});
  const setsearchPersonal = props.setsearchPersonal;

  function closeModaldanger(e) {
    const newdataconfig = { ...modaldangerConfig };
    newdataconfig.isOpen = false;
    setmodaldangerConfig(newdataconfig);
  }

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  function closeModaltabla(e) {
    setmodalTablaConfig({ isOpen: false });
  }
  function closeModaltablaPersonal(e) {
    setDataPersonal([])
    setmodalTablaPersonal({ isOpen: false });
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const setdatasearch = (e) => {
    changesearch(e.target.value.toUpperCase());
  };

  const handleSuccessful = (data) => {
    if (Array.isArray(data)) {
      if (data.length === 0) {
        setmodaldangerConfig({
          title: "Personal no Encontrado",
          isOpen: true,
          message:
            "No se econtro al personal: '" + search + "' en la sucursal: "+ getSucursal(),
        });
      }
      if (data.length === 1) {
        const personal = data[0];
        setsearchPersonal(personal);
        setshowForm(!showForm);
      }
      if (data.length > 1) {
        setdataModalTabla(data);
        setmodalTablaConfig({ isOpen: true });
      }
    }
  };

  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  const handleSubmit = (event) => {
    setsearchPersonal({});
    setshowForm(!showForm);
    useGetPersonal(search,handleSuccessful,handleError)
  };

  useEffect(() => {
    return () => {
      setsearchPersonal({});
    };
  }, [setsearchPersonal]);

  useEffect(()=>{
    if(!dataTablaPersonal.length){return}
    setmodalTablaPersonal({ isOpen: true });
  },[dataTablaPersonal])

  return (
    <>
      <Modaldanger config={modaldangerConfig}>
        <Modaldanger.Message
          title={modaldangerConfig.title}
        >
          {modaldangerConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModaldanger}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalgeneric config={modalTablaConfig}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Elija una opción</Modalgeneric.Title>
          <TableModal
            columns={[
              {
                header: "Nombre",
                accessor: "nombre",
                width: "w-1/3",
              },
              { header: "Puesto", accessor: "puesto", width: "w-1/3" },
              {
                header: "Fecha de ingreso",
                accessor: "fechaIngreso",
                width: "w-1/3",
              },
            ]}
            onclickRow={(item) => {
                setsearchPersonal(item);
                setmodalTablaConfig({ isOpen: false });
                setshowForm(!showForm);
            }}
            data={dataModalTabla}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalgeneric config={modalTablaPersonal}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>{"Personal de la Sucursal " + getSucursal()}</Modalgeneric.Title>
          <TableModal
            columns={[
              {
                header: "Codigo",
                accessor: "id",
                width: "w-1/6",
              },
              {
                header: "Nombre",
                accessor: "nombre",
                width: "w-1/3",
              },
              { header: "Puesto", accessor: "puesto", width: "w-1/3" },
              {
                header: "Fecha de ingreso",
                accessor: "fechaIngreso",
                width: "w-1/3",
              },
              {
                header:"Sucursal",
                accessor:"sucursal",
                width:"w-1/3"
              }
            ]}
            onclickRow={(item) => {
              if(havePermission(permisos.configurar)&&item.sucursal!==getSucursal()){return}
              const newItem = {...item};

              newItem.permisos = newItem.permisos.sort();

              setsearchPersonal(newItem);
              setmodalTablaPersonal({ isOpen: false });
            }}
            data={dataTablaPersonal}
          />
        </Modalgeneric.Body>

        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltablaPersonal}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
        </Modalgeneric>
      <Container lg="px-60">
        <div className="px-6">
          <div className="mb-10 py-2 border-b ">
            <div className="flex flex-row mb-0 ">
              <div>
                <h6 className=" text-gray-600 text-md lg:text-base font-bold">
                  Personal
                </h6>
              </div>
              <div className="mx-auto">
                  <button className="btn-green1" onClick={e=>{
                    const peticion={
                      indexName:"reverseIndex",
                      hashKey:{valor:"USER",nombre:"type"},
                    }
                    if(!havePermission(permisos.configurar)){
                      peticion.filters = [{valor:getSucursal(),operador:'eq',nombre:'sucursal'}]
                    }
                    useCustomQuery(peticion,(resp)=>{
                      if(!resp.datos?.length){
                        setmodaldangerConfig({
                          title: "Aun no hay Personal",
                          isOpen: true,
                          message:"De de alta algun personal para porder verlos aqui: ",
                        })
                        return
                      }
                      setDataPersonal(resp.datos);
                      
                    },handleError);
                  }}>
                    Ver Personal
                  </button>
              </div>

              <div className="ml-auto ">
                <div className="inline-block border-b-4 md:border-b-0 md:border-r-4 px-2">
                  <div className=" relative flex items-center mx-auto text-gray-600">
                    <input
                      className=" w-full uppercase border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
                      type="search"
                      name="buscar"
                      placeholder="Buscar"
                      onChange={setdatasearch}
                      onKeyDown={handleKeyDown}
                    />
                    <button
                      type="button"
                      className="absolute items-center right-0   mr-4"
                      onClick={handleSubmit}
                    >
                      <svg
                      className="text-gray-600 h-4 w-4 fill-current"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 56.966 56.966"
                      width="512px"
                      height="512px"
                      >
                        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="inline-block px-2">
                  <button
                    className="btn-blue w-full"
                    type="button"
                    onClick={(e) => {
                      setsearchPersonal({});
                      setshowForm(!showForm);
                    }}
                    style={{ transition: "all .15s ease" }}
                  >
                    Nuevo Personal
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4">
              {showForm ? (
                <Formulariopersonal key={0} />
              ) : (
                <Formulariopersonal key={1} />
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setsearchPersonal: (personal) => dispatch(setsearchPersonal(personal)),
});

export default connect(null, mapDispatchToProps)(Wrapperformpersonal);
