import React, { useState } from "react";

const TablaTicketFact = (props) => {
  const onclickRow = props.onclickRow || ((item) => {});
  const columns = props.columns || [{}];
  const footer = props.footer || [{}];
  const data = props.data || [];
  const [field, setfield] = useState("");
  const [desc, setdesc] = useState(false);
  const [input, setinput] = useState("");
  const searchinput =
    props.searchinput === undefined ? true : props.searchinput;

  function byfield(a, b) {
    if (desc) {
      if (a[field] > b[field]) return 1;
      if (a[field] < b[field]) return -1;
    } else {
      if (a[field] < b[field]) return 1;
      if (a[field] > b[field]) return -1;
    }

    return 0;
  }

  function searchByAnyField(item) {
    let newsearch = input.replace('+','\\+');
    newsearch =  input.replace(/\\/g,'\\\\').toUpperCase();

    for (const value of Object.values(item)) {
      if (typeof value === "string" || value instanceof String) {
        if (value.toUpperCase().search(newsearch) !== -1) {
          return true;
        }
      }
    }
  }

  return (

      <div className="overflow-x-auto overflow-y-auto h-full relative flex flex-col min-h-0 max-h-screen min-w-0  max-w-screen-2xl mb-1 break-words rounded bg-white border-0">
        <table>
          <thead className="sticky top-0 rounded-lg bg-blue-800 text-white text-sm font-bold align-middle cursor-pointer uppercase">
            <tr>
              {columns
                ? columns.map((item, index) => (
                    <th
                      key={index}
                      onClick={(e) => {
                        setdesc(!desc);
                        setfield(item.accessor);
                      }}
                      className={
                        "py-1 border-l-2 border-r-2"
                      }
                      data-priority={index}
                    >
                      {item.header}
                    </th>
                  ))
                : null}
            </tr>
          </thead>
          <tbody className="font-normal md:font-semibold">
            {data
              ? data
                  .filter((item) => searchByAnyField(item))
                  .sort(byfield)
                  .map((item, index1) => (
                    
                    <tr
                      key={index1}
                      className={"cursor-pointer rounded-lg hover:bg-indigo-200 h-7 lg:h-8 "+(index1 % 2 === 0 ? "bg-gray-100" : "bg-white") }
                      onClick={(e) => {
                        onclickRow(item);
                      }}
                    >
                      {props.columns.map((itemcolumn, index2) => (
                        <td key={index2} className={itemcolumn.width + " px-1 md:px-2 align-middle "} >
                          {itemcolumn.code
                            ? itemcolumn.code(item)
                            : <input className="bg-transparent w-full outline-none cursor-pointer" value={item[itemcolumn.accessor]} readOnly={true}/>}
                        </td>
                      ))}
                    </tr>
                  ))
              : null}
          </tbody>
          <tfoot className="sticky bottom-0 rounded-lg bg-blue-800 text-white">
          <tr>
              {footer
                ? footer.map((item, index) => (
                    <th
                      key={index}
                      className={item.width+" px-2"}
                      data-priority={index}
                    >
                      {item.code}
                    </th>
                  ))
                : null}
            </tr>
          </tfoot>
        </table>
      </div>

  );
};

export default TablaTicketFact;
