import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { apiUse } from "../services/functions.js";
import SearchSvg from "../static/img/SearchSvg.jsx";
import Modalgeneric from "./modal/generic";
import Modalinfo from "./modal/info";
import TableModal from "./modal/TableModal.jsx";
import LoadingModal from "./modal/LoadingModal.jsx";

const InputProveedor= ({ addProveedor,Proveedor,valor,habilitado,bg,className}) => {
    const [dataproveedor, setdataproveedor] = useState([]),
    [modalLoading, setmodalLoading] = useState({}),
    [modalTablaConfig, setmodalTablaConfig] = useState(false),
    [modalinfoConfig, setmodalinfoConfig] = useState({});

    const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
    const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };

    useEffect(() => {
      if(valor && !Proveedor?.id){
        input.value = valor;
        EnviarPeticion();
      } else if(!Proveedor?.id && input.value !== ""){
        input.value ="";
      } 
    }, [valor])

    useEffect(() => {
      if(!Proveedor?.id && input.value !== "" && valor){
        input.value = "";
      } 
    }, [Proveedor])

    function closeModalinfo(e) {
      setmodalinfoConfig({ isOpen: false });
    }
  
    function closeModaltabla(e) {
      setmodalTablaConfig({ isOpen: false });
    }
  
    let input;
    const handleSuccessful = (data) => {
      closemodalLoading();

      if (data.length === 0) {
        setmodalinfoConfig({
          isOpen: true,
          message: "No existe el proveedor '" + input.value.toUpperCase() + "'",
        });
        addProveedor({});
      }
  
      if (data.length === 1) {
        addProveedor(data[0]);
      }
  
      if (data.length > 1) {
        setdataproveedor(data);
        setmodalTablaConfig({ isOpen: true });
      }
    };

    const EnviarPeticion=(e)=>{
      if (!input.value.trim()) { return; }

      openmodalLoading();
      const peticion = { id: input.value.toUpperCase(), };

      apiUse({
        url: "/get-proveedor",
        method: "POST",
        data: peticion,
        handleSuccessful: handleSuccessful,
        handleError: handleError,
      });

    }
  
    const handleError = (error) => {
      closeModalinfo();
      setmodalinfoConfig({
        isOpen: true,
        message: error?.response?.data?.message,
      });
    };
  
    return (
      <>
        <LoadingModal config={modalLoading}>
          <LoadingModal.Body>
            <LoadingModal.Title>Descargando datos del proveedor</LoadingModal.Title>
          </LoadingModal.Body>
        </LoadingModal>

        <Modalinfo config={modalinfoConfig}>
          <Modalinfo.Message title={"Sin datos"}>
            {modalinfoConfig.message}
          </Modalinfo.Message>
          <Modalinfo.Buttons>
            <button
              type="button"
              className="btn-blue1"
              onClick={closeModalinfo}
            >
              Cerrar
            </button>
          </Modalinfo.Buttons>
        </Modalinfo>
  
        <Modalgeneric config={modalTablaConfig}>
          <Modalgeneric.Body>
            <Modalgeneric.Title>Elija una opción</Modalgeneric.Title>
            <TableModal
              columns={[
                { header: "Clave", accessor: "id" },
                { header: "Nombre", accessor: "nombre" },
                { header: "RFC", accessor: "rfc" },
              ]}
              onclickRow={(item) => {
                addProveedor(item);
                closeModaltabla();
              }}
              data={dataproveedor}
            />
          </Modalgeneric.Body>
  
          <Modalgeneric.Buttons>
            <button
              type="button"
              onClick={closeModaltabla}
              className="btn-gray2"
            >
              Cancelar
            </button>
          </Modalgeneric.Buttons>
          </Modalgeneric>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              EnviarPeticion(e);
            }}
          >
            <div className={"flex border-form "+className+" "+(bg?"bg-input-mesh":"bg-white")+(habilitado?" opacity-50":"")}>
              <input
                type="text"
                className={"w-full uppercase focus:outline-none bg-transparent"}
                name="proveedor"
                placeholder="Proveedor"
                ref={(node) => (input = node)}
                style={{ transition: "all .15s ease" }}
                readOnly={habilitado}
                onChange={e=>{
                  if(!e.target.value)addProveedor({})
                  if(Proveedor)[addProveedor({})]
                }}
              />
  
                <button
                    className="w-12 ml-2"
                    type="submit"
                    style={{ transition: "all .15s ease" }}
                >
                  <SearchSvg width="w-5" height="h-5" text="text-gray-600"/>
              </button>
            </div>
          </form>
      </>
    );
  };
  
  const mapStateToProps =(state)=>({
    Proveedor: state.SearchProveedor,
  })

  const mapDispatchToProps = (dispatch) => ({
    addProveedor: (proveedor) =>
      dispatch({
        type: "SET_SEARCHPROVEEDOR",
        proveedor: proveedor,
      }),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(InputProveedor)

