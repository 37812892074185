import React, { useState } from "react";
import { setCantidad, eliminarItem, setDescuento, setPrecioVenta, resetPrecioVenta } from "../../redux/Actions";
import { connect } from "react-redux";
import { getImporte, getSucursal, havePermission, moneyToValue, redondeo, valueToMoney2 } from "../../services/functions.js";
import Labelmoney from "../labelmoney";
import Table from "./Table";
import Modalinfo from "../modal/info";
import GetPermiso from "../GetPermiso";
import { useRef } from "react";
import { useEffect } from "react";
import { permisos } from "../../static/dat/datos.js";

const PanelTabla = ({ itemsVenta, setCantidad, eliminarItem, setItems,stateItem,setInputFocus,inputFocus,setDescuento,setPrecioVenta,resetPrecioVenta }) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({});
  const [bloqueardescuento, setbloqueardescuento] = useState(true);
  const [checkDescuentoGlobal, setcheckDescuentoGlobal] = useState(false);
  var total = redondeo(itemsVenta.reduce((total,item)=>parseFloat(total)+parseFloat(item.importe),0)),
  subtotal = redondeo(itemsVenta.reduce((total,item)=>total + parseFloat(item.precio*item.cantidad),0));
  const bad = useRef(), itemNoExist = useRef(),cantA=useRef(0),inputs =useRef({});

  const setInput = setInputFocus || null;
  const permisoSuper = havePermission(permisos.configurar);

  function getItemDescuento(item,descuento){
    const valor = parseFloat(descuento);
    const newDesc = !isNaN(valor) ? (valor<=item.descuentoMax)?valor:item.descuentoMax: 0;
    //console.log('promiseDesc=>',valor,' descMax=>',item.descuentoMax,'newDesc=>',newDesc)
      return ({...item,
        descuento:newDesc,
        importe: getImporte(item.precio,newDesc,item.cantidad)})
  }
  let global;
  const configGetPermiso = {
    ui: (
      <div className="mt-2 items-center flex gap-1">
        <input
          type="checkbox"
          className="check-box"
          checked={checkDescuentoGlobal}
          onChange={(e) => {
            setcheckDescuentoGlobal(!checkDescuentoGlobal);
          }}
        />
        <input
          type="number"
          step={1}
          className="border-form bg-white uppercase"
          name="descuentoglobal"
          placeholder="Descuento global"
          disabled={!checkDescuentoGlobal}
          onBlur={e=>{e.target.value = Math.trunc(e.target.value)}}
          autoFocus={true}
          ref={(node) => (global = node)}
          style={{ transition: "all .15s ease" }}
        />
      </div>
    ),
    permission: permisos.descuento,
    Successfunction: (data) => {
      
      let descuentoGlobal = global.value;
      global.value = "";
      if (checkDescuentoGlobal) {
        setcheckDescuentoGlobal(false);

        let productos = [...itemsVenta];
        productos = productos.map((producto) => (getItemDescuento(producto,descuentoGlobal)));
       
        setItems(productos);
      } else {
        setbloqueardescuento(false);
      }
    },
  };

  function closeModalinfo(e) {bad.current=null; itemNoExist.current=null; setmodalinfoConfig({ isOpen: false }); }

  function validAmount(cantidadActual, existencia,item) {
    const newcant =(item?.existenciaCero?
      cantidadActual:
      (cantidadActual>item?.sucursales[getSucursal().existencia]
        ?item.sucursales[getSucursal()].existencia
        :cantidadActual
      ))
    if (newcant < 0) {
      setmodalinfoConfig({
        isOpen: true,
        message: "El valor no puede ser menor a uno",
      });
      return 1;
    } else if (newcant > existencia && !item?.existenciaCero) {
      bad.current = "existencia";
      itemNoExist.current = {...item};
      cantA.current = cantidadActual;
      setmodalinfoConfig({
        isOpen: true,
        message: "No hay suficientes productos en el inventario, Desea venderlo de todas maneras?" ,
      });
      return existencia;
    }
    return newcant;
  }

  function changeexist(item, cantidadActual, ) {
    const existencia = parseInt(item?.sucursales[getSucursal()]?.existencia);
    let newcant;
    if(item.categoria=="SERVICIO"){
      newcant = cantidadActual;
    }else{
      newcant=validAmount(cantidadActual,existencia,item);
    }
    setCantidad(item, parseInt(newcant), redondeo(item.precio * (1 - item.descuento / 100) * parseInt(newcant)));
  }
  function getDesc(item,imp) {
    let importe = moneyToValue(imp);
    const precio = item.precio;
    let descuento = item.descuento;

    if(importe>=item.minPrecioVenta&&importe<=precio){
      descuento = Math.trunc(((item.precio - importe) / item.precio) * 100);
    }
    if(importe<item.minPrecioVenta){
      descuento = Math.trunc(((item.precio - item.minPrecioVenta) / item.precio) * 100);
    }else if(isNaN(imp)||!imp){
      console.log('nan')
      //inputs.current[item.id+"descuento"].value =valueToMoney2(item.precio);
      document.getElementById(item.id+"preciod").value =valueToMoney2(item.precio);
      //e.target.value = valueToMoney2(item.precio);
      setDescuento(item,descuento);
      return;
    }else if(importe>precio){
      descuento = 0;
      setPrecioVenta(item,importe);
      return;
    }
    
    const new_precio = getImporte(item.precio,descuento,1);
    
    //inputs.current[item.id+"descuento"].value = valueToMoney2(new_precio);
    document.getElementById(item.id+"preciod").value =valueToMoney2(new_precio);
    setDescuento(item,descuento);
  }

  useEffect(()=>{
    itemsVenta.forEach(item=>{
      if(item?.repeat){
        inputs.current[item.id+"cantidad"].focus();
        return;
      }
    })
  },[itemsVenta])

  useEffect(() => {
    if(itemsVenta.length<=0){return;}
    if([true,false].includes(stateItem)){
      //inputs.current[itemsVenta[itemsVenta.length-1].id+"cantidad"].focus();
      inputs.current[itemsVenta[itemsVenta.length-1].id+"cantidad"]?.select?inputs.current[itemsVenta[itemsVenta.length-1].id+"cantidad"].select():"";
    }
  }, [stateItem])
  

  return (
    <>       
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
          <button 
            type="button"
            className="btn-green mr-1"
            hidden={bad.current == "existencia"?false:true}
            onClick={e=>{
              const newitem = itemNoExist.current;
              newitem.existenciaCero = true;
              changeexist(newitem,cantA.current);
              closeModalinfo();
 
              /*const newitem = itemNoExist.current;
              newitem.existenciaCero = true;
             
              const error = agregarItemVenta(newitem,addItem,Cliente.descuento);
              closeModalinfo();
              if(error){
                if(error.type=="existencia"){
                  bad.current = error.type;
                  itemNoExist.current = error.item;
                  //useVentaPerdida(error.item.id,(error.item.cantidad-error.item.sucursales[getSucursal()].existencia),(resp)=>{console.log(resp,error.item.id)},(error)=>{})
                }
                validaProducto(error);
              }*/
            }}
            >Vender
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
      <Table
        searchinput={false}
        columns={[
          {
            header: "Cantidad",
            width: "w-20",
            // eslint-disable-next-line react/display-name
            code: (item) => (
              <div className={"flex flex-row w-full h-full items-center justify-between"}>
                <div className="text-red-600 ">
                  <button
                    onClick={(e) => {
                      eliminarItem(item);
                      setInput?setInput(!inputFocus):null;
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
                <input
                  type="number"
                  min="1"
                  className={"border-table w-full text-right focus:bg-ambar-100"}
                  value={item?.cantidad}
                  ref={node=>{inputs.current[item?.id+"cantidad"]=node}}
                  onWheel={e=>{
                    e.target.blur();
                  }}
                  onChange={(e) => {
                    /*if(e.target.value ==""){
                      e.target.value = 0;
                    }*/
                    /*if(!/^[0-9\u2190\u2191\u2192\u2193\r\n]+$/.test(e.target.value)){return}
                    console.log(e.target.value)*/
                    changeexist(item,e.target.value);
                  }}
                  onBlur={e=>{
                    if(e.target.value<=0||e.target.value=="")
                    {
                      inputs.current[item.id+"cantidad"].focus();
                    }
                  }}
                  onKeyDown={e=>{
                    if(e.key=="Delete"){
                      eliminarItem(item);
                      setInput?setInput(!inputFocus):null;
                    }
                    if(e.key=="Enter"){
                      setInput?setInput(!inputFocus):null;
                    }
                  }}
                />
                
              </div>
            ),
          },
          {
            header: "Código",
            width: "w-44 text-sm lg:text-base ",
            accessor: "id",
          },
          {
            width:"w-96 text-xs lg:text-sm ",
            header: "Descripción",
            accessor: "descripcion",
          },
          {
            header: "Precio",
            width: "w-24",
            // eslint-disable-next-line react/display-name
            code: (item) =>(
             <Labelmoney twoDecimal={true} className="w-full text-center border-table ">{item.precio}</Labelmoney>
            )
          },
          
          {
            // eslint-disable-next-line react/display-name
            header: (
              <>
                <label className="mr-1">Descuento</label>
                <GetPermiso {...configGetPermiso}>
                  <button type="button" className="text-ambar-200">
                    <i className="fas fa-edit"></i>
                  </button>
                </GetPermiso>
              </>
            ),
            width: "w-24",

            // eslint-disable-next-line react/display-name
            code: (item) => (
              <div className="flex w-24 ">
                <input
                  type="number"
                  min={0}
                  max={item?.descuentoMax}
                  step={1}
                  className="border-table w-10/12 text-right "
                  disabled={(item.flagPrecioVenta)}//bloqueardescuento
                  onChange={(e) => {
                    e.target.value = Math.trunc(e.target.value);
                    let descuento =  e.target.value;
                    
                    let newDesc = !isNaN(descuento)? (descuento<=item.descuentoMax)?e.target.value:item.descuentoMax: 0;
                    const precio = getImporte(item.precio,newDesc,1);
                    
                    document.getElementById(item.id+"preciod").value =valueToMoney2(precio);
                    setDescuento(item,newDesc);
                    

                   /* setItems(
                      productos.map((producto) =>{
                        if(producto.categoria=="SERVICIO"){
                          return producto.descripcion === item.descripcion
                          ? { ...producto,
                             descuento: newDesc*1,
                             importe: getImporte(producto.precio,newDesc,producto.cantidad)}
                          : producto
                        }else{
                          return producto.id === item.id
                          ? { ...producto,
                             descuento: newDesc*1,
                             importe: getImporte(producto.precio,newDesc,producto.cantidad)}
                          : producto
                        }
                      })
                    );*/
                  }}
                  value={item.descuento}
                ></input>
                <label className="font-bold w-2/12">%</label>
              </div>
            ),
          },
          {
            header: "P C/Desc",
            width: "w-32",
            // eslint-disable-next-line react/display-name
            code: (item) => //<Labelmoney twoDecimal={true} className="w-full text-center border-table ">{item.precio}</Labelmoney>,
            <div className="flex items-center justify-items-end justify-between w-full " 
            onKeyDown={e=>{
              if(e.key=="Escape"){
                resetPrecioVenta(item);
              document.getElementById(item.id+"preciod").value =valueToMoney2(item.precio);
              }
            }}>
              {item?.flagPrecioVenta?
                <button
                className="text-red-500 w-4"
                onClick={e=>{
                  resetPrecioVenta(item);
                  document.getElementById(item.id+"preciod").value =valueToMoney2(item.precio);
                }}
                >
                  <i className="fas fa-minus-circle"/>
                </button>
                :null
              }
              <label className="font-bold w-2 text-right">$</label>
              <input
                type="number"
                className="border-table w-28 text-right "
                disabled={false}//bloqueardescuento
                defaultValue={valueToMoney2(getImporte(item?.precio,item?.descuento,1))}
                ref={node=>{inputs.current[item?.id+"preciod"]=node}}
                id={item?.id+"preciod"}
                onBlur={(e) => {
                  //e.target.value = Math.trunc(e.target.value);
                  getDesc(item,e.target.value);
                }}
              />
          </div>
          },
          {
            header: "Importe",
            width: "w-28 text-right",
            // eslint-disable-next-line react/display-name
            code: (item) => <Labelmoney twoDecimal={true} className="text-right w-full text-size border-table">{item.importe}</Labelmoney>,
          },
        ]}
        footer={[
          {
            code: <Labelmoney twoDecimal={true} className="font-semibold md:w-full text-center w-full" noShowSignal={true}>{moneyToValue(itemsVenta.reduce((a, b) => parseInt(a) + (b?.id!==0?parseInt(b.cantidad):0), 0))}</Labelmoney>
          },
          {},
          {},
          {},
          {
            code:<label className="w-full">Totales:</label>
          },
          {
            code:<Labelmoney twoDecimal={true} className="font-semibold text-right w-full">{subtotal-total}</Labelmoney>
          },
          {
            code:  <Labelmoney twoDecimal={true} className="font-semibold text-right w-28 md:w-full">{total}</Labelmoney>
          }
        ]}
        data={itemsVenta}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCantidad: (item, cantidad, importe) => dispatch(setCantidad(item, cantidad, importe)),
  setDescuento: (item,descuento)=> dispatch(setDescuento(item,descuento)),
  setPrecioVenta:(item,precioVenta)=> dispatch(setPrecioVenta(item,precioVenta)),
  resetPrecioVenta:(item)=> dispatch(resetPrecioVenta(item)),
  eliminarItem: (item) => dispatch(eliminarItem(item)),
  setItems: (datos) =>  dispatch({  type: "SET_ITEMSDATA",  items: datos}),
});

const mapStateToProps = (state) => ({
  itemsVenta: state.itemsVenta,
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelTabla);
