import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useCustomQuery, useUpdateService } from '../../Hooks/useApis'
import { getSucursal, moneyToValue, Sucursal } from '../../services/functions'
import InputPrecioPanel from '../InputPrecioPanel'
import LabelCliente from '../LabelCliente'
import LabelFecha from '../LabelFecha'
import Modalgeneric from '../modal/generic'
import TableServicio from './TableServicio'
import TarjetaStatus from './TarjetaStatus'

const PanelServicio = () => {
    const [servicios,setServicios]=useState([]),
    [modalItem, setmodalItem] = useState({}),
    [servSelect,setServSelect]=useState([]);
    const timeServ= Sucursal('tiempoBisel'),tipo="BISEL";

    const GetServicios=()=>{
      let peticion ={
        hashKey:{valor:(tipo+"#"+getSucursal()),nombre:"id"},
        sortKey:{valor:"2022-01-01",operador:"gte",nombre:"type"}
      }
      useCustomQuery(peticion,handleSuccessful,handleError);
    }

    const funcionExitoUpdateServ=(data)=>{
      closemodalItem();
      GetServicios();
    }
    const handleSuccessful=(data)=>{
      let servicios = [];
      const datos= [...data.datos];
      datos.map(serv=>{
        let found = false;
        servicios.map(itemServ=>{
          if(itemServ?.ticket==serv.ticket){
            if(!itemServ?.items){
              itemServ.items =[{...itemServ,timeServ:(timeServ-((new Date()-new Date(serv.type+"Z"))/(1000*60)))},{...serv,timeServ:(timeServ-((new Date()-new Date(serv.type+"Z"))/(1000*60)))}];
              itemServ.cantidad =  itemServ.cantidad + 1;
              delete itemServ.timeServ;
              delete itemServ.linea;
              itemServ.status = "VARIOS";
            }else{
              itemServ.items.push({...serv,timeServ:(timeServ-((new Date()-new Date(serv.type+"Z"))/(1000*60)))});
              itemServ.cantidad =  itemServ.cantidad + 1;
            }
            found = true;
            return;
          }});
        if(!found){servicios.push({...serv,cantidad:1,timeServ:(timeServ-((new Date()-new Date(serv.type+"Z"))/(1000*60)))})}
        //servicios.push({...serv,timeServ:(timeServ-((new Date()-new Date(serv.type+"Z"))/(1000*60)))})
      })

      servicios.map(serv=>{
        if(serv?.items){
          let procs = 0,finish=0;
          serv.items.map(sub=>{
            procs = procs +(sub.status=="PROCESO"?1:0);
            finish = finish +(sub.status=="TERMINADO"?1:0);
          })
          if(procs>0){serv.cantProceso = procs;}
          if(finish>0){
            if(finish==serv.items.length){serv.status="TERMINADO"}
            serv.cantTerminado = finish;
          }
        }
      })
        setServicios(servicios)
      }
      const handleError = (error) => {
        console.log('Error',error);
      }

      const openmodalItem = (e) => {
        setmodalItem({ isOpen: true });
      };
      const closemodalItem = (e) => {
          setmodalItem({ isOpen: false });
      };

    useEffect(()=>{
     GetServicios();
    },[])

    return (
      <>
        <Modalgeneric config={modalItem}>
        <Modalgeneric.Body >
          <div className="size-form w-full justify-center">
            <TableServicio
            searchinput={false}
            columns={[
                {
                  header: "Ticket",
                  width:"w-1/12",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (
                    <div className='w-full flex text-center'>
                      <label className='w-full text-center font-semibold' >{item?.ticket||""}</label>
                    </div>
                  )
                },
                {
                  header: "Cliente",
                  width:"w-1/12",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (<div className='w-full flex text-center'>
                      <LabelCliente >{item?.cliente||""}</LabelCliente>
                    </div>)
                },
                {
                  header: "Fecha De Emision",
                  width:"w-2/12",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (<div className='w-full flex text-center'>
                      <LabelFecha >{item?.type||""}</LabelFecha>
                    </div>),
                },
                {
                  header: "Tipo",
                  width:"w-1/12",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (<div className='w-full flex text-center' onClick={e=>{console.log(item)
                  }}>
                      <label className={'w-full text-center font-bold '+(item?.timeServ<0?"text-red-500":(item?.timeServ>10?"text-green-500":"text-yellow-500")) }>{item?.linea||""}</label>
                    </div>)
                },
                {
                  header: "Status",
                  width:"w-2/12",
                  // eslint-disable-next-line react/display-name
                  code: (item) =>
                  <div className={"flex flex-wrap items-center justify-center"}>
                    <TarjetaStatus
                    bg={(item?.status=="SOLICITADO"?" bg-ambar-500 border border-ambar-400 hover:bg-ambar-400":(item?.status=="PROCESO"?"bg-cyan-500 border border-indigo-300 hover:bg-blue-500":(item?.status=="VARIOS"?"bg-purple-500 border border-purple-300 hover:bg-purple-500":"bg-green-600 border border-green-300 hover:bg-green-500")))}
                    valor={item?.status}
                    onClick={(e,valor)=>{
                      if(e.detail!==2){return;}
                      if(valor=="SOLICITADO"){ useUpdateService([{id:item.type,status:"PROCESO",tipo:tipo}],funcionExitoUpdateServ,handleError)}
                      if(valor=="PROCESO"){ useUpdateService([{id:item.type,status:"TERMINADO",tipo:tipo}],funcionExitoUpdateServ,handleError)}
                    }}
                    />
                  </div>
                },
            ]}
            data={servSelect}
            />
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons >
          <button
            type="button"
            className="btn-gray2 mx-1"
            onClick={closemodalItem}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
        </Modalgeneric>
        <TableServicio
        searchinput={false}
        columns={[
            {
              header: "Ticket",
              width:"w-1/12",
               // eslint-disable-next-line react/display-name
               code: (item) => (
                <div className='w-full flex text-center'>
                  <label className='w-full text-center font-semibold' >{item?.ticket||""}</label>
                </div>
               )
            },
            {
              header: "Cliente",
              width:"w-1/12",
               // eslint-disable-next-line react/display-name
               code: (item) => (<div className='w-full flex text-center'>
                  <LabelCliente >{item?.cliente||""}</LabelCliente>
                </div>)
            },
            {
              header: "Fecha De Emision",
              width:"w-2/12",
              // eslint-disable-next-line react/display-name
              code: (item) => (<div className='w-full flex text-center'>
                  <LabelFecha >{item?.type||""}</LabelFecha>
                </div>),
            },
            {
              header: "# Serv",
              width:"w-1/12",
               // eslint-disable-next-line react/display-name
               code: (item) => (
                  <InputPrecioPanel
                    width="w-full"
                    noShowSignal={true}
                    newValor={moneyToValue(item?.cantidad)}
                    disabled={true}
                    setValor={(e)=>{ }}/>
               )
            },
            {
              header: "Proceso",
              width:"w-1/12",
               // eslint-disable-next-line react/display-name
               code: (item) => (
                  <InputPrecioPanel
                    width="w-12"
                    noShowSignal={true}
                    hidden={(item?.cantProceso?false:true)}
                    newValor={moneyToValue(item?.cantProceso)}
                    disabled={true}
                    setValor={(e)=>{ }}/>
               )
            },
            {
              header: "Terminado",
              width:"w-1/12",
               // eslint-disable-next-line react/display-name
               code: (item) => (
                  <InputPrecioPanel
                    width="w-12"
                    noShowSignal={true}
                    hidden={(item?.cantTerminado?false:true)}
                    newValor={moneyToValue(item?.cantTerminado)}
                    disabled={true}
                    setValor={(e)=>{ }}/>
               )
            },
            {
              header: "Tipo",
              width:"w-1/12",
               // eslint-disable-next-line react/display-name
               code: (item) => (<div className='w-full flex text-center'>
                  <label className={'w-full text-center font-bold '+(item?.timeServ<0?"text-red-500":(item?.timeServ>10?"text-green-500":"text-yellow-500")) }>{item?.linea||""}</label>
                </div>)
            },
            {
              header: "Status",
              width:"w-3/12",
               // eslint-disable-next-line react/display-name
              code: (item) =>
              <div className={"flex flex-wrap items-center justify-center"}>
                <div className='w-9/12'>
                  <TarjetaStatus
                  bg={(item?.status=="SOLICITADO"?" bg-ambar-500 border border-ambar-400 hover:bg-ambar-400":(item?.status=="PROCESO"?"bg-cyan-500 border border-indigo-300 hover:bg-blue-500":(item?.status=="VARIOS"?"bg-purple-500 border border-purple-300 hover:bg-purple-500":"bg-green-600 border border-green-300 hover:bg-green-500")))}
                  valor={item?.status}
                  onClick={(e,valor)=>{
                    if(e.detail!==2){return;}
                    if(valor=="VARIOS"){
                      setServSelect(item.items);
                      openmodalItem();
                    }
                    if(valor=="SOLICITADO"){ useUpdateService([{id:item.type,status:"PROCESO",tipo:tipo}],funcionExitoUpdateServ,handleError)}
                    if(valor=="PROCESO"){ useUpdateService([{id:item.type,status:"TERMINADO",tipo:tipo}],funcionExitoUpdateServ,handleError)}
                    if(valor=="TERMINADO"){ 
                      if(item?.items?.length){
                        setServSelect(item.items);
                        openmodalItem();
                        return;
                      }
                      useUpdateService([{id:item.type,status:"ENTREGADO",tipo:tipo}],funcionExitoUpdateServ,handleError)
                    }
                  }}
                  />
                </div>
                <button className='w-7 h-7 lg:w-8 lg:h-8 rounded-full bg-green-700 p-1 md:ml-1'>
                  <i className="text-white ease-in duration-300 transform hover:scale-125  fas fa-people-carry"></i>
                </button>
              </div>
            },
        ]}
        data={servicios}
        />
      </>
    )
}

const mapDispatchToProps = (dispatch) => ({
  });
  
  
  const mapStateToProps = (state) => ({
    Cliente: state.Clientes,
  });

export default connect(mapStateToProps, mapDispatchToProps)(PanelServicio)