import React from "react";
import { connect } from "react-redux";
import Labelmoney from "../components/labelmoney";
import { FechaUTCToLocal } from "../services/functions";
import LabelFecha from "./LabelFecha";
import TableModal from "./modal/TableModal";

const Tablacredito = ({ ventasCredito, onclickRow = (item,e) => {} }) => {
  return (
      <TableModal
        columns={[
          {
            header: "# VENTA",
            width:"w-20 text-center",
            accessor: "shortId"
            // eslint-disable-next-line react/display-name
            //code: (item) => <label>{generate_id(item.id)}</label>,
          },
          {
            header: "Fecha",
            width:"w-36 text-center",
            // eslint-disable-next-line react/display-name
            code: (item) => <LabelFecha>{item.id}</LabelFecha>
            //accessor: "id",
          },
          {
            header: "Vencimiento",
            width:"w-32 text-center",
            // eslint-disable-next-line react/display-name
            code: (item) => <label>{FechaUTCToLocal(item.vencimiento).fecha}</label>
            //accessor: "vencimiento",
          },
          {
            header: "Importe",
            width:"w-24 text-center",
            // eslint-disable-next-line react/display-name
            code: (item) => <Labelmoney twoDecimal={true}>{item.total}</Labelmoney>,
          },
          {
            header: "Por cobrar",
            width:"w-36 text-end",
            // eslint-disable-next-line react/display-name
            code: (item) => <Labelmoney twoDecimal={true}>{item.total - item.pagado}</Labelmoney>
          },
        ]}
        onclickRow={(item,e) => {
          onclickRow(item,e);
        }}
        data={ventasCredito}
      />
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  ventasCredito: state.ventasCredito,
});

export default connect(mapStateToProps, mapDispatchToProps)(Tablacredito);
