import React from 'react';


const HeaderPedidoEspecial=()=> {
    return (
    <div className="size-form w-full justify-center">
    </div>
    )
}

export default HeaderPedidoEspecial