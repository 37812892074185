
let nextId = 0;

export const agregarProducto = (item) => {
  return {
    type: "ADD_ITEM",
    item: item,
  };
};

export const resetProducto = () => {
  return {
    type: "RESET_ITEM_VENTA",
    item: [],
  };
};

export const setCantidad = (product, cantidad, importe) => {
  return {
    type: "SET_CANTIDAD",
    product: product,
    cantidad: cantidad,
    importe: importe,
  };
};
export const setDescuento = (product, descuento) => {
  return {
    type: "SET_DESCUENTO",
    product: product,
    descuento: descuento
  };
};

export const setPrecioVenta = (product, precioVenta) => {
  return {
    type: "SET_PRECIOVENTA",
    product: product,
    precioVenta: precioVenta
  };
};3

export const resetPrecioVenta = (product) => {
  return {
    type: "RESET_PRECIOVENTA",
    product: product,
  };
};


export const eliminarItem = (item) => {
  return {
    type: "ELIMINAR_ITEM",
    item: item,
  };
};
export const setRuta = (ruta) => {
  return {
    type: "ADD_RUTA",
    ruta: ruta,
  };
};


export const returnRutaRep = () => {
  return {type: "DEL_RUTA_REP"};
};
export const setRutaRep = (ruta) => {
  return {
    type: "ADD_RUTA_REP",
    ruta: ruta,
  };
};


export const returnRuta = () => {
  return {type: "RETURN_RUTA"};
};


export const setLogueo = (datos) => {
  datos.windowSize = window.innerWidth;
  return {
    type: "SET_LOGUEO",
    datos: datos,
  };
};

export const addParametros = (categoria) => {
  return {
    type: "ADD_PARAMETROS",
    categoria: categoria,
  };
};

export const setsearchPersonal = (personal) => {
  return {
    type: "SET_SEARCHPERSONAL",
    personal: personal,
  };
};


export const agregarProductoCompra = (item) => {
  return {
    type: "ADD_ITEMCOMPRA",
    item: item,
  };
};


export const eliminarItemCompra = (index) => {
  return {
    type: "ELIMINAR_ITEMCOMPRA",
    index: index,
  };
};


export const agregarItemInventario = (item) => {
  return {
    type: "ADD_INVENTARIO",
    id: nextId++,
    item: item,
  };
};

export const eliminarItemInventario= (id) => {
  return {
    type: "ELIMINAR_INVENTARIO",
    id: id,
  };
};

export const agregarInventarioDetCompra = (id,inventario) => {
  return {
    type: "SET_INVENTARIOCOMPRA",
    id: id,
    inventario: inventario,
  };
};


export const setItemEsp = (item) => {
  return {
    type: "ADD_ITEM_ESP",
    id: nextId++,
    item: item,   
  };
};

export const resetProductoEsp = () => {
  nextId = 0;
  return {
    type: "RESET_ITEM_ESP",
    id: 0,
    item: [],
  };
};


export const setCantidadEsp = (id, cantidad) => {
  return {
    type: "SET_CANTIDAD_ESP",
    id: id,
    cantidad: cantidad
  };
};

export const eliminarItemEsp = (id) => {
  return {
    type: "ELIMINAR_ITEM_ESP",
    id: id,
  };
};

export const resetItemsEsp = () => {
  nextId = 0;
  return {
    type: "RESET_ITEM_ESP",
    id: 0,
    item: [],
  };
};

export const setItemCash = (item) => {
  return {
    type: "ADD_ITEM_CASH",
    id: nextId++,
    item: item,   
  };
};

export const setItemCompra = (item) => {
  return {
    type: "SET_ITEM_COMPRA",
    id: item.id,
    item: item
  };
};

export const setItemMov = (item) => {
  return {
    type: "SET_ITEM_CASH",
    id: item.id,
    item: item
  };
};

export const resetItemsCash = () => {
  nextId = 0;
  return {
    type: "RESET_ITEMS_CASH",
    id: 0,
    item: {},
  };
};
