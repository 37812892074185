import React from "react";

const Container=(props)=>{
    return (
    <section tabIndex={"4"} className={"my-auto "+(props?.h?props.h:"h-full overflow-y-auto ")} onKeyDown={props?.onKeyDown} >
      <div className={" lg:"+(props?.lg?props.lg:"px-12")+" h-full "}>
          <div className={"relative flex flex-col px-1 w-full shadow-xl h-full overflow-x-auto "+(props?.bg?props.bg:"bg-white")+(props?.div3?props.div3:"")}>
            {props.children}
          </div>
      </div>
    </section> 
    )
}

export default Container;