import React,{useEffect} from "react";
import PropTypes from "prop-types";

const InputGraduacion = ({nombre,getInputGraduacioni,getInputGraduacionf,getcheckgradi,getcheckgradf,getsalto,signo_igual,disabled,bg,defaultCheckGI,defaultCheckGF,defaultGI,defaultGF,disabledlInput}) => {
  let input_salto,rdi,rdf,rdi1,rdf1;
  const max= 50;

  useEffect(()=>{
    if(signo_igual){
      rdi.disabled = true;
      rdf.disabled = true;
    }
  },[signo_igual])

  return <div className={"flex flex-wrap mt-2 border-2 rounded-md px-1 "+(bg||"bg-gray-100")}
        onChange={e => {
      if (input_salto.value === "0.25") {
        getsalto(0.25);
      }
      }}
    >
      <div className="flex flex-wrap w-full">
        <label className="textf mt-2 md:text-sm w-1/3">{nombre} Inicial: </label>
        <span className="md:ml-1">
          <label className="font-bold text-xl md:text-2xl mx-1">-</label>
            <input
            type="radio"
            value="-"
            disabled={disabled?true:false}
            ref={node=>(rdi1=node)}
            checked={defaultCheckGI=="-"?true:false}
            className="radio-blue radio"
            onChange={e=>{
              if(e.target.checked){
                if(signo_igual){
                  rdi.checked = true; rdf.checked = false;
                  getcheckgradf("-");
                }
                rdf1.checked =false;
                getcheckgradi("-");
              }
            }}
            /> 
          <label className="font-bold text-xl md:text-2xl mr-1 ml-2">+</label>
            <input
            type="radio"
            value="+"
            checked={defaultCheckGI=="+"?true:false}
            disabled={disabled?true:false}
            ref={node=>(rdf1=node)}
            className="radio-blue radio"
            onChange={e=>{
              if(e.target.checked){
                if(signo_igual){
                  rdi.checked = false; rdf.checked = true;
                  getcheckgradf("+");
                }
                rdi1.checked =false;
                getcheckgradi("+");
              }
            }}
            />
          </span>
          <input
          type="number"
          min="0"
          max={max}
          value={defaultGI>=0?defaultGI:""}
          placeholder="0.00"
          step="0.25"
          disabled={(disabledlInput?disabledlInput:false)}
          className="border-form ml-4 w-20"
          onChange={(e) => {
            if(e.target.value!==""&&e.target.value!=="e"){
              e.target.validity && !isNaN(Number(parseFloat(e.target.value))) && parseFloat(e.target.value)<=max
               ? getInputGraduacioni(parseFloat(e.target.value))
               : e.target.value = defaultGI;
             }else{
              getInputGraduacioni()
             }
          }}
          />
      </div>
      <div className="flex flex-wrap w-full mt-2">
        <label className="textf mt-2 md:text-sm w-1/3">{nombre} Final: </label>
            <span className="md:ml-1">
              <label className="font-bold text-xl md:text-2xl mx-1">-</label>
              <input
                type="radio"
                value="-"
                checked={defaultCheckGF=="-"?true:false}
                disabled={disabled?true:false}
                ref={node=>(rdi=node)}
                className="radio-blue radio"
                onChange={e=>{
                  if(e.target.checked){
                    rdf.checked =false;
                    getcheckgradf("-");
                  }
                }}
              />
              <label className="font-bold text-xl md:text-2xl mr-1 ml-2">+</label>
              <input
                type="radio"
                value="+"
                checked={defaultCheckGF=="+"?true:false}
                disabled={disabled?true:false}
                ref={node=>(rdf=node)}
                className="radio-blue radio"
                onChange={e=>{
                  if(e.target.checked){
                    rdi.checked =false;
                    getcheckgradf("+");
                  }
                }}
              />
            </span>
          <input
            type="number"
            min="0"
            max={max}
            value={defaultGF>=0?defaultGF:""}
            step="0.25"
            placeholder="0.00"
            disabled={(disabledlInput?disabledlInput:false)}
            className="border-form ml-4 w-20"
            onChange={(e) => {
              if(e.target.value!==""&&e.target.value!=="e"){
               e.target.validity && !isNaN(Number(parseFloat(e.target.value))) && parseFloat(e.target.value)<=max
                ? getInputGraduacionf(parseFloat(e.target.value))
                : e.target.value = defaultGF;
              }else{
                getInputGraduacionf()
              }
            }}
          />
      </div>
      <div className="flex flex-wrap w-full mt-2 justify-center">
        <label className="textf mt-2 md:text-sm ">Salto</label>
        <input
          ref={(node) => (input_salto = node)}
          type="number"
          step="0.25"
          min="0.25"
          max="10"
          className="border-form ml-2 w-20"
          defaultValue="0.25"
          onInput={(e) => {
            if (e.target.validity.valid === true && e.target.value !== "") {
              getsalto(parseFloat(e.target.value));
            } else {
              getsalto(undefined);
            }
          }}
        />
      </div>
    </div>
};

InputGraduacion.propTypes = {
  nombre: PropTypes.string.isRequired,
  getInputGraduacionf: PropTypes.func.isRequired,
  getInputGraduacioni: PropTypes.func.isRequired,
  getcheckgradi: PropTypes.func.isRequired,
  getcheckgradf: PropTypes.func.isRequired,
  getsalto: PropTypes.func.isRequired,
  signo_igual: PropTypes.bool
};
export default InputGraduacion;
