import React, { useState, useRef, useEffect} from "react";
import {getTotal, tipoCelda, valueToMoney2 } from "../../../services/functions";
import Modalinfo from "../../modal/info";
import { connect } from "react-redux";
import Modaldanger from "../../modal/danger";
import { cloneDeep } from "lodash";
import TableReactWindows from "../Cash/TableReactWindows";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";
import Modalsuccess from "../../modal/success";
import LoadingModal from "../../modal/LoadingModal";

const PanelReporteVentaProveedor = ({PropsReportes,setHeader,Datos}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [repVentas,setRepVentas]=useState(false),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const title = useRef("Sin Datos"),ventasBrand=useRef([]),ventasOld=useRef([]);
  const nameComponent = "ventasProveedorLinea",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const info =Datos[nameComponent]||{};

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }
  function closeModaldangererror(e) {
      const newdataconfig = { ...modaldangererrorConfig };
      newdataconfig.isOpen = false;
      setmodaldangererrorConfig(newdataconfig);
  }
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }
  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  }

  useEffect(()=>{
     setHeader({text:"Sales by Supplier-Line"})
  },[]);

  useEffect(() => {
    if(rutaActualRep!==nameComponent){return;}    
    const datos = Datos[nameComponent]||{};
    ventasBrand.current = cloneDeep(datos[0]);
    ventasOld.current = cloneDeep(datos[0]);
    setRepVentas(!repVentas);
  }, []);


  
  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

     <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <div className="w-full h-full flex flex-col gap-1">
          <div className=' relative  w-full h-95/100 flex justify-start' >
          <TableReactWindows
            btnDownload={async(e,datos)=>{
              const data = datos||ventasBrand.current;
              if(!data?.length){handleError("No hay datos que exportar"); return;}
              openmodalLoading();
              const workb = await GenerateXlsx();
              if(workb?.error){
                closemodalLoading();
                handleError(workb.error);
                return
              }
    
              const nameFile="Ventas-"+info[1]?.proveedor+"-"+info[1]?.linea,
              headerFile="Reporte por proveedor "+info[1]?.proveedor+" por Linea "+info[1]?.linea+" del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final,
              sheet1 = workb.sheet(0),colPlus = 4;
    
              sheet1.cell("A1").value(headerFile);
              sheet1.cell("A1").style("bold",true);
              sheet1.cell("A1").style("fontSize",18);
          
              const sizeData = data.length + colPlus,
              header={'Codigo':17,'Qty':10,'Total':17,'Descripcion':70,'Modelo':15,'Material':15,'PrecioCompra':13,'Extras':40};
          
              Object.keys(header).map((key,index)=>{
                sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
                sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
                sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
                sheet1.column(columnasXlsx[index]).width(header[key]); 
              })
    
              data.forEach((venta,index) => {
                sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.type||"");
                sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.cantidadVendida||0);
                sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(venta?.importe||0));
                sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
                sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(venta?.descripcion||"");
                sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(venta?.modelo||"");
                sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(venta?.material||"");
                sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(venta?.precioCompra||0));
                sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
                sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(venta?.extras||"");
              });
          
              sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");
              sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
              sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.contabilidad);
              
              const response =await SaveXlsx(workb,nameFile);
              if(response?.error){
                closemodalLoading();
                handleError(response.error);
                return
              }
              closemodalLoading();
              openModalSuccess("Archivo generado correctamente","Descarga Completa");
            }}
            columns={[
              {
                key:"linea",
                header:"Linea",
                width:150, 
                type: tipoCelda.text,
                filter:true,
              },
              {
                key:"type",
                header:"Clave",
                width:200, 
                type: tipoCelda.text,
                filter:true,
              },
              {
                key:"cantidadVendida",
                header:"Cant",
                width:70, 
                type: tipoCelda.number,
                filter:true,
                footer:true,
                function: getTotal,
              },
              {
                key:"importe",
                header:"Total",
                width:120, 
                type: tipoCelda.money,
                filter:true,
                footer:true,
                function: getTotal,
              },
              {
                key:"descripcion",
                header:"Descripcion",
                width:400, 
                type: tipoCelda.text,
                filter:true,
              },
              {
                key:"modelo",
                header:"Modelo",
                width:100, 
                type: tipoCelda.text,
                className:"text-center",
                filter:true,
              }
            ]}
            data={ventasBrand.current}
            />
          </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect (mapStateToProps,mapDispatchToProps)(PanelReporteVentaProveedor);
