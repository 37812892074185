import React from 'react'

const logonew = ({width,height,bg,corona,corona1,text,indigo}) => {
	const colores={corona: corona, corona1: corona1, letras:text }
	const styles = {
		st1: { fill: 'url(#SVGID_1_)', },
		st2: { fill: 'url(#SVGID_2_)' },
		st3: { fill: colores.letras},
	};

  return (
	<svg 
	className={height+" "+width+" "+bg+" "+" fill-current "}
	version="1.1"
	id="Layer_1" 
	xmlns="http://www.w3.org/2000/svg" 
	x="0px" 
	y="0px"
	viewBox="0 0 401.36 257.36" 
	style={{ enableBackground: 'new 0 0 401.36 257.36' }}
	space="preserve"
	>
	<g id="Capa_3" className={corona}>
	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="198.615" y1="24.5059" x2="182.8779" y2="160.7657">
			<stop offset="0.4134" style={{stopColor: colores.corona1 }}/>
			<stop offset="0.877" style={{stopOpacity:0, stopColor: colores.corona1, }}/>
		</linearGradient>
		<path style={styles.st1}  d="M151.32,81.82c-0.02,0.02,0.06,0.03,0.06,0.06L151.32,81.82z M151.88,81.64c-0.36-0.07-0.67,0.24-0.12,0.12
			C151.78,81.75,152.54,81.76,151.88,81.64z M259.55,141.62l0.06,0.06C259.6,141.67,259.57,141.6,259.55,141.62z M259.74,141.43
			l0.06,0.06C259.79,141.48,259.76,141.41,259.74,141.43z M152.07,81.64l16.2-4.24c12.73-3.19,35.67-7.33,49.09-7.49
			c1.07-0.01,1.31-0.2,2.45-0.2c15.3-0.02,32.85,0.6,44.88,10.2c5.98,4.78,10,10.92,10,18.95v3.41c0,10.07-5.55,22.21-9.77,30.53
			l-4.99,8.64c1.3-0.87,6.71-6.6,7.84-8.06l3.76-4.37c0.44-0.52,0.77-0.93,1.22-1.43l3.51-4.44c0.42-0.56,0.71-0.95,1.14-1.51
			c0.81-1.07,1.52-2.08,2.29-3.19c7.13-10.28,17.66-28.35,9.3-42.01c-0.3-0.49-0.58-0.92-0.92-1.35c-0.39-0.48-0.69-0.96-1.13-1.33
			c-0.41-0.35-0.88-0.69-1.27-1c-0.52-0.4-0.79-0.64-1.4-1.06c-1.02-0.7-1.73-1.26-2.82-1.91c-24.41-14.46-60.74-8.61-87.21-1.99
			c-14.14,3.54-27.44,7.98-40.89,13.23l-0.93,0.39C152.16,81.54,152.22,81.51,152.07,81.64z"/>
		<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="198.615" y1="24.5059" x2="182.8779" y2="160.7657">
			<stop offset="0.4134" style={{stopColor: colores.corona }}/>
			<stop offset="0.877" style={{stopOpacity:0, stopColor: colores.corona, }}/>
		</linearGradient>
		<path style={styles.st2} d="M14.51,140.48l0.06,0.06C14.56,140.54,14.53,140.47,14.51,140.48 M15.07,140.11c-0.08,0-0.87,0.32-0.15,0.28
			C15.17,140.37,15.71,140.11,15.07,140.11z M15.26,140.11c0.72-0.19,10.98-7.19,11.88-7.8c1.05-0.7,1.87-1.25,2.99-1.93
			c1.09-0.66,1.87-1.29,2.98-1.94c0.6-0.35,0.93-0.61,1.49-0.97l3.03-1.89c8.73-5.46,17.52-10.8,26.44-15.94
			c0.49-0.28,1.03-0.58,1.56-0.9l11.19-6.22c0.69-0.34,0.95-0.53,1.58-0.88l11.41-5.99c2.2-1.1,4.31-2.15,6.56-3.28
			c2.94-1.47,10.68-5.36,13.39-6.48c3.5-1.45,6.78-3.2,10.3-4.65l10.49-4.46c18.04-7.79,40.57-15.07,59.74-19.73
			c29-7.05,69.27-12.06,96,1.26c7.5,3.74,13.98,9.34,17.35,16.9c1.05,2.37,1.71,4.57,2.25,7.21c0.15,0.75,0.35,2.03,0.39,2.83
			c0.14,3.07,0.2,5.68-0.29,8.79c-0.56,3.53-1.4,6.95-2.68,10.19c-1.77,4.5-3.78,8.73-6.26,12.85c-1.63,2.69-3.29,5.26-5.12,7.75
			c-2.11,2.87-5.96,8.01-8.49,10.62l-3.08,3.36c-0.92,1.2-2.07,2.16-3.17,3.26c-2.36,2.36-4.66,5.03-7.27,7.12l-1.63,1.59
			c-0.13,0.12-0.31,0.27-0.4,0.36c-0.5,0.52-0.15-0.17-0.4,0.54h64.34c0.8-1.19,1.67-2.14,2.59-3.28c2.66-3.29,5.25-6.66,7.59-10.2
			c0.81-1.23,1.56-2.22,2.39-3.48l7.68-13.13l4.56-10.2c0.27-0.75,0.53-1.36,0.81-2.22c2.56-7.82,4.26-15.03,4.47-22.35v-3.1
			c-0.15-5-1.02-10.1-2.81-15.52c-0.71-2.15-1.61-4.41-2.75-6.33c-1.98-3.31-3.94-6.5-6.5-9.4c-0.41-0.46-0.7-0.64-1.1-1.17
			c-0.71-0.91-1.38-1.57-2.21-2.34c-0.49-0.45-0.74-0.54-1.24-1.04c-5.96-5.96-16.54-11.49-24.34-14.27
			c-14.68-5.23-29.86-7.83-46.67-7.83c-14.36,0-24.02,0.77-37.83,2.66c-3.26,0.45-6.81,1.05-9.9,1.64c-6.49,1.25-12.58,2.54-18.8,4.1
			l-22.33,6.43c-10.29,3.43-19.37,6.8-29.35,10.77l-9.95,4.24c-8.6,3.7-16.94,7.99-25.3,12.16l-3.72,1.96
			c-3.19,1.58-6.18,3.42-9.34,5.04c-0.63,0.33-1.12,0.63-1.83,1.01c-3.69,1.97-7.32,4.19-10.88,6.34c-2.41,1.46-4.66,2.8-7.07,4.28
			c-1.22,0.74-2.36,1.44-3.48,2.19L57,107.11c-4.49,3.1-8.81,6.46-13.17,9.73l-9.69,7.53c-0.56,0.46-0.98,0.82-1.58,1.26l-4.84,3.86
			c-1.07,0.87-2,1.69-3.11,2.57c-2.38,1.87-4.61,4.01-7,5.87C16.76,138.58,15.86,139.21,15.26,140.11z"/>
	</g>
	<g id="Capa_5" style={styles.st3}>
		<path className="st3" d="M8.66,204.16v-92.73h23.68v92.73H8.66z"/>
		<path className="st3" d="M85.61,204.16v-38.6c0-9.4-3.26-13.78-10.9-13.78c-8.4,0-11.91,4.64-11.91,14.04v38.35H42v-69.55h19.42v7.39
			h0.25c4.39-7.14,10.53-9.77,18.92-9.77c12.53,0,25.82,7.02,25.82,27.57v44.36L85.61,204.16L85.61,204.16z"/>
		<path className="st3" d="M167.56,204.16v-7.52h-0.25c-3.88,6.39-10.9,9.77-20.8,9.77c-21.05,0-34.84-16.17-34.84-37.22
			c0-20.8,14.16-36.97,34.46-36.97c8.02,0,14.54,2.38,20.43,8.27v-29.07h20.8v92.73L167.56,204.16L167.56,204.16z M167.68,169.07
			c0-9.65-7.39-17.42-17.67-17.42c-9.77,0-17.54,7.27-17.54,17.42c0,10.53,7.39,17.92,17.42,17.92
			C160.04,186.99,167.68,179.34,167.68,169.07z"/>
		<path className="st3" d="M196.13,127.21v-15.79h20.8v15.79H196.13z M196.13,204.16v-69.55h20.8v69.55H196.13z"/>
		<path className="st3" d="M276.83,196.01c-3.13,6.64-10.9,10.4-19.42,10.4c-20.3,0-34.96-15.66-34.96-36.59
			c0-21.3,14.91-37.47,34.96-37.47c9.02,0,15.92,3.38,19.3,8.77h0.25v-6.52h20.8v62.16c0,9.4-1.25,16.54-3.76,21.3
			c-5.01,9.65-18.67,17.04-36.09,17.04c-19.67,0-32.46-11.15-34.21-24.44h24.06c2.38,5.14,7.02,6.89,12.16,6.89
			c10.53,0,16.92-6.89,16.92-19.55L276.83,196.01L276.83,196.01z M276.96,169.69c0-10.15-6.89-17.92-17.04-17.92
			c-9.27,0-16.67,7.77-16.67,17.29c0,9.77,6.64,17.92,16.67,17.92C270.06,186.99,276.96,179.72,276.96,169.69z"/>
		<path className="st3" d="M340.49,206.29c-21.18,0-37.47-15.66-37.47-36.97c0-21.55,16.67-36.97,37.47-36.97
			c20.93,0,37.47,15.66,37.47,36.72C377.96,190.87,361.42,206.29,340.49,206.29z M357.16,169.32c0-9.27-7.02-17.54-16.79-17.54
			c-9.15,0-16.54,8.02-16.54,17.54s7.27,17.54,16.67,17.54C350.26,186.86,357.16,178.59,357.16,169.32z"/>
	</g>
	</svg>
  )
}

export default logonew
