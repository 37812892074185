import React, { useEffect, useRef, useState } from "react";
import PanelTabla from "../components/Ventas/PanelTabla";
import InputProduct from "../components/inputProduct";
import InputClient from "../components/inputClient";
import InputCotizacion from "../components/inputCotizacion";
import Totales from "../components/Ventas/Totales";
import Container from "../components/Container";
import DatosClienteVenta from "../components/DatosClienteVenta";
import InputDeleteVenta from "../components/Ventas/InputDeleteVenta";
import Cotizaciones from "../components/Ventas/Cotizaciones";
import CambioArticulo from "../components/Ventas/CambioArticulo";
import BuscaTicket from "../components/Ventas/BuscaTicket.jsx";
import SearchLinea from "../components/Productos/SearchLinea";
import { GetFechaActual, havePermission, moneyToValue } from "../services/functions";
import Pendientes from "../components/Ventas/Pendientes";
import LoadingModal from "../components/modal/LoadingModal";
import { connect } from "react-redux";
import Modalgeneric from "../components/modal/generic.js";
import Labelmoney from "../components/labelmoney.js";
import { permisos } from "../static/dat/datos.js";
import {motion} from 'framer-motion';
import { btnScale, btnTap } from "../static/motionStyle.js";

const Venta = ({windowSize}) => {
  const [cotizacion,setCotizacion]= useState(),
  [stateCotizacion,setStateCotizacion]= useState(),
  [statePendientes,setStatePendientes]= useState(),
  [showDetail,setShowDetail]= useState(false),
  [cerrarVenta,setCerrarVenta]=useState(),
  [cerrarCotizacion,setCerrarCotizacion]=useState(),
  [stateItem,setStateItem]=useState({}),
  [cleanVenta,setCleanVenta]=useState(),
  [modalArqueo,setModalArqueo]= useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [loading,setLoading]=useState(),
  [arqueo,setArqueo]=useState({'1000':0,'500':0,'200':0,'100':0,'50':0,'20':0,'10':0,'5':0,'2':0,'1':0,'0.50':0,'0.20':0,'0.10':0}),
  [totalArqueo,setTotalArqueo]=useState(0),
  [focusInputProduct,setInputProduct]=useState();
  const permisoDeleteVenta = havePermission(permisos.cancelVenta);
  

  const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, titulo:titulo });};
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false }); };

  const openModalArqueo = () => { setModalArqueo({isOpen:true,titulo:"Arqueo"})}
  const closeModalArqueo=()=>{setModalArqueo({isOpen:false})}

  const handleKeyPress=(e)=>{
    if(e.key=="F5"){
      e.preventDefault();
      setCerrarVenta(!cerrarVenta);
      return;
    }
    if(e.key=="F7"){
      e.preventDefault();
      setCerrarCotizacion(!cerrarCotizacion);
      return;
    }
    if(e.key=="F8"){
      e.preventDefault();
      setCleanVenta(!cleanVenta);
      setStateCotizacion(!stateCotizacion);
      return;
    }
    if(e.key=="F9"){
      e.preventDefault();
      setStatePendientes(!statePendientes);
      return;
    }
    if(e.key=="F10"){
      e.preventDefault();
      openModalArqueo();
      return;
    }
  }

  const calculaArqueo=(newArqueo)=>{
    let total = 0;
    Object.keys(newArqueo).forEach(key=>{
      total += key*newArqueo[key];
    })
    setArqueo(newArqueo);
    setTotalArqueo(total);
  }

  useEffect(() => {
    if(loading){
      openmodalLoading();
    }else if (loading==false){
      closemodalLoading();
    }
  }, [loading])


  return (
  <Container onKeyDown={handleKeyPress}>
        <LoadingModal config={modalLoading}  >
        <LoadingModal.Body>
          <LoadingModal.Title>{modalLoading.titulo||"CARGANDO"}</LoadingModal.Title>
        </LoadingModal.Body>
        </LoadingModal>
        
        {modalArqueo?.isOpen?
    <Modalgeneric w="size-w-40" h="size-h-60" tab="300" config={modalArqueo} closeModal={closeModalArqueo}>
      <Modalgeneric.Body>
        <Modalgeneric.Title>{modalArqueo?.titulo+" "+GetFechaActual().fecha}</Modalgeneric.Title>
        <div className="w-full size-2 gap-2">
          <div className="flex flex-col gap-3 bg-pink-50 rounded-lg">
            <label className="font-size text-size w-full text-center">BILLETES</label>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$1,000</label>
              <input className="border-form" id="1000" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'1000':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$500</label>
              <input className="border-form" id="500" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'500':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$200</label>
              <input className="border-form" id="200" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'200':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$100</label>
              <input className="border-form" id="100" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'100':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$50</label>
              <input className="border-form" id="50" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'50':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$20</label>
              <input className="border-form" id="20" onBlur={e=>{ 
               let newNumber = parseInt(e.target.value);
               try{ if(isNaN(newNumber)){ throw new Error() } 
               } catch {newNumber=0; }
               e.target.value = newNumber;
               calculaArqueo({...arqueo,'20':newNumber})
               }}/>
            </div>
          </div>
          
          <div className="flex flex-col gap-3 bg-teal-50 rounded-lg">
            <label className="font-size text-size w-full text-center">MONEDAS</label>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$10</label>
              <input className="border-form" id="10" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'10':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$5</label>
              <input className="border-form" id="5" onBlur={e=>{ 
               let newNumber = parseInt(e.target.value);
               try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
               e.target.value = newNumber;
               calculaArqueo({...arqueo,'5':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$2</label>
              <input className="border-form" id="2" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'2':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$1</label>
              <input className="border-form" id="1" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'1':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$0.50</label>
              <input className="border-form" id="0.50" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'0.50':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$0.20</label>
              <input className="border-form" id="0.20" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'0.20':newNumber})
               }}/>
            </div>
            <div className="w-full size-form gap-2 items-center">
              <label className="text-center font-size text-size w-16">$0.10</label>
              <input className="border-form" id="0.10" onBlur={e=>{ 
                let newNumber = parseInt(e.target.value);
                try{ if(isNaN(newNumber)){ throw new Error() } 
                } catch {newNumber=0; }
                e.target.value = newNumber;
                calculaArqueo({...arqueo,'0.10':newNumber})
              }}/>
            </div>
          </div>
          <div className="w-full size-form py-2 rounded-lg items-center ">
              <label className="text-center font-size text-size w-16">Total</label>
              <Labelmoney twoDecimal={true}>{ moneyToValue(totalArqueo)}</Labelmoney>
          </div>
        </div>
      </Modalgeneric.Body>
      <Modalgeneric.Buttons>
        <button
        className="btn-gray2"
        tabIndex="300"
        name="modalgeneric"
        onClick={closeModalArqueo}
        >
          Cerrar
        </button>
        <button
        className="btn-red1"
        tabIndex="301"
        name="modalgeneric"
        onClick={e=>{
          document.getElementById('0.10').value = "";
          document.getElementById('0.20').value = "";
          document.getElementById('0.50').value = "";
          document.getElementById('1').value = "";
          document.getElementById('2').value = "";
          document.getElementById('5').value = "";
          document.getElementById('10').value = "";
          document.getElementById('20').value = "";
          document.getElementById('50').value = "";
          document.getElementById('100').value = "";
          document.getElementById('200').value = "";
          document.getElementById('500').value = "";
          document.getElementById('1000').value = "";
          calculaArqueo({'1000':0,'500':0,'200':0,'100':0,'50':0,'20':0,'10':0,'5':0,'2':0,'1':0,'0.50':0,'0.20':0,'0.10':0})
        }}>
          Borrar
        </button>
      </Modalgeneric.Buttons>
    </Modalgeneric>
    :null}

    <Cotizaciones stateCotizacion={stateCotizacion} inputFocus={focusInputProduct} setInputFocus={setInputProduct} getCotizacion={cotizacion=>{setCotizacion(cotizacion);}}/>
    <Pendientes statePendientes={statePendientes} inputFocus={focusInputProduct} setInputFocus={setInputProduct} getPendiente={venta=>{}}/>
    <div className="size-3 gap-2 mb-2 mt-1 w-full ">
      <InputClient inputFocus={focusInputProduct} setInputFocus={setInputProduct} />
      <div className="col-span-2 gap-2 flex">
        <motion.button 
        whileHover={btnScale}
        whileTap={btnTap}
        className="pl-1 text-teal-700 hover:text-green-500 text-xl text-left"
        onClick={e=>{setShowDetail(!showDetail)}}
        >
          <i className={"fas "+ (!showDetail?"fa-chevron-circle-down":"fa-chevron-circle-up")}/>
        </motion.button>
        <DatosClienteVenta/>
       
      </div>
      
     
    </div>

    <div className={(showDetail?"size-form gap-1 px-2 pb-2 w-full items-center text-size":" hidden ")}>
      <div className="size-form w-full gap-2">
        <div className={""+(permisoDeleteVenta?"block":"hidden")}>
          <InputDeleteVenta />
        </div>
        <InputCotizacion cotizaciones={cotizacion} setLoading={setLoading}/>
      </div>
      <div className="size-form gap-1 w-full items-center justify-center">
        <div className='size-form w-full justify-center group'>
          <button 
            className="btn-gray2 w-full"
            title="F8"
            onClick={e=>{
              setStateCotizacion(!stateCotizacion);
          }}>
              <i className="fas fa-file-alt">
                <div className={windowSize>=1440?"":'hidden'}>Cotizacion</div>
              </i>
          </button>
          <span className={" tooltiptext lg:hidden"}>
                Ver Cotizaciones
          </span>
        </div>
        <CambioArticulo windowSize={windowSize}/>
        <BuscaTicket windowSize={windowSize}/>
      </div>
    </div>

    <div className="size-form w-full items-center gap-2 ">
      <div className="w-full md:w-auto size-form ml-1 items-center ">
        <InputProduct stateItem={stateItem} setStateItem={setStateItem} inputFocus={focusInputProduct} setInputFocus={setInputProduct} Ruta="venta"/>
      </div>
      <div className="w-full md:w-60">
        <SearchLinea inputFocus={focusInputProduct} setInputFocus={setInputProduct} Ruta="venta"/>
      </div>
     
    </div>
    <div className="h-full w-full" >
      <div className="relative h-full md:h-87/100 md:mx-1" >
        <PanelTabla stateItem={stateItem} inputFocus={focusInputProduct} setInputFocus={setInputProduct}/>
      </div>
      <div className="h-10/100">
        <Totales closeSale={cerrarVenta} cerrarCotizacion={cerrarCotizacion} inputFocus={focusInputProduct} setInputFocus={setInputProduct} cleanVenta={cleanVenta}/>
      </div>
    </div>
  </Container>
  );
};

const mapStateToProps = (state) => ({
  windowSize: state.Logueo.windowSize,
});

export default connect(mapStateToProps)(Venta);
