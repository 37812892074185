import React, { useMemo, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { useEffect } from 'react';
import {  ConvertMoneytoFormatReturn, FechaLocalToUTCDateHour, FechaUTCToLocal, OrderArrayAZ, OrderArrayZA, filtroItems, filtroItemsExcept, filtroItemsMajor, filtroItemsMinor, filtroItemsSelect, filtroValoresRepetidos, filtroValoresUnicos, valueToMoney2 } from '../../../services/functions';
import { connect } from 'react-redux';
import ContextMenu from '../../modal/ContextMenu';
import LabelProcent from '../../LabelPorcent';


const Row = ({ index, style, data, columns, openmodalMenu,onRowClick,bgRow,conditionBgRow,isScrolling}) => {
  const bgR = bgRow||"bg-gray-50";

  return (
      <div 
      className={'flex flex-row border-table-bottom hover:bg-sky-100 '+(conditionBgRow?(data[index]?.[conditionBgRow?.campo]== conditionBgRow?.valor?conditionBgRow?.verdadero:(!conditionBgRow?.falso?bgR:conditionBgRow.falso)):bgR) }
      style={style}
      onClick={e=>{
        onRowClick?onRowClick({e,row:data[index]}):null;
      }}>
        {isScrolling?
        <div className='w-full text-center bot text-size-table border-table-separate font-semibold '>...</div>
        :columns.map(column=>{
          const bgColumn = column?.bgCol?(data[index]?.[column.bgCol?.campo]==column.bgCol?.valor?column.bgCol?.verdadero:(column.bgCol?.falso||"")):"";
          const valor = data[index]?.[column?.key];
          const row =  data[index];

          if(column?.content){
            return <div
            name={index} 
            style={{width:column?.width}}
            key={column?.type+index+column?.key}
            className={"border-table-separate pt-1 text-size-table font-semibold "+ column?.className +" "+ bgColumn}
            onContextMenu={e=>{
              column?.filter?openmodalMenu(e,row,column.key,column?.type):null;
             }}
             onClick={e=>{
              column?.onClick?column.onClick({e,row,index,value:valor}):null;
             }}>
              {column.content({row,index,value:valor})}
            </div>
          }
          else if(column?.type == "money"){
              const number = valueToMoney2(valor||0).toFixed(2);
              const val = ConvertMoneytoFormatReturn(number);
             return  <p 
              style={{width:column?.width}}
              key={column?.type+index+column?.key}
              className={"border-table-separate pt-1 font-semibold text-right text-size-table "+(number<0?"text-red-500":(column?.className||""))+" "+ bgColumn} 
              onContextMenu={e=>{
                column?.filter?openmodalMenu(e,row,column.key,column?.type):null;
              }}
              onClick={e=>{
                column?.onClick?column.onClick({e,row,index,value:valor}):null;
               }}>
                {(number==0?"-":"$ "+val+(val.split('.').length==2?"":".00"))}
              </p>
          } 
          else if(column?.type == "date"){
            const date = column?.noUtc?FechaLocalToUTCDateHour(valor):FechaUTCToLocal(valor);
            date.fecha=="Invalid Date"?date.fecha="-":null;
            date.hora=="Invalid Date"?date.hora="":null;
            return <div 
            style={{width:column?.width}}
            key={column?.type+index+column?.key}
            className={"border-table-separate pt-1 font-semibold flex group justify-end text-size-table  " + bgColumn}
            onContextMenu={e=>{
              column?.filter?openmodalMenu(e,row,column.key,column?.type):null;
            }}
            onClick={e=>{
              column?.onClick?column.onClick({e,row,index,value:valor}):null;
             }}>
              {!column?.hideTip?<span className="tooltiptext-free bg-blue-800 text-white ">{date.hora}</span>:null}
                <p className='w-full text-left '>
                  {date.fecha}
                </p>
            </div>
          }
          else if(column?.type == "porcent"){
            return <p 
            name={index} 
            style={{width:column?.width}}
            key={column?.type+index+column?.key}
            className={"border-table-separate pt-1 font-semibold text-size-table "+ column?.className+ " "+ bgColumn} 
            onContextMenu={e=>{
              column?.filter?openmodalMenu(e,row,column.key,column?.type):null;
            }}
            onClick={e=>{
              column?.onClick?column.onClick({e,row,index,value:valor}):null;
             }}>
              <LabelProcent className="text-right" valor={valor}/>
            </p>
          }
          else if(column?.type == "number"){
            const number = (valor||0).toString();
            const val = ConvertMoneytoFormatReturn(number);
            return <p 
            name={index} 
            style={{width:column?.width}}
            key={column?.type+index+column?.key}
            className={"border-table-separate pt-1 font-semibold text-right text-size-table "+(number<0?"text-red-500":(column?.className||""))+" "+ bgColumn} 
            onContextMenu={e=>{
              column?.filter?openmodalMenu(e,row,column.key,column?.type):null;
            }}
            onClick={e=>{
              column?.onClick?column.onClick({e,row,index,value:valor}):null;
             }}>
              {val==0?"-":val}
            </p>
          }
          else if(column?.type == "text"){
            return <p 
            name={index} 
            style={{width:column?.width}}
            key={column?.type+index+column?.key}
            className={"border-table-separate pt-1 break-all overflow-x-auto font-semibold text-size-table "+ column?.className+" "+ bgColumn} 
            onContextMenu={e=>{
              column?.filter?openmodalMenu(e,row,column.key,column?.type):null;
            }}
            onClick={e=>{
              column?.onClick?column.onClick({e,row,index,value:valor}):null;
             }}>
              { valor+""}
            </p>
          }
          else{
            return  <p 
            name={index} 
            style={{width:column?.width}}
            key={column?.type+index+column?.key}
            className={"border-table-separate pt-1 break-all overflow-x-auto font-semibold text-size-table "+ column?.className+" "+ bgColumn}
            onContextMenu={e=>{
              column?.filter?openmodalMenu(e,row,column.key,column?.type):null;
             }}
             onClick={e=>{
              column?.onClick?column.onClick({e,row,index,value:valor}):null;
             }}>
              {valor+""}
            </p>
          }
        })}
      </div>
  );
};

const Footer = ({value,index,col}) => {
  const bgFooter = (col?.bgFooter?col.bgFooter:"bg-gray-50");

          if(col?.type == "money"){
              const number = valueToMoney2(value||0).toFixed(2);
              const val = ConvertMoneytoFormatReturn(number);
             return  <p 
              style={{width:col?.width}}
              key={col?.type+index+col?.key+"footer"}
              className={bgFooter+" "+(col?.className||"")+" border-table-separate-trans font-semibold  text-right text-size-table pt-1 "+(number<0?"text-red-500":"")} 
              >
                {(number==0?"-":"$ "+val+(val.split('.').length==2?"":".00"))}
              </p>
          }
          else if(col?.type == "porcent"){
            return <p 
            name={index} 
            style={{width:col?.width}}
            key={col?.type+index+col?.key+"footer"}
            className={bgFooter+" "+(col?.className||"")+ " border-table-separate-trans font-semibold text-size-table pt-1"} >
              <LabelProcent className="text-right" valor={value}/>
            </p>
          }
          else if(col?.type == "number"){
            const number = (value||0).toString();
            const val = ConvertMoneytoFormatReturn(number);
            return <p 
            name={index} 
            style={{width:col?.width}}
            key={col?.type+index+col?.key+"footer"}
            className={bgFooter+" "+(col?.className||"")+  " border-table-separate-trans font-semibold  text-right text-size-table pt-1 "+(number<0?"text-red-500":"")} >
              {val}
            </p>
          }
          else if(col?.type == "text"){
            return <p 
            name={index} 
            style={{width:col?.width}}
            key={col?.type+index+col?.key+"footer"}
            className={bgFooter+" "+(col?.className||"")+ " border-table-separate-trans font-semibold  text-size-table pt-1 "} >
              {value+""}
            </p>
          }
          else{
            return  <p 
            name={index} 
            style={{width:col?.width}}
            key={col?.type+index+col?.key+"footer"}
            className={bgFooter+" "+(col?.className||"")+ " border-table-separate-trans pt-1 "}>
              {value+""}
            </p>
          }
};

const TableReactWindows=(props)=> {
  const rowHeight = props.rowHeight || 22;
  const widthTable = useRef(),arrayItems=useRef([]),cont=useRef(200),containerRef = useRef(null);
  const data = props.data||[],
  cols = props.columns || [],
  bgHeader = props.bgHeader || "bg-black text-ambar-200",
  bgRow =props.bgRow || "",
  conditionBgRow =  props.conditionBgRow || null,
  //configDownload = props.configDownload || {},
  onRowClick =  props.onRowClick|| null;
  const [modalMenu, setmodalMenu] = useState(false),
  [chageArray,setChangeArray]=useState(false),
  [footerMap,setFooterMap]=useState([]);
  

  const openmodalMenu = (e,item,campo,type) => {
    const new_item = item || {};
    let positionY = e.clientY-(e.clientY-containerRef.current.getBoundingClientRect().y);
    let positionX = e.clientX-(containerRef.current.getBoundingClientRect().x<e.clientX?containerRef.current.getBoundingClientRect().x:0)+20;
    //console.log('x:',e.clientX,' y:',e.clientY,'wx:', window.screenX);
    if(e.clientY+100 >containerRef?.current?.clientHeight){
      positionY = e.clientY-350;
    }
    //console.log('y>>',e.clientY,' yN>>>',containerRef.current?.getBoundingClientRect().y)
   // console.log('x>>',e.clientY,' xN>>>',containerRef.current?.getBoundingClientRect().x)
    setmodalMenu({ isOpen: true, title: "Cargando Datos", x: positionX, y: positionY, items: AddItem(new_item,campo,type)});
  };
  const closemodalMenu = (e) => {
    setmodalMenu({ isOpen: false, title: "", x: 0, y: 0});
  };

  const AddItem=(item,campo,type)=>{
    return [
      <div key="0" 
      className={' py-1 px-1 hover:bg-gray-300 '}
      onClick={e=>{
        //setItemsFilter(filtroItems(item,campo,type));
        arrayItems.current = filtroItems(arrayItems.current,item,campo,type);
        setChangeArray(!chageArray);
        closemodalMenu();
      }}>
          Filtrar Por Seleccion
      </div>,
      <div key="1" 
      className={' py-1 px-1 hover:bg-gray-300 '}
      onClick={e=>{
        //setItemsFilter(filtroItemsExcept(item,campo));
        arrayItems.current = filtroItemsExcept(arrayItems.current,item,campo,type);
        setChangeArray(!chageArray);
        closemodalMenu();
      }}>
          Filtro Excluyendo Seleccion
      </div>,
      <div key="2" 
      className={' py-1 px-1 hover:bg-gray-300 '}
      onClick={e=>{
        //setItemsFilter(filtroItemsExcept(item,campo));
        arrayItems.current = filtroValoresUnicos(arrayItems.current,campo,type);
        setChangeArray(!chageArray);
        closemodalMenu();
      }}>
          Filtro Valores Unicos
      </div>,
      <div key="3" className={' py-1 px-1 hover:bg-gray-300 '}>
        <label>Filtrar </label>
        <input className="border-form uppercase" onChange={e=>{e.target.value=e.target.value.toUpperCase()}} onKeyPress={e=>{
          if(e.key=="Enter"){
            //setItemsFilter(filtroItemsSelect(e.target.value,campo));
            arrayItems.current = filtroItemsSelect(arrayItems.current,e.target.value,campo,type);
            setChangeArray(!chageArray);
            closemodalMenu();
          }
        }}/>
      </div>,
      <div key="4"  className={' py-1 px-1 hover:bg-gray-300 '} 
      onClick={e=>{
        //setItemsFilter(data);
        arrayItems.current = [...data];
        setChangeArray(!chageArray);
        closemodalMenu();
      }}>
        <button className='mr-1'>
            <i className='fas fa-ban text-red-600'></i>
        </button>
        <label>Quitar Filtro </label>
      </div>,
      <div key="5" 
       className={' py-1 px-1 hover:bg-gray-300 '}
       onClick={e=>{
        //setItemsFilter(OrderArrayAZ(itemsFilter,campo,typeof(item?.[campo])));
        arrayItems.current =OrderArrayAZ(arrayItems.current,campo,typeof(item?.[campo]));
        setChangeArray(!chageArray);
        closemodalMenu();
      }}>
        <i className="fas text-blue-800 fa-sort-alpha-down pr-1"/>
        Orden Ascendente
      </div>,
      <div key="6" 
      className={' py-1 px-1 hover:bg-gray-300 '}
      onClick={e=>{
        //setItemsFilter(OrderArrayZA(itemsFilter,campo,typeof(item?.[campo])));
        arrayItems.current =OrderArrayZA(arrayItems.current,campo,typeof(item?.[campo]));
        setChangeArray(!chageArray);
        closemodalMenu();
     }}>
      <i className="fas text-red-800 fa-sort-alpha-down-alt pr-1"/>
       Orden Descendente
     </div>,
     <div key="7" 
     className={' py-1 px-1 hover:bg-gray-300 text-xs'}
     onClick={e=>{
       //setItemsFilter(OrderArrayZA(itemsFilter,campo,typeof(item?.[campo])));
       arrayItems.current =filtroItemsMinor(arrayItems.current,item,campo,type);
       setChangeArray(!chageArray);
       closemodalMenu();
    }}><i className="fas fa-less-than pr-1"/>
     Filtrar Menores
    </div>,
     <div key="8" 
      className={' py-1 px-1 hover:bg-gray-300 text-xs'}
      onClick={e=>{
        //setItemsFilter(OrderArrayZA(itemsFilter,campo,typeof(item?.[campo])));
        arrayItems.current =filtroItemsMajor(arrayItems.current,item,campo,type);
        setChangeArray(!chageArray);
        closemodalMenu();
     }}><i className="fas fa-greater-than pr-1 "/>
      Filtrar Mayores
     </div>,
     
     props?.btnDownload?
      <div key="10" 
      className={' py-1 px-1 font-semibold text-teal-700 hover:bg-gray-400 '}
      onClick={e=>{
        props.btnDownload(e,arrayItems.current);
        closemodalMenu();
     }}>
      <i className="fas fa-arrow-alt-circle-down pr-1"/>
       Descargar Excel
     </div>
     :null,
     <div key="11" 
      className={' py-1 px-1 hover:bg-gray-300 '}
      onClick={e=>{
        //setItemsFilter(filtroItemsExcept(item,campo));
        arrayItems.current = filtroValoresRepetidos(arrayItems.current,campo);
        setChangeArray(!chageArray);
        closemodalMenu();
      }}>
          Filtro Valores Repetidos
      </div>,



      <p key="20" 
      className={' py-1 px-1 hover:bg-gray-300 '}
      onClick={e=>{
        closemodalMenu();
      }}>
        Cancelar
      </p>,
    ]
  }

  useMemo(() => {
    let width= 0;
    cols.forEach(col=>{
      width += col.width ||0;
    });
    
    widthTable.current = width;

    if(containerRef.current?.scrollWidth){
      //console.log(cont.current,'<>',containerRef.current.clientHeight,'<>',containerRef.current.offsetHeight)
      const isVerticalScrollbarVisible = cont.current > containerRef.current.clientHeight-40;
      if(isVerticalScrollbarVisible) widthTable.current = width+17;
    }
  }, [cols])

  useMemo(()=>{
    if(data.length<1){return;}
    arrayItems.current = data;
    setChangeArray(!chageArray);
  },[data])

  useEffect(()=>{
    //console.log('footer')
    if(!containerRef.current?.clientHeight){ return;}
    let new_footer = cols?
    <div 
    style={{width:widthTable.current}}
    className={'flex flex-row text-center '}>
      {cols.map((col,index)=>{
        if(col?.footer){
          const val =  col?.function?col.function(arrayItems.current,col.key):(col?.footer||"");
          return Footer({value:val,index,col:col});
        }else{
          return<p 
          name={index} 
          style={{width:col.width}}
          key={col?.type+index+col?.key+"header"}
          className={"w-full "}>
            {""}
          </p>
        }
      
      })}
    </div>
    :null;
    

    if(containerRef.current.clientHeight<(arrayItems.current.length)*rowHeight){
      //console.log('muchositems:',containerRef.current.clientHeight)
      cont.current = containerRef.current.clientHeight;
    }else{
      //console.log('pocositems:',arrayItems.current.length*rowHeight)
      cont.current = arrayItems.current.length*rowHeight;
    }
    
    setFooterMap(new_footer);
  },[arrayItems.current])




  return (
      <div ref={containerRef} className="absolute flex flex-col break-words overflow-x-auto overflow-y-auto h-full max-w-full mx-auto landscape:mt-0">
        <ContextMenu config={modalMenu} closemodalMenu={closemodalMenu}/>
        {cols?
          <div 
          style={{width:widthTable.current}}
          className={'flex flex-row border-table-bottom rounded-md text-center '+(bgHeader)}>
            {cols.map(col=>(
              <p 
              key={col.key+"header"} 
              style={{width:col?.width}} 
              title={col.title||""}
              className={" border-table-separate font-size cursor-pointer"}
              >
                {col.header}
              </p>
            ))}
          </div>
        :null}
        <List
          height={cont.current}
          itemCount={arrayItems.current.length}
          itemSize={rowHeight}
          itemData={arrayItems.current}
          width={widthTable.current}
          //useIsScrolling {...props}
        >
          {({index,style,data,isScrolling})=>(
            <Row
            index={index}
            style={style}
            data={data}
            columns={cols}
            openmodalMenu={openmodalMenu}
            onRowClick={onRowClick}
            bgRow={bgRow}
            conditionBgRow={conditionBgRow}
            //isScrolling={isScrolling}
            />
          )}
        </List>
        {footerMap}
      </div>
  )
};

const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  CuentaMayor:state.Catalogos.MAJORACCOUNTS,
  Datos: state.Datos
});

export default connect(mapStateToProps, null)(TableReactWindows)