import React, { useState, useEffect} from "react";
import { CalcularPorcentajeMargenUtilidad, FechaLocalToUTC, OrderArrayAZ, TransformSysAll, apiUsePromise, getSucursal, tipoCelda, valueToMoney2 } from "../../../services/functions";
import Modalinfo from "../../modal/info";
import { connect } from 'react-redux';
import LoadingModal from "../../modal/LoadingModal";
import TableReactWindows from "../Cash/TableReactWindows";
import { setRutaRep } from "../../../redux/Actions";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";
import Modalsuccess from "../../modal/success";

const PanelReporteVentasDay = ({setRutaRep,PropsReportes,setHeader,addDatos,Datos,setPropsRep}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [ventas,setVentas]=useState([]);
  const nameComponent = "ventasDay",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];

  const handleSuccesfulDayVenta=(data)=>{
    const balance = data.map(venta=>({...venta,type:venta.type.split('#')[0]}));
    let ventasPes = Datos.ventasRango[0].filter(venta=>venta.type==PropsReportes.header.day)[0];
    ventasPes.itemsPes.forEach(pes=>{
      balance.push(pes);
    })

    balance.sort((va,vb)=>{
      return va.shortId.localeCompare(vb.shortId);
    })
    return balance;
  }
  const handleError = (error) => {
    closemodalLoading();
  }

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }
  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }


  
function getTotal(array,campo) {
  //console.log(array,' campo:',campo,' val:',array[0]?.[campo],'==>',array.reduce((a, b) => parseInt(a) + parseInt(b?.[campo]), 0))
  let new_array = [...array];
  new_array = new_array.reduce((a, b) => parseInt(a) + parseInt(b?.[campo]||0), 0)
  return new_array;
}

  
function getMargenTotal(array) {
  //console.log(array,' campo:',campo,' val:',array[0]?.[campo],'==>',array.reduce((a, b) => parseInt(a) + parseInt(b?.[campo]), 0))
  let new_array = [...array];
  //const margen =
  new_array = CalcularPorcentajeMargenUtilidad(array.reduce((a, b) => parseInt(a) + parseInt(b?.utilidad||0), 0),array.reduce((a, b) => parseInt(a) + parseInt(b?.total), 0))
  return new_array;
}

  useEffect(() => {
    if(rutaActualRep!==nameComponent){return;}
    const newPropsRep = {...PropsReportes,selectSe:PropsReportes.header?.day,header:{btns:{detail:true,directionSe:true},day:PropsReportes.header?.day}};
    if(newPropsRep.header?.text!=="Sales by Invoice"){ newPropsRep.header.text = "Sales by Invoice"}
    
    const datos = Datos[nameComponent]||{};
    
    const getVentasAsync=async()=>{
      openmodalLoading();
      let reporteVentas=[];
      let peticion ={
          indexName:"reverseIndex",
          hashKey:{valor:("VENTA#"+getSucursal()),nombre:"type"},
          sortKey:{valor:[FechaLocalToUTC(PropsReportes.header.day),FechaLocalToUTC(PropsReportes.header.day,23,59,59)],operador:"between",nombre:"id"},
          filters:[{valor:'',operador:'exists',nombre:'pagado'}]
      }
      try{
        const reponseVentas = await apiUsePromise({ url: "/custom-query", method: "POST",data: peticion});
        const reporteFecha = reponseVentas.data.datos;
        reporteVentas = handleSuccesfulDayVenta(reporteFecha);
        
        reporteVentas.map(reg=>{
          reg.margen = CalcularPorcentajeMargenUtilidad(reg?.utilidad,reg?.total);
          reg.credito = (reg.pagado == reg.total?"":"TRUE")
          
          if(reg.type=="PE"){
            reg.utilidad = reg.total - reg.totalPrecioCompra;
            reg.margen = CalcularPorcentajeMargenUtilidad(reg.utilidad,reg.total)
          }
        });

        reporteVentas = OrderArrayAZ(reporteVentas,'shortId','string');

        newPropsRep.select = reporteVentas[0].shortId;
        
        setPropsRep(newPropsRep)
        setVentas(reporteVentas);
        addDatos(nameComponent,[reporteVentas,{day:PropsReportes.header.day}])
        closemodalLoading();
      }catch(error){
        closemodalLoading();
        handleError(error)
      }
    }
    
    if(JSON.stringify(datos)=='{}'){
      getVentasAsync();
      return;
    }
    if(PropsReportes.header.day==datos[1].day){
      const reporteVentas =datos[0];
      setVentas(reporteVentas);
      newPropsRep.select = reporteVentas[0].shortId;
      setPropsRep(newPropsRep)
      return;
    }
    getVentasAsync();
  }, [PropsReportes.header.day,PropsReportes.rutas]);

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <TableReactWindows
        btnDownload={async(e,datos)=>{
          const data = datos||ventas;
          if(!data?.length){handleError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            handleError(workb.error);
            return
          }

          const nameFile="Ventas-"+PropsReportes.header?.day,
          headerFile="Reporte de Ventas del dia "+PropsReportes.header?.day,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={'Ticket':10,'Cant':10,'Ventas':17,'Descuento':17,'Importe':17,'Profit':15,'Margen':10,'Customer':15,'Tipo':10};
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((venta,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.shortId||"");
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.numeroArticulos||0);
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(venta?.subtotal||0));
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(valueToMoney2(venta?.descuentoTotal||0));
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(venta?.total||0));
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(venta?.utilidad||0));
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).value((venta?.margen/100)||0);
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).style("numberFormat", "0.00%");
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(TransformSysAll(venta?.cliente)||"");
            sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(venta?.type||"");
          });
      
          sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[3]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[4]+sizeData).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[4]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[6]+sizeData).formula("AVERAGE("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[6]+sizeData).style("numberFormat", "0.00%");
          
          const response =await SaveXlsx(workb,nameFile);
          if(response?.error){
            closemodalLoading();
            handleError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
        }}
        onRowClick={({e,row})=>{ if(e.detail==1){ setPropsRep({...PropsReportes,select:row.shortId}) } }}
        columns={[
          {
            key:"shortId",
            header:"#Invoice",
            width:100, 
            type: tipoCelda.text,
            className:"text-blue-800",
            filter:true,
            onClick: ({e,row})=>{
              if(e.detail==2){
                setHeader({...PropsReportes.header,folio:row.shortId,cliente:row.cliente,nombreCliente:row.nombreCliente,user:row.vendedor,type:row.type,items:row?.items||[]})
                setRutaRep("ventasFolio");
              }
            }
          },
          {
            key:"numeroArticulos",
            header: "QtySold",
            width:70,
            type: tipoCelda.number,
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"subtotal",
            header: "Sales",
            width:120,
            type: tipoCelda.money,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"iva",
            header: "Iva",
            width:100,
            type: tipoCelda.money,
            filter:true,
          },
          {
            key:"descuentoTotal",
            header: "Discount",
            width:100,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"total",
            header: "Total",
            width:120,
            type: tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"utilidad",
            header: "Profit",
            width:120,
            type: tipoCelda.money,
            footer:true,
            function: getTotal,
          },
          {
            key:"margen",
            header: "Margin",
            width:90,
            type: tipoCelda.porcent,
            filter:true,
            footer:true,
            function: getMargenTotal,
          },
          {
            key:"cliente",
            header: "Customer",
            width:100,
            type: tipoCelda.text,
            filter:true,
          },
          {
            key:"type",
            header: "Type",
            width:90,
            type: tipoCelda.text,
            filter:true,
          },
          {
            key:"credito",
            header: "Credit",
            width:90,
            className:"text-center",
            type: tipoCelda.text,
            filter:true,
          },
        ]}
        data={ventas}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  setPropsRep: (props)=>dispatch({type:"SET_PROPS_REP",props:props})
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelReporteVentasDay);
