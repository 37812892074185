import React, { useState } from 'react'

const CambioArticulo=({windowSize})=> {
  const [enable,setEnable]=useState(false);
  return (
    <div className='size-form w-full justify-center group'>
        <button className="btn-gray2 w-full break-words">
          <i className="fas fa-exchange-alt mr-2">
            <p className={windowSize>=1440?"":'hidden'}>Cambio Articulo</p>
          </i>
        </button>
        <span className={" tooltiptext lg:hidden"}>
              Cambio Articulo
        </span>
    </div>
  )
}
export default  CambioArticulo