import React, { useEffect, useState} from "react";
import InputPrecioPanel from "../../InputPrecioPanel";
import TableReportes from "../TableReportes";
import { connect } from "react-redux";
import { moneyToValue } from "../../../services/functions";
import {setRutaRep} from '../../../redux/Actions';

const ReporteLineas = ({PropsReportes,Datos,setHeader,setRutRep}) => {
    const [ventas,setVentas]=useState([]);
    const nameComponent = "inventarioLineas",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  
  
    function getInventario() {
      const  strData = Datos["inventarioCategoria"][0];
      const reporte={};
      strData.map(rep=>{
        for(let marca of Object.keys(rep)){
            if(typeof rep[marca] === "object" && !Array.isArray(rep[marca])){
                if(!reporte[marca]){reporte[marca]={ total: 0, existencia:0, cantidadComprada:0, cantidadVendida:0, compras:0, ventas:0, qohb:0, nombre:marca }; }
                for(let line of Object.keys(rep[marca])){
                    if(typeof rep[marca][line] === "object" && !Array.isArray(rep[marca][line])){                           
                        if(!reporte[marca][line]){
                            reporte[marca][line]={
                            total: rep[marca][line].total, 
                            existencia:rep[marca][line].existencia, 
                            cantidadComprada:rep[marca][line].cantidadComprada, 
                            cantidadVendida:rep[marca][line].cantidadVendida, 
                            compras:rep[marca][line].compras, 
                            ventas:rep[marca][line].ventas,
                            qohb:rep[marca][line].qohb,
                            marca: marca,
                            nombre: line
                        }
                        }else{
                            reporte[marca][line].total+=rep[marca][line].total;
                            reporte[marca][line].existencia+=rep[marca][line].existencia;
                            reporte[marca][line].cantidadComprada+= rep[marca][line].cantidadComprada;
                            reporte[marca][line].cantidadVendida+= rep[marca][line].cantidadVendida;
                            reporte[marca][line].compras+= rep[marca][line].compras;
                            reporte[marca][line].ventas+= rep[marca][line].ventas;
                            reporte[marca][line].qohb+= rep[marca][line].qohb;
                        }
                        reporte[marca].total+=rep[marca][line].total;
                        reporte[marca].existencia+=rep[marca][line].existencia;
                        reporte[marca].cantidadComprada+= rep[marca][line].cantidadComprada;
                        reporte[marca].cantidadVendida+= rep[marca][line].cantidadVendida;
                        reporte[marca].compras+= rep[marca][line].compras;
                        reporte[marca].ventas+= rep[marca][line].ventas;
                        reporte[marca].qohb+= rep[marca][line].qohb;      
                    }
                }
            }
        }
      })
      let aux=[];
      for(let marca of Object.keys(reporte)){
        aux.push(reporte[marca]);
      }
      
      setVentas(aux);
  }
  
    useEffect(() => {
      if(rutaActualRep==nameComponent){return;}
      getInventario();
      /*const datos = Datos[nameComponent]||{};
      if(JSON.stringify(datos)=='{}'){
        getInventario();
      }else{
        if(PropsReportes.fecha_Inicial==datos[1].fechai&&PropsReportes.fecha_Final==datos[1].fechaf){
          setVentas(datos[0]);
          return;
        }
        getInventario();
      }*/
    }, [PropsReportes.rutas]);
  
  
    return (
      <>
        <TableReportes
          searchinput={false}
          columns={[
            {
              header: "Brand",
              width:"w-24",
                          // eslint-disable-next-line react/display-name
              code: (item,index) => (
                <div 
                className="w-full flex justify-center text-center cursor-pointer"
                onClick={e=>{
                  if(e.detail==2){
                    setHeader({...PropsReportes.header,marca:item,text:"Inventory by Brand"})
                    setRutaRep("inventarioMarca");
                  }
                }}
                >
                  <input
                  type="text"
                  className="border-table w-full text-center cursor-pointer"
                  readOnly={true}
                  value={item?.nombre}
                  />
                </div>
              )
            },
            {
              header: "QohBgn",
              width:"w-32",
              // eslint-disable-next-line react/display-name
              code: (item,index) => (
                 <InputPrecioPanel
                  width="w-full"
                  noShowSignal={true}
                  newValor={moneyToValue(item?.qohb)}
                  readOnly={true}
                  setValor={(valor) => {}}
                 />
              ),
            },
            {
              header: "Rcvd",
              width:"w-28",
              // eslint-disable-next-line react/display-name
              code: (item,index) => (
                  <InputPrecioPanel
                  width="w-full"
                  bgtext="text-blue-500"
                  noShowSignal={true}
                  newValor={moneyToValue(item?.cantidadComprada)}
                  readOnly={true}
                  setValor={(valor) => {}}
                 />
              ),
            },
            {
              header: "Sold",
              width:"w-28",
              // eslint-disable-next-line react/display-name
              code: (item,index) => (
                  <InputPrecioPanel
                  width="w-full"
                  noShowSignal={true}
                  bgtext="text-pink-700"
                  newValor={moneyToValue(item?.cantidadVendida)}
                  readOnly={true}
                  setValor={(valor) => {}}
                 />
              ),
            },
            {
              header: "Qoh End",
              width:"w-32",
              // eslint-disable-next-line react/display-name
              code: (item,index) => (
                <InputPrecioPanel
                  width="w-full"
                  noShowSignal={true}
                  newValor={moneyToValue(item?.existencia)}
                  readOnly={true}
                  setValor={(valor) => {}}
                 />
              ),
            },
            {
              header: "Amount",
              width:"w-40",
              // eslint-disable-next-line react/display-name
              code: (item,index) => (
                  <InputPrecioPanel
                  min={0}
                  width="w-full"
                  newValor={item?.total}
                  readOnly={true}
                  disabled={true}
                  setValor={(valor) => {}}
                 />
              ),
            },
          ]}
          footer={[
            { 
              width:"bg-gray-100",
              code: <label className="font-semibold">Total:</label>
            },
            {
              width:"bg-gray-100",
              // eslint-disable-next-line react/display-name
              code:  <InputPrecioPanel 
              width="w-full bg-red-200" 
              noShowSignal={true}
              min={0} 
              newValor={moneyToValue(ventas.reduce((a, b) => parseInt(a) + parseInt(b.qohb), 0))} 
              readOnly={true} 
              setValor={e=>{}}
              />
            },
            {
              width:"bg-gray-100",
              // eslint-disable-next-line react/display-name
              code:  <InputPrecioPanel 
              width="w-full" 
              noShowSignal={true}
              min={0} 
              newValor={moneyToValue(ventas.reduce((a, b) => parseInt(a) + parseInt(b.cantidadComprada), 0))} 
              readOnly={true} 
              setValor={e=>{}}/>
            },
            {
              width:"bg-gray-100",
              // eslint-disable-next-line react/display-name
              code:  <InputPrecioPanel 
              width="w-full" 
              noShowSignal={true}
              min={0} 
              newValor={moneyToValue(ventas.reduce((a, b) => parseInt(a) + parseInt(b.cantidadVendida), 0))} 
              readOnly={true} 
              setValor={e=>{}}/>
            },
            {
              width:"bg-gray-100",
              // eslint-disable-next-line react/display-name
              code:  <InputPrecioPanel 
              width="w-full" 
              noShowSignal={true}
              min={0} 
              newValor={moneyToValue(ventas.reduce((a, b) => parseInt(a) + parseInt(b.existencia), 0))} 
              readOnly={true} 
              setValor={e=>{}}/>
            },
            {
              width:"bg-gray-100",
               // eslint-disable-next-line react/display-name
               code:  <InputPrecioPanel 
               width="w-full" 
               min={0} 
               newValor={ventas.reduce((a, b) => parseInt(a) + parseInt(b.total), 0)} 
               readOnly={true} 
               setValor={e=>{}}/>
            }
          ]}
          data={ventas}
        />
      </>
    );
  };
  
  const mapDispatchToProps = (dispatch) => ({
    setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
    setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
    addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  });
  const mapStateToProps = (state) => ({
    PropsReportes: state.PropsReportes,
    Datos: state.Datos,
  });

export default connect (mapStateToProps,mapDispatchToProps)(ReporteLineas);
