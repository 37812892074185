import React, { useState,useEffect} from "react";
import { connect } from "react-redux";
import { FechaLocalToUTC, FechaUTCToLocal, GetFechaActual, getArrayAsync, getSucursal, getTotal, tipoCelda, valueToMoney2 } from "../../../services/functions";
import Modalinfo from "../../modal/info";
import LoadingModal from "../../modal/LoadingModal";
import TableReactWindows from "../Cash/TableReactWindows";
import Modalsuccess from "../../modal/success";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";
import Modalgeneric from "../../modal/generic";
import Modaldanger from "../../modal/danger";
import { useRef } from "react";

const ReporteVentasCreditoDay = ({PropsReportes,setHeader,addDatos,Datos}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalError,setModalError]=useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [ventas,setVentas]=useState([]),
  [modalFecha,setModalFecha]=useState({});
  const inputFecha = useRef("");
  const nameComponent = "ventasFolio",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const header = PropsReportes.header||{};


  const handleError = (error) => {
    closemodalLoading();
    setModalError({isOpen:true,title:"Error de Red",message:error.message||error});
  }
  const closeModalError=()=>{ setModalError({isOpen:false}); }

  const openModalFecha=()=>{setModalFecha({isOpen:true})};
  const closeModalFecha=()=>{setModalFecha({isOpen:false})};
  const closeModalinfo=(e)=> {setmodalinfoConfig({ isOpen: false });}
  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }



  const getVentasAsync=async()=>{
    const fecha = inputFecha.current.value;
    const peticionVentas={
      indexName:"reverseIndex",
      hashKey:{valor:("VENTA#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[FechaLocalToUTC(fecha),FechaLocalToUTC(fecha,23,59,59)],operador:"between",nombre:"id"},
      filters:[{valor:'',operador:'exists',nombre:'pagado'}]
    },
    peticionPes={
      indexName:"reverseIndex",
      hashKey:{valor:("PE#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[getSucursal()+"#"+FechaLocalToUTC(fecha),getSucursal()+"#"+FechaLocalToUTC(fecha,23,59,59)],operador:"between",nombre:"id"},
    };
    const creditos = {};
    try{
      openmodalLoading();

      const ventas = await getArrayAsync(peticionVentas,[]);
      ventas.forEach(venta=>{
        const cliente = venta.cliente;
        let pagado = 0;
        if(!creditos?.[cliente]){creditos[cliente]={cliente:cliente,nombre:venta.nombreCliente,total:0};}
        venta.pagos.forEach(pago=>{
          if(FechaUTCToLocal(pago.fecha).fecha==fecha){ pagado += pago.monto; }
        });
        if(pagado<venta.total){creditos[cliente].total += (venta.total-pagado); }
      });


      const respuesta = await getArrayAsync(peticionPes,[]);
      const pedidos = respuesta.filter(pes=>(!['CANCELADO'].includes(pes.status)));
      pedidos.forEach(pe=>{
        const cliente = pe.cliente;
        let pagado = 0;
        if(!creditos?.[cliente]){creditos[cliente]={cliente:cliente,nombre:pe?.nombreCliente||"",total:0};}
        pe.pagos.forEach(pago=>{
          if(FechaUTCToLocal(pago.fecha).fecha==fecha){ pagado += pago.monto; }
        });
        if(pagado<pe.importe){creditos[cliente].total += (pe.importe-pagado); }
      })

      
      let newArreglo = Object.values(creditos).filter(venta=>(venta.total))
      //console.log('==',newArreglo)
      setVentas(newArreglo);
      closemodalLoading();

    }catch (error){
      console.log('error')
      closemodalLoading();
      handleError(error)
    }
  }

  /*useEffect(() => {
    //if(rutaActualRep!==nameComponent){return;}
    const datos = Datos[nameComponent]||{};

    if(JSON.stringify(datos)=='{}'){
      getVentasAsync();
      return;
    }
    if(PropsReportes.header.folio==datos[1].folio){
      setVentas(datos[0]);
      return;
    }
      getVentasAsync();
  }, []);*/

  /*useEffect(() => {
    if(rutaActualRep!==nameComponent){return;}
    setHeader({...PropsReportes.header,text:"Sales Detail"});
  }, []);*/

  useEffect(() => {
    openModalFecha();
  }, [])
  

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modaldanger config={modalError} closeModal={closeModalError}>
        <Modaldanger.Message title={modalError?.title}>
          {modalError.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
          className="btn-gray2"
          tabIndex="200"
          onClick={closeModalError}>Cerrar</button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalgeneric bg="bg-bluei text-white" w="size-w-20" h="size-h-20" config={modalFecha} front="z-30" closeModal={closeModalFecha}>
        <Modalgeneric.Body>
          <div className="size-4 items-center ">
            <label className="font-semibold text-size">Fecha:</label>
            <input 
            type="date" 
            ref={inputFecha}
            max={GetFechaActual().fecha}
            min={'2023-01-01'} 
            className="border-form col-span-3" 
            defaultValue={GetFechaActual().fecha}
            onBlur={e=>{
              const fecha = e.target.value;
              if(fecha<"2023-01-01"){e.target.value = "2023-01-01"}
              if(fecha>GetFechaActual().fecha){e.target.value = GetFechaActual().fecha}
            }}/>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
          className="btn-blue1"
          onClick={e=>{
            getVentasAsync();
            closeModalFecha();
          }}>Aplicar</button>
          <button
          name="modalgeneric"
          className="btn-gray2"
          onClick={closeModalFecha}>
            Cerrar
          </button>
          
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>


      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
      <div className="block w-full text-center font-bold">{"Reporte Creditos AR de la fecha "+inputFecha.current.value}</div>

      <TableReactWindows
        btnDownload={async(e,datos)=>{
          const data = datos||ventas;
          if(!data?.length){handleError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            handleError(workb.error);
            return
          }

          const nameFile="Credito#"+getSucursal()+"-"+inputFecha.current.value,
          headerFile="Reporte de Credito de la fecha: "+inputFecha.current.value ,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={'Cliente':10,'Credito':17,'Nombre':60};
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((venta,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.cliente||"");
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(valueToMoney2(venta?.total||0));
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(venta?.nombre||"");
          });
      
          sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[1]+sizeData).style(formatoCelda.contabilidad);
  
          
          const response =await SaveXlsx(workb,nameFile,true);
          if(response?.error){
            closemodalLoading();
            handleError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
          
        }}
        columns={[
          {
            key:"cliente",
            header: "Client",
            width:100,
            className:"text-blue-800",
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"total",
            header: "Credit",
            width:120,
            type:tipoCelda.money,
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"nombre",
            header: "Name",
            width:400,
            type:tipoCelda.text,
            filter:true,
          }
        ]}

        data={ventas}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteVentasCreditoDay);
