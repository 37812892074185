import React, { useState, useEffect} from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import { useCustomQuery } from "../../../Hooks/useApis";
import Modalinfo from "../../modal/info";
import LoadingModal from "../../modal/LoadingModal";
import { FechaUTCToLocal, TransformIndex, apiUsePromise, getSucursal, getTotal, tipoCelda, valueToMoney2 } from "../../../services/functions";
import TableReactWindows from "../Cash/TableReactWindows";
import { cloneDeep } from "lodash";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";
import Modalgeneric from "../../modal/generic";
import Modaldanger from "../../modal/danger";
import InOut from "../Modales/InOut";

const ReporteComprasFolio = ({PropsReportes,Datos,addDatos,setRutaPrev,setPropsInventarioNew,setCleanInventario,setHeader}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modalError,setModalError]=useState({}),
  [idCompra,setIdCompra]=useState("");
  const nameComponent ="comprasFolio";
  const compra=useRef([]);
  const headerCompra = Datos?.[nameComponent]?.[1]?.info ||{};
  const rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];


  const funcionExito=(itemsCompra,buy)=>{
    let newCompra ={...itemsCompra}
      buy.items.forEach(item=>{
        newCompra.items.push({...item,
          maker: item.id.substring(0,2),
          style: item.id.substring(2,item.id.length),
        })
      })
      newCompra.compra = {...buy.compra};
     
    return newCompra;
  }
  const funcionExitoDevolucion=(itemsCompra,buy)=>{
    let newCompra ={...itemsCompra}
      buy.items.forEach(item=>{
        newCompra.items.push({...item,
          maker: item.id.substring(0,2),
          style: item.id.substring(2,item.id.length),
        })
      })
      newCompra.compra = {...buy.traspaso};
     
      return newCompra;
  }

  const handleError = (error) => { 
    closemodalLoading();
    setModalError({isOpen:true,title:"Error al obtener Datos",message:error.message||error})
  }
  const closeModalError=()=>{
    setModalError({isOpen:false})
  }


  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }

  const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, title: titulo||"" });};
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false });};
  
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }



  useEffect(async() => {
    if(rutaActualRep!==nameComponent){return;}
    const peticion ={ id: PropsReportes.header.folio, sucursal: getSucursal() };
    let itemsCompra = {compra:{},items:[]};

    const getCompra=async(petLast)=>{
      if(petLast){peticion.LastEvaluatedKey = petLast;}
      const responseCompra= await apiUsePromise({ url: "/get-compra", method: "POST",data: peticion});
      const buy = responseCompra.data;
      itemsCompra = funcionExito(itemsCompra,buy);
      if(responseCompra.data?.LastEvaluatedKey){
        await getCompra(responseCompra.data.LastEvaluatedKey);
      }
    }
    const getTraspaso=async(petLast)=>{
      if(petLast){peticion.LastEvaluatedKey = petLast;}
      const responseTraspaso= await apiUsePromise({ url: "/get-traspaso", method: "POST",data: peticion});
      const buy = responseTraspaso.data;
      itemsCompra = funcionExitoDevolucion(itemsCompra,buy);
      if(responseTraspaso.data?.LastEvaluatedKey){
        await getTraspaso(responseTraspaso.data.LastEvaluatedKey);
      }
    }

    const apiCompra=async()=>{
      openmodalLoading("Recuperando Traspaso");
      if(PropsReportes.header?.type=="TRASPASO#"+getSucursal()){
        await getTraspaso();
      }else{
        await getCompra();
      }
      compra.current = itemsCompra.items;
      setHeader({...PropsReportes.header,btns:{edit:true,new:true,direction:true}})
      setIdCompra(itemsCompra.compra.id);
      addDatos(nameComponent,[itemsCompra.items,{folio:itemsCompra.compra.shortId,info:itemsCompra.compra}]);
      closemodalLoading();
    }
    const datos = Datos[nameComponent]||{};
    if(JSON.stringify(datos)=='{}'){
     apiCompra();
    }else{
      if(datos[1].folio==PropsReportes.header?.folio){
        compra.current = datos[0];
        setIdCompra(datos[1].info.id);
        //addDatos(nameComponent,[datos[0],{folio:datos[1]?.info.shortId,info:datos[1]?.info}]);
        setHeader({...PropsReportes.header,btns:{edit:true,new:true,direction:true}})
        closemodalLoading();
        return;
      }
      apiCompra();
    }
    
  }, [PropsReportes.rutas,PropsReportes.header?.folio]);



  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>{modalLoading.title||"Cargando Datos"}</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modaldanger config={modalError}>
        <Modalinfo.Message title={modalError.title||"Error al obtener datos"}>
          {modalError.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalError}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modaldanger>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <TableReactWindows
        btnDownload={async(e,datos)=>{
          const data = datos||compra.current;
          if(!data?.length){handleError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            handleError(workb.error);
            return
          }

          const nameFile="Compra-"+PropsReportes?.header?.folio,
          headerFile="Compra: "+PropsReportes?.header?.folio+"     Proveedor: "+PropsReportes?.header?.proveedor+"     Fecha: "+PropsReportes?.header?.day,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={'Maker':7,'Style':17,'Descripcion':60,'Cant':7,'Costo':15,'importe':15};
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((compra,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(compra?.maker||"");
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(compra?.style||"");
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(compra?.descripcion||"");
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(compra?.cantidadComprada||0);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(compra?.precioCompra||0));
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(compra?.importe||0));
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
          });
      
          sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
      
          sheet1.cell(columnasXlsx[4]+(sizeData+2)).value("Descuento");
          sheet1.cell(columnasXlsx[4]+(sizeData+2)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet1.cell(columnasXlsx[4]+(sizeData+3)).value("Envio");
          sheet1.cell(columnasXlsx[4]+(sizeData+3)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet1.cell(columnasXlsx[4]+(sizeData+4)).value("Total");
          sheet1.cell(columnasXlsx[4]+(sizeData+4)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
      
          sheet1.cell(columnasXlsx[5]+(sizeData+2)).value(valueToMoney2(headerCompra?.descuento||0));
          sheet1.cell(columnasXlsx[5]+(sizeData+2)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+(sizeData+3)).value(valueToMoney2(headerCompra?.envio||0));
          sheet1.cell(columnasXlsx[5]+(sizeData+3)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+(sizeData+4)).formula(""+columnasXlsx[5]+sizeData+"-"+columnasXlsx[5]+(sizeData+2)+"+"+columnasXlsx[5]+(sizeData+3));
          sheet1.cell(columnasXlsx[5]+(sizeData+4)).style(formatoCelda.contabilidad);
          
          const response =await SaveXlsx(workb,nameFile,true);
          if(response?.error){
            closemodalLoading();
            handleError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
        }}
        columns={[
          {
            key:"maker",
            header: "Maker",
            width:60,
            type: tipoCelda.text,
            className: "text-blue-800",
            filter:true,
          },
          {
            key:"style",
            header: "Style",
            width:150,
            filter:true,
            onClick:({row,e})=>{
              if(e.detail>1){return;}
              const propsHeader = {...PropsReportes.header};
              propsHeader.inout = row.linea.split('#')[0];
              setHeader(propsHeader);
            },
            // eslint-disable-next-line react/display-name
            content:({row,index})=>(
              <div  className="flex flex-wrap gap-1 w-full justify-between items-center">
               <p key="index"
                type="text"
                className={" cursor-pointer text-blue-700 text-center "}
                >{row.style}</p>
                <button
                  className={row.type === 'PRODUCT' ||row?.inventarioPendiente==false?"hidden":"text-blue-600 "}
                    onClick={(e) => {
                      openmodalLoading("Recuperando inventario");
                      let peticion ={
                        hashKey:{valor:"COMPRA#"+getSucursal()+"#INVENTARIO#"+row.id,nombre:"id"},
                        sortKey:{valor:idCompra,operador:"eq",nombre:"type"}
                      }
                      useCustomQuery(peticion,resp=>{
                        const compra = Datos[nameComponent][1].info;
                        const PropsInventory = {idcompra:compra.id, precioCompra:row.precioCompra, cantidad:row.cantidadComprada, linea:row.id, inventario:[]};
                        const inv = resp.datos[0];
                        //console.log(inv);
                        if(inv?.inventario){PropsInventory.inventario = inv.inventario; }

                        setCleanInventario();
                        setPropsInventarioNew(PropsInventory);
                        setRutaPrev(["receivingFolio","inventario"]);
                      },error=>{
                        handleError(error);
                      });
                      
                    }}
                  >
                  <i className={(row?.id.substring(0,2)=="MI"||row?.id.substring(0,2)=="LC"?"fas fa-border-all":"fas fa-glasses")}></i>
                </button>
                <button
                  className={row?.inventarioPendiente==false?"text-green-600 text-xs":"hidden"}
                    onClick={(e) => { 
                      //openmodalLoading();
                      let peticion ={
                        hashKey:{valor:"COMPRA#"+getSucursal()+"#INVENTARIO#"+row.id,nombre:"id"},
                        sortKey:{valor:idCompra,operador:"eq",nombre:"type"}
                      }
                      //console.log(peticion)
                      useCustomQuery(peticion,resp=>{
                        const compra = cloneDeep(Datos[nameComponent][1].info);
                        const PropsInventory = {idcompra:compra.id, precioCompra:row.precioCompra, cantidad:row.cantidad, linea:row.id, inventario:[],inv_completo:true};
                        const inv = resp.datos[0];
                        
                        PropsInventory.inventario = inv.inventario;
                        setCleanInventario();
                        setPropsInventarioNew(PropsInventory);
                        setRutaPrev(["receivingFolio","inventario"]);
                      },error=>{
                        handleError(error);
                      });
                      
                    }}
                  >
                  <i className="fas fa-check"></i>
                </button>
            </div>
            ),
          },
          {
            key:"descripcion",
            header: "Description",
            width:650,
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"cantidadComprada",
            header: "Qty",
            width:72,
            type:tipoCelda.number,
            className:"text-right text-blue-700",
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"precioCompra",
            header: "UnitCost",
            width:115,
            type:tipoCelda.money,
            filter:true,
          },
          {
            key:"precioVenta",
            header: "UnitPrice",
            width:120,
            type:tipoCelda.money,
            filter:true,
          },
          {
            key:"importe",
            header: "Amount",
            width:120,
            type:tipoCelda.money,
            filter:true,
            footer:true,
            function:getTotal,
          }
        ]}

        data={compra.current}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRutaPrev:(rutas)=>dispatch({type:"SET_PREV_RUTA",rutas:rutas}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  setPropsInventarioNew: (estado) => dispatch({type:"SET_NEW_PROPSINVENTARIO", estado:estado}),
  setCleanInventario: () => dispatch({type: 'RESET_INVENTARIO'}),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
  propsInventario: state.PropsInventario,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteComprasFolio);