import React from "react";

const Labelerror = (props) => {
  const namefield = props.children.props.name || "";
  return (
    <>
      {props.children}
      <p className={" text-sm italic font-semibold break-words w-full "+(props?.text?"text-yellow-300":"text-red-500")}>
        {props.error[namefield]?.message}
      </p>
    </>
  );
};

export default Labelerror;
