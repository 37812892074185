import React, { useEffect } from "react";
import Labelmoney from '../labelmoney';
import { useState } from "react";
import TableModal from "../modal/TableModal";
import LabelFecha from "../LabelFecha";

const TablaCreditoProveedor = ({ comprasCredito, onclickRow = (item) => {} }) => {
  const [totales,setTotales]=useState({});

  useEffect(()=>{
    if(!comprasCredito){return;}
    setTotales({...totales, 
      cantidad:comprasCredito.reduce((total,compra)=> parseFloat(total) + parseFloat(compra.cantidadTotal), 0),
      importe: comprasCredito.reduce((total,compra)=> parseFloat(total) + parseFloat(compra.importe), 0),
      pagado: comprasCredito.reduce((total,compra)=> parseFloat(total) + parseFloat(compra.importe-compra.pagado), 0),
    })
  },[comprasCredito])

  return (
    <div className="flex flex-col w-full flex-wrap my-1 mx-2">
      <TableModal
        columns={[
          {
            header: "# COMPRA",
            width:"w-1/12",
            accessor: "shortId"
            // eslint-disable-next-line react/display-name
            //code: (item) => <label>{generate_id(item.id)}</label>,
          },
          {
            header: "Fecha",
            width:"w-2/12",
            // eslint-disable-next-line react/display-name
            code: (item) => <LabelFecha font="font-size" hour={true}>{item.id}</LabelFecha>
            //accessor: "id",
          },
          {
            header: "Cantidad",
            width:"w-1/12 text-center items-center",
            // eslint-disable-next-line react/display-name
            accessor: "cantidadTotal",
          },
          {
            header: "SubTotal",
            width: "w-2/12",
            // eslint-disable-next-line react/display-name
            code: (item) => <Labelmoney font="font-size" twoDecimal={true}>{item.subtotal}</Labelmoney>,
          },
          {
            header: "Freigth",
            width: "w-1/12",
            // eslint-disable-next-line react/display-name
            code: (item) => <Labelmoney font="font-size" twoDecimal={true}>{item?.envio||0}</Labelmoney>,
          },
          {
            header: "Discount",
            width: "w-1/12",
            // eslint-disable-next-line react/display-name
            code: (item) => <Labelmoney font="font-size" twoDecimal={true}>{item?.descuento||0}</Labelmoney>,
          },
          {
            header: "Importe",
            width: "w-2/12",
            // eslint-disable-next-line react/display-name
            code: (item) => <Labelmoney font="font-size" twoDecimal={true}>{item?.importe||0}</Labelmoney>,
          },
          {
            header: "Por Pagar",
            width: "w-2/12",
            // eslint-disable-next-line react/display-name
            code: (item) => <Labelmoney font="font-size" twoDecimal={true}>{item.importe - item.pagado}</Labelmoney>,
          },
          {
            header: "#Invoice",
            width:"w-48",
            accessor: "referencia"
          }
        ]}
        onclickRow={(item) => {
          onclickRow(item);
        }}
        data={comprasCredito}
        footer={[
          {
          },
          {
            code: <label className="text-rigth">Totales:</label>
          },
          {
            width: "w-1/12",
            // eslint-disable-next-line react/display-name
            code:  <label className="font-semibold">{comprasCredito?totales.cantidad:""} </label>
          },
          {
            width: "w-1/12",
            // eslint-disable-next-line react/display-name
            code:  <Labelmoney font="font-size" twoDecimal={true}>{comprasCredito?totales.subtotal:""} </Labelmoney>
          },
          {
            width: "w-1/12",
            // eslint-disable-next-line react/display-name
            code:  <Labelmoney font="font-size" twoDecimal={true}>{comprasCredito?totales.envios:""} </Labelmoney>
          },
          {
            width: "w-1/12",
            // eslint-disable-next-line react/display-name
            code:  <Labelmoney font="font-size" twoDecimal={true}>{comprasCredito?totales.descuento:""} </Labelmoney>
          },
          {
            width: "w-2/12",
            // eslint-disable-next-line react/display-name
            code:  <Labelmoney font="font-size" twoDecimal={true}>{comprasCredito?totales.importe:""} </Labelmoney>
          },
          {
            width: "w-2/12",
            // eslint-disable-next-line react/display-name
            code:  <Labelmoney font="font-size" twoDecimal={true}>{comprasCredito?totales.pagado:""} </Labelmoney>
          },
          {}
        ]}
      />
    </div>
  );
};

export default TablaCreditoProveedor;
