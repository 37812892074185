import React from 'react'
import Modalgeneric from './generic';
import { useState } from 'react';
import { AddDiasFecha, GetFechaActual, GetFirstLastWeek, GetFirtsLastMonth, RestaFechas, Sucursal, fechaMax, validaFecha } from '../../services/functions';
import { useRef } from 'react';
import { connect } from 'react-redux';
import { useEffect } from 'react';

function CalendarModal({PropsReportes,setFechas,state,setState,openmodal,closemodal}) {
  const [modalgenericonfig, setmodalgenericonfig] = useState({}),
  [fecha,setFecha]=useState({fechai:PropsReportes.fecha_Inicial,fechaf:PropsReportes.fecha_Final});

  const input_fechaf = useRef(""),input_fechai=useRef("");


  const openmodalgeneric = (e) => {
    setmodalgenericonfig({ isOpen: true });
  };
  const closemodalgeneric = (e) => {
      setmodalgenericonfig({ isOpen: false });
      closemodal(false);
  };


  function getFechaCero(fecha) {
      const new_fecha = new Date(fecha+" 00:00:00");
      return {año:new_fecha.getFullYear(),mes:new_fecha.getMonth(),dia:new_fecha.getDate()};
  }

  useEffect(()=>{
    if(openmodal){
        let fechai = PropsReportes.fecha_Inicial,fechaf = PropsReportes.fecha_Final;
        if(state?.fecha_Inicial&&state?.fecha_Final){
            fechai = state.fecha_Inicial;
            fechaf = state.fecha_Final;
        }
        setFecha({fechai:fechai,fechaf:fechaf});
        openmodalgeneric();
    }
  },[openmodal])
  
  useEffect(()=>{
    input_fechaf.current.value = fecha.fechaf;
    input_fechai.current.value = fecha.fechai;
  },[fecha])


  return (
    <>
    <Modalgeneric w="size-w-50" h="size-h-80" bg="bg-mesh" front="z-30" config={modalgenericonfig}>
        <Modalgeneric.Title text="text-white text-2xl">DATE RANGE</Modalgeneric.Title>
        <Modalgeneric.Body >
          <div className=" flex flex-col md:flex-row gap-2 items-center  w-full h-full ">
            <div className='flex flex-col w-full items-center rounded-md gap-1 bg-white   '>
                <label className='bg-ambar-100 font-bold w-full text-center rounded-md'>Beginning Date</label>
                <div className='flex flex-wrap md:flex-none md:grid grid-rows-1 grid-cols-3 w-full justify-center  '>
                    <button className='w-24 md:w-auto btn-free border py-2 border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                     onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechai);
                        let date = new Date(new_fecha.año-1,new_fecha.mes,new_fecha.dia).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,"2023-01-01")>0?setFecha({...fecha,fechai:"2023-01-01"}):setFecha({...fecha,fechai:date});
                    }}>
                        <i className='fas fa-arrow-circle-left text-xl'></i>
                    </button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 '>YEAR</button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black '
                      onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechai);
                        let date = new Date(new_fecha.año+1,new_fecha.mes,new_fecha.dia).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,fecha.fechaf)<0?"":setFecha({...fecha,fechai:date});
                    }}>
                        <i className='fas fa-arrow-circle-right text-xl'></i>
                    </button>
                </div>
                <div className='flex flex-wrap md:flex-none md:grid grid-rows-1 grid-cols-3 w-full justify-center '>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black '
                     onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechai);
                        let date = new Date(new_fecha.año,new_fecha.mes-1,new_fecha.dia).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,"2023-01-01")>0?"":setFecha({...fecha,fechai:date});
                    }}>
                        <i className='fas fa-arrow-circle-left text-xl'></i>
                        </button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 '>MONTH</button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                     onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechai);
                        let date = new Date(new_fecha.año,new_fecha.mes+1,new_fecha.dia).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,fecha.fechaf)<0?"":setFecha({...fecha,fechai:date});
                    }}>
                        <i className='fas fa-arrow-circle-right text-xl'></i>
                    </button>
                </div>
                 <input
                    type="date"
                    max={fecha.fechaf}
                    min={'2023-01-01'}
                    className="border-form-free bg-ambar-100 text-center text-lg w-full font-bold"
                    defaultValue={fecha.fechai}
                    ref={input_fechai}
                    onInput={e=>{
                        if(e.target.validity.valid){
                            setFecha({...fecha,fechai:e.target.value});
                        }else{
                            e.target.value = fecha.fechai;
                        }
                    }}
                />
                 <div className='flex flex-wrap md:flex-none md:grid grid-rows-1 grid-cols-3 w-full justify-center '>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black '
                     onClick={e=>{
                        let date = GetFirstLastWeek(fecha.fechai).primer;
                        if(date==fecha.fechai){
                            const new_fecha = getFechaCero(date);
                            date = GetFirstLastWeek(new Date(new_fecha.año,new_fecha.mes,new_fecha.dia-1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')})).primer;
                        }
                        RestaFechas(date,"2023-01-01")>0?"":setFecha({...fecha,fechai:date});
                    }}>
                        <i className='fas fa-arrow-circle-left text-xl'></i>
                        </button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200'>WEEK</button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                     onClick={e=>{
                        const new_fecha = getFechaCero(GetFirstLastWeek(fecha.fechai).ultimo);
                        let date = new Date(new_fecha.año,new_fecha.mes,new_fecha.dia+1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,fecha.fechaf)<0?"":setFecha({...fecha,fechai:date});
                    }}>
                        <i className='fas fa-arrow-circle-right text-xl'></i>
                    </button>
                </div>
                <div className='flex flex-wrap md:flex-none md:grid grid-rows-1 grid-cols-3 w-full justify-center '>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black '
                    onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechai);
                        let date = new Date(new_fecha.año,new_fecha.mes,new_fecha.dia-1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,"2023-01-01")>0?"":setFecha({...fecha,fechai:date});
                    }}>
                        <i className='fas fa-arrow-circle-left text-xl'></i>
                    </button>
                   <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                    onClick={e=>{
                        const new_fecha = GetFechaActual().fecha;
                        RestaFechas(new_fecha,fecha.fechaf)<0?"":setFecha({...fecha,fechai:new_fecha});
                    }}>
                        DAY
                    </button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                    onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechai);
                        let date = new Date(new_fecha.año,new_fecha.mes,new_fecha.dia+1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,fecha.fechaf)<0?"":setFecha({...fecha,fechai:date});
                    }}>
                        <i className='fas fa-arrow-circle-right text-xl'></i>
                    </button>
                </div>
            </div>


            <div className='flex flex-col w-full items-center rounded-md gap-1 bg-white'>
                <label className='bg-ambar-100 font-bold w-full text-center rounded-md'>Ending Date</label>
                <div className='flex flex-wrap md:flex-none md:grid grid-rows-1 grid-cols-3 w-full justify-center '>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                     onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechaf);
                        let date = new Date(new_fecha.año-1,new_fecha.mes,new_fecha.dia).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,fecha.fechai)>0?"": setFecha({...fecha,fechaf:date});
                    }}>
                        <i className='fas fa-arrow-circle-left text-xl'></i>
                    </button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200  '>YEAR</button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                      onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechaf);
                        let date = new Date(new_fecha.año+1,new_fecha.mes,new_fecha.dia).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,GetFechaActual().fecha)<0?"": setFecha({...fecha,fechaf:date});
                    }}>
                        <i className='fas fa-arrow-circle-right text-xl'></i>
                    </button>
                </div>
                <div className='flex flex-wrap md:flex-none md:grid grid-rows-1 grid-cols-3 w-full justify-center '>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                     onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechaf);
                        let date = new Date(new_fecha.año,new_fecha.mes-1,new_fecha.dia).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,fecha.fechai)>0?"": setFecha({...fecha,fechaf:date});
                    }}>
                        <i className='fas fa-arrow-circle-left text-xl'></i>
                        </button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200'>MONTH</button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                     onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechaf);
                        let date = new Date(new_fecha.año,new_fecha.mes+1,new_fecha.dia).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,GetFechaActual().fecha)<0?"": setFecha({...fecha,fechaf:date});
                    }}>
                        <i className='fas fa-arrow-circle-right text-xl'></i>
                    </button>
                </div>
                <input
                    type="date"
                    min={fecha.fechai}
                    max={fechaMax}
                    className="border-form-free bg-ambar-100 text-center text-lg w-full font-bold"
                    defaultValue={fecha.fechaf}
                    ref={input_fechaf}
                    onInput={e=>{
                        if(e.target.validity.valid){
                            setFecha({...fecha,fechaf:e.target.value})
                        }else{
                            e.target.value = fechaMax;
                        }
                    }}
                />
                <div className='flex flex-wrap md:flex-none md:grid grid-rows-1 grid-cols-3 w-full justify-center '>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                     onClick={e=>{
                        let date = GetFirstLastWeek(fecha.fechaf).primer;
                        if(date==fecha.fechaf){
                            const new_fecha = getFechaCero(date);
                            date = GetFirstLastWeek(new Date(new_fecha.año,new_fecha.mes,new_fecha.dia-1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')})).primer;
                        }
                        RestaFechas(date,fecha.fechai)>0?"": setFecha({...fecha,fechaf:date});
                    }}>
                        <i className='fas fa-arrow-circle-left text-xl'></i>
                        </button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 '>WEEK</button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                     onClick={e=>{
                        const new_fecha = getFechaCero(GetFirstLastWeek(fecha.fechaf).ultimo);
                        let date = new Date(new_fecha.año,new_fecha.mes,new_fecha.dia+1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,GetFechaActual().fecha)<0?"": setFecha({...fecha,fechaf:date});
                    }}>
                        <i className='fas fa-arrow-circle-right text-xl'></i>
                    </button>
                </div>
                <div className='flex flex-wrap md:flex-none md:grid grid-rows-1 grid-cols-3 w-full justify-center '>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black '
                    onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechaf);
                        let date = new Date(new_fecha.año,new_fecha.mes,new_fecha.dia-1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,fecha.fechai)>0?"": setFecha({...fecha,fechaf:date});
                    }}>
                        <i className='fas fa-arrow-circle-left text-xl'></i>
                    </button>
                   <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                    onClick={e=>{
                        const new_fecha = GetFechaActual().fecha;
                        setFecha({...fecha,fechaf:new_fecha});
                    }}>
                    DAY
                   </button>
                    <button className='w-24 md:w-auto btn-free py-2 border border-gray-400 rounded-md bg-gray-200 hover:bg-ambar-300 text-black'
                    onClick={e=>{
                        const new_fecha =getFechaCero(fecha.fechaf);
                        let date = new Date(new_fecha.año,new_fecha.mes,new_fecha.dia+1).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        RestaFechas(date,GetFechaActual().fecha)<0?"": setFecha({...fecha,fechaf:date});
                    }}>
                        <i className='fas fa-arrow-circle-right text-xl'></i>
                    </button>
                </div>
            </div>


            <div className=' w-full grid grid-flow-row gap-2'>
                <div className='flex flex-row w-full items-center justify-center gap-1'>
                    <button className='btn-free py-2 rounded-md bg-gray-200 hover:bg-ambar-300 text-black h-full '
                    onClick={e=>{
                        const aux_fecha = new Date(fecha.fechaf);
                        const lastMonth = new Date(aux_fecha.getFullYear(), aux_fecha.getMonth() , 0).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        const month = GetFirtsLastMonth(lastMonth);
                        const new_fecha = validaFecha(month.primer,month.ultimo);
                        setFecha({...fecha,fechai:new_fecha.fechai,fechaf:new_fecha.fechaf});
                    }}>
                        <i className='fas fa-chevron-circle-left text-3xl'></i>
                    </button>
                    <button className='btn-free py-2 bg-gray-200 hover:bg-ambar-300 w-28 h-28'
                    onClick={e=>{
                        const week = GetFirtsLastMonth(new Date());
                        const new_fecha = validaFecha(week.primer,week.ultimo);
                        setFecha({...fecha,fechai:new_fecha.fechai,fechaf:new_fecha.fechaf});
                    }}
                    >THIS MONTH</button>
                    <button className='btn-free py-2 rounded-md bg-gray-200 hover:bg-ambar-300 text-black h-full'
                     onClick={e=>{
                        const aux_fecha = new Date(fecha.fechaf);
                        const lastMonth = new Date(aux_fecha.getFullYear(), aux_fecha.getMonth() + 2, 0).toLocaleDateString('fr-ca',{dateStyle:'short',timeZone: Sucursal('timezone')});
                        const month = GetFirtsLastMonth(lastMonth);
                        const new_fecha = validaFecha(month.primer,month.ultimo);
                        setFecha({...fecha,fechai:new_fecha.fechai,fechaf:new_fecha.fechaf});
                    }}>
                        <i className='fas fa-chevron-circle-right text-3xl'></i>
                    </button>
                </div>
                <div className='flex flex-row w-full items-center justify-center gap-1'>
                    <button className='btn-free py-2 rounded-md bg-gray-200 hover:bg-ambar-300 text-black h-full'
                                        onClick={e=>{
                        const week =GetFirstLastWeek(AddDiasFecha(fecha.fechai,-6));
                        const new_fecha = validaFecha(week.primer,week.ultimo);
                        setFecha({...fecha,fechai:new_fecha.fechai,fechaf:new_fecha.fechaf});
                    }}>
                        <i className='fas fa-chevron-circle-left text-3xl'></i>
                    </button>
                    <button className='btn-free bg-gray-200 hover:bg-ambar-300 w-28 h-28'
                    onClick={e=>{
                        const week = GetFirstLastWeek(new Date());
                        const new_fecha = validaFecha(week.primer,week.ultimo);
                        setFecha({...fecha,fechai:new_fecha.fechai,fechaf:new_fecha.fechaf});
                    }}>THIS WEEK</button>
                    <button className='btn-free py-2 rounded-md bg-gray-200 hover:bg-ambar-300 text-black h-full'
                    onClick={e=>{
                        const week =GetFirstLastWeek(AddDiasFecha(fecha.fechaf,6));
                        const new_fecha = validaFecha(week.primer,week.ultimo);
                        setFecha({...fecha,fechai:new_fecha.fechai,fechaf:new_fecha.fechaf});
                    }}>
                        <i className='fas fa-chevron-circle-right text-3xl'></i>
                    </button>
                </div>
            </div>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons bg="bg-mesh">
          <button
            type="button"
            className="btn-blue"
            onClick={(e) => {
                if(state){
                    setState({fecha_Inicial:fecha.fechai,fecha_Final:fecha.fechaf});
                    closemodalgeneric();
                    return;
                }
                setFechas(fecha.fechai,fecha.fechaf);
                closemodalgeneric();
            }}
          >
            Guardar
          </button>
          <button
            type="button"
            className="btn-gray2"
            onClick={closemodalgeneric}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setFechas:(fechai,fechaf)=>dispatch({type:"SET_FECHAS_REP",fechai:fechai,fechaf:fechaf}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarModal)