import React, { useState,useEffect,useRef, useMemo } from "react";
import { eliminarItemCompra, agregarProductoCompra, setItemCompra, setRuta } from "../../redux/Actions";
import { connect } from "react-redux";
import { CalculaMinPrecioVenta, CalcularPorcentajeMargenUtilidad, ceros_decimal, getDescriptionProduct, getSucursal, moneyToValue, redondeo, validProductId, valueToMoney } from "../../services/functions.js";
import Modalinfo from "../modal/info";
import InputPrecioPanel from "../InputPrecioPanel";
import TableCompra from "./TableCompra";
import Modalgeneric from "../modal/generic";
import LoadingModal from "../modal/LoadingModal";
import { useCustomQuery, useGetProduct } from "../../Hooks/useApis";
import TableModal from "../modal/TableModal";
import Labelmoney from "../labelmoney";
import SearchLine from "../Productos/SearchLine";
import {motion} from "framer-motion"
import LabelNumber from "../LabelNumber";
import { itemCompra } from "../../static/dat/datos.js";

const AddProduct=({config})=>{

  const changeProd=(item)=>{
    let prods = [...config.datos];

    prods[config.index].cantidad = item.cantidad;
    prods[config.index].precioCompra =  item.precioCompra;
    config.setdata(prods);
  }

  return (<div key={config.index} className="w-full flex flex-row gap-2 justify-center mt-2">
    <input
    type="text"
    className="border-form w-24"
    key={config.index+"qty"}
    defaultValue={config.item.cantidad}
    onBlur={e=>{
      let cantidad = 0;
      const value = parseInt(e.target.value);
      if(!isNaN(value)){
        let val =value
       if(Number.isInteger(val)){
        cantidad = val;
       }
      }
      changeProd({...config.item,cantidad:cantidad})
    }}
    />
    <InputPrecioPanel
    width="w-28"
    className="bg-white"
    form={true}
    min="0.0001"
    zero={true}
    reset={config.modalAddProducts}
    defaultValue={config.item.precioCompra}
    newValor={config.item.precioCompra}
    setValor={e=>{}}
    onBlur={(e)=>{
      changeProd({...config.item,precioCompra:moneyToValue(e)})
    }}/>
    <button
    className="text-red-400 hover:text-red-300 text-xl mr-2"
    onClick={(e) => {
          config.deletepago(config.index);
    }}>
      <i className="fas fa-times-circle"></i>
    </button>
  </div>)

}

const PanelTablaCompra = ({ Proveedor,itemsCompra, eliminarItemCompra,addRuta,setPropsInventarioNew,HeaderCompra,addItem,setItem,setCantPc,setCleanInventario,setCleanItem}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalTablaConfig, setmodalTablaConfig] = useState(false),
  [modalAddProducts, setModalAddProducts] = useState(false),
  [modalLoading, setmodalLoading] = useState({}),
  [add,setAdd]=useState(undefined),
  [addProd,setAddProd]=useState([{cantidad:0,precioCompra:0,precioCompraIni:0},{cantidad:0,precioCompra:0,precioCompraIni:0}]),
  [dataproduct, setdataproduct] = useState([]);
  const title = useRef("Cargando Informacion"),inputs =useRef({});

  function agregarItem(item) {
    const newitem = {...itemsCompra[0]};

    if(!item?.sucursales?.[getSucursal()]){
      newitem.id=item.id
      newitem.descripcion= getDescriptionProduct(item).descripcion;
      newitem.type = item.type;
      newitem.categoria= item.categoria;
      newitem.marca= item.marca;
      newitem.linea= item.linea;
      newitem.cantidad=1;
    }else{
      const propSucursal = item.sucursales.[getSucursal()];
      const pc = propSucursal.precioCompra ||0;
      //console.log('first')
      if(![true,undefined].includes(propSucursal?.comprable)){  
        title.current = "Producto No valido"
        setmodalinfoConfig({isOpen: true,message: "El Producto "+item.id+" no esta disponible para compra!!! " });
        return
      }

      newitem.id=item.id
      newitem.descripcion= getDescriptionProduct(item).descripcion;
      newitem.type = item.type;
      newitem.categoria= item.categoria;
      newitem.marca= item.marca;
      newitem.linea= item.linea;
      newitem.cantidad=1,
      newitem.comprable = propSucursal.comprable,
      newitem.vendible = propSucursal.vendible,
      newitem.guideline=propSucursal?.guideline||0,
      newitem.oldExistencia=propSucursal?.existencia||0,
      newitem.precioCompra= redondeo(pc),
      newitem.precioCompraPromediado= redondeo(pc),
      newitem.oldPrecioCompra= pc ,
      newitem.minPrecioVenta = pc?CalculaMinPrecioVenta(pc,item.categoria):0,
      newitem.precioVenta = redondeo(propSucursal.precioVenta || 0),
      newitem.importe = newitem.cantidad*newitem.precioCompra;    
    }

    inputs.current["0id"].value = newitem.id;
    inputs.current["0cantidad"].focus();
    inputs.current["0cantidad"]?.select?inputs.current["0cantidad"].select():""
    //console.log('add==>',newitem);
    setItem(newitem);
  }
  function changecantidad(item, e) {
    const val = e.target.value ||e ;
    const cantidad = parseInt(val);
    if(val==""||val==0){
      setCantPc({...item,cantidad:""})
    }else if(!isNaN(cantidad)){
      setCantPc({...item,cantidad:cantidad})
    }
  }

  function changepreciocompra(item, e) {
    let precioCompra = moneyToValue(e);

    setCantPc({...item, precioCompra:precioCompra })
  }

  function changeprecioventa(item,e){
    const precioVenta = parseFloat((parseFloat(e)*ceros_decimal).toFixed(0));
    //console.log('pv: ',precioVenta, 'minPv: ',item.minPrecioVenta, "val: ",e)
    return (item.minPrecioVenta > precioVenta? item.minPrecioVenta: precioVenta)
  }

  function foundProduct (id,campo){
    let index = 0, find = false;
    itemsCompra.forEach(producto=>{
      if (producto.id == id&&producto.precioCompra>0){
        find = true;
        index = producto.index;
      }
    })
    if(find){
      setItem(itemCompra);
      inputs.current["0id"].value = "";
      inputs.current.[index+campo].focus();
      inputs.current[index+campo]?.select?inputs.current[index+campo].select():""
      
    }
    return find;
  }

  const deletePago = (index) => {
    let newPagos = [...addProd];
    let misPagos = [];
    for (let i = 0; i < newPagos.length; i++) {
      if (i !== index) {
        misPagos.push(newPagos[i]);
      }
    }
    setAddProd(misPagos);
  };

  const validaAddProds=()=>{
    let valido = true;
    
    if(addProd.length<2){
      valido = false;
    }
    addProd.forEach(prod=>{
      if(prod.cantidad==0||prod.precioCompra==0){
        valido= false;
      }
    })

    return valido
  }

  const funcionExito = (dataproductos) => {
    const data = dataproductos.productos;
    closemodalLoading();

    if(dataproductos.linea){
      if(HeaderCompra?.traspaso==true){
        title.current ="Lineas no aceptadas en devolucion";
        setmodalinfoConfig({ isOpen: true,message: ("De momento las devoluciones tienes que ser detalladas "+itemsCompra[0]?.id),});
        return
      }
      if(dataproductos.linea?.bloqueado){
        title.current ="Producto Bloquedo";
        setmodalinfoConfig({ isOpen: true,message: ("El producto ha sido dado de baja "+itemsCompra[0]?.id),});
        return;
      }
      if( dataproductos.linea.sucursales?.[getSucursal()]){
        dataproductos.linea.sucursales[getSucursal()].existencia = data.reduce((a, b) => parseInt(a) + parseInt(b.sucursales?.[getSucursal()]?.existencia||0), 0);
      }
      if(foundProduct(dataproductos.linea.id,"cantidad")){return}
      agregarItem(dataproductos.linea);      
    }else{
      if (data.length === 0) {
        title.current ="Producto No Encontrado";
        setmodalinfoConfig({ isOpen: true,message: ("No existe el producto "+itemsCompra[0]?.id),});
        return;
      }
      if (data.length === 1){ 
        agregarItem(data[0]);
        return;
      } 
      if (data.length > 1) {
        setdataproduct(data);
        setmodalTablaConfig({ isOpen: true });
        return;
      }
    }
  };
  const funcionError = (error) => {
    closemodalLoading();
    setmodalinfoConfig({ isOpen: true, message: error?.response?.data?.message || "No existe el codigo",});
  };
  const handleError = (error) => { 
    closemodalLoading();
  }

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
    inputs.current["0id"].focus();
    setItem(itemCompra)
  }
  function closeModaltabla(e) {
    setmodalTablaConfig({ isOpen: false });
    inputs.current["0id"].focus();
    inputs.current["0id"].select();
  }
  function closeModalAddProducts(e) {
    setModalAddProducts({ isOpen: false });
    setAddProd([{cantidad:0,precioCompra:0,precioCompraIni:0},{cantidad:0,precioCompra:0,precioCompraIni:0}]);
  }

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true, title });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };

  useEffect(() => {
    if(add!==undefined){
      inputs.current["0id"].value = "";
      inputs.current["0descripcion"].value = "";
      inputs.current["0cantidad"].value = 1;
      inputs.current["0pc"].value = "";
      inputs.current["0id"].focus();
    }
  }, [add])

  useEffect(()=>{
    if(itemsCompra.length<=0){return}
    if(!inputs.current){return}
    itemsCompra.forEach(item=>{
      inputs.current[item.index+"id"].value = item.id;
      inputs.current[item.index+"descripcion"].value = item.descripcion;
      inputs.current[item.index+"cantidad"].value = item.cantidad;
      inputs.current[item.index+"pc"].value = valueToMoney(item.precioCompra);
    })
  },[itemsCompra])

  useMemo(()=>{
    if(HeaderCompra.shortId){return}
    //console.log('first')
    if(itemsCompra.length==1){
      if(itemsCompra[0].id!==""&&itemsCompra[0].descripcion!==""){ setCleanItem(); }
      return
    }
    setCleanItem();
  },[HeaderCompra.compraMenor,HeaderCompra.traspaso])


  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Buscando articulo {itemsCompra[0]?.id}</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
    
      <Modalinfo config={modalinfoConfig} tab="300" closeModal={closeModalinfo}>
        <Modalinfo.Message title={title.current}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            name="modalinfo"
            tabIndex="300"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modalgeneric w="size-w-80" config={modalTablaConfig} closeModal={closeModaltabla}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Elija una opción chida</Modalgeneric.Title>
          <TableModal
          searchinput ={true}
           config={modalTablaConfig}
            columns={[
              { header: "Clave", accessor: "id" },
              { header: "Nombre", accessor: "nombre" },
              { header: "Descripción", accessor: "descripcion" },
            ]}
            onclickRow={(item,e) => {
              if(e.detail==2||e.key=="Enter"){
                agregarItem(item);
                setmodalTablaConfig({ isOpen: false });
              }
            }}
            data={dataproduct}
          />
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModaltabla}
            tabIndex="200"
            name="modalgeneric"
            className="btn-gray1"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>


      <Modalgeneric bg="bg-bluei " w="size-w-30" h="size-h-40" config={modalAddProducts} closeModal={closeModalAddProducts}>
        <Modalgeneric.Body >
          <Modalgeneric.Title text="text-white">Promediar Costos</Modalgeneric.Title>
          <div className="w-full flex flex-col items-center">
            <div className="grid grid-flow-col">
              <label className="font-size text-size text-white text-center w-24">Cantidad</label>
              <label className="font-size text-size text-white text-center w-28">Precio C</label>
            </div>
            {addProd.map((item,index)=>(
              <AddProduct
              key={index}
              config={{
                item:item,
                setdata: setAddProd,
                datos: addProd,
                index: index,
                deletepago : deletePago,
                modalAddProducts: modalAddProducts,
              }}/>
            ))}
            <motion.button 
                className=" rounded-full"
                whileHover={{scale:1.2}}
                whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}}
                onClick={e=>{
                  let prods = [...addProd];
                  prods.push({cantidad:0,precioCompra:0,precioCompraIni:0})
                  setAddProd(prods);
               }}>
                  <i className="fas fa-plus-circle text-2xl mt-2 text-ambar-300 hover:text-ambar-400"/>
            </motion.button>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModalAddProducts}
            className="btn-gray2"
            tabIndex="200"
          >
            Cancelar
          </button>
          <button
          type="button"
          tabIndex="201"
          onClick={e=>{
            let operacion = 0,cantidadTotal=0,pcp;
            addProd.forEach(item=>{
              operacion+=item.cantidad*item.precioCompra;
              cantidadTotal+= item.cantidad;
            })

            pcp = Math.round(operacion/cantidadTotal) ;
            inputs.current[modalAddProducts.item.index+"cantidad"].value = cantidadTotal;
            inputs.current[modalAddProducts.item.index+"pc"].value = valueToMoney(pcp);
            inputs.current[modalAddProducts.item.index+"pc"].focus();
            inputs.current[modalAddProducts.item.index+"pc"]?.select?inputs.current[modalAddProducts.item.index+"pc"].select():""
            setCantPc({...modalAddProducts.item,cantidad:cantidadTotal,precioCompra:pcp});
            closeModalAddProducts();
          }}
          className={(validaAddProds()?"btn-green1":"hidden")}
          >
            Agregar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      {!HeaderCompra.compraMenor&&!HeaderCompra.shortId?
        <SearchLine
        getLinea={linea=>{
          if(!linea?.id){
            setmodalinfoConfig({isOpen: true,message: "El id no es válido. " });
            setItem(itemCompra);
            return;
          }
          if(validProductId(linea?.id)){
            if(linea.type=="LINEA"){
              const peticion ={producto:linea.id,funcionExito:funcionExito,funcionError:funcionError,sinLimite:true};
              if(["MI","LC","AR"].includes(linea.id.substring(0,2))){
                peticion.linea = true;
              }
              openmodalLoading();
              useGetProduct(peticion);
            }
            else{
              if(!foundProduct(linea.id,"cantidad")){
                agregarItem(linea);
              }
            }
          }
        }}/>
        :null
      }

      <TableCompra
        searchinput={false}
        columns={[
          {
            header: "maker/style",
            width:"w-44",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <div className="flex flex-row w-full gap-1">
                <input
                  type="text"
                  className="border-table w-full text-rigth uppercase"
                  placeholder=""
                  ref={node=>{inputs.current[item?.index+"id"]=node}}
                  defaultValue={item?.id}
                  readOnly={Proveedor.nombre === "Inexistente" || Proveedor.nombre === undefined?true:item?.index==0?false:true}
                  onBlur={e=>{
                    const valor = e.target.value.trim().toUpperCase();
                    if(HeaderCompra?.id){return}
                    if(valor==item.id||item.index!==0){return}
                    setItem({...item,id:e.target.value.trim().toUpperCase()})
                  }}
                  onKeyDown={e=>{
                    const valor = e.target.value.trim().toUpperCase();
                    if(HeaderCompra?.id){return}
                    if((e.key=="Enter"||e.key=="Tab")&&item.index==0&&valor.length>3){
                      if(foundProduct(valor,"cantidad")){return}
                      if(validProductId(valor)){
                        const peticion ={producto:valor,funcionExito:funcionExito,funcionError:funcionError};
                        if(["MI","LC","AR"].includes(valor.substring(0,2))){
                          peticion.linea = true;
                          peticion.sinLimite = true;
                        }
                        openmodalLoading(e);
                        useGetProduct(peticion);
                      } else if (HeaderCompra.compraMenor) {
                        const peticion ={producto:valor,funcionExito:funcionExito,funcionError:funcionError};
                        openmodalLoading(e);
                        useGetProduct(peticion);
                      }
                      else{
                        setmodalinfoConfig({isOpen: true,message: "El id "+item.valor+" no es válido. " });
                        setItem(itemCompra)
                      }
                    }else if(e.key=="Escape"){
                      setItem(itemCompra);
                      setAdd(!add);
                    }
                  }}
                />
                {HeaderCompra?.id?null:(item?.index==0?null:
                <div className="text-red-600 transform hover:text-pink-700 hover:scale-110 w-4">
                  <button
                  onClick={(e) => {
                      eliminarItemCompra(item.index);
                  }}>
                    <i className="fas fa-times-circle"></i>
                  </button>
                </div>
                )}
                
            </div>
            ),
          },
          {
            header: "Description",
            width:"w-96 ",
             // eslint-disable-next-line react/display-name
            code:(item)=>(
              <label className="border-table text-size-table w-full"  ref={node=>{inputs.current[item?.index+"descripcion"]=node}} >{item?.descripcion}</label>
            )
          },
          {
            header: "qty",
            width:"w-20",
            // eslint-disable-next-line react/display-name
            code: (item) => (
              <div className="w-full flex flex-row justify-end">
                 <motion.button 
                className={(item.id!==""&&item.type!==""&&!HeaderCompra.id?" w-4 rounded-full":"hidden")}
                whileHover={{scale:1.2}}
                whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}}
                onClick={e=>{
                  setModalAddProducts({ isOpen: true, title ,item:item});
                }}
                >
                  <i className="fas fa-plus-circle text-teal-500 hover:text-blue-500"/>
                </motion.button>
                <input
                  type="text"
                  key={item.index+"cantidad"}
                  className={" w-full text-right rounded disabled:opacity-50 font-semibold outline-none transition-colors duration-200 ease-in-out focus:shadow-outline "+(item.cantidad <= 0 ? "bg-red-200" : "bg-transparent" )}
                  defaultValue={item.cantidad}
                  ref={node=>{inputs.current[item?.index+"cantidad"]=node}}
                  readOnly={HeaderCompra?.id?true:false}
                  onBlur={(e) => {
                    if(HeaderCompra?.id){return}
                    if(e.target.value==""&&item.id!==""){
                      inputs.current[item.index+"cantidad"].focus();
                      return
                    }
                    if(e.target.value==item.cantidad){ return;  }
                    changecantidad(item, e);
                  }}
                  onKeyDown={e=>{
                    if(HeaderCompra?.id){return}
                    if(e.key=="Escape"){
                      setItem(itemCompra);
                      setAdd(!add);
                    }
                  }}
                />
               
              </div>
            ),
          },
          {
            header: "Unit Cost",
            width:"w-28",
            // eslint-disable-next-line react/display-name
            code: (item) => (
                <InputPrecioPanel
                className={"w-full"}
                min="0.0001"
                readOnly={HeaderCompra?.id?true:false}
                newValor={item?.precioCompra}
                zero={true}
                inputRef={e=>{inputs.current[item?.index+"pc"]=e}}
                onBlur={e=>{
                  if(HeaderCompra?.id){return}
                  if(e<0.0001&&item.id!==""){
                    inputs.current[item?.index+"pc"].focus();
                    return
                  }
                  if(e!==valueToMoney(item.precioCompra)){
                    changepreciocompra(item, e); 
                  }
                }}
                onKeyPress={e=>{
                  if(HeaderCompra?.id){return}
                  if(e.key=="Escape"){
                    setItem(itemCompra);
                    setAdd(!add);
                  }
                }}
                setValor={(e)=>{ /*changepreciocompra(item, e);*/}}/>
            ),
          },
          {
            header: "Unit Price",
            width:"w-28",
            // eslint-disable-next-line react/display-name
            code: (item) => (item?.type=="PRODUCT"?
              <InputPrecioPanel
                width="w-full"
                min={item?.minPrecioVenta}
                newValor={item?.precioVenta}
                readOnly={HeaderCompra?.id?true:(item?.type === "LINEA"?true:false)}
                /*onBlur={(e)=>{ 
                  if(HeaderCompra?.id){return}
                  if(e==valueToMoney(item.precioVenta)){return}
                  setItem({...item,precioVenta:changeprecioventa(item,e)});
                }}*/
                onKeyPress={(e,val)=>{
                  if(HeaderCompra?.id){return}
                  if(e==valueToMoney(item.precioVenta)){return}
                  if(e.key=="Tab"||e.key=="Enter"){
                    const newitem ={...item,precioVenta:changeprecioventa(item,val)};
                    delete newitem.first_codigo;
                    if(item.index==0&&item.id!==""){
                      addItem(newitem);
                      setAdd(!add);
                    }else if(item.index!==0){
                      setItem(newitem);
                      setAdd(!add);
                    }
                  }else if(e.key=="Escape"){
                    setItem(itemCompra);
                    setAdd(!add);
                  }
                }}
                />
              :<input 
              className="w-full text-right font-semibold pl-1 outline-none focus:outline-none bg-transparent" 
              type="text" 
              readOnly={true} 
              key={item.index+"pv"}
              onKeyDown={e=>{
                if(HeaderCompra?.id){return}
                if((e.key=="Tab"||e.key=="Enter")&&item.index==0&&item.id!==""){
                  const newitem ={...item};
                  delete newitem.first_codigo;
                  addItem(newitem);
                  setAdd(!add);
                }
              }}/>
            ),
          },
          {
            header: "MarkUp",
            width:"w-20",
            // eslint-disable-next-line react/display-name
            code: (item) => <p className="w-full text-right" hidden={item?.type=="LINEA"} >
              {CalcularPorcentajeMargenUtilidad(item?.precioVenta-item?.precioCompraPromediado,item?.precioVenta)+"%"}
              </p>,
          },
          {
            header: "Amount",
            width:"w-36",
            // eslint-disable-next-line react/display-name
            code: (item) => (<div className="flex flex-row gap-1 w-full justify-end">
              <button
              className={(item.type === 'PRODUCT' || !HeaderCompra.id || item?.inventarioPendiente==false?"hidden w-0 ":"text-blue-600 ")}
                    onClick={(e) => {  
                      openmodalLoading();
                      let peticion ={
                        hashKey:{valor:"COMPRA#"+getSucursal()+"#INVENTARIO#"+item.id,nombre:"id"},
                        sortKey:{valor:HeaderCompra.id,operador:"eq",nombre:"type"}
                      }
                      useCustomQuery(peticion,resp=>{
                        const PropsInventory = {idcompra:HeaderCompra.id, precioCompra:item.precioCompra, cantidad:item.cantidad, linea:item.id, inventario:[]};
                        const inv = resp.datos[0];
                        if(inv?.inventario){
                          PropsInventory.inventario = inv.inventario;
                        }

                        setCleanInventario();
                        setPropsInventarioNew(PropsInventory);
                        closemodalLoading();
                        addRuta("inventario");
                      },error=>{
                        //console.log('error=>',error)
                        handleError(error);
                      });
                    }}
              >
                <i className="fas fa-border-all"></i>
              </button>
              <button
                  className={(item.type === 'PRODUCT' || [true,undefined].includes(item?.inventarioPendiente)?"hidden w-0":"text-green-600")}
                    onClick={(e) => { 
                      openmodalLoading();
                      let peticion ={
                        hashKey:{valor:"COMPRA#"+getSucursal()+"#INVENTARIO#"+item.id,nombre:"id"},
                        sortKey:{valor:HeaderCompra.id,operador:"eq",nombre:"type"}
                      }
                      useCustomQuery(peticion,resp=>{
                        const PropsInventory = {idcompra:HeaderCompra.id, precioCompra:item.precioCompra , cantidad:item.cantidad, linea:item.id, inventario:[], inv_completo:true};
                        const inv = resp.datos[0];
                        if(inv?.inventario){
                          PropsInventory.inventario = inv.inventario;
                        }

                        setCleanInventario();
                        setPropsInventarioNew(PropsInventory);
                        closemodalLoading();
                        addRuta("inventario");
                      },error=>{
                        //console.log('error=>',error)
                        handleError(error);
                      });
                    }}
                  >
                  <i className="fas fa-check"></i>
                </button>
                <Labelmoney twoDecimal={true} >{item?.importe}</Labelmoney>
            </div>
            ),
          },
        ]}
        footer={[
          { width:"w-44"},
          {
            width:"w-96 bg-input-mesh",
            // eslint-disable-next-line react/display-name
            code:<p className="w-full">Cantidad</p>
          },
          { width:"w-20 bg-input-mesh",
          // eslint-disable-next-line react/display-name
            code: <LabelNumber twoDecimal={true} className="font-semibold w-full pl-2">{itemsCompra.reduce((a, b) => parseInt(a) + parseInt(b.cantidad), 0)}</LabelNumber>
          },
          { width:"w-28"},
          { width:"w-28"},
          {
            width:"w-20 bg-input-mesh",
            // eslint-disable-next-line react/display-name
            code:<p>SubTotal</p>
          },

          { width:"w-36 bg-input-mesh ",
          // eslint-disable-next-line react/display-name
            code: <Labelmoney twoDecimal={true} className="font-semibold w-full  ">{itemsCompra.reduce((a, b) => parseInt(a) + parseInt(b.importe), 0)}</Labelmoney>
          },
        ]}
        footer2={[
          { width:"w-44"},
          { width:"w-96", },
          { width:"w-20",},
          { width:"w-28"},
          { width:"w-28"},
          {
            width:"w-20 bg-input-mesh",
            // eslint-disable-next-line react/display-name
            code:<p className="w-full">Total</p>
          },
          { width:"w-36 bg-ambar-100 ",
          // eslint-disable-next-line react/display-name
            code: <Labelmoney twoDecimal={true} className="font-semibold w-full ">{itemsCompra.reduce((a, b) => parseInt(a) + parseInt(b.importe), 0)+HeaderCompra.envio-HeaderCompra.descuento}</Labelmoney>
          },
        ]}
        data={itemsCompra}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  eliminarItemCompra: (id) => dispatch(eliminarItemCompra(id)),
  addItem: (item) => dispatch(agregarProductoCompra(item)),
  setItem: (item) => dispatch(setItemCompra(item)),
  setCantPc: (item) => dispatch({type:"CHANGE_CANTPC_COMPRA",id:item.id,item:item}),

  addRuta: (ruta) => dispatch(setRuta(ruta)),
  
  setPropsInventarioNew: (estado) => dispatch({type:"SET_NEW_PROPSINVENTARIO", estado:estado}),
  setCleanInventario: () => dispatch({type: 'RESET_INVENTARIO'}),
  setCleanItem:() => dispatch({type:"CLEAN_ITEMCOMPRA"}),
});

const mapStateToProps = (state) => ({
  itemsCompra: state.itemsCompra,
  HeaderCompra: state.HeaderCompra,
  Proveedor: state.SearchProveedor,
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelTablaCompra);