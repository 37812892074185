import React from "react";
import PropTypes from "prop-types";
const CheckDual = ({ getvalor, nombre, opcion1, opcion2 , valor }) => {


  return <div className="flex flex-wrap mb-3 justify-center"
      onChange={(e) => {
        getvalor(e.target.value);
      }}
    >
        <label className="textf mr-2 md:text-base">{opcion1}</label>
        <input
          className="radio-blue radio"
          type="radio"
          checked={(valor==opcion1.toUpperCase()?true:false)}
          value={opcion1.toUpperCase()}
          name={nombre}
          id={"radioprod" + nombre + opcion1}
        />
        <label className="textf md:text-base ml-4 mr-2">{opcion2}</label>
        <input
          className="radio-blue radio"
          type="radio"
          checked={(valor==opcion2.toUpperCase()?true:false)}
          value={opcion2.toUpperCase()}
          name={nombre}
          id={"radioprod" + nombre + opcion2}
        />
      </div>
};

CheckDual.propTypes = {
  getvalor: PropTypes.func.isRequired,
  nombre: PropTypes.string.isRequired,
  opcion1: PropTypes.string.isRequired,
  opcion2: PropTypes.string.isRequired,
};
export default CheckDual;
