import React, { useMemo, useState } from "react";
import Modalgeneric from "./modal/generic";
const Historial = (props) => {
  const saveChange = props.savechange || (() => {});
  const [data, setdata] = useState([]);
  const [modalgenericonfig, setmodalgenericonfig] = useState({});
  const [registro, setregistro] = useState([]);

  const openmodalgeneric = (e) => {
    setmodalgenericonfig({ isOpen: true });
  };
  const closemodalgeneric = (e) => {
    setmodalgenericonfig({ isOpen: false });
    setregistro(["", ""]);
  };

  function porfecha(a, b) {
    if (a[0] < b[0]) return 1;
    if (a[0] > b[0]) return -1;
    return 0;
  }

  const closesavemodalgeneric = () => {
    let newArray = [...data];
    const index = newArray.findIndex((element) => element[0] === registro[0]);
    index > -1
      ? (newArray[index][1] = registro[1])
      : newArray.push([registro[0], registro[1]]);
    saveChange(Object.fromEntries(newArray));
    setdata(newArray);
    setmodalgenericonfig({ isOpen: false });
    setregistro(["", ""]);
  };

  const editItem = (index) => {
    setregistro(data[index]);
    openmodalgeneric(0);
  };

  const createItem = (e) => {
    openmodalgeneric(e);
  };

  useMemo(() => {
    saveChange(props.data || {});
    setdata(Object.entries(props.data || {}));
  }, [props.data]);

  return (
    <>
      <Modalgeneric config={modalgenericonfig}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Entrada del historial</Modalgeneric.Title>
          <div className="flex flex-column flex-wrap items-stretch mt-2 w-full">
            <input
              type="date"
              className="mt-1 w-full border-form"
              value={registro[0] || ""}
              onChange={(e) => {
                setregistro([e.target.value, registro[1]]);
              }}
            ></input>
            <textarea
              className="mt-2 inputf"
              rows="3"
              onChange={(e) => {
                setregistro([registro[0], e.target.value]);
              }}
              value={registro[1]}
            ></textarea>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            className="btn-blue"
            onClick={(e) => {
              closesavemodalgeneric();
            }}
          >
            Guardar
          </button>
          <button
            type="button"
            className="btn-gray2"
            onClick={closemodalgeneric}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <div className="w-full flex flex-col items-center  mx-auto ">
        <div className="w-full px-0 ">
          <div className="flex flex-col items-center relative border border-blue-900 ">
            <div className="w-full ">
              <div
                className="my-2 p-1  bg-white rounded   focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3  transition-colors duration-200 ease-in-out mb-2 max-h-select overflow-y-auto"
                style={{ height: "20vh" }}
              >
                {data.sort(porfecha).map((item, index) => (
                  <div key={index} className="flex flex-auto">
                    <div
                      className="cursor-pointer flex justify-center items-center m-1 font-medium py-1 px-2  rounded text-gray-700 bg-gray-100 border border-gray-300 "
                      onClick={(e) => {
                        editItem(index);
                      }}
                    >
                      <div className="text-xs font-normal leading-none max-w-full flex-initial">
                        {item[0]}
                      </div>
                    </div>
                    <div className="flex m-1 font-medium py-1 px-2  rounded text-gray-700 bg-white border border-gray-300 ">
                      {item[1]}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col relative justify-center items-center">
                <div className="cursor-pointer flex justify-center items-center m-1 w-1/3 py-2 px-2  rounded font-bold text-white bg-green-600 border border-gray-700 ">
                  <button
                    disabled={props.disabled}
                    type="button"
                    onClick={createItem}
                    className="text-xs font-bold uppercase leading-none max-w-full flex-initial disabled:opacity-50"
                  >
                    Agregar registro
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Historial;
