import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import Modaldanger from '../modal/danger';
import Multiselector from '../Multiselector';
import { useGetProduct } from '../../Hooks/useApis';
import { CalculaMinPrecioVenta, CalculaPrecioCompraPromediado, getAlcance, getSucursal } from '../../services/functions.js';
import LoadingModal from '../modal/LoadingModal';
import TestExcel from '../Excel/TestExcel';
import SearchLinea from '../Productos/SearchLinea';
import {motion} from 'framer-motion'
import SearchSvg from '../../static/img/SearchSvg';

 const HeaderInventario=({Ruta,setInventario,setCleanInventario,PropsInventario,resetPropsInventario,setSucursalesInventario,setPropsInventario,})=> {
    const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
    [modalLoading, setmodalLoading] = useState({}),
    [datos,setDatos]=useState({});
    const rutas = ['inventory','menu'];
    const rutaActual =Ruta?.[Ruta.length-1], oldRuta =Ruta?.[Ruta.length-2];

    const title = useRef("Sin Datos");

    function sortMay(a, b) {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    }

    function sortMen(a, b) {
      if (a > b) return -1;
      if (a < b) return 1;
      return 0;
    }

    function closeModaldangererror(e) {
      const newdataconfig = { ...modaldangererrorConfig };
      newdataconfig.isOpen = false;
      setmodaldangererrorConfig(newdataconfig);
    }
    const openmodalLoading = (e) => {
      setmodalLoading({ isOpen: true, title });
    };
    const closemodalLoading = (e) => {
     setmodalLoading({ isOpen: false });
    };
  
    let linea;

    const handleSuccessful = (data) => {
      //console.log('==>',data)
      let new_inventario = [],oldExistencia=0;
      data.productos.forEach(item => {
        oldExistencia+= (PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.existencia||0), 0));
      });

      let pcp = CalculaPrecioCompraPromediado(data.linea?.sucursales?.[getSucursal()]?.precioCompra||0,PropsInventario.cantidad,data.linea?.sucursales?.[getSucursal()]?.oldPrecioCompra||data.linea?.sucursales?.[getSucursal()]?.precioCompra||0,(oldExistencia==0?PropsInventario.cantidad:oldExistencia));
      let minPrecioVenta = CalculaMinPrecioVenta(pcp,data.productos[0].categoria);

      if(data.linea){
      let new_tablas = [];
      const props = {...PropsInventario};
      if(data.linea.descripcion.includes("MONOFOCAL")){
        new_tablas = Object.keys(data.linea.schema).sort(sortMen)
      }else{
        new_tablas = Object.keys(data.linea.schema).sort(sortMay)
      }
      data.linea.tablas= [...new_tablas];
      data.linea.categoria = data.productos[0].categoria;
      data.linea.marca = data.productos[0].marca;
      data.linea.categoria = data.productos[0].categoria;
      data.linea.linea = data.productos[0].linea;

      props.data=data.linea;
      props.items=data.productos;
      props.sucursales=[getSucursal()];

        if(rutas.includes(oldRuta)){
          props.tab = "IN";
          props.editItems = false;
          pcp = CalculaPrecioCompraPromediado(data.linea?.sucursales?.[getSucursal()]?.precioCompra||0,oldExistencia,(data.linea?.sucursales?.[getSucursal()]?.oldPrecioCompra||data.linea?.sucursales?.[getSucursal()]?.precioCompra||0),oldExistencia);
          minPrecioVenta= CalculaMinPrecioVenta(pcp,data.productos[0].categoria);
          data.productos.forEach(item => {
            let line = "";
            new_tablas.map(tabla=>{
              let new_tabla = tabla;
              if(item.id.indexOf(new_tabla)>=0){
                item.line = tabla;
                line = tabla;
              }else{
                if(item?.forma=="BASE"&&item?.color){
                  if(item.ciladd.trim().length==0){new_tabla= tabla.replace('+','')}
                  if(item.id.indexOf(new_tabla)>=0){
                    item.line = tabla;
                    line = tabla;
                  }
                }else if(item?.forma=="BASE"){
                  item.line = tabla;
                  line = tabla;
                }
              }
            });
             data.linea.tablas.forEach(tab=>{
              if(item.forma=="BASE"){
                let new_tab =tab.replace('+','');
                new_tab = new_tab.replace('-','');
                tab= new_tab;
              }
                if(item.id.includes(tab)){
                  new_inventario.push({
                    id:item.id, 
                    guideline:  PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales[b]?.guideline||0), 0),
                    cantidad: PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales[b]?.existencia||0), 0), 
                    N: PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt((item?.sucursales[b]?.guideline||0)-(item?.sucursales[b]?.existencia||0)), 0),
                    precioVenta: (PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales[b]?.precioVenta||0), 0)/PropsInventario.sucursales.length), 
                    precioCompra: (PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales[b]?.precioCompra||0), 0)/PropsInventario.sucursales.length) , 
                    esfera:item.esfera, 
                    ciladd:item.ciladd, 
                    descripcion:item.descripcion, 
                    linea:line,
                    categoria: item.categoria,
                    marca: item.marca,
                    type: item.linea,
                  })
                }
             })
            //(PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales[b]?.existencia||0), 0)>0)
            //?
            
            //:"";//delInventario(item.id)
          });
          //
        }
        if((['compra','receiving','receivingFolio'].includes(oldRuta)||rutaActual)&&PropsInventario?.idcompra){
          props.editItems = PropsInventario?.inv_completo||true;
          props.tab = "B";
          PropsInventario.inventario.map(item=>{
            const new_item = {...item};
            if(item.precioVenta<minPrecioVenta){
              
              new_item.precioVenta = 0;
            }
            new_inventario.push(new_item);
          })
          new_inventario =  PropsInventario.inventario;
        }
        props.minPrecioVenta = minPrecioVenta;
        setPropsInventario(props);
        closemodalLoading();
        
        setInventario(new_inventario.sort((a,b)=>{
          if(a.id<b.id) return -1;
          if(a.id>b.id) return 1;
          return 0;
        }));
      }else{
        closemodalLoading();
      }
    };
  
  const handleError = (error) => {
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: error || "aqui va otro mensaje de error" ,
    });
  }

  const GetLinea=()=>{
    const new_linea = linea.value.trim().toUpperCase();
    if (!new_linea||new_linea.length<4) { return;}
    //if(new_linea==PropsInventario.data?.linea){return;}
    resetPropsInventario();
    setCleanInventario();
    openmodalLoading();
    useGetProduct({producto:new_linea,linea:true,sucursal:"",funcionExito:handleSuccessful,funcionError:handleError,sinLimite:true});
  }

  useEffect(() => {
    const peticion = {linea:true,funcionExito:handleSuccessful,funcionError:handleError,sinLimite:true};
    if(rutas.includes(oldRuta)){
      if(!PropsInventario.data?.id){return;}
      peticion.sucursal = "";
      peticion.producto = PropsInventario.data.id;
    }else if(['compra','receiving','receivingFolio'].includes(oldRuta)){
      peticion.producto = PropsInventario.linea;
    } else{ return;}

    openmodalLoading();
    //console.log('pet:',peticion)
    useGetProduct(peticion);
  }, [])


  useEffect(() => {
    let new_inventario = [];
    if(!PropsInventario?.sucursales?.length){setInventario([]); return;}
    if(!PropsInventario?.items?.length){return;}
    if(PropsInventario?.idcompra){return;}
    if(PropsInventario.sucursales.length==1){
      const props = {...PropsInventario};
      let oldExistencia =0;

      PropsInventario.items.forEach(item => {
        oldExistencia+= (PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.existencia||0), 0));
      });
      const pcp = CalculaPrecioCompraPromediado(PropsInventario.data.sucursales?.[PropsInventario?.sucursales?.[0]]?.precioCompra||0, oldExistencia, PropsInventario.data?.sucursales?.[PropsInventario.sucursales?.[0]]?.oldPrecioCompra||PropsInventario.data?.sucursales?.[PropsInventario.sucursales?.[0]]?.precioCompra||0,oldExistencia);
      props.minPrecioVenta =  CalculaMinPrecioVenta(pcp,PropsInventario.data.categoria);
      setPropsInventario(props);
    }

    PropsInventario.items.forEach(item => {
      //(PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.existencia||0), 0)>0)
     // ?
      new_inventario.push({
        id:item.id,
        guideline:  PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.guideline||0), 0),
        cantidad: PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.existencia||0), 0), 
        N: PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt((item?.sucursales?.[b]?.guideline||0)-(item?.sucursales?.[b]?.existencia||0)), 0),
        precioVenta: (PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.precioVenta||0), 0)/PropsInventario.sucursales.length), 
        precioCompra: (PropsInventario.sucursales.reduce((a, b) => parseInt(a) + parseInt(item?.sucursales?.[b]?.precioCompra||0), 0)/PropsInventario.sucursales.length), 
        esfera:item.esfera, 
        ciladd:item.ciladd,
        descripcion:item.descripcion, 
        linea: item.line,
        categoria: item.categoria,
        marca: item.marca,
        type: item.linea,
      })
      //:"";//delInventario(item.id)
    });
    setInventario(new_inventario);
  },[PropsInventario.sucursales]); 

  useEffect(()=>{
    if(!datos?.linea?.id){return;}
    linea.value = datos.linea.id;
    GetLinea();
  },[datos])

    return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>{"Cargando Formato "}</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>
      <TestExcel/>
        <div className='size-5 justify-center items-center pt-1'>
            <form className="size-form w-full justify-center gap-1 items-center" onSubmit={e=>{
              e.preventDefault();
              GetLinea();
            }}>
              <label hidden={(!rutas.includes(oldRuta)?true:false)} className='text-size text-center font-bold'>{"Codigo: "}</label>
              <div className='flex flex-row'>
                <input
                  type="text"
                  className="border-form uppercase md:w-40"
                  name="linea"
                  placeholder="Linea"
                  autoFocus={true}
                  ref={(node) => (linea = node)}
                  style={{ transition: "all .15s ease" }}
                  hidden={(!rutas.includes(oldRuta)?true:false)}
                  onKeyPress={e=>{
                    if(e.key=="Tab"){
                      GetLinea();
                    }
                  }}
                />
                <motion.button
                type='submit'
                className=" hover:text-blue-400 md:mr-0"
                style={{ transition: "all .15s ease" }}
                hidden={PropsInventario.idcompra}
                whileHover={{ scale: 1.2 }}
                whileTap={{
                  scale: 0.8,
                  rotate: 5,
                  borderRadius: "100%"
                }}>
                <SearchSvg width="w-4" height="h-4" text="text-blue-600"/>
                </motion.button>
              </div>
              
          </form>
          {rutas.includes(oldRuta)?
            <div className=" w-full items-center justify-center" >
              <SearchLinea Ruta="inventario" getLinea={linea=>{
                //console.log('linea')
                setDatos({...datos,linea:linea})
              }}/>
            </div>
            :null
          }

          {rutas.includes(oldRuta)?
            <div className={"size-form w-full col-span-3 justify-center items-center"}>
              <label className='text-size font-bold '>{"Sucursales: "}</label>
                <Multiselector
                  className="w-auto"
                  selected={PropsInventario.sucursales}
                  disabled={PropsInventario.idcompra}
                  filters={true}
                  savechange={(data) => {
                    if(JSON.stringify(data)!==JSON.stringify(PropsInventario.sucursales)){
                      setSucursalesInventario(data)
                    }else if(PropsInventario.sucursales.length==0){
                      setSucursalesInventario([getSucursal()])
                    }
                  }}
                  data={getAlcance()}
                />
            </div>
            :null
          }

        </div>
      </>
    )
}

  const mapStateToProps = (state) => ({
    Ruta: state.Ruta,
    Inventario: state.Inventario,
    PropsInventario: state.PropsInventario,
    ItemsCompra: state.itemsCompra
  });
  
  const mapDispatchToProps = dispatch => ({
    setCleanInventario: () => dispatch({type: 'RESET_INVENTARIO'}),
    resetPropsInventario: ()=> dispatch({type: "RESET_PROPSINVENTARIO"}),
    setPropsInventario:(data)=> dispatch({type:"SET_PROPSINVENTARIO", estado:data}),
    
    setSucursalesInventario:(sucursales)=> dispatch({type:"SET_SUCURSALESINVENTARIO",sucursales:sucursales}),
    setInventario:(items)=>dispatch({type:"SET_INVENTARIO",inventario:items}),
});

export default connect(mapStateToProps, mapDispatchToProps) (HeaderInventario);