import React, { useEffect } from "react";
import { connect } from "react-redux";
import { returnRuta } from "../../redux/Actions";
import { showMoney, Sucursal, TransformSys, FechaUTCToLocal, GetFechaActual, valueToMoney } from "../../services/functions.js";
import Newlogo from "../../static/img/logonew.jsx";
import {motion} from 'framer-motion'

const TicketPagadoAbonosPes = ({ Ticket, delRuta }) => {
  const fecha = (Ticket?.item?.id?Ticket.item.id.split('#')[1]:"");

  useEffect(() => {
    window.print();
  }, []);

  return (
    <>
      <div className="mx-2 75mm">
        <div className="w-full text-2xs">
          <div className="grid grid-flow-col justify-items-stretch w-full">
          <label className="text-base mt-3 justify-self-end font-black uppercase">ABONO</label>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{
              scale: 0.8,
              rotate: 5,
              borderRadius: "100%"
            }}
            onClick={(e) => {delRuta(); }}
            className={ "w-[90px] justify-self-end h-14 border-none leading-relaxed inline-block" }
            >
              <Newlogo corona="#00ace5" corona1="#00C9E5"  text="#000000"/>
            </motion.button>
          </div>
          <div className="w-full">
            <p className="uppercase w-full text-lg font-bold text-center">{Sucursal('nombre')}</p>
            <p className="uppercase">{Sucursal('direccion')+" C.P. "+Sucursal('codigo_postal')+" Tel. "+Sucursal('telefono')}</p>
            <p className="uppercase"> e-mail: {Sucursal('email')}</p>
            <p className="uppercase ">Ticket: <label className="font-bold">{Ticket?.item?.shortId}</label> Fecha Venta: <label className="font-bold">{FechaUTCToLocal(fecha).fecha + " "+ FechaUTCToLocal(fecha).hora}</label> </p>
            <p className="uppercase">Cliente: <label className="font-bold">{TransformSys(Ticket?.cliente)}</label>   VENDEDOR: <label className="font-bold">{Ticket?.item?.nombrePersonal}</label></p>
            <p className="uppercase">
              fecha emision: {GetFechaActual().fecha+" "+GetFechaActual().hora}
            </p>
          </div>
          <div className="w-full mt-3">
          {Ticket?.pagos.length?
              <div className="w-full md:w-1/2">
                <div className="grid grid-cols-3 font-bold text-start">
                  <label onClick={e=>{console.log(Ticket)}}>Metodo</label>
                  <label>Abono</label>
                </div>
                {Ticket.pagos.map((pago,index)=>(
                  <div key={index} className="grid grid-cols-3">
                    <label>{pago.metodo}:</label>
                    <label>{(Ticket?.cambio==true&&pago.metodo=="EFECTIVO"?valueToMoney(Ticket.recibido || 0):pago.monto || 0)}</label>
                  </div>
                ))}
                <div className="grid grid-cols-3 mt-3">
                  <label className="">Importe:</label><label className="col-span-2">{showMoney(Ticket?.item?.importe || 0)}</label>
                  <label className="">Saldo Anterior:</label><label className="col-span-2">{showMoney(Ticket?.total || 0)}</label>
                  <label className="">Abono:</label><label className="col-span-2">{showMoney(Ticket?.pagado+(Ticket?.cambio==true?Ticket.restante:0) || 0)}</label>
                  <label className={""+(Ticket?.total-Ticket?.pagado>0?"block":"hidden")}>Nuevo Saldo:</label><label className={"col-span-2 "+(Ticket?.total-Ticket?.pagado>0?"block":"hidden")}>{showMoney(Ticket?.total-Ticket?.pagado || 0)}</label>
                  <label className={""+(Ticket?.cambio==true?"block":"hidden")}>Cambio:</label><label className={"col-span-2 "+(Ticket?.cambio==true?"block":"hidden")}>{showMoney(Ticket.restante || 0)}</label>
                </div>
              </div>
              :""
            }
          </div>

          {Sucursal('ticket')?.productos.length?
            <div className="w-full text-center mt-3">
              <p className="uppercase w-full text-center font-black mb-1">Nuevos Productos</p>
              {Sucursal('ticket').productos.map((prod,index)=>(<p key={index} className="uppercase w-full text-center">{prod}</p>))}
            </div>
          :""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Ticket: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  delRuta: () => dispatch(returnRuta()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketPagadoAbonosPes);
