import React, { useEffect } from "react";
import { connect } from "react-redux";
import { returnRuta } from "../../redux/Actions";
import { showMoney, Sucursal, TransformSys, FechaUTCToLocal } from "../../services/functions.js";
import Newlogo from "../../static/img/logonew.jsx";
import {motion} from 'framer-motion'
import LabelFecha from "../LabelFecha";

const TicketCopiaPes= ({ Ticket, delRuta }) => {
  const fecha = (Ticket?.item?.id?Ticket.item.id.split('#')[1]:"");

  useEffect(() => { window.print();}, []);

  return (
    <>
    <div className="mx-2 75mm">
        <div className="w-full text-2xs">
          <div className="grid grid-flow-col justify-items-stretch w-full">
            <label className="text-base mt-3 justify-self-end font-black uppercase">COPIA</label>
            <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{
              scale: 0.8,
              rotate: 5,
              borderRadius: "100%"
            }}
            onClick={(e) => {delRuta();}}
            className={ "w-[80px] justify-self-end h-14 border-none leading-relaxed inline-block" }
            >
              <Newlogo corona="#00ace5" corona1="#00C9E5"  text="#000000"/>
            </motion.button>
          </div>
            <div className="w-full">
                <p className="uppercase w-full text-lg font-bold text-center">{Sucursal('nombre')}</p>
                <p className="uppercase">{Sucursal('direccion')+" C.P. "+Sucursal('codigo_postal')+" Tel. "+Sucursal('telefono')}</p>
                <p className="uppercase"> e-mail: {Sucursal('email')}</p>
                <p className="uppercase ">Ticket: <label className="font-bold">{Ticket?.item?.shortId}</label> Fecha: <label className="font-bold">{FechaUTCToLocal(fecha).fecha + " "+ FechaUTCToLocal(fecha).hora}</label> </p>
                <p className="uppercase">Cliente: <label className="font-bold">{TransformSys(Ticket?.item?.cliente)}</label>   VENDEDOR: <label className="font-bold">{Ticket?.item?.nombrePersonal}</label></p>
            </div>
            <table className="w-full text-2xs font-semibold mt-1">
              <thead>
                <tr>
                  <th className="text-center">Cant</th>
                  <th className="text-center">Descripción</th>
                  <th className="text-center w-12">Precio</th>
                </tr>
              </thead>
              <tbody>
              {Ticket?.item?.items
              ? (Ticket.item.items.map((product,index)=>{
                return <tr key={index}>
                <td className=" text-center">{product.cantidad}</td>
                  <td className=" text-start text-3xs md:text-2xs">{product.descripcion}</td>
                  <td className=" text-center ">{showMoney(product.precioVenta)}</td>
              </tr>
              }))
              : null}
              </tbody>
              <tfoot>
                <tr>
                  <td className="text-center"> {Ticket?.numeroArticulos || ""}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            <div className="flex flex-col w-full my-2 ">
              <div className="grid grid-cols-3 w-full">
                <div className="font-bold text-start">Metodo</div>
                <div className="font-bold text-start">Monto</div>
                <div className="font-bold text-start">Fecha</div>
              </div>
              {Ticket?.pagos.length?
              <div className="w-full ">
                {Ticket.pagos.map((pago,index)=>(
                  <div key={index} className="grid grid-cols-3 ">
                    <label >{pago.metodo}:</label>
                    <label>{(Ticket?.cambio==true&&pago.metodo=="EFECTIVO"?showMoney(Ticket.recibido || 0):showMoney(pago.monto || 0))}</label>
                    <div ><LabelFecha >{pago.fecha}</LabelFecha></div>
                  </div>
                ))}
              </div>
              :""
            }
            <div className="grid grid-cols-3 w-full ">
              <label>Importe:</label>
              <label>{showMoney(Ticket?.total || 0)}</label>
              <label></label>
              {Ticket?.pagos.length?
              <><label className="">Abonado:</label>
              <label>{showMoney(Ticket?.pagado)}</label>
              <label></label></>
              :""}
              {Ticket?.pagos.length?
              <><label>Saldo:</label>
              <label>{showMoney((Ticket?.total || 0)-Ticket?.pagado)}</label>
              <label></label></>
              :""}
            </div>
            </div>
            {Sucursal('ticket')?.productos.length?
              <div className="w-full text-center mt-3">
                <p className="uppercase w-full text-center font-black mb-1">Nuevos Productos</p>
                {Sucursal('ticket').productos.map((prod,index)=>(<p key={index} className="uppercase w-full text-center">{prod}</p>))}
              </div>
            :""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Ticket: state.Ticket
});

const mapDispatchToProps = (dispatch) => ({
  delRuta: () => dispatch(returnRuta()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketCopiaPes);
