import React, { useState,useEffect} from "react";
import { connect } from "react-redux";
import { FechaLocalToUTC, FechaUTCToLocal, GetFechaActual, getArrayAsync, getSucursal, getTotal, tipoCelda } from "../../../services/functions";
import Modalinfo from "../../modal/info";
import LoadingModal from "../../modal/LoadingModal";
import TableReactWindows from "../Cash/TableReactWindows";
import Modalsuccess from "../../modal/success";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";
import Modalgeneric from "../../modal/generic";
import { useRef } from "react";
import CalendarModal from "../../modal/CalendarModal";
import Modaldanger from "../../modal/danger";
import { motion } from 'framer-motion';
import { btnScale, btnTap } from "../../../static/motionStyle";

const ReportePesEliminados = ({PropsReportes,setHeader,addDatos,Datos}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modalError,setModalError]=useState({}),
  [modalItems,setModalItems]=useState({}),
  [ventas,setVentas]=useState([]),
  [openModalCalendar,setOpenModalCalendar]=useState(false),
  [fechaCalendar,setFechaCalendar] = useState({});
  const inputFecha = useRef("");
  const nameComponent = "ventasFolio",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];
  const header = PropsReportes.header||{};


  const handleError = (error) => {
    closemodalLoading();
    setModalError({isOpen:true,title:"Error de Red",message:error.message||error});
  }
  const closeModalError=()=>{ setModalError({isOpen:false}); }

  const openModalItems=()=>{setModalItems({isOpen:true})};
  const closeModalItems=()=>{setModalItems({isOpen:false})};
  const closeModalinfo=(e)=> {setmodalinfoConfig({ isOpen: false });}
  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }



  const getVentasAsync=async()=>{
    const peticionPes={
      hashKey:{valor:("PESDELETE#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[FechaLocalToUTC(fechaCalendar.fecha_Inicial),FechaLocalToUTC(fechaCalendar.fecha_Final,23,59,59)],operador:"between",nombre:"type"},
    };

    try{
      openmodalLoading();

      const respuesta = await getArrayAsync(peticionPes,[]);

      setVentas(respuesta);
      closemodalLoading();
    }catch (error){
      handleError(error)
    }
  }

  /*useEffect(() => {
    //if(rutaActualRep!==nameComponent){return;}
    const datos = Datos[nameComponent]||{};

    if(JSON.stringify(datos)=='{}'){
      getVentasAsync();
      return;
    }
    if(PropsReportes.header.folio==datos[1].folio){
      setVentas(datos[0]);
      return;
    }
      getVentasAsync();
  }, []);*/

  /*useEffect(() => {
    if(rutaActualRep!==nameComponent){return;}
    setHeader({...PropsReportes.header,text:"Sales Detail"});
  }, []);*/

  useEffect(() => {
    setOpenModalCalendar(true);
  }, [])

  useEffect(()=>{
    if(openModalCalendar){return}
    if(!fechaCalendar.fecha_Inicial||!fechaCalendar.fecha_Final){return}
    getVentasAsync();

  },[openModalCalendar,fechaCalendar])
  

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      {openModalCalendar?<CalendarModal state={fechaCalendar} setState={setFechaCalendar} openmodal={openModalCalendar} closemodal={setOpenModalCalendar}/>:""}
      
      <Modaldanger config={modalError} closeModal={closeModalError}>
        <Modaldanger.Message title={modalError?.title}>
          {modalError.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
          className="btn-gray2"
          tabIndex="200"
          onClick={closeModalError}>Cerrar</button>
        </Modaldanger.Buttons>
      </Modaldanger>


      <Modalgeneric  w="size-w-50" h="size-h-50" config={modalItems} front="z-30" closeModal={closeModalItems}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>{modalItems?.title}</Modalgeneric.Title>
          <div className="relative h-80/100">
            <TableReactWindows
              columns={[
              {
                key:"cantidad",
                header:"Cantidad",
                width:100,
                type:tipoCelda.number,
                filter:true,
                footer:true,
                function:getTotal,
              },
              {
                key:"descripcion",
                header:"Descripcion",
                width:300,
                type:tipoCelda.text,
              },
              {
                key:"precioVenta",
                header: "Precio",
                width:120,
                className:"text-blue-800",
                type:tipoCelda.money,
                filter:true,
                footer:true,
              function:getTotal,
              },
              ]}
              data={modalItems?.items}
            />
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
          name="modalgeneric"
          className="btn-gray2"
          onClick={closeModalItems}>
            Cerrar
          </button>
          
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>


      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>
      <div className="block w-full text-center font-bold">{"Reporte Pedidos Especiales Eliminados "}</div>

      <TableReactWindows
        btnDownload={async(e,datos)=>{
          /*const data = datos||ventas;
          if(!data?.length){handleError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            handleError(workb.error);
            return
          }

          const nameFile="Ticket-"+PropsReportes.header?.folio,
          headerFile="Ticket: "+PropsReportes.header?.folio +"     Cliente: "+PropsReportes?.header?.cliente +" ("+PropsReportes?.header?.nombreCliente+")     Fecha: "+PropsReportes?.header?.day,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={'Maker':7,'Style':17,'Descripcion':60,'Cant':7,'Precio':15,'Descuento':15,'Importe':15,'Costo':12,'Profit':12,'Margen':9};
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((venta,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.maker||"");
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.style||"");
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(venta?.descripcion||"");
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).style("fontSize",10);
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(venta?.cantidadVendida||0);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(venta?.precioVenta||0));
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(venta?.descuento||0));
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(venta?.importe||0));
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(valueToMoney2(venta?.precioCompra||0));
            sheet1.cell(columnasXlsx[7]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(valueToMoney2(venta?.utilidad||0));
            sheet1.cell(columnasXlsx[8]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[9]+(colPlus+index)).value((venta?.margen/100)||0);
            sheet1.cell(columnasXlsx[9]+(colPlus+index)).style("numberFormat", "0.00%");
          });
      
          sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[6]+sizeData).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[8]+sizeData).formula("SUM("+columnasXlsx[8]+""+colPlus+":"+columnasXlsx[8]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[8]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[9]+sizeData).formula("AVERAGE("+columnasXlsx[9]+""+colPlus+":"+columnasXlsx[9]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[9]+sizeData).style("numberFormat", "0.00%");
          
          const response =await SaveXlsx(workb,nameFile,true);
          if(response?.error){
            closemodalLoading();
            handleError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
          */
        }}
        columns={[
          {
            key:"type",
            header:"Fecha",
            width:100,
            type:tipoCelda.date,
            filter:true,
          },
          {
            key:"shortId",
            header:"PES",
            width:90,
            type:tipoCelda.text,
          },
          {
            key:"cliente",
            header: "Cliente",
            width:100,
            className:"text-blue-800",
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"importe",
            header: "Importe",
            width:120,
            type:tipoCelda.money,
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"pagado",
            header:"Pagado",
            width:120,
            type:tipoCelda.money,
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"items",
            header:"Productos",
            width:90,
            type:tipoCelda.text,
            className:"items-center justify-center ",
             // eslint-disable-next-line react/display-name
            content:({row,index})=>(
              <motion.button 
              whileHover={btnScale}
              whileTap={btnTap}
              className="text-blue-800 text-center justify-center"
              onClick={e=>{
                setModalItems({
                  isOpen:true,
                  items:row.items,
                  title:"PES "+row.shortId,
                })
              }}
              >
                <i className="fas fa-bars"/>
              </motion.button>
            )
          },
          {
            key:"motivo",
            header: "Motivo",
            width:250,
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"personal",
            header:"Personal",
            width:80,
            type:tipoCelda.text,
            filter:true
          }
        ]}

        data={ventas}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportePesEliminados);
