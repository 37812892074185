import React, { useMemo, useState } from 'react'
import { FechaUTCToLocal } from '../services/functions';

const LabelFecha=(props)=> {
    const [aux,setaux]=useState({Fecha:"",hora:0});

    useMemo(()=>{
        setaux(props?.children?FechaUTCToLocal(props.children):{Fecha:"",hora:0})
      },[props.children]);

  return <label 
  className={(props?.className?props.className:" w-full")+" whitespace-nowrap "+(props?.font)}
  >
    {aux.fecha+(!props?.hour?" "+aux.hora:"")}
  </label>;
}

export default LabelFecha