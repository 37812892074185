import React, { useState, useMemo } from "react";
import {motion} from 'framer-motion';
import { OrderArrayAZ } from "../services/functions";

const Multiselector = (props) => {
  let enabled = true;
  enabled = typeof props.disabled !== "undefined" ? !props.disabled : true;
  const datainit = props.data || [];
  const selected = props.selected || [];
  const saveChange = props.savechange || ((data) => {});
  const [display, setdisplay] = useState(false);
  const filters = props.filters || false;
  const changedisplay = (e) => {
    setdisplay(!display);
  };
  const [search, setSearch] = useState(""),[found,setFound]=useState(false);
  const changesearch = (e) => {
    setSearch(e.target.value.toUpperCase());
  };
  const arraytoobject = (data) =>
    data.reduce( (acc, val) => ({ ...acc, [val]: { value: val, status: false } }),{} );

  const selecttoobject = (data) =>
    data.reduce( (acc, val) => ({ ...acc, [val]: { value: val, status: true } }),{} );

  const mergeobjects = (objectOne, objectTwo) => {
    const union = { ...objectOne, ...objectTwo };
    const result = Object.values(union);

    const save = result
      .filter((item) => item.status === true)
      .map((item) => item.value);
    saveChange(save);
    return OrderArrayAZ(result,'value','string');
  };

  const [data, setdata] = useState([]);
  useMemo(() => {
    if (selected.length > 0) {
      setdata(mergeobjects(arraytoobject(datainit), selecttoobject(selected)));
    }
  }, [selected]);

  useMemo(()=>{
    if(!props.data.length){return;}
    if(found==true){return;}
    setFound(true)
    
    setdata(
      mergeobjects(
        arraytoobject(props.data || []),
        selecttoobject(props.selected || [])
      )
    );
  },[props.data])

  function pornombre(a, b) {
    if (a.value > b.value) return 1;
    if (a.value < b.value) return -1;
    return 0;
  }

  const changestatus = (value, newstatus) => {
    let newArray = [...data];
    const index = newArray.findIndex((element) => element.value === value);
    newArray[index].status = newstatus;
    const datasave = newArray
      .filter((item) => item.status === true)
      .map((item) => item.value);
    saveChange(datasave);
    setdata(newArray);
  };

  return (
    <div className={"flex flex-col items-center mx-auto "+props.width||"w-full"}>
        <div className="flex flex-col items-center relative w-full">
          <div className="w-full ">
            <div disabled={props?.disable?props.disable:false} className={"flex items-center justify-center rounded border border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200  text-xs px-1 w-full  uppercase "+props.className}>
              <div className="flex flex-auto flex-wrap w-11/12 ">
                {data
                  .filter((item) => item.status === true)
                  .map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-center items-center ml-1 mb-1 lg:m-1 font-medium py-0.5 lg:py-1 px-1 rounded-full text-blue-800 bg-blue-100 border border-indigo-300 "
                    >
                      <div className="text-2xs lg:text-xs font-semibold leading-none max-w-full flex-initial">
                        {item.value}
                      </div>
                      <div className="flex flex-auto flex-row-reverse">
                        <div
                          className="feather text-2xs lg:text-xs feather-x cursor-pointer hover:text-indigo-400 rounded-full w-3 h-3 lg:w-4 lg:h-4 ml-1"
                          onClick={(e) => {
                            enabled && changestatus(item.value, false);
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </div>
                      </div>
                    </div>
                  ))}

                <div className="flex-1 ">
                  <input
                    placeholder=""
                    disabled={props?.disable?props.disable:false}
                    onChange={changesearch}
                    className="bg-transparent p-1 px-1 appearance-none outline-none h-full w-full text-gray-800 uppercase"
                  />
                </div>
              </div>
              <div className="cursor-pointer text-gray-300 text-center w-5 h-5 bg-blue-200 hover:bg-blue-300  border flex items-center border-gray-300">
                <div
                  className=" w-6 h-6 text-gray-600 outline-none focus:outline-none"
                  hidden={props?.disable?props.disable:false}
                  onClick={enabled && changedisplay}
                >
                  <div className="feather feather-x  w-4 h-4 mt-1">
                    <i className="fas fa-chevron-down font-bold"></i>
                  </div>
                </div>
              </div>
              <div className="size-2-none gap-1">
                <motion.button whileHover={{scale:1.2}} whileTap={{scale:0.8, borderRadius:"100%"}} className={" w-5 h-5 rounded-full item bg-red-600 hover:text-gray-200 "+(filters?(filters==true?"block":"hidden"):"hidden")}
                      onClick={e=>{
                        e.preventDefault()
                        setdata(
                          mergeobjects(
                            arraytoobject(props.data || []),
                            selecttoobject( [])
                          )
                        );
                      }}>
                      <i className='fas fa-times text-sm text-white '></i>
                </motion.button>
                <motion.button whileHover={{scale:1.2}} whileTap={{scale:0.8, borderRadius:"100%"}} className={"w-5 h-5 rounded-full bg-blue-500 hover:text-gray-200 "+(filters?(filters==true?"block":"hidden"):"hidden")}
                      onClick={e=>{
                        e.preventDefault();
                        setdata(
                          mergeobjects(
                            arraytoobject(props.data || []),
                            selecttoobject(props.data || [])
                          )
                        );
                      }}>
                          <i className='fas fa-filter text-xs  text-white '></i>
                </motion.button>
              </div>
            </div>
          </div>
          <div
            className={
              (display ? "absolute" : "hidden") +
              " top-100 shadow bg-transparent z-40 w-full lef-0 rounded max-h-select overflow-y-auto"
            }
            style={{ height: "20vh" }}
            onMouseLeave={e=>{setdisplay(false)}}
          >
            {data
              .filter((item) => item.status === false)
              .filter((item) => item.value.toUpperCase().search(search) !== -1)
              .sort(pornombre)
              .map((item, index) => (
                <div key={index} className="flex flex-col w-full">
                  <div className="cursor-pointer w-full border-gray-100 bg-white border-b hover:bg-indigo-100 ">
                    <div
                      className="flex w-full items-center pb-1 pl-2 border-transparent border-l-2 relative border-indigo-600"
                      onClick={(e) => {
                        changestatus(item.value, true);
                      }}
                    >
                      <div className="w-full items-center flex uppercase">
                        <div className="mx-2 leading-6">{item.value} </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
    </div>
  );
};

export default Multiselector;
