import React from "react";

const Building = (props) => {
  return (
    <section className="pt-20 px-4 text-center h-full">
      <div className="max-w-auto md:max-w-lg mx-auto">
        <h2 className="text-5xl mb-2 font-semibold font-heading">
          En construcción
        </h2>

        <p className="mb-6 text-gray-400">Esta página aun no esta lista</p>
      </div>
    </section>
  );
};

export default Building;
