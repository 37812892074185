import React, { useState,useRef } from "react";
import { connect } from "react-redux";
import TableCompra from "../Compras/TableCompra";
import Container from "../Container";
import Selectormap from "../Productos/Selectormap";
import { useEffect } from "react";
import { useCustomQuery, useGetProduct } from "../../Hooks/useApis";
import { getSucursal, redondeo } from "../../services/functions";
import InputPrecioPanel from "../InputPrecioPanel";
import LoadingModal from "../modal/LoadingModal";
import Modalgeneric from "../modal/generic";
import TableModal from "../modal/TableModal";
import Modalinfo from "../modal/info";
import Labelmoney from "../labelmoney";

const PanelMermas = ({HeaderCompra}) => {
    const item0 = {id:0,descripcion : "",cantidad : 1,importe : 0,precioCompra: 0,type : "",first_item:true,first_codigo:"",}
    const [itemsAdjust, setItemsAdjust] = useState([item0]),
    [header,setHeader]=useState({personal:"",descripcion:"",cantidadTotal:0,importeTotal:0}),
    [modalinfoConfig, setmodalinfoConfig] = useState({}),
    [modalTablaConfig, setmodalTablaConfig] = useState(false),
    [modalLoading, setmodalLoading] = useState({}),
    [dataproduct, setdataproduct] = useState([]),
    [usuarios,setUsuario]=useState([]);
    const title = useRef("Sin Datos"),inputs =useRef({}),found=useRef(false);
   
    const setItem=(new_item)=>{
      let new_items = [...itemsAdjust];
      setItemsAdjust(new_items.map((item) =>
          item.id === new_item.id
          ? { ...item, 
            id:new_item.id, 
            oldExistencia:new_item.oldExistencia,
            descripcion:new_item.descripcion, 
            cantidad:new_item.cantidad, 
            precioCompra:new_item.precioCompra, 
            importe:(new_item.cantidad*new_item.precioCompra), 
            type:new_item.type,
            first_codigo:new_item.first_codigo,
            categoria: new_item.categoria,
            marca: new_item.marca,
            linea: new_item.linea,
          }
          : item
      ));
    }
    const eliminarItemCompra=(id)=>{
      let new_items = [...itemsAdjust];
      setItemsAdjust(new_items.filter((item) => item.id !== id))
    }
  
    const addItem=(new_item)=>{
      let new_items = [],find=false;
      itemsAdjust.forEach(function (item) {
        if(item.id==0){item=item0;}
        if (item.id === new_item.id) {
          const cantidadfinal = item.cantidad + 1;
    
          new_items.push({
            ...item,
            cantidad: cantidadfinal,
            importe: item.precioCompra*cantidadfinal,
          });
          find = true;
        } else {
          new_items.push(item);
        }
      });
  
      if (find) {
        setItemsAdjust(new_items);
      } else {
        setItemsAdjust([
          ...new_items,
          {
            ...new_item,
            importe:(new_item.cantidad*new_item.precioCompra), 
          },
        ]);
      }
    }
  
    function foundProduct (product,campo){
      itemsAdjust.forEach(producto=>{
        (producto.id == product)?found.current = true:"";
      })
      if(found.current){
        inputs.current[product+campo].focus();
        setItem(item0);
      }
    }
    function agregarItem(item) {
      const newitem = itemsAdjust[0];
        setItem({...newitem,
          oldExistencia:item?.sucursales?.[getSucursal()]?.existencia||0,
          cantidad:1,
          descripcion: item.descripcion,
          precioCompra: redondeo(item?.sucursales?.[getSucursal()]?.precioCompra || 0),
          type : item.type,
          first_codigo: item.id,
          categoria: item.categoria,
          marca: item.marca,
          linea: item.linea,
          responsable:"",
          ticket:"",
        })
    }
    function changecantidad(item, e) {
      const cantidad = parseInt(e.target.value);
      if(e.target.value==""||e.target.value==0){
        setItem({...item,cantidad:""});
      }else if(!isNaN(cantidad)){
        setItem({...item,cantidad:cantidad});
      }
    }
    function validProductId(id){
      const invalidCharacters = [",",".","-","+"]
      let x = true
      if(id.length<4){x=false;}
      invalidCharacters.forEach(element=> {
      
        if (id.includes(element)){
          //
          x = false
        }
      });
      return x
    }
  
    const funcionExito = (dataproductos) => {
      const data = dataproductos.productos;
      closemodalLoading();
      //
      if(dataproductos.linea){
        setmodalinfoConfig({ isOpen: true,message: "Los ajustes de Micas debe hacerse en el Modulo de Inventario ",});
        return;
      }else{
        if (data.length === 0) {
          title.current ="Producto No Encontrado";
          setmodalinfoConfig({ isOpen: true,message: ("No existe el producto "+itemsAdjust[0]?.first_codigo),});
        }
        if (data.length === 1){ 
          foundProduct(data[0].id,"cantidad");
          if(found.current==false){
            inputs.current[0+"cantidad"].focus();
            agregarItem(data[0]);
          }else {found.current = false;}
        } 
        if (data.length > 1) {
          setdataproduct(data);
          setmodalTablaConfig({ isOpen: true });
        }
      }
    };
  
    const funcionError = (error) => {
      setmodalinfoConfig({ isOpen: true, message: error?.response?.data?.message,});
    };
  
    const handleSuccessful=(data)=>{
    const personas = data.datos
    let usuarios = {};
      if(personas.length==0){return;}
      personas.map(personal=>usuarios[personal?.id]=personal?.nombre)
      setUsuario(usuarios)
    }
    const handleError = (error) => {
      
    }
  
    const openmodalLoading = (e) => {
      setmodalLoading({ isOpen: true, title });
    };
    const closemodalLoading = (e) => {
     setmodalLoading({ isOpen: false });
    };
    function closeModaltabla(e) {
      setmodalTablaConfig({ isOpen: false });
    }
    function closeModalinfo(e) {
      setmodalinfoConfig({ isOpen: false });
      inputs.current[0+"codigo1"].focus();
      setItem(item0)
    }
  
    useEffect(()=>{
      const peticion ={
        indexName:"reverseIndex",
        hashKey:{valor:("USER"),nombre:"type"},
        filters:[{valor:getSucursal(),operador:'eq',nombre:'sucursal'}]
      }
      useCustomQuery(peticion,handleSuccessful,handleError);
    },[])
  
    return (
      <>
      <LoadingModal config={modalLoading} >
          <LoadingModal.Body>
            <LoadingModal.Title>Cargando Servicios</LoadingModal.Title>
          </LoadingModal.Body>
      </LoadingModal>
  
      <Modalgeneric config={modalTablaConfig}>
          <Modalgeneric.Body>
            <Modalgeneric.Title>Elija una opción</Modalgeneric.Title>
            <TableModal
              columns={[
                { header: "Clave", accessor: "id" },
                { header: "Nombre", accessor: "nombre" },
                { header: "Descripción", accessor: "descripcion" },
              ]}
              onclickRow={(item) => {
                agregarItem(item);
                closeModaltabla();
              }}
              data={dataproduct}
            />
          </Modalgeneric.Body>
          <Modalgeneric.Buttons>
            <button
              type="button"
              onClick={closeModaltabla}
              className="btn-gray2"
            >
              Cancelar
            </button>
          </Modalgeneric.Buttons>
        </Modalgeneric>
  
        <Modalinfo config={modalinfoConfig}>
          <Modalinfo.Message title={title.current}>
            {modalinfoConfig.message}
          </Modalinfo.Message>
          <Modalinfo.Buttons>
            <button
              type="button"
              className="btn-blue1"
              onClick={closeModalinfo}
            >
              Cerrar
            </button>
          </Modalinfo.Buttons>
        </Modalinfo>
      <Container bg="bg-mesh">
         <div className="flex flex-col gap-2 mt-1 px-2 w-full lg:w-1/3 items-center lg:items-start">
            <div className="w-full size-3-none">
              <label className="text-white text-size font-bold">Personal: </label>
              <Selectormap 
              width={"w-full bg-input-mesh col-span-2"}
              catalogo={usuarios}
              getvalor={(valor) => {
                if(!valor?.clave){return;}
                setHeader({...header,personal:valor.clave})
              }}/>
            </div>
            <div className="w-full size-3-none">
              <label className="text-white text-size font-bold">Referencia: </label>
              <input 
              type="text" 
              className="border-form w-full uppercase bg-input-mesh col-span-2"
              maxLength="50"
              onChange={e=>{
              }}
              />
            </div>
            <button className="btn-dark-free h-12 hover:text-yellow-200 bg-yellow-500 hover:bg-yellow-600 w-full md:w-32"
            onClick={e=>{
              if(itemsAdjust.length<2){return}
              
              
            }}>
              Hacer Ajuste
            </button>
        </div>
        
          <div className="flex flex-grow w-full mx-auto">
              <TableCompra
              searchinput={false}
              columns={[
                {
                  header: "maker/style",
                  width:"w-32",
                  // eslint-disable-next-line react/display-name
                  code: (item,index) => (
                    <div className="flex flex-row w-full gap-2">
                      
                      <input className="w-0" ref={node=>{inputs.current[item?.id+"codigo"]=node}}/>
                      <input
                        type="text"
                        className="border-table w-full "
                        placeholder=""
                        ref={node=>{inputs.current[item?.id+"codigo1"]=node}}
                        value={item?.id||item?.first_codigo}
                        readOnly={!item?.first_item}
                        onChange={e=>{setItem({...item,first_codigo:e.target.value.trim().toUpperCase()})}}
                        onKeyDown={e=>{
                          if((e.key=="Enter"||e.key=="Tab")&&item?.first_codigo){
                            foundProduct(item.first_codigo,"descripcion");
                            if(found.current==false){
                              if(validProductId(item.first_codigo)){
                                const peticion ={producto:item.first_codigo,funcionExito:funcionExito,funcionError:funcionError};
                                if(item.first_codigo.substring(0,2)=="MI"||item.first_codigo.substring(0,2)=="LC"||item.first_codigo.substring(0,2)=="AR"){
                                  peticion.linea = true;
                                }
                                openmodalLoading(e);
                                useGetProduct(peticion);
                              }
                              else{
                                setmodalinfoConfig({isOpen: true,message: "El id "+item.first_codigo+" no es válido. " });
                                setItem(item0)
                              }
                            }else {found.current = false;}
                          }else if(e.key=="Escape"){
                            setItem(item0);
                          }
                        }}
                      />
                        <button
                        className="text-red-600 transform hover:text-pink-700 hover:scale-110 " hidden={HeaderCompra?.id?true:item?.first_item}
                          onClick={(e) => {
                            eliminarItemCompra(item.id);
                          }}
                        >
                          <i className="fas fa-times-circle"></i>
                        </button>
                  </div>
                  ),
                },
                {
                  header: "Description",
                  width:"w-96",
                   // eslint-disable-next-line react/display-name
                  code:(item)=>(
                    <input className="border-table w-full" readOnly={true} ref={node=>{inputs.current[item?.id+"descripcion"]=node}} value={item?.descripcion}/>
                  )
                },
                {
                  header: "Qty",
                  width:"w-28 ",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (
                      <input
                        type="number"
                        className={"w-full rounded disabled:opacity-50 text-base font-semibold outline-none  transition-colors duration-200 ease-in-out focus:shadow-outline bg-input-mesh "+(item.cantidad <= 0 ? "text-red-500" : "text-black" )}
                        value={item.cantidad}
                        ref={node=>{inputs.current[item?.id+"cantidad"]=node}}
                        onChange={(e) => {
                            changecantidad(item, e);
                        }}
                        onBlur={e=>{
                          if(e.target.value==""||e.target.value==0){ inputs.current[item.id+"cantidad"].focus()}
                        }}
                      />
                  ),
                },
                {
                  header: "Unit Cost",
                  width:"w-28",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (
                    <InputPrecioPanel
                      width="w-full"
                      min="0"
                      readOnly={true}
                      newValor={item?.precioCompra}
                      setValor={(e)=>{}}/>
                  ),
                },
                {
                  header: "Amount",
                  width:"w-36",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (
                      <InputPrecioPanel
                      width="w-full"
                      newValor={item?.importe}
                      readOnly={true}
                      setValor={(e)=>{}}
                      onKeyPress={e=>{
                        if((e.key=="Tab"||e.key=="Enter")&&item?.first_item&&item?.first_codigo){
                          const newitem ={...item,id:item.first_codigo};
                          delete newitem.first_codigo;
                          delete newitem.first_item;
                          addItem(newitem);
                        }
                      }}
                      />
                  ),
                },
              ]}
              footer={[
                {width:"w-32"},{width:"w-96"},{width:"w-28"},{width:"w-28 bg-ambar-100", code:<p>Total</p>},
                { width:"w-36 bg-ambar-100  ",
                // eslint-disable-next-line react/display-name
                  code: <Labelmoney twoDecimal={true} className="text-size">{itemsAdjust.reduce((a, b) => parseInt(a) + (b?.id!==""?parseInt(b.importe):0), 0)}</Labelmoney>
                }
              ]}
              data={itemsAdjust}
          />
          </div>
  
          <div className="mx-auto">
  
          </div>
      </Container>
      </>
    );
  };
  
  const mapDispatchToProps = (dispatch) => ({
    setPropsInventario: (estado) => dispatch({type:"SET_PROPSINVENTARIO", estado:estado}),
  });
  
  const mapStateToProps = (state) => ({
    HeaderCompra: state.HeaderCompra,
  });
export default connect(mapStateToProps, mapDispatchToProps)(PanelMermas)