import React from "react";
import HeaderInventario from "../components/Inventario/HeaderInventario.jsx";
import FooterInventario from "../components/Inventario/FooterInventario";
import Container from "../components/Container";
import WrapperTable from "../components/Inventario/WrapperTable.jsx";

const Inventario = () => {
  return (
    <Container div3=" items-center" >
      <HeaderInventario/>
       <div className="relative h-90/100 overflow-y-auto overflow-x-auto w-94/100" >
        <WrapperTable/>
      </div>
      <FooterInventario/> 
  </Container>
  );
};

export default Inventario;
