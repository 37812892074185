import React, { useEffect, useState } from 'react';
import Modaldanger from '../modal/danger';
import { FechaLocalToUTC, apiUsePromise, getArrayAsync, getDescuentoMax, getSucursal, havePermission } from "../../services/functions.js";
import { connect } from 'react-redux';
import { agregarInventarioDetCompra, returnRuta, setRuta } from '../../redux/Actions';
import Modalsuccess from '../modal/success';
import { useInsertMerma, useUpdateCompra, useUpdateProducto } from '../../Hooks/useApis';
import { useRef } from 'react';
import LoadingModal from '../modal/LoadingModal';
import {motion} from 'framer-motion';
import { permisos } from '../../static/dat/datos.js';

const FooterInventario=({Inventario,PropsInventario,ItemsCompra,setInventarioCompra,setCleanInventario,setInventario0,delRuta,Ruta,setPropsInventario,resetDatos,setInventario,PropsReportes,Rango})=> {
    const [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
      [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
      [btnDisable,setDisable] = useState(false),
      [peticiones,setPeticiones] = useState({}),
      [lotes,setLotes]= useState([]),
      [modalLoading, setmodalLoading] = useState({});
    const inventario=useRef([]),title = useRef("Sin Datos"),inputOferta=useRef();
    const LogitudLote =20;
    const on = {scale:1.1,color:"#FBF9EF"},off={scale:1.0,color:"#250000"},oldRuta =Ruta?.[Ruta.length-2];
    const permisoConfiguracion = havePermission(permisos.altaProveedor);

    const getitem=(id)=>{
      let item = PropsInventario.items.find(item=>item.id == id);
      return {...item}
    }

    const handleErrorDatos =(msg)=>{
      setmodaldangererrorConfig({
        isOpen: true,
        message: ""+msg ,
      });
    }
    const handleErrorConteo =(msg)=>{
      setmodaldangererrorConfig({
        isOpen: true,
        message: ""+msg ,
      });
      return -1;
    }
    const funcionExitoMerma=(respuesta)=>{
      //console.log(respuesta);
      closemodalLoading();
      setmodalSuccessConfig({
        isOpen: true,
        message: respuesta,
        title: "AJUSTE REALIZADO CON EXITO"
      }); 
    }
    const handleSuccessfulUpdateProdFinish= (data) => {
      if(PropsInventario?.updatePrice){
        const props={...PropsInventario};
        delete props.ajuste;
        delete props.editItems;
        delete props.inventarioAjuste;
        delete props.updatePrice;
        setPropsInventario(props);
      }
      closemodalLoading();
      setDisable(false);
      setPeticiones({});
      setLotes([])
      setCleanInventario()
      setmodalSuccessConfig({
        isOpen: true,
        message: data,
      }); 
    };
    const handleSuccessfulSave = (data) => {
      //console.log('seguire haciedno algo');
      setLotes(inventario.current.splice(0,LogitudLote));
    };
    const handleSuccessfulprod = (data) => {
      if(peticiones?.updateProduct){
        setDisable(true);
        setLotes(inventario.current.splice(0,LogitudLote));
      }else{
        closemodalLoading();
        //console.log('algo anda mal')
        setPeticiones({});
        setmodalSuccessConfig({
          isOpen: true,
          message: data,
        }); 
      }
    };
  
    const openmodalLoading = (titulo) => {
      title.current= titulo||"Procesando Datos";
      setmodalLoading({ isOpen: true, title });
    };
    const closemodalLoading = (e) => {
     setmodalLoading({ isOpen: false });
    };
      
    function closeModalsuccess(e) {
      const newdataconfig = { ...modalSuccessConfig };
      newdataconfig.isOpen = false;
      setmodalSuccessConfig(newdataconfig);
      setInventario0({type: "RESET_PROPSINVENTARIO"});
      resetDatos();
      if(oldRuta=="compra"){
        delRuta();
      }
    }

    function closeModaldangererror(e) {
        const newdataconfig = { ...modaldangererrorConfig };
        newdataconfig.isOpen = false;
        setmodaldangererrorConfig(newdataconfig);
      }
    const handleError = (error) => {
      closemodalLoading();
      setmodaldangererrorConfig({
        isOpen: true,
        message: error || "aqui va otro mensaje de error" ,
      });
    }

    const getCompras=async(filtroFecha)=>{
      let data=[],datajoin=[],datajoinMenor=[],dataMenor=[],peticiones = [],peticionesCompraMenor=[],lotes=5;
      
      PropsInventario.sucursales.forEach(suc=>{
        peticiones.push({
          hashKey:{valor:"COMPRA#"+suc+"#INVENTARIO#"+PropsInventario.data.id,nombre:"id"},
          sortKey:{valor:filtroFecha,operador:"between",nombre:"type"},
        });
        peticionesCompraMenor.push({
          indexName:"sparseIndex",
          hashKey:{valor:"COMPRA#"+suc+"#LINEA#"+PropsInventario.data.linea,nombre:"statusTemp"},
          sortKey:{valor:filtroFecha,operador:"between",nombre:"id"},
          filters:[{valor:"PRODUCT",operador:"eq",nombre:"tipo"}]
        })
      })
       //peticionInventario.filters=[{valor:PropsInventario.sucursales[0],operador:"eq",nombre:"sucursal"}]
     
       for(let i=0; i<peticiones.length; i+=lotes){
        const lote = peticiones.slice(i,i+lotes);
        const requests =  lote.map(async (peticion, index)=>{
          let datos=[];
          await new Promise(resolve=> setTimeout(resolve,index));
          datos = await getArrayAsync(peticion,[]);
          return datos
        });
        const respuestas = await Promise.all(requests);
        datajoin = datajoin.concat(respuestas);
        await new Promise(resolve=>setTimeout(resolve,200));
      }

      for(let i=0; i<peticionesCompraMenor.length; i+=lotes){
        const lote = peticionesCompraMenor.slice(i,i+lotes);
        const requests =  lote.map(async (peticion, index)=>{
          let datos=[];
          await new Promise(resolve=> setTimeout(resolve,index));
          datos = await getArrayAsync(peticion,[]);
          return datos
        });
        const respuestas = await Promise.all(requests);
        datajoinMenor = datajoinMenor.concat(respuestas);
        await new Promise(resolve=>setTimeout(resolve,200));
      }
      //console.log('>>>',datajoinMenor)

      datajoinMenor.forEach(dat=>{ dataMenor = dataMenor.concat(dat); })
      datajoin.forEach(dat=>{ data = data.concat(dat); })

      return [data,dataMenor]
    }

    const getVentas=async(filtroFecha)=>{
      let datajoin=[],peticiones = [],lotes=5;

      PropsInventario.sucursales.forEach(suc=>{
        peticiones.push({
          indexName:"sparseIndex",
          hashKey:{valor:"VENTA#"+suc+"#LINEA#"+PropsInventario.data.linea,operador:"eq",nombre:"statusTemp"},
          sortKey:{valor:filtroFecha,operador:"between",nombre:"id"},
        })
      });


      for(let i=0; i<peticiones.length; i+=lotes){
        const lote = peticiones.slice(i,i+lotes);
        const requests =  lote.map(async (peticion, index)=>{
          let datos=[];
          await new Promise(resolve=> setTimeout(resolve,index));
          datos = await getArrayAsync(peticion,[]);
          return datos
        });
        const respuestas = await Promise.all(requests);
        //console.log('==>',respuestas)
        respuestas.forEach(resp=>{
          datajoin = datajoin.concat(resp)
        })
        //datajoin = datajoin.concat(respuestas);
        //console.log('>>>',datajoin)
        await new Promise(resolve=>setTimeout(resolve,200));
      }
     // console.log(datajoin)
      return datajoin;
    }

    const getDevoluciones=async(filtroFecha)=>{
      let data=[],datajoin=[],datajoinMenor=[],dataMenor=[],peticiones=[],lotes=5;
      
      PropsInventario.sucursales.forEach(suc=>{
        peticiones.push({
          indexName:"sparseIndex",
          hashKey:{valor:"TRASPASO#"+suc+"#LINEA#"+PropsInventario.data.linea,nombre:"statusTemp"},
          sortKey:{valor:filtroFecha,operador:"between",nombre:"id"},
          filters:[{valor:"PRODUCT",operador:"eq",nombre:"tipo"}]
        })
      })
       //peticionInventario.filters=[{valor:PropsInventario.sucursales[0],operador:"eq",nombre:"sucursal"}]
     
      for(let i=0; i<peticiones.length; i+=lotes){
        const lote = peticiones.slice(i,i+lotes);
        const requests =  lote.map(async (peticion, index)=>{
          let datos=[];
          await new Promise(resolve=> setTimeout(resolve,index));
          datos = await getArrayAsync(peticion,[]);
          return datos
        });
        const respuestas = await Promise.all(requests);
        datajoinMenor = datajoinMenor.concat(respuestas);
        await new Promise(resolve=>setTimeout(resolve,200));
      }
      //console.log('>>>',datajoinMenor)

      datajoinMenor.forEach(dat=>{ dataMenor = dataMenor.concat(dat); })
      datajoin.forEach(dat=>{ data = data.concat(dat); })

      return [data,dataMenor]
    }

    const getGarantias=async(filtroFecha)=>{
      let datajoin=[],peticiones = [],lotes=5;

      PropsInventario.sucursales.forEach(suc=>{
        peticiones.push({
          hashKey:{valor:"MERMA#"+suc,nombre:"id"},
          sortKey:{valor:filtroFecha,operador:"between",nombre:"type"},
        })
      });

      for(let i=0; i<peticiones.length; i+=lotes){
        const lote = peticiones.slice(i,i+lotes);
        const requests =  lote.map(async (peticion, index)=>{
          let datos=[];
          await new Promise(resolve=> setTimeout(resolve,index));
          datos = await getArrayAsync(peticion,[]);
          return datos
        });
        const respuestas = await Promise.all(requests);
        datajoin = datajoin.concat(respuestas);
        await new Promise(resolve=>setTimeout(resolve,200));
      }
      return datajoin[0];
    }

    const getAjustes=async(filtroFecha)=>{
      let datajoin=[],peticiones = [],lotes=5;

      PropsInventario.sucursales.forEach(suc=>{
        peticiones.push({
          hashKey:{valor:"AJUSTE#"+suc,nombre:"id"},
          sortKey:{valor:filtroFecha,operador:"between",nombre:"type"},
        })
      });

      for(let i=0; i<peticiones.length; i+=lotes){
        const lote = peticiones.slice(i,i+lotes);
        const requests =  lote.map(async (peticion, index)=>{
          let datos=[];
          await new Promise(resolve=> setTimeout(resolve,index));
          datos = await getArrayAsync(peticion,[]);
          return datos
        });
        const respuestas = await Promise.all(requests);
        datajoin = datajoin.concat(respuestas);
        await new Promise(resolve=>setTimeout(resolve,200));
      }
      return datajoin[0];
    }

    function calcularMesesPasados(desde, hasta) {
      var desdeFecha = new Date(desde);
      var hastaFecha = new Date(hasta);
  
      var meses = (hastaFecha.getFullYear() - desdeFecha.getFullYear()) * 12;
      meses -= desdeFecha.getMonth() + 1;
      meses += hastaFecha.getMonth() + 1;
  
      return meses;
  }

    useEffect(() => {
      if(!lotes.length>0){return;}
      //console.log('lote a enviar=>',lotes,"\nContenedor=>",inventario.current)
      useUpdateProducto(lotes,(inventario.current.length>0?handleSuccessfulSave:handleSuccessfulUpdateProdFinish),handleError);
    }, [lotes])

    useEffect(() => {
       if(!peticiones?.updateCompra){return;}
      //console.log(peticiones)
      useUpdateCompra(peticiones.updateCompra,handleSuccessfulprod,handleError)
    }, [peticiones])

    useEffect(()=>{
      const filtroFecha = [FechaLocalToUTC(PropsReportes.fecha_Inicial),FechaLocalToUTC(PropsReportes.fecha_Final,"23","59","59")];
      if(!PropsInventario?.tab){return}
      const CambioPestana=async()=>{
        let newInventario = [];

        if(!["BT","S","ST","W","G2","DT"].includes(PropsInventario?.tab)){ return; }

        if (PropsInventario?.tab=="BT") {
          let new_inv = [...Inventario];
          openmodalLoading("Recuperando Compras del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final);

          new_inv.forEach(item=>{delete item.BT;});
          let [data,dataMenor] = await getCompras(filtroFecha);

          if(data.length){
            data.forEach(dato=>{
              dato?.inventario
              ?dato.inventario.forEach(item => {
                new_inv.forEach(inv => {
                  (inv.id == item.id? inv.BT =(inv?.BT||0)+item.cantidad: "")
                });
              })
              :"";
            })
          }
          newInventario = new_inv;
          //console.log('===>>',dataMenor)
          
          if(dataMenor.length){
            const datos = dataMenor;
            let arraycompras={};
            datos.forEach(prod=>{
              //console.log(prod)
              let id = prod.type.split("#")[2]||"";
              if(!arraycompras?.[id]){ 
                //console.log('first')
                arraycompras[id]={id:id,cantidad:prod.cantidadComprada};
              }else{
                //console.log('edn')
                arraycompras[id].cantidad += prod.cantidadComprada;
              }
            });

            if(JSON.stringify(arraycompras)!=""){
              newInventario.forEach(inv => {
                  (inv.id == arraycompras[inv.id]?.id? inv.BT =(inv?.BT||0)+arraycompras[inv.id].cantidad: "")
              });
            }
          }
          setInventario(newInventario);
          closemodalLoading();
        }

        if (PropsInventario?.tab=="ST") {
          let new_inv = [...Inventario];
          openmodalLoading("Recuperando Ventas del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final);  
          new_inv.forEach(item=>{ delete item.ST;});
          let ventas = await getVentas(filtroFecha);

          if(ventas){
            const datos= ventas;
            datos.forEach(item=>{
              const codigo = item.type.split('#')[2];
    
              if(PropsInventario.sucursales.includes(item?.sucursal)){
                //console.log(codigo)
                new_inv.forEach(inv => {
                  (inv.id == codigo? inv.ST =(inv?.ST||0)+item.cantidadVendida: "")
                });
              }
            })
          }
          setInventario(new_inv);
          closemodalLoading();
        }

        if (PropsInventario?.tab=="DT") {
          let new_inv = [...Inventario];
          openmodalLoading("Recuperando Devoluciones del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final);

          new_inv.forEach(item=>{delete item.DT;});
          let [data,dataMenor] = await getDevoluciones(filtroFecha);

          /*if(data.length){
            data.forEach(dato=>{
              dato?.inventario
              ?dato.inventario.forEach(item => {
                new_inv.forEach(inv => {
                  (inv.id == item.id? inv.BT =(inv?.BT||0)+item.cantidad: "")
                });
              })
              :"";
            })
          }*/
          newInventario = new_inv;
        
          //console.log('===>>',dataMenor)
          
          if(dataMenor.length){
            const datos = dataMenor;
            let arraycompras={};
            datos.forEach(prod=>{
              //console.log(prod)
              let id = prod.type.split("#")[2]||"";
              if(!arraycompras?.[id]){ 
                //console.log('first')
                arraycompras[id]={id:id,cantidad:prod.cantidadComprada};
              }else{
                //console.log('edn')
                arraycompras[id].cantidad += prod.cantidadComprada;
              }
            });
            //console.log(arraycompras)

            if(JSON.stringify(arraycompras)!=""){
              newInventario.forEach(inv => {
                  (inv.id == arraycompras[inv.id]?.id? inv.DT =(inv?.DT||0)+arraycompras[inv.id].cantidad: "")
              });
            }
          }
          setInventario(newInventario);
          closemodalLoading();
        }


        if(PropsInventario?.tab=="S"){
          let new_inv = [...Inventario];
            openmodalLoading("Calculando Ajustes del Mes");
            new_inv.forEach(item=>{ delete item.S;});
            let ajustes = await getAjustes(filtroFecha);

            if(ajustes.length){
              const datos = ajustes;
              datos.forEach(dato=>{
                dato.items.forEach(item=>{
                  new_inv.forEach(inv => {
                    (inv.id == item.id? inv.S =(inv?.S||0)+item.cantidad: "")
                  });
                })
              })
              setInventario(new_inv);
            }
            else{
              setmodaldangererrorConfig({
                isOpen: true,
                message: "No hubo Ajustes este mes" ,
              });
            }
            closemodalLoading();
        }
        if(PropsInventario?.tab=="W"){
          let new_inv = [...Inventario];
    
          openmodalLoading("Calculando Garantias del Mes");
          new_inv.forEach(item=>{ delete item.W;});
          let garantias = await getGarantias(filtroFecha);

          if(garantias.length){
            const datos = garantias;

            datos.forEach(dato=>{
              dato.items.forEach(item=>{
                new_inv.forEach(inv => {
                  (inv.id == item.id? inv.W =(inv?.W||0)+item.cantidad: "")
                });
              })
            })
            setInventario(new_inv);
          }
          else{
            setmodaldangererrorConfig({
              isOpen: true,
              message: "No hubo Garantias este mes" ,
            });
          }
          closemodalLoading();
        }
        
        if(PropsInventario?.tab=="G2"){
          const meses = calcularMesesPasados(PropsReportes.fecha_Inicial,PropsReportes.fecha_Final);
          if(meses<Rango){
            setmodaldangererrorConfig({
              isOpen: true,
              message: "El minimo de meses debe ser de "+Rango ,
            });
            return;
          }
          openmodalLoading("Calculando Ventas de "+meses+" meses");
          const newCampo = {ST:0,PM:0};
          let new_inv = [...Inventario];
          const jsonDiff ={};
          const ventas = await getVentas(filtroFecha);

          ventas.forEach(item=>{
            const codigo = item.type.split('#')[2];

            if(!jsonDiff?.[codigo]){
              jsonDiff[codigo]={...newCampo,ST:item.cantidadVendida}
            }else{
              jsonDiff[codigo].ST+= item.cantidadVendida;
            }
          })
          
          Object.keys(jsonDiff).forEach((key,index)=>{
            jsonDiff[key].ST =  Math.round((jsonDiff[key].ST/meses)*Rango);
          })

          new_inv.forEach(inv=>{
            const diferencia= Math.round((inv.guideline/100)*10);
            if(jsonDiff?.[inv.id]){
              /*if(jsonDiff[inv.id].ST>=(inv.guideline+diferencia)){//273>=276
                inv.G2 = inv.guideline+diferencia;
              }else*/ if(jsonDiff[inv.id].ST<=(inv.guideline-diferencia)){//273<=226
                inv.G2 = inv.guideline-diferencia;
              }else{
                inv.G2 = jsonDiff[inv.id].ST;
              }
              if (inv.G2 % 2 !== 0) {
                inv.G2 = inv.G2 + 1;
              }
              inv.ST = jsonDiff[inv.id].ST
            }else{inv.G2=0;}

            inv.G2 == 0? inv.G2 = 2 : "";
          })

          setInventario(new_inv);
          closemodalLoading();
        }
        if(PropsInventario?.tab=="INV"){
          let [compras,comprasMenor] = await getCompras(filtroFecha);
          const newCampo = {BT:0,ST:0,W:0,S:0};
          const ventas = await getVentas(filtroFecha);
          /*const garantias = await getGarantias(filtroFecha);
          const ajustes = await getAjustes(filtroFecha);*/
          //console.log(compras);
          const jsonDiff ={};

          compras.forEach(compra=>{
            compra.inventario.forEach(inv=>{
              if(!jsonDiff?.[inv.id]){
                jsonDiff[inv.id]={...newCampo,BT:inv.cantidad}
              }else{
                jsonDiff[inv.id].BT+= inv.cantidad;
              }
            })
          });

          comprasMenor.forEach(compra=>{
            const codigo = compra.type.split("#")[2];
            if(!jsonDiff?.[codigo]){
              jsonDiff[codigo]={...newCampo,BT:compra.cantidadComprada}
            }else{
              jsonDiff[codigo].BT+= compra.cantidadComprada;
            }
          })

          ventas.forEach(item=>{
            const codigo = item.type.split('#')[2];

            if(!jsonDiff?.[codigo]){
              jsonDiff[codigo]={...newCampo,ST:item.cantidadVendida}
            }else{
              jsonDiff[codigo].ST+= item.cantidadVendida;
            }
          })

          /*console.log('==>>',jsonDiff)
          console.log(comprasMenor)
          console.log(ventas)*/
        }
      }
      CambioPestana();
    },[PropsInventario.sucursales,PropsInventario?.tab,PropsReportes.fecha_Inicial,PropsReportes.fecha_Final])
      
    return (
        <>
         <LoadingModal config={modalLoading} >
          <LoadingModal.Body>
            <LoadingModal.Title>{title.current}</LoadingModal.Title>
          </LoadingModal.Body>
        </LoadingModal>
        <Modaldanger config={modaldangererrorConfig}>
            <Modaldanger.Message title={"Error"}>
            {modaldangererrorConfig.message}
            </Modaldanger.Message>
            <Modaldanger.Buttons>
            <button
                type="button"
                className="btn-gray2 "
                onClick={closeModaldangererror}
            >
                Cerrar
            </button>
            </Modaldanger.Buttons>
        </Modaldanger>
        <Modalsuccess config={modalSuccessConfig}>
        <Modalsuccess.Body title={modalSuccessConfig.title}>
          Producto Guardado Con Exito
        </Modalsuccess.Body>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
      <div className='size-col-1 w-full justify-center'>
        <ul className={(!PropsInventario.editItems?(PropsInventario?.data?.linea?"w-full justify-center flex flex-wrap text-sm text-center text-gray-500 dark:text-gray-400 my-1":"hidden"):"hidden")}>
          <li className="">
            <motion.button 
            whileHover={on} 
            animate={PropsInventario?.tab=="IN"?on:off} 
            className={"font-semibold border-r border-gray-400 py-1 px-3 rounded-l-lg  hover:bg-ambar-300 "+(PropsInventario?.tab=="IN"?"bg-ambar-300 ":"bg-ambar-200")} 
            onClick={e=>{
                setPropsInventario({...PropsInventario,tab:"IN"});
              }}>
              {PropsInventario?.data?.linea}
            </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="BT"?on:off} 
              className={"font-semibold border-r border-gray-400 py-1 px-3 hover:bg-pink-500 "+(PropsInventario?.tab=="BT"?"bg-pink-500":"bg-pink-200")} 
              onClick={e=>{
                 setPropsInventario({...PropsInventario,tab:"BT"});
              }}>
                BT
              </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="ST"?on:off} 
              className={"font-semibold border-r border-gray-400 py-1 px-3 hover:bg-cyan-500 "+(PropsInventario?.tab=="ST"?"bg-cyan-500":"bg-cyan-200")}
              onClick={e=>{
                setPropsInventario({...PropsInventario,tab:"ST"});
              }}>
                ST
              </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="DT"?on:off} 
              className={"font-semibold border-r border-gray-400 py-1 px-3 hover:bg-blue-400 "+(PropsInventario?.tab=="DT"?"bg-blue-400":"bg-gray-100")} 
              onClick={e=>{
                 setPropsInventario({...PropsInventario,tab:"DT"});
              }}>
                DT
              </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="S"?on:off}
              className={"font-semibold border-r border-gray-400 py-1 px-3 hover:bg-gray-500 "+(PropsInventario?.tab=="S"?"bg-gray-500":"bg-gray-100")} 
              onClick={e=>{
                 setPropsInventario({...PropsInventario,tab:"S"});
              }}>
                S
              </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="W"?on:off}  
              className={"font-semibold border-r border-gray-400 py-1 px-3 hover:bg-yellow-500 "+(PropsInventario?.tab=="W"?"bg-yellow-500":"bg-yellow-100 ")} 
              onClick={e=>{
                 setPropsInventario({...PropsInventario,tab:"W"});
              }}>
                W
              </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="G"?on:off} 
              className={"font-semibold border-r border-gray-400 py-1 px-3 hover:bg-green-500 "+(PropsInventario?.tab=="G"?"bg-green-500":"bg-green-100")}
              onClick={e=>{
                setPropsInventario({...PropsInventario,tab:"G"});
              }}>
                G
              </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="N"?on:off} 
              className={"font-semibold border-r border-gray-400 py-1 px-3 hover:bg-blue-500 "+(PropsInventario?.tab=="N"?"bg-blue-500 ":"bg-blue-100")} 
              onClick={e=>{
               setPropsInventario({...PropsInventario,tab:"N"});
              }}>
                N
              </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="O"?on:off} 
              className={"font-semibold border-r border-gray-400 py-1 px-3 hover:bg-red-500 "+(PropsInventario?.tab=="O"?"bg-red-500":"bg-red-200")} 
              onClick={e=>{
               setPropsInventario({...PropsInventario,tab:"O"});
              }}>
              {PropsInventario?.data?.linea+"-O"}
              </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="OP"?on:off} 
              className={"font-semibold border-r border-gray-400 py-1 px-3 hover:bg-purple-500 "+(PropsInventario?.tab=="OP"?"bg-purple-500":"bg-purple-200")} 
              onClick={e=>{
               setPropsInventario({...PropsInventario,tab:"OP"});
              }}>
              OP
              </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="OT"?on:off} 
              className={"font-semibold border-r border-gray-400 py-1 px-3 hover:bg-teal-500 "+(PropsInventario?.tab=="OT"?"bg-teal-500":"bg-teal-200")} 
              onClick={e=>{
               setPropsInventario({...PropsInventario,tab:"OT"});
              }}>
              OT
              </motion.button>
          </li>
          <li className="">
              <motion.button 
              whileHover={on} 
              animate={PropsInventario?.tab=="G2"?on:off} 
              className={"font-semibold py-1 px-3 rounded-r-lg hover:bg-gray-500 "+(PropsInventario?.tab=="G2"?"bg-gray-500":"bg-gray-300")} 
              onClick={e=>{
               setPropsInventario({...PropsInventario,tab:"G2"});
              }}>
              G2
              </motion.button>
          </li>
          { /*permisoConfiguracion?
            <li className="">
            <motion.button 
            whileHover={on} 
            animate={PropsInventario?.tab=="UP"?on:off} 
            className={"font-semibold py-1 px-3 rounded-2xl hover:bg-teal-600 "+(PropsInventario?.tab=="UP"?"bg-teal-600":"bg-teal-400")} 
            onClick={e=>{
             setPropsInventario({...PropsInventario,tab:"UP"});
            }}>
            UP
            </motion.button>
            </li>
            :null*/
          }
        </ul>
        <div className='flex flex-wrap w-full gap-3 justify-center'>
          <button
          className={""+(PropsInventario?.tab=="B"?(PropsInventario?.idcompra&&PropsInventario?.inv_completo?"hidden":"btn-gray1"):(PropsInventario?.tab=="G"&&PropsInventario?.importExcel?"btn-gray1":"hidden"))}
          onClick={e=>{
            let new_inventario = [...Inventario];
            new_inventario.map(item=>{
              if(PropsInventario?.tab=="G"){
                item.guideline = item.guideline*2;
              }else if(PropsInventario?.tab=="B"){
                item.cantidad = item.cantidad*2;
              }
              
            })
            setInventario(new_inventario);
          }}>
            x2
          </button>
          {PropsInventario?.tab=="G2"?
            <button
            className={"btn-gray1"}
            onClick={e=>{
              let new_inventario = [...Inventario];
              new_inventario.map(item=>{
                if(PropsInventario?.tab=="G2"){
                  const g2 = item.G2;
                  item.G2 = item.ST;
                  item.ST = g2;
                }
              })
              setInventario(new_inventario);
            }}>
              Ventas Promediado
            </button>
            :null
          }
 
          <button
          className={(PropsInventario?.tab=="O"?"btn-gray1":"hidden")}
          onClick={e=>{
            let new_inventario = [...Inventario];
            new_inventario.map(item=>{
              if(PropsInventario?.tab=="O"){
                item.N = (item.N>0?item.N/2:0);
              }
            })
            setInventario(new_inventario);
            setPropsInventario({...PropsInventario,Pares:true})
          }}>
            Pares
          </button>
          <button 
          type="action" 
          className={PropsInventario?.cantidad?"btn-blue":"hidden "}
          disabled={PropsInventario?.inv_completo || !PropsInventario?.idcompra || btnDisable}
          onClick={e=>{
                let CantidadTotal = 0,detCompra=[];
                inventario.current = [...Inventario];

                Inventario.forEach(element => {
                  //console.log('item sacado del inventario ',element)
                  if(element.precioVenta!=0){CantidadTotal += parseInt(element.cantidad);
                  }else{ CantidadTotal = handleErrorConteo("La Fila de la graduacion "+element.id+" debe tener precio"); return;}
                  detCompra.push({...element})
                  //delete element.linea; delete element.fila; delete element.columna;
                });
                //console.log('DetInv ',detCompra)

                if(PropsInventario?.cantidad){
                  ItemsCompra.forEach(item=>{
                    if(item.id === PropsInventario.linea){
                      setInventarioCompra(item.id,detCompra)
                    }
                  }) 
                  
                  if(CantidadTotal<=PropsInventario.cantidad && CantidadTotal>0){
                    let petitions = {};

                    petitions.updateCompra = {
                      id: PropsInventario.idcompra,
                      sucursal: getSucursal(),
                      pagos: [],
                      lineas: [{id:PropsInventario.linea,inventario: detCompra,inventarioPendiente : true}],
                      ////lineas: [{id:PropsInventario.linea,inventario: detCompra,inventarioPendiente: true}],
                      inventarioPendiente : true,
                    } 

                    if(CantidadTotal==PropsInventario.cantidad){
                      //console.log('hacer los update product=>',inventario.current)
                      inventario.current.forEach(element => {
                        element.linea = element.type;
                        element.guideline = (element?.guideline?element.guideline:0)//ajuste_guideline
                        delete element.type; delete element.esfera; delete element.ciladd; delete element.N; delete element.ST; delete element.BT;
                      });
                      petitions.updateProduct = true;
                      petitions.updateCompra.lineas = petitions.updateCompra.lineas.map(linea=>{
                        if(linea.id==PropsInventario.linea){
                          return {...linea,inventarioPendiente: false};
                        }else{
                          return linea;
                        }
                      })
                      //setDisable(true);
                    }
                    //console.log('antes de hacer la peticion ',petitions)
                    openmodalLoading("Subiendo Datos a la Nube")
                    setPeticiones(petitions);
                  }
                  else if(CantidadTotal === -1){return}
                  else if(CantidadTotal>PropsInventario.cantidad){
                    handleErrorDatos("El total a inventariar no puede ser mayor a la cantidad comprada");
                  }else handleErrorDatos("El total a inventariar no coincide con el total inventariado");
                  
                }else handleErrorDatos("No hay nada por inventariar");
                
              }} >
                Guardar
          </button>
          <button 
              className={(PropsInventario?.ajuste&&PropsInventario?.tab=="IN"?"btn-green ml-2":"hidden")}
              onClick={e=>{
                let arrayFinal = [];
                let cantTotal = 0, importeTotal = 0;
               Inventario.map(item=>{
                  let inv = PropsInventario.inventarioAjuste.find(inv=>inv.id==item.id);
                  if(inv){
                    const diferencia = item.cantidad-inv.cantidad;
                    const importe = diferencia*item.precioCompra;
                    importeTotal += importe;
                    cantTotal += inv.cantidad;
                    if(diferencia!==0){
                      arrayFinal.push({
                        id: inv.id,
                        cantidad: diferencia,
                        importe: importe,
                        categoria: inv.categoria,
                        linea: inv.type,
                        marca: inv.marca,
                        responsable: "",
                        ticket: "",
                        descripcion: item.descripcion,
                        existenciaOld: item.cantidad,
                      })
                    }
                  }
                })
                //console.log(arrayFinal)
                if(arrayFinal.length==0){return;}
                openmodalLoading("Realizando ajustes al Inventario Porfavor Espere");
                useInsertMerma({items:arrayFinal,descripcion:"AJUSTE DE INVENTARIO",cantidadTotal:cantTotal,importeTotal:importeTotal,tipo:"AJUSTE"},funcionExitoMerma,handleError);
                const props={...PropsInventario};
                delete props.ajuste;
                delete props.editItems;
                delete props.inventarioAjuste;
                setPropsInventario(props);
              }}>
                Ajustar Inventario
          </button>
          <button 
              className={(PropsInventario?.ajuste&&PropsInventario?.tab=="G"?"btn-green ml-2":"hidden")}
              onClick={e=>{
                //console.log(Inventario)
                let arrayFinal = [];
               Inventario.map(item=>{
                  let inv = PropsInventario.inventarioAjuste.find(inv=>inv.id==item.id);
                  if(inv){
                    if(inv.guideline!==item.guideline){
                      const sucursalProps = getitem(inv.id)?.sucursales[getSucursal()];
                      const element = {...item,comprable:sucursalProps?.comprable||true,vendible:sucursalProps?.vendible||true};
                        element.linea = element.type;
                        element.cantidad = 0;
                        delete element.type; delete element.esfera; delete element.ciladd; delete element.N; delete element.ST; delete element.BT;
                        arrayFinal.push(element);
                    }
                  }
                })
                //console.log(arrayFinal)
                if(arrayFinal.length==0){return;}
                //console.log(arrayFinal)
                const props={...PropsInventario};
                delete props.ajuste;
                delete props.editItems;
                delete props.inventarioAjuste;
                setPropsInventario(props);
                inventario.current = arrayFinal;
                openmodalLoading("Actualizando GuideLine Porfavor Espere");
                setLotes(inventario.current.splice(0,LogitudLote));
              }}>
               Cambiar GuideLine
          </button>
          <button 
              className={(PropsInventario?.updatePrice?"btn-green ml-2 ":"hidden")}
              onClick={e=>{
                let arrayFinal = [],errors=false;
                if(PropsInventario?.globalPrice){
                  Inventario.map(item=>{
                    let inv = PropsInventario.oldInv.find(inv=>inv.id==item.id);
                    if(inv){
                      if(inv.precioVenta!==item.precioVenta){
                        const sucursalProps = getitem(inv.id)?.sucursales[getSucursal()];
                        const element = {...item,comprable:sucursalProps?.comprable||true,vendible:sucursalProps?.vendible||true};
                          element.linea = element.type;
                          element.cantidad = 0;
                          delete element.type; delete element.esfera; delete element.ciladd; delete element.N;
                          arrayFinal.push(element);
                          if(!item?.precioCompra){
                            handleErrorConteo("La Fila de la graduacion "+item.id+" debe tener precio de compra"); 
                            errors = true;
                            return;
                         }
                      }

                    }
                  })
                }else{
                  Inventario.map(item=>{
                    let inv = PropsInventario.inventarioAjuste.find(inv=>inv.id==item.id);
                    if(inv){
                      if(inv.precioVenta!==item.precioVenta){
                        const sucursalProps = getitem(inv.id)?.sucursales[getSucursal()];
                        const element = {...item,comprable:sucursalProps?.comprable||true,vendible:sucursalProps?.vendible||true};
                          element.linea = element.type;
                          element.cantidad = 0;
                          delete element.type; delete element.esfera; delete element.ciladd; delete element.N;
                          arrayFinal.push(element);
                          if(!item?.precioCompra){
                            handleErrorConteo("La Fila de la graduacion "+item.id+" debe tener precio de compra"); 
                            errors = true;
                            return;
                         }
                      }

                    }
                  })
                }
                
                if(arrayFinal.length==0){console.log('no hay nada que actualizar'); return;}
                if(errors){return;}
                openmodalLoading("Actualizando Precios");
                inventario.current = arrayFinal;
                setLotes(inventario.current.splice(0,LogitudLote));
              }}>
                Actualizar Precio Venta
          </button>
          <button 
              className={(PropsInventario?.updatePriceBuy?"btn-red ml-2 ":"hidden")}
              onClick={e=>{
                let arrayFinal = [];
                if(PropsInventario?.globalPriceBuy){
                  Inventario.map(item=>{
                    let inv = PropsInventario.oldInv.find(inv=>inv.id==item.id);
                    if(inv){
                      if(inv.precioCompra!==item.precioCompra){
                        const sucursalProps = getitem(inv.id)?.sucursales[getSucursal()];
                        const element = {...item,comprable:sucursalProps?.comprable||true,vendible:sucursalProps?.vendible||true};
                          element.linea = element.type;
                          element.cantidad = 0;
                          delete element.type; delete element.esfera; delete element.ciladd; delete element.N;
                          arrayFinal.push(element);
                      }
                    }
                  })
                }else{
                  Inventario.map(item=>{
                    let inv = PropsInventario.inventarioAjuste.find(inv=>inv.id==item.id);
                    if(inv){
                      if(inv.precioCompra!==item.precioCompra){
                        const sucursalProps = getitem(inv.id)?.sucursales[getSucursal()];
                        const element = {...item,comprable:sucursalProps?.comprable||true,vendible:sucursalProps?.vendible||true};
                          element.linea = element.type;
                          element.cantidad = 0;
                          delete element.type; delete element.esfera; delete element.ciladd; delete element.N;
                          arrayFinal.push(element);
                      }
                    }
                  })
                }
                if(arrayFinal.length==0){console.log('no hay nada que actualizar'); return;}
               // console.log(arrayFinal)
                openmodalLoading("Actualizando Precios");
                inventario.current = arrayFinal;
                setLotes(inventario.current.splice(0,LogitudLote));
              }}>
                Actualizar Precio Compra
          </button>
          <button 
              className={(PropsInventario?.updateItem?"btn-blue1 ml-2":"hidden")}
              onClick={e=>{
                let arrayFinal = [];
               Inventario.map(item=>{
                  const sucursalProps = getitem(item.id)?.sucursales[getSucursal()];
                  const element = {...item,comprable:sucursalProps.comprable,vendible:sucursalProps.vendible};
                  element.linea = element.type;
                  delete element.type; delete element.esfera; delete element.ciladd;
                  
                  arrayFinal.push(element);
                })
                if(arrayFinal.length==0){console.log('no hay nada que actualizar'); return;}
                openmodalLoading("Actualizando Productos");
                inventario.current = arrayFinal;
                //console.log(inventario.current)
                setLotes(inventario.current.splice(0,LogitudLote));
          }}>
                Actualizar Producto
          </button>
          <input
          className={(PropsInventario?.ofertaEnable&&PropsInventario?.tab=="IN"?'border-form':"hidden")}
          ref={inputOferta}
          onBlur={e=>{
            if(!isNaN(e.target.value)){
              e.target.value = parseInt(e.target.value);
            }
          }}
          />
          <button
          className={(PropsInventario?.ofertaEnable&&PropsInventario?.tab=="IN"?"btn-blue1":"hidden")}
          onClick={e=>{
            if(!inputOferta.current.value){return}
            const oferta = parseInt(inputOferta.current.value);

            let new_inventario = [...Inventario];
            new_inventario.map(item=>{
              const descMax = getDescuentoMax(item.precioCompra,item.precioVenta);
              if(item.precioCompra&&item.precioVenta){ item.oferta = oferta>descMax?descMax:oferta;}
            })
            setInventario(new_inventario);
            setPropsInventario({...PropsInventario,oferta:oferta})
          }}>
            Calcular Oferta
          </button>
          <button
          className={(PropsInventario?.oferta&&PropsInventario?.tab=="IN"?"btn-green1":"hidden")}
          onClick={e=>{
            let arrayFinal = [],errors=false;
              Inventario.map(item=>{
                let inv = PropsInventario.oldInv.find(inv=>inv.id==item.id);
                if(inv){
                    const sucursalProps = getitem(inv.id)?.sucursales[getSucursal()];
                    const element = {...item,comprable:sucursalProps?.comprable||true,vendible:sucursalProps?.vendible||true};
                      element.linea = element.type;
                      element.cantidad = 0;
                      delete element.type; delete element.esfera; delete element.ciladd; delete element.N;
                      if(element?.oferta){arrayFinal.push(element);}
                }
              })
            if(arrayFinal.length==0){console.log('no hay nada que actualizar'); return;}
            if(errors){return;}
            openmodalLoading("Actualizando Ofertas");
            inventario.current = arrayFinal;
            setLotes(inventario.current.splice(0,LogitudLote));
          }}>
            Aplicar Oferta
          </button>
        </div>
      </div>
      </>
    )
}

const mapStateToProps = (state) => ({
  Inventario: state.Inventario,
  PropsInventario: state.PropsInventario,
  PropsReportes: state.PropsReportes,
  ItemsCompra: state.itemsCompra,
  Ruta: state.Ruta,
  Rango: state.Datasucursal.analisis,
});


const mapDispatchToProps = dispatch => ({
  setCleanInventario: () => dispatch({type: 'RESET_INVENTARIO'}),
  setInventarioCompra:(id,inventario) => dispatch(agregarInventarioDetCompra(id,inventario)),
  setInventario0: (set)=> dispatch(set),
  addRuta: (ruta) => dispatch(setRuta(ruta)),
  delRuta:()=> dispatch(returnRuta()),
  setPropsInventario:(data)=> dispatch({type:"SET_PROPSINVENTARIO", estado:data}),
  resetDatos: ()=>dispatch({type:"RESET_DATOS"}),
  setInventario:(items)=>dispatch({type:"SET_INVENTARIO",inventario:items}),
});


export default connect(mapStateToProps, mapDispatchToProps) (FooterInventario);