import React from 'react'
import Container from '../components/Container'
import PanelServicio from '../components/Servicios/PanelServicio'
import BuscaTicket from '../components/Ventas/BuscaTicket'

const Bisel = () => {
  return (
    <Container>
        <div className='flex flex-col w-full justify-center'>
            <div className='w-full md:w-44 self-center'>
                <BuscaTicket/>
            </div>
            <PanelServicio/>
        </div>
    </Container>
  )
}

export default Bisel