import React, { useEffect, useRef, useState } from "react";
import { setsearchPersonal, setRuta } from "../../redux/Actions";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  apiUse,
  updating,
  validatePermission,
  disabledFieldIsNot,
  moneyToValue,
  getIdUser,
  havePermission,
  getSucursal,
} from "../../services/functions.js";
import Modaldanger from "../../components/modal/info";
import Modalsuccess from "../../components/modal/success";
import Labelerror from "../../components/LabelError";
import Bancos from "../../components/Bancos";
import InputPrecioPanel from "../../components/InputPrecioPanel";
import Categorias from "../../components/Sucursales/Categorias";
import { useCatalogo } from "../../Hooks/useApis";
import LoadingModal from "../../components/modal/LoadingModal";
import LabelErrors from "../../components/LabelErrors";
import {Regimen_Sat, permisos}  from '../../static/dat/datos';

const schema = yup.object().shape({
  nombre: yup.string().required("El nombre es obligatorio"),
  permisos: yup.array().of(yup.string()),
  rfc: yup.string().required("El RFC es obligatorio"),
  email: yup.string().email("email invalido"),
  codigo_postal: yup
    .string()
    .matches(
      /^[0-9]{5}$/,
      "Por favor, un código postal válido"
    )
    .required("El código postal es obligatorio"),
  direccion: yup.string(),
  telefono: yup
    .string()
    .matches(/^[0-9]{10}$/, "El telefono debe tener 10 digitos")
    .required("El teléfono es obligatorio"),
  id: yup
    .string()
    .min(3, "El ID debe tener 3 caracteres al menos")
    .max(10, "El ID no debe sobrepasar los 10 caracteres"),

  iva: yup
    .number()
    .min(0,"El valor debe ser mayor a cero")
    .typeError('Por favor ingresa un valor numérico')
    .integer()
    .required("El porcentaje de iva es obligatorio"),
  tiempoTallado: yup
    .number()
    .typeError('Por favor ingresa un valor numérico')
    .positive("El valor debe ser mayor a cero")
    .integer()
    .required("El tiempo de tallado es obligatorio"),
  tiempoBisel: yup
    .number()
    .typeError('Por favor ingresa un valor numérico')
    .positive("El valor debe ser mayor a cero")
    .integer()
    .required("El tiempo de biselado es obligatorio"),
  pagoAutorizado: yup
    .number()
    .min(0,"El valor debe ser mayor a cero")
    .max(100,"El valor debe ser menor al 100% ")
    .typeError('Por favor ingresa un valor numérico')
    .integer()
    .required("El monto de pago autorizado es obligatorio"),
  analisis: yup
    .number()
    .min(1,"El valor debe ser mayor a 0")
    .max(12,"El valor debe ser menor a 12 meses ")
    .typeError('Por favor ingresa un valor numérico')
    .integer()
    .required("El periodo de analisis es obligatorio"),
  ticket: yup.object().required("Ticket es obligatorio"),
});

const FormularioSucursal = (props) => {
  const [idvalue, setidvalue] = useState(props.datosSucursal?.id || ""),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [ticket,setTicket]=useState({header:"",footer:"",productos:[]}),
  [prod,setProd]=useState("");
  const dat= useRef({bancos:[],timezones:[],monedas:[]});
  const array = ["BANCOS","TIMEZONES","MONEDAS"];

  const setsearchPersonal = props.setsearchPersonal;
  const validatepermission = validatePermission(props.datosSucursal);
  const verifyPermissionSuper =havePermission(permisos.configurar);

  let pet=0;

  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  const { register, handleSubmit, errors, setValue, getValues,onChange,watch,reset } = useForm({
    resolver: yupResolver(schema),
  });

  register({ name: "cuentas" });
  register({ name: "limiteCredito" });
  register({ name: "categorias" });
  register({ name: "ticket"});
  register({ name: "razonSocial"});
  register({ name: "regimen"});
  register({ name: "descuentos"});

  const nameEndpoind = (data) => {
    if (updating(data)) {
      return "/update-sucursal";
    }

    return "/insert-sucursal";
  };

  const handleSuccessful = (data) => {
    const endpoind = nameEndpoind(props.datosSucursal);
    if(endpoind === "/insert-sucursal"){
      apiUse({
        url: "/inventario",
        method: "POST",
        data: {sucursales:[data.id]},
        handleSuccessful: (respuesta)=>{console.log(respuesta)},
        handleError: (error)=>{console.log(error)},
      });
    }
    setmodalSuccessConfig({
      isOpen: true,
      message:"La actualización se realizó con éxito",
    });
    reset();
    setsearchPersonal({});
  };

  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  };

  const sendData = (dataform) => {
    //console.log(dataform)
    const endpoind = nameEndpoind(props.datosSucursal);
    let miDataform = { ...dataform};
    miDataform.moneda = JSON.parse(miDataform.moneda);
    if(endpoind=="/insert-sucursal"){miDataform.creador= getIdUser();}
    
    apiUse({
      url: endpoind,
      method: "POST",
      data: miDataform,
      handleSuccessful: handleSuccessful,
      handleError: handleError,
    });
  };

  const validatepermissioncreate = validatepermission.create(permisos.regSucursal);

  function verifyPermissionID() {
    return disabledFieldIsNot([validatepermissioncreate]);
  }

  function verifyPermission() {
    return disabledFieldIsNot([
      validatepermissioncreate,
      validatepermission.update(permisos.modSucursal),
    ]);
  }

  const funcionError=(error)=>{
    pet=0;
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: (error||"")+" Error al cargar el catalogos",
    });
  }

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
    setmodalLoading({ isOpen: false });
  };

  const reg = watch('register');

  useEffect(()=>{
    pet=0;
   openmodalLoading();
    array.map(send=>{
      useCatalogo(send,(resp)=>{
        if(!resp?.opciones){
          funcionError();
          return;
        }
        pet = pet+1;
        const new_dat={...dat.current};
        new_dat[send.toLowerCase()]=resp.opciones;
        dat.current = new_dat;
        if(pet!==array.length){return; }
        closemodalLoading();
      },funcionError);
    })
  },[])

  useEffect(()=>{
    if(!props.datosSucursal?.id){
      reset();
    }
  },[props.datosSucursal])

  useEffect(()=>{
    if(!props.datosSucursal?.id){document.getElementsByName("pagoAutorizado")[0].value ="";return;}
    document.getElementsByName("pagoAutorizado")[0].value = props.datosSucursal.pagoAutorizado;
  },[props.datosSucursal?.id])

  useEffect(()=>{
    if(!props.datosSucursal?.ticket){return}
    setTicket(props.datosSucursal.ticket);
  },[props.datosSucursal])

  useEffect(()=>{
    setValue("ticket", ticket);
  },[ticket])


  return (
    <>
    <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Movimientos</LoadingModal.Title>
        </LoadingModal.Body>
    </LoadingModal>
      <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2"
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalsuccess config={modalSuccessConfig}>
        <Modalsuccess.Message title={"Éxito"}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
      <form id="formSucursal">
        <div className="flex flex-wrap items-top mb-6">
          <div className="w-full lg:w-6/12 px-4 ml-auto">
          <div className="relative w-full mb-3">
              <label className="block textf  text-blue-700" htmlFor="grid-password">
                ID
              </label>
              <Labelerror error={errors}>
                <input
                  type="text"
                  maxLength={"10"}
                  className="border-form w-full uppercase"
                  name="id"
                  placeholder="ID"
                  ref={register}
                  readOnly={verifyPermissionID()}
                  value={props.datosSucursal?.id || idvalue}
                  onChange={(e) => {
                    setidvalue(e.target.value.toUpperCase().substring(0, 6));
                  }}
                  style={{ transition: "all .15s ease" }}
                />
              </Labelerror>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf  text-blue-700" htmlFor="grid-password">
                Nombre
              </label>
              <Labelerror error={errors}>
                <input
                  type="text"
                  className="border-form w-full uppercase"
                  name="nombre"
                  readOnly={verifyPermission()}
                  placeholder="Nombre"
                  ref={register}
                  defaultValue={props.datosSucursal?.nombre}
                  onChange={e=>{
                    e.target.value = e.target.value.toUpperCase();
                  }}
                  style={{ transition: "all .15s ease" }}
                />
              </Labelerror>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                C.P.
              </label>
              <Labelerror error={errors}>
                <input
                  type="text"
                  className="border-form w-full"
                  name="codigo_postal"
                  readOnly={verifyPermission()}
                  placeholder="C.P."
                  ref={register}
                  defaultValue={props.datosSucursal?.codigo_postal}
                  style={{ transition: "all .15s ease" }}
                />
              </Labelerror>
            </div>
           
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Dirección
              </label>
              <Labelerror error={errors}>
                <input
                  type="text"
                  className="border-form w-full"
                  name="direccion"
                  readOnly={verifyPermission()}
                  placeholder="Dirección"
                  ref={register}
                  defaultValue={props.datosSucursal?.direccion}
                  style={{ transition: "all .15s ease" }}
                />
              </Labelerror>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Email
              </label>
              <Labelerror error={errors}>
                <input
                  type="text"
                  className="border-form w-full"
                  name="email"
                  readOnly={verifyPermission()}
                  placeholder="Email"
                  ref={register}
                  defaultValue={props.datosSucursal?.email}
                  style={{ transition: "all .15s ease" }}
                />
              </Labelerror>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Teléfono
              </label>
              <Labelerror error={errors}>
                <input
                  type="text"
                  className="border-form w-full"
                  name="telefono"
                  maxLength={"10"}
                  readOnly={verifyPermission()}
                  placeholder="Teléfono"
                  defaultValue={props.datosSucursal?.telefono}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                />
              </Labelerror>
            </div>
              <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Razon Social
              </label>
              <Labelerror error={errors}>
                <input
                  type="text"
                  className="border-form w-full"
                  name="razonSocial"
                  readOnly={verifyPermission()}
                  placeholder="Razon Social"
                  ref={register}
                  defaultValue={props.datosSucursal?.razonSocial}
                  style={{ transition: "all .15s ease" }}
                />
              </Labelerror>
            </div>  
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                RFC
              </label>
              <Labelerror error={errors}>
                <input
                  type="text"
                  className="border-form w-full"
                  name="rfc"
                  readOnly={verifyPermission()}
                  placeholder="RFC"
                  ref={register}
                  defaultValue={props.datosSucursal?.rfc}
                  style={{ transition: "all .15s ease" }}
                />
              </Labelerror>
            </div>
            
             <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Regimen Fiscal
              </label>
              <Labelerror error={errors}>
                <select
                  name="regimen"
                  ref={register}
                  disabled={verifyPermission()}
                  className="border-form w-full"
                  defaultValue={props.datosSucursal?.regimen}
                >
                   <option key={-1} value={""} defaultValue=""></option>
                  {Object.keys(Regimen_Sat).map((item,index) => (
                  <option key={index} value={item} defaultValue={props.datosSucursal?.regimen===item} selected={props.datosSucursal?.regimen===item}>
                      {Regimen_Sat[item]}
                    </option>
                  ))}
                </select>
              </Labelerror>
            </div>  

            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">BANCOS</label>
              <Labelerror error={errors}>
                <Bancos
                  savechange={(data) => {
                    setValue("cuentas", data);
                  }}
                  readOnly={verifyPermission()}
                  data={props.datosSucursal?.cuentas}
                  banks={dat.current?.bancos}
                />
              </Labelerror>
            </div>

            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">Texto Ticket </label>
              <Labelerror error={errors}>
                <label name="ticket" ref={register}/>
              </Labelerror>
              <div className="w-full flex flex-col items-center  mx-auto ">
                <div className="w-full px-0 ">
                  <div className="flex flex-col items-center relative border border-blue-900 ">
                    <div className="w-full ">
                      <div className="my-2 p-1  bg-white rounded focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3  transition-colors duration-200 ease-in-out mb-2 max-h-select overflow-y-auto"
                      style={{ height: "20vh" }}>
                        <div className="flex flex-auto h-14">
                            <label className="w-32 font-bold text-size text-white bg-teal-500 border border-gray-300  py-3 px-2 text-center">Header</label> 
                            <textarea
                            className="resize-none w-full text-size md:text-sm  md:px-1 lg:px-2 border-0 rounded ring-1 md:mt-0 font-semibold text-gray-700 ring-blue-300 transition-colors duration-200 ease-in-out  outline-none focus:ring-blue-500 disabled:opacity-50 disabled:bg-gray-100"
                            rows="2"
                            readOnly={verifyPermission()}
                            value={ticket.header}
                            onChange={e=>{
                              setTicket({...ticket,header:e.target.value});
                            }}
                            />
                        </div>
                        <div className="flex flex-auto h-14">
                            <label className="w-32 font-bold text-size text-white bg-teal-500 border border-gray-300  py-3 px-2 text-center">Footer</label> 
                            <textarea
                            className="resize-none w-full text-size md:text-sm  md:px-1 lg:px-2 border-0 rounded ring-1 md:mt-0 font-semibold text-gray-700 ring-blue-300 transition-colors duration-200 ease-in-out  outline-none focus:ring-blue-500 disabled:opacity-50 disabled:bg-gray-100"
                            rows="2"
                            readOnly={verifyPermission()}
                            value={ticket.footer}
                            onChange={e=>{
                              setTicket({...ticket,footer:e.target.value});
                            }}
                            />
                        </div>
                        <div className="flex flex-auto h-14">
                            <label className="w-32 font-bold text-size text-white bg-teal-500 border border-gray-300  py-3 px-2 text-center">Productos Nuevos</label> 
                            <div className="border-2 rounded-md w-full flex items-center">
                            {ticket.productos.map((item,index)=>{
                              return <div key={index} className="flex justify-center items-center self-center mr-1 py-1 px-1 rounded-full text-blue-800 bg-blue-100 border border-indigo-300 ">
                              <div className="text-2xs lg:text-xs font-semibold leading-none max-w-full flex-initial">
                                {item}
                              </div>
                              <div className="flex flex-auto flex-row-reverse">
                                <div
                                  className="feather text-2xs lg:text-xs feather-x cursor-pointer hover:text-red-400 rounded-full mx-1"
                                  onClick={(e) => {
                                    const new_prods = [...ticket.productos].filter(prod=>item!==prod);
                                    setTicket({...ticket,productos:new_prods});
                                  }}
                                >
                                  <i className="fas fa-times"></i>
                                </div>
                              </div>
                            </div>
                            })}
                            <input className="border-form mr-1 uppercase" value={prod} onChange={e=>{
                              setProd(e.target.value.toUpperCase());
                            }}/>
                            <button type="button" hidden={(prod!==""?false:true)} onClick={e=>{
                              if(prod==""){return}
                              const new_ticket = {...ticket};
                              new_ticket.productos=[...ticket.productos,prod.trim()];
                              setTicket(new_ticket);
                              setProd("");
                            }}><i className="fas fa-plus-circle text-green-600"></i></button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
          </div>

          <div className="w-full lg:w-6/12 px-4">
          <div className="relative w-full mb-3">
              <label onClick={e=>{
                console.log('=>',getValues().analisis)
                console.log('reg=>',register())
                console.log('error=>',errors["pagoAutorizado"])
                //console.log(verifyPermissionSuper)
              }} className="block textf" htmlFor="grid-password">
                Limite de Crédito para compras
              </label>
              <Labelerror error={errors}>
                <InputPrecioPanel
                  className="bg-white"
                  form={true}
                  min={0}
                  newValor={props.datosSucursal?.creditoProveedores}
                  placeholder="Crédito para compras"
                  readOnly={!verifyPermissionSuper}
                  setValor={(valor) => { 
                    setValue('limiteCreditoCompras',moneyToValue(valor));
                  }}
                />
              </Labelerror>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Limite de Crédito a clientes
              </label>
              <Labelerror error={errors}>
                <InputPrecioPanel
                  className="bg-white"
                  form={true}
                  min={0}
                  newValor={props.datosSucursal?.limiteCredito}
                  placeholder="Límite de crédito"
                   readOnly={!verifyPermissionSuper} 
                  setValor={(valor) => { 
                    setValue('limiteCredito',moneyToValue(valor));
                  }}
                />
              </Labelerror>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
                Crédito otorgado:
              </label>
              <Labelerror error={errors}>
                <InputPrecioPanel
                  className="bg-white"
                  form={true}
                  min={0}
                  newValor={props.datosSucursal?.creditoOtorgado}
                  placeholder=""
                  readOnly={true}
                  setValor={(valor) => {}}
                />
              </Labelerror>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Porcentaje de pago autorizado:
              </label>
              <div className="flex border-form w-full items-center " >
                <input
                  type="number"
                  className="bg-transparent focus:outline-none w-full font-semibold disabled:opacity-50"
                  name="pagoAutorizado"
                  readOnly={!verifyPermissionSuper} 
                  placeholder="Porcentaje de pago autorizado"
                  ref={register}
                  defaultValue={props.datosSucursal?.pagoAutorizado}
                  onChange={e=>{
                    e.target.value = e.target.value.toUpperCase();
                  }}
                  style={{ transition: "all .15s ease" }}
                />
                <span className="font-semibold disabled:opacity-50" >%</span>
              </div>
              <LabelErrors name="pagoAutorizado" errors={errors}/>
            </div>
            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
               IVA
              </label>
              <Labelerror error={errors}>
                <input
                  type="number"
                  className="border-form w-full"
                  name="iva"
                  readOnly={verifyPermission()}
                  placeholder="IVA"
                  defaultValue={props.datosSucursal?.iva}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                  onChange={e=>{
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
              </Labelerror>
            </div>
         
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
               Tiempo de servicio de tallado (minutos)
              </label>
              <Labelerror error={errors}>
                <input
                  type="number"
                  className="border-form w-full"
                  name="tiempoTallado"
                  readOnly={verifyPermission()}
                  placeholder="Tiempo de servicio de tallado"
                  defaultValue={props.datosSucursal?.tiempoTallado}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                  onChange={e=>{
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
              </Labelerror>
            </div>

            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">
               Tiempo de servicio de bisel (minutos)
              </label>
              <Labelerror error={errors}>
                <input
                  type="number"
                  className="border-form w-full"
                  name="tiempoBisel"
                  readOnly={verifyPermission()}
                  placeholder="Tiempo de servicio de bisel"
                  defaultValue={props.datosSucursal?.tiempoBisel}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                  //onChange={e=>{{
                />
              </Labelerror>
            </div>

            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Moneda
              </label>
              <Labelerror error={errors}>
                <select
                  name="moneda"
                  ref={register}
                  readOnly={verifyPermission()}
                  className="border-form w-full"
                 // onChange={e=>{
                 defaultValue={props.datosSucursal.moneda?.nombre||""}
                >
                  {dat.current?.monedas.map((item,index) => (
                    <option key={index} value={JSON.stringify(item)} defaultValue={props.datosSucursal.moneda?.nombre===item.nombre}>
                      {item.nombre}
                    </option>
                  ))}
                </select>
              </Labelerror>
            </div>


            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
                Zona horaria
              </label>
              <Labelerror error={errors}>
                <select
                  name="timezone"
                  ref={register}
                  readOnly={verifyPermission()}
                  defaultValue={props.datosSucursal?.timezone||""}
                  className="border-form w-full"
                >
                  {dat.current?.timezones.map((item, index) => (
                    <option key={index} defaultValue={props.datosSucursal?.timezone===item.tz}>
                      {item.tz}
                    </option>
                  ))}
                </select>
              </Labelerror>
            </div>
            
            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">Ganancia minima por Categoria </label>
              <Labelerror error={errors}>
                <Categorias
                  savechange={(data) => {
                    setValue("categorias", data);
                  }}
                  readOnly={verifyPermission()}
                  data={props.datosSucursal?.categorias}
                  utilMin={props.utilMin}
                />
                </Labelerror>
            </div>

            <div className="relative w-full mb-3">
              <label className="block textf" htmlFor="grid-password">Descuentos Generales </label>
              <Labelerror error={errors}>
                <Categorias
                  bg={true}
                  savechange={(data) => {
                    setValue("descuentos", data);
                  }}
                  readOnly={verifyPermission()}
                  data={props.datosSucursal?.descuentos}
                  utilMin={0}
                />
                </Labelerror>
            </div>

            <div className="relative w-full mb-3">
              <label className="block textf text-blue-700" htmlFor="grid-password">
               Lapso de tiempo para analisis de ventas
              </label>
              <Labelerror error={errors}>
                <input
                  type="number"
                  className="border-form w-full"
                  name="analisis"
                  readOnly={verifyPermission()}
                  placeholder="Lapso de tiempo para analisis de ventas"
                  defaultValue={props.datosSucursal?.analisis}
                  ref={register}
                  style={{ transition: "all .15s ease" }}
                  onChange={e=>{
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
              </Labelerror>
            </div>

            <div className="md:flex md:items-center mb-6">
                <button
                  className="btn-gray2 w-full lg:w-1/2 px-1"
                  type="button"
                  onClick={(e) => {
                    props.setRuta("menu");
                  }}
                  style={{ transition: "all .15s ease" }}
                >
                  Cancelar
                </button>
                <input
                  className="btn-blue w-full lg:w-1/2 px-1 cursor-pointer disabled:opacity-50"
                  type="button"
                  disabled={verifyPermission()}
                  onClick={handleSubmit(sendData)}
                  value="Guardar"
                  style={{ transition: "all .15s ease" }}
                ></input>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
const mapStateToProps = (state) => ({
  datosSucursal: state.SearchPersonal,
  utilMin: state.Catalogos.GLOBALCONFIGS.utilidadMinima,
});

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
  setsearchPersonal: (personal) => dispatch(setsearchPersonal(personal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormularioSucursal);
